import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../services/api";
import FileInput from "../../../components/FileInput";

export default ({ article, setArticle }) => {
  const navigate = useNavigate();

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/article/${article._id}`);
    toast.success("successfully removed!");
    navigate("/articles");
  }

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/article/${article._id}`, article);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setArticle(data);
      toast.success("Updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!article) return <div />;

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-4 p-2">
        <div className="w-full space-y-2">
          <label className="font-semibold">Title</label>
          <input type="text" className="input" value={article.title} onChange={(e) => setArticle({ ...article, title: e.target.value })} />
        </div>
        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Description</label>
          <textarea type="text" className="input" value={article.description} rows={5} onChange={(e) => setArticle({ ...article, description: e.target.value })} />
        </div>
      </div>
      <div className="flex gap-4 justify-end">
        <button className="red-btn" onClick={onDelete}>
          Delete
        </button>
        <button className="blue-btn" onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
};
