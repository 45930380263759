import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoPaperPlane } from "react-icons/io5";
import { useSelector } from "react-redux";

import MultipleSelectInput from "../../../components/MultipleSelectInput";
import TextEditor from "../../../components/TextEditor";
import Modal from "../../../components/modal";
import api from "../../../services/api";
import dayjs from "dayjs";

export default ({ project, callback, refetch }) => {
  const u = useSelector((state) => state.Auth.user);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState({});
  const [templates, setTemplates] = useState([]);
  const [isCreateTemplateMode, setIsCreateTemplateMode] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  async function fetchTemplates() {
    const { data, ok } = await api.post("/mail-template/search", { type: "REPORTING" });
    if (!ok) return toast.error("Error fetching templates");
    setTemplates(data);
  }

  function loadTemplate(templateName) {
    const obj = { ...mail };
    const template = templates.find((template) => template.name === templateName);

    obj.subject = template.subject.replace("{{PROJECT_NAME}}", project.name);
    obj.body = template.body.replace("{{PROJECT_NAME}}", project.name).replace("{{CLIENT_NAME}}", project.clientName);

    setMail(obj);
  }

  async function sendEmail() {
    try {
      if (!mail.recipients.length) return toast.error("Please add recipients");
      setLoading(true);

      await api.post(`/report_email/`, { ...mail, project_id: project._id });
      setLoading(false);
      toast.success("Email sent !");

      setOpen(false);
      setMail({ recipients: [u.email], subject: `Reporting of ${dayjs(new Date()).format("dddd DD MMMM YYYY")}`, body: "" });
      refetch();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error sending email");
    }
  }

  return (
    <div>
      <button
        onClick={() =>
          setOpen((e) => {
            const email = [];
            if (project.clientsMails) email.push(...project.clientsMails);
            if (project?.team.filter((e) => e.userRole === "client")) email.push(...project?.team.filter((e) => e.userRole === "client")?.map((user) => user.userEmail));
            email.push(u.email);
            email.push("report@selego.co");
            setMail({
              recipients: email,
              subject: `Reporting of ${dayjs(new Date()).format("dddd DD MMMM YYYY")}`,
              body: `<p>Hello ${project.name},<br><br><br>Have a nice day</p>`,
            });
            return !e;
          })
        }
        className="w-full hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 text-sm border-[1px] border-blue-500 hover:border-transparent rounded">
        Send email
      </button>
      {open && (
        <Modal isOpen={open} className={"w-2/3"} onClose={() => setOpen(false)}>
          <div className="flex flex-col gap-2 p-3 pt-5 w-full">
            {isCreateTemplateMode ? (
              <TemplateForm setIsCreateTemplateMode={setIsCreateTemplateMode} fetchTemplates={fetchTemplates} />
            ) : (
              <>
                <div className="flex gap-2 items-end">
                  <div className="">
                    <div className="px-1 text-sm text-gray-600 font-medium">Template</div>
                    <select
                      disabled={loading}
                      onChange={(e) => loadTemplate(e.target.value)}
                      className="bg-[#FFFFFF] text-sm text-gray-700 py-2 px-2 rounded-sm cursor-pointer shadow-sm">
                      <option disabled selected>
                        Select a template
                      </option>
                      {templates
                        .filter((template) => template.active)
                        .map((template) => (
                          <option key={template.name} value={template.name} label={template.name}>
                            {template.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <MultipleSelectInput value={mail.recipients} label="To" placeholder="Add recipients..." onChange={(e) => setMail({ ...mail, recipients: e })} />

                <div className={`w-full`}>
                  <div className="px-1 text-sm text-gray-600 font-medium">Subject</div>
                  <input
                    disabled={loading}
                    className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
                    name="subject"
                    value={mail.subject}
                    onChange={(e) => setMail({ ...mail, subject: e.target.value })}
                  />
                </div>
                <div className="mt-3">
                  <div className="px-1 text-sm text-gray-600 font-medium">Message</div>
                  <TextEditor
                    value={mail.body}
                    onChange={(e) => setMail({ ...mail, body: e.target.value })}
                    defaultPrompt={`Help me format this email properly for giving project progress detail to client. The email: ${mail.body}`}
                  />
                </div>
                <div className="flex gap-1 items-center w-3/4 h-3/4 min-h-max min-w-max">
                  <button
                    disabled={loading}
                    className="ml-2 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-3 text-sm border border-blue-500 hover:border-transparent rounded flex gap-2 items-center disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-wait"
                    onClick={() => sendEmail()}>
                    <IoPaperPlane />
                    Send
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

const TemplateForm = ({ setIsCreateTemplateMode, fetchTemplates }) => {
  const [loading, setLoading] = useState(false);
  const [newTemplate, setNewTemplate] = useState({ name: "", subject: "", body: "", active: true, type: "REPORTING" });

  async function saveTemplate() {
    try {
      setLoading(true);
      const { data, ok } = await api.post("/mail-template", newTemplate);
      if (!ok) return toast.error("Error saving template");
      toast.success("Template saved !");
      setNewTemplate({ name: "", subject: `Reporting of ${dayjs(new Date()).format("dddd DD MMMM YYYY")}`, body: "", active: true });
      setLoading(false);
      setIsCreateTemplateMode(false);
      fetchTemplates();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error saving template");
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div className={`w-full`}>
        <div className="px-1 text-sm text-gray-600 font-medium">Template name</div>
        <input
          label="Template name"
          className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
          name="templateName"
          value={newTemplate.name}
          onChange={(e) => setNewTemplate({ ...newTemplate, name: e.target.value })}
        />
      </div>
      <div className={`w-full`}>
        <div className="px-1 text-sm text-gray-600 font-medium">Subject</div>
        <input
          disabled={loading}
          className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
          name="subject"
          value={newTemplate.subject}
          onChange={(e) => setNewTemplate({ ...newTemplate, subject: e.target.value })}
        />
      </div>
      <div className="w-full">
        <div className="px-1 text-sm text-gray-600 font-medium">Message</div>
        <textarea
          disabled={loading}
          className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
          rows="12"
          name="description"
          value={newTemplate.body}
          onChange={(e) => setNewTemplate({ ...newTemplate, body: e.target.value })}
        />
      </div>

      <div>
        <div className="text-sm text-blue-500 font-medium">Add Variables using double curly braces. E.g.: {"{{PROJECT_NAME}} will be replaced by the project name"}</div>
        <div className="text-sm text-blue-500 font-medium">
          Available variables:
          <ul>
            <li>{"{{PROJECT_NAME}}"}</li>
            <li>{"{{CLIENT_NAME}}"}</li>
          </ul>
        </div>
      </div>

      <div className="flex gap-2 justify-end w-full mt-2">
        <button
          disabled={loading}
          className="ml-2 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-3 text-sm border border-blue-500 hover:border-transparent rounded flex gap-2 items-center disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-wait"
          onClick={() => setIsCreateTemplateMode(false)}>
          Cancel
        </button>
        <button
          disabled={loading}
          className="ml-2 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-3 text-sm border border-blue-500 hover:border-transparent rounded flex gap-2 items-center disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-wait"
          onClick={() => saveTemplate()}>
          Save Template
        </button>
      </div>
    </div>
  );
};
