import React, { useEffect, useState } from "react";

import api from "../../../services/api";

const Answers = ({ question }) => {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const { data } = await api.post("/answer/search", { question_id: question._id });
    setAnswers(data);
  };

  return (
    <div className="mt-2 flow-root">
      <div className="flex flex-wrap gap-2">
        <span className={`inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium "text-gray-600"`}>
          <div className={`h-2 w-2 rounded-full bg-gray-400`} />
          {answers?.length} questions sent
        </span>
        <span className={`inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium "text-green-600"`}>
          <div className={`h-2 w-2 rounded-full bg-green-400`} />
          {answers?.filter((a) => a.status === "done")?.length} answered
        </span>
        <span className={`inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium "text-yellow-600"`}>
          <div className={`h-2 w-2 rounded-full bg-yellow-400`} />
          {answers?.filter((a) => a.status === "pending")?.length} pending
        </span>
      </div>
      <div className="overflow-x-auto">
        <div className="bg-white inline-block min-w-full align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="text-left text-sm font-semibold text-gray-900">
              <tr>
                <th className="py-2 px-3">Status</th>
                <th className="py-2 px-3">User name</th>
                <th className="py-2 px-3">Question</th>
                <th className="py-2 px-3">Answer</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {answers.map((answer) => (
                <tr key={answer._id} className="even:bg-gray-100 whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 cursor-pointer">
                  <td className="py-1 px-3">
                    <Status status={answer.status} />
                  </td>
                  <td className="py-1 px-3">
                    <div className="flex items-center gap-1">
                      <img src={answer.user_avatar} alt="userlogo" className="rounded-full w-7 h-7" />
                      <div>{answer.user_name}</div>
                    </div>
                  </td>
                  <td className="py-1 px-3">{answer.question_title}</td>
                  <td className="py-1 px-3">{answer.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Status = ({ status }) => {
  let bgColor = "bg-gray-100";
  let textColor = "text-gray-600";
  let dotColor = "bg-gray-400";
  if (status === "pending") {
    bgColor = "bg-yellow-100";
    textColor = "text-yellow-600";
    dotColor = "bg-yellow-400";
  }
  if (status === "done") {
    bgColor = "bg-green-100";
    textColor = "text-green-600";
    dotColor = "bg-green-400";
  }
  return (
    <span className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}>
      <div className={`h-2 w-2 rounded-full ${dotColor}`} />
      {status}
    </span>
  );
};

export default Answers;
