import React, { useState, useEffect } from "react";
import { HiChevronLeft, HiChevronRight, HiOutlineFunnel } from "react-icons/hi2";
import moment from "moment";
import { NavLink, useNavigate, Routes, Route } from "react-router-dom";

import CalendarDaysOff from "./calendar";
import TableDaysOff from "./table";
import api from "../../services/api";
import MinSwitch from "../../components/MinSwitch";
import Modal from "../../components/modal";
import { CONTRACTS } from "../../constants";

// get dates for the month for a given date
function getDaysInMonth(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstOfMonth = new Date(year, month, 1);
  const lastOfMonth = new Date(year, month + 1, 0);
  const used = firstOfMonth.getDay() + lastOfMonth.getDate();
  const numberOfWeeks = Math.ceil(used / 7);
  const days = [];
  let dayCounter = 1 - firstOfMonth.getDay();
  for (let i = 0; i < numberOfWeeks; i++) {
    for (let j = 0; j < 7; j++) {
      const _date = new Date(year, month, dayCounter);
      days.push({
        dateString: `${_date.getFullYear()}-${+_date.getMonth() + 1}-${_date.getDate()}`,
        date: _date,
        isSameMonth: moment(date).isSame(_date, "month"),
        isToday: moment().isSame(_date, "day"),
      });
      dayCounter++;
    }
  }
  return days;
}

const Index = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [days, setDays] = useState();
  const [data, setData] = useState();
  const [modalFilterProjectOpen, setModalFilterProjectOpen] = useState(false);
  const [modalFilterContractOpen, setModalFilterContractOpen] = useState(false);
  const [filter, setFilter] = useState({
    projectName: ["Leave paid", "Unavailable", "RTT", "Congé sans solde", "Arret Maladie", "Absences autorisées", "Absence école", "Evenements familiaux"],
    users: [],
    userContract: [],
  });
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    if (!date) return;
    const _days = getDaysInMonth(date);
    setDays(_days);
  }, [date]);

  useEffect(() => {
    if (!days) return;
    let pathname = window.location.search;
    const a = new URLSearchParams(pathname);
    const params = Object.fromEntries(a);
    const paramsUser = params.users?.length ? params.users.split(",") : [];
    const paramsContract = params.userContract?.length ? params.userContract.split(",") : [];
    const paramsProjectName = params.projectName?.length
      ? params.projectName.split(",")
      : ["Leave paid", "Unavailable", "RTT", "Congé sans solde", "Arret Maladie", "Absences autorisées", "Absence école", "Evenements familiaux"];
    get({ days, filter: { projectName: paramsProjectName, users: paramsUser, userContract: paramsContract } });
  }, [days]);

  const get = async ({ days: _days = days, filter }) => {
    const tempFilter = { ...filter };
    const queryParameters = "?" + new URLSearchParams(tempFilter).toString();
    navigate({ pathname: window.location.pathname, search: queryParameters });
    setQuery(queryParameters);
    setFilter(tempFilter);
    const params = new URLSearchParams(tempFilter).toString();
    setLoading(true);

    const firstDayOfMonth = _days.find((d) => d.isSameMonth)?.date;
    const lastDayOfMonth = _days.reverse().find((d) => d.isSameMonth)?.date;
    const response = await api.get(`/activity?${params}&dateFrom=${firstDayOfMonth.getTime()}&dateTo=${lastDayOfMonth.getTime()}`);

    const _daysOff = response.data?.map((d) => {
      const user = { userId: d.userId, userAvatar: d.userAvatar, userName: d.userName, daysOff: [], projectName: d.projectName, userContract: d.userContract };
      d?.detail.forEach((e) => {
        const day = d?.detail.indexOf(e) + 1;
        const currentStream = user.daysOff.find((arr) => {
          return arr?.find((a) => a.day === day - 1) != undefined;
        });
        if (e.value > 0) {
          if (currentStream) currentStream.push({ day: day, hours: e.value });
          else user.daysOff.push([{ day: day, hours: e.value }]);
        }
      });
      return user;
    });
    setLoading(false);
    setData(_daysOff);
  };

  if (!days) return <div>loading days...</div>;

  return (
    <div className="flex flex-col">
      <header className="flex items-center justify-between px-6 lg:flex-none">
        <div className="flex flex-col lg:flex-row items-center gap-1">
          <h1 className="text-sm lg:text-lg font-semibold text-gray-900">Days off</h1>
          <div className="hidden lg:block w-px h-5 bg-gray-400 mx-2" />
          <HiOutlineFunnel className="hidden lg:block" />
          {filter?.projectName?.length ? (
            <button onClick={() => setModalFilterProjectOpen((e) => !e)} className="text-xs text-gray-500 rounded-full px-1.5 py-0.5 hover:underline">
              {filter?.projectName?.length} projects
            </button>
          ) : null}
          <button onClick={() => setModalFilterContractOpen((e) => !e)} className="text-xs text-gray-500 rounded-full px-1.5 py-0.5 hover:underline">
            {filter?.userContract?.length || "All"} Contracts
          </button>

          <Allusers filter={filter} get={get} />
          {loading ? <i>Loading...</i> : null}
        </div>

        <div className="flex items-center">
          <div className="mr-3 text-gray-600 font-normal">{moment(date).format("MMMM YYYY")}</div>
          <div className="flex items-center rounded-md shadow-sm md:items-stretch">
            <button
              onClick={() => {
                setData(null);
                setDate(moment(date).subtract(1, "month").toDate());
              }}
              type="button"
              className="p-2 flex items-center justify-center rounded-l-md border-[1px] border-r-0 border-gray-300 bg-[#ffffff] text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:hover:bg-gray-50">
              <HiChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={() => {
                setData(null);
                setDate(new Date());
              }}
              type="button"
              className="hidden border-t-[1px] border-b border-gray-300 bg-[#ffffff] px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block">
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              onClick={() => {
                setData(null);
                setDate(moment(date).add(1, "month").toDate());
              }}
              type="button"
              className="p-2 flex items-center justify-center rounded-r-md border-[1px] border-l-0 border-gray-300 bg-[#ffffff] text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:hover:bg-gray-50">
              <span className="sr-only">Next month</span>
              <HiChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </header>
      <Modal isOpen={modalFilterProjectOpen} onClose={() => setModalFilterProjectOpen(false)}>
        <div className="flex flex-col gap-2 p-3 pt-5">
          {["Leave paid", "Unavailable", "RTT", "Absence école", "Absences autorisées", "Arret Maladie", "Evenements familiaux", "Congé sans solde"].map((project) => {
            return (
              <div key={project} className="flex gap-1 items-center">
                <MinSwitch
                  checked={filter.projectName.includes(project)}
                  onChange={() => {
                    if (!filter.projectName.includes(project)) get({ filter: { ...filter, projectName: [...filter.projectName, project] } });
                    else
                      get({
                        filter: {
                          ...filter,
                          projectName: filter.projectName.filter((e) => e !== project),
                        },
                      });
                  }}
                />
                <button
                  onClick={() => {
                    if (!filter.projectName.includes(project)) get({ filter: { ...filter, projectName: [...filter.projectName, project] } });
                    else get({ filter: { ...filter, projectName: filter.projectName.filter((e) => e !== project) } });
                  }}
                  className="text-xs text-gray-700">
                  {project}
                </button>
              </div>
            );
          })}
        </div>
      </Modal>
      <Modal isOpen={modalFilterContractOpen} onClose={() => setModalFilterContractOpen(false)}>
        <div className="flex flex-col gap-2 p-3 pt-5">
          {Object.keys(CONTRACTS).map((c) => {
            return (
              <div key={c} className="flex gap-1 items-center">
                <MinSwitch
                  checked={filter.userContract.includes(c)}
                  onChange={() => {
                    if (!filter.userContract.includes(c)) get({ filter: { ...filter, userContract: [...filter.userContract, c] } });
                    else get({ filter: { ...filter, userContract: filter.userContract.filter((e) => e !== c) } });
                  }}
                />
                <button
                  onClick={() => {
                    if (!filter.userContract.includes(c)) get({ filter: { ...filter, userContract: [...filter.userContract, c] } });
                    else get({ filter: { ...filter, userContract: filter.userContract.filter((e) => e !== c) } });
                  }}
                  className="text-xs text-gray-700">
                  {c}
                </button>
              </div>
            );
          })}
        </div>
      </Modal>
      <Router filter={filter} data={data} days={days} query={query} />
    </div>
  );
};

const Allusers = ({ filter, get }) => {
  const [users, setUsers] = useState(null);
  const [open, seOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await api.post("/user/search", {});
      setUsers(data.users);
    })();
  }, []);

  if (!users) return null;

  console.log(users);

  return (
    <>
      <button onClick={() => seOpen((state) => !state)} className="text-xs text-gray-500 rounded-full px-1.5 py-0.5 hover:underline">
        {filter?.users?.length || "All"} people
      </button>
      <Modal isOpen={open} onClose={() => seOpen(false)} className="m-3">
        <div className="flex flex-col gap-2 p-3">
          <div className="flex items-center gap-2 p-1">
            <button onClick={() => get({ filter: { ...filter, users: [] } })} className="text-xs text-gray-700 hover:underline">
              Clear all
            </button>
            <button
              onClick={() => {
                get({ filter: { ...filter, users: users.map((e) => e._id.toString()) } });
              }}
              className="text-xs text-gray-700 hover:underline">
              Select all
            </button>
          </div>
          <div className="grid grid-cols-3 gap-2">
            {users
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((user) => (
                <div key={user._id} className="flex items-center gap-2">
                  <MinSwitch
                    checked={filter.users.includes(user._id)}
                    onChange={() => {
                      if (!filter.users.includes(user._id)) get({ filter: { ...filter, users: [...filter.users, user._id] } });
                      else get({ filter: { ...filter, users: filter.users.filter((e) => e !== user._id) } });
                    }}
                  />
                  <button
                    onClick={() => {
                      if (!filter.users.includes(user._id)) get({ filter: { ...filter, users: [...filter.users, user._id] } });
                      else get({ filter: { ...filter, users: filter.users.filter((e) => e !== user._id) } });
                    }}
                    className="text-xs text-gray-700">
                    {user.name}
                  </button>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

const Router = ({ filter, data, days, query }) => {
  return (
    <>
      <nav className="flex flex-1">
        <TabItem title="Calendar" to={`/days-off/calendar${query}`} />
        <TabItem title="Table" to={`/days-off/table${query}`} />
      </nav>
      <div className="bg-white pt-4">
        <Routes>
          <Route path="/calendar" element={<CalendarDaysOff filter={filter} data={data} days={days} />} />
          <Route path="/table" element={<TableDaysOff filter={filter} data={data} date={days.find((day) => day.isSameMonth == true).dateString} />} />
        </Routes>
      </div>
    </>
  );
};

const TabItem = ({ to, title }) => (
  <NavLink
    to={to}
    activeClassName="text-black bg-white border-none"
    className="text-[13px] px-3 py-2 cursor-pointer text-gray-600 bg-gray-50 border-t-[1px] border-r-[1px] border-l-[1px] border-gray-200 hover:text-black">
    <div className="flex items-center gap-2">{title}</div>
  </NavLink>
);

export default Index;
