import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { FaChevronUp, FaChevronDown, FaTrash, FaStar } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

import SelectUser from "../../../../components/selectUser";
import moment from "moment";

export default ({ quote, setQuote }) => {
  const [values, setValues] = useState(quote);
  const [comment, setComment] = useState("");
  const { user } = useSelector((state) => state.Auth);
  const [rating, setRating] = useState(quote.score || 0);
  const [hover, setHover] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (comment.trim()) {
      try {
        const updatedComments = [...values.comments, { text: comment, user_name: user.name, user_avatar: user.avatar, created_at: new Date() }];
        const { data } = await api.put(`/quote/${quote._id}`, { ...values, comments: updatedComments });
        setQuote(data);
        setValues(data);
        setComment("");
        toast.success("Quote updated");
      } catch (error) {
        console.error(error);
        toast.error("Error updating quote");
      }
    } else {
      toast.error("Comment cannot be empty!");
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const updatedComments = values.comments.filter((comment) => comment._id !== commentId);
      const { data } = await api.put(`/quote/${quote._id}`, { ...values, comments: updatedComments });
      setQuote(data);
      setValues(data);
      toast.success("Comment deleted");
    } catch (error) {
      console.error(error);
      toast.error("Error deleting comment");
    }
  };

  const handleRatingChange = async (newRating) => {
    setRating(newRating);
    try {
      const scoreChangeComment = {
        text: `Changed the score ${rating} → ${newRating}`,
        user_name: user.name,
        user_avatar: user.avatar,
        created_at: new Date(),
      };

      const updatedComments = [...values.comments, scoreChangeComment];
      const { data } = await api.put(`/quote/${quote._id}`, { ...values, score: newRating, comments: updatedComments });

      setValues(data);
      setQuote(data);
      toast.success("Score updated and logged in comments");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update score");
    }
  };

  return (
    <>
      <div className=" py-2 mb-2">
        <div className="space-y-2 flex items-center justify-center">
          <label
            className="group text-lg font-semibold cursor-pointer flex text-center"
            htmlFor="score"
            onClick={() => handleRatingChange(0)}
            onMouseEnter={(e) => (e.target.textContent = "Reset")}
            onMouseLeave={(e) => (e.target.textContent = "Score")}>
            Score
          </label>
        </div>
        <div id="score" className="flex space-x-1 justify-center py-1">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              className="focus:outline-none"
              onClick={() => handleRatingChange(star)}
              onMouseEnter={() => setHover(star)}
              onMouseLeave={() => setHover(null)}>
              <FaStar size={24} color={star <= (hover || rating) ? "#ffc107" : "#e4e5e9"} />
            </button>
          ))}
        </div>
      </div>
      <hr />
      <div className="p-2 border-b border-gray-300 text-center">
        <label className="text-lg font-semibold">Comment</label>
      </div>
      <div className="flex-grow-0">
        <div className="flex flex-col gap-y-1 mb-2">
          {(values.comments || []).map((comment, index) => (
            <div key={comment._id || index} className="flex items-center bg-white p-2 rounded-md">
              <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4" alt="User Avatar" />
              <div>
                <div className="flex items-center gap-2 text-xs font-semibold">
                  <div>{comment.user_name}</div>
                  <div className="font-normal text-gray-500" data-tip data-for={`comment-${comment._id}`}>
                    {moment(comment.created_at).fromNow()}
                  </div>
                  <ReactTooltip id={`comment-${comment._id}`}>
                    <span>{new Date(comment.created_at).toLocaleString()}</span>
                  </ReactTooltip>
                </div>
                <div>{comment.text}</div>
              </div>
              <div className="flex-grow" />
              <div>
                <div
                  className="text-xs text-gray-500 ml-4 cursor-pointer border border-gray-300 rounded-md p-1 hover:bg-gray-100"
                  onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      handleDelete(comment._id);
                    }
                  }}>
                  <FaTrash />
                </div>
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mt-2 gap-1 justify-between mx-1">
            <input type="text" className="w-[85%] projectsInput m-0" placeholder="Add a comment" value={comment} onChange={(e) => setComment(e.target.value)} />
            <button className="btn btn-primary">Add</button>
          </div>
        </form>
      </div>
    </>
  );
};

