import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextEditor from "../../../../components/TextEditor";
import { readFileAsync } from "../../../../utils";
import { IoEyeOutline } from "react-icons/io5";

export default ({ step, workshop, onUpdate, onRemove }) => {
  const [values, setValues] = useState(step);

  useEffect(() => {
    setValues(step);
  }, [step]);

  if (!values) return <div />;

  return (
    <div>
      <div className="grid grid-cols-3 gap-4 mb-4">
        <div>
          <div className="px-1 text-sm text-gray-600 font-medium">Title</div>
          <input
            className="w-full rounded-md border-[1px] bg-white disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={values.title}
            onChange={(e) => setValues({ ...values, title: e.target.value })}
          />
        </div>
        <div>
          <div className="px-1 text-sm text-gray-600 font-medium">Position</div>
          <input
            className="w-full rounded-md border-[1px] bg-white disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={values.index}
            type="number"
            onChange={(e) => setValues({ ...values, index: e.target.value })}
          />
        </div>
        <div className="grid grid-cols-3 gap-2">
          <Link
            to={`/learn/${workshop._id}?index=${step.index + 1}`}
            className="flex items-center gap-2 border border-gray-300 text-sm font-medium text-black py-2 px-4 rounded-md hover:border-gray-400">
            <IoEyeOutline />
            Preview
          </Link>
          <button
            className="bg-primary text-sm font-medium text-white py-2 px-4 rounded-md hover:bg-primary-500"
            onClick={() => {
              onUpdate(values);
            }}>
            Save
          </button>
          <button className="bg-red-600 text-sm font-medium text-white py-2 px-4 rounded-md hover:bg-red-500" onClick={onRemove}>
            Delete
          </button>
        </div>
      </div>
      <Elements
        values={values.elements}
        setValues={(elements) => {
          console.log("elements", elements);
          setValues({ ...values, elements });
        }}
      />
    </div>
  );
};

const Elements = ({ values, setValues }) => {
  console.log("🚀 ~ Elements ~ values:", values);
  const onUpdate = (index, value) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
  };

  const onRemove = (index) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  return (
    <div className="mt-4 grid grid-cols-2 gap-4">
      {values.map((element, index) => (
        <div key={element._id} className="flex flex-col gap-4">
          {element.kind === "code" && <Code value={element.value} setValue={(value) => onUpdate(index, { ...element, value })} onRemove={() => onRemove(index)} />}
          {element.kind === "image" && <Image value={element.value} setValue={(value) => onUpdate(index, { ...element, value })} onRemove={() => onRemove(index)} />}
          {element.kind === "text" && <Text value={element.value} setValue={(value) => onUpdate(index, { ...element, value })} onRemove={() => onRemove(index)} />}
          {element.kind === "video" && <Video value={element.value} setValue={(value) => onUpdate(index, { ...element, value })} onRemove={() => onRemove(index)} />}
          {element.kind === "question" && <Questions values={element.value} onChange={(value) => onUpdate(index, { ...element, value })} onRemove={() => onRemove(index)} />}
        </div>
      ))}
      <div className="space-x-2">
        <button className="btn btn-primary" onClick={() => setValues([...values, { value: "", kind: "text" }])}>
          Add text
        </button>
        <button className="btn btn-primary" onClick={() => setValues([...values, { value: "", kind: "image" }])}>
          Add image
        </button>
        <button className="btn btn-primary" onClick={() => setValues([...values, { value: "", kind: "code" }])}>
          Add code
        </button>
        <button className="btn btn-primary" onClick={() => setValues([...values, { value: "", kind: "video" }])}>
          Add video
        </button>
        <button className="btn btn-primary" onClick={() => setValues([...values, { value: { question: "", answers: [] }, kind: "question" }])}>
          Add question
        </button>
      </div>
    </div>
  );
};

const Code = ({ value, setValue, onRemove }) => {
  return (
    <div>
      <div className="px-1 text-sm text-gray-600 font-medium flex justify-between">
        <div>Code</div>
        <button onClick={onRemove}>Remove</button>
      </div>
      <textarea
        placeholder="Code"
        rows="10"
        className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

const Text = ({ value, setValue, onRemove }) => {
  return (
    <div>
      <div className="px-1 text-sm text-gray-600 font-medium flex justify-between">
        <div>Text</div>
        <button onClick={onRemove}>Remove</button>
      </div>
      <TextEditor value={value} onChange={(e) => setValue(e.target.value)} />
    </div>
  );
};

const Video = ({ value, setValue, onRemove }) => {
  return (
    <div>
      <div className="px-1 text-sm text-gray-600 font-medium flex justify-between">
        <div>Video</div>
        <button onClick={onRemove}>Remove</button>
      </div>
      <div className="flex justify-between max-w-md py-6">
        {value && (
          <video className="w-[260px] rounded-[10px]" controls>
            <source src={value} type="video/mp4" />
          </video>
        )}
        <label htmlFor="openFile" className="flex items-center cursor-pointer text-[12px] text-[#676D7C] font-normal">
          Change or upload a video
        </label>
        <input
          id="openFile"
          type="file"
          onChange={async (e) => {
            const f = e.target.files[0];
            const rawBody = await readFileAsync(f);
            setValue({ rawBody, name: f.name });
          }}
        />
        {value && (
          <div
            className="underline text-sm cursor-pointer"
            onClick={() => {
              if (confirm("Are you sure you want to remove this video?")) {
                setValue("");
              }
            }}>
            Remove video
          </div>
        )}
      </div>
    </div>
  );
};

const Image = ({ value, setValue, onRemove }) => {
  return (
    <div>
      <div className="px-1 text-sm text-gray-600 font-medium flex justify-between">
        <div>Image</div>
        <button onClick={onRemove}>Remove</button>
      </div>
      <div className="flex justify-between max-w-md py-6">
        {/* @todo <FileInput value={value} onChange={setValue} folder={`/workshop`}  /> */}
        {value && <img className="w-[80px] h-[80px] rounded-[10px]" src={value.rawBody || value} alt="user.png" />}
        <label htmlFor="openFile" className="flex items-center cursor-pointer text-[12px] text-[#676D7C] font-normal">
          Change or upload a picture
        </label>
        <input
          id="openFile"
          type="file"
          onChange={async (e) => {
            const f = e.target.files[0];
            const rawBody = await readFileAsync(f);
            setValue({ rawBody, name: f.name });
          }}
        />
        {value && (
          <div
            className="underline text-sm cursor-pointer"
            onClick={() => {
              if (confirm("Are you sure you want to remove this image?")) {
                setValue("");
              }
            }}>
            Remove image
          </div>
        )}
      </div>
    </div>
  );
};

const Questions = ({ values = { question: "", answers: [], key: "" }, onChange, onRemove }) => {
  const updateQuestion = (question) => {
    onChange({ ...values, question, key: generateShortId() });
  };

  const updateAnswer = (index, updatedAnswer) => {
    const newAnswers = [...values.answers];
    newAnswers[index] = updatedAnswer;
    onChange({ ...values, answers: newAnswers });
  };

  const addAnswer = () => {
    onChange({
      ...values,
      answers: [...values.answers, { answer: "", isCorrect: false, key: generateShortId(), explanation: "" }],
    });
  };

  const removeAnswer = (index) => {
    const newAnswers = [...values.answers];
    newAnswers.splice(index, 1);
    onChange({ ...values, answers: newAnswers });
  };

  return (
    <div className="mt-6">
      <div className="px-1 text-sm text-gray-600 font-medium flex justify-between">
        <div>Question</div>
        <button onClick={onRemove}>Remove</button>
      </div>

      <textarea
        placeholder="Question"
        className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
        value={values.question}
        onChange={(e) => updateQuestion(e.target.value)}
      />

      {values.answers.map((question, index) => (
        <div className="mb-2 w-full flex flex-col gap-4" key={question.key}>
          <textarea
            placeholder="Answer"
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={question.answer}
            onChange={(e) => updateAnswer(index, { ...question, answer: e.target.value })}
          />
          <textarea
            placeholder="Answer explanation"
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={question.explanation}
            onChange={(e) => updateAnswer(index, { ...question, explanation: e.target.value })}
          />
          <div className="flex items-center justify-between mt-3">
            <label className="flex items-center gap-2 text-sm font-medium">
              <input type="checkbox" checked={question.isCorrect} onChange={(e) => updateAnswer(index, { ...question, isCorrect: e.target.checked })} />
              <span>Is correct?</span>
            </label>
            <button className="bg-red-500 text-sm font-medium text-white py-2 px-5 rounded-lg" onClick={() => removeAnswer(index)}>
              Remove
            </button>
          </div>
        </div>
      ))}

      <div className="border-b border-gray-300 pt-2 mt-4 pb-4">
        <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={addAnswer}>
          Add Answer
        </button>
      </div>
    </div>
  );
};

function generateShortId() {
  return Math.random().toString(36).substr(2, 9);
}
