import React, { useState } from "react";
import toast from "react-hot-toast";
import { IoMdDocument } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";

import TextEditor from "../../../components/TextEditor";
import FileInput from "../../../components/FileInput";

import api from "../../../services/api";

export default ({ contact, setContact }) => {
  const [values, setValues] = useState(contact);

  async function onSubmit(e) {
    try {
      e.preventDefault();
      const { data, ok } = await api.put(`/folk/${contact._id}`, values);
      if (!ok) return toast.error("Error");
      setContact(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  }
  console.log("values", contact)

  return (
    <div className="grid grid-cols-3 space-x-5">
      <form onSubmit={onSubmit} className="col-span-2">
        <div className="p-3 !pt-0 ">
          <div className="px-1 text-sm text-gray-600 font-medium">Notes</div>
          <TextEditor value={values.notes} onChange={e => setValues({ ...values, notes: e.target.value })} />
        </div>
        <div className="p-3 !pt-0 flex">
          <button className="bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onSubmit}>
            Update
          </button>
        </div>
      </form>
      <div className="">
        <div className="px-1 text-sm text-gray-600 font-medium">Files</div>
        {values.files.map((file, index) => (
          <div
            key={file}
            className="flex gap-1 mb-1 items-center justify-between text-sm rounded bg-white py-2 px-5 border border-gray-300 text-gray-700 hover:underline cursor-pointer">
            <a className="flex gap-1 items-center" href={file} target="_blank" rel="noreferrer">
              <IoMdDocument />
              {file.split("/").pop()}
            </a>
            <div className="flex justify-center cursor-pointer text-xl hover:text-red-500 ml-2">
              <MdDeleteForever
                onClick={async () => {
                  if(confirm("Are you sure?") === false) return;
                  const newValue = values.files.filter((_, i) => i !== index);
                  const { data } = await api.put(`/folk/${contact._id}`, { files: newValue });
                  setValues({ ...values, files: data.files });
                  toast.success("Updated!");
                }}
              />
            </div>
          </div>
        ))}
        <FileInput
          className="w-full  h-16 mt-5"
          allowMultiple
          name="files"
          onChange={async (e) => {
            const { data } = await api.put(`/folk/${contact._id}`, { files: [...values.files, ...e.target.value] });
            setValues({ ...values, files: data.files });
            toast.success("Updated!");
          }}
          folder={`/folk`}
        />
      </div>
    </div>
  );
};
