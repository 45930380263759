import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import MultiSelect from "../../../components/MultiSelect";
import Loader from "../../../components/loader";

export default ({ values, onChange }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const { data } = await api.post("/project/search", { status: "active" });

    const arr = data
      .sort(function (a, b) {
        if(!a) return -1;
        if(!b) return 1;
        
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
        return 0;
      })
      .map((j) => ({ value: j._id, label: j.name }));

    setProjects(arr);
  }

  if (!projects?.length) return <Loader size="small" />;

  return <MultiSelect id="select-projects" options={projects} values={values} onSelectedChange={onChange} placeholder="Select a project" />;
};
