import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../services/api";
import FileInput from "../../../components/FileInput";

const Edit = ({ perk, setPerk }) => {
  const navigate = useNavigate();

  const handleDelete = async () => {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/perk/${perk._id}`);
    toast.success("successfully removed!");
    navigate("/perks");
  };

  const handleSubmit = async () => {
    try {
      const { ok, data } = await api.put(`/perk/${perk._id}`, perk);
      if (!ok) throw new Error("Error while updating the perk");
      setPerk(data);
      toast.success("Updated !");
    } catch (error) {
      console.error(error);
      toast.error("Error while updating the perk");
    }
  };

  if (!perk) return <div />;

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-4 p-2">
        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Logo</label>
          <FileInput value={perk.logo} name="logo" folder="/logo" onChange={(e) => setPerk({ ...perk, logo: e.target.value[0] })} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Name</label>
          <input type="text" className="input" placeholder="Name" value={perk.name} onChange={(e) => setPerk({ ...perk, name: e.target.value })} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Status</label>
          <select className="input" value={perk.status} onChange={(e) => setPerk({ ...perk, status: e.target.value })}>
            <option value="">Select status</option>
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Value</label>
          <input type="text" className="input" value={perk.value} onChange={(e) => setPerk({ ...perk, value: e.target.value })} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Link</label>
          <input type="text" className="input" value={perk.link} onChange={(e) => setPerk({ ...perk, link: e.target.value })} />
        </div>
        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Description</label>
          <textarea type="text" className="input" placeholder="Description" value={perk.description} rows={5} onChange={(e) => setPerk({ ...perk, description: e.target.value })} />
        </div>
        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Activation</label>
          <textarea type="text" className="input" placeholder="Activation" value={perk.activation} rows={5} onChange={(e) => setPerk({ ...perk, activation: e.target.value })} />
        </div>
      </div>
      <div className="flex gap-4 justify-end">
        <button className="red-btn" onClick={handleDelete}>
          Delete
        </button>
        <button className="blue-btn" onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
};

export default Edit;
