import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import Loader from "../../components/loader";

import api from "../../services/api";

const DebateList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function getData() {
    setLoading(true);
    const { data, ok } = await api.get("/debate");
    console.log("data", data);
    if (!ok) return toast.error("Error getting debates");
    setData(data);
    setLoading(false);
  }
  useEffect(() => {
    getData();
  }, []);

  if (loading) return <Loader />;
  if (!data) return <Loader />;

  return (
    <div>
      <div className="m-4">
        <div className="flex items-center justify-between gap-1">
          <Create />
        </div>

        <div className="mt-4">
          {data.map((item) => (
            <Item key={item._id} data={item} />
          ))}

          {data.length === 0 ? <div className="text-center text-[#000000] text-[16px] font-medium mt-[50px]">No debates found</div> : null}
        </div>
      </div>
    </div>
  );
};

const Item = ({ data }) => {
  async function onClick() {
    window.open(`/debate/${data._id}`, "_blank");
  }

  return (
    <div className="flex items-center p-4 bg-white shadow-lg rounded-lg cursor-pointer mt-2" onClick={onClick}>
      <img src={data.user_avatar} alt={`${data.user_name}'s avatar`} className="w-12 h-12 rounded-full mr-4" />
      <div>
        <div className="font-bold text-lg">{data.name}</div>
        <div className="text-gray-700">{data.user_name}</div>
        <div className="text-gray-500 text-sm mt-1">{data.description}</div>
      </div>
    </div>
  );
};
const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  async function onCreate() {
    const { data, ok } = await api.post("/debate", values);
    if (!ok) return toast.error("Error creating debate");
    toast.success("Debate created");
    setOpen(false);
    navigate(`/debate/${data._id}`);
  }

  return (
    <>
      <div className="text-right">
        <button className="btn btn-primary" onClick={() => setOpen(true)}>
          Start a war
        </button>
      </div>
      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div
            className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <label className="text-[#000000] text-[16px] font-medium">Name</label>

            <input
              className="mt-[1rem] border border-[#E5E5E5] rounded-[10px] w-full h-[40px] px-[15px] text-[16px] font-medium"
              placeholder="Name"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />

            <React.Fragment>
              <button className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onCreate}>
                Create
              </button>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DebateList;
