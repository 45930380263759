import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa6";
import toast from "react-hot-toast";

import api from "../../../services/api";

const Question = ({ job, setJob }) => {
  const [question, setQuestion] = useState("");

  const handleAddQuestion = () => {
    if (question.trim()) {
      const updatedQuestions = [...(job.questions || []), { text: question }];
      setJob({ ...job, questions: updatedQuestions });
      setQuestion("");
    }
  };

  const handleEditQuestion = (index, text) => {
    const updatedQuestions = [...job.questions];
    updatedQuestions[index].text = text;
    setJob({ ...job, questions: updatedQuestions });
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = job.questions.filter((_, i) => i !== index);
    setJob({ ...job, questions: updatedQuestions });
  };

  const handleSave = async () => {
    const { ok, data, code } = await api.put(`/job/${job._id}`, job);
    if (!ok) return toast.error(code, "Error saving questions!");

    setJob(data);
    toast.success("Questions saved successfully!");
  };

  return (
    <div className="py-5">
      <h4 className="font-semibold mb-3">Questions</h4>

      <div className="flex flex-col gap-3">
        {job.questions?.map((question, index) => (
          <div key={index} className="flex items-center gap-2">
            <input className="rounded-md text-sm flex-grow" type="text" value={question.text} onChange={(e) => handleEditQuestion(index, e.target.value)} />
            <button className="w-6 h-6 text-gray-600" onClick={() => handleDeleteQuestion(index)}>
              <FaTrash />
            </button>
          </div>
        ))}

        <div>
          <p className="font-semibold mt-5 mb-2">Add new question</p>

          <div className="flex gap-3 items-center">
            <input className="text-sm rounded-md flex-grow" type="text" value={question} onChange={(e) => setQuestion(e.target.value)} placeholder="Add new question" />
            <button onClick={handleAddQuestion} className="w-6 h-6 border-black border flex justify-center items-center rounded-md">
              <FaPlus className="text-xs" />
            </button>
          </div>
        </div>
      </div>

      <button className="bg-blue-700 text-white px-5 py-2 rounded-lg float-end mt-10" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default Question;
