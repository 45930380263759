import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../services/api";
import Info from "./Info";
import Send from "./Send";
import Answers from "./Answers";
import Raw from "./Raw";

export default () => {
  const { id } = useParams();
  const [question, setQuestion] = useState();
  const [tab, setTab] = useState("info");

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/question/${id}`);
      setQuestion(data);
    })();
  }, [id]);

  if (!question) return <div />;

  return (
    <div className="max-w-5xl space-y-2 p-4">
      <div className="text-sm font-semibold text-sky-700">{question.title}</div>
      <nav className="bg-white rounded-md flex item-center gap-2 w-full border border-neutral-300 overflow-hidden p-1">
        <TabItem tab="info" title="Info" setTab={setTab} active={tab === "info"} />
        <TabItem tab="send" title="Send" setTab={setTab} active={tab === "send"} />
        <TabItem tab="answers" title="Answers" setTab={setTab} active={tab === "answers"} />
        <TabItem tab="raw" title="Raw" setTab={setTab} active={tab === "raw"} />
      </nav>

      {tab === "info" && <Info question={question} setQuestion={setQuestion} setTab={setTab} />}
      {tab === "send" && <Send question={question} />}
      {tab === "answers" && <Answers question={question} />}
      {tab === "raw" && <Raw question={question} />}
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active }) => (
  <button onClick={() => setTab(tab)} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
