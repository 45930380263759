import React, { useEffect, useState } from "react";
import api from "../../services/api";

import Table from "../../components/Table";
import Paginator from "../../components/Paginator";
import FolkModal from "./folkModal";

export default () => {
  const [folks, setFolks] = useState([]);
  const [selectedFolk, setSelectedfolk] = useState(null);
  const [filters, setFilters] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = { type: "CONQUEST", ...filters };
    const { data, total } = await api.post("/folk/search", newFilters);
    setFolks(data);
    setTotal(total);
    setLoading(false);
  }

  return (
    <div className="bg-white h-full">
      <Search setFilters={setFilters} filters={filters} />
      <FolkModal onClose={() => setSelectedfolk(null)} contact={selectedFolk} />
      <div>{total} items</div>
      <Table
        total={total}
        header={[
          { title: "First Name", key: "first_name" },
          { title: "Last Name", key: "last_name" },
          { title: "Company", key: "company" },
          { title: "Owner", key: "owner" },
          { title: "Created At", key: "status_created_date" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {folks.map((item, index) => {
          return (
            <tr className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`} key={item._id} onClick={() => setSelectedfolk(item)}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.first_name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.last_name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.company}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <img src={item.user_avatar} className="w-6 h-6 rounded-full" alt="avatar" />
                <span className="ml-2">{item.user_name}</span>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.status_created_date}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");

  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);

  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};
