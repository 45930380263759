import React from "react";
import _ from "lodash";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "Septembe", "October", "November", "December"];

const TransactionTab = ({ invoice }) => {
  return (
    <div className="flex flex-col gap-10">
      <div className="flex-1">
        <h2 className="mb-2 text-gray-800">Transactions received</h2>
        <div className="flex items-center gap-2 p-6 border border-gray-300 rounded">
          <ReceivedTable invoice={invoice} />
        </div>
      </div>
      <div className="flex-1">
        <h2 className="mb-2 text-gray-800">Transactions sent</h2>
        <div className="flex items-center gap-2 p-6 border border-gray-300 rounded">
          <SentTable invoice={invoice} />
        </div>
      </div>
    </div>
  );
};

const ReceivedTable = ({ invoice }) => {
  const totalPrice = invoice.banks.reduce((acc, e) => acc + (e.amount || 0), 0);

  const buildDate = (date) => {
    const d = new Date(date);
    return `${MONTHS[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  };

  return (
    <div className="w-full">
      <table className="table-fixed">
        <thead className="border-collapse border-b border-gray-300">
          <tr>
            <th colSpan={2} className="font-normal text-sm p-2">
              Name
            </th>
            <th className="font-normal text-sm p-2">Date</th>
            <th className="text-right font-normal text-sm p-2">Amount</th>
          </tr>
        </thead>
        <tbody className="border-collapse border-none">
          {invoice.banks.length > 0 ? (
            invoice.banks.map((item, i) => (
              <tr key={i} className="border-none">
                <td colSpan={2} className="p-2 text-sm">
                  {item.name}
                </td>
                <td className="p-2 text-sm">{buildDate(item.date)}</td>
                <td className="text-right p-2 text-sm">{(item.amount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="p-2 text-sm text-center text-gray-400">
                No transaction found
              </td>
            </tr>
          )}
        </tbody>
        <tfoot className="border-collapse border-t border-gray-300">
          <tr>
            <td className="p-2 text-sm font-semibold">Total</td>
            <td colSpan={2} />

            <td className="text-right p-2 text-sm font-semibold">{totalPrice.toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const SentTable = ({ invoice }) => {
  const totalPrice = invoice.itemsCompta.reduce((acc, e) => acc + (e.totalCost || 0), 0);

  return (
    <div className="w-full">
      <table className="table-fixed">
        <thead className="border-collapse border-b border-gray-300">
          <tr>
            <th colSpan={2} className="font-normal text-sm p-2">
              Name
            </th>
            <th className="font-normal text-sm p-2">Description</th>
            <th className="text-right font-normal text-sm p-2">Amount</th>
          </tr>
        </thead>
        <tbody className="border-collapse border-none">
          {invoice.itemsCompta.length > 0 ? (
            invoice.itemsCompta.map((item, i) => {
              return (
                <tr key={i} className="border-none">
                  <td colSpan={2} className="p-2 text-sm">
                    {item.name}
                  </td>
                  <td className="p-2 text-sm">{item.name}</td>
                  <td className="text-right p-2 text-sm">{(item.totalCost || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="p-2 text-sm text-center text-gray-400">No transaction found</td>
            </tr>
          )}
        </tbody>
        <tfoot className="border-collapse border-t border-gray-300">
          <tr>
            <td className="p-2 text-sm font-semibold">Total</td>
            <td colSpan={2} />

            <td className="text-right p-2 text-sm font-semibold">{totalPrice.toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TransactionTab;
