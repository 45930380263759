import React, { useState } from "react";

import { CONTRACTS, DIVISION, LOCATION, ROLES, YEARSSCHOOL, SCHOOL, MANAGEMENT, PRICING, PRICING_START } from "./constants";
import { dutchInfo, frenchInfo, spanishInfo, freelanceInfo } from "./salary";

export default ({ user, salary }) => {
  const [sellTest, setSellTest] = useState();

  if (!user.position) return <div />;

  let sellPerDay = getPricing(user);

  let elements = [];

  if ([CONTRACTS.FREELANCE].includes(user.contract)) {
    elements = freelanceInfo(user);
  } else {
    if ([DIVISION.LE_COLLECTIF, DIVISION.SELEGO_FR].includes(user.division)) {
      elements = frenchInfo(user, salary);
    } if ([DIVISION.SELEGO_NL].includes(user.division)) {
        elements = dutchInfo(user, salary);
    } if ([DIVISION.SELESPAGNE].includes(user.division)) {
        elements = spanishInfo(user, salary);
    }
  }

  let costPerDayExpenses = getCostExpenses(elements, user);
  let costPerDaySalary = getCostSalary(elements, user);
  let costPerDay = costPerDayExpenses + costPerDaySalary;


  let marginTheoretical = (((sellPerDay - costPerDay) / sellPerDay) * 100).toFixed(2);
  let marginTest = (((sellTest - costPerDay) / sellTest) * 100).toFixed(2);

  return (
    <div className="w-full">
      <label className="font-bold">Sell & margin</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md flex flex-col">
        <div className="mt-2">
          <div className="flex flex-row justify-between py-1">
            <div>
              <label>Total cost per day</label>
            </div>
            <div>{costPerDay}€</div>
          </div>
          <div className="flex flex-row justify-between py-1 text italic text-sm">
            <div>
              <label>Including costs due to salary</label>
            </div>
            <div>{costPerDaySalary}€</div>
          </div>
          <div className="flex flex-row justify-between py-1 text italic text-sm">
            <div>
              <label>Including cost due to fixed costs</label>
            </div>
            <div>{costPerDayExpenses}€</div>
          </div>
        </div>
        <div className="flex flex-row justify-between py-1">
          <div>
            <label>Price based on benchmark</label>
          </div>
          <div>{sellPerDay}€</div>
        </div>
        <div className="flex flex-row justify-between py-1">
          <div>
            <label>Margin based on price from benchmark</label>
          </div>
          <div>{marginTheoretical}%</div>
        </div>
        <div className="flex flex-row justify-between py-1">
          <div>
            <label>Other TJM</label>
          </div>
          <input
            className="block w-1/3 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
            name="sellTest"
            type="number"
            value={sellTest}
            onChange={(e) => setSellTest(e.target.value)}
          />
        </div>
        <div className="flex flex-row justify-between py-1">
          <div>
            <label>Margin if other TJM</label>
          </div>
          <div>{marginTest}%</div>
        </div>
      </div>
    </div>
  );
};

function getPricing(user) {
  let sellPerDay = 0;

  if (user.contract == CONTRACTS.APPRENTICESHIP) {
    sellPerDay = 350;
  } else if (user.contract == CONTRACTS.INTERNSHIP) {
    sellPerDay = 300;
  } else if ([CONTRACTS.CDI, CONTRACTS.CDD, CONTRACTS.VIE].includes(user.contract)) {
    const index = PRICING_START.findIndex((e) => e[0] === user.position);

    if (user.experience_total < 7) {
      sellPerDay = Math.round(PRICING_START[index][2] * Math.pow(1.075, user.experience_total));
    } else {
      sellPerDay = Math.round(PRICING_START[index][2] * Math.pow(1.075, 7) * Math.pow(1.03, user.experience_total - 7));
    }

  } else if ([CONTRACTS.FREELANCE].includes(user.contract)) {
    const index = PRICING_START.findIndex((e) => e[0] === user.position);

    if (user.experience_total < 7) {
      sellPerDay = Math.round(PRICING_START[index][2] * Math.pow(1.1, user.experience_total));
    } else {
      sellPerDay = Math.round(PRICING_START[index][2] * Math.pow(1.1, 7) * Math.pow(1.06, user.experience_total - 7));
    } 
  }

  if (user.management == MANAGEMENT.LEAD) sellPerDay = Math.round(sellPerDay * 1.05);
  if (user.management == MANAGEMENT.HEADOF) sellPerDay = Math.round(sellPerDay * 1.1);
  if (user.school == SCHOOL.ENGINEERING) sellPerDay = Math.round(sellPerDay * 1.08);

  return sellPerDay;
}

function getCostSalary(elements, user) {
  let numberOfWeeksOff = 5;
  if (user.division == DIVISION.LE_COLLECTIF) numberOfWeeksOff += 5;
  if (user.division == DIVISION.SELEGO_FR) numberOfWeeksOff += 5;

  let totalDaysWorkedPerYear = user.days_worked / 4.4 * (52 - numberOfWeeksOff) - 8;
  let totalSalary = 0;

  for (let i = 0; i < elements.length; i++) {
    // Vérifiez si l'objet a un attribut 'key' égal à 'salary'
    if (elements[i].key === "salary") {
      // Si oui, ajoutez sa valeur à la somme
      totalSalary += elements[i].value;
    } else if (elements[i].key === "salary-reduction") {
      totalSalary -= elements[i].value;
    }
  }

  if (user.contract == CONTRACTS.FREELANCE) return totalSalary;

  let totalSalaryPerDay = Math.round(totalSalary / totalDaysWorkedPerYear);

  return totalSalaryPerDay;
  // ici besoin de sommer depuis elements le brut annuel + coût entreprise
  // diviser par le nb de jours travaillés/semaine - 52 semaines - 6 semaines (5 de congés payés + 1 jours fériés)
}

function getCostExpenses(elements, user) {
  let numberOfWeeksOff = 5;
  if (user.division == DIVISION.LE_COLLECTIF) numberOfWeeksOff += 5;
  if (user.division == DIVISION.SELEGO_FR) numberOfWeeksOff += 5;

  let totalDaysWorkedPerYear = user.days_worked / 4.4 * (52 - numberOfWeeksOff) - 8;
  let totalExpenses = 0;

  for (let i = 0; i < elements.length; i++) {
    // Vérifiez si l'objet a un attribut 'key' égal à 'salary'
    if (elements[i].key === "expenses") {
      // Si oui, ajoutez sa valeur à la somme
      totalExpenses += elements[i].value;
    }
  }

  if (user.contract == CONTRACTS.FREELANCE) return totalExpenses;

  let totalExpensesPerDay = Math.round(totalExpenses / totalDaysWorkedPerYear);

  return totalExpensesPerDay;
  // ici besoin de sommer depuis elements retraite + prévoyance + mutuelle + ordi + bureau + CEG
}
