import React, { useEffect, useState } from "react";
import { useParams, Routes, Route, Link } from "react-router-dom";
import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import api from "../../../services/api";

import Raw from "./raw";
import Edit from "./edit";
import Documents from "./documents";

export default function () {
  const [values, setValues] = useState(null);
  const [tab, setTab] = useState("edit");
  const { id } = useParams();

  const get = async () => {
    const { data } = await api.get(`/portfolio_company/${id}`);
    setValues(data);
  };

  useEffect(() => {
    get();
  }, [id]);

  async function onSaveDocuments(documents) {
    await api.put(`/portfolio_company/${id}`, { documents });
    await getPortfolioCompany();
    toast.success("Documents updated");
  }

  if (!values) return <Loader />;

  return (
    <div className="p-6">
      <div className="mb-2">
        <TabItem key="edit" onClick={(e) => setTab("edit")} title="Edit" />
        <TabItem key="documents" onClick={(e) => setTab("documents")} title="Documents" />
        <TabItem key="raw" onClick={(e) => setTab("raw")} title="Raw" />
      </div>
      <div className="p-4 bg-white rounded-lg flex flex-row flex-wrap border place-content-evenly border-gray-300 space-x-8">
        {tab === "edit" && <Edit values={values} setValues={setValues} />}
        {tab === "raw" && <Raw values={values} />}
        {tab === "documents" && <Documents documents={values?.documents} onChange={(documents) => onSaveDocuments(documents)} />}
      </div>
    </div>
  );
}

const TabItem = ({ title, onClick }) => {
  return (
    <div className={`relative rounded py-2 px-4 flex items-center gap-2`} onClick={onClick}>
      <div className="flex items-center gap-2 truncate">{title}</div>
    </div>
  );
};
