import React, { useState } from "react";
import API from "../../services/api";

export default function MeilleurAcheteur() {
  const [address, setAddress] = useState("46 rue saint antoine Paris");
  const [radius, setRadius] = useState(100);
  const [leaders, setLeaders] = useState([]);
  const [coords, setCoords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
        const data = await API.post("/meilleuracheteur", { address, radius });
      setLoading(false);
      if (data.ok) {
        setLeaders(data.data);
        setCoords(data.coords);
        setSubmitted(true);
      } else {
        alert(data.message || "Error fetching data.");
      }
    } catch (error) {
        setLoading(false);
        console.error("Error submitting the form:", error);
        alert("Server error: " + error.message);
    }
  };
  
  const handleReset = () => {
    setSubmitted(false);
    setLeaders([]);
    setCoords(null);
    setAddress("46 rue saint antoine Paris");
    setRadius(100);
  };

  if (submitted) {
    return <Results leaders={leaders} coords={coords} address={address} handleReset={handleReset} />;
  }

  return (
    <div className="flex justify-center items-center h-screen  px-6 w-[400px]">
      <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-lg">
        <form onSubmit={handleSubmit}>
          <h1 className="text-2xl font-bold text-center mb-6">Recherchez les SCI autour de cette adresse</h1>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Adresse</label>
            <input type="text" placeholder="Enter address" value={address} onChange={(e) => setAddress(e.target.value)} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">Rayon (en mètres)</label>
            <input type="range" min="10" max="500" step="1" value={radius} onChange={(e) => setRadius(parseInt(e.target.value))} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />
            <div className="text-gray-600 text-sm mt-1">Rayon: {radius} m</div>
          </div>
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            {loading ? "Chargement..." : "Envoyer"}
          </button>
        </form>
      </div>
    </div>
  );
}

const Results = ({ leaders, coords, address, handleReset }) => {
    return (
      <div className="flex flex-col items-center h-screen px-6">
        <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-lg text-center">
          <h1 className="text-xl font-semibold mb-4">Adresse entrée : {address}</h1>
          {coords && (
            <h2 className="text-lg mb-4">
              Coordonnées correspondantes : {coords.latitude}, {coords.longitude}
            </h2>
          )}
          <h2 className="text-lg font-semibold mb-2">Dirigeants trouvés :</h2>
          {leaders.length === 0 ? (
            <p>Aucune SCI trouvée.</p>
          ) : (
            <div className="text-left mx-auto max-w-md overflow-auto">
              {leaders.map((leader, index) => (
                <div key={index} className="mb-4 p-4 border rounded bg-gray-50">
                  <p><strong>Nom :</strong> {leader.nom || "Nom inconnu"}</p>
                  <p><strong>Prénom(s) :</strong> {leader.prenoms || "Prénoms inconnus"}</p>
                  <p><strong>Fonction :</strong> {leader.fonction || "Fonction inconnue"}</p>
                  <p><strong>Année de naissance :</strong> {leader.annee_de_naissance || "Année de naissance inconnue"}</p>
                  <p><strong>Nationalité :</strong> {leader.nationalite || "Nationalité inconnue"}</p>
                  <p><strong>SIREN :</strong> {leader.siren}</p>
                  <p><strong>Code NAF :</strong> {leader.code_NAF}</p>
                  <p><strong>Entreprise :</strong> {leader.entreprise}</p>
                  <p><strong>Adresse du siège :</strong> {leader.adresse_siege}</p>
                </div>
              ))}
            </div>
          )}
          <button
            onClick={handleReset}
            className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Revenir à la page précédente
          </button>
        </div>
      </div>
    );
  };
  
  