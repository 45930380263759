import React, { useEffect, useState } from "react";

export default ({ payment, setPayment }) => {
  return (
    <div>
      <span className="text-[14px] font-medium text-[#212325] bt-2 mb-3">
        - Create one invoice per month
        <br />
        - If you are invoicing Selego B.V from the Netherlands, put a TVA to 21%
        <br />
        - If you are invoicing Selego B.V from any other country, put a TVA to 0%
        <br />
        - If you are invoicing Le Collectif from France, put a TVA to 20%
        <br />
        - If you are invoicing Le Collectif from any other country, put a TVA to 0%
        <br /> <br />
        SELEGO B.V
        <br />
        Oudeschans 83 II
        <br />
        1011 KW Amsterdam
        <br />
        Netherlands
        <br />
        office@selego.co
        <br />
        VAT Intracommunity Tax : NL 858995918B01
        <br />
        IBAN: NL67BUNQ2206790084
        <br />
        <br /> <br />
        Le Collectif
        <br />
        46 Rue Saint-Antoine, 75004 Paris
        <br />
        office@selego.co
        <br />
        VAT Intracommunity Tax : FR 76919519637
        <br />
        IBAN: FR76 3000 4018 7500 0100 6280 775
        <br />
      </span>
    </div>
  );
};
