import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../../../services/api";
import Create from "../create";

const Questions = () => {
  const [filter, setFilter] = useState({ search: "" });
  const [questions, setQuestions] = useState(null);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, [filter]);

  async function load() {
    setLoading(true);
    const newFilter = { ...filter };
    const { data, total } = await api.post("/question/search", newFilter);
    setQuestions(data);
    setTotal(total);
    setLoading(false);
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="">
      <div className="flex items-center justify-end mb-4">
        <Create />
      </div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="text-left text-sm font-semibold text-gray-900">
          <tr>
            <th className="px-6 py-3">User</th>
            <th className="px-6 py-3">Question</th>
            <th className="px-6 py-3">Created at</th>
            <th className="px-6 py-3">Answers</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {(questions || []).map((question) => (
            <Row key={question._id} question={question} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Row = ({ question }) => {
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState();

  const getAnswers = async () => {
    setLoading(true);
    const { data } = await api.post("/answer/search", { question_id: question._id });
    console.log("✌️  resAnswers.data", data);
    setAnswers(data);
    setLoading(false);
  };

  useEffect(() => {
    getAnswers();
  }, [question]);

  return (
    <tr key={question._id} className="whitespace-nowrap text-sm">
      <td className="py-1 px-3">
        <div className="flex items-center gap-1">
          <img src={question.user_avatar} alt="userlogo" className="rounded-full w-7 h-7" />
          <div>{question.user_name}</div>
        </div>
      </td>
      <td className="px-6 py-2">
        <Link className="hover:underline" to={`/questioning/${question._id}`}>
          {question.title}
        </Link>
      </td>
      <td className="px-6 py-2">{question.created_at.substring(0, 10)}</td>
      {loading ? (
        <td>Loading...</td>
      ) : (
        <td>
          <span>{answers?.filter((a) => a.status === "done")?.length}</span>
          <span> /{answers?.length}</span>
        </td>
      )}
    </tr>
  );
};

export default Questions;
