import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment";
import { HiMagnifyingGlass } from "react-icons/hi2";
import DebounceInput from "react-debounce-input";
import Board from "react-trello";

import CvIcon from "../../../assets/cv.svg";
import GithubIcon from "../../../assets/github.svg";
import LinkedinIcon from "../../../assets/linkedin.svg";

import SelectUsers from "../components/selectUser";
import SelectProjects from "../components/SelectProjects";
import SelectJobs from "../components/SelectJobs";
import SelectSector from "../components/SelectSector";

import api from "../../../services/api";

export default () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.user);
  const [applicants, setApplicants] = useState([]);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [filters, setFilters] = useState({ role: "applicant", user, page: 1, per_page: 100 });

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setApplicants([]);
    const newFilters = {};
    if (filters.role) newFilters.role = filters.role;
    if (filters.user) newFilters.user_id = filters.user._id;
    if (filters.search) newFilters.search = filters.search;
    if (filters.projects) newFilters.project_ids = filters.projects.map((e) => e.value);
    if (filters.jobs) newFilters.job_ids = filters.jobs.map((e) => e.value);
    if (filters.sectors) newFilters.sectors = filters.sectors.map((e) => e.value);

    newFilters.sort = "-created_at";

    ["SHORTLIST", "CONTACTED", "INTERVIEW", "TECHNICAL_TEST", "FINAL_LIST", "HIRE", "REJECT"].forEach(async (e) => {
      newFilters.statuses = [e];
      if (e === "REJECT") newFilters.per_page = 3;
      const { data } = await api.post("/user/search", newFilters);
      setApplicants((v) => v.concat(data.users));
    });
  }

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between mb-4">
        <div className="flex space-x-4">
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Search"}</div>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 top-0 flex items-center pl-3">
                <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <DebounceInput
                debounceTimeout={300}
                name="search"
                id="search"
                className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                placeholder="Search"
                value={filters.search}
                onChange={(e) => {
                  e.persist();
                  setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
                }}
              />
            </div>
          </div>
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Lead"}</div>
            <SelectUsers
              value={filters.user}
              userName={filters.user?.name}
              onChange={(v) => {
                setFilters({ ...filters, user: v });
              }}
            />
          </div>
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Project"}</div>
            <SelectProjects values={filters.projects} onChange={(e) => setFilters((f) => ({ ...f, projects: e, page: 1 }))} />
          </div>
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Job"}</div>
            <SelectJobs values={filters.jobs} onChange={(e) => setFilters((f) => ({ ...f, jobs: e, page: 1 }))} />
          </div>
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Sector"}</div>
            <SelectSector values={filters.sectors} onChange={(e) => setFilters((f) => ({ ...f, sectors: e, page: 1 }))} />
          </div>
        </div>
      </div>

      {(applicants.length > 0 && (
        <Board
          components={{ Card }}
          style={{ backgroundColor: "#F5F6FC", overflow: "scroll", height: "100%" }}
          laneStyle={{ backgroundColor: "#e5e6eC", overflow: "scroll" }}
          hideCardDeleteIcon
          data={{
            lanes: ["SHORTLIST", "CONTACTED", "INTERVIEW", "TECHNICAL_TEST", "FINAL_LIST", "HIRE", "REJECT"].map((category) => ({
              id: category,
              title: (
                <div>
                  <h2 className="text-base">
                    {category}&nbsp;-&nbsp;
                    {applicants.filter((app) => app.applicant_status === category).length}&nbsp;applicants
                  </h2>
                </div>
              ),
              cards: applicants
                .filter((applicant) => applicant.applicant_status === category)
                .sort((a, b) => {
                  const dateA = new Date(a.last_login_at);
                  const dateB = new Date(b.last_login_at);
                  return dateB - dateA;
                })
                .map((applicant) => ({ applicant, id: applicant._id })),
            })),
          }}
          onCardClick={(id) => {
            const applicant = applicants.find((f) => f._id === id);
            query.set("user_modal_id", applicant._id);
            query.set("user_modal_tab", "applicant");
            navigate({ search: query.toString() });
          }}
          onCardMoveAcrossLanes={async (fromLaneId, toLaneId, cardId) => {
            const applicant = applicants.find((f) => f._id === cardId);
            applicant.applicant_status = toLaneId;
            const { data, ok } = await api.put(`/user/${cardId}`, applicant);
            if (!ok) return toast.error("Something went wrong");
            toast.success("applicant moved successfully");
          }}
        />
      )) || <div className="w-full h-full flex justify-center items-center">No data</div>}
    </div>
  );
};

const Card = ({ applicant, onClick }) => {
  return (
    <div className="w-[250px]">
      <div className="p-2 my-2 flex flex-col bg-gray-50 rounded shadow-md cursor-pointer" onClick={onClick}>
        <div className="flex justify-between space-x-2">
          <div className="w-[70px] h-[100px] rounded-xl overflow-hidden shadow">
            <img alt="profile" src={applicant.avatar} className="w-[70px] h-[100px] object-cover" />
          </div>
          <div className="flex flex-col items-start w-full">
            <div className="text-sm w-full">{applicant?.name}</div>
            {applicant.applicant_status === "HIRE" && applicant.starting_date && (
              <p className="text-sm text-primary">starting: {new Date(applicant.starting_date).toLocaleDateString()}</p>
            )}
            {applicant.applicant_status === "HIRE" && applicant.office && <p className="text-sm text-primary">office: {applicant.office}</p>}
            <div className="text-xs w-full text-gray-500 whitespace-pre-line">{applicant.job_title}</div>
            <div className="flex justify-between w-full mt-auto">
              <div className="text-xs text-gray-500">{applicant.country}</div>
              <div className="flex space-x-2 items-center" onClick={(e) => e.stopPropagation()}>
                {applicant.linkedin && (
                  <a href={applicant.linkedin} target="_blank" rel="noreferrer">
                    <img alt="linkedin" src={LinkedinIcon} className="w-6 h-6" />
                  </a>
                )}
                {applicant.github && (
                  <a href={applicant.github} target="_blank" rel="noreferrer">
                    <img alt="github" src={GithubIcon} className="w-6 h-6 rounded-full" />
                  </a>
                )}
                {applicant.cv && (
                  <a href="">
                    <img alt="cv" src={CvIcon} className="w-6 h-6 border-2 border-gray-300 rounded p-1" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-2 mb-2" />
        <div className="flex justify-between">
          <div className="flex items-center">
            <img src={applicant.user_avatar} className="w-8 h-8 rounded-full" alt={applicant.user_name} />
            {(applicant.followers || []).map((e) => (
              <img key={e.user_avatar} className="h-8 w-8 rounded-full bg-gray-50 ring-2 ring-white" src={e.user_avatar} alt={e.user_avatar} />
            ))}
          </div>
          <div>
            <div className="text-xs text-gray-500">{`Applied : ${moment(applicant.created_at).format("DD/MM/YYYY")}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
