import React, { useState, useEffect } from "react";
import { ResponsiveRadar } from "@nivo/radar";
import api from "../../../services/api";
import Loader from "../../../components/loader";

const CategoryRadar = ({ userId }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();

  const getAnswers = async () => {
    try {
      setLoading(true);
      const resQuestions = await api.post("/skill_question/search", { limit: 1000 });
      const questions = resQuestions.data;
      const resAnswers = await api.post("/skill_answer/search", { userId });
      const answers = resAnswers.data;

      // for each answers, get the lowest score for each answer.skillCategory
      const categories = resAnswers.data.map((answer) => answer.skillCategory);
      const uniqueCategories = [...new Set(categories)];

      const scores = uniqueCategories.map((category) => {
        const questionsByCategory = questions.filter((q) => q.skillCategory === category);

        let levelSum = 0;
        const subCategories2 = questionsByCategory.map((q) => q.skillSubCategory);
        const subCategories = [...new Set(subCategories2)];

        for (let s = 0; s < subCategories.length; s++) {
          const questionsBySubCategory = questions.filter((q) => q.skillSubCategory === subCategories[s]);
          const answeredQuestionsSubCategory = answers.filter((a) => questionsBySubCategory.find((q) => q._id === a.questionId));

          let levelValidatedSubCategory = 0;
          for (let i = 1; i <= 5; i++) {
            // if there is no question for this level, we consider the level validated
            const questionsForThisLevel = questionsBySubCategory.filter((question) => question.level === i);
            if (!questionsForThisLevel.length) {
              levelValidatedSubCategory = i;
              continue;
            }

            // get all answers for this subCategory and this level
            const answersForThisLevel = answeredQuestionsSubCategory.filter((answer) => answer.levelValue === i);
            // if there is at least one falsy answer for this level, we consider the level not validated and we stop the loop
            if (!answersForThisLevel.length || answersForThisLevel.find((answer) => !parseInt(answer.value))) {
              break;
            }
            levelValidatedSubCategory = i;
          }
          levelSum += levelValidatedSubCategory;
        }
        return { skill: category, score: levelSum / subCategories.length };
      });
      setData(scores);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnswers();
  }, [userId]);

  if (loading || !data) return <Loader />;

  return (
    <ResponsiveRadar
      data={data}
      keys={["score"]}
      indexBy="skill"
      valueFormat=">-.2f"
      margin={{ top: 30, right: 10, bottom: 30, left: 10 }}
      borderColor={{ from: "color" }}
      gridLabelOffset={20}
      dotSize={5}
      dotColor={{ theme: "background" }}
      dotBorderWidth={2}
      colors={{ scheme: "dark2" }}
      blendMode="multiply"
      motionConfig="wobbly"
      maxValue={5}
    />
  );
};

export default CategoryRadar;
