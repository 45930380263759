import React, { useContext } from "react";
import { MdClose } from "react-icons/md";

import { AppContext } from "./context";

export default ({}) => {
  const { index, setIndex, workshop } = useContext(AppContext);
  const max = workshop.steps.length;

  const progressValue = (index * 100) / max;
  const currentStep = workshop.steps[index - 1];

  return (
    <div className="bg-gray-100 flex justify-between items-center p-3 border-b border-gray-200">
      <div className="w-full">
        <div className="w-full bg-gray-300 rounded-full h-2">
          <div className="bg-blue-600 h-2 rounded-full transition-all duration-300" style={{ width: `${progressValue}%` }}></div>
        </div>
        <div className="my-2" />
        <div className="flex flex-col md:flex-row">
          <div className="flex items-center gap-3 p-3">
            <div className="border border-gray-200 relative w-10 h-10 rounded-xl overflow-hidden">
              <img src={workshop.image} alt="workshop.image" />
            </div>
            <div className="flex-1">
              <div className="text-xs text-gray-600 uppercase">{workshop.name}</div>
              <div className="text-base md:text-xl font-black">{currentStep.title}</div>
            </div>
          </div>
          <div className="flex-1" />
          <div className="flex items-center px-3 self-end md:self-center">
            <button
              title="Close"
              className="bg-white rounded-full w-9 h-9 border border-gray-200 flex items-center justify-center text-gray-600 hover:border-gray-300 hover:!text-primary"
              onClick={() => setIndex(0)}>
              <MdClose className="text-base" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
