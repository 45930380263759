import { useState } from "react";
import validator from "validator";
import { Link, Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import { setUser } from "../../redux/auth/actions";

import api from "../../services/api";
import Loader from "../../components/loader";

const Invite = () => {
  const [values, setValues] = useState({ email: "", password: "", name: "" });
  const [errors, setErrors] = useState({ email: "", password: "", name: "" });
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);
  const navigate = useNavigate();
  const redirect = searchParams.get("redirect");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!values.email) return setErrors({ ...errors, email: "This field is Required" });
    if (!values.password) return setErrors({ ...errors, password: "This field is Required" });
    if (!values.name) return setErrors({ ...errors, name: "This field is Required" });
    if (!validator.isEmail(values?.email)) return toast.error("Invalid email");

    setLoading(true);
    try {
      const { user, token, code } = await api.post("/user/invite", { ...values, projectId: redirect });
      if (code) toast.error(code);
      if (token) api.setToken(token);
      if (user) {
        console.log(user);
        dispatch(setUser(user));
        toast.success("Your account has been created successfully!");
        navigate(`/project/${redirect}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error while signing up");
    }
    setLoading(false);
  };

  if (user) return <Navigate to={"/"} />;

  if (!redirect) {
    toast.error("Invalid invitation link");
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center bg-gray-50">
        <div className="flex flex-col items-center gap-4">
          <div className="flex items-center gap-3 justify-center">
            <h1 className="text-3xl font-bold text-gray-800 tracking-wide">ACCOUNTING</h1>
            <img
              src="https://media.licdn.com/dms/image/v2/D4D0BAQGaYxyy8tjtIA/company-logo_200_200/company-logo_200_200/0/1718790848566/lestud_logo?e=2147483647&v=beta&t=LQi-s0kQfVS2dw1rhz3OWj2xZMf9xIlADhKMCKnBOZU"
              alt="logo"
              className="w-16 h-16 shadow-md"
            />
          </div>
          <h2 className="text-2xl font-medium text-gray-600">Invitation</h2>
          <div className="mt-6 p-4 bg-white rounded-lg shadow-md border border-gray-200 text-center max-w-md">
            <p className="text-gray-700 mt-2 font-medium">You've got an invalid invitation link.</p>
            <p className="text-gray-600 mt-2 text-sm">👉 Please contact the project owner to get a new one</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <form className="py-12 px-4 w-full md:w-1/2" onSubmit={handleSubmit}>
        <div className="flex gap-2 items-center justify-center">
          <div className="flex items-center gap-3 justify-center">
            <h1 className="text-3xl font-bold text-gray-800 tracking-wide">ACCOUNTING</h1>
            <img
              src="https://media.licdn.com/dms/image/v2/D4D0BAQGaYxyy8tjtIA/company-logo_200_200/company-logo_200_200/0/1718790848566/lestud_logo?e=2147483647&v=beta&t=LQi-s0kQfVS2dw1rhz3OWj2xZMf9xIlADhKMCKnBOZU"
              alt="logo"
              className="w-16 h-16 shadow-md"
            />
          </div>
        </div>
        <div className="flex gap-1 items-center justify-center">
          <h2 className="text-2xl font-medium text-gray-600">Invitation</h2>
        </div>

        <div className="space-y-6 mt-4">
          <div className="space-y-2">
            <label className="text-lg font-semibold" htmlFor="name">
              Name
            </label>
            <input className="input" name="name" type="text" id="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
            <p className="text-sm text-red-700">{errors.name}</p>
          </div>
          <div className="space-y-2">
            <label className="text-lg font-semibold" htmlFor="email">
              E-mail
            </label>
            <input
              className="input"
              validate={(v) => !validator.isEmail(v) && "Invalid email address"}
              name="email"
              type="email"
              id="email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
            <p className="text-sm text-red-700">{errors.email}</p>
          </div>
          <div className="space-y-2">
            <label className="text-lg font-semibold" htmlFor="password">
              Password
            </label>
            <input className="input" name="password" type="password" id="password" value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })} />
            <p className="text-sm text-red-700">{errors.password}</p>
          </div>
          <div className="w-full flex flex-col gap-4 justify-center items-center">
            <button className="blue-btn w-56" type="submit">
              {loading ? <Loader size="small" color="white" /> : "Create account"}
            </button>

            <div className="w-</button>full flex justify-center">
              <Link className="empty-btn w-56" to={`/auth/`}>
                Already have an account
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Invite;
