import React, { useEffect, useState } from "react";
import API from "../../../../../services/api";
import { useParams } from "react-router-dom";

const statusBadges = {
  TODO: <span className="bg-blue-100 border border-blue-300 text-blue-700 text-sm font-medium px-2 py-1 rounded">Todo</span>,
  READY_TO_DEV: <span className="bg-pink-100 border border-pink-300 text-pink-700 text-sm font-medium px-2 py-1 rounded">Ready to dev</span>,
  IN_PROGRESS: <span className="bg-blue-100 border border-blue-300 text-blue-700 text-sm font-medium px-2 py-1 rounded">In progress</span>,
  TESTING: <span className="bg-purple-100 border border-purple-300 text-purple-700 text-sm font-medium px-2 py-1 rounded">Testing</span>,
  DONE: <span className="bg-green-100 border border-green-300 text-green-700 text-sm font-medium px-2 py-1 rounded">Done</span>,
  ARCHIVED: <span className="bg-gray-100 border border-gray-300 text-gray-700 text-sm font-medium px-2 py-1 rounded">Archived</span>,
};

const statusCategories = {
  BUG: <span className="bg-red-100 border border-red-300 text-red-700 text-sm font-medium px-2 py-1 rounded">Bug</span>,
  FEATURE: <span className="bg-yellow-100 border border-yellow-300 text-yellow-700 text-sm font-medium px-2 py-1 rounded">Feature</span>
};

export default function History() {
  const { id } = useParams();
  const [doneNotions, setDoneNotions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    try {
      setLoading(true);
      const { data, ok } = await API.post("/notion/search", { project_id: id, sort: "-lastest_status_date", statuses :["DONE", "IN_PROGRESS", "TESTING"] });
      if (!ok) return toast.error("Error fetching notions");
      console.log("data", data);
      setDoneNotions(data);
    } catch (error) {
      console.error("Error fetching notions:", error);
      toast.error("Error fetching notions");
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <div className="p-4">Loading...</div>;

  return (
    <div className="p-4 flex flex-col gap-4 w-full">
      {doneNotions.map((item, index) => (
        <div key={index} className="p-4 border divide-x-2 space-x-2 rounded shadow flex justify-between items-center">
          <div className="text-sm text-wrap font-semibold flex-1">{item.name}</div>
          <div className="text-lg text-gray-800 w-44 pl-2">{statusBadges[item.status]}</div>
          <div className="text-lg text-gray-800 w-44 pl-2">{statusCategories[item.category]}</div>
          <div className="text-lg text-gray-800 w-60 pl-2">{new Date(item.lastest_status_date).toLocaleString() || "Date unknown"}</div>
          <div className="flex gap-2 w-44 px-2">
            {item.users.map((user, indexUser) => (
              <img key={indexUser} src={user.user_avatar} alt={user.user_name} className="w-8 h-8 rounded-full" />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
