import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";

import Modal from "../../../components/modal";

// const REASONS = {
//   NO_TEAMS_AVAILABLE: "No teams available",
//   TOO_EXPENSIVE: "Proposal too expensive",
//   NO_MATCH: "No Match with us",
//   TOO_SLOW: "Too slow",
//   NO_NEWS: "No news from the client",
//   OTHER: "Other",
// };

const Loose = ({ values, setValues, open, setOpen, onClose }) => {
  const [reason, setReason] = useState(values?.reason_lost);
  const [reason_comment, setReasonComment] = useState(values?.reason_lost_comment);

  useEffect(() => {
    setReason(values?.reason_lost);
  }, [values]);

  async function onSubmit() {
    try {
      const { data, ok } = await api.put(`/folk/${values._id}`, {
        ...values, reason_lost: reason,
        reason_lost_comment: reason_comment,
        status: "LOST"
      });
      if (!ok) return toast.error("Error");
      setValues(data);
      toast.success("Updated!");
      setOpen(false);
      onClose();
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  }

  return (
    <div className="">
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="flex flex-col gap-2">
          {/* <div className="w-full"> Please select a reason why the deal was lost</div>
          <div className="w-full">
            <div className="px-1 text-sm text-gray-600 font-medium">Reason</div>
            <select
              className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
              type="select"
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}>
              <option selected disabled>
                Select
              </option>
              {Object.keys(REASONS).map((key) => (
                <option key={key} value={key}>
                  {REASONS[key]}
                </option>
              ))}
            </select>
          </div> */}
          <div className="w-full">
            <div className="px-1 text-sm text-gray-600 font-medium">Comment</div>
            <textarea
              className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
              type="text"
              value={reason_comment}
              onChange={(e) => setReasonComment(e.target.value)}
            />
          </div>
          <button className="btn btn-primary w-32" onClick={onSubmit}>
            Update
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Loose;
