import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../services/api";
import { IoIosLink } from "react-icons/io";
import { IoFolderOutline, IoMailOutline, IoInformationCircleOutline, IoChatboxOutline, IoLogoAppleAr } from "react-icons/io5";
import { TbLayoutGridAdd } from "react-icons/tb";

import CvIcon from "../../assets/cv.svg";
import GithubIcon from "../../assets/github.svg";
import LinkedinIcon from "../../assets/linkedin.svg";

import Modal from "../modal";

import About from "./about";
import Presentation from "./presentation";
import Applicant from "./applicant";
import Comments from "./comments";
import Files from "./files";
import Raw from "./raw";
import Activity from "./activity";
import Resume from "./resume";
import Action from "./action";
import Skills from "./skills";
import Availability from "./availability";
import Device from "./device";
import Ratings from "./ratings";
import Salary from "./salary";
import Payment from "./payment";
import Overview from "./overview";

import { copyToClipboard, classNames } from "../../utils";

import { setSelectedUser } from "../../redux/auth/actions";

const UserCard = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  const [tab, setTab] = useState();
  const user = useSelector((state) => state.Auth.selectedUser);
  const loggedInUser = useSelector((state) => state.Auth.user);

  const getUser = async () => {
    try {
      const user_modal_id = query.get("user_modal_id");
      if (user_modal_id) {
        const user_modal_tab = query.get("user_modal_tab") || "about";
        setTab(user_modal_tab);
        setLoading(true);
        const res = await api.get(`/user/${user_modal_id}`);
        setLoading(false);
        if (!res.ok) return;
        dispatch(setSelectedUser(res.data));
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    const user_modal_id = query.get("user_modal_id");
    if (!user || user._id !== user_modal_id) getUser();
  }, [query]);

  useEffect(() => {
    if (!tab) return;
    query.set("user_modal_tab", tab);
    navigate({ pathname: location.pathname, search: query.toString() });
  }, [tab]);

  const dispatch = useDispatch();

  const handleUserChange = (user) => {
    if (!user) {
      query.delete("user_modal_id");
      query.delete("user_modal_tab");
      navigate({ pathname: location.pathname, search: query.toString() });
    }
    dispatch(setSelectedUser(user));
  };

  const render = () => {
    if (tab === "about") return <About user={user} setUser={handleUserChange} />;
    if (tab === "comments") return <Comments user={user} setUser={handleUserChange} />;
    if (tab === "applicant") return <Applicant user={user} setUser={handleUserChange} />;
    if (tab === "resume") return <Resume user={user} />;
    if (tab === "files") return <Files user={user} setUser={handleUserChange} />;
    if (tab === "raw") return <Raw user={user} />;
    if (tab === "activity") return <Activity user={user} />;
    if (tab === "availability") return <Availability user={user} />;
    if (tab === "presentation") return <Presentation user={user} />;
    if (tab === "skills") return <Skills user={user} setUser={handleUserChange} />;
    if (tab === "salary") return <Salary user={user} setUser={handleUserChange} />;
    if (tab === "action") return <Action user={user} setUser={handleUserChange} />;
    if (tab === "payment") return <Payment user={user} setUser={handleUserChange} />;
    if (tab === "device") return <Device user={user} />;
    if (tab === "ratings") return <Ratings user={user} setUser={handleUserChange} />;
    if (tab === "overview") return <Overview user={user} setUser={handleUserChange} />;
  };

  if (!user) return <></>;

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        handleUserChange(null);
        setTab("");
      }}>
      {loading ? (
        <div className="w-full h-[90vh] bg-gray-100 p-2 flex items-center justify-center">
          <div className="text-xl text-gray-500 animate-pulse">
            Knock Knock...
            <br />
            Who's there ?
          </div>
        </div>
      ) : (
        <div className="w-full h-[90vh] bg-[#FBFBF9] p-2 overflow-y-auto">
          <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
            <div className="flex items-center gap-2 p-4">
              <img
                src={user.avatar || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
                alt="userlogo"
                className="rounded-full w-7 h-7 md:w-14 md:h-14 mr-2"
              />
              <div className="flex justify-between flex-1">
                <div className="flex items-center gap-2">
                  <div>
                    <div className="text-lg font-bold">{user.name}</div>
                    <div className="text-sm">{user.job_title}</div>
                  </div>
                  <div className="flex space-x-3 items-center">
                    {user.linkedin && (
                      <a href={user.linkedin} target="_blank" rel="noreferrer">
                        <img alt="linkedin" src={LinkedinIcon} className="w-6 h-6" />
                      </a>
                    )}
                    {user.github && (
                      <a href={user.github} target="_blank" rel="noreferrer">
                        <img alt="github" src={GithubIcon} className="w-6 h-6" />
                      </a>
                    )}
                    {user.cv && (
                      <a href="">
                        <img alt="cv" src={CvIcon} className="w-6 h-6 border-2 border-gray-300 rounded p-1" />
                      </a>
                    )}
                  </div>
                  <button
                    className="hover:bg-gray-100 cursor-pointer rounded-lg p-2 flex items-center gap-1 text-xs text-gray-700"
                    onClick={() => {
                      const v = `${window.location.origin}/people?user_modal_id=${user._id.toString()}&user_modal_tab=${tab}`;
                      copyToClipboard(v);
                      toast.success("Link copy to clipboard");
                    }}>
                    <IoIosLink />
                    Copy link
                  </button>
                  <button
                    className="hover:bg-gray-100 cursor-pointer rounded-lg p-2 flex items-center gap-1 text-xs text-gray-700"
                    onClick={() => {
                      const v = `${window.location.origin}/public/applicant/${user._id.toString()}`;
                      copyToClipboard(v);
                      toast.success("Link copy to clipboard");
                    }}>
                    <IoIosLink />
                    Copy public link
                  </button>
                  <a href="/salary/bench" target="_blank" className="hover:bg-gray-100 cursor-pointer rounded-lg p-2 flex items-center gap-1 text-xs text-gray-700">
                    <IoIosLink />
                    Salary benchmark
                  </a>
                  {user.status === "inactive" ? <span className="bg-red-100 text-red-700 border-red-200 border-[1px] uppercase text-xs px-3 py-1">inactive</span> : null}{" "}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="grid grid-cols-2 gap-x-4">
                  <span className="text-xs flex-1 text-right text-[#b7b3b4]">Updated at</span>
                  <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(user.updated_at).format("DD/MM/YYYY, HH:mm")}</span>
                  <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created at</span>
                  <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(user.created_at).format("DD/MM/YYYY, HH:mm")}</span>
                </div>
              </div>
            </div>
            <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
              <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} Icon={IoInformationCircleOutline} />
              <TabItem title="Overview" tab="overview" setTab={setTab} active={tab === "overview"} Icon={IoLogoAppleAr} />
              {user.role === "applicant" && loggedInUser.role != "applicant" && (
                <>
                  <TabItem title="Applicant" tab="applicant" setTab={setTab} active={tab === "applicant"} Icon={IoMailOutline} />
                  <TabItem title={"Presentation"} tab="presentation" setTab={setTab} active={tab === "presentation"} Icon={IoChatboxOutline} />
                  <TabItem
                    title={user.comments?.length ? `Comments (${user.comments?.length})` : "Comments"}
                    tab="comments"
                    setTab={setTab}
                    active={tab === "comments"}
                    Icon={IoChatboxOutline}
                  />
                </>
              )}
              {loggedInUser.role === "admin" && (
                <>
                  <TabItem
                    title={`Files ${user.documents?.length ? `(${user.documents?.length})` : ""}`}
                    tab="files"
                    setTab={setTab}
                    active={tab === "files"}
                    Icon={IoFolderOutline}
                  />

                  <TabItem title="Activities" tab="activity" setTab={setTab} active={tab === "activity"} Icon={TbLayoutGridAdd} />
                </>
              )}
              <TabItem title="Actions" tab="action" setTab={setTab} active={tab === "action"} Icon={TbLayoutGridAdd} />
              <TabItem title="Salary" tab="salary" setTab={setTab} active={tab === "salary"} Icon={TbLayoutGridAdd} />
              <TabItem title="Payment" tab="payment" setTab={setTab} active={tab === "payment"} Icon={TbLayoutGridAdd} />
              <TabItem title="Skills" tab="skills" setTab={setTab} active={tab === "skills"} Icon={TbLayoutGridAdd} />
              {user.resume && <TabItem title="Resume" tab="resume" setTab={setTab} active={tab === "resume"} Icon={TbLayoutGridAdd} />}
              <TabItem title="Availability" tab="availability" setTab={setTab} active={tab === "availability"} />
              {["SELEGO_NL", "SELEGO.FR", "LE_COLLECTIF"].includes(user.contract) ? <TabItem title="Device" tab="device" setTab={setTab} active={tab === "device"} /> : null}
              {loggedInUser.role === "admin" && (
                <>
                  <TabItem title="Ratings" tab="ratings" setTab={setTab} active={tab === "ratings"} />
                  <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
                </>
              )}
            </nav>
          </div>
          <div className="my-2" />
          {render()}
        </div>
      )}
    </Modal>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 ",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);

export default UserCard;
