import React, { useEffect, useState } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { ResponsiveBar } from "@nivo/bar";

import { formatNumberWithSpaces } from "../utils";

import api from "../../../services/api";

ChartJS.register(...registerables);

export default ({ project }) => {
  const [budgets, setBudgets] = useState(null);

  const [year, setYear] = useState();

  const [credits, setCredits] = useState(null);
  const [activities, setActivities] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [banks, setBanks] = useState(null);

  useEffect(() => {
    get();
  }, [year]);

  async function get() {
    const beginingOfThisYear = new Date(year, 0, 1);
    const endOfThisYear = new Date(year, 11, 31);

    const fActivities = {};
    fActivities.projectId = project._id;
    
    if (year) {
      fActivities.dateFrom = beginingOfThisYear;
      fActivities.dateTo = endOfThisYear;
      
    }
    const { data: activities } = await api.post(`/activity/search`, fActivities);

    const fBank = { project_id: project._id, notCategories: ["DISBURSEMENT", "INCOME", "VARIABLE_COST"], per_page: 20000 };
    if (year) {
      fBank.startDate = beginingOfThisYear;
      fBank.endDate = endOfThisYear;
    }

    const { data: b } = await api.post(`/bank/search`, fBank);
    const banks = b.banks;

    setActivities(activities);
    setBanks(banks);

    const { data: bu } = await api.post(`/budget/search`, { projectId: project._id });
    setBudgets(bu);

    const { data: cr } = await api.post(`/credit/search`, { projectId: project._id });
    setCredits(cr);

    const fInvoices = { projectId: project._id };
    if (year) {
      fInvoices.dateFrom = beginingOfThisYear;
      fInvoices.dateTo = endOfThisYear;
    }
    const { data: inv } = await api.post(`/invoice/search`, fInvoices);
    setInvoices(inv);
  }

  if (!activities || !banks || !budgets || !credits || !invoices) return <div>Loading...</div>;

  return (
    <div className="bg-white">
      <div className="flex items-center justify-end mb-2">
        <SelectYear value={year} onChange={(e) => setYear(e)} />
      </div>
      {/* <div className="flex space-x-4 mb-4">
        <YearToDate activities={activities} banks={banks} />
        <AnnualBudget budgets={budgets} activities={activities} banks={banks} credits={credits} />
        <MonthtoDate activities={activities} />
      </div> */}
      <div className="flex space-x-4 mb-4">
        <InvoicesPaid invoices={invoices} />
        <InvoicesToBePaid invoices={invoices} />
        <InvoicesNext invoices={invoices} credits={credits} banks={banks} activities={activities} />
        <Credits credits={credits} activities={activities} />
      </div>
      <div className="flex space-x-4 mb-4">
        <Budgets budgets={budgets} />
      </div>
      <Chart activities={activities} banks={banks} budgets={budgets} credits={credits} />
    </div>
  );
};

const Credits = ({ credits, activities }) => {
  if (!credits) return <div />;

  const total = credits.reduce((acc, cur) => acc + cur.amount, 0);

  return (
    <div className="border border-gray-200 rounded-md p-6 flex-1">
      <h2 className="text-gray-700 font-semibold text-lg">Total vouchers</h2>
      <div className="mt-2">
        <p className="text-3xl font-bold">{`${formatNumberWithSpaces(total)} €`}</p>
        <p className="text-sm text-gray-500">---</p>
      </div>
      {/* <p className="text-gray-600 text-sm mt-4">Current MTD Cost</p> */}
    </div>
  );
};

const YearToDate = ({ activities, banks }) => {
  if (!activities) return <div />;
  if (!banks) return <div />;

  const totalActivities = Math.ceil(activities.reduce((acc, cur) => acc + cur.value, 0));
  const totalBanks = Math.ceil(banks.reduce((acc, cur) => acc + cur.amount, 0));
  const total = totalActivities - totalBanks;

  return (
    <div className="border border-gray-200 rounded-md p-4 flex-1">
      <h2 className="text-gray-700 font-semibold text-lg">Year to date cost</h2>
      <div className="mt-2">
        <p className="text-sm text-gray-500">Sum of all activities and banks this year</p>
        <p className="text-3xl font-bold">{`${formatNumberWithSpaces(total)} €`}</p>
        <p className="text-sm text-gray-500">{`Activities : ${formatNumberWithSpaces(totalActivities)}€`}</p>
        <p className="text-sm text-gray-500">{`Expenses : ${formatNumberWithSpaces(-totalBanks)}€`}</p>
      </div>
    </div>
  );
};
const InvoicesToBePaid = ({ invoices }) => {
  if (!invoices) return <div />;

  const arr = invoices.filter((e) => e.sent == "yes" && e.received == "no").filter((e) => e.total);

  const total = Math.ceil(arr.reduce((acc, cur) => acc + cur.total, 0));

  return (
    <div className="border border-gray-200 rounded-md p-4 flex-1">
      <h2 className="text-gray-700 font-semibold text-lg">Invoice(s) to be paid</h2>
      <div className="mt-2">
        <p className="text-3xl font-bold">{`${formatNumberWithSpaces(total.toFixed(0))} €`}</p>
        {arr.map((e) => {
          return (
            <p className="text-sm text-gray-500">
              {e.name} ({e.total.toFixed(0)})€
            </p>
          );
        })}
      </div>
    </div>
  );
};

const InvoicesNext = ({ invoices, credits, banks, activities }) => {
  if (!invoices) return <div />;

  const totalInvoices = invoices.filter((e) => e.sent == "yes").reduce((acc, cur) => acc + cur.total, 0);

  const totalBanks = Math.ceil(banks.reduce((acc, cur) => acc + cur.amount, 0));
  const totalActivities = Math.ceil(activities.reduce((acc, cur) => acc + cur.value, 0));
  const totalCredits = credits.reduce((acc, cur) => acc + cur.amount, 0);
  // total = Math.ceil(total - credits.reduce((acc, cur) => acc + cur.amount, 0));
  const total = Math.ceil(totalBanks + totalActivities - totalInvoices - totalCredits);

  console.log(`total`, total, totalBanks, totalActivities, totalInvoices, totalCredits)

  return (
    <div className="border border-gray-200 rounded-md p-4 flex-1">
      <h2 className="text-gray-700 font-semibold text-lg">Next invoice</h2>
      <div className="mt-2">
        <p className="text-3xl font-bold">{`${formatNumberWithSpaces(total)} €`}</p>
        <p className="text-sm text-gray-500">Total consumed but not invoiced yet</p>
        <p className="text-sm text-gray-500">banks + activities - invoices - credits</p>
      </div>
    </div>
  );
};

const InvoicesPaid = ({ invoices }) => {
  if (!invoices) return <div />;

  const arr = invoices.filter((e) => e.received == "yes").filter((e) => e.total);

  const total = Math.ceil(arr.reduce((acc, cur) => acc + cur.total, 0));

  return (
    <div className="border border-gray-200 rounded-md p-4 flex-1">
      <h2 className="text-gray-700 font-semibold text-lg">Invoice(s) paid</h2>
      <div className="mt-2">
        <p className="text-3xl font-bold">{`${formatNumberWithSpaces(total)} €`}</p>
        {arr.map((e) => {
          return (
            <p className="text-sm text-gray-500">
              {e.name} ({e.total})€
            </p>
          );
        })}
      </div>
    </div>
  );
};

const MonthtoDate = ({ activities }) => {
  if (!activities) return <div />;

  let total = activities
    .filter((e) => new Date(e.date).getMonth() === new Date().getMonth() && new Date(e.date).getFullYear() === new Date().getFullYear())
    .reduce((acc, cur) => acc + cur.value, 0);

  total = Math.abs(total).toFixed(0);

  return (
    <div className="border border-gray-200 rounded-md p-4 flex-1">
      <h2 className="text-gray-700 font-semibold text-lg">Month to date cost</h2>
      <div className="mt-2">
        <p className="text-3xl font-bold">{`${formatNumberWithSpaces(total)} €`}</p>
        <p className="text-sm text-gray-500">---</p>
      </div>
    </div>
  );
};

const AnnualBudget = ({ budgets, activities, banks, credits }) => {
  if (budgets === null) return <div />;

  const total = budgets.reduce((acc, cur) => acc + cur.amount, 0);
  const totalSpent = activities.reduce((acc, cur) => acc + cur.value, 0);
  const totalCredits = credits.reduce((acc, cur) => acc + cur.amount, 0);

  const percentage = (totalSpent / total) * 100;

  const remaining = total + totalCredits - totalSpent;
  const remainingMonths = 12 - new Date().getMonth();

  const avg = Math.ceil(remaining / remainingMonths);

  return (
    <div className="border border-gray-200 rounded-md p-4 flex-1">
      <h2 className="text-gray-700 font-semibold text-lg">Annual budget</h2>
      <div className="mt-2">
        <p className="text-3xl font-bold">{formatNumberWithSpaces(total)} €</p>
        <p className="text-sm text-gray-500">{avg} € per month from now</p>
      </div>
      <p className="text-gray-600 text-sm mt-4">{`${percentage.toFixed(2)}% used til date`}</p>
    </div>
  );
};

const Budgets = ({ budgets }) => {
  if (!budgets) return <div />;

  return (
    <div className="border border-gray-200 rounded-md p-4 flex-1">
      {budgets.map((budget) => {
        let progressPercentage = ((budget.amountUsed || 0) / budget.amount) * 100;
        if (progressPercentage < 0) progressPercentage = 0;
        if (progressPercentage > 100) progressPercentage = 100;

        return (
          <div key={budget._id} className="mb-4">
            <div className="flex items-center justify-between mb-2">
              <p>{budget.name}</p>
              <p>{`${Math.ceil(budget.amountUsed || 0)} / ${budget.amount}`}</p>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-4">
              <div className="bg-blue-600 h-4 rounded-full" style={{ width: `${progressPercentage}%` }}></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SelectYear = ({ value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="block w-32 px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary">
    <option value="">All</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
  </select>
);


const Chart = ({ activities, banks, budgets, credits }) => {
  const hasrecurring = budgets.some((e) => e.recurring);

  if (!hasrecurring) return null;

  const arr = [];
  const beginingOfThisYear = new Date(new Date().getFullYear(), 0, 1);
  const date = new Date(beginingOfThisYear);

  if (!activities || !banks || !budgets) return <div />;

  let accumulatedCost = 0;
  let accumulatedBudget = 0;

  const totalBudget = budgets.reduce((acc, cur) => acc + cur.amount, 0);
  const avg = Math.ceil(totalBudget / 12);

  for (let i = 0; i < 12; i++) {
    const month = date.toLocaleString("default", { month: "long" });

    if (date < new Date()) {
      const findActivities = activities.filter((activity) => {
        const m = new Date(activity.date).toLocaleString("default", { month: "long" });
        return m === month;
      });

      const findBanks = banks.filter((bank) => {
        const m = new Date(bank.date).toLocaleString("default", { month: "long" });
        return m === month;
      });

      const totalBanks = findBanks.reduce((acc, cur) => acc + cur.amount, 0);
      const totalActivities = findActivities.reduce((acc, cur) => acc + cur.value, 0);
      accumulatedCost += -totalBanks + totalActivities;
    } else {
      accumulatedCost = 0;
    }

    // startAt
    // // endAt
    // const findBudgets = budgets.filter((budget) => {
    //   const m = new Date(budget.startAt).toLocaleString("default", { month: "long" });
    //   return m === month;
    // });

    // const totalBudget = findBudgets.reduce((acc, cur) => acc + cur.amount, 0);

    // console.log(`totalBudget`, totalBudget);
    accumulatedBudget += avg;

    arr.push({ month, accumulatedCost: Math.ceil(accumulatedCost), accumulatedBudget: Math.ceil(accumulatedBudget) });
    date.setMonth(date.getMonth() + 1);
  }

  return (
    <div className="border border-gray-200 rounded-md p-4 w-[65%]">
      <h1 className="text-sm font-semibold leading-6 text-gray-900 mb-5">Budget consumed last 12 months</h1>
      <div className="h-[500px] w-full">
        <ResponsiveBar
          data={arr}
          keys={["accumulatedCost", "accumulatedBudget"]}
          indexBy="month"
          margin={{ top: 10, right: 0, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          groupMode="grouped"
          defs={[
            { id: "dots", type: "patternDots", background: "inherit", color: "#38bcb2", size: 4, padding: 1, stagger: true },
            { id: "lines", type: "patternLines", background: "inherit", color: "#eed312", rotation: -45, lineWidth: 6, spacing: 10 },
          ]}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: "Money", legendPosition: "middle", legendOffset: -50 }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color" }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </div>
  );
};
