import React from "react";

export default ({ data }) => {
  return (
    <div>
      <div className="p-2">
        <pre className="text-xs bg-gray-800 text-gray-100 rounded p-2">{JSON.stringify(data, null, 2)}</pre>
      </div>
    </div>
  );
};
