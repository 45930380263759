import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import Modal from "../../components/modal";

import QueryBuilder from "./queryBuilder";

import { timeSince } from "../../utils";

import api from "../../services/api";

const Missions = () => {
  const [missions, setMissions] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ search: "", query: null, page: 1 });
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    update();
  }, [filters]);

  async function update() {
    setLoading(true);
    const { data, total: t } = await api.post(`/mission/search`, filters);
    setMissions(data);
    setTotal(t);
    setLoading(false);
  }

  return (
    <div className="flex w-full space-x-4">
      <Alerts filters={filters} setFilters={setFilters} alert={alert} setAlert={setAlert} />
      <div className="py-4 mb-4 w-full flex-1">
        <div className="flex items-center mb-4 space-x-4 w-full">
          <div className="bg-white mb-1 rounded-xl">
            <Search filters={filters} setFilters={(e) => setFilters({ ...filters, ...e })} />
          </div>
          <div className="!ml-auto flex items-center space-x-2">{alert && <Settings alert={alert} setAlert={setAlert} />}</div>
          <div className="!ml-auto flex items-center space-x-2">{!alert && <CreateAlert filters={filters} />}</div>
        </div>
        <div className="text-sm font-medium text-gray-700">{`${total} results`}</div>
        <Results loading={loading} missions={missions} total={total} filters={filters} />
        <div className="mb-2 mt-2">
          <Pagination
            per_page={filters.perPage}
            total={total}
            onNext={() => setFilters((f) => ({ ...f, page: +f.page + 1 }))}
            onPrevious={() => setFilters((f) => ({ ...f, page: +f.page - 1 }))}
            currentPage={filters.page}
          />
        </div>
      </div>
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState();
  const [advanced, setAdvanced] = useState(false);

  useEffect(() => {
    if (filters.query) {
      setQuery(filters.query);
      setAdvanced(true);
      setSearch("");
    } else {
      setAdvanced(false);
      setQuery(null);
    }
  }, [filters]);

  function display() {
    if (advanced) {
      return (
        <div className="pr-2 pl-2 flex items-center space-x-4">
          <QueryBuilder query={query} onQuery={(query) => setQuery(query)} />
          <button className="btn btn-primary py-1.5" onClick={() => setFilters({ query, search: "", page: 1 })}>
            Search
          </button>
        </div>
      );
    }

    return (
      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-4">
          <input className="searchbox max-w-md" placeholder="Search by job title, skill, category etc." value={search} onChange={(e) => setSearch(e.target.value)} />
          <button className="btn btn-primary py-1.5" onClick={() => setFilters({ search, query: null })}>
            Search
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1">
      <div className="flex items-center space-x-2">
        <div>{display()}</div>
        <label className="relative flex items-center cursor-pointer">
          <input type="checkbox" value="" className="sr-only peer" checked={advanced} onChange={(e) => setAdvanced(e.target.checked)} />
          <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
          <span className="ml-2 text-sm font-medium text-primary">Query Builder</span>
        </label>
      </div>
    </div>
  );
};

const Alerts = ({ filters, setFilters, alert, setAlert }) => {
  const [alerts, setAlerts] = useState([]);
  const [alertId, setAlertId] = useState(null);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    setAlertId(queryParameters.get("alertId"));
    run();
  }, [filters]);

  async function run() {
    const { data } = await api.get("/alert");
    setAlerts(data);
  }

  useEffect(() => {
    if (!alerts) return;
    const a = alerts.find((e) => e._id.toString() === alertId);
    if (!a) return;
    setAlert(a);
    setFilters({ query: a.query.bool, search: "", page: 1 });
  }, [alerts]);

  return (
    <div className="max-w-[160px] w-full border-r border-gray-300 pt-5 px-2">
      <div
        className={`p-1 border-blue-600 cursor-pointer ${!alert?._id && "text-blue-600"}`}
        onClick={() => {
          setFilters({ search: "", query: null, page: 1 });
          setAlert(null);
        }}>
        All
      </div>
      {alerts.map((a) => {
        return (
          <div
            key={a._id}
            className={`p-1 text-base cursor-pointer ${alert?._id === a._id && "text-blue-600 underline"}`}
            onClick={() => {
              setAlert(a);
              setFilters({ query: a.query, search: "", page: 1 });
            }}>
            {a.name}
          </div>
        );
      })}
    </div>
  );
};

const CreateAlert = ({ filters }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onCreate() {
    if (!values.name) return toast.error("Missing Name !");
    let query = filters.query;
    const { data } = await api.post(`/alert`, { query, name: values.name, frequency: values.frequency });
    toast.success("Alert created successfully");
    setOpen(false);
  }

  return (
    <div className="mt-5">
      <button
        className="btn btn-primary text-xs"
        onClick={() => {
          if (!filters.query) return toast.error("Please work with a query");
          setOpen(true);
        }}>
        Create an alert
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="projectsInput block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <select
          className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px] m-0"
          value={values.frequency}
          onChange={(e) => setValues({ ...values, frequency: e.target.value })}>
          {["DAILY", "REAL TIME"].map((t) => (
            <option value={t}>{t}</option>
          ))}
        </select>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};

export default Missions;

const Results = ({ loading, missions }) => {
  if (loading) {
    return (
      <div className="flex justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="space-y-5">
        {missions.map((mission) => {
          return (
            <Link
              key={mission._id}
              className="transition duration-500 hover:shadow-md cursor-pointer rounded-md bg-white border border-gray-200 p-4 grid grid-cols-12 gap-4"
              to={`/missions/${mission._id}`}>
              <div className="flex items-center justify-between col-span-6">
                <h3 className="text-base font-medium mb-1 text-[#346abf] cursor-pointer">{mission.title}</h3>
              </div>
              {(mission.location || mission.country) && (
                <div className="flex items-center text-sm space-x-1 col-span-2">
                  {/* <span className="text-dull-grey">Location: </span> */}
                  <span className="font-medium text-dull-black">{mission.location || mission.country}</span>
                </div>
              )}

              {mission.origin && (
                <div className="flex items-center text-sm space-x-1 col-span-2">
                  <span className="font-medium text-dull-black">{mission.origin}</span>
                </div>
              )}
              <div className="col-span-2">
                <div className=" font-medium text-[#535556]">{timeSince(mission.created_at)}</div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

const Settings = ({ alert, setAlert }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    setValues(alert);
  }, []);

  async function onCreate() {
    const { data } = await api.put(`/alert/${alert._id}`, values);
    setAlert(data);
    toast.success("Alert updated successfully");
    setOpen(false);
  }
  return (
    <div>
      <button className="btn gray-btn p-2" onClick={() => setOpen(true)}>
        Settings
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Frequency</div>
          <select
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.frequency}
            onChange={(e) => setValues({ ...alert, frequency: e.target.value })}>
            {["DAILY", "REAL TIME"].map((t) => (
              <option value={t}>{t}</option>
            ))}
          </select>
        </div>
        <button
          className="btn btn-primary p-2 text-sm"
          onClick={async () => {
            await api.put(`/alert/${alert._id}`, { query: filters.query, frequency: alert.frequency });
            toast.success("Alert updated successfully");
          }}>
          {`Update ${alert.name}`}
        </button>
        <button
          className="btn red-btn p-2 text-sm"
          onClick={async () => {
            if (!window.confirm("Are you sure you want to delete this alert ?")) return;
            await api.remove(`/alert/${alert._id}`);
            toast.success("Alert deleted successfully");
            setAlert(null);
            setFilters({ search: "", query: null, page: 1 });
          }}>
          {`Delete ${alert.name}`}
        </button>
        <button className="btn btn-primary" onClick={onCreate}>
          Save
        </button>
      </Modal>
    </div>
  );
};
