import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";

import Modal from "../../../components/modal";
import MultiSelect from "../../../components/MultiSelect";
import RichTextEditor from "../../../components/RichTextEditor";
import FileInput from "../../../components/FileInput";
import api from "../../../services/api";

export default ({ agent }) => {
  const [features, setFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState();

  useEffect(() => {
    get();
  }, []);

  const get = async () => {
    try {
      const { data } = await api.post("/agent_feature/search", { agent_id: agent._id });
      setFeatures(data);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  };

  return (
    <div className="py-8 px-10">
      <div className="flex items-center justify-between mb-4">
        <Create onCreate={(e) => setFeatures([...features, e])} agent={agent} />
        <Feature
          feature={selectedFeature}
          setFeature={(e) => {
            setSelectedFeature(e);
            get();
          }}
        />
      </div>
      <ul className="flex flex-col gap-1">
        <li className="w-full list-none flex flex-row gap-2 px-4">
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Name</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Tags</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Created at</h3>
        </li>
        {features.map((e, i) => (
          <li key={i} className="list-none" onClick={() => setSelectedFeature(e)}>
            <div className="p-4 flex flex-col gap-2 md:flex-row items-center w-full rounded border border-gray-200 text-sm text-gray-700 hover:border-sky-700 appearance-none">
              <div className="flex-1 flex items-center gap-2">{e.title}</div>
              <div className="flex-1">{e.tags.join(", ")}</div>
              <div className="flex-1">{new Date(e.created_at).toLocaleDateString()}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Feature = ({ feature, setFeature }) => {
  if (!feature) return <div />;

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/agent_feature/${feature._id}`);
    toast.success("successfully removed!");
    setFeature(null);
  }

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/agent_feature/${feature._id}`, feature);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setFeature(null);
      toast.success("Updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  return (
    <Modal isOpen={!!feature} className="max-w-3xl w-full p-6" onClose={() => setFeature(null)}>
      <div className="grid grid-cols-2 gap-4 p-2">
        <div className="w-full space-y-2">
          <label className="font-semibold">Title</label>
          <input type="text" className="input" value={feature.title} onChange={(e) => setFeature({ ...feature, title: e.target.value })} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Id</label>
          <p>{feature._id}</p>
        </div>
        <SelectTags value={feature.tags} onChange={(e) => setFeature({ ...feature, tags: e })} agentId={feature.agent_id} />
        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Files</label>
          <FileInput value={feature.files} folder="agent" allowMultiple={true} name="files" onChange={(e) => setFeature({ ...feature, files: e.target.value })} />
        </div>
        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Description</label>
          <textarea
            value={feature.description}
            className="input"
            rows={10}
            onChange={(e) => setFeature({ ...feature, description: e.target.value })}
          />
        </div>
      </div>
      <div className="flex gap-4 justify-end">
        <button className="red-btn" onClick={onDelete}>
          Delete
        </button>
        <button className="blue-btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
};

const SelectTags = ({ value, onChange, agentId }) => {
  const [tags, setTags] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/agent_feature/aggregate", { agent_id: agentId, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-col gap-2">
      <MultiSelect
        values={tags.map((tag) => ({ value: tag, label: tag }))}
        id="select-tags"
        options={options}
        onSelectedChange={(e) => {
          setTags(e.map((e) => e.value));
          onChange(e.map((e) => e.value));
        }}
        placeholder="Tags"
        onCreateNew={(e) => {
          setTags([...tags, e.value]);
          get();
        }}
      />

      <div className="flex flex-wrap gap-1">
        {tags.map((tag) => (
          <span
            key={tag}
            className="text-xs w-fit  text-gray-600 bg-gray-100 px-2 py-1 rounded border border-gray-200 hover:bg-gray-200 cursor-pointer"
            onClick={() => {
              const newTags = tags.filter((t) => t !== tag);
              setTags(newTags);
              onChange(newTags);
            }}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

const Create = ({ onCreate, agent }) => {
  const [values, setValues] = useState({ name: "", description: "" });
  const [open, setOpen] = useState(false);

  const handleSubmit = async () => {
    try {
      const v = { ...values, agent_id: agent._id, agent_name: agent.name };
      const res = await api.post("/agent_feature", v);
      if (!res.ok) throw res;
      toast.success("Created!");
      setOpen(false);
      onCreate(res.data);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  };

  return (
    <>
      <button className="btn blue-btn" onClick={() => setOpen(true)}>
        Create a new feature
      </button>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="p-8">
          <div className="grid grid-cols-2 gap-4 ">
            <div className="w-full col-span-2">
              <div className="text-sm font-medium mb-2">Title</div>
              <input className="projectsInput block" name="title" value={values.title} onChange={(e) => setValues({ ...values, title: e.target.value })} />
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <button className="btn blue-btn w-32" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
