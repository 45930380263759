import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";

import Kpis from "../../../components/kpi";

ChartJS.register(...registerables);

const Dashboard = ({ project }) => {
  return (
    <div className="py-4 space-y-6">
      <Kpis project={project} />
    </div>
  );
};

export default Dashboard;
