import React, { useState, useEffect } from "react";

import Loader from "../../components/loader";
import api from "../../services/api";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const CATEGORIES = ["Code", "Communication", "Leadership", "Human", "DevOps", "General", "Other"];

export default () => {
  const [skill, setSkill] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    get();
  }, []);

  async function get() {
    try {
      setLoading(true);
      const { data } = await api.get(`/skill/${id}`);
      setSkill(data);
    } catch (e) {
      console.log("a", e);
    } finally {
      setLoading(false);
    }
  }

  async function update() {
    try {
      const { data, ok } = await api.put(`/skill/${id}`, skill);
      if (ok) toast.success("Skill updated");
      console.log(data);
    } catch (e) {
      console.log("a", e);
    }
  }

  if (!skill) return <Loader />;

  return (
    <div className="flex flex-col gap-3">
      <div>
        <span>category</span>
        <select className="w-full" onChange={(e) => setSkill({ ...skill, category: e.target.value })} value={skill.category}>
          <option>--select category--</option>
          {CATEGORIES.map((category) => {
            return (
              <option key={category} value={category}>
                {category}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <span>subCategory</span>
        <input className="w-full" placeholder="name" onChange={(e) => setSkill({ ...skill, subCategory: e.target.value })} value={skill.subCategory}></input>
      </div>
      <div>
        <span>description</span>
        <input className="w-full" placeholder="description" onChange={(e) => setSkill({ ...skill, description: e.target.value })} value={skill.description}></input>
      </div>
      <div>
        <span>version</span>
        <input className="w-full" placeholder="version" onChange={(e) => setSkill({ ...skill, version: e.target.value })} value={skill.version}></input>
      </div>
      <button className="blue-btn" onClick={update}>
        update
      </button>
      {JSON.stringify(skill)}
    </div>
  );
};
