import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { Formik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";

import _ from "lodash";

import api from "../../../services/api";
import { COLORS, Pie } from "../../../components/chart";
import Modal from "../../../components/modal";
import Select from "../../../components/Select";
import Switch from "../../../components/Switch";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "Septembe", "October", "November", "December"];
const TYPES = ["timeoff", "prospection", "invoice", "admin", "invest"];
const TYPES_LABEL = { timeoff: "Timeoff", prospection: "Prospection", invoice: "Invoice", admin: "Admin", invest: "Invest" };
const TAXES = ["FR 20%", "NL 21%", "N/A (Not applicable)"];

const Info = ({ invoice, setInvoice, project }) => {
  const navigate = useNavigate();
  const [budget, setBudget] = useState({});
  const [profitShares, setProfitShares] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const totalCost = invoice.itemsCompta.reduce((acc, a) => acc + a.totalCost, 0);
  const totalPrice = invoice.itemsInvoice.reduce((acc, a) => acc + a.totalPrice, 0);

  const graphData = [
    { name: "Cost", value: totalCost, color: COLORS.blue },
    { name: "Discount", value: (invoice.subTotal * invoice.discount) / 100, color: COLORS.yellow },
    { name: "Profit", value: invoice.profit, color: COLORS.green },
  ];
  if (profitShares) {
    graphData.push({ name: "Shares", value: profitShares.reduce((acc, e) => acc + (e.amount || 0), 0), color: COLORS.purple });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resBudget = await api.get(`/budget/${invoice.budgetId}`);
        if (resBudget.ok) setBudget(resBudget.data);

        const resShares = await api.post(`/wallet_transaction/search`, { invoiceId: invoice._id });
        if (resShares.ok && resShares.data.length) setProfitShares(resShares.data);
        else setProfitShares(project.profitSharing);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async () => {
    const res = window.confirm("Are you sure you want to delete this invoice?");
    if (!res) return;
    try {
      const { code } = await api.remove(`/invoice/${invoice._id}`);
      if (code) return toast.error(code);
      toast.success("successfully removed!");
      navigate("/invoice");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex flex-col gap-10">
      <EditInfoModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} invoice={invoice} setInvoice={setInvoice} />

      <div className="grid grid-cols-1 md:grid-cols-2 items-start justify-between gap-10">
        <div className="space-y-2">
          <h2 className="text-gray-800">Invoice status</h2>
          <div className="grid grid-cols-2 gap-6 p-6 border border-gray-300 rounded">
            <label className="text-sm text-gray-950 font-semibold text-right">Freezed</label>
            <Switch checked={invoice.freezed === "yes"} onChange={(e) => setInvoice({ ...invoice, freezed: e ? "yes" : "no" })} />
            <label className="text-sm text-gray-950 font-semibold text-right">Sent</label>
            <Switch checked={invoice.sent === "yes"} onChange={(e) => setInvoice({ ...invoice, sent: e ? "yes" : "no" })} />
            <label className="text-sm text-gray-950 font-semibold text-right">Received</label>
            <Switch checked={invoice.received === "yes"} onChange={(e) => setInvoice({ ...invoice, received: e ? "yes" : "no" })} />
          </div>
        </div>
        <div className="space-y-2">
          <h2 className="text-gray-800">Budget</h2>
          <div className="flex items-start p-6 border border-gray-300 rounded">
            <div className="flex-1 flex flex-col gap-2">
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Budget Id:</label>
                <p className="text-sm">{budget._id}</p>
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Budget name:</label>
                <p className="text-sm">{budget.name}</p>
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Budget amount:</label>
                <p className="text-sm">{(budget.amount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Status:</label>
                <p className="text-sm">{budget.status === "active" ? <span className="text-green-600">Active</span> : <span className="text-gray-600">Inactive</span>}</p>
              </div>
            </div>
            <Link className="blue-btn" to={`/project/${project._id}/budget`}>
              See budgets
            </Link>
          </div>
        </div>
        <div className="space-y-2">
          <h2 className="text-gray-800">Billing informations</h2>
          <div className="flex items-start p-6 border border-gray-300 rounded">
            <div className="flex-1 flex flex-col gap-2">
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Invoice name:</label>
                <p className="text-sm">{invoice.name}</p>
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Organisation:</label>
                <p className="text-sm">{invoice.organisation_name}</p>
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Date:</label>
                <p className="text-sm">
                  {MONTHS[new Date(invoice.date).getMonth()]} {new Date(invoice.date).getFullYear()}
                </p>
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Type:</label>
                <p className="text-sm">{TYPES_LABEL[invoice.type] || " -- "}</p>
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Budget:</label>
                <p className="text-sm">{invoice.budgetName}</p>
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Tax:</label>
                <p className="text-sm">{invoice.taxType || " -- "}</p>
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-950 font-semibold mr-1">Markup on expenses:</label>
                <p className="text-sm">{`${invoice.markupExpenses}%` || " -- "}</p>
              </div>
            </div>
            <button className="gray-btn" onClick={() => setIsEditModalOpen(true)}>
              Edit
            </button>
          </div>
        </div>

        <div className="space-y-2">
          <h2 className="text-gray-800">Invoice</h2>
          <div className="flex items-center gap-2 p-6 border border-gray-300 rounded">
            <div className="w-1/3 h-60">
              {totalPrice > 0 ? (
                <Pie data={graphData} />
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="text-gray-500">No data</p>
                </div>
              )}
            </div>
            <div className="flex-1 flex flex-col px-4">
              <div className="flex w-full">
                <label className="text-sm text-gray-950 font-semibold mr-1">Cost</label>
                <div className="flex-1 border-b mb-1 border-dotted border-gray-600" />
                <p className="text-sm ml-2">{totalCost.toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
              </div>
              <div className="flex w-full">
                <label className="text-sm text-gray-950 font-semibold mr-1">Price</label>
                <div className="flex-1 border-b mb-1 border-dotted border-gray-600" />
                <p className="text-sm ml-2">{invoice.subTotal.toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
              </div>
              <div className="flex w-full">
                <label className="text-sm text-gray-950 font-semibold mr-1">Discount</label>
                <div className="flex-1 border-b mb-1 border-dotted border-gray-600" />
                <p className="text-sm ml-2">
                  -{(invoice.subTotal - (invoice.subTotal * (100 - invoice.discount)) / 100).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                </p>
              </div>
              <div className="flex w-full">
                <label className="text-sm text-gray-950 font-semibold mr-1">Tax</label>
                <div className="flex-1 border-b mb-1 border-dotted border-gray-600" />
                <p className="text-sm ml-2">{(invoice.taxValue || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
              </div>
              {profitShares && profitShares.length > 0 && (
                <div className="flex w-full">
                  <label className="text-sm text-gray-950 font-semibold mr-1">Shares</label>
                  <div className="flex-1 border-b mb-1 border-dotted border-gray-600" />
                  <p className="text-sm ml-2">{profitShares.reduce((acc, e) => acc + (e.amount || 0), 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
                </div>
              )}
              <div className="h-px w-full bg-gray-300 my-6" />
              <div className="flex w-full">
                <label className="text-sm text-gray-950 font-semibold mr-1">Total sent</label>
                <div className="flex-1 border-b mb-1 border-dotted border-gray-600" />
                <p className="text-sm ml-2">{invoice.total.toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
              </div>
              <div className="flex w-full">
                <label className="text-sm text-gray-950 font-semibold mr-1">Profit</label>
                <div className="flex-1 border-b mb-1 border-dotted border-gray-600" />
                <p className="text-sm ml-2">{invoice.profit.toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-10">
        <button className="red-btn" onClick={handleDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

const EditInfoModal = ({ isOpen, onClose, invoice, setInvoice }) => {
  const [loading, setLoading] = useState(true);
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    api.get("/organisation").then((res) => {
      setOrganisations(res.data);
      setLoading(false);
    });
  }, []);

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.name)) errors.name = "Ce champ est requis";
    if (validator.isEmpty(values.organisation?._id)) errors.organisation = "Ce champ est requis";
    if (validator.isEmpty(values.year.toString())) errors.year = "Ce champ est requis";
    if (validator.isEmpty(values.month)) errors.month = "Ce champ est requis";
    if (values.budget && !validator.isNumeric(values.budget.toString())) errors.budget = "Ce champ doit être un nombre";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    if (loading) return;

    const data = {
      name: values.name,
      organisation_id: values.organisation._id,
      organisation_name: values.organisation.name,
      date: new Date(Date.UTC(values.year, MONTHS.indexOf(values.month), 1)),
      markupExpenses: values.markupExpenses,
      type: values.type,
      budgetId: values.budgetId,
      budgetName: values.budgetName,
      billing: values.billing,
      taxType: values.taxType,
      dueAt: values.dueAt,
    };

    setLoading(true);
    api.put(`/invoice/${invoice._id}`, data).then((res) => {
      setInvoice(res.data);
      toast.success("Invoice updated");
      setLoading(false);
      onClose();
    });
  };

  if (loading) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/3 overflow-auto">
      <div className="py-6 px-10">
        <h2 className="text-xl font-semibold mb-4">Edit invoice</h2>

        <Formik
          initialValues={{
            name: invoice.name,
            organisation: organisations.find((o) => o._id === invoice.organisation_id),
            year: new Date(invoice.date).getFullYear(),
            month: MONTHS[new Date(invoice.date).getMonth()],
            type: invoice.type || "",
            budgetId: invoice.budgetId || "",
            budgetName: invoice.budgetName || "",
            billing: invoice.billing || "",
            projectId: invoice.projectId,
            taxType: invoice.taxType || "",
            markupExpenses: invoice.markupExpenses || 0,
            dueAt: invoice.dueAt ? invoice.dueAt.split("T")[0] : "",
          }}
          onSubmit={handleSubmit}>
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form className="flex flex-col gap-3">
              <div className="w-full">
                <label className="text-sm text-gray-950 font-semibold mb-2" htmlFor="name">
                  Invoice Name
                </label>
                <input className="input" name="name" id="name" value={values.name} onChange={handleChange} />
                {errors.name && <p className="text-sm text-red-600">{errors.name}</p>}
              </div>
              <div className="w-full">
                <label className="text-sm text-gray-950 font-semibold mb-2">Organisation</label>
                <Select
                  options={organisations}
                  value={values.organisation}
                  onChange={(e) => setFieldValue("organisation", e)}
                  placeholder="Organization"
                  width="w-full"
                  getValue={(v) => v._id}
                  getLabel={(v) => v.name}
                />
                {errors.organisation && <p className="text-sm text-red-600">{errors.organisation}</p>}
              </div>
              <div className="w-full flex items-center gap-3">
                <div className="flex-1">
                  <label className="text-sm text-gray-950 font-semibold mb-2">Year</label>
                  <Select options={[2021, 2022, 2023, 2024]} value={values.year} onChange={(e) => setFieldValue("year", e)} placeholder="1999" width="w-full" />
                  {errors.year && <p className="text-sm text-red-600">{errors.year}</p>}
                </div>
                <div className="flex-1">
                  <label className="text-sm text-gray-950 font-semibold mb-2">Month</label>
                  <Select options={MONTHS} value={values.month} onChange={(e) => setFieldValue("month", e)} placeholder="March" width="w-full" />
                  {errors.month && <p className="text-sm text-red-600">{errors.month}</p>}
                </div>
              </div>
              <div className="w-full">
                <label className="text-sm text-gray-950 font-semibold mb-2">Type</label>
                <Select options={TYPES} value={values.type} onChange={(e) => setFieldValue("type", e)} placeholder="Prospection" width="w-full" getLabel={(v) => TYPES_LABEL[v]} />
                {errors.type && <p className="text-sm text-red-600">{errors.type}</p>}
              </div>
              <div className="w-full">
                <SelectBudget
                  value={{ _id: values.budgetId, name: values.budgetName }}
                  projectId={values.projectId}
                  onChange={(e) => {
                    setFieldValue("budgetId", e._id);
                    setFieldValue("budgetName", e.name);
                  }}
                />
              </div>

              <div className="w-full">
                <label className="text-sm text-gray-950 font-semibold mb-2" htmlFor="markup">
                  Markup on expenses
                </label>
                <div className="w-full relative">
                  <input className="input pr-8" type="number" name="markupExpenses" id="markupExpenses" value={values.markupExpenses} onChange={handleChange} />
                  <span className="absolute right-3 top-1/2 -translate-y-1/2">%</span>
                </div>
              </div>
              <div className="w-full">
                <label className="text-sm text-gray-950 font-semibold mb-2">Tax</label>
                <Select options={TAXES} value={values.taxType} onChange={(e) => setFieldValue("taxType", e)} placeholder="Taxes" width="w-full" />
                {errors.taxType && <p className="text-sm text-red-600">{errors.taxType}</p>}
              </div>
              <div className="flex items-center gap-3 mt-6">
                <button className="gray-btn flex-1" type="button" onClick={onClose}>
                  Cancel
                </button>
                <button className="blue-btn flex-1" type="submit" disabled={loading}>
                  {loading ? "Loading..." : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

const SelectBudget = ({ value, onChange, projectId }) => {
  const [options, setOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const query = { limit: 20, status: "active", projectId };
    const res = await api.post("/budget/search", query);
    setOptions(res.data.sort((a, b) => (a.name || "").localeCompare(b.name)));
  }

  async function onSelect(e) {
    const selected = options.find((o) => o._id === e.target.value);
    setSelectedOption(selected);
    onChange(selected);
  }

  if (!options) return <div>loading</div>;

  return (
    <select value={selectedOption?._id || ""} onChange={onSelect} className="w-full border border-gray-200 rounded-md p-2">
      <option value="">Select budget</option>
      {options.map((e, i) => (
        <option key={i} value={e._id}>
          {e.name}
        </option>
      ))}
    </select>
  );
};

export default Info;
