import React from "react";


export default ({ tool }) => {
  return (
    <div className="mt-2">
      <div className="p-2">
        <pre className="text-xs bg-gray-800 text-gray-100 rounded p-2">{JSON.stringify(tool, null, 2)}</pre>
      </div>
    </div>
  );
};
