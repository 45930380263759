import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Loader from "../../components/loader";

import api from "../../services/api";
import { MissionIcon } from "../../assets/Icons";
import { formatDate } from "../../utils";
import Modal from "../../components/modal";
import { HiOutlineClock, HiOutlineDocumentText, HiOutlineMap, HiOutlineRss } from "react-icons/hi2";

export default () => {
  const [applications, setApplications] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [job, setJob] = useState();
  const [loadingJobPost, setLoadingJobPost] = useState(false);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    (async () => {
      const obj = {};
      obj.user_id = user._id;
      const { data } = await api.post("/application/search", obj);
      setApplications(data);
    })();
  }, []);

  const hanldeOpenModal = async (e) => {
    setLoadingJobPost(true);
    setOpenModal(true);
    const { data } = await api.get(`/jobpost/${e.jobpost_id}`);
    const { data: dataJob } = await api.get(`/job/${data.job_id}`);
    setJob(dataJob);
    setLoadingJobPost(false);
  };

  return (
    <div className="bg-white p-6 border border-gray-200 rounded-md shadow-md mb-10">
      <div className="text-2xl font-bold mb-4 text-gray-800">Your Current Applications</div>
      <div className="text-md mb-4 text-gray-600">Here you'll find all your current applications. To increase your chances of being selected: complete your profile!</div>
      <div className="py-4 space-y-4">
        {!applications ? (
          <Loader size="small" />
        ) : (
          applications.map((application) => (
            <div key={application.mission_id} className="flex flex-row no-underline  rounded-md p-2 my-2 mr-1 border border-gray-200">
              <div className="flex flex-row w-full items-center">
                <div className="h-12 w-12 rounded-xl m-2 flex flex-col justify-center bg-gray-100">
                  <MissionIcon />
                </div>
                <div className="flex flex-col w-full ml-4 my-2 justify-center items-start">
                  <div className="flex flex-row items-center w-full">
                    <button onClick={() => hanldeOpenModal(application)} className="text-lg text-gray-800 font-bold mr-auto hover:underline">
                      {application.mission_name || "Apply to a job!"}
                    </button>
                    <div className="text-sm font-medium text-gray-500 mr-5">{formatDate(application.created_at)}</div>
                  </div>

                  <div className="text-sm text-gray-600 mt-2">{application.organisation_name}</div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)} className="h-[90vh] w-[80vw]">
        {loadingJobPost ? <Loader /> : <Offer job={job} />}
      </Modal>
    </div>
  );
};

const Offer = ({ job }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm p-2 pt-10">
      <img className="object-contain p-1 rounded-md shadow-sm w-14 h-14 md:w-20 md:h-20 bg-light-white -translate-y-1/3 translate-x-1/2" src={job.project_logo} />
      <div className="flex flex-col gap-4 md:flex-row items-start justify-between px-10">
        <div className="flex flex-col">
          <h2 className="text-slate-700 text-xs md:text-normal uppercase font-sm tracking-wide">{job.project_name}</h2>
          <h1 className="textlg md:text-2xl text-black font-semibold mt-1 mb-2">{job.title}</h1>
          <div className="flex gap-x-5 gap-y-2 flex-wrap">
            {job.contract_type ? (
              <div className="flex items-center gap-1">
                <HiOutlineDocumentText className="text-gray-500" />
                <span className="text-gray-600 text-xs">{job.contract_type}</span>
              </div>
            ) : null}
            {job.contract_duration ? (
              <div className="flex items-center gap-1">
                <HiOutlineClock className="text-gray-500" />
                <span className="text-gray-600 text-xs">{job.contract_duration}</span>
              </div>
            ) : null}
            {job.location ? (
              <div className="flex items-center gap-1">
                <HiOutlineMap className="text-gray-500" />
                <span className="text-gray-600 text-xs">{job.location}</span>
              </div>
            ) : null}
            {job.remote ? (
              <div className="flex items-center gap-1">
                <HiOutlineRss className="text-gray-500" />
                <span className="text-gray-600 text-xs">{job.remote}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="px-3 py-4 md:!px-14 md:!py-16">
        <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
      </div>
    </div>
  );
};
