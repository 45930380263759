import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import api from "../../../services/api";
import SelectUser from "../../../components/selectUser";
import Select from "../../../components/Select";
import Combobox from "../../../components/Combobox";

const Answers = ({}) => {
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    load();
  }, [filters]);

  const load = async () => {
    try {
      const obj = { limit: 1000 };
      if (filters.status) obj.status = filters.status;
      if (filters.userId) obj.user_id = filters.userId;
      if (filters.questionId) obj.question_id = filters.questionId;
      navigate({ search: new URLSearchParams(filters).toString() });

      setLoading(true);
      const { data } = await api.post("/answer/search", obj);
      setAnswers(data);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const { ok, data } = await api.post("/question/search");
        if (ok) setQuestions(data);
      } catch (error) {
        toast.error("An error occured while fetching questions");
      }
    };
    fetchQuestions();
  }, []);

  return (
    <div className="mt-2 flow-root">
      <div className="flex flex-wrap gap-2">
        <span className={`inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium "text-gray-600"`}>
          <div className={`h-2 w-2 rounded-full bg-gray-400`} />
          {answers?.length} questions sent
        </span>
        <span className={`inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium "text-green-600"`}>
          <div className={`h-2 w-2 rounded-full bg-green-400`} />
          {answers?.filter((a) => a.status === "done")?.length} answered
        </span>
        <span className={`inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium "text-yellow-600"`}>
          <div className={`h-2 w-2 rounded-full bg-yellow-400`} />
          {answers?.filter((a) => a.status === "pending")?.length} pending
        </span>
      </div>
      <div className="w-full flex-col md:flex-row flex items-center gap-3">
        <div className="w-full">
          <label className="text-xs text-gray-500">Status</label>
          <Select
            options={["", "done", "pending"]}
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e })}
            placeholder="Status"
            width="w-full md:w-80"
            getValue={(o) => o}
            getLabel={(o) => o}
            disabled={loading}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">User</label>
          <SelectUser
            className="mb-3"
            label="user"
            userName={filters.userName}
            value={{ user_name: filters.userName, user_id: filters.userId }}
            onChange={(e) => {
              setFilters({ ...filters, userId: e._id, userName: e.name });
            }}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Question</label>
          <Combobox
            options={questions}
            value={questions.find((e) => e._id === filters.questionId)}
            onChange={(e) => setFilters({ ...filters, questionId: e?._id ?? null })}
            placeholder="Question"
            getValue={(o) => o._id}
            getLabel={(o) => o.title}
            match={(o, s) => o.title.toLowerCase().includes(s.toLowerCase())}
            width="w-full md:w-80"
            disabled={loading}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <div className="bg-white inline-block min-w-full align-middle overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="text-left text-sm font-semibold text-gray-900">
              <tr>
                <th className="py-2 px-3">Status</th>
                <th className="py-2 px-3">User</th>
                <th className="py-2 px-3">Question</th>
                <th className="py-2 px-3">Answer</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {answers.map((answer) => (
                <tr key={answer._id} className="even:bg-gray-100 whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 cursor-pointer">
                  <td className="py-1 px-3">
                    <Status status={answer.status} />
                  </td>
                  <td className="py-1 px-3">
                    <div className="flex items-center gap-1">
                      <img src={answer.user_avatar} alt="userlogo" className="rounded-full w-7 h-7" />
                      <div>{answer.user_name}</div>
                    </div>
                  </td>
                  <td className="py-1 px-3">
                    <Link className="hover:underline" to={`/questioning/${answer.question_id}`}>
                      {answer.question_title}
                    </Link>
                  </td>
                  <td className="py-1 px-3">{answer.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Status = ({ status }) => {
  let bgColor = "bg-gray-100";
  let textColor = "text-gray-600";
  let dotColor = "bg-gray-400";
  if (status === "pending") {
    bgColor = "bg-yellow-100";
    textColor = "text-yellow-600";
    dotColor = "bg-yellow-400";
  }
  if (status === "done") {
    bgColor = "bg-green-100";
    textColor = "text-green-600";
    dotColor = "bg-green-400";
  }
  return (
    <span className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}>
      <div className={`h-2 w-2 rounded-full ${dotColor}`} />
      {status}
    </span>
  );
};

export default Answers;
