import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { FunnelChart } from 'react-funnel-pipeline'
import 'react-funnel-pipeline/dist/index.css'

import Select from "../../../components/Select";

export default ({ }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    try {
      setLoading(true);
      const { data } = await api.post("/user/pool-applicant-stats");
      setData(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="overflow-x-auto">
      <div>
        <div className="px-1 text-sm text-gray-600 font-medium">{"Lead"}</div>
        <Select
          options={data?.map((row) => row.user_name)}
          value={user}
          onChange={(e) => setUser(e)}
          placeholder="Lead"

        />
      </div>

      {data && data.filter((row) => row.user_name === user).map((row) => {
        let months = row.months.slice(-4)

        return (
          <div>
            <div className="grid grid-cols-4 gap-4 p-4" >
              {months.map((month) => {
                let stats = month.stats
                let date = month.date
                stats = Object.entries(stats).map(([key, value]) => { return { value: value, name: key } })
                return (
                  <div >
                    <div className="px-1 text-md text-gray-600 font-medium"> {new Date(date).toLocaleDateString("fr-FR", { month: "long", year: "numeric" })}</div>
                    <FunnelChart
                      chartHeight={300}
                      showRunningTotal={true}
                      showLabels={true}
                      colorScheme={["#a5b4fc", "#c4b5fd", "#e0d8fd", "#ede9fe", "#f5f3ff"]}
                      data={stats}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )
      })}
    </div>
  );
};
