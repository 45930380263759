import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Board from "react-trello";
import toast from "react-hot-toast";
import { GrFormClose, GrFavorite } from "react-icons/gr";

import api from "../../services/api";

import SelectUser from "../../components/selectUser";
import SelectProject from "../../components/selectProject";
import SelectJob from "../../components/selectJob";
import { TRADUCTION_STATUS } from "../../utils/applicant";

const categories = ["NEW", "SHORTLIST", "CONTACTED", "INTERVIEW", "TECHNICAL_TEST", "FINAL_LIST", "HIRE", "REJECT"];

const LaneHeader = (lane) => {
  return (
    <div className="flex items-center justify-between">
      <div>{`${TRADUCTION_STATUS[lane.id]} (${lane.cards.length})`}</div>
    </div>
  );
};

const ApplicantIndex = () => {
  const user = useSelector((state) => state.Auth.user);
  const [filters, setFilters] = useState({ user });

  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    load();
  }, [filters]);

  async function load() {
    const obj = {};
    if (filters.user) obj.user_id = filters.user._id;
    if (filters.project) obj.project_id = filters.project._id;
    if (filters.job) obj.job_id = filters.job._id;
    const { data } = await api.post(`/user/search`, obj);
    setUsers(data.users);
  }

  return (
    <div className="flex flex-col">
      <div className="p-2 flex items-center gap-2">
        <SelectUser value={filters.user} onChange={(e) => setFilters({ ...filters, user: e })} />
        <SelectProject value={filters.project} onChange={(e) => setFilters({ ...filters, project: e })} />
        <SelectJob value={filters.job} onChange={(e) => setFilters({ ...filters, job: e })} />
      </div>
      {/* <ApplicantPreview applicant={selected} onClose={() => setSelected(null)} /> */}
      <Filters filters={filters} setFilters={setFilters} />
      <Board
        hideCardDeleteIcon
        components={{ Card, LaneHeader }}
        data={{
          lanes: categories.map((category) => ({
            id: category,
            title: `${category} (${users.filter((user) => user.status === category).length})`,
            cards: users.filter((user) => user.status === category).map((user) => ({ user, id: user._id })),
          })),
        }}
        onCardClick={(cardId) => {
          const s = users.find((f) => f._id === cardId);
          setSelected(s);
          // window.open(`/pool/${cardId}`, "_blank");
        }}
        onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId) => {
          const user = users.find((f) => f._id === cardId);
          user.status = toLaneId;
          api.put(`/user/${cardId}`, user);
          toast.success("Applicant status updated");
        }}
      />
    </div>
  );
};

const Card = ({ user, onClick }) => {
  return (
    <div className="cursor-pointer min-w-230 bg-white p-2 mb-2 rounded-sm shadow-sm" onClick={onClick}>
      <div className="font-semibold flex flex-center">
        <div>{user.name}</div>
        {user.favorite && <GrFavorite />}
      </div>
      <div className="text-sm text-[#676D7C]">{user.job_title}</div>
      <div className="flex gap-1 my-1">
        <div className="text-sm px-2 text-[13px] rounded-lg bg-yellow-500 text-white">{user.project_name}</div>
      </div>
      <div></div>
      <div>
        <div className="flex justify-between items-end">
          <div className="text-sm text-[#676D7C]">{`${user?.created_at?.substring(0, 10)} (${displayDaysAgo(user.created_at)} days ago)`}</div>
          {user.user_id && <img src={user.user_avatar} alt="userlogo" className="rounded-full w-7 h-7" />}
        </div>
      </div>
    </div>
  );
};

const Filters = ({ filters, setFilters }) => {
  const arr = [];

  const Tag = ({ children, onClick }) => {
    return (
      <div className="flex items-center gap-2 p-1  text-sm px-2 text-[13px] rounded-lg bg-yellow-500 text-white cursor-pointer" onClick={onClick}>
        {children}
        <GrFormClose />
      </div>
    );
  };

  if (filters.user) {
    arr.push(
      <Tag
        onClick={() => {
          const { user, ...rest } = filters;
          setFilters(rest);
        }}>
        <div>{filters.user.name}</div>
        <img src={filters.user.avatar} className="rounded-lg w-7 h-7" />
      </Tag>,
    );
  }
  if (filters.project) {
    arr.push(
      <Tag
        onClick={() => {
          const { project, ...rest } = filters;
          setFilters(rest);
        }}>
        <div>{filters.project.name}</div>
        <img src={filters.project.logo} className="rounded-lg w-7 h-7" />
      </Tag>,
    );
  }

  if (filters.job) {
    arr.push(
      <Tag
        onClick={() => {
          const { job, ...rest } = filters;
          setFilters(rest);
        }}>
        <div>{filters.job.title}</div>
      </Tag>,
    );
  }

  return (
    <div className="flex gap-2 p-2">
      <div className="flex gap-2">
        {arr.map((e) => (
          <div className="text-sm px-2 text-[13px] rounded-lg bg-yellow-500 text-white">{e}</div>
        ))}
      </div>
    </div>
  );
};

function displayDaysAgo(date) {
  const today = new Date();
  const createdDate = new Date(date);
  const diffTime = Math.abs(today - createdDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export default ApplicantIndex;
