import React, { useState } from "react";
import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import api from "../../../services/api";
import RichTextEditor from "../../../components/RichTextEditor";
import { GptIcon } from "../../../assets/Icons";
import Modal from "../../../components/modal";
import LoadingButton from "../../../components/loadingButton";

const View = ({ job, setJob }) => {
  const [aiModal, setAIModal] = useState(false);

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/job/${job._id}`, job);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setJob(data);
      toast.success("Job post updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  const AIButton = () => (
    <button className={`border border-blue-600 text-blue-600 p-2 py-1.5 text-xs`} onClick={() => setAIModal(true)}>
      <GptIcon />
    </button>
  );

  if (!job) return <Loader />;

  return (
    <div className="flex flex-col gap-4 p-2 ">
      <div className="bg-white rounded-2xl">
        <div className="flex mb-2">
          <AIButton></AIButton>
        </div>
        <RichTextEditor
          buttonList={[
            ["font", "fontSize", "formatBlock"],
            ["bold", "underline", "italic"],
            ["fontColor", "hiliteColor"],
            ["align", "list", "lineHeight"],
            ["outdent", "indent"],
            ["link"],
            ["removeFormat"],
          ]}
          values={job.description}
          onChange={(e) => {
            setJob({ ...job, description: e });
          }}
          options={{ minHeight: "250px" }}></RichTextEditor>
        {aiModal && (
          <AIModal
            onClose={() => setAIModal(false)}
            userPrompt={userPrompt(job)}
            systemPrompt={systemPrompt}
            onChange={(v) => {
              const val = "\n\n ---- AI Generated ---- \n\n" + v;
              setJob({ ...job, description: val });
            }}
          />
        )}
        <div className="flex mt-2">
          <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const userPrompt = (job) => {
  return `You are a recruiter for our startup studio. Can you write a job offer with thoses informations ?
    
Company description : We are a startup studio located in Paris, Amsterdam and Barcelona. Our team is highly focused on technology and has a strong entrepreneurial mindset. 
We work with 6 to 10 startups every year, providing support in three key areas: development, product, and growth.
Some examples of the projects we have include:
Champerché : A bioponic farm in Île de France that aims to bring agriculture as locally as possible while reducing energy consumption. We are working on the R&D side of this project, developing new technologies to make the farm more efficient.
Money walkie : A noebank for young people. This product is highly technical on the technology side and ambitious on the acquisition side. We co-created this product from its early days and have been working to develop it ever since.

Our tech stack is ReactJS, Nodejs, ReactNative, Mongodb, Postgresql, Tailwind.

Job title : ${job.title}
    
Job persona : XXXXXXX ( example 2 years experience full stack developpeur. Entrepreneur mindset)
    `;
};

const systemPrompt = "The offer will be in a html format so don't use ** for bold or _ for italic. Please don't use a <style> tag but put the style directly in the h3, div etc.";

const AIModal = ({ onClose, onChange, userPrompt = "", systemPrompt = "" }) => {
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(userPrompt);

  async function handleGenerate() {
    try {
      setLoading(true);
      const { ok, data, code } = await api.post(`/gpt`, {
        userPrompt: prompt,
        systemPrompt: systemPrompt,
      });
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      onChange(data);
      setLoading(false);
      onClose();
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  return (
    <Modal isOpen={true} onClose={onClose} className="max-w-4xl w-full p-4">
      <div className="bg-white rounded-2xl font-bold">Prompt</div>
      <textarea className="border block w-full h-[200px] my-4" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
      <div>
        <LoadingButton loading={loading} className="btn btn-primary" onClick={handleGenerate}>
          Generate
        </LoadingButton>
      </div>
    </Modal>
  );
};

export default View;
