import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";

import api from "../../services/api";

export default () => {
  
  const [items, setItems] = useState([]);
  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const { data } = await api.post("/uxui2/search", {});
    setItems(data);
  }

  return (
    <div className="p-6">
      <Create refetch={() => {}} />
      <div className="grid grid-cols-4 gap-6 mt-2">
        {items.map((item) => {
          return (
            <Link key={item._id} to={`/uxui2/${item._id}`} className="bg-white p-4 rounded-lg shadow-md cursor-pointer">
              <div className="flex justify-between items-center">
                <div className="text-lg font-semibold">{item.name}</div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const Create = ({ refetch }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  async function onCreate() {
    const formatted = {};
    const { data, ok } = await api.post("/uxui2", { name });
    if (!ok) return toast.error("Error creating example");
    toast.success("Example created");
    setOpen(false);
    refetch();
  }

  return (
    <>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Add a view
      </button>
      {open ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 min-h-max bg-[#00000066] flex justify-center items-center p-[1rem] z-50 h-screen" onClick={() => setOpen(false)}>
          <div className="w-full max-h-[96vh] overflow-auto md:w-[60%] h-fit bg-[white] p-[25px] rounded-md" onClick={(e) => e.stopPropagation()}>
            <React.Fragment>
              <div>
                <h2 className="text-[24px] font-semibold">Create a new view</h2>
                <div className="mt-[1rem]">
                  <input
                    type="text"
                    className="form-input w-full p-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onCreate}>
                  Create
                </button>
              </div>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </>
  );
};
