import React from "react";

const Dashboard = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full space-y-4 px-2">
      <iframe src="https://selego-metabase.cleverapps.io/public/dashboard/5e913f91-15b5-4ec5-ae7d-dc1eb3c5234e" width="100%" height="100%" allowtransparency></iframe>
    </div>
  );
};

export default Dashboard;
