import React, { useState, useEffect } from "react";

import { defaultOperators, defaultCombinators, mergedQueries, withUniqueKey, uuidv4 } from "./utils";
import Rule from "./rule";

const fields = [
  { text: "Title", value: "title" },
  { text: "Description", value: "description" },
  { text: "Date", value: "date" },
  { text: "Location (City)", value: "location" },
  { text: "Location (Country)", value: "country" },
  { text: "Origin", value: "origin" },
  { text: "Language", value: "language" },
  { text: "Category ", value: "category" },
];

const defaultRule = { field: fields[0].value, operator: defaultOperators[0].value, value: "", combinator: "AND", index: 0 };

function QueryBuilder({ query, onQuery }) {
  const [rules, setRules] = useState(withUniqueKey(query || [defaultRule]));

  useEffect(() => {
    console.log("rules", rules);
    // const queries = mergedQueries(rules.map((r) => ({ ...r, query: defaultOperators.find((o) => o.value === r.operator).query(r.field, r.value) })));
    onQuery(rules);

    // const obj = JSON.stringify(rules);
    // const str = encodeURIComponent(obj);
    // navigate(`?qb=${str}`);
  }, [JSON.stringify(rules)]);

  return (
    <div className="flex items-center justify-between">
      <div className="react-es-query-builder flex-1">
        {rules.map((rule) => (
          <Rule
            combinator={rule.combinator}
            fieldProps={rule.field}
            operatorProps={rule.operator}
            valueProps={rule.value}
            fields={fields}
            operators={defaultOperators}
            combinators={defaultCombinators}
            key={rule.index}
            index={rule.index}
            onAdd={() => setRules([...rules, { ...defaultRule, index: rules.length, key: uuidv4() }])}
            onDelete={(index) => {
              let arr = rules
                .filter((e) => e.index !== index)
                .filter((e) => e)
                .map((v, k) => ({ ...v, index: k }));
              setRules(arr);
              // onQuery(arr);
            }}
            onChange={(r) => {
              rules[r.index] = { ...r, key: rules[r.index].key };
              setRules([...rules]);
              // onQuery([...rules]);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default QueryBuilder;
