import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaStar } from "react-icons/fa";

import api from "../../../services/api";

export default ({ channel, setChannel }) => {
  return (
    <div className="flex flex-col gap-4 pt-2 ">
      <div className="bg-white rounded-2xl p-12 pb-6">
        <div className="grid grid-cols-1 gap-4">
          <div className="text-[18px] text-[#212325] font-medium mt-2">{channel.name}</div>
          <div className="text-[14px] text-[#212325] font-medium mt-2">
            <a href={channel.url} target="_blank">
              {channel.url}
            </a>
          </div>
          <div
            className="text-[14px] text-[#212325] font-medium mt-2 cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(channel.login);
              toast.success("Copied to clipboard!");
            }}>
            {channel.login}
          </div>
          <div
            className="text-[14px] text-[#212325] font-medium mt-2 cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(channel.password);
              toast.success("Copied to clipboard!");
            }}>
            {channel.password}
          </div>
          <div className="text-[14px] text-[#212325] font-medium mt-2">{channel.description}</div>
        </div>
        <Score channel={channel} setChannel={setChannel} />
      </div>
      <div className="italic text-sm mt-3">created at {new Date(channel.created_at).toLocaleString()}</div>
    </div>
  );
};

const Score = ({ channel, setChannel }) => {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  useEffect(() => {
    setRating(channel.score);
  }, [channel]);

  const handleRatingChange = async (newRating) => {
    setRating(newRating);
    try {
      const { data } = await api.put(`/jobchannel/${channel._id}`, { score: newRating });
      setChannel(data);
      toast.success("Score updated and logged in comments");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update score");
    }
  };

  return (
    <>
      <div className=" py-2 mb-2">
        <div className="space-y-2 flex items-center justify-center">
          <label
            className="group text-lg font-semibold cursor-pointer flex text-center"
            htmlFor="score"
            onClick={() => handleRatingChange(0)}
            onMouseEnter={(e) => (e.target.textContent = "Reset")}
            onMouseLeave={(e) => (e.target.textContent = "Score")}>
            Score
          </label>
        </div>
        <div id="score" className="flex space-x-1 justify-center py-1">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              className="focus:outline-none"
              onClick={() => handleRatingChange(star)}
              onMouseEnter={() => setHover(star)}
              onMouseLeave={() => setHover(null)}>
              <FaStar size={24} color={star <= (hover || rating) ? "#ffc107" : "#e4e5e9"} />
            </button>
          ))}
        </div>
      </div>
    </>
  );
};
