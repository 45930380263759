import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import Loader from "../../components/loader";
import LoadingButton from "../../components/loadingButton";
import "react-tagsinput/react-tagsinput.css";
import api from "../../services/api";
import { setUser } from "../../redux/auth/actions";
import { toDateInputValue } from "../../utils";

export default () => {
  const disponibilityData = ["full", "20 hrs/week", "10 hrs/week", "5 hrs/week"];
  const user = useSelector((state) => state.Auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    ...user,
    birthdate: toDateInputValue(user.birthdate),
  });

  const dispatch = useDispatch();
  if (!user) return <Loader />;

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    let body = values;
    if (values.skills) body.skills = values.skills;
    if (values.location) body.location = values.location;
    try {
      const responseData = await api.put(`/user/${user._id}`, body);
      if (responseData.code) throw responseData.code;
      toast.success("Updated!");
      dispatch(setUser(responseData.data));
    } catch (e) {
      console.log(e);
      toast.error("Some Error! " + e);
    }
    setIsLoading(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <PersonalDetails values={values} setValues={setValues} />
      <div className="flex justify-end">
        <LoadingButton
          className="ml-[10px] bg-[#17a2b8] hover:bg-[#138496] text-[1rem] text-[#fff] py-[0.375rem] px-[0.75rem] rounded-[0.25rem]"
          loading={isLoading}
          onClick={handleSubmit}>
          Update
        </LoadingButton>
      </div>
    </form>
  );
};

const PersonalDetails = ({ values, setValues }) => {
  return (
    <div className="border border-gray-300 rounded p-6 space-y-4 bg-white">
      <h2 className="text-gray-800">Personal information</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="name">
            Name
          </label>
          <input className="input" type="text" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="email">
            Email
          </label>
          <input className="input" type="text" name="email" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="birthdate">
            Birthdate
          </label>
          <input className="input" type="date" name="birthdate" value={values.birthdate} onChange={(e) => setValues({ ...values, birthdate: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="availability">
            Availability
          </label>
          <select className="input" name="availability" value={values.availability} onChange={(e) => setValues({ ...values, availability: e.target.value })}>
            <option value="available">Available</option>
            <option value="not available">Not Available</option>
          </select>
        </div>

        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="github">
            Github
          </label>
          <input className="input" type="text" name="github" value={values.github} onChange={(e) => setValues({ ...values, github: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="calendly">
            Calendly
          </label>
          <input className="input" type="text" name="calendly" value={values.calendly} onChange={(e) => setValues({ ...values, calendly: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="linkedin">
            Linkedin
          </label>
          <input className="input" type="text" name="linkedin" value={values.linkedin} onChange={(e) => setValues({ ...values, linkedin: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="meeting">
            Meeting
          </label>
          <input className="input" type="text" name="meeting" value={values.meeting} onChange={(e) => setValues({ ...values, meeting: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="iban">
            IBAN
          </label>
          <input className="input" type="text" name="iban" value={values.iban} onChange={(e) => setValues({ ...values, iban: e.target.value })} />
        </div>
        <div className="space-y-2 w-full col-span-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="description">
            Description
          </label>
          <textarea row={4} className="input" type="text" name="description" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
        </div>
        {(values.role === "client" || values.role === "lead") && (
          <div className="space-x-2 w-full flex flex-row items-center">
            <input type="checkbox" id="newsletter" checked={values.newsletter} onChange={(e) => setValues({ ...values, newsletter: e.target.checked })} className="rounded" />
            <label className="block text-sm font-medium text-gray-700" htmlFor="wbso_eligible">
              Newsletter
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
