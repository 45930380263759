import React, { useEffect, useState } from "react";

export default ({ invoice }) => {
  return (
    <div>
      <object data={invoice.file} type="application/pdf" width="100%" height="900px">
        <p>
          Alternative text - include a link <a href={invoice.file}>to the PDF!</a>
        </p>
      </object>
    </div>
  );
};
