import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { CONTRACTS, DIVISION, LOCATION, ROLES, YEARSSCHOOL, SCHOOL, MANAGEMENT } from "./constants";
import { dutchInfo, frenchInfo, spanishInfo } from "./benchmark";

import Salary from "./salary";
import Sell from "./sell";
import Benchmark from "./benchmark";

import API from "../../../services/api";

export default ({ user, setUser }) => {
  const [values, setValues] = useState(user);
  const [salaryTest, setSalaryTest] = useState(user.brut_salary || 0);

  useEffect(() => {
    setValues(user);
  }, [user]);

  const saveChanges = async () => {
    try {
      const { data } = await API.put(`/user/${user._id}`, values);
      setUser(data);
      toast.success("Saved!");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="flex w-full">
        <div className="flex flex-col flex-1 p-4 gap-2">
          <SelectContract user={values} setUser={setValues} />
          <SelectDivision user={values} setUser={setValues} />
          <ContractLength user={values} setUser={setValues} />
          <YearsOfSchools user={values} setUser={setValues} />
          <SchoolCost user={values} setUser={setValues} />
          <NumberOfDayPerWeek user={values} setUser={setValues} />
          <Localisation user={values} setUser={setValues} />
          <JobTitle user={values} setUser={setValues} />
          <School user={values} setUser={setValues} />
          <Management user={values} setUser={setValues} />
          <hr className="my-4" />
          <div className="flex justify-between">
            <div className="flex-1">
              <GlobalAdvantage user={values} setUser={setValues} />
            </div>
            <div className="flex-1 px-4">
              <FrenchContractAdvantage user={values} setUser={setValues} />
            </div>
            <div className="flex-1">
              <DutchContractAdvantage user={values} setUser={setValues} />
            </div>
          </div>
          <hr className="my-4" />
          <ExperienceSimulator user={values} setUser={setValues} />

          <div>
            <button className="btn btn-primary" onClick={saveChanges}>
              Save
            </button>
          </div>
        </div>

        <div className="flex flex-col flex-1 p-4 gap-2">
          <Benchmark user={values} setUser={setValues} />
          <hr className="my-4" />
          <label className="font-bold">Chosen annual brut salary</label>
          <input
            className="block w-1/3 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
            name="salaryTest"
            type="number"
            value={values.brut_salary || 0}
            onChange={(e) => {
              setValues({ ...values, brut_salary: e.target.value });
              setSalaryTest(e.target.value);
            }}
          />
          <div>
            <button className="btn btn-primary" onClick={saveChanges}>
              Save as annual brut salary
            </button>
          </div>
          <hr className="my-4" />
          <Salary user={values} salary={salaryTest} setUser={setValues} />
          <hr className="my-4" />
          <Sell user={values} salary={salaryTest} />
        </div>
      </div>
    </div>
  );
};

const YearsOfSchools = ({ user, setUser }) => {
  const CHOICES = Object.values(YEARSSCHOOL);

  if (![CONTRACTS.INTERNSHIP, CONTRACTS.APPRENTICESHIP].includes(user.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Number of years of school</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={user.yearSchool}
        onChange={(e) => setUser({ ...user, yearSchool: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.reverse().map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const SchoolCost = ({ user, setUser }) => {
  if (![CONTRACTS.APPRENTICESHIP].includes(user.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">School cost</label>
      <input
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        type="number"
        value={user.school_cost}
        onChange={(e) => setUser({ ...user, school_cost: Math.round(e.target.value) })}></input>
    </div>
  );
};

const DutchContractAdvantage = ({ user, setUser }) => {
  if (![DIVISION.SELEGO_NL].includes(user.division)) return <div />;

  return (
    <div className="w-full">
      <label className="font-bold">Avantages Dutch</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        <div>
          <input type="checkbox" checked={user.dutch_ruling} onChange={(e) => setUser({ ...user, dutch_ruling: e.target.checked })} />
          <label className="p-4">30% Ruling</label>
        </div>
      </div>
    </div>
  );
};

const GlobalAdvantage = ({ user, setUser }) => {
  if ([CONTRACTS.FREELANCE].includes(user.contract)) return <div />;

  const elements = [];

  elements.push(
    <div>
      <input type="checkbox" checked={user.computer} onChange={(e) => setUser({ ...user, computer: e.target.checked })} />
      <label className="p-4">Computer</label>
    </div>,
  );

  return (
    <div className="w-full">
      <label className="font-bold">Avantages globaux</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        {elements.map((e, i) => {
          return e;
        })}
      </div>
    </div>
  );
};

const FrenchContractAdvantage = ({ user, setUser }) => {
  if (![DIVISION.LE_COLLECTIF, DIVISION.SELEGO_FR].includes(user.division)) return <div />;
  if ([CONTRACTS.VIE, CONTRACTS.FREELANCE].includes(user.contract)) return <div />;

  const elements = [];

  elements.push(
    <div>
      <input type="checkbox" checked={user.french_ticketresto} onChange={(e) => setUser({ ...user, french_ticketresto: e.target.checked })} />
      <label className="p-4">Ticket resto</label>
    </div>,
  );

  elements.push(
    <div>
      <input type="checkbox" checked={user.french_alan} onChange={(e) => setUser({ ...user, french_alan: e.target.checked })} />
      <label className="p-4">Alan</label>
    </div>,
  );

  elements.push(
    <div>
      <input type="checkbox" checked={user.french_navigo} onChange={(e) => setUser({ ...user, french_navigo: e.target.checked })} />
      <label className="p-4">Transport</label>
    </div>,
  );

  return (
    <div className="w-full">
      <label className="font-bold">Avantages Francais</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        {elements.map((e, i) => {
          return e;
        })}
      </div>
    </div>
  );
};

const JobTitle = ({ user, setUser }) => {
  const CHOICES = Object.values(ROLES);

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Job title</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={user.position}
        onChange={(e) => setUser({ ...user, position: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const Localisation = ({ user, setUser }) => {
  const CHOICES = Object.values(LOCATION);

  if (![CONTRACTS.CDD, CONTRACTS.CDI, CONTRACTS.VIE, CONTRACTS.INTERNSHIP, CONTRACTS.APPRENTICESHIP].includes(user.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Localisation</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={user.office}
        onChange={(e) => setUser({ ...user, office: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const School = ({ user, setUser }) => {
  const CHOICES = Object.values(SCHOOL);

  if (![CONTRACTS.CDD, CONTRACTS.CDI].includes(user.contract)) return <div />;
  if ([ROLES.DEVOPS].includes(user.position)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">School</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={user.school}
        onChange={(e) => setUser({ ...user, school: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const Management = ({ user, setUser }) => {
  const CHOICES = Object.values(MANAGEMENT);

  if (![CONTRACTS.CDD, CONTRACTS.CDI].includes(user.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Management of a team</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={user.management}
        onChange={(e) => setUser({ ...user, management: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const NumberOfDayPerWeek = ({ user, setUser }) => {
  if (![CONTRACTS.CDD, CONTRACTS.CDI, CONTRACTS.APPRENTICESHIP, CONTRACTS.VIE, CONTRACTS.INTERNSHIP].includes(user.contract)) return <div />;

  let numberDays = Math.round(user.days_worked / 4.4);

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Number of days per week</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={numberDays}
        onChange={(e) => setUser({ ...user, days_worked: Math.round(e.target.value * 4.4) })}>
        <option key="" value=""></option>
        {[1, 2, 3, 4, 5].reverse().map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
    </div>
  );
};

const SelectContract = ({ user, setUser }) => {
  const CHOICES = Object.values(CONTRACTS);
  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Contract</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={user.contract}
        onChange={(e) => setUser({ ...user, contract: e.target.value })}>
        <option key="contract" value="contract"></option>
        {CHOICES.map((contract) => (
          <option key={contract} value={contract}>
            {contract}
          </option>
        ))}
      </select>
    </div>
  );
};

const ContractLength = ({ user, setUser }) => {
  if (CONTRACTS.CDD !== user.contract) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Contract length in month</label>
      <input
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        type="number"
        value={user.contractLength}
        onChange={(e) => setUser({ ...user, contractLength: e.target.value })}
      />
    </div>
  );
};

const SelectDivision = ({ user, setUser }) => {
  const CHOICES = Object.values(DIVISION);

  if (![CONTRACTS.CDD, CONTRACTS.CDI, CONTRACTS.INTERNSHIP, CONTRACTS.APPRENTICESHIP, CONTRACTS.VIE].includes(user.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Division</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={user.division}
        onChange={(e) => setUser({ ...user, division: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((contract) => (
          <option key={contract} value={contract}>
            {contract}
          </option>
        ))}
      </select>
    </div>
  );
};

const ExperienceSimulator = ({ user, setUser }) => {
  if (user.contract === CONTRACTS.INTERNSHIP || user.contract === CONTRACTS.APPRENTICESHIP) return <div />;
  // Calculate the total years of experience

  function calculateTotal(obj) {
    return (
      parseFloat(obj.experience_leStud || 0) * 1 +
      parseFloat(obj.experience_notUseful || 0) * 0 +
      parseFloat(obj.experience_inTheFieldButNotUsefull || 0) * 0.25 +
      parseFloat(obj.experience_halfUseful || 0) * 0.5 +
      parseFloat(obj.experience_useful || 0)
    );
  }

  return (
    <div className="w-full">
      <label className="font-bold">Experience</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        <div className="p-4">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  -
                </th>
                <th scope="col" className="px-6 py-3">
                  -
                </th>
                <th scope="col" className="px-6 py-3">
                  Coefficient
                </th>
                <th scope="col" className="px-6 py-3">
                  Years
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="px-6 py-2">At Le Stud</td>
                <td className="px-6 py-2">-</td>
                <td className="px-6 py-2">1</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    name="leStud"
                    value={user.experience_leStud}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...user, experience_leStud: val });
                      setUser({ ...user, experience_leStud: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-6 py-2">Outside of Le Stud</td>
                <td className="px-6 py-2">Not in the field</td>
                <td className="px-6 py-2">0</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    name="outside"
                    value={user.experience_notUseful}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...user, experience_notUseful: val });
                      setUser({ ...user, experience_notUseful: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-6 py-2">Outside of Le Stud</td>
                <td className="px-6 py-2">In the field but not useful</td>
                <td className="px-6 py-2">0.25</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    value={user.experience_inTheFieldButNotUsefull}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...user, experience_inTheFieldButNotUsefull: val });
                      setUser({ ...user, experience_inTheFieldButNotUsefull: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-6 py-2">Outside of Le Stud</td>
                <td className="px-6 py-2">50% useful</td>
                <td className="px-6 py-2">0.5</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    value={user.experience_halfUseful}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...user, experience_halfUseful: val });
                      setUser({ ...user, experience_halfUseful: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-6 py-2">Outside of Le Stud</td>
                <td className="px-6 py-2">Useful</td>
                <td className="px-6 py-2">1</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    value={user.experience_useful}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...user, experience_useful: val });
                      setUser({ ...user, experience_useful: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-2 font-medium text-gray-900">Total years of experience</td>
                <td className="px-6 py-2"></td>
                <td className="px-6 py-2"></td>
                <td className="px-6 py-2">
                  <input
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    type="number"
                    disabled={true}
                    value={user.experience_total || 0}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const UserInput = ({ name, value, type = "text", onChange, label, className, disabled }) => (
  <div className={`${className} w-full`}>
    <div className="px-1 text-sm text-gray-600 font-medium">{label}</div>
    <input
      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
    />
  </div>
);
