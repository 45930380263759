import React, { useEffect, useState } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import Modal from "../../components/modal";
import api from "../../services/api";
import DebounceInput from "react-debounce-input";

export default () => {
  const [filters, setFilters] = useState({ status: "ACTIVE" });
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = { search: filters.search };
        const { ok, data } = await api.post("/perk/search", query);
        if (!ok) throw new Error("Error while fetching perks");
        setData(data);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching perks");
      }
    };
    fetchData();
  }, [filters]);

  const totalPerks = data.filter((e) => e.status === "ACTIVE").length;
  const totalSum = data.filter((e) => e.status === "ACTIVE").reduce((sum, perk) => sum + (parseInt(perk.value) || 0), 0);

  return (
    <div className="px-8 py-12 space-y-8">
      <div className="flex flex-col items-start gap-4 bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-semibold text-gray-800 flex items-center gap-2">Selego Startup Studio Perks</h2>
        <p className="text-md text-gray-700">These perks are exclusively available to members of the Selego Startup Studio, enhancing your startup experience.</p>
        <p className="text-md text-gray-700">
          These perks are crafted to provide the resources, support, and connections necessary to accelerate your startup’s growth and success.
        </p>
        <div className="flex flex-row gap-4">
          <div className="border border-black rounded-md p-2">
            Total Perks: <strong>{totalPerks}</strong>
          </div>
          <div className="border border-black rounded-md p-2">
            Total Benefit: <strong>€{totalSum.toFixed(2)}</strong>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between gap-4 mb-4">
        <div className="flex gap-4">
          <button
            className={`px-4 py-2 rounded ${filters.status === "ACTIVE" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
            onClick={() => setFilters({ ...filters, status: "ACTIVE" })}>
            Active
          </button>
          <button
            className={`px-4 py-2 rounded ${filters.status === "INACTIVE" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
            onClick={() => setFilters({ ...filters, status: "INACTIVE" })}>
            Coming soon
          </button>
          <Create />
        </div>
        <div>
          <div className="relative text-[#A0A6B1]">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <button type="submit" className="p-1">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-5 h-5">
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </span>
            <DebounceInput
              debounceTimeout={300}
              className="py-2 w-[364px] text-[14px] font-normal projectsInput m-0 pl-10 text-black"
              placeholder="Search (name, type, etc...)"
              value={filters.search}
              onChange={(e) => {
                e.persist();
                setFilters((prev) => ({ ...prev, search: e.target.value }));
              }}
            />
          </div>
        </div>
      </div>
      {filters.status === "ACTIVE" && <Actives perks={data.filter((e) => e.status === "ACTIVE")} />}
      {filters.status === "INACTIVE" && <Others perks={data.filter((e) => e.status === "INACTIVE")} />}
    </div>
  );
};

const Actives = ({ perks }) => {
  return (
    <div className="space-y-4">
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {perks.map((e, i) => (
          <li key={i} className="list-none">
            <Card perk={e} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const Others = ({ perks }) => {
  return (
    <div>
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {perks.map((perk, i) => (
          <li key={i} className="list-none">
            <Card perk={perk} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const Card = ({ perk }) => {
  return (
    <div className="flex flex-col no-underline hover:no-underline bg-white rounded-md shadow-md hover:shadow-lg overflow-hidden h-full">
      <div className="flex justify-between mb-6 p-4 pb-0">
        <div className="relative">
          {perk.logo ? (
            <img src={perk.logo} alt={perk.name} className="object-contain rounded-xl w-25 h-25" />
          ) : (
            <div className="h-25 w-25 rounded-xl bg-gray-200 flex items-center justify-center text-2xl">{perk.name[0]}</div>
          )}
        </div>
      </div>
      <Link to={`/perks/${perk._id}`} className="flex flex-col flex-1 group">
        <div className="text-xl font-bold text-dull-black px-4 group-hover:underline">{perk.name}</div>
        <div className="text-base font-semibold text-green-700 px-4">{perk.value ? `€${perk.value}` : ""}</div>
        <div className="text-sm text-light-grey mt-1 p-4">
          {perk.description?.substring(0, 90)}
          {perk.description?.length > 100 ? "..." : ""}
        </div>
        <div className="flex-1" />
      </Link>
    </div>
  );
};

const Create = () => {
  const [values, setValues] = useState({ name: "" });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const res = await api.post("/perk", values);
      if (!res.ok) throw res;
      toast.success("Created!");
      setOpen(false);
      navigate(`/perks/${res.data._id}`);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  };

  return (
    <>
      <button className="btn blue-btn" onClick={() => setOpen(true)}>
        Request a new perk
      </button>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="p-8">
          <h2 className="text-2xl font-semibold mb-4">Create perks</h2>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="w-full col-span-2">
              <div className="text-sm font-medium mb-2">Name</div>
              <input className="projectsInput block" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <button className="btn blue-btn w-32" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
