import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import api from "../../services/api";
import { useSelector } from "react-redux";

const Training = () => {
  const [skills, setSkills] = useState(null);
  const [workshops, setWorkshops] = useState([]);
  const [userWorkshops, setUserWorkshops] = useState([])
  const user = useSelector((state) => state.Auth.user);
  const tags = ["Intro", "Communication", "API Principles",  "Conventions", "Componentisation", "Separation of Concerns", "Data Modeling",  "DevOps Selego PMF Kit", "AI", "React Native", "Devops", "Outro" ];

  useEffect(() => {
    getSkills();
    getWorkshops();
    getUserWorkshops();
  }, []);

  const getWorkshops = async () => {
    const { data, ok } = await api.post("/workshop/search", { tags: tags });
    if (!ok) return toast.error("Error fetching workshops!");
    setWorkshops(data);
  };

  const getUserWorkshops = async () => {
    const { data, ok } = await api.post("/workshop_user/search", { user_id: user._id, per_page: 200, tags: tags });
    if (!ok) return toast.error("Error fetching workshops!");
    setUserWorkshops(data);
  };

  const getSkills = async () => {
    try {
      const { data, ok } = await api.get("/user_tech_skill/me");
      if (!ok) return toast.error("Error fetching skills!");
      setSkills(data);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching skills!");
    }
  };

  if (!workshops) return <div>Loading...</div>;


  // sorted workshops 
  const sortedWorkshops = Object.entries(
    workshops.reduce((acc, workshop) => {
      const category = workshop.tech_skill_category || 'Uncategorized';
      if (!acc[category]) acc[category] = [];
      acc[category].push(workshop);
      return acc;
    }, {})
  ).sort(([a], [b]) => {
    return tags.indexOf(a) - tags.indexOf(b);
  }); 
  


  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid gap-8 grid-cols-1 lg:grid-cols-4">
          <div className="lg:col-span-3">
            <div className="bg-white rounded-xl shadow-md overflow-hidden">
              <div className="p-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-2">Selego Entry Roadmap</h2>
                <p className="text-gray-600 mb-8"> 
                  A list of the basic workshops to get you started with Selegos tech stack.
                </p>
                
                <div className="space-y-10">
                  {sortedWorkshops.map(([category, categoryWorkshops]) => (
                    <div key={category} className="relative">
                      <div className="flex items-center">
                        <h3 className="text-lg font-semibold text-gray-900">{category}</h3>
                        <div className="ml-3 flex-grow border-t border-gray-200"></div>
                      </div>
                      
                      <div className="mt-6 grid gap-4 sm:grid-cols-2">
                        {categoryWorkshops.map((workshop) => {
                          const userWorkshop = userWorkshops.find(w => w.workshop_id === workshop._id);
                          const isCompleted = userWorkshop?.finished_at;
                          
                          return (
                            <div
                              key={workshop._id}
                              className={`group relative rounded-lg border ${
                                isCompleted ? 'border-green-200 bg-green-50' : 'border-gray-200 bg-white'
                              } p-4 hover:border-blue-400 hover:shadow-lg transition duration-300 ease-in-out flex`}
                            >
                              <div className="relative w-24 h-24 mr-4 overflow-hidden rounded-md flex-shrink-0">
                                <img 
                                  src={workshop?.image} 
                                  alt={workshop?.name}
                                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                                  onError={(e) => e.target.src = "/default-workshop.png"}
                                />
                              </div>
                              
                              <div className="flex flex-col flex-grow">
                                <div className="flex items-center justify-between mb-2">
                                  <h4 className="text-lg font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                                    {workshop.name}
                                  </h4>
                                  {isCompleted && (
                                    <span className="flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-200 text-green-800">
                                      <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                                      </svg>
                                      Completed
                                    </span>
                                  )}
                                </div>
                                
                                <p className="text-sm text-gray-600 flex-grow mb-3 line-clamp-2">{workshop.description}</p>
                                
                                <a
                                  href={`/learn/${workshop._id}`}
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className={`inline-flex items-center justify-center px-3 py-1 rounded-lg text-sm font-medium ${
                                    isCompleted 
                                      ? 'bg-green-600 hover:bg-green-700' 
                                      : 'bg-blue-600 hover:bg-blue-700'
                                  } text-white transition-colors duration-300`}
                                >
                                  {isCompleted ? (
                                    <>
                                      <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                      </svg>
                                      Review Again
                                    </>
                                  ) : (
                                    <>
                                      <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>
                                      Start Learning
                                    </>
                                  )}
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Training;
