import React from "react";
import { Line } from "react-chartjs-2";

const KPI = ({ project }) => {
  if (!project.kpis_iframe) return <div className="text-center text-sm text-gray-400 italic">Seems like no iframe for Metabase is specified</div>;

  return <iframe src={project.kpis_iframe} frameborder="0" width="100%" className="h-screen" allowtransparency></iframe>;

  const [labels, setLabels] = React.useState([]);

  React.useEffect(() => {
    const allDates = [...new Set(project.kpis2.reduce((prev, currentKpi) => [...prev, ...currentKpi.data.reduce((prev, curr) => [...prev, ...Object.keys(curr)], [])], []))]?.sort(
      (a, b) => new Date(a) - new Date(b),
    );
    setLabels(allDates);
  }, []);

  const kpisLineChartData = {
    labels: labels,
    datasets: project?.kpis2.map((kpi, index) => {
      return {
        label: kpi.label,
        data: kpi.data.sort((a, b) => new Date(Object.keys(a)[0]) - new Date(Object.keys(b)[0])).map((e) => Object.values(e)[0]),
        fill: false,
        backgroundColor: colors[index],
        borderColor: colors[index],
      };
    }),
  };

  return (
    <div className="w-full bg-[#FFFFFF] border border-[#E5EAEF] rounded-[16px]">
      <div className="p-2">
        <Line data={kpisLineChartData} />
      </div>
    </div>
  );
};

const colors = ["#07CBFD", "#003CBF", "#0560FD", "#212325"];

export default KPI;
