import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import api from "../../../services/api";
import toast from "react-hot-toast";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const Treasury = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  // const [month, setMonth] = useState(`${currentYear}-12-01T00:00:00.000Z`);
  // const options = [2024, 2023, 2022, 2021, 2020];

  const onClick = async () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `Month;BUNQ;BNP;BUNQ_LESTUD\n`;
    csvContent += data.map((e) => `${e.month};${e.BUNQ}€;${e.BNP}€;${e.BUNQ_LESTUD}€`).join("\n");
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  useEffect(() => {
    get();
  }, []);

  async function get() {
    try {
      setLoading(true);
      const obj = { month: new Date(new Date().setFullYear(currentYear)).toISOString() };
      const res = await api.post(`/report/trezo`, obj);
      if (res.ok) {
        setData(res.data);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error while fetching data");
    } finally {
      setLoading(false);
    }
  }

  if (!data) return <div>Loading</div>;

  return (
    <div className="p-4 flex-1 flex flex-col">
      <div className="flex justify-between h-12">
        <h1>Treasury</h1>

        <div>
          {loading ? (
            <p className="text-gray-500 animate-pulse">Loading...</p>
          ) : (
            <div className="flex items-center gap-4">
              <button className="empty-btn" onClick={onClick}>
                Export
              </button>
              <div>Last 12 months</div>
              {/* <select defaultValue={month} onChange={(e) => setMonth(e.target.value)}>
                {options.map((e) => (
                  <option key={e} value={`${e}-12-01T00:00:00.000Z`}>
                    {e}
                  </option>
                ))}
              </select> */}
            </div>
          )}
        </div>
      </div>

      <div className=" bg-white mt-4 p-2 rounded h-[1000px]">
        <Graph data={data} />
      </div>
    </div>
  );
};

const Graph = ({ data }) => (
  <ResponsiveBar
    data={data}
    keys={["BUNQ", "BNP", "BUNQ_LESTUD"]}
    indexBy="date"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "treso",
      legendPosition: "middle",
      legendOffset: 32,
      truncateTickAt: 0,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "month",
      legendPosition: "middle",
      legendOffset: -40,
      truncateTickAt: 0,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    role="application"
    ariaLabel="Cash flows"
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
);

export default Treasury;
