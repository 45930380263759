import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import QueryBuilder from "./queryBuilder";
import Loader from "../../../components/loader";
import Paginator from "../../../components/Paginator";
import api from "../../../services/api";
import ModalItem from "./modalItem";
import Table from "../../../components/Table";

export default () => {
  const { id } = useParams([]);
  const [data, setData] = useState();
  const [result, setResult] = useState([]);
  const [filters, setFilters] = useState({ search: "", query: null, page: 1, pageSize: 50 });
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [filters]);

  const getData = async () => {
    setLoading(true);
    const res = await api.post(`/data/${id}/search`, filters);
    if (res.code) {
      toast.error(res.code);
    }
    setResult(res.result);
    setData(res.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data) return <Loader />;

  console.log("result", data);

  const _cols = data && data.fields ? data?.fields?.filter((field) => field.visible === true).sort((a, b) => a.position - b.position) : [];
  const HEADER_COLS = (data?.fields?.filter((field) => field.visible === true).sort((a, b) => a.position - b.position) || []).map((col) => ({ title: col.name }));
  const _data = result?.hits?.hits || [];
  return (
    <div className="py-5 w-full">
      <div className="flex gap-4 items-start justify-between mb-2 mr-2">
        <Search setFilters={setFilters} dataFields={_cols} />
        <ExportButton data={_data} cols={_cols} />
      </div>
      <Table
        header={HEADER_COLS}
        total={result?.hits?.total?.value}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {_data?.map((item, index) => (
          <tr className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`} key={item._id} onClick={() => setSelectedItem(item)}>
            {_cols.map((field) => (
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{handleStrings(item._source[field.key])}</td>
            ))}
          </tr>
        ))}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(result?.hits?.total?.value / filters.pageSize)} />
      </div>
      <div className="text-gray-700">{`${result?.hits?.total?.value} in ${result.took} ms`}</div>
      <ModalItem item={selectedItem} setItem={setSelectedItem} />
    </div>
  );
};

function handleStrings(data) {
  if (typeof data === "object") return JSON.stringify(data);

  if (typeof data === "string" && data.indexOf(`https://`) !== -1) {
    return (
      <a href={data} target="_blank">
        {data}
      </a>
    );
  }

  return data;
}

const Search = ({ setFilters, dataFields }) => {
  const [query, setQuery] = useState();
  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <QueryBuilder query={query} onQuery={(query) => setQuery(query)} dataFields={dataFields} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ query })}>
        Search
      </button>
    </div>
  );
};

const ExportButton = ({ data, cols }) => {
  const downloadCSV = () => {
    const csvData = [cols.map((column) => column.name)];
    data.forEach((item) => {
      const rowData = cols.map((column) => {
        const value = item._source[column.key];
        return `"${value ? String(value).replace(/"/g, '""') : ""}"`;
      });
      csvData.push(rowData);
    });

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "exported_data.csv";
    link.click();
  };

  return (
    <button onClick={downloadCSV} className="btn btn-primary">
      Export CSV
    </button>
  );
};
