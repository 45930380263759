import React, { useEffect, useState } from "react";
import Modal from "../../components/modal";
import API from "../../services/api";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import html2pdf from "html2pdf.js";

const options = [0, 1, 2, 3, 4, 5, 6];

const DebsQuiz = () => {
  const [open, setOpen] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const user = useSelector((state) => state.Auth.user);


  const getQuiz = async () => {
    const { data, ok } = await API.get(`/personality_test/${user._id}`);
    if (ok) {
      setQuiz(data);
    }
  };

  useEffect(() => {
    getQuiz();
  }, [open]);

  async function print() {
    const saveOptions = {
      margin: 4,
      filename: `personality_test_results.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { dpi: 300 },
      jsPDF: { unit: "mm", format: "a4" },
    };
    
    const mainElemToPrint = document.getElementById("results");
    mainElemToPrint.classList.add("printing");
    const pdf = await html2pdf().set(saveOptions).from(mainElemToPrint).save();


    mainElemToPrint.classList.remove("printing");
    return pdf;
  }

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">What is your developer personality?</h1>

      <PersonalityQuiz open={open} setOpen={setOpen} quiz={quiz} setQuiz={setQuiz} />
      <UserResults quiz={quiz} />
      <button
       className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
       onClick={print}
       >Download Results</button>

    </div>
  );
};

const PersonalityQuiz = ({ open, setOpen, quiz, setQuiz }) => {
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateQuiz = async () => {
    const { data, ok } = await API.post(`/personality_test`);
    if (ok) {
      setQuiz(data);
    }
    if (!ok) console.log("handleCreateQuiz error", data);
  };

  async function onDone(option) {
    if (option === null) return console.log("no option selected");

    const user_statements = [...quiz.user_statements];

    user_statements[step].score = option;

    console.log("user_statements", user_statements);
    const { data, ok } = await API.put(`/personality_test/${quiz._id}`, {
      user_statements: user_statements,
    });

    if (!ok) return toast.error("Error updating quiz");
    setStep(step + 1);
    setQuiz(data);
  }

  return (
    <>
      {!open && (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            setOpen(true);
            quiz ? console.log("quiz exists") : handleCreateQuiz();
          }}>
          Take or continue the quiz
        </button>
      )}
      <Modal isOpen={open} className="max-w-2xl w-full p-6" onClose={() => setOpen(false)}>

        {
          isLoading && (
            <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex items-center justify-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          )
        }
       { !isLoading && <div className="p-4 space-y-4">
          <h2 className="text-xl font-bold mb-4">Developer Assignment Test</h2>

          {quiz?.user_statements?.[step] && <Statement statement={quiz.user_statements[step]} onDone={onDone} />}

          <div className="mt-4">
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-200">
                    {step + 1} / {quiz?.user_statements?.length || 0}
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-gray-600">{Math.round(((step + 1) / (quiz?.user_statements?.length || 1)) * 100)}%</span>
                </div>
              </div>
              <div className="w-full bg-gray-200 rounded-full">
                <div style={{ width: `${((step + 1) / (quiz?.user_statements?.length || 1)) * 100}%` }} className="bg-green-500 rounded-full py-1"></div>
              </div>
            </div>
          </div>


          {step === quiz?.user_statements?.length && (
            <div className="mt-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={async () => {
                  setIsLoading(true);
                  const data = await API.get(`/personality_test/${quiz._id}/results`);
                  setIsLoading(false);
                  setOpen(false);
                }}>
                Generate Results
              </button>
            </div>
          )}

          <button onClick={() => setOpen(false)} className="absolute top-2 right-2"></button>
        </div>}
      </Modal>
    </>
  );
};

const Statement = ({ statement, onDone }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(statement.score !== null ? statement.score : null);
  }, [statement]);

  const labels = ["Strongly Disagree", "Disagree", "Somewhat Disagree", "Neutral", "Somewhat Agree", "Agree", "Strongly Agree"];

  console.log(selectedOption);
  return (
    <div className="mb-4">
      <p className="text-md font-bold">Agree or disagree with the following statement:</p>
      <p className="text-lg mb-4">{statement.statement}</p>
      <div className="mt-2 w-full flex border-t border-b py-2">
        {options.map((option, index) => {
          console.log("option", option);
          console.log("selectedOption", selectedOption);
          const isSelected = selectedOption === option;

          return (
            <button
              key={option}
              className={`flex-1 flex flex-col items-center justify-center cursor-pointer bg-white  ${isSelected ? "border ring-gray-700" : ""}`}
              onClick={() => {
                setSelectedOption(option);
              }}>
              <span className={`text-xs ${isSelected ? " font-bold " : ""}`}>{labels[index]}</span>
            </button>
          );
        })}
      </div>
      <div className="flex justify-end mt-4">
        <button
          disabled={selectedOption === null}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out ${
            selectedOption === null ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => {
            onDone(selectedOption);
            setSelectedOption(null);
          }}>
          Next
        </button>
      </div>

      <div className="mt-4 text-sm text-gray-500">
        <p>Select an option to proceed to the next question.</p>
      </div>
    </div>
  );
};

export default DebsQuiz;

const UserResults = ({ quiz }) => {
  if (!quiz) return null;
  if (!quiz.results) return null;

  const spectrum1 = quiz.results.scores.filter((score) => score.spectrum_number.startsWith("1"));
  const spectrum2 = quiz.results.scores.filter((score) => score.spectrum_number.startsWith("2"));
  const spectrum3 = quiz.results.scores.filter((score) => score.spectrum_number.startsWith("3"));
  const spectrum4 = quiz.results.scores.filter((score) => score.spectrum_number.startsWith("4"));
  const spectrum5 = quiz.results.scores.filter((score) => score.spectrum_number.startsWith("5"));
  const spectrum6 = quiz.results.scores.filter((score) => score.spectrum_number.startsWith("6"));

  return (
    <div  id="results" className="p-4 w-full bg-gray-100 rounded-lg shadow-md">
      <div className="flex items-center mb-4 flex-col space-y-6">

        <div className="mt-4">
          {/* Personality Section */}
          <div className="mb-4">
            <img crossOrigin="anonymous" src={quiz.results.personality_image} alt="Personality Avatar" className="w-16 h-16 rounded-full mr-2 mb-2 printing" />
            <h3 className="text-lg font-bold mb-2">{quiz.results.personality_name}</h3>
            <p className="text-sm text-gray-500 mb-4">{quiz.results.personality_description}</p>
          </div>

          {/* Perfect Projects */}
          <div className="mb-4">
            <h4 className="text-md font-bold mb-2">🌟 Perfect Projects:</h4>
            <p className="text-sm text-gray-600 whitespace-pre-line">{quiz.results.perfect_projects}</p>
          </div>

          {/* Potential Challenges */}
          <div className="mb-4">
            <h4 className="text-md font-bold mb-2">⚠️ Potential Challenges:</h4>
            <p className="text-sm text-gray-600 whitespace-pre-line">{quiz.results.potential_challenges}</p>
          </div>

          {/* Growth Tips */}
          <div className="mb-4">
            <h4 className="text-md font-bold mb-2">🌱 Growth Tips:</h4>
            <p className="text-sm text-gray-600 whitespace-pre-line">{quiz.results.growth_tips}</p>
          </div>

          {/* Roles and Occupations */}
          <div className="mb-4">
            <h4 className="text-md font-bold mb-2">🛠️ Roles and Occupations:</h4>
            <p className="text-sm text-gray-600 whitespace-pre-line">{quiz.results.roles_and_occupations}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-8 bg-gray-100 p-6">
        <div className="flex-1">
          {renderSpectrum("⚙️ Spectrum 1: Execution Style", spectrum1, "blue")}
          {renderSpectrum("📣 Spectrum 2: Communication and Feedback", spectrum2, "yellow")}
          {renderSpectrum("🪖 Spectrum 3: Leadership and Team Dynamics", spectrum3, "green")}
          {renderSpectrum("❣️ Spectrum 4: Emotional Resilience and Self-Perception", spectrum4, "red")}
          {renderSpectrum("🧑‍🚀 Spectrum 5: Impact Orientation", spectrum5, "purple")}
          {renderSpectrum("🦺 Spectrum 6: Safety and Risk", spectrum6, "indigo")}
        </div>
      </div>
    </div>
  );
};

function renderSpectrum(title, spectrum, color) {
  const [negativeColor, positiveColor, lightBackgroundColor] = getColorsFromBase(color);
  return (
    <div className={`${lightBackgroundColor} p-4 rounded mb-6 shadow-md`}>
      <h3 className="text-lg font-bold mb-4">{title}</h3>
      <Spectrum spectrum={spectrum} color={color} />
    </div>
  );
}
const Spectrum = ({ spectrum, color}) => {
  const [positiveColor, negativeColor] = getTextColorsFromBase(color);
  return (
    <>
      {spectrum.map((score, index) => (
        <div key={index} className="p-2 px-3 border border-gray-300 rounded-lg w-full mt-4">
          <blockquote className="text-gray-600 italic mb-2 text-center">{`"${score.result_quote}"`}</blockquote>
          <div className="flex items-center mb-2 justify-between">
            <div className="flex items-center mr-4">
              <img src={score.spectrum_icon_left} alt="" className="w-8 h-8 mr-2" />
              <span className={` ml-2 ${score.spectrum_title_left === score.result_title ? `font-black ${negativeColor} text-base` : "font-medium text-sm"}`}>
                {score.spectrum_title_left}
              </span>
            </div>

            <div className="flex items-center ml-4">
              <span className={` mr-2 ${score.spectrum_title_right === score.result_title ? `font-black ${negativeColor} text-base ` : "font-medium text-sm"}`}>
                {score.spectrum_title_right}
              </span>
              <img src={score.spectrum_icon_right} alt="" className="w-8 h-8" />
            </div>
          </div>

          <ScoreBar score={score.score} baseColor={color} />

          <p className="text-sm text-gray-500 mb-2 mt-2">{score.result_description}</p>
        </div>
      ))}
    </>
  );
};

const ScoreBar = ({ score, baseColor }) => {

  const [positiveColor, negativeColor] = getColorsFromBase(baseColor);


  const leftPercentage = score < 0 ? 50 - score : 50 - score / 2;
  const rightPercentage = 100 - leftPercentage;

  return (
    <div className="border p-2 rounded-md shadow-md">
      <div className="flex items-center w-full">
        <div className={`${negativeColor} h-4 relative`} style={{ width: `${leftPercentage}%` }}>
          <span className="absolute top-50 left-50 transform -translate-x-50 -translate-y-50 text-white text-sm">{Math.round(leftPercentage)}%</span>
        </div>

        <div className={`bg-gray-300 h-4 ${score === 0 ? "w-full" : "w-px"}`}></div>

        <div className={`${positiveColor} h-4 relative`} style={{ width: `${rightPercentage}%` }}>
          <span className="absolute top-50 left-50 transform -translate-x-50 -translate-y-50 text-white text-sm">{Math.round(rightPercentage)}%</span>
        </div>
      </div>
    </div>
  );
};

const getColorsFromBase = (color) => {
  switch (color) {
    case 'blue':
      return ['bg-blue-400', 'bg-blue-600', 'bg-blue-100'];
    case 'yellow':
      return ['bg-yellow-400', 'bg-yellow-600', 'bg-yellow-100'];
    case 'green':
      return ['bg-green-400', 'bg-green-600', 'bg-green-100'];
    case 'red':
      return ['bg-red-400', 'bg-red-600', 'bg-red-100'];
    case 'purple':
      return ['bg-purple-400', 'bg-purple-600', 'bg-purple-100'];
    case 'indigo':
      return ['bg-indigo-400', 'bg-indigo-600', 'bg-indigo-100'];
    default:
      return ['bg-gray-400', 'bg-gray-600', 'bg-gray-100'];
  }
};

const getTextColorsFromBase = (color) => {
  switch (color) {
    case 'blue':
      return ['text-blue-400', 'text-blue-600'];
    case 'yellow':
      return ['text-yellow-400', 'text-yellow-600'];
    case 'green':
      return ['text-green-400', 'text-green-600'];
    case 'red':
      return ['text-red-400', 'text-red-600'];
    case 'purple':
      return ['text-purple-400', 'text-purple-600'];
    case 'indigo':
      return ['text-indigo-400', 'text-indigo-600'];
    default:
      return ['text-gray-400', 'text-gray-600'];
  }
};
