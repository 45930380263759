import React from "react";

export default History = ({ invoice }) => {
  return (
    <div>
      {invoice.logs
        .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
        .map((h) => {
          return <Card key={h._id} log={h} />;
        })}
    </div>
  );
};

const Card = ({ log }) => {
  return (
    <div className="bg-white shadow overflow-hidden md:rounded-lg mb-2 p-2">
      <span className="font-bold">
        Updated at {new Date(log.created_at).toLocaleString()} by {log.user_name}
      </span>
      <div>{log.text}</div>
    </div>
  );
};
