import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../components/loader";
import api from "../../services/api";
import SelectMonth from "../../components/selectMonth";

import { getDaysInMonth } from "../../utils";
import Combobox from "../../components/Combobox";

const Budget = () => {
  const [date, setDate] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [budget, setBudget] = useState(null);
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fecthData = async () => {
      try {
        const res = await api.post(`/budget/search`, { status: "active" });
        if (res.ok) {
          setBudgets(res.data);
          if (search) {
            const params = new URLSearchParams(search);
            const budgetId = params.get("budget");
            const found = res.data.find((e) => e._id === budgetId);
            if (found) setBudget(found);
            else setBudget(res.data[0]);
          } else setBudget(res.data[0]);
        }
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching budgets");
      }
    };
    fecthData();

    if (search) {
      const params = new URLSearchParams(search);
      const date = params.get("date");
      if (date) setDate(date);
    }
  }, []);

  const handleSelectBudget = (e) => {
    if (!e) {
      setBudget(null);
      navigate(`/activity/budget${date ? `?date=${date}` : ""}`);
    } else {
      navigate(`/activity/budget?budget=${e._id}${date ? `&date=${date}` : ""}`);
      setBudget(e);
    }
  };

  const handleSelectDate = (e) => {
    if (!e) {
      setDate(null);
      navigate(`/activity/budget${budget ? `?budget=${budget._id}` : ""}`);
    } else {
      navigate(`/activity/budget?date=${new Date(e).toISOString()}${budget ? `&budget=${budget._id}` : ""}`);
      setDate(e);
    }
  };

  if (!budgets.length) return <Loader />;
  return (
    <div className="py-8">
      <div className="grid grid-cols-4 gap-6">
        <div className="space-y-2 col-span-2">
          <label className="text-xs text-gray-500">Budget</label>
          <Combobox options={budgets} value={budget} onChange={(e) => handleSelectBudget(e)} getLabel={(e) => e.name} getValue={(e) => e._id} placeholder="Select a budget" />
        </div>
        <div className="space-y-2">
          <label className="text-xs text-gray-500">Date</label>
          <SelectMonth start={-3} indexDefaultValue={3} value={date} onChange={(e) => handleSelectDate(e.target.value)} showArrows />
        </div>
      </div>
      {budget ? <Activities date={new Date(date)} user={budget} /> : <div className="flex items-center justify-center h-[300px] text-gray-500">Select a budget</div>}
    </div>
  );
};

const Activities = ({ date, user: budget }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const fecthData = async () => {
      try {
        const res = await api.post(`/activity/search`, { date: date.getTime(), budgetId: budget._id });
        if (res.ok) setActivities(res.data);
      } catch (e) {
        console.error(e);
      }
    };
    fecthData();
  }, [date, budget]);

  const navigate = useNavigate();

  const days = getDaysInMonth(date.getMonth(), date.getFullYear());

  const getWorkingDays = () => {
    return days.reduce((acc, a) => {
      const day = a.getDay();
      if (day === 0 || day == 6) return acc;
      return acc + 1;
    }, 0);
  };

  const totalDays = activities.reduce((acc, a) => acc + a.total, 0) / 8;
  const totalSpent = activities.reduce((acc, a) => acc + a.value, 0);

  return (
    <div className="flex flex-wrap py-3 gap-4 text-black">
      <div className="w-screen md:w-full p-2 md:!px-8">
        {true && (
          <div className="mt-2 rounded-xl bg-[#fff] overflow-auto">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="py-[10px] text-[14px] font-bold text-[#212325] text-left pl-[10px]">Users</th>
                  {days.map((e) => {
                    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                    const _date = new Date(e);
                    const day = _date.getDay();
                    const weekday = days[day];
                    const date = _date.getDate();
                    return (
                      <th
                        className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center ${day == 0 || day == 6 ? "bg-[#FFD5F1]" : "bg-[white]"}`}
                        key={e}
                        day={day}>
                        <div>{weekday}</div>
                        <div>{date}</div>
                      </th>
                    );
                  })}
                  <th className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center bg-[white]`} />
                </tr>
              </thead>
              <tbody>
                {activities.map((e, i) => {
                  return (
                    <tr key={i} className="border-t border-b border-r border-[#E5EAEF]">
                      <td className="w-[100px] border-t border-b border-r text-[12px] font-bold text-[#212325] text-left">
                        <div className="flex-1 text-left">{e.userName}</div>
                        <div className="text-xs italic font-normal">
                          {(e.total / 8).toFixed(2)} days - {e.value.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                        </div>
                      </td>
                      {e.detail.map((e, i) => (
                        <td key={i} className="p-2 text-center" style={{ backgroundColor: getBgColor(e.value), color: getTextColor(e.value) }}>
                          {e.value}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td className="px-2">
                    <div className="flex justify-end w-full text-[12px] font-bold text-[#212325] italic">
                      <div>{`${totalDays.toFixed(2)} days`}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="px-2">
                    <div className="flex justify-end w-full text-[12px] font-bold text-[#212325] italic">
                      <div>{totalSpent.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

const getBgColor = (value) => {
  if (value >= 7) return "#216E39";
  if (value >= 5) return "#30A14E";
  if (value >= 3) return "#40C463";
  if (value > 0) return "#9BE9A8";
  return "#fff";
};

const getTextColor = (value) => {
  if (value >= 7) return "#fff";
  return "#000";
};

export default Budget;
