import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

import RichTextEditorSun from "../../../components/RichTextEditor";
import Loader from "../../../components/loader";
import Modal from "../../../components/modal";

import api from "../../../services/api";

const STEPS = {
  BASIC_INFO: "BASIC_INFO",
  BRIEF_CHOICE: "BRIEF_CHOICE",
  PROJECT_DETAILS: "PROJECT_DETAILS",
};
export default () => {


  const [values, setValues] = useState({
    name: "",
    website: "",
    description: "",
    spec_problems: "",
    spec_keys_features: "",
    spec_personnae: "",
    spec_platforms: "",
    spec_revenu_envisages: "",
    spec_other: "",
  });

  console.log("VALUES ", values);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(STEPS.BASIC_INFO);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      values.status = "active";
      const res = await api.post("/project", values);
      if (!res.ok) throw res;
      toast.success("Project Created Successfully!");
      setOpen(false);
      navigate(`/project/${res.data._id}`);
    } catch (e) {
      console.log(e);
      toast.error("Error creating project", e.code);
    }
    setLoading(false);
  };

  const handleFileUpload = async (e) => {
    // TODO: Implement brief file upload and AI processing
    toast.success("Coming soon: AI brief processing");
  };

  return (
    <>
      <button className="btn blue-btn" onClick={() => setOpen(true)}>
        Create new project
      </button>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="p-8">
          <>
            {step === STEPS.BASIC_INFO && <BasicInfoStep values={values} setValues={setValues} />}
            {step === STEPS.BRIEF_CHOICE && <BriefChoiceStep handleFileUpload={handleFileUpload} onStepByStep={() => setStep(STEPS.PROJECT_DETAILS)} />}
            {step === STEPS.PROJECT_DETAILS && <ProjectDetailsStep values={values} setValues={setValues} onFinish={handleSubmit} />}
          </>
          <div className="flex justify-between mt-6">
            {step === STEPS.BRIEF_CHOICE && (
              <button
                className="btn border"
                onClick={() => {
                  if (step === STEPS.BRIEF_CHOICE) setStep(STEPS.BASIC_INFO);
                }}>
                Back
              </button>
            )}
            <div className="flex-1" />
            {step === STEPS.BASIC_INFO && (
              <button className="btn blue-btn" disabled={!values.name} onClick={() => setStep(STEPS.BRIEF_CHOICE)}>
                Continue
              </button>
            )}
            {step === STEPS.BRIEF_CHOICE && (
              <button className="btn blue-btn" onClick={() => setStep(STEPS.PROJECT_DETAILS)}>
                Continue
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const BasicInfoStep = ({ values, setValues }) => (
  <div className="space-y-4">
    <h3 className="text-lg font-medium">Basic Information</h3>
    <p className="text-sm text-gray-500">Let's start with the essential details of your project</p>

    <div className="space-y-4">
      <div>
        <label className="text-sm font-medium mb-2 block">Project Name</label>
        <input
          className="projectsInput block w-full"
          name="name"
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          placeholder="Enter project name"
        />
      </div>

      <div>
        <label className="text-sm font-medium mb-2 block">Website</label>
        <input
          className="projectsInput block w-full"
          name="website"
          value={values.website}
          onChange={(e) => setValues({ ...values, website: e.target.value })}
          placeholder="https://..."
        />
      </div>
    </div>
  </div>
);

const BriefChoiceStep = ({ handleFileUpload, onStepByStep }) => (
  <div className="space-y-6">
    <div>
      <h3 className="text-lg font-medium">Project Brief</h3>
      <p className="text-sm text-gray-500 mb-4">Choose how you want to create your project brief</p>

      <div className="space-y-4">
        <div className="border rounded-lg p-4 hover:border-blue-500 cursor-pointer">
          <h4 className="font-medium">Upload Brief Document</h4>
          <p className="text-sm text-gray-500">Upload your existing brief and let AI help structure it</p>
          <input type="file" onChange={handleFileUpload} className="mt-2" />
        </div>

        <div className="border rounded-lg p-4 hover:border-blue-500 cursor-pointer" onClick={onStepByStep}>
          <h4 className="font-medium">Create Step by Step</h4>
          <p className="text-sm text-gray-500">We'll guide you through creating a comprehensive brief</p>
        </div>
      </div>
    </div>
  </div>
);

const STEPS_DETAIL = {
  PROBLEMS: "PROBLEMS",
  SOLUTIONS: "SOLUTIONS",
  PERSONNAE: "PERSONNAE",
  PLATFORM: "PLATFORM",
  REVENUE: "REVENUE",
  OTHER: "OTHER",
};

const ProjectDetailsStep = ({ values, setValues, onFinish }) => {
  const [detailStep, setDetailStep] = useState(STEPS_DETAIL.PROBLEMS);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium"></h3>
        <div className="text-sm text-gray-500">
          Step {Object.values(STEPS_DETAIL).indexOf(detailStep) + 1} of {Object.values(STEPS_DETAIL).length}
        </div>
      </div>

      {detailStep === STEPS_DETAIL.PROBLEMS && (
        <div className="space-y-4">
          <h4 className="font-medium">Problems to Solve</h4>
          <p className="text-sm text-gray-500">What problems or challenges does your project aim to address?</p>
          <RichTextEditorSun
            buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
            values={values.spec_problems}
            options={{ minHeight: "150px", backgroundColor: "white" }}
            onChange={(e) => setValues({ ...values, spec_problems: e })}
          />
        </div>
      )}

      {detailStep === STEPS_DETAIL.SOLUTIONS && (
        <div className="space-y-4">
          <h4 className="font-medium">Key Features & Solutions</h4>
          <p className="text-sm text-gray-500">What are the main features and solutions your project will provide?</p>
          <RichTextEditorSun
            buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
            values={values.spec_keys_features}
            options={{ minHeight: "150px", backgroundColor: "white" }}
            onChange={(e) => setValues({ ...values, spec_keys_features: e })}
          />
        </div>
      )}

      {detailStep === STEPS_DETAIL.PERSONNAE && (
        <div className="space-y-4">
          <h4 className="font-medium">Target Users</h4>
          <p className="text-sm text-gray-500">Who are the target users or personas for your project?</p>
          <RichTextEditorSun
            buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
            values={values.spec_personnae}
            options={{ minHeight: "150px", backgroundColor: "white" }}
            onChange={(e) => setValues({ ...values, spec_personnae: e })}
          />
        </div>
      )}

      {detailStep === STEPS_DETAIL.PLATFORM && (
        <div className="space-y-4">
          <h4 className="font-medium">Platforms & Technologies</h4>
          <p className="text-sm text-gray-500">What platforms and technologies will your project use?</p>
          <RichTextEditorSun
            buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
            values={values.spec_platforms}
            options={{ minHeight: "150px", backgroundColor: "white" }}
            onChange={(e) => setValues({ ...values, spec_platforms: e })}
          />
        </div>
      )}

      {detailStep === STEPS_DETAIL.REVENUE && (
        <div className="space-y-4">
          <h4 className="font-medium">Revenue Model</h4>
          <p className="text-sm text-gray-500">What is your planned revenue model and monetization strategy?</p>
          <RichTextEditorSun
            buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
            values={values.spec_revenu_envisages}
            options={{ minHeight: "150px", backgroundColor: "white" }}
            onChange={(e) => setValues({ ...values, spec_revenu_envisages: e })}
          />
        </div>
      )}

      {detailStep === STEPS_DETAIL.OTHER && (
        <div className="space-y-4">
          <h4 className="font-medium">Additional Information</h4>
          <p className="text-sm text-gray-500">Any other important details about your project?</p>
          <RichTextEditorSun
            buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
            values={values.spec_other}
            options={{ minHeight: "150px", backgroundColor: "white" }}
            onChange={(e) => setValues({ ...values, spec_other: e })}
          />
        </div>
      )}

      <div className="flex justify-between mt-6">
        <button
          className="btn border"
          onClick={() => {
            const currentIndex = Object.values(STEPS_DETAIL).indexOf(detailStep);
            if (currentIndex > 0) {
              setDetailStep(Object.values(STEPS_DETAIL)[currentIndex - 1]);
            }
          }}>
          Previous
        </button>
        <button
          className="btn blue-btn"
          onClick={() => {
            const currentIndex = Object.values(STEPS_DETAIL).indexOf(detailStep);
            if (currentIndex < Object.values(STEPS_DETAIL).length - 1) {
              setDetailStep(Object.values(STEPS_DETAIL)[currentIndex + 1]);
            } else {
              onFinish();
            }
          }}>
          {detailStep === STEPS_DETAIL.OTHER ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  );
};
