import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import { toast } from "react-hot-toast";
import Loader from "../../../components/loader";

import StudFlixPng from "../../../assets/studflix.png";

export default function Meetings({ project }) {
  const [meetings, setMeetings] = useState([]);
  const [filters, setFilters] = useState({
    project: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMeetings();
  }, []);

  async function fetchMeetings(e) {
    try {
      e?.preventDefault();
      setLoading(true);
      const response = await api.post("/meeting/search", { ...filters, project_id: project._id });
      setLoading(false);
      if (!response.ok) return toast.error("Error fetching meetings");
      setMeetings(response.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching meetings:", error);
    }
  }

  return (
    <>
      <form className="w-full grid grid-cols-4 items-center gap-3 p-2" onSubmit={fetchMeetings}>
        <div className="w-full">
          <label className="text-xs text-gray-500">Recherche</label>
          <input
            className="w-full flex items-center justify-between border border-gray-300 rounded hover:border-sky-700 overflow-hidden"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            placeholder="Search title, tags, ..."
            disabled={loading}
          />
        </div>
        <button type="submit" className="mt-5 h-9 w-full flex items-center justify-center bg-sky-600 text-white rounded hover:bg-sky-500">
          Search
        </button>
      </form>
      <div className="relative p-6 bg-[#272727] text-white min-h-screen">
        <div className="my-8 w-full flex justify-center">
          <img src={StudFlixPng} />
        </div>
        {loading ? <Loader /> : null}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {meetings.map((e) => (
            <Tile meeting={e} />
          ))}
        </div>
      </div>
    </>
  );
}

const Tile = ({ meeting }) => {
  return (
    <Link to={`/meetings/${meeting._id}`} className="">
      <div key={meeting._id} className="group relative p-4 shadow-lg rounded-lg mb-4 bg-[#272727] shadow-neomorphisme">
        <div className="relative">
          <img src={`https://drive.google.com/thumbnail?id=${meeting.drive_id}` || StudFlixPng} className="rounded-lg w-full h-48 object-cover object-center" alt="content" />
          {meeting.project_name ? <span className="absolute top-1 right-1 bg-[#DC1B27] rounded-full px-4 text-white text-sm">{meeting.project_name}</span> : null}
        </div>
        <div className="mt-4 cursor-pointer">
          <h3 className="text-lg font-semibold text-white group-hover:text-red-500">{meeting.name}</h3>
          <p className="text-sm text-neutral-300">{meeting.description}</p>
        </div>
      </div>
    </Link>
  );
};
