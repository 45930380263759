import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import api from "../../../services/api";

import Info from "./info";
import Edit from "./edit";
import Stats from "./stats";

const TABS = {
  INFO: "Info",
  EDIT: "Edit",
  STATS : "Stats"
};

export default () => {
  const [channel, setChannel] = useState(null);
  const [tab, setTab] = useState(TABS.EDIT);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/jobchannel/${id}`);
      setChannel(data);
    })();
  }, []);

  if (!channel) return <Loader />;

  return (
    <div>
      <div className="m-2">
        <div className="flex justify-between">
          <div>{channel.name}</div>
        </div>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px list-none">
            <li className="mr-2" onClick={(e) => setTab(TABS.INFO)}>
              <a
                href="#"
                className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                  tab === TABS.INFO ? "active" : ""
                }`}>
                Info
              </a>
            </li>
            <li className="mr-2" onClick={(e) => setTab(TABS.EDIT)}>
              <a
                href="#"
                className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                  tab === TABS.EDIT ? "active" : ""
                }`}>
                Editor
              </a>
            </li>
            <li className="mr-2" onClick={(e) => setTab(TABS.STATS)}>
              <a
                href="#"
                className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                  tab === TABS.STATS ? "active" : ""
                }`}>
                Stats
              </a>
            </li>
          </ul>
        </div>
        {tab === TABS.INFO && <Info channel={channel} setChannel={setChannel} />}
        {tab === TABS.EDIT && <Edit channel={channel} setChannel={setChannel} />}
        {tab === TABS.STATS && <Stats channel={channel} />}
      </div>
    </div>
  );
};
