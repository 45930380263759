import { NavLink, Navigate, Route, Routes, useNavigate } from "react-router-dom";

import Rentability from "./Rentability";
import Prospection from "./Prospection";
import CashActivity from "./CashActivity";

import Cashout from "./Cashout";
import Treasury from "./Treasury";
import Expenses from "./Expenses";
import Employees from "./Employees";
import Admin from "./Admin";
import Team from "./Team";
import WbsoCir from "./WbsoCir";
import Team2 from "./Team2";
import Forecast from "./Forecast";
import Bonus from "./Bonus";
import Cashflow from "./Cashflow";
import ControlAccounting from "./ControlAccounting";
import Latent from "./Latent";
import Projects from "./Projects";
import Cashflow2 from "./Cashflow2";
export default () => {
  const path = window.location.pathname;

  const navigate = useNavigate();

  return (
    <div className="p-2">
      <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 overflow-x-auto p-1">
        <TabItem href="/report/latent" title="P&L - CA Latent" />
        <TabItem href="/report/cash" title="P&L - Facturation" />
        <TabItem href="/report/cashflow" title="Cashflow" />
        <TabItem href="/report/projects" title="Projects" />
        {/* <TabItem href="/report/rentability" title="Rentability" /> */}
        <TabItem href="/report/forecast" title="Forecast" />
        <TabItem href="/report/bonus" title="Bonus" />
        {/* <TabItem href="/report/employees" title="Employees" />
        <TabItem href="/report/prospection" title="Prospection" />
        <TabItem href="/report/admin" title="Admin" /> */}
        <TabItem href="/report/wbsoCir" title="Wbso/Cir" />
        {/* <TabItem href="/report/expenses" title="Expenses" />
        <TabItem href="/report/cashout" title="Cashout" />
        <TabItem href="/report/cashoutByProject" title="Cashout by project" /> */}
        <TabItem href="/report/treasury" title="Treasury" />
        {/* <TabItem href="/report/team" title="Team" /> */}
        <TabItem href="/report/team2" title="Team2" />
        <TabItem href="/report/controlAccounting" title="Control Accounting" />
      
      </nav>
      <div className="flex-1 flex flex-col">
        <Routes>
          <Route index element={<Navigate to="latent" replace />} />
          <Route path="/latent" element={<Latent />} />
          <Route path="/cash" exact element={<CashActivity />} />
          <Route path="/cashflow" element={<Cashflow />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/prospection" element={<Prospection />} />
          <Route path="/treasury" element={<Treasury />} />
          <Route path="/wbsoCir" element={<WbsoCir />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/forecast" element={<Forecast />} />
          <Route path="/bonus" element={<Bonus />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/cashout" element={<Cashout />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team2" element={<Team2 />} />
          <Route path="/controlAccounting" element={<ControlAccounting />} />
          <Route path="/cashflow2" element={<Cashflow2 />} />

        </Routes>
      </div>
    </div>
  );
};

const TabItem = ({ href, title }) => {
  return (
    <NavLink to={href} className={({ isActive }) => `${isActive ? "blue-btn" : "transparent-btn"} relative rounded py-1 px-2 flex items-center gap-2 font-normal text-sm`}>
      {title}
    </NavLink>
  );
};
