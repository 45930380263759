import React, { useState } from "react";
import Modal from "../../../components/modal";
import toast from "react-hot-toast";

const TABS = {
  INFO: "Info",
  RAW: "Raw",
};

export default ({ item, setItem }) => {
  const [tab, setTab] = useState(TABS.INFO);

  if (!item) return <div />;

  return (
    <Modal isOpen={!!item} onClose={(e) => setItem(null)}>
      <div className="p-10">
        <div className="text-2xl font-bold text-gray-800">Item {item._id}</div>
        <div className="m-2">
          <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px list-none space-x-2">
              <li
                onClick={(e) => setTab(TABS.INFO)}
                className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${tab === TABS.INFO && "border-primary"
                  }`}>
                Info
              </li>
              <li
                onClick={(e) => setTab(TABS.RAW)}
                className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${tab === TABS.UPLOAD && "border-primary"
                  }`}>
                Raw
              </li>
            </ul>
          </div>
          {tab === TABS.INFO && <Info item={item._source} />}
          {tab === TABS.RAW && <Raw item={item} />}
        </div>
      </div>
    </Modal>
  );
};

const Info = ({ item }) => {
  console.log("item2", item._source)
  const keys = Object.keys(item);

  const render= (value) => {
    if(typeof value === "object") return JSON.stringify(value)
    if(value?.includes("http")) return <a href={value} target="_blank">{value}</a> 
    return value
     
  }
  return <div>
    <div className="grid grid-cols-2 gap-4">
      {keys.map((key) => (
        <div className="p-2 border border-gray-200 rounded  flex justify-between" key={key}>
          <div>
            <div className="text-sm font-semibold text-gray-500">{key}</div>
            <div>{render(item[key])}</div>
          </div>
          <div className="cursor-pointer" onClick={() => {
            //copy in keyboard
            navigator.clipboard.writeText(item[key]);
            toast.success("Copied to clipboard");
          }}>
            copy
          </div>
        </div>
      ))}
    </div>
  </div>;
};


const Raw = ({ item }) => {
  console.log(item._source)
  return (
    <div className="mt-2">
      <div className="p-2">
        <pre className="text-xs bg-gray-800 text-gray-100 rounded p-2">{JSON.stringify(item._source, null, 2)}</pre>
      </div>
    </div>
  );
}
