import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import MultiSelect from "../../../components/MultiSelect";
import Loader from "../../../components/loader";

export default ({ values, onChange }) => {
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await api.post("/job/search", {});

      const sectors = Array.from(new Set(res.data.map((j) => j.sector))).sort((a, b) => {
        if (a?.toLowerCase() < b?.toLowerCase()) return -1;
        if (a?.toLowerCase() > b?.toLowerCase()) return 1;
        return 0;
      });

      setSectors(sectors.map((sector) => ({ value: sector, label: sector })));
    })();
  }, []);

  if (!sectors?.length) return <Loader size="small" />;

  return <MultiSelect id="select-sectors" options={sectors} values={values} onSelectedChange={onChange} placeholder="Select a sector" />;
};
