import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../components/loader";
import FileInput from "../../../components/FileInput";
import api from "../../../services/api";

const View = ({ data, setData }) => {
  const [fields, setFields] = useState(data.fields);

  async function handleSubmit() {
    try {
      const { ok, data: d, code } = await api.put(`/data/${data._id}`, { ...data, fields: fields });
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setData(d);
      toast.success("data post updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!data) return <Loader />;

  return (
    <div className="flex flex-col gap-4 p-2 ">
      <div className="p-12 pb-6 bg-white rounded-2xl">
        <div className="flex justify-end">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Save{" "}
          </button>
        </div>
        <FieldEditor fields={fields} setFields={setFields} />
      </div>
    </div>
  );
};

const FieldEditor = ({ fields, setFields }) => {
  const handleChange = (id, key, value) => {
    const updatedFields = fields.map((field) => {
      if (field._id === id) return { ...field, [key]: value };
      return field;
    });
    setFields(updatedFields);
  };

  return (
    <div className="p-6 space-y-4">
      {fields
        .sort((a, b) => a.position - b.position)
        .map((field) => (
          <div key={field._id} className="border p-4 rounded shadow-md flex space-x-4 items-center">
            <div className="flex-1">
              <label className="text-gray-700">Key:</label>
              <input type="text" value={field.key} disabled className="mt-1 p-2 border rounded w-full bg-gray-100 cursor-not-allowed" />
            </div>
            <div className="flex-1">
              <label className="text-gray-700">Name:</label>
              <input type="text" value={field.name} onChange={(e) => handleChange(field._id, "name", e.target.value)} className="mt-1 p-2 border rounded w-full" />
            </div>
            <div className="flex-1">
              <label className="text-gray-700">Type:</label>
              <input type="text" value={field.type} onChange={(e) => handleChange(field._id, "type", e.target.value)} className="mt-1 p-2 border rounded w-full" />
            </div>
            <div className="flex-1">
              <label className="text-gray-700">Position:</label>
              <input
                type="number"
                value={field.position}
                onChange={(e) => handleChange(field._id, "position", parseInt(e.target.value, 10))}
                className="mt-1 p-2 border rounded w-full"
              />
            </div>
            <div>
              <label className="text-gray-700">Visible:</label>
              <input type="checkbox" checked={field.visible} onChange={(e) => handleChange(field._id, "visible", e.target.checked)} className="mt-1 ml-2" />
            </div>
          </div>
        ))}
    </div>
  );
};

export default View;
