import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../../services/api";

import Modal from "../../../components/modal";

import { copyToClipboard, classNames } from "../../../utils";

const UserCard = ({ questionModalId, setQuestionModalId }) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState();
  const [skills, setSkills] = useState();
  const [question, setQuestion] = useState();

  async function get() {
    try {
      if (questionModalId) {
        setLoading(true);
        const res = await api.get(`/skill_question/${questionModalId}`);
        setQuestion(res.data);
        setLoading(false);
      } else {
        setQuestion(null);
      }
    } catch (e) {
      console.log("a", e);
    } finally {
      setLoading(false);
    }
  }

  async function getSkills() {
    try {
      setLoading(true);
      const { data } = await api.post(`/skill/search`);
      setSkills(data);
    } catch (e) {
      console.log("a", e);
    } finally {
      setLoading(false);
    }
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.put(`/skill_question/${questionModalId}`, question);
      console.log("data", data);
      toast.success("Question updated");
    } catch (e) {
      console.log("a", e);
    } finally {
      setQuestionModalId(null);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this question?")) return;

    try {
      const { data } = await api.remove(`/skill_question/${questionModalId}`);
      toast.success("Question deleted");
      console.log("data", data);
    } catch (e) {
      console.error("handleDelete error", e);
    } finally {
      setQuestionModalId(null);
    }
  };

  useEffect(() => {
    if (!question || question._id !== questionModalId) {
      getSkills();
      get();
    }
  }, [questionModalId]);

  if (!question) return <></>;

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        setQuestionModalId(null);
      }}>
      {loading ? (
        <div className="w-full h-[90vh] bg-gray-100 p-2 flex items-center justify-center">
          <div className="text-xl text-gray-500 animate-pulse">
            Knock Knock...
            <br />
            Who's there ?
          </div>
        </div>
      ) : (
        <form className="flex flex-col gap-3 p-4" onSubmit={handleUpdate}>
          <span className="text-xs text-neutral-400">{question._id}</span>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <label className="text-sm text-sky-700">Skill</label>
              <select
                className="input"
                onChange={(e) => {
                  const s = skills.find((s) => s._id === e.target.value);
                  setQuestion({ ...question, skillId: s._id, skillCategory: s.category, skillSubCategory: s.subCategory });
                }}
                value={question.skillId}>
                <option>--select skill--</option>
                {skills?.map((skill) => {
                  return (
                    <option key={skill._id} value={skill._id}>
                      {skill.category} - {skill.subCategory}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label className="text-sm text-sky-700">Level</label>
              <div className="flex justify-between gap-3">
                {[1, 2, 3, 4, 5].map((level) => (
                  <button
                    type="button"
                    key={level}
                    className={classNames(`input`, level === question.level ? "bg-sky-700 text-white" : "")}
                    onClick={() => setQuestion({ ...question, level: level })}>
                    {level}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div>
            <span>title</span>
            <input className="w-full" placeholder="title" onChange={(e) => setQuestion({ ...question, title: e.target.value })} value={question.title}></input>
          </div>
          <div>
            <span>details</span>
            <input className="w-full" placeholder="details" onChange={(e) => setQuestion({ ...question, details: e.target.value })} value={question.details}></input>
          </div>
          <div className="flex justify-between gap-10">
            <button className="blue-btn flex-1 disabled:opacity-40" type="submit" disabled={!question.level || !question.skillId}>
              Update
            </button>
            <button className="red-btn w-1/4 disabled:opacity-40" type="button" onClick={() => handleDelete()}>
              Delete
            </button>
          </div>
          {/* {JSON.stringify(question)} */}
        </form>
      )}
    </Modal>
  );
};

export default UserCard;
