import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import api from "../../../services/api";

import Chart from "../Chart";

export default ({}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const obj = {};
    const res = await api.post(`/report/expenses`, obj);
    setData(res.data);
  }

  if (!data) return <div>Loading</div>;

  return (
    <div>
      <h1>Expenses of le stud</h1>
      <p>
        <strong>Bank charges</strong> that cannot be passed on to the customer (restaurant, train ticket, ...)
      </p>
      <div className="bg-white mt-4 p-2 rounded">
        <div className="h-[500px] w-1/2 bg-white mt-4 p-2 rounded">
          <Chart
            data={data}
            onClick={(e) => {
              console.log(e);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Graph = ({ data, onClick, keys, indexBy = "month", groupMode = "grouped", legendX = "month", legendY }) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={indexBy}
    margin={{ top: 50, right: 10, bottom: 50, left: 65 }}
    padding={0.3}
    groupMode={groupMode}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisBottom={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: legendX, legendPosition: "middle", legendOffset: 32 }}
    axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: legendY, legendPosition: "middle", legendOffset: -60 }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    role="application"
    ariaLabel={legendY}
    onClick={(data) => {
      if (!onClick) return;
      onClick(data);
    }}
    barAriaLabel={(e) => e.id + ": XXXX" + e.formattedValue + " in: " + e.indexValue}
    legends={[
      {
        anchor: "top",
        direction: "row",
        translateY: -35,
        itemWidth: 150,
        itemHeight: 20,
        symbolSize: 20,
        symbolShape: "circle",
        textColor: "#999",
        effects: [{ on: "hover", style: { itemTextColor: "#000" } }],
      },
    ]}
  />
);
