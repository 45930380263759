import React from "react";
import MultiSelect from "../../../components/MultiSelect";

export default ({ values, onChange }) => {
  return (
    <MultiSelect
      values={values}
      id="select-statuses"
      options={[
        { value: "NEW", label: "NEW" },
        { value: "REJECT", label: "REJECT" },
        { value: "SHORTLIST", label: "SHORTLIST" },
        { value: "CONTACTED", label: "CONTACTED" },
        { value: "FINAL_LIST", label: "FINAL_LIST" },
        { value: "INTERVIEW", label: "INTERVIEW" },
        { value: "TECHNICAL_TEST", label: "TECHNICAL_TEST" },
        { value: "HIRE", label: "HIRE" },
      ]}
      onSelectedChange={onChange}
      placeholder="Select a status"
    />
  );
};
