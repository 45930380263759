import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaSwatchbook } from "react-icons/fa";
import { GiBookCover, GiBookmarklet } from "react-icons/gi";
import { HiBookmark, HiOutlineBookmark } from "react-icons/hi";
import DebounceInput from "react-debounce-input";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Rating from "../rating";
import Loader from "../../../components/loader";
import SelectUser from "../../../components/selectUser";

import api from "../../../services/api";
import DoneIcon from "../../../assets/learn/done.svg";

export default () => {
  const [workshops, setWorkshops] = useState();
  const [userWorkshops, setUserWorkshops] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ search: "", user_id: "" });

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    fetchData();
  }, [filter.search]);

  const fetchData = async () => {
    try {
      const { data: w } = await api.get("/workshop" + "?" + new URLSearchParams(filter).toString());
      const arr = w.sort((a, b) => b.rating_score - a.rating_score);
      setWorkshops(arr);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserWorkshops();
  }, [filter.user_id]);

  const fetchUserWorkshops = async () => {
    try {
      setLoading(true);
      const { data: Wu } = await api.get(`/workshop_user?user_id=${filter.user_id || user._id}`);
      setUserWorkshops(Wu);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  if (!workshops || !userWorkshops) return <Loader />;

  return (
    <div className="p-4 font-inter">
      <div className="flex items-center space-x-4">
        <div className="relative text-[#A0A6B1]">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" className="p-1">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-5 h-5">
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </span>
          <DebounceInput
            debounceTimeout={300}
            className="py-2 w-[364px] text-[14px] font-normal projectsInput m-0 pl-10 text-black"
            placeholder="Search (name, tags, etc...)"
            value={filter.search}
            onChange={(e) => {
              e.persist();
              setFilter((prev) => ({ ...prev, search: e.target.value }));
            }}
          />
        </div>
        <SelectUser className="projectsInput m-0" placeholder={user.name} value={filter.user_id} onChange={(u) => setFilter({ ...filter, user_id: u._id })} />

        {loading && <Loader size="small" />}
      </div>
      <Tutorials workshops={workshops.filter((e) => e.category === "lesson")} userWorkshops={userWorkshops} setUserWorkshops={setUserWorkshops} filter={filter} />

      <h3 className="text-xl text-dull-black my-4 font-bold">Profiling</h3>
      {workshops.filter((e) => e.category === "profil").length === 0 && <Empty />}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {workshops
          .filter((e) => e.category === "profil")
          .map((e) => (
            <Card key={e._id} workshop={e} userWorkshop={userWorkshops.find((w) => w.workshop_id === e._id)} setUserWorkshops={setUserWorkshops} userId={filter.user_id} />
          ))}
      </div>

      <h3 className="text-xl text-dull-black my-4 font-bold">Post mortem</h3>
      {workshops.filter((e) => e.category === "mortem").length === 0 && <Empty />}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {workshops
          .filter((e) => e.category === "mortem")
          .map((e) => (
            <Card key={e._id} workshop={e} userWorkshop={userWorkshops.find((w) => w.workshop_id === e._id)} setUserWorkshops={setUserWorkshops} userId={filter.user_id} />
          ))}
      </div>

      <h3 className="text-xl text-dull-black my-4 font-bold">Challenges</h3>
      {workshops.filter((e) => e.category === "challenge").length === 0 && <Empty />}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {workshops
          .filter((e) => e.category === "challenge")
          .map((e) => (
            <Card key={e._id} workshop={e} userWorkshop={userWorkshops.find((w) => w.workshop_id === e._id)} setUserWorkshops={setUserWorkshops} userId={filter.user_id} />
          ))}
      </div>

      <h3 className="text-xl text-dull-black my-4 font-bold">Admin</h3>
      {workshops.filter((e) => e.category === "admin").length === 0 && <Empty />}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {workshops
          .filter((e) => e.category === "admin")
          .map((e) => (
            <Card key={e._id} workshop={e} userWorkshop={userWorkshops.find((w) => w.workshop_id === e._id)} setUserWorkshops={setUserWorkshops} userId={filter.user_id} />
          ))}
      </div>
    </div>
  );
};

const Tutorials = ({ workshops, userWorkshops, setUserWorkshops, filter }) => {
  const [axe, setAxe] = useState("");

  return (
    <div className="mb-6">
      <h3 className="text-lg my-4 font-bold">Tutorials</h3>
      <div className="flex items-center space-x-4 mb-6">
        {["", "code", "leadership", "communication", "business", "productivity", "investment", "admin","product","gtm","data"].map((e) => (
          <TabItem key={e} title={e ? e : "all"} onClick={() => setAxe(e)} active={axe === e} />
        ))}
      </div>

      {workshops.find((e) => e.level === "beginner" && (axe ? e.axe === axe : true)) && (
        <>
          <Divider title="Beginner" />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {workshops
              .filter((e) => e.level === "beginner" && (axe ? e.axe === axe : true))
              .map((e) => (
                <Card key={e._id} workshop={e} userWorkshop={userWorkshops.find((w) => w.workshop_id === e._id)} setUserWorkshops={setUserWorkshops} userId={filter.user_id} />
              ))}
          </div>
        </>
      )}
      {workshops.find((e) => e.level === "intermediate" && (axe ? e.axe === axe : true)) && (
        <>
          <Divider title="Intermediate" />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {workshops
              .filter((e) => e.level === "intermediate" && (axe ? e.axe === axe : true))
              .map((e) => (
                <Card key={e._id} workshop={e} userWorkshop={userWorkshops.find((w) => w.workshop_id === e._id)} setUserWorkshops={setUserWorkshops} userId={filter.user_id} />
              ))}
          </div>
        </>
      )}
      {workshops.find((e) => e.level === "advanced" && (axe ? e.axe === axe : true)) && (
        <>
          <Divider title="Advanced" />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {workshops
              .filter((e) => e.level === "advanced" && (axe ? e.axe === axe : true))
              .map((e) => (
                <Card key={e._id} workshop={e} userWorkshop={userWorkshops.find((w) => w.workshop_id === e._id)} setUserWorkshops={setUserWorkshops} userId={filter.user_id} />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

const Card = ({ workshop, userWorkshop, setUserWorkshops, userId }) => {
  function renderStatus() {
    if (!userWorkshop) return <div />;
    if (userWorkshop.finished_at) return <img src={DoneIcon} />;
    return <Progress value={userWorkshop.step_id} total={workshop.steps.length} />;
  }

  function renderStatusBar() {
    if (!userWorkshop) return <div />;
    // if (userWorkshop.todo) return <div className="bg-amber-100 text-amber-500 font-medium uppercase text-xs tracking-widest text-center py-2">todo</div>
    if (userWorkshop.finished_at) return <div className="bg-green-400 text-white uppercase text-xs tracking-widest text-center py-2">finished</div>;
    return <div className="bg-blue-100 text-blue-600 uppercase text-xs tracking-widest text-center py-2">in progress</div>;
  }

  async function toggleTodo() {
    if (userWorkshop) {
      const { data } = await api.put(`/workshop_user/${userWorkshop._id}`, {
        todo: !userWorkshop.todo,
      });
      setUserWorkshops((prev) => prev.map((e) => (e._id === userWorkshop._id ? data : e)));
    } else {
      const { data } = await api.post(`/workshop_user`, {
        workshop_id: workshop._id,
        workshop_name: workshop.name,
        workshop_version: workshop.version,
        todo: true,
        user_id: userId || null,
      });
      setUserWorkshops((prev) => [...prev, data]);
    }
  }

  return (
    <div className="flex flex-col no-underline hover:no-underline bg-white rounded-md shadow-md hover:shadow-lg overflow-hidden">
      <div className="flex justify-between mb-6 p-4 pb-0">
        <div className="relative">
          <img src={workshop.image} className="object-contain rounded-xl w-25 h-25" />
          <div className="h-8 w-8 shrink-0 absolute top-1 left-1"> {renderStatus()}</div>
        </div>
        <div className="text-right text-sm">
          {workshop.type && (
            <div className="p-1 px-2.5 capitalize rounded bg-[#F5F6FB] text-dull-black mb-2.5 inline-flex items-center space-x-1 whitespace-nowrap">
              {getTypeIcon(workshop.type)} <span className="text-dull-black">{workshop.type}</span>
            </div>
          )}
          {workshop.read_time && (
            <div className="text-sm flex items-center justify-end space-x-1">
              <AiOutlineClockCircle className="text-base text-dull-grey" />
              <span className="text-dull-black">{workshop.read_time}</span>
            </div>
          )}

          <button onClick={toggleTodo} className="flex items-center gap-2 mt-2.5 h-8 w-8 rounded bg-[#F5F6FB] justify-center border-2 border-gray-50 hover:border-gray-100 ml-auto">
            {userWorkshop?.todo ? <HiBookmark className="text-base text-dull-grey" /> : <HiOutlineBookmark className="text-base text-dull-grey" />}
          </button>
        </div>
      </div>
      <Link to={`/learn/${workshop._id}`} className="flex flex-col flex-1 group">
        <div className="text-xl font-bold text-dull-black px-4 group-hover:underline">{workshop.name}</div>
        <div className="flex items-center space-x-2 text-sm text-light-grey my-2 px-4">
          <span>Rating:</span>
          {!!workshop.rating_score && (
            <>
              <Rating value={workshop.rating_score} />
              <strong>{`${workshop.rating_score} (${workshop.rating_count})`}</strong>
            </>
          )}
        </div>
        <div className="text-sm text-light-grey mt-1 p-4">
          {workshop.description?.substring(0, 100)}
          {workshop.description?.length > 100 ? "..." : ""}
        </div>
        <div className="flex-1" />
        {renderStatusBar()}
      </Link>
    </div>
  );
};

const Divider = ({ title }) => (
  <div className="relative my-6 after:absolute after:left-0 after:top-1/2 -after:translate-y-1/2 after:z-[1] after:h-[1px] after:w-full after:bg-[#CBCDD5]">
    <span className="bg-lighter-grey pr-2 text-sm pl-0 relative z-[2] inline-block font-normal">{title}</span>
  </div>
);

const Empty = () => <span className="px-3 py-1 bg-gray-50 text-gray-400 rounded-lg border-[1px] border-gray-100 text-xs">No elements</span>;

const Progress = ({ value, total }) => (
  <CircularProgressbar
    value={value}
    maxValue={total}
    strokeWidth={14}
    styles={buildStyles({
      strokeLinecap: "round",
      pathColor: `#0560FD`,
      trailColor: "#E5EAEF",
      backgroundColor: "transparent",
    })}
  />
);

const TabItem = ({ title, onClick, active }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 cursor-pointer text-sm rounded border ${active ? "bg-primary text-white  border-primary" : "text-dull-grey border-dull-grey/30"}`}>
    <div className="flex items-center gap-2 capitalize">{title}</div>
  </button>
);

function getTypeIcon(type) {
  switch (type) {
    case "book":
      return <GiBookCover className="mr-1 text-lg text-dull-grey" />;
    case "mooc":
      return <FaSwatchbook className="mr-1 text-lg text-dull-grey" />;
    case "tutorial":
      return <GiBookmarklet className="mr-1 text-lg text-dull-grey" />;
    default:
      return <GiBookmarklet className="mr-1 text-lg text-dull-grey" />;
  }
}
