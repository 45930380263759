import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import RichTextEditorSun from "../../../components/RichTextEditor";
import Modal from "../../../components/modal";
import api from "../../../services/api";

export default ({}) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const res = await api.post("/ticket", values);
      if (!res.ok) throw res;
      toast.success("Created!");
      setOpen(false);
      navigate(`/message/${res.data._id}`);
    } catch (err) {
      console.log("ERROR ", err);
    }
  }

  return (
    <div className="">
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Create new ticket
      </button>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <form>
          <div className="flex flex-col gap-2 items-center justify-center max-w-lg mx-auto p-3">
            <h1 className="text-2xl font-semibold">Create new ticket</h1>
            <RichTextEditorSun
              buttonList={[
                ["font", "fontSize", "formatBlock"],
                ["bold", "underline", "italic"],
                ["fontColor", "hiliteColor"],
                ["align", "list", "lineHeight"],
                ["outdent", "indent"],
                ["link", "image", "video"],
                ["removeFormat"],
              ]}
              values={values.title}
              options={{ minHeight: "180px" }}
              onChange={(e) => setValues((prevValue) => ({ ...prevValue, title: e }))}
            />
            <div className="my-2">
              <SelectAgent value={values.agent_id} onChange={(e) => setValues((prevValue) => ({ ...prevValue, agent_id: e }))} />
            </div>
            <button disabled={!values.title} onClick={onSubmit} className="blue-btn p-6 w-full">
              Create
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const SelectAgent = ({ value, onChange }) => {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    loadAgents();
  }, []);

  async function loadAgents() {
    const { data } = await api.post("/agent/search", {});
    setAgents(data);
  }

  return (
    <div>
      <select value={value} onChange={(e) => onChange(e.target.value)} className="input">
        <option value="">Generic</option>
        {agents.map((agent) => (
          <option value={agent._id}>{`${agent.name} (${agent.job_title})`}</option>
        ))}
      </select>
    </div>
  );
};
