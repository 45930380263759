import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import FrameworkTShirt from "./FrameworkTShirt";
import Planning from "./Planning";
import FrameworkNNL from "./FrameworkNNL";

export default ({ project }) => {
  const [view, setView] = useState();

  const navigate = useNavigate();

  const { id, tab } = useParams();

  useEffect(() => {
    if (tab === "nnl") return setView("NNL");
    if (tab === "tshirt") return setView("TSHIRT");
    if (tab === "stats") return setView("STATS");
    if (tab === "planning") return setView("PLANNING");
    setView("NNL");
  }, [tab]);

  return (
    <div className="roadmap-page-container">
      <div className="bg-white h-full">
        <div className="flex justify-between">
          <div className="flex items-end ml-2 ">
            <div onClick={() => navigate(`/project/${id}/roadmap/nnl`)} className="flex items-center cursor-pointer">
              <div className={`${view === "NNL" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>Now,Next,Later</div>
            </div>
            <div onClick={() => navigate(`/project/${id}/roadmap/tshirt`)} className="flex items-center cursor-pointer">
              <div className={`${view === "TSHIRT" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>Tshirt Sizing</div>
            </div>
            <div onClick={() => navigate(`/project/${id}/roadmap/stats`)} className="flex items-center cursor-pointer">
              <div className={`${view === "STATS" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>Stats</div>
            </div>
            <div onClick={() => navigate(`/project/${id}/roadmap/planning`)} className="flex items-center cursor-pointer">
              <div className={`${view === "PLANNING" ? "gray-btn" : "transparent-btn"} h-8 text-center transition duration-300 rounded-b-none`}>Planning</div>
            </div>
          </div>
        </div>
        {view === "NNL" && <FrameworkNNL project={project} />}
        {view === "TSHIRT" && <FrameworkTShirt project={project} />}
        {view === "STATS" && <div>Stats</div>}
        {view === "PLANNING" && <Planning project={project} />}
      </div>
    </div>
  );
};
