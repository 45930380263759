import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api";
import SelectProject from "../../components/selectProject";
import Modal from "../../components/modal";
import toast from "react-hot-toast";
import Loader from "../../components/loader";
import Table from "../../components/Table";
import Paginator from "../../components/Paginator";

const HEADER = [
  { title: "Name", key: "projectName" },
  { title: "Status", key: "status" },
  { title: "Remaining", key: "totalRemaining" },
  { title: "Payment missing since", key: "firstDueAt" },
  { title: "Last comment", key: "" },
  { title: "Next event if action", key: "date" },
];

export default ({}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({ search: "", query: {}, page: 1, pageSize: 50, sort: { created_at: -1 } });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    get();
  }, [filters]);

  async function get() {
    try {
      setLoading(true);

      const { ok, data, total } = await api.post(`/cashout/search`, filters);

      setLoading(false);
      if (!ok) return toast.error("Something went wrong!");
      setData(data);

      setTotal(total);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  if (!data) return <Loader />;

  //each unique status
  const status = [...new Set(data.map((e) => e.status))];

  const handleSort = (key) => {
    setFilters({ ...filters, sort: { [key]: !filters.sort[key] ? 1 : filters.sort[key] === 1 ? -1 : undefined } });
  };

  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold">
            Cashout - <small>{data.reduce((acc, item) => acc + item.totalRemaining, 0).toFixed(2)}€</small>
          </h1>

          {/* for each status*/}
          <div className="flex gap-4 mt-2">
            {status.map((e) => (
              <div className="bg-gray-200 px-2 py-1 rounded-md">
                <div>{e ? e : "Non défini"}</div>
                <div>
                  {(data.filter((item) => item.status === e).reduce((acc, item) => acc + item.totalRemaining, 0) || 0).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </div>
              </div>
            ))}
          </div>

          <div className="my-4 w-fit">
            <label className="block text-sm font-medium text-gray-700">Collection Status</label>

            <select
              value={filters?.query?.status}
              onChange={async (e) => {
                const newStatus = e.target.value === "" ? undefined : e.target.value;
                setFilters((prev) => {
                  const newQuery = { ...prev.query };
                  if (newStatus === undefined) {
                    delete newQuery.status;
                  } else {
                    newQuery.status = newStatus;
                  }

                  return {
                    ...prev,
                    query: newQuery,
                  };
                });
              }}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="">All</option>
              <option value="Timetable sent">Timetable sent</option>
              <option value="Signed timetable">Signed timetable</option>
              <option value="Payment in progress">Payment in progress</option>
              <option value="Late payment">Late payment</option>
              <option value="Late payment in progress">Late payment in progress</option>
              <option value="In recovery">In recovery</option>
            </select>
          </div>
        </div>

        <Create />
      </div>
      <Table
        header={HEADER}
        total={total}
        loading={loading}
        sort={filters.sort}
        onSort={handleSort}
        height="min-h-min"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {loading
          ? [...Array(filters.pageSize)].map((_, index) => (
              <tr key={index} className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"}`}>
                {HEADER.map((_, index) => (
                  <td key={index} className="py-4 px-3 max-w-xs">
                    <div className="h-2 bg-gray-200 rounded w-full" />
                  </td>
                ))}
              </tr>
            ))
          : data.map((e) => {
              const lastCommentText = e.comments.length ? e.comments[e.comments.length - 1].text : "";
              const lastCommentDate = e.comments.length ? e.comments[e.comments.length - 1].created_at.split("T")[0] : "";
              return (
                <tr key={e.name} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-1 px-2">
                    <Link to={`/cashout/${e._id}`} key={e.name} className="">
                      <div className="text-sm font-semibold text-left">{e.projectName}</div>
                    </Link>
                  </td>
                  <td className="py-1 px-2">{e.status}</td>
                  <td className="py-1 px-2">{(e.totalRemaining || 0).toFixed(2)}€</td>
                  <td className="py-1 px-2">{e.firstDueAt ? new Date(e.firstDueAt).toISOString().slice(0, 10) : ""}</td>
                  <td className="py-1 px-2">
                    {lastCommentDate} - {lastCommentText.substring(0, 50)}{" "}
                  </td>
                  <td className="py-1 px-2">{e.date ? e.date.split("T")[0] : ""}</td>
                </tr>
              );
            })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.pageSize)} />
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  const navigate = useNavigate();

  async function onCreate() {
    const { data } = await api.post("/cashout", values);
    toast.success("Created!");
    navigate(`/cashout/${data._id}`);
  }

  return (
    <div>
      <div className="text-right">
        <button className="bg-[#0560FD] text-[#fff] py-[12px] px-[22px] h-[48px]	rounded-[10px] text-sm font-medium" onClick={() => setOpen(true)}>
          Create new contract
        </button>
      </div>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="w-full max-w-md">
        <div className="max-w-2xl w-full h-auto bg-[white] p-[25px] rounded-md">
          <div>
            <SelectProject
              value={values.projectName}
              onChange={(e) => {
                console.log("e", e);
                setValues({ ...values, projectId: e._id, projectName: e.name });
              }}
            />
          </div>
          <button
            className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px] disabled:cursor-not-allowed"
            disabled={!values.projectId}
            onClick={onCreate}>
            Create
          </button>
        </div>
      </Modal>
    </div>
  );
};
