import React, { useState } from "react";
import api from "../../services/api";
import Confetti from "react-confetti";
import { GiPartyPopper } from "react-icons/gi";

const HappyBirthday = () => {
  const width = window.innerWidth - 15; // scrollbasr margin
  const height = window.innerHeight;
  const [confetti, setConfetti] = useState(false);

  const [birthdays, setBirthdays] = useState();
  React.useEffect(() => {
    getBirthdays();
  }, []);

  const isToday = (date) => {
    const today = new Date();
    const d = new Date(date);
    return today.toDateString() === d.toDateString();
  };

  async function getBirthdays() {
    const { data, ok } = await api.get(`/user/incoming-birthdays`);
    if (!ok) return;
    setBirthdays(data.filter((e) => isToday(e.nextBirthdate)));
  }

  if (!birthdays || birthdays.length === 0) return null;

  return (
    <>
      <div
        className="border-[1px] border-yellow-500 py-2 px-3 text-[13px] rounded-lg bg-yellow-500 text-white flex items-center gap-1 cursor-pointer"
        onClick={() => setConfetti((e) => !e)}>
        It&apos;s {birthdays.map((u) => u.name).join(" & ")}&apos;s birthday ! <GiPartyPopper className="text-lg" />
      </div>
      <Confetti recycle={confetti} width={width} height={height} />
    </>
  );
};

export default HappyBirthday;
