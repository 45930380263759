export function mergedQueries(queries) {
  let obj = { must: [], must_not: [], should: [] };
  queries
    .filter((q) => q.query)
    .forEach((q, k) => {
      let combinator = q.combinator;
      if (k === 0) {
        combinator = queries.length === 1 ? "AND" : queries[1].combinator;
      }
      obj[combinator === "AND" ? "must" : "should"].push(q.query);
    });
  return obj;
}

function query(key, value, cb, shouldOrMust = "should") {
  if (Array.isArray(key)) {
    return { bool: { [shouldOrMust]: key.map((k) => cb(k, value)) } };
  }
  return cb(key, value);
}

export const defaultOperators = [
  {
    value: "*",
    text: "contains",
    useInput: true,
    query: (key, value) => value && query(key, value, (k, v) => ({ wildcard: { [k]: `*${v}*` } })),
  },
  {
    value: "!*",
    text: "does not contain",
    useInput: true,
    query: (key, value) => value && query(key, value, (k, v) => ({ bool: { must_not: { wildcard: { [k]: `*${v}*` } } } }), "must"),
  },
  {
    value: "==",
    text: "is",
    useInput: true,
    query: (key, value) => value && query(key, value, (k, v) => ({ term: { [k]: v } })),
  },
  {
    value: "!=",
    text: "is not",
    useInput: true,
    query: (key, value) => value && query(key, value, (k, v) => ({ bool: { must_not: { term: { [k]: v } } } }), "must"),
  },
];

export const defaultCombinators = [
  { value: "AND", text: "AND" },
  { value: "OR", text: "OR" },
];

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export function withUniqueKey(rules) {
  if (!Array.isArray(rules)) return [];
  return rules.map((r) => ({ ...r, key: uuidv4() }));
}
