import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";

export default ({ channel }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    get();
  }, []);

  async function get() {
    const { data } = await api.post("/jobpost/search", { channel_id: channel._id });
    setItems(data);
  }

  return (
    <div>
      <div>Statistiques</div>
      <div className="flex flex-col gap-4 p-2 ">
        <div className="p-12 pb-6 bg-white rounded-2xl shadow-md">
          <table className="w-full text-left table-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2">Job name</th>
                <th className="px-4 py-2">Clicks</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item._id} className="hover:bg-gray-100">
                  <td className="border px-4 py-2">{item.job_title}</td>
                  <td className="border px-4 py-2">{item.views}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
