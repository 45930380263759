import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import App, { AppContext } from "./context";

import End from "./end";
import Home from "./home";
import Content from "./content";

export default () => {
  const { id } = useParams();

  return (
    <div className="w-full flex flex-col p-4 max-w-7xl mx-auto">
      <App workshopId={id}>
        <Home />
        <Content />
        <End />
      </App>
    </div>
  );
};
