import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import SelectBudget from "../../../components/SelectBudget";
import api from "../../../services/api";

export default ({ project }) => {
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(new Date().getMonth());
  const [budget, setBudget] = useState(null);

  const [loading, setLoading] = useState(false);

  const [activities, setActivities] = useState([]);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    get();
  }, [year, month, budget]);

  async function get() {
    try {
      setLoading(true);
      setActivities([]);
      setBanks([]);

      const filter = {};
      filter.projectId = project._id;
      filter.invoiceId = null;

      if (budget) {
        filter.budgetId = budget._id;
      }

      if (year) {
        if (month) {
          const dateFrom = new Date(year, month, 1);
          const dateTo = new Date(year, month + 1, 0);
          filter.dateFrom = dateFrom;
          filter.dateTo = dateTo;
        } else {
          const dateFrom = new Date(year, 0, 1);
          const dateTo = new Date(year, 11, 31);
          filter.dateFrom = dateFrom;
          filter.dateTo = dateTo;
        }
      }

      const { data: activities } = await api.post(`/activity/search`, filter);

      const { data: b } = await api.post(`/bank/search`, {
        project_id: project._id,
        invoiceId: null,
        notCategories: ["INVOICE", "TAX"],
        per_page: 20000,
      });

      const a = activities.filter((a) => a.value);
      setActivities(a);
      const banks = b.banks.map((bank) => ({ ...bank, amount: -bank.amount }));
      setBanks(banks);
      setLoading(false);
    } catch (e) {
      console.log("error", e);
    }
  }

  let total = activities.reduce((acc, a) => acc + a.value, 0);
  total = banks.reduce((acc, a) => acc + a.amount, total);

  if (loading) return <div>Loading...</div>;

  console.log(activities);
  return (
    <div>
      <div className="flex items-center justify-end mb-5">
        <SelectYear value={year} onChange={setYear} />
        {year && <SelectMonth value={month} onChange={setMonth} />}
        <SelectBudget2 value={budget} onChange={setBudget} projectId={project._id} />
      </div>
      <div className="overflow-x-auto">
        <table className="w-full table-fixed">
          <thead>
            <tr className="border-b border-[#E5EAEF] text-[14px] font-semibold text-[#212325]">
              <th className="p-3">Item</th>
              <th className="p-3">Description</th>
              <th className="p-3">Budget</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Price total</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((item, index) => {
              // console.log("item", item.budgetId)
              return (
                <tr key={index} className="border-b border-[#E5EAEF] text-[14px] text-[#676D7C] font-medium">
                  <td className="pl-3">
                    <div className="flex items-center gap-3">
                      {item.userAvatar && <img className="h-7 rounded-full border border-[#C9C8C9]" src={item.userAvatar} alt="image.png" />}
                      <span className="py-2.5">{item.userName}</span>
                    </div>
                  </td>
                  <td>{`${item.userJobTitle} ${new Date(item.date).toLocaleDateString("en-US", { year: "numeric", month: "long" })}`}</td>
                  <td>
                    <SelectBudget
                      value={{ _id: item.budgetId, name: item.budgetName }}
                      projectId={project._id}
                      onChange={async (e) => {
                        await api.put(`/activity/${item._id}`, { budgetId: e._id });
                        toast.success("updated");
                      }}
                    />
                  </td>
                  <td>{item.userTjms}</td>
                  <td>{`${(item.total / 8).toFixed(2)} days`}</td>
                  <td>{item.value.toFixed(0)}</td>
                </tr>
              );
            })}
            {banks.map((item, index) => {
              return (
                <tr key={index} className="border-b border-[#E5EAEF] text-[14px] text-[#676D7C] font-medium">
                  <td className="pl-3">
                    <div className="flex items-center gap-3">
                      {item.logo && <img className="h-7 rounded-full border border-[#C9C8C9]" src={item.logo} alt="image.png" />}
                      <span className="py-2.5">{item.name}</span>
                    </div>
                  </td>
                  <td>{item.description}</td>
                  <td>
                    <SelectBudget
                      value={{ _id: item.budgetId, name: item.budgetName }}
                      projectId={project._id}
                      onChange={async (e) => {
                        const res = await api.put(`/activity/${item._id}`, { budgetId: e._id });
                        toast.success("updated");
                      }}
                    />
                  </td>
                  <td>{item.price}</td>
                  <td>{``}</td>
                  <td>{item.amount.toFixed(0)}</td>
                </tr>
              );
            })}
            <tr key="total" className="text-[14px] font-semibold text-[#212325]">
              <td className="p-3">Total-</td>
              <td />
              <td />
              <td />
              <td>{total.toFixed(2)}€</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SelectMonth = ({ value, onChange }) => {
  return (
    <select value={value} onChange={(e) => onChange(e.target.value)} className="border border-gray-300 rounded-md p-2 text-sm focus:border-indigo-500 focus:ring-indigo-500">
      <option value="">All</option>
      <option value={0}>January</option>
      <option value={1}>February</option>
      <option value={2}>March</option>
      <option value={3}>April</option>
      <option value={4}>May</option>
      <option value={5}>June</option>
      <option value={6}>July</option>
      <option value={7}>August</option>
      <option value={8}>September</option>
      <option value={9}>October</option>
      <option value={10}>November</option>
      <option value={11}>December</option>
    </select>
  );
};

const SelectYear = ({ value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="block w-32 px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary">
    <option value="">All</option>
    <option value="2024">2024</option>
    <option value="2023">2023</option>
    <option value="2022">2022</option>
    <option value="2021">2021</option>
  </select>
);

const SelectInvoice = ({ value, onChange, projectId }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const query = { limit: 50, projectId };
    const res = await api.post("/invoice/search", query);
    setOptions(res.data.sort((a, b) => (a.name || "").localeCompare(b.name)));
  }

  async function onSelect(e) {
    const selected = options.find((o) => o._id === e.target.value);
    onChange(selected);
  }

  return (
    <select value={value?._id} onChange={onSelect} className="w-full border border-gray-200 rounded-md p-2">
      <option value="">Select invoice</option>
      {options.map((e, i) => (
        <option key={i} value={e._id}>
          {e.name}
        </option>
      ))}
    </select>
  );
};

const SelectBudget2 = ({ value, onChange, projectId }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const query = { limit: 20, status: "active", projectId };
    const res = await api.post("/budget/search", query);
    setOptions(res.data.sort((a, b) => (a.name || "").localeCompare(b.name)));
  }

  async function onSelect(e) {
    const selected = options.find((o) => o._id === e.target.value);
    onChange(selected);
  }

  return (
    <select value={value?._id} onChange={onSelect} className="w-full border border-gray-200 rounded-md p-2">
      <option value="">Select budget</option>
      {options.map((e, i) => (
        <option key={i} value={e._id}>
          {e.name}
        </option>
      ))}
    </select>
  );
};
