import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../utils";

export default function MinSwitch({ checked, onChange }) {
  const [enabled, setEnabled] = useState();

  useEffect(() => {
    setEnabled(checked);
  }, [checked]);

  const handleChange = () => {
    onChange(!enabled);
    setEnabled((p) => !p);
  };

  
  return (
    <Switch
      checked={enabled}
      onChange={handleChange}
      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <span
        aria-hidden="true"
        className={classNames(enabled ? "bg-primary" : "bg-gray-200", "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out")}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out",
        )}
      />
    </Switch>
  );
}
