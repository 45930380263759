import React, { useState } from "react";
import { classNames } from "../../utils";

import { BENCHMARKS_CDI, BENCHMARKS_CDI_START } from "./benchmark";

import { CONTRACTS, DIVISION, LOCATION, ROLES, YEARSSCHOOL, SCHOOL, MANAGEMENT } from "./constants";

export default ({ filter }) => {
  const [tab, setTab] = useState("day");
  if (!filter.role) return <div />;

  let experience_total = 0;
  if (filter.experience_total) {
    experience_total = filter.experience_total;
  }

  filter.experience_total = experience_total;

  let elements = [];

  if ([DIVISION.LE_COLLECTIF, DIVISION.SELEGO_FR].includes(filter.division)) {
    elements = frenchInfo(filter);
  }
  if ([DIVISION.SELEGO_NL].includes(filter.division)) {
    elements = dutchInfo(filter);
  }
  if ([DIVISION.SELESPAGNE].includes(filter.division)) {
    elements = spanishInfo(filter);
  }

  console.log(elements);
  return (
    <div className="w-full">
      <label className="font-bold">Salary</label>

      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        <div className="mt-2">
          {/* <TabItem title="Day" setTab={setTab} tab="day" active={tab === "day"} />
        <TabItem title="Month" setTab={setTab} tab="month" active={tab === "month"} />
        <TabItem title="Year" setTab={setTab} tab="year" active={tab === "year"} /> */}
          {elements.map((e, i) => {
            return e.title ? (
              <div className="flex flex-row justify-between py-1">
                <div>
                  <label>{e.title}</label>
                  <div className="text-sm text-gray-500">{e.subtitle}</div>
                </div>
                <div>{e.value}€</div>
              </div>
            ) : e.category ? (
              <div className="w-full text-center flex-row justify-between py-1">
                <label className="font-bold">{e.category}</label>
              </div>
            ) : (
              <hr />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const spanishInfo = (filter) => {
  const elements = [];

  if (filter.contract === CONTRACTS.INTERNSHIP) {
    let monthly_brut = 0;
    if (filter.yearSchool === YEARSSCHOOL.FIFTHYEAR) {
      monthly_brut = 1200;
    } else if (filter.yearSchool === YEARSSCHOOL.FOURTHYEAR) {
      monthly_brut = 1000;
    } else {
      monthly_brut = 800;
    }
    let brut = monthly_brut * 12;
    if (filter.brut_salary) brut = filter.brut_salary;
    elements.push({ key: "salary", title: "Brut", subtitle: `${monthly_brut} net per month, you need to come to the office at least 75% of the time`, value: brut });

    elements.push({ title: "Net", subtitle: "Net is almost the same as brut for interns", value: brut });
  }

  if ([CONTRACTS.CDI, CONTRACTS.CDD].includes(filter.contract)) {
    const index = BENCHMARKS_CDI_START.findIndex((e) => e[0] === filter.role);

    let brut = 0;

    if (filter.experience_total < 7) {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, filter.experience_total) * 0.9;
    } else {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, 7) * Math.pow(1.03, filter.experience_total - 7) * 0.9;
    }

    brut = 0.9 * brut;
    brut = filter.brut_salary ? filter.brut_salary : brut;

    elements.push({ key: "salary", title: "Brut", subtitle: "", value: Math.round(brut) });

    elements.push(<hr />);
    elements.push({ category: "--- Employee part ----" });

    const tax = brut * 0.23;
    elements.push({ title: "Tax salaire", subtitle: "", value: Math.round(tax) });

    const net = brut - tax;
    elements.push({ title: "Net", subtitle: "", value: Math.round(net) });

    elements.push(<hr />);
    elements.push({ category: "--- Costs due to Salary ----" });

    const taxentreprise = brut * 0.25;
    elements.push({ key: "salary", title: "Company taxes", subtitle: "paid to ? (pension, illness, unemployment) brut * 0.25", value: Math.round(taxentreprise) });
  }

  // elements.push(<hr />);
  // elements.push({ category: "--- Costs due to the company ----"});
  // if (filter.computer) {
  //   elements.push({ key: "expenses", title: "Computer from the company", subtitle: "one shot, renting", value: 50 * 12 });
  // }
  // if (filter.location == LOCATION.PARIS) {
  //   elements.push({ key: "expenses", title: "Office in Paris", subtitle: "per year, for one person", value: 200 * 12 });
  // } else if (filter.location == LOCATION.BARCELONE) {
  //   elements.push({ key: "expenses", title: "Office in Barcelone", subtitle: "per year, for one person, coworking spot", value: 50 * 12 });
  // } else if (filter.location == LOCATION.AMSTERDAM) {
  //   elements.push({ key: "expenses", title: "Office in Amsterdam", subtitle: "per year, for one person", value: 300 * 12 });
  // }

  // elements.push({ key: "expenses", title: "Tools for the whole company (Notion, Github, Figma, OpenAi...)", subtitle: "700€ per year per employee", value: 700 });
  // elements.push({ key: "expenses", title: "Hosting", subtitle: "700€ per year per employee (Mainly Accounting)", value: 700 });
  // elements.push({ key: "expenses", title: "Accountant & payroll", subtitle: "500€ per year per employee", value: 500 });

  return elements;
};

export const dutchInfo = (filter) => {
  const elements = [];

  if (filter.contract === CONTRACTS.INTERNSHIP) {
    let monthly_brut = 0;
    if (filter.yearSchool === YEARSSCHOOL.FIFTHYEAR) {
      monthly_brut = 1500;
    } else if (filter.yearSchool === YEARSSCHOOL.FOURTHYEAR) {
      monthly_brut = 1200;
    } else {
      monthly_brut = 1000;
    }
    let brut = monthly_brut * 12;
    if (filter.brut_salary) brut = filter.brut_salary;

    elements.push({ key: "salary", title: "Brut", subtitle: `${monthly_brut} net per month, you need to come to the office at least 75% of the time`, value: brut });

    elements.push({ title: "Net", subtitle: "Net is almost the same as brut for interns", value: brut });

    elements.push(<hr />);
    elements.push({ category: "--- Costs due to Salary ----" });

    const taxentreprise = brut * 0.25;
    elements.push({ key: "salary", title: "Company taxes", subtitle: "paid to Belastingdienst (pension, illness, unemployment) * brut * 0.25", value: Math.round(taxentreprise) });
  }

  if ([CONTRACTS.CDI, CONTRACTS.CDD].includes(filter.contract)) {
    const index = BENCHMARKS_CDI_START.findIndex((e) => e[0] === filter.role);

    let brut = 0;

    if (filter.experience_total < 7) {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, filter.experience_total) * 0.9;
    } else {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, 7) * Math.pow(1.03, filter.experience_total - 7) * 0.9;
    }
    brut = filter.brut_salary ? filter.brut_salary : brut;
    elements.push({ key: "salary", title: "Brut", subtitle: "", value: Math.round(brut) });

    elements.push(<hr />);
    elements.push({ category: "--- Employee part ----" });

    let tax = 0;
    if (filter.dutch_ruling) {
      tax = Math.round(brut * 0.34 - 7128);
      elements.push({ title: "Tax salaire", subtitle: "(brut * 0,34) - 7128 (30% ruling applied)", value: Math.round(tax) });
    } else {
      tax = Math.round(brut * 0.4672 - 9564);
      elements.push({ title: "Tax salaire", subtitle: "(brut * 0,4672) - 9564", value: Math.round(tax) });
    }

    const net = brut - tax;
    elements.push({ title: "Net", subtitle: "", value: Math.round(net) });

    elements.push(<hr />);
    elements.push({ category: "--- Costs due to Salary ----" });

    const taxentreprise = brut * 0.25;
    elements.push({ key: "salary", title: "Company taxes", subtitle: "paid to Belastingdienst (pension, illness, unemployment) * brut * 0.25", value: Math.round(taxentreprise) });
  }
  elements.push({ title: "Billet aller/retour ( oneshot )", subtitle: "", value: 400 });

  // elements.push({ category: "--- Costs due to the company ----"});

  // if (filter.computer) {
  //   elements.push({ key: "expenses", title: "Computer from the company", subtitle: "one shot, renting", value: 50 * 12 });
  // }

  // if (filter.location == LOCATION.PARIS) {
  //   elements.push({ key: "expenses", title: "Office in Paris", subtitle: "per year, for one person", value: 200 * 12 });
  // } else if (filter.location == LOCATION.BARCELONE) {
  //   elements.push({ key: "expenses", title: "Office in Barcelone", subtitle: "per year, for one person, coworking spot", value: 50 * 12 });
  // } else if (filter.location == LOCATION.AMSTERDAM) {
  //   elements.push({ key: "expenses", title: "Office in Amsterdam", subtitle: "per year, for one person", value: 300 * 12 });
  // }

  // elements.push({ key: "expenses", title: "Tools for the whole company (Notion, Github, Figma, OpenAi...)", subtitle: "700€ per year per employee", value: 700 });
  // elements.push({ key: "expenses", title: "Hosting", subtitle: "700€ per year per employee (Mainly Accounting)", value: 700 });
  // elements.push({ key: "expenses", title: "Accountant & payroll", subtitle: "500€ per year per employee", value: 500 });

  return elements;
};

export const frenchInfo = (filter) => {
  const elements = [];
  if (filter.contract === CONTRACTS.VIE) {
    let net = 0;
    console.log(filter.location);
    if (filter.location == LOCATION.BARCELONE) {
      net = Math.round(2382 * 12);
    } else if (filter.location == LOCATION.AMSTERDAM) {
      net = Math.round(3108 * 12);
    }

    net = filter.brut_salary ? filter.brut_salary : net;

    elements.push({
      key: "salary",
      title: "Brut",
      subtitle: "Reference : https://civiwebprod.cdn.prismic.io/civiwebprod/45b28a08-9245-4020-b95a-26f792a11704_BAREME+VIE+AU+1ER+JANVIER+2024+VF.pdf",
      value: net,
    });
    elements.push(<hr />);
    elements.push({ category: "--- Employee part ----" });

    elements.push({ title: "Net", subtitle: "Reference : pour un VIE, brut = net", value: net });

    elements.push(<hr />);
    elements.push({ category: "--- Costs due to Salary ----" });

    elements.push({ key: "salary", title: "Company taxes", subtitle: "0 taxes for a VIE payed by the company", value: 0 });

    let max_trip = 400;
    elements.push({ title: "Trip", subtitle: "paid by the company, max 400€", value: max_trip });
  }

  if (filter.contract === CONTRACTS.INTERNSHIP) {
    let monthly_brut = 0;
    if (filter.yearSchool === YEARSSCHOOL.FIFTHYEAR) {
      monthly_brut = 1500 * 0.9;
    } else if (filter.yearSchool === YEARSSCHOOL.FOURTHYEAR) {
      monthly_brut = 1200 * 0.9;
    } else {
      monthly_brut = 1000 * 0.9;
    }
    let brut = 12 * monthly_brut;
    brut = filter.brut_salary ? filter.brut_salary : brut;
    elements.push({ key: "salary", title: "Brut", subtitle: `${monthly_brut} net per month`, value: brut });

    elements.push(<hr />);
    elements.push({ category: "--- Employee part ----" });

    let french_ticketresto_employee = 0;

    if (filter.french_ticketresto) {
      const perDayEmployee = 4.61;
      french_ticketresto_employee = Math.round((perDayEmployee * filter.numberDays * 218) / 5);
      elements.push({
        title: "Tickets resto Employee",
        subtitle: `employee pays ${french_ticketresto_employee}) out of his brutto salary`,
        value: Math.round(french_ticketresto_employee),
      });
    }

    const net = brut - french_ticketresto_employee;
    elements.push({ title: "Net", subtitle: "Brut (- Tickets restau)", value: Math.round(net) });

    elements.push(<hr />);
    elements.push({ category: "--- Costs due to Salary ----" });

    const taxentreprise = brut * 0.25;
    elements.push({ key: "salary", title: "Company taxes", subtitle: "paid to Urssaf (pension, illness, unemployment) * brut * 0.25", value: Math.round(taxentreprise) });
  }

  if (filter.contract === CONTRACTS.APPRENTICESHIP) {
    let monthly_brut = 0;
    if (filter.yearSchool === YEARSSCHOOL.FIFTHYEAR) {
      monthly_brut = 1800;
    } else if (filter.yearSchool === YEARSSCHOOL.FOURTHYEAR) {
      monthly_brut = 1500;
    } else {
      monthly_brut = 1300;
    }

    let brut = monthly_brut * 12;
    brut = filter.brut_salary ? filter.brut_salary : brut;
    elements.push({ key: "salary", title: "Brut", subtitle: `${monthly_brut} net per month`, value: brut });

    let french_ticketresto_employee = 0;

    elements.push(<hr />);
    elements.push({ category: "--- Employee part ----" });

    if (filter.french_ticketresto) {
      const perDayEmployee = 4.61;
      french_ticketresto_employee = Math.round((perDayEmployee * filter.numberDays * 218) / 5);
      elements.push({
        title: "Tickets resto Employee",
        subtitle: `employee pays ${french_ticketresto_employee}) out of his brutto salary`,
        value: Math.round(french_ticketresto_employee),
      });
    }

    const net = brut - french_ticketresto_employee;
    elements.push({ title: "Net", subtitle: "Brut - Tickets restau, net is almost the same for alternants", value: Math.round(net) });

    elements.push(<hr />);
    elements.push({ category: "--- Costs due to Salary ----" });

    const taxentreprise = brut * 0.25;
    elements.push({ key: "salary", title: "Company taxes", subtitle: "paid to Urssaf (pension, illness, unemployment) * brut * 0.25", value: Math.round(taxentreprise) });

    const taxreduction = 6000;
    elements.push({ key: "salary-reduction", title: "Tax reduction", subtitle: "for the first year only", value: Math.round(taxreduction) });

    elements.push(<hr />);
    elements.push({ category: "--- Additional costs ----" });

    const find = elements.find((e) => e.title === "Brut");
    if (find) {
      const brut = Math.round(find.value);
      const month_treshold = 3428;
      let monthly_brut = brut / 12;

      if (monthly_brut < month_treshold) {
        elements.push({
          key: "salary",
          title: "Retraite Complémentaire - Malakoff Mederic",
          subtitle: "7.87% of gross salary paid by the company, 0% paid by the employee",
          value: Math.round(brut * 0.0787),
        });
        elements.push({
          key: "salary",
          title: "Contribution d'équilibre général CEG - Malakoff Mederic",
          subtitle: "2.15% of gross salary paid by the company, 0% paid by the employee",
          value: Math.round(brut * 0.0215),
        });
        elements.push({
          key: "salary",
          title: "Prevoyance - Alan ",
          subtitle: "0.8% of gross salary paid by the company, 0% paid by the employee",
          value: Math.round(brut * 0.008),
        });
      } else {
        elements.push({
          key: "salary",
          title: "Retraite Complémentaire - Malakoff Mederic",
          subtitle: "21.59% of gross salary difference paid by the company, 0% paid by the employee",
          value: Math.round((brut - month_treshold * 12) * 0.2159),
        });
        elements.push({
          key: "salary",
          title: "Contribution d'équilibre général CEG - Malakoff Mederic",
          subtitle: "2.7% of gross salary paid by the company, 0% paid by the employee",
          value: Math.round((brut - month_treshold * 12) * 0.027),
        });
        elements.push({
          key: "salary",
          title: "Prevoyance - Alan",
          subtitle: "1.3% of gross salary difference paid by the company, 0% paid by the employee",
          value: Math.round((brut - month_treshold * 12) * 0.013),
        });
      }
    }
    elements.push({ key: "salary", title: "Médecine du Travail - CIAMT", subtitle: "115€ per year per employee", value: 115 });
  }

  if ([CONTRACTS.CDI, CONTRACTS.CDD].includes(filter.contract)) {
    const index = BENCHMARKS_CDI_START.findIndex((e) => e[0] === filter.role);

    let brut = 0;

    if (filter.experience_total < 7) {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, filter.experience_total) * 0.9;
    } else {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, 7) * Math.pow(1.03, filter.experience_total - 7) * 0.9;
    }
    brut = filter.brut_salary ? filter.brut_salary : brut;
    elements.push({ key: "salary", title: "Brut", subtitle: "", value: Math.round(brut) });

    elements.push(<hr />);
    elements.push({ category: "--- Employee part ----" });

    const tax = brut * 0.4432 - 6624;
    elements.push({ title: "Employee taxes", subtitle: "(brut * 0.4432) - 6624", value: Math.round(tax) });

    let french_ticketresto_employee = 0;

    if (filter.french_ticketresto) {
      const perDayEmployee = 4.61;
      french_ticketresto_employee = Math.round((perDayEmployee * filter.numberDays * 218) / 5);
      elements.push({ title: "Tickets resto Employee", subtitle: `employee pays ${french_ticketresto_employee} out of his brutto salary`, value: french_ticketresto_employee });
    }

    const net = brut - tax - french_ticketresto_employee;
    elements.push({ title: "Net", subtitle: "Brut - Taxes - Tickets restau", value: Math.round(net) });

    if (CONTRACTS.CDD === filter.contract) {
      const prime = 0.1 * (brut / 12).toFixed(0) * filter.contractLength;
      elements.push({ key: "salary", title: "Primes fin CDD", subtitle: `0.1 x ${Math.round(brut / 12)} x ${filter.contractLength}`, value: Math.round(prime) });
    }

    elements.push(<hr />);
    elements.push({ category: "--- Costs due to Salary ----" });

    const taxentreprise = brut * 0.25;
    elements.push({ key: "salary", title: "Company taxes", subtitle: "paid to Urssaf (pension, illness, unemployment) brut * 0.25", value: Math.round(taxentreprise) });

    const find = elements.find((e) => e.title === "Brut");
    if (find) {
      const brut = Math.round(find.value);
      const month_treshold = 3428;
      let monthly_brut = brut / 12;

      if (monthly_brut < month_treshold) {
        elements.push({
          key: "salary",
          title: "Retraite Complémentaire - Malakoff Mederic",
          subtitle: "7.87% of gross salary paid by the company, 0% paid by the employee",
          value: Math.round(brut * 0.0787),
        });
        elements.push({
          key: "salary",
          title: "Contribution d'équilibre général CEG - Malakoff Mederic",
          subtitle: "2.15% of gross salary paid by the company, 0% paid by the employee",
          value: Math.round(brut * 0.0215),
        });
        elements.push({
          key: "salary",
          title: "Prevoyance - Alan ",
          subtitle: "0.8% of gross salary paid by the company, 0% paid by the employee",
          value: Math.round(brut * 0.008),
        });
      } else {
        elements.push({
          key: "salary",
          title: "Retraite Complémentaire - Malakoff Mederic",
          subtitle: "21.59% of gross salary difference paid by the company, 0% paid by the employee",
          value: Math.round((brut - month_treshold * 12) * 0.2159),
        });
        elements.push({
          key: "salary",
          title: "Contribution d'équilibre général CEG - Malakoff Mederic",
          subtitle: "2.7% of gross salary paid by the company, 0% paid by the employee",
          value: Math.round((brut - month_treshold * 12) * 0.027),
        });
        elements.push({
          key: "salary",
          title: "Prevoyance - Alan",
          subtitle: "1.3% of gross salary difference paid by the company, 0% paid by the employee",
          value: Math.round((brut - month_treshold * 12) * 0.013),
        });
      }
    }
    elements.push({ key: "salary", title: "Médecine du Travail - CIAMT", subtitle: "115€ per year per employee", value: 115 });
  }

  elements.push(<hr />);
  elements.push({ category: "--- Costs due to Expenses ----" });
  if (filter.location === LOCATION.REMOTE) {
    const monthly_teletravail = 50;
    elements.push({ key: "expenses", title: "Teletravail", subtitle: "per month", value: monthly_teletravail * 12 });
  }

  if (filter.french_alan) {
    const monthly_french_mutuelle = 64;
    elements.push({ key: "expenses", title: "French Alan", subtitle: "per employee/month", value: monthly_french_mutuelle * 12 });
  }

  if (filter.french_ticketresto) {
    const perDayEmployer = 6.91;
    const perDayEmployee = 4.61;
    let french_ticketresto_employer = Math.round((perDayEmployer * filter.numberDays * 218) / 5);
    elements.push({
      key: "expenses",
      title: "Tickets resto",
      subtitle: `employee/day paid by the company (employee pays ${perDayEmployee} per day)`,
      value: Math.round(french_ticketresto_employer),
    });
  }

  if (filter.french_navigo) {
    elements.push({ key: "expenses", title: "French transport", subtitle: "per month", value: 50 });
  }

  // if (filter.computer) {
  //     elements.push({ key: "expenses", title: "Computer from the company", subtitle: "one shot, renting", value: 50 * 12 });
  // }

  // elements.push(<hr />);
  // elements.push({ category: "--- Costs due to the company ----"});

  // if (filter.location == LOCATION.PARIS) {
  //   elements.push({ key: "expenses", title: "Office in Paris", subtitle: "per year, for one person", value: 200 * 12 });
  // } else if (filter.location == LOCATION.BARCELONE) {
  //   elements.push({ key: "expenses", title: "Office in Barcelone", subtitle: "per year, for one person, coworking spot", value: 50 * 12 });
  // } else if (filter.location == LOCATION.AMSTERDAM) {
  //   elements.push({ key: "expenses", title: "Office in Amsterdam", subtitle: "per year, for one person", value: 300 * 12 });
  // }

  // elements.push({ key: "expenses", title: "Tools for the whole company (Notion, Github, Figma, OpenAi...)", subtitle: "700€ per year per employee", value: 700 });
  // elements.push({ key: "expenses", title: "Hosting", subtitle: "700€ per year per employee (Mainly Accounting)", value: 700 });
  // elements.push({ key: "expenses", title: "Accountant & payroll", subtitle: "500€ per year per employee", value: 500 });

  return elements;
};

/*
Tax salary (hors VIE, for CDI and stages) :
si France : Salaire brut mensuel*0,4432-552
si NL : Salaire brut mensuel*0,4672-797
si Espagne : je sais pas encore


2. Nour did it
3. benchmark on cdd I'll do for end of week, for now let's take the one from cdi
4. benchmark on alternant I'll do for tomorrow end of day, tax salary is also not the same if it's an alternant so I'll let you know
5. si 30% ruling & NL : tax salary = salaire brut mensuel*0,34-594
6. faut rajouter les primes de cdd si c'est contrat cdd qui est coché (meme si quand transféré en cdi il y en a pas je pense que c'est cool)
and so primes cdd = 0.1*brut salary*months of the length of the cdd
7. missing the tax for the company (tax for the company + every advantages + brut salary ) = total cost (I think it's important to add it, whether the tool is for the company or for the team)
*/

const TabItem = ({ tab, title, setTab, active, Icon }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-primary  border-primary" : "",
    )}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
