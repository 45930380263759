import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../components/pagination";
import api from "../../../services/api";

export default ({ category }) => {
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({ status: "open", page: 1, search: "" });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    get();
  }, [filters, category]);

  async function get() {
    setLoading(true);

    const nf = { ...filters };
    if (category) nf.category = category;
    const { data, total } = await api.post(`/mail/search`, nf);

    setData(data);
    setTotal(total);
    setLoading(false);
  }

  if (!data) return <div>Loading...</div>;

  return (
    <div>
      <div className="p-2">
        <CheckBox
          label="Show only unprocessed"
          value={filters.status}
          onChange={(v) => {
            if (v) {
              setFilters((f) => ({ ...f, status: "open" }));
            } else {
              setFilters((f) => ({ ...f, status: undefined }));
            }
          }}
        />
        <Searchbar value={filters.search} onChange={(search) => setFilters({ ...filters, search })} />
        <div className="overflow-x-auto bg-white mt-2">
          <table className="md:w-full table-auto">
            <thead className="border-t border-b-2	 border-t-[#dee2e6] border-l-0 border-r-0 border-b-[#dee2e6]">
              <tr>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Status
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Date
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>Loading...</tr>
              ) : (
                (data || []).map((hit) => {
                  return (
                    <tr key={hit._id} className="hover:bg-[#dee2e6] border-b border-[#dee2e6]">
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <Status value={hit.status} />
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div>{hit.from_name?.substring(0, 20)}</div>
                        <div className="text-gray-400 text-xs">{hit.subject}</div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div>
                          <div>{hit.createdAt.slice(0, 10)}</div>
                          <div className="text-gray-400 text-xs">created at {new Date(hit.createdAt).toLocaleString()}</div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <Link to={`/mail/${hit._id}`} className="text-blue-500">
                          GO
                        </Link>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <Files files={hit.files} />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          per_page={50}
          total={total}
          onNext={() => setFilters((f) => ({ ...f, page: +f.page + 1 }))}
          onPrevious={() => setFilters((f) => ({ ...f, page: +f.page - 1 }))}
          currentPage={filters.page}
        />
      </div>
    </div>
  );
};

const Files = ({ files }) => {
  if (files.length === 0) return <div />
  return files.map((e) => {
    return (
      <a key={e} href={e} target="_blank" className="cursor-pointer">
        🧾
      </a>
    );
  });
};


const CheckBox = ({ label, value, onChange }) => {
  return (
    <div className="flex items-center gap-2">
      <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />
      <div>{label}</div>
    </div>
  );
};

const Status = ({ value = "" }) => {
  let color = "bg-purple-600 text-white";
  if (value === "open") color = "bg-purple-600 text-white";
  if (value === "closed") color = "bg-green-600 text-white";
  return (
    <div>
      <span className={`text-xs ${color} rounded-sm px-2 capitalize`}>{value.toLowerCase()}</span>
    </div>
  );
};

const Searchbar = ({ value, onChange }) => {
  const [search, setSearch] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    onChange(search);
  }

  return (
    <form className="flex items-center" onSubmit={onSubmit} type="submit">
      <div className="flex items-center">
        <div className="flex items-center border border-[#ccc] rounded-[10px] w-[300px] h-[40px]">
          <input
            key="mykyyy"
            className="w-full h-full pl-[20px] bg-[#F9FBFD] rounded-[10px] focus:outline-none"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#F9FBFD] rounded-[10px]">
            <button className="flex items-center justify-center w-[40px] h-[40px] bg-[#F9FBFD] rounded-[10px]" onClick={onSubmit}>
              Search
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
