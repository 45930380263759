import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import Modal from "../../components/modal";

import api from "../../services/api";

export default () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"] });
  const navigate = useNavigate();

  async function onCreate() {
    if (!values.title) return toast.error("Missing title !");
    const { data } = await api.post("/question", values);

    navigate(`/questioning/${data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Create a question
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Title (*)</div>
          <input className="projectsInput block" value={values.title} onChange={(e) => setValues({ ...values, title: e.target.value })} />
        </div>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
