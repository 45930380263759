import TechSvg from "../assets/TechSvg";
import DataSpecialistSvg from "../assets/DataSpecialistSvg";
import MarketingSvg from "../assets/MarketingSvg";
import ProjectManagerSvg from "../assets/ProjectManagerSvg";
import WebDesignSvg from "../assets/WebDesignSvg";
import ThreeDots from "../assets/ThreeDots";

export const JOB_CATEGORIES = [
  {
    id: 0,
    ImageSvg: TechSvg,
    title: "Tech",
    categories: ["Backend Developer", "Frontend Developer", "Full Stack Developer..."],
  },
  {
    id: 1,
    ImageSvg: DataSpecialistSvg,
    title: "Data Specialist",
    categories: ["Data Scientist", "Data Engineer", "Data Analyst..."],
  },
  {
    id: 2,
    ImageSvg: MarketingSvg,
    title: "Marketing",
    categories: ["Marketing Consultant, CRM", "Consultant, SEO", "Consultant..."],
  },
  {
    id: 3,
    ImageSvg: ProjectManagerSvg,
    title: "Product",
    categories: ["Product Owner", "Product Data", "Product Manager…"],
  },
  {
    id: 4,
    ImageSvg: WebDesignSvg,
    title: "Web & Graphic Design",
    categories: ["UI/UX Designer", "UI Developer", "UX Designer..."],
  },
  {
    id: 5,
    ImageSvg: ThreeDots,
    title: "Other",
    categories: ["Type your own category"],
  },
];

export const AREA_OF_EXPERTISE = {
  Tech: [
    "Backend Web Developer",
    "Frontend Web Developer",
    "Full Stack Developer",
    "CMS Developer",
    "Mobile Developer",
    "Web Integrator",
    "Software Engineer",
    "DevOps",
    "Architect IT",
    "Cybersecurity Expert",
    "iOT Developer",
    "Cloud Engineer & Architect",
    "ERP Developer",
  ],
  "Data Specialist": ["Data Scientist", "Data Engineer", "Data Analyst"],
  Marketing: [
    "Marketing Consultant",
    "CRM Consultant",
    "SEO Consultant",
    "Traffic Manager",
    "Growth Hacker",
    "Revenue Manager",
    "E-Merchandiser",
    "Conversion Rate optimizer",
    "Analytics Consultant",
  ],
  Product: ["Product Manager", "Mobile Project Manager", "Product Owner", "Product Data", "Agile Coach", "IT Project Manager"],
  "Web & Graphic Design": ["Graphics Designer", "Web Designer", "UX Designer", "UI Designer", "Illustrator", "Art Director"],
};

export const SKILLS = [
  "Blockchain",
  "Video marketing",
  "Cloud Computing",
  "Analytic web",
  "AI (Artificial Intelligence)",
  "UX Design",
  "UI Design",
  "Commercial development",
  "Affiliate marketing",
  "Sales",
  "IT",
  "Efficiency",
  "Organization",
  "Communication",
  "Empathy",
  "Versatility",
  "Team spirit",
  "Sense of initiative",
  "Creativity",
  "Adaptability",
  "Redaction",
  "Time management",
  "JavaScript",
  "Node.js",
  "ReactNative",
  "ReactJS",
  "Management",
  "Git",
  "C",
  "C++",
  "C#",
  "Java",
  "PHP",
  "MySQL",
  "Symfony",
  "HTML",
  "CSS",
  "Vue.js",
  "Angular",
  "Wordpress",
  "Figma",
  "Project management",
  "English",
  "French",
  "Data analytics",
  "Delivery",
  "Discovery",
];

export const TRADUCTION_STATUS = {
  NEW: "New applications",
  SHORTLIST: "Shortlist of best profiles",
  CONTACTED: "Contacted profiles",
  INTERVIEW: "Profiles scheduled for a first interview",
  TECHNICAL_TEST: "Technical test sent or in progress",
  FINAL_LIST: "Second interview scheduled or final list",
  HIRE: "In hiring process",
  REJECT: "Rejected",
};
