import React from "react";
import { Link } from "react-router-dom";
import Applications from "./applications";
import Loader from "../../components/loader";
import api from "../../services/api";
import selego from "../../assets/selego.jpeg";
import ProfileCompletionBlock from "./profile_completion";

const ApplicantHome = () => {
  return (
    <div className="px-4 py-8 mx-auto">
      <div className="bg-white p-6 rounded-md ">
        <div className={"flex items-start"}>
          <h2 className="text-3xl font-bold mb-4 text-gray-800">Welcome to Startup Studio Le Stud</h2>
          <img className="ml-4" src={selego} alt="" width={40} height={40} />
        </div>

        <p className="text-md mb-6 text-gray-600">Here, you'll discover exciting opportunities tailored just for you and continue to develop your skills.</p>
        <p className="text-md mb-6 text-gray-600">
          Le Stud is a cutting-edge startup creation studio with offices in Paris and Amsterdam. Our team of seasoned entrepreneurs is dedicated to developing and supporting CEOs
          in building, strategizing, and growing technology startups.
        </p>
        <p className="text-md mb-2 text-gray-600">
          Your application is currently under review by our experienced team. If it aligns with our needs, you'll hear back from us. Meanwhile, explore our job offerings and ensure
          your profile is complete and up to date.
        </p>
      </div>
      <div className="flex justify-end mb-2 p-2">
        <Link to="/jobs" className="btn btn-primary p-4">
          Discover other job offers
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
        <Applications />
        {/* <ProfileCompletionBlock /> */}
      </div>
    </div>
  );
};

export default ApplicantHome;

const Tutorials = () => {
  const [workshops, setWorkshops] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await api.get("/workshop?category=lesson");
      setWorkshops(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="bg-white p-6 border border-gray-200">
      <div className="text-base font-bold mb-2">Your courses</div>
      <div className="text-sm mb-4">Here you'll find all your latest courses. The more courses you validate, the greater your chances of being selected in your applications!</div>
      <div className="space-y-4 overflow-auto max-h-96">
        {!workshops ? (
          <Loader size="small" />
        ) : (
          workshops.map((workshop) => (
            <Link
              to={`/learn/${workshop._id}`}
              className="flex flex-row no-underline	hover:no-underline rounded-md cursor-pointer hover:shadow-md p-2 my-2 mr-1 border border-gray-200">
              <div className="flex flex-row w-full">
                <img src={workshop.image} className="object-cover rounded-xl w-10 h-10" />
                <div className="flex flex-col">
                  <div className="text-xs font-semibold text-dull-black flex flex-col mx-2">{workshop.name}</div>
                  {workshop.read_time && <div className="text-xs text-dull-grey ml-2">({workshop.read_time} read)</div>}
                  <div className="flex flex-row items-center ml-2 mt-auto mb-4">
                    <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                    <div className="text-xs ml-2">{`${workshop.rating_score} (${workshop.rating_count})`}</div>
                  </div>
                  <div className="text-sm">{workshop.description}</div>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};
