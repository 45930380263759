import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

import Modal from "./modal";
import api from "../services/api";

//https://dribbble.com/shots/21132444-Manage-team-members-modal

export default function UserPicker({ value, onChange, roles = ["admin", "user"] }) {
  return (
    <div>
      <div className="flex items-center w-full gap-2 flex-wrap">
        {(value || []).map((user) => (
          <div key={user.user_id} className="group relative">
            <span
              onClick={() => {
                const updatedUsers = value.filter((u) => u.user_id !== user.user_id);
                onChange(updatedUsers);
              }}
              className="cursor-pointer h-6 w-6 p-2 rounded-full bg-black text-white flex items-center justify-center opacity-0 text-xs group-hover:opacity-100 absolute -top-1 -right-1">
              ×
            </span>
            <img className="h-10 w-10 rounded-full" src={user.user_avatar} alt={user.user_name} />
          </div>
        ))}
        <SearchModal
          onUser={(user) => {
            if (value) {
              onChange([...value, { user_id: user._id, user_name: user.name, user_avatar: user.avatar }]);
            } else {
              onChange({ user_id: user._id, user_name: user.name, user_avatar: user.avatar });
            }
          }}
          roles={roles}
        />
      </div>
    </div>
  );
}
const SearchModal = ({ onUser, roles }) => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.Auth.user);

  const debouncedSearchUser = debounce(async (searchQuery) => {
    setLoading(true);
    const res = await api.post("/user/search", { search: searchQuery, per_page: 10, sort: "name", roles });
    setUsers(res.data.users);
    setLoading(false);
  }, 300);

  useEffect(() => {
    if (search.length > 0) {
      debouncedSearchUser(search);
    } else {
      setUsers([]);
    }
  }, [search]);

  return (
    <div>
      <button onClick={() => setOpen(true)} className="py-2 px-4 bg-blue-500 text-white rounded-md">
        Add
      </button>
      <Modal
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setSearch("");
        }}
        className="w-1/3">
        <div className="p-4 m-4">
          <input type="text" placeholder="Search" className="border border-gray-300 p-2 rounded w-full mb-2" onChange={(e) => setSearch(e.target.value)} />
          {!search && (
            <Card
              user={{ ...user, name: `${user?.name} (me)` }}
              onClick={() => {
                onUser(user);
                setOpen(false);
                setSearch("");
              }}
            />
          )}
          {search && (
            <div className="space-y-2">
              {loading ? (
                <div>Loading...</div>
              ) : (
                users.map((user) => {
                  return (
                    <Card
                      key={user._id}
                      user={user}
                      onClick={() => {
                        onUser(user);
                        setOpen(false);
                        setSearch("");
                      }}
                    />
                  );
                })
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

const Card = ({ user, onClick }) => {
  return (
    <div onClick={onClick} className="bg-white border border-gray-200 rounded-md p-2 cursor-pointer hover:shadow-md transition-shadow duration-150 ease-in-out">
      <div className="flex items-center gap-2">
        <img className="h-8 w-8 rounded-full" src={user.avatar} alt={user.name} />
        <div className="flex-1">
          <p className="font-medium">{user.name}</p>
          <span className="text-gray-500 text-sm">{user.contract}</span>
        </div>
      </div>
    </div>
  );
};
