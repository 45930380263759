export const authActions = {
  SETUSER: "SETUSER",
  SETSELECTEDUSER: "SETSELECTEDUSER",
  SETORGANISATION: "SETORGANISATION",
};

export function setUser(user) {
  return { type: authActions.SETUSER, user };
}

export function setSelectedUser(selectedUser) {
  return { type: authActions.SETSELECTEDUSER, selectedUser };
}

export function setOrganisation(organisation) {
  return { type: authActions.SETORGANISATION, organisation };
}
