import React, { useState } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";
import LoadingButton from "../../../components/loadingButton";

export default ({ project }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ from: null, to: null });
  const [report, setReport] = useState();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  async function handleGenerateReport(e) {
    try {
      e.preventDefault();
      setIsGeneratingReport(true);
      const { data, ok } = await api.post(`/project/${project._id}/commits_report`, values);
      setIsGeneratingReport(false);
      if (!ok) return toast.error("Error");
      setReport(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
      setIsGeneratingReport(false);
    }
  }

  return (
    <>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Generate report
      </button>
      {open ? (
        <div className="absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md" onClick={(e) => e.stopPropagation()}>
            <React.Fragment>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-1">
                  <div className="flex flex-col mb-4">
                    <label className="block font-medium text-gray-600 mb-1">{"From (Date)"}</label>
                    <input
                      type="date"
                      className="w-full border border-gray-300 rounded-md p-2 mt-1 block focus:ring-blue-500 focus:border-blue-500"
                      value={values.from}
                      onChange={(e) => setValues({ ...values, from: e.target.value })}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <label className="block font-medium text-gray-600 mb-1">{"To (Date)"}</label>
                    <input
                      type="date"
                      className="w-full border border-gray-300 rounded-md p-2 mt-1 block focus:ring-blue-500 focus:border-blue-500"
                      value={values.to}
                      onChange={(e) => setValues({ ...values, to: e.target.value })}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <LoadingButton
                      className="btn btn-primary ml-auto mb-2"
                      loading={isGeneratingReport}
                      onClick={(e) => handleGenerateReport(e)}>
                      Generate report
                    </LoadingButton>
                  </div>

                  {isGeneratingReport && <div className="text-[#0560FD] text-[16px] font-medium">GPT is generating a report. This might take a while. Please do not reload the page</div>}
                </div>
                <div className="col-span-1">
                  <div className="flex flex-col mb-4">
                    <label className="block font-medium text-gray-600 mb-1">GPT report</label>
                    <textarea
                      placeholder="Report will appear here"
                      rows={20}
                      className="w-full border border-gray-300 rounded-md p-2"
                      value={report}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>


            </React.Fragment>
          </div>
        </div>
      ) : null}
    </>
  );
};
