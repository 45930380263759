import React, { useEffect, useState } from "react";
import DebounceInput from "react-debounce-input";

import Loader from "../../components/loader";
import api from "../../services/api";
import Pagination from "../../components/pagination";
import Edit from "./edit";

const List = () => {
  const [filter, setFilter] = useState({ search: "", per_page: 200, applicants: false, page: 1 });

  return (
    <div className="pt-1 px-2 md:px-8">
      <div className="flex flex-col-reverse md:flex-row justify-between items-center">
        <div className="flex gap-2 flex-wrap flex-col">
          <div className="flex items-center space-x-3">
            <div className="relative text-[#A0A6B1]">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button type="submit" className="p-1">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-5 h-5">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </span>
              <DebounceInput
                debounceTimeout={300}
                className="py-2 w-[364px] text-[14px] font-normal text-[black] rounded-[10px] bg-[#F9FBFD] border border-[#FFFFFF] pl-10"
                placeholder="Search (name, email, skills, etc...)"
                value={filter.search}
                onChange={(e) => {
                  e.persist();
                  setFilter((prev) => ({ ...prev, search: e.target.value, page: 1 }));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Users filter={filter} setFilter={setFilter} />
    </div>
  );
};

const Users = ({ filter, setFilter, query }) => {
  const [users, setUsers] = useState(null);
  const [total, setTotal] = useState(0);
  const [skillmap, setSkillmap] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);

  useEffect(() => {
    getUsers();
  }, [filter]);

  async function getUsers() {
    const { data } = await api.post(`/findyourcofounder_user/admin/search`, filter);
    setUsers(data.users);
    setTotal(data.total);
  }

  async function onDelete(id) {
    if (!window.confirm("Are you sure?")) return;
    const { data, ok } = await api.remove(`/findyourcofounder_user/${id}`);
    if (!ok) return toast.error(data.code);
    getUsers();
  }

  if (!users) return <Loader />;
  return (
    <>
      <Edit user={userToEdit} onClose={() => {
        setUserToEdit(null)
      }}
      />
      <div>
        <span className="text-sm font-normal text-gray-500">
          <>
            <span className="text-base font-medium text-gray-700">{users?.length}</span> of {total}
          </>
        </span>
      </div>
      <div className="overflow-x-auto">
        <table className="md:w-full table-auto">
          <thead className="border-t border-b-2	 border-t-[#dee2e6] border-l-0 border-r-0 border-b-[#dee2e6] bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                (APP) Country
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                City
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Approved
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Clicks
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Last login at
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {(users || []).map((user) => {
              return (
                <tr key={user._id} className="hover:bg-[#dee2e6] border-b border-[#dee2e6]">
                  <td className="weventespace-nowrap py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0">{user.first_name} {user.last_name}</td>
                  <td className="weventespace-nowrap py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0">{user.app_country}</td>
                  <td className="weventespace-nowrap py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0">{user.city}</td>
                  <td className="weventespace-nowrap py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0">{user.approved ? "Yes" : "No"}</td>
                  <td className="weventespace-nowrap py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0">{user.clicks}</td>
                  <td className="weventespace-nowrap py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0">{user.last_login_at}</td>
                  <td className="flex items-center">
                    {/* <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(user._id);
                      }}
                      className="btn btn-error text-xs">
                      Delete
                    </button> */}
                    <button
                      onClick={async () => {
                        setUserToEdit(user);
                      }}
                      className="btn btn-error text-xs">
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        per_page={filter.per_page}
        total={total}
        onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
        onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
        currentPage={filter.page}
      />
    </>
  );
};


export default List;
