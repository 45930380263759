import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../services/api";
import Chat from "./chat";
import { RightBar } from "./right_bar/right_bar";
import { SUPPORT_LABELS } from "../../../constants";
import moment from "moment";

export default ({}) => {
  const [ticket, setTicket] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    fetchTicket();
  }, [id]);

  async function fetchTicket() {
    const { data } = await api.get(`/ticket/${id}`);
    setTicket(data);
  }

  if (!ticket) return <div>Loading...</div>;

  return (
    <div>
      <Topbar ticket={ticket} setTicket={setTicket} />
      <div className="flex justify-between">
        <Chat ticket={ticket} setTicket={setTicket} />
        <RightBar ticket={ticket} setTicket={setTicket} />
      </div>
    </div>
  );
};

const Topbar = ({ ticket, setTicket }) => {
  async function onEditTitle(e) {
    try {
      const { data } = await api.put(`/ticket/${ticket._id}`, { title: e.target.value });
      toast.success(`Title changed`);
      setTicket(data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="border-b border-lightShade-grey p-2 flex items-center">
      <div className="flex items-center space-x-4">
        <input className="text-2xl font-bold w-full" defaultValue={ticket.title} onBlur={onEditTitle} />
        <div className="text-sm">{`#${ticket.index}`}</div>
      </div>
      <div className="flex items-center space-x-4 ml-auto">
        <div>
          <div>
            <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created at: </span>
            <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(ticket.created_at).format("DD/MM/YYYY, HH:mm")}</span>
          </div>
          <div>
            <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created by: </span>
            <span
              className="text-xs flex-1 text-left text-[#b7b3b4] "
              onClick={() => {
                const url = `/user/${ticket.user_id}`;
                window.open(url, "_blank");
              }}>
              {ticket.user_name}
            </span>
          </div>
        </div>
        <SelectLabel ticket={ticket} setTicket={setTicket} />
        <SelectStatus ticket={ticket} setTicket={setTicket} />
        <SelectPriority ticket={ticket} setTicket={setTicket} />
      </div>
    </div>
  );
};

const SelectPriority = ({ ticket, setTicket }) => {
  async function handleStatusChange(event) {
    try {
      const { data } = await api.put(`/ticket/${ticket._id}`, { priority: event.target.value });
      toast.success(`Priority changed`);
      setTicket(data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <select
        value={ticket.priority}
        onChange={handleStatusChange}
        className="mt-1 block w-full py-2 px-3 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
        <option value="HIGH">HIGH</option>
        <option value="MEDIUM">MEDIUM</option>
        <option value="LOW">LOW</option>
      </select>
    </div>
  );
};

const SelectStatus = ({ ticket, setTicket }) => {
  async function handleStatusChange(event) {
    const newStatus = event.target.value;
    try {
      if (newStatus === "CLOSE" && !confirm("Are you sure you want to close this ticket?")) return;
      const { data } = await api.put(`/ticket/${ticket._id}`, { status: newStatus });
      toast.success(`Ticket ${newStatus.toLowerCase()}`);
      setTicket(data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <select
        id="statusSelect"
        value={ticket.status}
        onChange={handleStatusChange}
        className="mt-1 block w-full py-2 px-3 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
        <option value="TODO">To Do</option>
        <option value="INPROGRESS">In Progress</option>
        <option value="AWAITING_FEEDBACK">Awaiting Feedback</option>
        <option value="CLOSE">Close</option>
      </select>
    </div>
  );
};

const SelectLabel = ({ ticket, setTicket }) => {
  async function handleStatusChange(event) {
    try {
      const { data } = await api.put(`/ticket/${ticket._id}`, { label: event.target.value });
      toast.success(`Label changed`);
      setTicket(data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <select
        value={ticket.label}
        onChange={handleStatusChange}
        id="statusSelect"
        className="mt-1 block w-24 py-2 px-3 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
        <option value="">None</option>
        {Object.keys(SUPPORT_LABELS).map((e) => {
          return (
            <option key={e} value={e} label={e}>
              {e}
            </option>
          );
        })}
      </select>
    </div>
  );
};
