import React from "react";
import { RiCake2Line } from "react-icons/ri";
import { Link } from "react-router-dom";

const Birthdays = () => {
  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md">
      <div className="p-3 md:flex-auto">
        <h1 className="flex items-center gap-1 text-base font-semibold leading-6 text-gray-900">
          Incoming Birthdays <RiCake2Line />
        </h1>
        <Link to="/birthdays" className="text-xs text-gray-700 hover:underline">
          View all &gt;
        </Link>
      </div>
    </div>
  );
};

export default Birthdays;
