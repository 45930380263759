import React, { useState, useEffect } from "react";
import { ResponsiveRadar } from "@nivo/radar";

import Loader from "../../../components/loader";
import api from "../../../services/api";

const SubCategoryRadar = ({ userId }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();

  const [categories, setCategories] = useState([]);

  const getAnswers = async () => {
    try {
      setLoading(true);
      const resQuestions = await api.post("/skill_question/search", { limit: 1000 });
      const { data } = await api.post("/skill_answer/search", { userId });

      const categories = data.map((answer) => answer.skillCategory);
      const uniqueCategories = [...new Set(categories)];
      setCategories(uniqueCategories);

      // for each answers, get the lowest score for each answer.skillCategory
      const subCategories = data.map((answer) => answer.skillSubCategory);
      const uniqueSubCategories = [...new Set(subCategories)];

      const scores = uniqueSubCategories.map((subcategory) => {
        const answers = data.filter((answer) => answer.skillSubCategory === subcategory);
        let levelValidated = 0;
        for (let i = 1; i <= 5; i++) {
          // if there is no question for this level, we consider the level validated
          const questionsForThisLevel = resQuestions.data.filter((question) => question.skillSubCategory === subcategory && question.level === i);
          if (!questionsForThisLevel.length) {
            levelValidated = i;
            continue;
          }

          // get all answers for this category and this level
          const answersForThisLevel = answers.filter((answer) => answer.levelValue === i);
          // if there is at least one falsy answer for this level, we consider the level not validated and we stop the loop
          if (!answersForThisLevel.length || answersForThisLevel.find((answer) => !parseInt(answer.value))) {
            break;
          }
          levelValidated = i;
        }
        return { category: answers[0].skillCategory, skill: subcategory, score: levelValidated };
      });
      setData(scores);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnswers();
  }, [userId]);

  if (loading || !data) return <Loader />;

  return (
    <div className="h-[200px] flex justify-center ">
      {categories.map((category) => {
        const _data = data.filter((e) => e.category === category);
        return (
          <div key={`${category}-${userId}`} className="flex-1 h-full border border-neutral-200 flex flex-col items-center">
            <span className="font-bold text-sm text-sky-600 text-center my-2">{category}</span>
            <ResponsiveRadar
              data={_data}
              keys={["score"]}
              indexBy="skill"
              valueFormat=">-.2f"
              margin={{ top: 30, right: 10, bottom: 30, left: 10 }}
              borderColor={{ from: "color" }}
              gridLabelOffset={20}
              dotSize={5}
              dotColor={{ theme: "background" }}
              dotBorderWidth={2}
              colors={{ scheme: "nivo" }}
              blendMode="multiply"
              motionConfig="wobbly"
              maxValue={5}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SubCategoryRadar;
