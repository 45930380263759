import React, { useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";

import People from "./people";
import Questions from "./questions";
import Answers from "./answers";
import List from "./list";
import Edit from "./edit";
import KeyWords from "./keyWords";
import Stats from "./stats";
import StatsOverview from "./statsOverview";

import EditQuestionModal from "./components/EditQuestionModal";
import { useSelector } from "react-redux";

const Skills = () => {
  const [questionModalId, setQuestionModalId] = useState();
  const { user } = useSelector((state) => state.Auth);
  return (
    <div>
      <div className="m-2">
        <div className="overflow-x-scroll">
          <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
            <TabItem href={"/skills/answers"} title="Answers" />
            <TabItem href={"/skills/list"} title="List" />
            <TabItem href={"/skills/people"} title="People" />
            <TabItem href={"/skills/questions"} title="Questions" />
            {user?.role === "admin" && <TabItem href={"/skills/stats"} title="Stats" />}
            {user?.role === "admin" && <TabItem href={"/skills/stats-overview"} title="Stats overview" />}
            <TabItem href={"/skills/keyWords"} title="Keywords" />
          </nav>
        </div>
        <Routes>
          <Route path="/list" element={<List />} />
          <Route path="/people" element={<People />} />
          <Route path="/questions" element={<Questions setQuestionModalId={setQuestionModalId} />} />
          <Route path="/answers?" index element={<Answers setQuestionModalId={setQuestionModalId} />} />
          <Route path="/keyWords" element={<KeyWords />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/stats-overview" element={<StatsOverview />} />
          <Route path="/:id" element={<Edit />} />
        </Routes>
      </div>
      <EditQuestionModal questionModalId={questionModalId} setQuestionModalId={setQuestionModalId} />
    </div>
  );
};

const TabItem = ({ href, title }) => {
  return (
    <NavLink to={href} className={({ isActive }) => `${isActive ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
      <div className="flex items-center gap-2 truncate">{title}</div>
    </NavLink>
  );
};

export default Skills;
