import React, { useEffect, useState } from "react";
import moment from "moment";
import { HiOutlineCalendarDays, HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import api from "../../../services/api";

const Stats = () => {
  const user = useSelector((state) => state.Auth.user);
  const [totalByProject, setTotalByProject] = useState({});
  const [dateFrom, setDateFrom] = useState();
  const [theoretical, setTheoretical] = useState();

  useEffect(() => {
    getDays();
  }, []);

  async function getDays() {
    let _dateFrom = moment().startOf("year").toDate();
    if (user.starting_date) {
      _dateFrom = moment(new Date(user.starting_date)).set("year", new Date().getFullYear()).toDate();
      if (_dateFrom > new Date()) {
        _dateFrom = moment(_dateFrom).subtract(1, "year").toDate();
      }
    }
    setDateFrom(_dateFrom);
    const dateTo = moment(_dateFrom).add(1, "year").subtract(1, "day").toDate();
    const diff = moment().diff(moment(_dateFrom), "months");
    setTheoretical(diff * 2.5);

    const { data } = await api.get(`/activity?userId=${user._id}&projectName=${encodeURIComponent("Leave paid")},RTT&dateFrom=${_dateFrom.getTime()}&dateTo=${dateTo.getTime()}`);

    const _totalByProject = data.reduce((acc, curr) => {
      const _project = curr.projectName;
      if (!acc[_project]) {
        acc[_project] = 0;
      }
      acc[_project] += curr.total;
      return acc;
    }, {});

    setTotalByProject(_totalByProject);
  }

  if (user.contract === "FREELANCE") return null;

  return (
    <div className="bg-white flex flex-col justify-between gap-4 p-3 w-full border-[1px] border-gray-200 rounded-md">
      <div>
        <h3 className="flex items-center gap-1 text-base text-gray-600 mb-1 font-normal">
          My days off since{" "}
          <span className="inline-flex items-center gap-1 rounded-full bg-purple-100 px-3 py-0.5 text-sm font-medium text-purple-800">
            <HiOutlineCalendarDays />
            {moment(dateFrom).format("DD MMM YYYY")}
          </span>
        </h3>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-2">
        <div>
          <h2 className="text-3xl text-gray-800 font-medium m-0">{Object.keys(totalByProject).reduce((previous, curr) => previous + (totalByProject[curr] || 0), 0) / 8}</h2>
          <p className="flex items-center gap-1 text-sm text-gray-400 cursor-help" data-tip data-for="theoretical-days-off">
            {theoretical} days accumulated <HiOutlineQuestionMarkCircle />
          </p>
          <ReactTooltip id="theoretical-days-off">
            <span>
              {moment().diff(moment(dateFrom), "months")} complete month(s) * 2.5 = {theoretical} days
            </span>
          </ReactTooltip>
        </div>
        <div className="flex flex-col justify-center gap-2">
          {Object.entries(totalByProject).map(([project, total]) => (
            <div className="flex gap-1 items-center" key={project}>
              <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800">{project}</span>
              <span className="text-sm text-gray-500">
                {total / 8} day{total > 1 ? "s" : ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stats;
