import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../../services/api";
import DebounceInput from "../../../components/DebounceInput";

const Send = ({ question }) => {
  const [users, setUsers] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [filters, setFilters] = useState({ contracts: "", division: "", position: "", status: "active" });

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data } = await api.post("/answer/search", { question_id: question._id });
      setAnswers(data);
    })();
  }, []);

  useEffect(() => {
    load();
  }, [filters]);

  const load = async () => {
    const { data } = await api.post("/user/search", filters);
    setUsers(data.users);
  };

  const handleCheckboxChange = (id) => {
    let arr = [...selectedUsers];
    if (selectedUsers.includes(id)) {
      arr = selectedUsers.filter((rowId) => rowId !== id);
    } else {
      arr.push(id);
    }
    setSelectedUsers(arr);
    setSelectAll(arr.length === users.length);
  };

  const handleSelectAllChange = () => {
    setSelectAll((state) => !state);
    if (selectAll) return setSelectedUsers([]);
    setSelectedUsers(users.filter((u) => !answers.map((a) => a.user_id).includes(u._id)).map((row) => row._id));
  };

  const onSend = async () => {
    const arr = users.filter((u) => selectedUsers.includes(u._id));

    const obj = {};
    obj.question_id = question._id;
    obj.question_title = question.title;

    for (const user of arr) {
      obj.user_id = user._id;
      obj.user_name = user.name;
      obj.user_avatar = user.avatar;

      await api.post("/answer", obj);
    }

    toast.success("question sent !");
    setSelectedUsers([]);
  };

  return (
    <div className="mt-2 flow-root">
      <div className="grid grid-cols-4 gap-2">
        <div className="flex-1 space-y-1">
          <label htmlFor="search" className="text-sm font-semibold">
            Search
          </label>
          <DebounceInput debounce={300} value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} className="input" placeholder="Search" />
        </div>
        <div className="w-full space-y-2">
          <label htmlFor="search" className="text-sm font-semibold">
            Contract
          </label>
          <select className="input block w-full" value={filters.contracts} onChange={(e) => setFilters({ ...filters, contracts: e.target.value })}>
            <option value="">All</option>
            {["CDD", "CDI", "VIE", "INTERNSHIP", "APPRENTICESHIP", "FREELANCE"].map((c) => (
              <option value={c}>{c}</option>
            ))}
          </select>
        </div>
        <div className="w-full space-y-2">
          <label htmlFor="search" className="text-sm font-semibold">
            Division
          </label>
          <select className="input block w-full" value={filters.division} onChange={(e) => setFilters({ ...filters, division: e.target.value })}>
            <option value="">All</option>
            {["LE_COLLECTIF", "SELEGO_FR", "SELEGO_NL", "SELESPAGNE"].map((c) => (
              <option value={c}>{c}</option>
            ))}
          </select>
        </div>
        <div className="w-full space-y-2">
          <label htmlFor="search" className="text-sm font-semibold">
            Position
          </label>
          <select className="input block w-full" value={filters.position} onChange={(e) => setFilters({ ...filters, position: e.target.value })}>
            <option value="">All</option>
            {["FULLSTACK", "CTO", "DATA", "DEVOPS", "PRODUCT", "SALES", "OTHER"].map((c) => (
              <option value={c}>{c}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex items-center justify-between py-2">
        {selectedUsers?.length > 0 ? <div className="text-lg font-bold">{selectedUsers?.length} users selected</div> : <div className="text-lg font-bold">Select users</div>}
        <button className="btn btn-primary mt-4" onClick={onSend}>
          Send
        </button>
      </div>

      <div className="overflow-x-auto">
        <div className="bg-white inline-block min-w-full align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="text-left text-sm font-semibold text-gray-900">
              <tr>
                <th className="py-2 px-3">
                  <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out" />
                </th>
                <th className="py-2 px-3">Name</th>
                <th className="py-2 px-3">Job Title</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {users.map((user) => (
                <tr
                  onClick={() => {
                    if (answers.map((a) => a.user_id).includes(user._id)) return;
                    handleCheckboxChange(user._id);
                  }}
                  key={user.email}
                  className="even:bg-gray-100 whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 cursor-pointer">
                  <td className="py-1 px-3">
                    <input
                      type="checkbox"
                      disabled={answers.map((a) => a.user_id).includes(user._id)}
                      checked={selectedUsers.includes(user._id)}
                      className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out disabled:opacity-50 "
                    />
                  </td>
                  <td
                    className="cursor-pointer group py-1 px-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      query.set("user_modal_id", user._id);
                      navigate({ search: query.toString() });
                    }}>
                    <div className="flex items-center gap-1">
                      <img src={user.avatar} alt="userlogo" className="rounded-full w-7 h-7" />
                      <div>
                        <div className="group-hover:underline">{user.name}</div>
                        <div className="flex items-center gap-1 font-normal text-xs text-gray-700">{user.email}</div>
                      </div>
                    </div>
                  </td>
                  <td className="py-1 px-3">{user.job_title || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Send;
