import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import moment from "moment";

import Comments from "./Comments";
import DebounceInput from "../../../../../components/DebounceInput";
import MultiSelect from "../../../../../components/MultiSelect";
import SelectUser from "../../../../../components/selectUser";
import api from "../../../../../services/api";

import Gerard from "./gerard";
import SingleSelect from "../../../../../components/SingleSelect";

export default ({ epic, setEpic }) => {
  const user = useSelector((state) => state.Auth.user);
  // const [tickets, setTickets] = useState([]);

  // const fetchTickets = async () => {
  //   try {
  //     const { data } = await api.post(`/notion/search`, { epic_id: epic._id, project_id: epic.project_id });
  //     setTickets(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTickets();
  // }, [epic]);

  return (
    <>
      <div className="flex mx-2 gap-2">
        <div className="w-1/6 pt-2">
          <DebounceInput
            debounceTimeout={600}
            className="input h-24"
            isTextArea={true}
            placeholder="Business Objective"
            type="text"
            id="business_objective"
            value={epic.business_objective || ""}
            onChange={async (e) => {
              const { data } = await api.put(`/epic/${epic._id}`, { business_objective: e.target.value });
              setEpic(data);
              toast.success("Business Objective updated!");
            }}
          />
          <div className="text-xs font-semibold mb-1">Personae</div>
          <div className="mb-2">
            <DebounceInput
              className="input"
              value={epic.persona}
              onChange={async (e) => {
                const { data } = await api.put(`/epic/${epic._id}`, { persona: e.target.value });
                setEpic(data);
                toast.success("Persona updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Assigned</div>
          <SelectAssigned
            value={epic.assigned}
            onChange={async (e) => {
              const { data } = await api.put(`/epic/${epic._id}`, { assigned: e });
              setEpic(data);
              toast.success("Assigned updated!");
            }}
            project={epic.project_id}
          />
          <div className="text-xs font-semibold mb-1">Module</div>
          <SelectModule
            value={epic.module}
            onChange={async (e) => {
              const { data } = await api.put(`/epic/${epic._id}`, { module: e });
              setEpic(data);
              toast.success("Module updated!");
            }}
            projectId={epic.project_id}
          />
          <div className="text-xs font-semibold mb-1">Priority</div>
          <div className="mb-2">
            <select
              className="input"
              value={epic.priority}
              onChange={async (e) => {
                const { data } = await api.put(`/epic/${epic._id}`, { priority: e.target.value });
                setEpic(data);
                toast.success("Priority updated!");
              }}>
              <option value="NOW">Now</option>
              <option value="NEXT">Next</option>
              <option value="LATER">Later</option>
            </select>
          </div>
          <div className="text-xs font-semibold mb-1">Tags</div>

          <SelectTags
            value={epic.tags}
            onChange={async (e) => {
              const { data } = await api.put(`/epic/${epic._id}`, { tags: e });
              setEpic(data);
              toast.success("Tags updated!");
            }}
            projectId={epic.project_id}
          />
          <SelectUser
            value={{ _id: epic.user_id, name: epic.user_name, avatar: epic.user_avatar }}
            onChange={async (e) => {
              const { data } = await api.put(`/epic/${epic._id}`, { user_id: e._id, user_name: e.name, user_avatar: e.avatar });
              setEpic(data);
              toast.success("User updated!");
            }}
          />
          <div className="text-xs font-semibold mb-1">Days Required</div>
          <div className="mb-2">
            <EstimationDays
              value={epic.days_required}
              onChange={async (e) => {
                const tjm = epic.tjm || 500;
                const estimation = e * tjm;
                const { data } = await api.put(`/epic/${epic._id}`, { days_required: e, estimation });
                setEpic(data);
                toast.success("Estimation updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Resource Profile</div>
          <div className="mb-2">
            <select
              className="input"
              value={epic.resource_profile}
              onChange={async (e) => {
                let tjm = 500;
                if (e.target.value === "CTO") {
                  tjm = 650;
                } else if (e.target.value === "EXPERIMENTED DEVELOPER") {
                  tjm = 500;
                } else if (e.target.value === "DEVELOPER") {
                  tjm = 350;
                } else if (e.target.value === "PRODUCT") {
                  tjm = 500;
                }
                const estimation = epic.days_required * tjm;
                const { data } = await api.put(`/epic/${epic._id}`, { resource_profile: e.target.value, tjm, estimation });
                setEpic(data);
                toast.success("Resource profile updated!");
              }}>
              <option value="DEVELOPER">Developer</option>
              <option value="EXPERIMENTED DEVELOPER">Experimented Developer</option>
              <option value="CTO">CTO</option>
              <option value="PRODUCT">Product</option>
            </select>
          </div>

          <div className="text-xs font-semibold mb-1">Daily Rate (€)</div>
          <div className="mb-2">
            <input
              type="number"
              className="input"
              value={epic.tjm}
              onChange={async (e) => {
                const estimation = epic.days_required * e.target.value;
                const { data } = await api.put(`/epic/${epic._id}`, { tjm: e.target.value, estimation });
                setEpic(data);
                toast.success("Daily rate updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Estimation (€) </div>
          <div className="mb-2">
            <EstimationPrice
              value={epic.estimation}
              onChange={async (e) => {
                const { data } = await api.put(`/epic/${epic._id}`, { estimation: e });
                setEpic(data);
                toast.success("Estimation updated!");
              }}
            />
          </div>

          <div className="text-xs font-semibold mb-1">Charge </div>
          <div className="mb-2">
            <select
              className="input"
              value={epic.charge}
              onChange={async (e) => {
                const { data } = await api.put(`/epic/${epic._id}`, { charge: e.target.value });
                setEpic(data);
                toast.success("Estimation updated!");
              }}>
              <option value="XS">XS</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
            </select>
          </div>

          <div className="text-xs font-semibold mb-1">Business Contribution </div>
          <div className="mb-2">
            <select
              className="input"
              value={epic.business_contribution}
              onChange={async (e) => {
                const { data } = await api.put(`/epic/${epic._id}`, { business_contribution: e.target.value });
                setEpic(data);
                toast.success("Estimation updated!");
              }}>
              <option value="XS">XS</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
            </select>
          </div>
        </div>
        <div className="w-3/6">
          <div className="flex-1">
            <Comments
              value={epic.comments}
              onAdd={async (e) => {
                console.log("Adding comment", e);
                const comments = [...epic.comments, { text: e, user_name: user.name, user_id: user._id, user_avatar: user.avatar }];
                const { data } = await api.put(`/epic/${epic._id}`, { comments });
                setEpic(data);
                toast.success("Comment added!");
              }}
              onDelete={async (id) => {
                const comments = epic.comments.filter((c) => c._id !== id);
                const { data } = await api.put(`/epic/${epic._id}`, { comments });
                setEpic(data);
                toast.success("Comment deleted!");
              }}
            />
            <div className="mb-2">
              <span className="flex items-center text-xs text-gray-600 italic">
                Created by&nbsp;{epic.user_name} on {moment(epic.created_at).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="w-2/6">
          <Gerard epic={epic} setEpic={setEpic} />
        </div>
      </div>
    </>
  );
};

const EstimationPrice = ({ value, onChange }) => {
  const [v, setV] = useState(value);

  useEffect(() => {
    setV(value);
  }, [value]);

  return <input className="input" disabled type="number" value={v} onChange={(e) => setV(e.target.value)} onBlur={() => onChange(v)} />;
};

const EstimationDays = ({ value, onChange }) => {
  const [v, setV] = useState(value);

  useEffect(() => {
    setV(value);
  }, [value]);

  return <input className="input" type="number" value={v} onChange={(e) => setV(e.target.value)} onBlur={() => onChange(v)} />;
};

const SelectTags = ({ value, onChange, projectId }) => {
  const [tags, setTags] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/epic/aggregate", { project_id: projectId, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-col gap-2">
      <MultiSelect
        values={tags.map((tag) => ({ value: tag, label: tag }))}
        id="select-tags"
        options={options}
        onSelectedChange={(e) => {
          setTags(e.map((e) => e.value));
          onChange(e.map((e) => e.value));
        }}
        placeholder="Tags"
        onCreateNew={(e) => {
          setTags([...tags, e.value]);
          get();
        }}
      />

      <div className="flex flex-wrap gap-1">
        {tags.map((tag) => (
          <span
            key={tag}
            className="text-xs w-fit  text-gray-600 bg-gray-100 px-2 py-1 rounded border border-gray-200 hover:bg-gray-200 cursor-pointer"
            onClick={() => {
              const newTags = tags.filter((t) => t !== tag);
              setTags(newTags);
              onChange(newTags);
            }}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

const SelectAssigned = ({ value, onChange, projectId }) => {
  const [assigned, setAssigned] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/epic/aggregate", { project_id: projectId, key: "assigned" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-col gap-2">
      <SingleSelect
        value={assigned ? { value: assigned, label: assigned } : null}
        id="select-assigned"
        options={options}
        onChange={(option) => {
          setAssigned(option.value);
          onChange(option.value);
        }}
        placeholder="Select a assigned"
        onCreateNew={(e) => {
          setAssigned(e.value);
          get();
        }}
      />
    </div>
  );
};

const SelectModule = ({ value, onChange, projectId }) => {
  const [module, setModule] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/epic/aggregate", { project_id: projectId, key: "module" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-col gap-2">
      <SingleSelect
        value={module ? { value: module, label: module } : null}
        id="select-module"
        options={options}
        onChange={(option) => {
          setModule(option.value);
          onChange(option.value);
        }}
        placeholder="Select a module"
        onCreateNew={(e) => {
          setModule(e.value);
          get();
        }}
      />
    </div>
  );
};
