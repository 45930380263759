import React from "react";
import { PiStarFill, PiStarLight, PiStarHalfFill } from "react-icons/pi";
import { classNames } from "../../utils";

export default function Rating({ value }) {
  return (
    <div className="flex items-center">
      {[1, 2, 3, 4, 5].map((score) => {
        const isShining = score <= value;
        const isFirstHalf = value < score && value > score - 1;
        return (
          <div key={score} className={classNames("px-0.5 text-base", isShining || isFirstHalf ? "text-yellow-400" : "text-gray-300")}>
            {isShining ? <PiStarFill /> : isFirstHalf ? <PiStarHalfFill /> : <PiStarLight />}
          </div>
        );
      })}
    </div>
  );
}
