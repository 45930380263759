import React, { useEffect, useState } from "react";
import api from "../../../services/api";

import { IoInformationCircleSharp } from "react-icons/io5";
import moment from "moment/moment";
import ReactTooltip from "react-tooltip";
const months = ["Jan", "Fev", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const years = ["2021", "2022", "2023", "2024", "2025"];

export default () => {
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [taxSystem, setTaxSystem] = useState("WBSO");
  const [nbContract, setContract] = useState(0);

  useEffect(() => {
    get();
  }, [selectedYear, taxSystem]);

  async function get() {
    const queryUsers = {};
    queryUsers.division = taxSystem === "WBSO" ? "SELEGO_NL" : "LE_COLLECTIF";
    queryUsers.wbso_eligible = taxSystem === "WBSO";
    queryUsers.cir_eligible = taxSystem === "CIR";
    const query = {};
    query.dateFrom = new Date(Number(selectedYear), 0, 1);
    query.dateTo = new Date(Number(selectedYear), 11, 31);
    query.wbso_eligible = taxSystem === "WBSO";
    query.cir_eligible = taxSystem === "CIR";
    const response = await api.post(`/user/search`, queryUsers);
    const { data: c } = response;
    const eligibleUsers = c.users.map((user) => user._id);
    setContract(c.total);
    const { data: r } = await api.post(`/activity/search`, query);
    const arr = [];
    const lastLine = { projectName: "Total", total: 0, months: Array(12).fill(0), peoples: [] };
    for (let i = 0; i < r.length; i++) {
      const projectName = r[i].projectName;
      const projectId = r[i].projectId;

      let findIndex = arr.findIndex((e) => e.projectName === projectName);
      if (findIndex === -1) {
        arr.push({ projectName, projectId, total: 0, months: Array(12).fill(0), peoples: [] });
      }
      if (!eligibleUsers.includes(r[i].userId)) continue;

      const month = new Date(r[i].date).getMonth();
      const total = r[i].total;
      const userName = r[i].userName;
      const userId = r[i].userId;

      findIndex = arr.findIndex((e) => e.projectName === projectName);
      arr[findIndex].total += total;
      arr[findIndex].months[month] += total;
      lastLine.total += total;
      lastLine.months[month] += total;

      let findUserIndex = arr[findIndex].peoples.findIndex((e) => e.userId === userId);
      if (findUserIndex === -1) {
        arr[findIndex].peoples.push({ userName, userId, total, months: Array(12).fill(0) });
      }
      findUserIndex = arr[findIndex].peoples.findIndex((e) => e.userId === userId);
      arr[findIndex].peoples[findUserIndex].total += total;
      arr[findIndex].peoples[findUserIndex].months[month] += total;

      let findTotalUserIndex = lastLine.peoples.findIndex((e) => e.userId === userId);
      if (findTotalUserIndex === -1) {
        lastLine.peoples.push({ userName, userId, total, months: Array(12).fill(0) });
      }
      findTotalUserIndex = lastLine.peoples.findIndex((e) => e.userId === userId);
      lastLine.peoples[findTotalUserIndex].total += total;
      lastLine.peoples[findTotalUserIndex].months[month] += total;
    }
    arr.push(lastLine);
    setData(arr);
    console.log(arr);
  }

  return (
    <div className="p-3 flex flex-col w-full h-full">
      <div className="p-5 bg-white border-[1px] border-gray-200 rounded-md overflow-hidden w-screen md:w-full">
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <IoInformationCircleSharp className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">{`Warning, total/month can not exceeds ${8 * 22 * nbContract} hour -> ${22 * nbContract} days`}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <select
            className="mt-2 flex items-center justify-between border border-gray-300 rounded hover:border-sky-700 overflow-hidden"
            value={taxSystem}
            id={"taxSystem"}
            onChange={(e) => setTaxSystem(e.target.value)}>
            <option key={"WBSO"} value={"WBSO"}>
              WBSO
            </option>
            <option key={"CIR"} value={"CIR"}>
              CIR
            </option>
          </select>
          <select
            className="mt-2 flex items-center justify-between border border-gray-300 rounded hover:border-sky-700 overflow-hidden"
            id={"year"}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-wrap py-3 gap-4 text-black">
          <div className="w-screen md:w-full p-2 md:!px-8">
            <div className="mt-2 rounded-xl bg-[#fff] overflow-auto">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="p-[10px] text-[14px] font-bold text-[#212325] text-left min-w-[150px] bg-white sticky left-0">Project</th>
                      <th className="p-[10px] text-[14px] font-bold text-[#212325] text-left min-w-[100px] bg-white sticky left-[150px]">Total</th>
                      {months.map((month) => (
                        <th className={`p-[10px] border border-[#E5EAEF] text-[12px] font-semibold text-left bg-[white] min-w-[100px]`} key={month}>
                          {month}{" "}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((line) => {
                      return (
                        <tr key={line.projectName} className="border-t border-b border-r border-[#E5EAEF]">
                          <th className="p-[10px] text-[14px] font-bold text-[#212325] text-left min-w-[150px] bg-white sticky ">{line.projectName}</th>
                          <td className="p-[10px] text-[14px] font-semibold text-[#212325] text-left min-w-[150px] bg-white " data-tip data-for={line.projectName + "tooltip"}>
                            <div>{Math.round((line.total / 8) * 8) / 4} days</div>
                            <div>{line.total * 11} €</div>
                            <ReactTooltip id={line.projectName + "tooltip"} style={{ zIndex: 999 }}>
                              <span className="z-50">
                                {line.peoples?.map((people) => (
                                  <div>{people.total ? `${people.userName}:${Math.round((people.total / 8) * 4) / 4} days` : ""}</div>
                                ))}
                              </span>
                            </ReactTooltip>
                          </td>
                          {line.months.map((month, index) => (
                            <>
                              <td
                                key={line.projectName + index}
                                className="p-[10px] text-[14px] text-[#212325] text-left min-w-[150px]"
                                data-tip
                                data-for={line.projectName + index + "tooltip"}>
                                <div>{month ? Math.round((month / 8) * 4) / 4 + " days" : "-"}</div>
                                <div>{month ? month * 11 + " €" : ""}</div>
                                <ReactTooltip id={line.projectName + index + "tooltip"}>
                                  <span>
                                    {line.peoples?.map((people) => (
                                      <div>{people.months[index] ? `${people.userName}:${Math.round((people.months[index] / 8) * 4) / 4} days` : ""}</div>
                                    ))}
                                  </span>
                                </ReactTooltip>
                              </td>
                            </>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
