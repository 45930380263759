import React, { useState } from "react";
import toast from "react-hot-toast";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import CvIcon from "../../assets/cv.svg";
import GithubIcon from "../../assets/github.svg";
import LinkedinIcon from "../../assets/linkedin.svg";
import EntryLevel from "../../assets/entryLevel.svg";

const UserCard = ({ applicant }) => {
  const [showFullBio, setShowFullBio] = useState(false);

  return (
    <div className="w-full h-full" onClick={() => toast.success("Please contact Sebastien !")}>
      <div className="flex h-full flex-col rounded-xl overflow-hidden bg-white p-5 space-y-4 hover:shadow-lg transition-shadow duration-300 cursor-pointer shadow-search">
        <div className="flex space-x-4">
          <div className="w-24 h-24 rounded-xl overflow-hidden shadow">
            <img alt="profile" src={applicant.avatar} className="w-full h-full object-contain" />
          </div>
          <div className="flex flex-col flex-grow">
            <h2 className="text-xl font-semibold">{applicant.name}</h2>
            {applicant.job_title && <p className="text-sm text-gray-600">{applicant.job_title}</p>}
            <p className="text-xs text-gray-600">{new Date(applicant.created_at).toLocaleString()}</p>
            {applicant.country && <p className="text-xs text-gray-600">{applicant.country}</p>}
            <div className="flex space-x-2 items-center" onClick={(e) => e.stopPropagation()}>
              {applicant.linkedin && (
                <a href={applicant.linkedin} target="_blank" rel="noreferrer">
                  <img alt="linkedin" src={LinkedinIcon} className="w-6 h-6" />
                </a>
              )}
              {applicant.github && (
                <a href={applicant.github} target="_blank" rel="noreferrer">
                  <img alt="github" src={GithubIcon} className="w-6 h-6 rounded-full" />
                </a>
              )}
              {applicant.cv && (
                <a href="">
                  <img alt="cv" src={CvIcon} className="w-6 h-6 border-2 border-gray-300 rounded p-1" />
                </a>
              )}
            </div>
          </div>
        </div>
        <Experience applicant={applicant} />
        <Education applicant={applicant} />
        {applicant.bio?.length > 200 ? (
          <button
            className="text-left"
            onClick={(e) => {
              e.stopPropagation();
              setShowFullBio((e) => !e);
            }}>
            <p className="text-gray-500 text-sm">{showFullBio ? applicant.bio : `${applicant.bio.substring(0, 200)}...`}</p>
            {showFullBio ? (
              <div className="flex w-full gap-1 justify-center items-center py-2 px-3 text-sm text-gray-700 md:pl-3 pl-0 cursor-pointer hover:underline">
                See less
                <IoIosArrowUp />
              </div>
            ) : (
              <div className="flex w-full gap-1 justify-center items-center py-2 px-3 text-sm text-gray-700 md:pl-3 pl-0 cursor-pointer hover:underline">
                See more
                <IoIosArrowDown />
              </div>
            )}
          </button>
        ) : (
          <p className="text-gray-500 text-sm">{applicant.bio}</p>
        )}
        <div className="flex-1" />
        <div className="flex space-x-2 items-center justify-between">
          <div className="flex flex-wrap gap-2">
            {applicant.contracts.map((contract, i) => (
              <span key={i} className="bg-gray-200 text-gray-500 rounded text-sm px-2 py-1">
                {contract}
              </span>
            ))}
          </div>

          <div className="flex items-center space-x-2">
            <img alt="level" src={EntryLevel} className="w-5 h-5" />
            <p className="text-xs text-gray-500 capitalize whitespace-nowrap">{applicant.experience || <span className="text-gray-400 text-xs">Empty</span>}</p>
          </div>
          {/* <div className="flex items-center space-x-2">
            <img alt="achievement" src={TutoAchievements} className="w-5 h-5" />
            <p className="text-sm text-blue-700">0 tutos achieved</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserCard;

const Experience = ({ applicant }) => {
  if (!applicant?.linkedin_raw || !applicant.linkedin_raw?.experience?.length) return <></>;

  const data = applicant?.linkedin_raw?.experience[0];

  return (
    <div className="text-sm mb-2">
      <h3 className="text-base font-semibold">Experience :</h3>
      <div className="flex items-center justify-between">
        <span className="text-dull-black">{data?.title || ""}</span>
        <span className="text-dull-grey whitespace-nowrap">{data?.duration || ""}</span>
      </div>
      <div className="text-dull-grey">
        {data?.company || ""} · {data?.type || ""}
      </div>
      <div className="text-dull-grey">{data?.location || ""}</div>
    </div>
  );
};

const Education = ({ applicant }) => {
  if (!applicant?.linkedin_raw || !applicant.linkedin_raw?.education?.length) return <></>;

  const data = applicant?.linkedin_raw?.education[0];

  return (
    <div className="text-sm mb-2">
      <h3 className="text-base font-semibold">Education :</h3>
      <div className="flex items-center justify-between">
        <span className="text-dull-black">{data?.degree || ""}</span>
        <span className="text-dull-grey whitespace-nowrap">{data?.duration || ""}</span>
      </div>
      <div className="text-dull-grey">{data?.school || ""}</div>
    </div>
  );
};

const Empty = () => <span className="px-3 py-1 bg-gray-50 text-gray-400 rounded-lg border-[1px] border-gray-100 text-xs">Empty</span>;
