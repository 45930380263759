import React, { useEffect, useState } from "react";
import SelectUser from "../../components/selectUser";
import SelectInvoice from "../../components/SelectInvoice";
import toast from "react-hot-toast";

import api from "../../services/api";

const MakeTransaction = ({ amount = 0, description = "" }) => {
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({ userName: "", userId: "", amount, description });

  useEffect(() => {
    if (!open) return;
    const obj = {};
    if (amount) obj.amount = amount;
    if (description) obj.description = description;
    setValues(obj);
  }, [open]);

  function isValid() {
    if (!values.userName) return false;
    if (!values.userId) return false;
    if (!values.amount) return false;
    if (!values.description) return false;
    return true;
  }

  const makeTransaction = async () => {
    if (!isValid()) {
      toast.error("Please fill all fields to save the transaction");
      return;
    }
    const { data } = await api.post("/wallet_transaction", values);
    setOpen(false);
    toast.success(`OK`);
    return;
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <div className="text-right">
        <button
          className="bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-3 px-5 h-12 rounded-lg text-lg font-medium transform hover:scale-105 transition duration-300"
          onClick={() => {
            setOpen(true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}>
          Make Transaction
        </button>
      </div>

      {open ? (
        <div className="absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div
            className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <React.Fragment>
              <div>
                <div className="w-1/2 flex flex-row items-center">
                  <SelectUser
                    label="User"
                    value={values.walletUserId}
                    userName={values.userName}
                    onChange={(e) => setValues({ ...values, userName: e.name, userId: e._id, userAvatar: e.avatar })}
                  />
                </div>
                <div className="w-1/2 flex flex-row items-center">
                  <SelectInvoice
                    invoiceId={values.invoiceId}
                    invoiceName={values.invoiceName} //
                    onChange={async (e) => {
                      if (!e) {
                        setValues({ ...values, invoiceId: "", invoiceName: "" });
                        return true;
                      }
                      setValues({ ...values, invoiceId: e._id, invoiceName: e.name });
                      return true;
                    }}
                  />
                </div>
                <div className="w-1/2 flex flex-row items-center">
                  <input
                    type="number"
                    placeholder="Amount"
                    className="w-full border border-gray-300 rounded-md p-2"
                    value={values.amount}
                    onChange={(e) => setValues({ ...values, amount: e.target.value })}
                  />
                </div>
                <div className="w-1/2 flex flex-row items-center">
                  <input
                    type="text"
                    placeholder="Description"
                    className="w-full border border-gray-300 rounded-md p-2"
                    value={values.description}
                    onChange={(e) => setValues({ ...values, description: e.target.value })}
                  />
                </div>
              </div>

              <br />
              <button className="mt-[1rem]  bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={makeTransaction}>
                Execute
              </button>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MakeTransaction;
