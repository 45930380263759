import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { format } from "date-fns";
import LoadingButton from "../../../components/loadingButton";
import api from "../../../services/api";
import GitReport from "./git-report";
import Pagination from "../../../components/pagination";
import Loader from "../../../components/loader";
import DebounceInput from "react-debounce-input";
import { HiMagnifyingGlass } from "react-icons/hi2";

export default ({ project, setProject }) => {
  const [commits, setCommits] = useState([]);
  const [isScrapping, setIsScrapping] = useState(false);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ page: 1, per_page: 30 });
  const [scrapPeriod, setScrapPeriod] = useState({ from: null, to: null });

  useEffect(() => {
    fetchCommits();
  }, [filters]);

  const fetchCommits = async () => {
    try {
      setLoading(true);
      const obj = {};
      if (filters.search) obj.search = filters.search;
      if (filters.page) obj.page = filters.page;
      if (filters.per_page) obj.per_page = filters.per_page;
      obj.project_id = project._id;

      const { data, ok } = await api.post(`/git_commits/search`, obj);
      console.log(data)
      if (!ok) return toast.error("Error");
      setLoading(false);
      setCommits(data.commits);
      setTotal(data.total);
    } catch (e) {
      setLoading(e.error);
    }
  };

  async function handleScrap(e) {
    try {
      e.preventDefault();
      setIsScrapping(true);
      let obj = {};
      if (scrapPeriod.from) obj.from = scrapPeriod.from;
      if (scrapPeriod.to) obj.to = scrapPeriod.to;
      const { data, ok } = await api.post(`/project/${project._id}/scrap_commits`, obj);
      setIsScrapping(false);
      setCommits(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error(e.error);
      setIsScrapping(false);
    }
  }


  return (
    <div className="bg-white p-4">
      <div className="flex gap-2 w-full items-between">
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex top-2 pl-3">
            <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <DebounceInput
            debounceTimeout={300}
            name="search"
            id="search"
            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
            placeholder="Search"
            value={filters.search}
            onChange={(e) => {
              e.persist();
              setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
            }}
          />
        </div>
        <div className="flex gap-2 w-full items-end justify-end ml-auto mb-2 align-bottom">
          <div className="w-[140px]">
            <label className="text-[#212325] font-medium " htmlFor="date">Scrap from...</label>
            <input type="date" className="input" value={scrapPeriod.from} onChange={(e) => setScrapPeriod({ ...scrapPeriod, from: e.target.value })} />
          </div>
          <div className="w-[140px]">
            <label className="text-[#212325] font-medium " htmlFor="date">to...</label>
            <input type="date" className="input" value={scrapPeriod.to} onChange={(e) => setScrapPeriod({ ...scrapPeriod, to: e.target.value })} />
          </div>
          <LoadingButton
            className="btn btn-primary "
            loading={isScrapping}
            onClick={(e) => handleScrap(e)}>
            Scrap Commits
          </LoadingButton>

          {commits.length > 0 &&
            <GitReport project={project} setProject={setProject} />
          }
        </div>
      </div>
      {isScrapping && <div className="text-[#0560FD] text-[16px] font-medium">Scrapping in prrocess. This might take a while. Please do not reload the page</div>}

      {loading ? <Loader size="small" /> : null}
      <table className="md:w-full table-auto border border-gray-200">
        <thead className="border-t border-b-2	 border-t-[#dee2e6] border-l-0 border-r-0 border-b-[#dee2e6] bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Commit SHA</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Committer</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Message</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Additions</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Deletion</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Commit Date</th>
          </tr>
        </thead>
        <tbody className="">
          {commits.map((commit) => (
            <tr key={commit.commit_sha} className="hover:bg-gray-100 border border-gray-200">
              <td className="flex justify-between py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0 relative w-full">
                <div className="truncate">
                  {commit.commit_sha.substring(0, 5)}...
                </div>
                <button
                  onClick={() => navigator.clipboard.writeText(commit.commit_sha)}
                  className=" bg-gray-100 hover:bg-white px-1 py-1 rounded-md transition-opacity duration-300"
                >
                  copy
                </button>
              </td>
              <td className="py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0">
                {commit.committer}</td>
              <td className=" py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0">
                {commit.message}</td>
              <td className="py-1 px-3 text-sm font-normal text-green-500 md:pl-3 pl-0">
                {commit.added_code}</td>
              <td className="py-1 px-3 text-sm font-normal text-red-500 md:pl-3 pl-0">
                {commit.removed_code}</td>
              <td className="py-1 px-3 text-sm font-normal text-gray-500 md:pl-3 pl-0">
                {new Date(commit.commit_date).toLocaleDateString('fr', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        per_page={filters.per_page}
        total={total}
        onNext={() => setFilters((f) => ({ ...f, page: f.page + 1 }))}
        onPrevious={() => setFilters((f) => ({ ...f, page: f.page - 1 }))}
        currentPage={filters.page}
      />
    </div >
  );
};
