import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { toast } from "react-hot-toast";
import Combobox from "../../../components/Combobox";

export default ({ meeting, setMeeting }) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await api.post("/project/search", { status: "active", per_page: 1000 });
      if (res.ok) setProjects(res.data.projects);
    })();
  }, []);

  const onSave = async () => {
    const { data } = await api.put(`/meeting/${meeting._id}`, meeting);
    setMeeting(data);
    toast.success("Meeting updated successfully!");
  };

  const onDelete = async () => {
    const { data } = await api.put(`/meeting/${meeting._id}`, { isDeleted: true });
    toast.success("Meeting deleted successfully!");
    navigate("/meetings");
  };

  return (
    <div className="p-6 bg-white text-gray-900 min-h-screen">
      <h2 className="text-2xl font-semibold mb-4">Meeting Details</h2>

      <div className="grid grid-cols-1 gap-4 mt-6">
        <div className="col-span-1">
          <label className="text-sm text-gray-600 font-medium mb-1" htmlFor="name">
            Name
          </label>
          <input
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
            type="text"
            id="name"
            value={meeting.name}
            onChange={(e) => setMeeting({ ...meeting, name: e.target.value })}
          />
        </div>

        <div className="col-span-1">
          <label className="text-sm text-gray-600 font-medium mb-1" htmlFor="description">
            Description
          </label>
          <textarea
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
            rows="4"
            id="description"
            value={meeting.description}
            onChange={(e) => setMeeting({ ...meeting, description: e.target.value })}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Project</label>
          <Combobox
            options={projects}
            value={projects.find((p) => p._id === meeting.project_id)}
            onChange={(e) => setMeeting({ ...meeting, project_id: e._id, project_name: e.name })}
            placeholder="Project"
            getValue={(o) => o._id}
            getLabel={(o) => o.name}
            match={(o, s) => o.name.toLowerCase().includes(s.toLowerCase())}
            width="w-full md:w-80"
          />
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onSave}>
          Save
        </button>

        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};
