import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { FaSync } from "react-icons/fa";
import FeedbackModal from "./feedback";

import api from "../../../../../../services/api";

export default ({ notion, setNotion }) => {
  const [loading, setLoading] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    loadGerard();
  }, [notion]);

  async function loadGerard() {
    if ((notion.suggestions || []).length > 0) return;
    if (loading) return;
    console.log("LOAD GERARD");
    try {
      setLoading(true);
      const { data } = await api.put(`/notion/${notion._id}/gerard`);
      setNotion(data);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!", e.code);
    }
    setLoading(false);
  }

  console.log("notion", notion);

  return (
    <div className="relative w-full max-w-2xl mx-auto bg-white px-4 py-2 border border-gray-200 rounded-md">
      <FeedbackModal isOpen={feedbackOpen} onClose={() => setFeedbackOpen(false)} notion={notion} setNotion={setNotion} />
      <div className="flex items-center gap-3">
        <h1 className="text-lg font-semibold">AI Suggestions</h1>
        {!loading && (
          <div>
            <button className="cursor-pointer text-blue-500 font-semibold text-sm mt-4 mb-4 hover:bg-blue-100 rounded" onClick={loadGerard}>
              <FaSync className={`${loading && "animate-spin"}`} />
            </button>
          </div>
        )}
        <button
          className={`cursor-pointer text-blue-500 font-semibold rounded px-2 py-1 hover:bg-blue-100 ml-2 text-sm `}
          onClick={async () => {
            setFeedbackOpen(true);
          }}>
          Give feedback
        </button>
      </div>

      {loading && <p>Loading...</p>}
      <div>
        {(notion.suggestions || []).map((suggestion, index) => {
          if (suggestion.status === "ACCEPTED") return null;
          if (suggestion.status === "REJECTED") return null;
          return (
            <div key={index} className="mt-4">
              <SuggestionSentence suggestion={suggestion} />
              <p className="text-gray-600 text-xs">{suggestion.why}</p>
              <div className="flex justify-between">
                <div></div>
                <div>
                  <button
                    className={`cursor-pointer text-green-500 font-semibold rounded px-2 py-1 hover:green-blue-100 text-sm`}
                    onClick={async () => {
                      const suggestions = [...notion.suggestions];
                      suggestions[index].status = "ACCEPTED";
                      const { data } = await api.put(`/notion/${notion._id}`, { [suggestion.field]: suggestion.to, suggestions });
                      setNotion(data);
                      toast.success("Suggestion accepted!");
                    }}>
                    Accept
                  </button>
                  <button
                    className={`cursor-pointer text-red-500 font-semibold rounded px-2 py-1 hover:bg-red-100 ml-2 text-sm `}
                    onClick={async () => {
                      const suggestions = [...notion.suggestions];
                      suggestions[index].status = "REJECTED";
                      const { data } = await api.put(`/notion/${notion._id}`, { suggestions });
                      setNotion(data);
                      toast.success("Suggestion rejected!");
                    }}>
                    Reject
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        {(notion.suggestions || []).some((e) => e.status === "PENDING") && (
          <button className="cursor-pointer text-green-500 font-semibold text-sm mt-4 px-2 py-1 hover:bg-green-100 rounded" onClick={() => toast.success("TODO")}>
            Accept All Suggestions
          </button>
        )}
      </div>
    </div>
  );
};

const SuggestionSentence = ({ suggestion }) => {
  if (!suggestion.from) {
    return (
      <h4 className="text-sm">
        Set {suggestion.field} to <strong>{suggestion.to}</strong>
      </h4>
    );
  }
  return (
    <h4 className="text-sm">
      Change {suggestion.field} from <strong>{suggestion.from || "N/A"}</strong> to <strong>{suggestion.to}</strong>
    </h4>
  );
};
