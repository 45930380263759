import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { FaChevronUp, FaChevronDown, FaTrash, FaStar } from "react-icons/fa";

import api from "../../../../services/api";
import FileInput from "../../../../components/FileInput";

export default ({ page, onPagesChange, pages }) => {
  const [values, setValues] = useState({
    format: page?.format,
    name: page?.name,
    background_image_url: page?.background_image_url,
  });

  useEffect(() => {
    setValues({ format: page?.format, name: page?.name, background_image_url: page?.background_image_url });
  }, [page]);

  const handlePageMove = async (indexShift) => {
    try {
      const pageIndex = pages.findIndex((p) => p._id === page._id);
      if (pageIndex === -1) throw new Error("Page not found in pages array");

      const newPosition = page.position + indexShift;

      await api.put(`/quote_page/${page._id}`, { position: newPosition });

      const newPages = [...pages];

      for (const p of newPages) {
        if (p._id !== page._id) {
          if (indexShift > 0 && p.position >= page.position && p.position <= newPosition) {
            p.position -= 1;
            await api.put(`/quote_page/${p._id}`, { position: p.position });
          } else if (indexShift < 0 && p.position <= page.position && p.position >= newPosition) {
            p.position += 1;
            await api.put(`/quote_page/${p._id}`, { position: p.position });
          }
        } else {
          p.position = newPosition;
        }
      }
      onPagesChange([...newPages]);
      toast.success("Page moved");

      const pageElement = document.getElementById(`page-${page._id}`);
      if (!pageElement) return;
      pageElement.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      toast.error("Error moving page");
    }
  };
  if (!page) return null;

  const isFirstPage = pages.findIndex((p) => p._id === page._id) === 0;
  const isLastPage = pages.findIndex((p) => p._id === page._id) === pages.length - 1;

  const onBackgroundChange = async (pageId, backgroundUrl) => {
    try {
      await api.put(`/quote_page/${pageId}`, { background_image_url: backgroundUrl[0] || "" });
      const newPages = pages.map((p) => (p._id === pageId ? { ...p, background_image_url: backgroundUrl[0] } : p));
      onPagesChange(newPages);
    } catch (error) {
      toast.error("Error uploading background image");
    }
  };

  return (
    <>
      <div className="p-2 border-b border-gray-300 text-center">
        <label className="text-lg font-semibold">Page {page.position + 1}</label>
      </div>
      <div className="space-y-2 p-4">
        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="position">
            Page position
          </label>
          {!isFirstPage && (
            <button onClick={() => handlePageMove(-1)} className="w-full flex items-center justify-center p-4 border rounded-md shadow-sm cursor-pointer hover:bg-gray-50 bg-white">
              <FaChevronUp />
            </button>
          )}
          {!isLastPage && (
            <button onClick={() => handlePageMove(1)} className="w-full flex items-center justify-center p-4 border rounded-md shadow-sm cursor-pointer hover:bg-gray-50 bg-white">
              <FaChevronDown />
            </button>
          )}
        </div>
        <BackgroundImageSelect page={page} values={values} onChange={onBackgroundChange} />
      </div>
    </>
  );
};

const BackgroundImageSelect = ({ page, values, onChange }) => {
  const defaultList = ["https://bank.cellar-c2.services.clever-cloud.com/filebgImage/4d851a8563e36ded49503fe26de6f650/Le%20bon.png"];

  return (
    <div className="flex flex-col wrap">
      <label className="mb-3 text-sm font-semibold">Background image</label>
      <div className="flex items-center gap-4 wrap">
        {defaultList.map((bg, i) => (
          <button
            className="w-20 h-20"
            onClick={() => {
              if (values.background_image_url !== bg) onChange(page._id, [bg]);
              else onChange(page._id, "");
            }}>
            <img className={`w-20 h-20 border-2 rounded-lg ${values.background_image_url === bg ? "border-primary" : "border-gray-200"}`} src={bg} />
          </button>
        ))}
        <FileInput name="backgroundImage" folder="bgImage" value={values?.background_image_url} onChange={(e) => onBackgroundChange(page._id, e.target.value)} />
      </div>
    </div>
  );
};
