import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { useLocation } from "react-router-dom";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";
import { MdDelete, MdUpload } from "react-icons/md";

import { readFileAsync } from "../../utils";

import Loader from "../../components/loader";
import selego from "../../assets/selego_logo3.jpg";

import api from "../../services/api";

const STEPS = {
  APPLY: "APPLY",
  QUESTIONS: "QUESTIONS",
  THANKYOU: "THANKYOU",
};

export default () => {
  const [step, setStep] = useState(STEPS.APPLY);
  const [loading, setLoading] = useState(true);
  const [jobPost, setJobPost] = useState(null);
  const [job, setJob] = useState(null);
  const [user, setUser] = useState(null);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    setLoading(true);
    const jobPostId = query.get("jobpost");
    const { data: datajobPost } = await api.get(`/jobpost/${jobPostId}?incrementViews=true`);
    const { data: job } = await api.get(`/job/${datajobPost.job_id}`);
    setJobPost(datajobPost);
    setJob(job);
    setLoading(false);
  }

  if (loading) return <Loader />;

  return (
    <div className="w-full px-4 py-8 bg-blue-50">
      {step === STEPS.APPLY && (
        <Apply
          jobPost={jobPost}
          job={job}
          setUser={(e) => {
            setUser(e);
            setStep(STEPS.THANKYOU);
          }}
        />
      )}
      {step === STEPS.QUESTIONS && <Questions job={job} onClose={() => setStep(STEPS.APPLY)} user={user} />}
      {step === STEPS.THANKYOU && <ThankYou onClose={() => setStep(STEPS.APPLY)} />}
    </div>
  );
};

const Questions = ({ job, user, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState([]);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);

      const job_application_qna = job?.questions.map((question, index) => ({
        question: question.text,
        answer: answers[index] || "",
      }));

      const { data, ok } = await api.put(`/user/${user._id}`, { job_application_qna });
      setLoading(false);

      if (!ok) return toast.error("Wrong signup");

      onClose();
    } catch (e) {
      setLoading(false);
      if (e.code === "USER_ALREADY_REGISTERED") {
        return toast.error(
          <span>
            This email is already registered.
            <br />
            We&apos;ll contact you soon.
          </span>,
        );
      }
      toast.error(e.code);
    }
  }

  if (loading) return <Loader />;

  return (
    <form className="flex flex-col overflow-auto" onSubmit={handleSubmit}>
      <div className="mb-6">{job ? <h1 className="text-2xl text-black font-bold mb-1">Please complete the required information to proceed with your application</h1> : null}</div>
      <div className="flex flex-col gap-4 mb-4 border-b pb-5">
        {job?.questions.map((question, index) => (
          <div key={index} className="mb-4">
            <p className="text-dull-black text-base font-semibold mb-1">{question.text}</p>
            <textarea
              className="bg-transparent outline-0 block w-full p-2.5 mb-1.5 rounded-lg text-sm font-medium pl-3 border border-gray-200 min-h-20"
              type="text"
              placeholder="Type your answer"
              value={answers[index] || ""}
              onChange={(e) => {
                const newAnswers = [...answers];
                newAnswers[index] = e.target.value;
                setAnswers(newAnswers);
              }}
            />
          </div>
        ))}
      </div>
      <button type="submit" className="mt-3 self-end bg-blue-600 hover:bg-blue-500 text-white tracking-wider px-5 py-3 text-base font-medium rounded-[10px] cursor-pointer">
        Submit Application
      </button>
    </form>
  );
};

const ThankYou = ({ onClose }) => {
  return (
    <div className="p-6 text-center">
      <h4 className="text-2xl font-semibold">Thank You for Your Application!</h4>
      <p className="text-gray-500">We appreciate your interest in joining our team and the time and effort you put into the application.</p>
    </div>
  );
};

const Apply = ({ jobPost, job, setUser }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({ linkedin: "" });

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (!validator.isEmail(values?.email)) return toast.error("Invalid email");
      setLoading(true);

      const { data, token, code, ok } = await api.post(`/user/applicant`, { ...values, jobpost_id: jobPost?._id, job_id: job?._id });
      setLoading(false);

      if (!ok) return toast.error("Wrong signup", code);
      api.setToken(token);

      setUser(data);
      setValues({});
    } catch (e) {
      setLoading(false);
      if (e.code === "USER_ALREADY_REGISTERED") {
        return toast.error(
          <span>
            This email is already registered.
            <br />
            We&apos;ll contact you soon.
          </span>,
        );
      }
      toast.error(e.code);
    }
  }

  return (
    <div className="max-w-3xl mx-auto p-8 bg-white rounded-xl shadow-lg">
      <form className="flex flex-col space-y-8" onSubmit={handleSubmit}>
        {job && (
          <div className="text-center">
            <div className="flex items-center justify-center gap-6 mb-6">
              <img src={selego} alt="Selego Logo" className="h-16" />
              <h1 className="text-3xl font-bold text-gray-800">Join Our Team</h1>
            </div>
            <p className="text-gray-600">Please complete the required information to proceed with your application</p>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <label className="block text-gray-700 font-medium">
              Full Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="name"
              value={values?.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
              placeholder="John Doe"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-gray-700 font-medium">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="email"
              value={values?.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
              placeholder="example@mail.com"
            />
          </div>
        </div>

        <div className="space-y-6">
          {job?.linkedin_active && (
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-gray-700 font-medium">
                LinkedIn Profile <FaLinkedin className="text-blue-600" />
                {job?.linkedin_required && <span className="text-red-500">*</span>}
              </label>
              <input
                required={job?.linkedin_required}
                type="text"
                value={values?.linkedin}
                onChange={(e) => setValues({ ...values, linkedin: withHttp(e.target.value) })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                placeholder="Enter your LinkedIn URL"
              />
            </div>
          )}

          {job?.github_active && (
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-gray-700 font-medium">
                GitHub Profile <FaGithub className="text-gray-800" />
                {job?.github_required && <span className="text-red-500">*</span>}
              </label>
              <input
                required={job?.github_required}
                type="text"
                value={values?.github}
                onChange={(e) => setValues({ ...values, github: withHttp(e.target.value) })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                placeholder="Enter your GitHub URL"
              />
            </div>
          )}

          {job?.resume_active && (
            <div className="space-y-3">
              <label className="block text-gray-700 font-medium">
                Resume
                {job?.resume_required && <span className="text-red-500">*</span>}
              </label>
              <div className="flex items-center gap-4">
                <label className="flex-1 flex items-center justify-between px-4 py-3 border border-gray-300 rounded-lg hover:border-blue-500 cursor-pointer transition-all group">
                  <div className="flex items-center gap-3">
                    <MdUpload className="text-gray-500 group-hover:text-blue-500 transition-colors" />
                    <span className="text-gray-600 group-hover:text-gray-800 transition-colors">Upload resume</span>
                  </div>
                  {values.resume ? <BsCheckCircleFill className="text-green-500" /> : <BsCheckCircle className="text-gray-400" />}
                  <input
                    type="file"
                    hidden
                    required={job?.resume_required}
                    onChange={async (e) => {
                      const f = e.target.files[0];
                      const rawBody = await readFileAsync(f);
                      const { data } = await api.post(`/file`, { file: { rawBody, name: f.name }, folder: "/documents" });
                      setValues({ ...values, resume: data });
                    }}
                  />
                </label>
                {values.resume && (
                  <div className="flex items-center gap-3 px-4 py-2 bg-gray-50 rounded-lg border border-gray-200">
                    <div className="flex-1 flex items-center gap-2">
                      <div className="flex flex-col">
                        <span className="text-sm font-medium text-gray-900">Resume uploaded</span>
                        <a href={values.resume} target="_blank" rel="noopener noreferrer" className="text-xs text-blue-600 hover:text-blue-700">
                          Click to view
                        </a>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => setValues({ ...values, resume: "" })}
                      className="p-1.5 text-gray-400 hover:text-red-500 rounded-full hover:bg-red-50 transition-colors">
                      <MdDelete size={18} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <label className="flex items-start gap-3 cursor-pointer group">
          <input
            type="checkbox"
            className="mt-1 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            onChange={(e) => setValues({ ...values, profile_retention_consent: e.target.checked })}
          />
          <span className="text-gray-700 group-hover:text-gray-900 transition-colors">Would you like us to keep your profile for upcoming job openings or potential projects?</span>
        </label>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className={`px-8 py-3 rounded-lg font-medium text-white transition-all
              ${loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700 active:bg-blue-800"}`}>
            {loading ? (
              <div className="flex items-center gap-2">
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                Submitting...
              </div>
            ) : (
              "Submit Application"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
