import React, { useState } from "react";
import Modal from "../../../../components/modal";
import SelectUsers from "../../../../components/selectUser";
import api from "../../../../services/api";
import toast from "react-hot-toast";

export const TicketPeople = ({ ticket, onAdd }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <h3>People</h3>
      <div className="flex items-center cursor-pointer hover:bg-lightShade-grey p-2 pr-4 w-full" onClick={() => setOpen(true)}>
        {ticket.people.map((e) => (
          <img key={e.user_avatar} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" src={e.user_avatar} alt={e.user_avatar} />
        ))}
      </div>
      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="text-2xl mb-8 text-dull-black font-bold">Add people to this conversation</div>
        <div className="w-full mb-6">
          <SelectUsers
            name="people"
            className="projectsInput"
            placeholder="@somebody"
            onChange={async (u) => {
              if (!u._id) return;
              const { data } = await api.post(`/ticket/people/${ticket._id}`, { user_id: u._id });
              if (!data) return toast.error("Something went wrong!");
              toast.success(`Added ${u.name} to this conversation`);
              onAdd(data);
            }}
          />
        </div>
        {(ticket.people || []).map((e) => (
          <div className="flex items-center space-x-4 mb-4" key={e._id}>
            <img key={e.user_avatar} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" src={e.user_avatar} alt={e.user_avatar} />
            <div>{e.user_name}</div>
          </div>
        ))}
      </Modal>
    </>
  );
};
