import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { MdOutlineFileDownload } from "react-icons/md";

import api from "../../../services/api";
import Modal from "../../../components/modal";
import SelectMonth from "../../../components/selectMonth";
import { MONTHS, YEARS } from "../../../constants";

const AdminInvoice = ({ project }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    year: Number(searchParams.get("year")) || "",
    month: Number(searchParams.get("month")) || "",
    budget: searchParams.get("budget") || "",
    sent: searchParams.get("sent") || "",
    received: searchParams.get("received") || "",
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [budgets, setBudgets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, ok } = await api.post("/budget/search", { projectId: project._id, limit: 50 });
        if (!ok) return toast.error("Error");
        setBudgets(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = { projectId: project._id };

        if (filters.budget) query.budgetId = filters.budget;
        if (filters.year) {
          if (filters.month) {
            query.dateFrom = new Date(filters.year, filters.month, 1);
            query.dateTo = new Date(filters.year, filters.month + 1, 0);
          } else {
            query.dateFrom = new Date(filters.year, 0, 1);
            query.dateTo = new Date(filters.year, 11, 31);
          }
        }
        if (filters.invoice) query.invoiceId = filters.invoice;
        if (filters.sent) query.sent = filters.sent;
        if (filters.received) query.received = filters.received;

        const { data, ok } = await api.post(`/invoice/search`, query);
        if (!ok) return toast.error("Error while fetching data");
        setData(data);
        setTotal(data.length); // total from post search not correct
        // set search params
        const newSearchParams = new URLSearchParams();
        if (filters.year) newSearchParams.set("year", filters.year);
        if (filters.month) newSearchParams.set("month", filters.month);
        if (filters.budget) newSearchParams.set("budget", filters.budget);
        if (filters.sent) newSearchParams.set("sent", filters.sent);
        if (filters.received) newSearchParams.set("received", filters.received);
        setSearchParams(newSearchParams);
      } catch (error) {
        toast.error("Error while refreshing");
        console.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  const invoicesAmount = data.reduce((acc, e) => acc + e.total, 0);
  const invoicesNotPaid = data.filter((e) => e.sent === "yes" && e.received === "no").length || 0;
  const invoicesNotPaidAmount = data.filter((e) => e.sent === "yes" && e.received === "no").reduce((acc, e) => acc + e.totalRemaining, 0);

  return (
    <div className="p-4 space-y-6">
      <div className="flex items-center gap-4 justify-end mb-5">
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="year">
            Year
          </label>
          <select value={filters.year} onChange={(e) => setFilters({ ...filters, year: e.target.value })} className="select">
            <option value="">Select year</option>
            {YEARS.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        {filters.year && (
          <div className="space-y-2 w-full">
            <label className="block text-sm font-medium text-gray-700" htmlFor="month">
              Month
            </label>
            <select value={filters.month} onChange={(e) => setFilters({ ...filters, month: e.target.value })} className="select">
              <option value="">Select month</option>
              {MONTHS.map((e, i) => (
                <option key={i} value={i}>
                  {e}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="budget">
            Budget
          </label>
          <select value={filters.budget} onChange={(e) => setFilters({ ...filters, budget: e.target.value })} className="select">
            <option value="">Select budget</option>
            {budgets.map((e, i) => (
              <option key={i} value={e._id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="sent">
            Sending status
          </label>
          <select value={filters.sent} onChange={(e) => setFilters({ ...filters, sent: e.target.value })} className="select">
            <option value="">Select sending status</option>
            <option value="yes">Sent</option>
            <option value="no">Not sent</option>
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="received">
            Receiving status
          </label>
          <select value={filters.received} onChange={(e) => setFilters({ ...filters, received: e.target.value })} className="select">
            <option value="">Select receiving status</option>
            <option value="yes">Received</option>
            <option value="no">Not received</option>
          </select>
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        <div className="border border-gray-200 rounded-md p-4 flex-1">
          <h2 className="text-lg font-semibold">Invoices</h2>
          <p className="text-xl font-bold">{total}</p>
        </div>
        <div className="border border-gray-200 rounded-md p-4 flex-1">
          <h2 className="text-lg font-semibold">Invoices</h2>
          <p className="text-xl font-bold">{(invoicesAmount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
        </div>
        <div className="border border-gray-200 rounded-md p-4 flex-1">
          <h2 className="text-lg font-semibold">Invoices dues</h2>
          <p className="text-xl font-bold text-red-600">{invoicesNotPaid}</p>
        </div>
        <div className="border border-gray-200 rounded-md p-4 flex-1">
          <h2 className="text-lg font-semibold">Invoice amount due</h2>
          <p className="text-xl font-bold text-red-600">{(invoicesNotPaidAmount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
        </div>
      </div>
      {loading ? (
        <div>loading...</div>
      ) : (
        <div className="w-full p-6 border border-gray-300 rounded">
          <table className="table-fixed">
            <thead className="border-b border-gray-300">
              <tr>
                <th colSpan={2} className="text-left font-semibold text-sm p-2">
                  Name
                </th>
                <th className="text-left font-semibold text-sm p-2">Date</th>
                <th className="text-left font-semibold text-sm p-2">Reference</th>
                <th className="text-left font-semibold text-sm p-2">Sent</th>
                <th className="text-left font-semibold text-sm p-2">Received</th>
                <th className="text-right font-semibold text-sm p-2 pr-16">Total</th>
              </tr>
            </thead>
            <tbody className="">
              {data.length > 0 ? (
                data.map((item) => <ItemRow key={item._id} item={item} />)
              ) : (
                <tr>
                  <td colSpan={8} className="p-2 text-sm text-center text-gray-400">
                    No invoice found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const ItemRow = ({ item }) => {
  const onDownload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let alink = document.createElement("a");
    alink.href = item.file;
    alink.setAttribute("target", "_blank");
    alink.click();
  };

  return (
    <tr className="hover:bg-gray-100 h-12" onClick={() => window.open(`/invoice/${item._id}`, "_blank")}>
      <td colSpan={2} className="p-2 text-sm">
        {item.name}
      </td>
      <td className="text-left p-2 text-sm">{new Date(item.date).toLocaleDateString("en-US", { year: "numeric", month: "long" })} </td>
      <td className="text-left p-2 text-sm">{item.index}</td>
      <td className="text-left p-2 text-sm">
        {item.sent} {item.sent === "yes" && `(${new Date(item.sentAt).toLocaleString().substring(0, 10)})`}
      </td>
      <td className="text-left p-2 text-sm">
        {item.received} {item.received === "yes" && `(${new Date(item.receivedAt).toLocaleString().substring(0, 10)})`}
      </td>
      <td className="text-left text-sm">
        <div className="w-full flex items-center gap-4 p-2">
          <div className="flex-1 text-right">{(item.total || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</div>
          {item.file ? (
            <button onClick={onDownload} className="text-sm h-8 w-8 border border-gray-200 rounded-md flex items-center justify-center">
              <MdOutlineFileDownload />
            </button>
          ) : (
            <div className="w-8" />
          )}
        </div>
      </td>
    </tr>
  );
};

const CreateInvoice = ({ budgets, onChange }) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    name: "",
    budgetId: "",
    date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    project_id: id,
  });

  const handleSubmit = async () => {
    try {
      const res = await api.post("/invoice", values);
      if (!res.ok) return toast.error("Error while creating invoice");
      setOpen(false);
      onChange(res.data);
      toast.success("Invoice created");
    } catch (error) {
      console.error(error);
      toast.error("Error while creating invoice");
    }
  };

  return (
    <div>
      <button className="blue-btn" onClick={() => setOpen(true)}>
        Create Invoice
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3">
        <div className="px-16 py-12 space-y-8">
          <h2 className="text-xl font-semibold mb-4">Create New Invoice</h2>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="invoice-name">
              Invoice Name
            </label>
            <input id="invoice-name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} placeholder="Invoice name" className="input" />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="invoice-budget">
              Budget
            </label>
            <select id="invoice-budget" value={values.budgetId} onChange={(e) => setValues({ ...values, budgetId: e.target.value })} className="select">
              <option value="">Select budget</option>
              {budgets.map((e, i) => (
                <option key={i} value={e._id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="invoice-date">
              Invoice Date
            </label>
            <SelectMonth
              id="invoice-date"
              placeholder="Select month"
              indexDefaultValue={0}
              start={0}
              value={values.date}
              onChange={(e) => setValues({ ...values, date: e.target.value })}
            />
          </div>
          <div className="mt-6 flex justify-end">
            <button className="blue-btn mr-2" onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button className="blue-btn" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminInvoice;
