import React from "react";
import toast from "react-hot-toast";

import api from "../../services/api";

import Comments from "../Comments";

export default ({ user, setUser }) => {
  return (
    <div className="max-w-lg">
      <Comments
        value={user.comments}
        onAdd={async (text) => {
          const { data } = await api.post(`/user/${user._id}/comment`, { text });
          setUser(data);
          toast.success("Comment added!");
        }}
        onDelete={async (comment_id) => {
          const { data } = await api.put(`/user/${user._id}/delete-comment/${comment_id}`, {});
          setUser(data);
          toast.success("Comment deleted!");
        }}
      />
    </div>
  );
};
