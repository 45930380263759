import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoArrowForwardOutline } from "react-icons/io5";
import { FaUser } from "react-icons/fa";

import { classNames } from "../../utils";

const MyProfile = () => {
  const user = useSelector((state) => state.Auth.user);

  const fields = ["name", "email", "github", "calendly", "linkedin", "meeting", "description"];
  const neededFields = fields.filter((field) => !user[field]);
  const percentageField = (fields.length - neededFields.length) / fields.length;

  const percentageSkills = Math.min(user.key_words?.length / 5, 1);

  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const percentage = percentageField * 0.5 + percentageSkills * 0.5;
  const strokeDashoffset = String(circumference * (1 - percentage));
  let color = "";
  if (percentage >= 0.8) color = "#34D399";
  if (percentage < 0.8) color = "#FBBF24";
  if (percentage < 0.5) color = "#F87171";

  if (percentage >= 1) return null;

  return (
    <div className="flex-1">
      <div className={classNames(" border-[1px] border-gray-200 rounded-md overflow-hidden h-full w-full bg-white flex flex-col justify-between")}>
        <Link to={percentageSkills < 1 ? "/account?tab=skills" : "/account"} className="group relative flex flex-col items-center h-full">
          <h1 className="w-full text-base font-semibold text-gray-900 px-3 p-3 ">
            <span>My profile</span>
          </h1>
          <div className="flex-1 group relative flex flex-col items-center justify-between">
            <div className="relative flex items-center justify-center h-28 w-28">
              {percentage < 0.5 ? (
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-20"></span>
              ) : percentage < 0.8 ? (
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-50"></span>
              ) : null}
              <svg className="-rotate-90" width="100%" height="100%" viewBox="0 0 80 80">
                <circle cx="40" cy="40" r={radius} fill="transparent" stroke={"#DFE1E5"} strokeWidth="4" />
                <circle
                  cx="40"
                  cy="40"
                  r={radius}
                  fill="white"
                  stroke={color}
                  strokeWidth="4"
                  strokeDasharray={circumference}
                  strokeDashoffset={strokeDashoffset}
                  strokeLinecap="round"
                />
              </svg>
              {/* <FaUser className="absolute text-3xl " /> */}
              <div className="absolute flex flex-col items-center text-lg font-semibold">
                {(percentage).toFixed(2) * 100}%
              </div>
            </div>
          </div>
          <div className="p-2 w-full">
            {percentageField < 1 && (
              <div to={`/account`} className="flex flex-row gap-2 items-center rounded-md p-2 bg-white text-black group-hover:bg-gray-100">
                <span className="flex-1">Fill my informations</span>
                <IoArrowForwardOutline className="text-xl" />
              </div>
            )}
            {percentageSkills < 1 && (
              <div to={`/account?tab=skills`} className="flex flex-row gap-2 items-center rounded-md p-2 bg-white text-black group-hover:bg-gray-100">
                <span className="flex-1">Fill my skills</span>
                <IoArrowForwardOutline className="text-xl" />
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MyProfile;
