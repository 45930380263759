export function mergedQueries(queries) {
  let obj = { $and: [], $or: [] };
  queries
    .filter((q) => q.query)
    .forEach((q, k) => {
      let combinator = q.combinator;
      if (k === 0) {
        combinator = queries.length === 1 ? "AND" : queries[1].combinator;
      }
      if (combinator === "AND") obj["$and"].push(q.query);
      else obj["$or"].push(q.query);
    });
  if (obj.$and.length === 0) delete obj.$and;
  if (obj.$or.length === 0) delete obj.$or;
  return obj;
}

function query(key, value, cb) {
  if (Array.isArray(key)) {
    return key.map((k) => cb(k, value));
  }
  return cb(key, value);
}

export const defaultOperators = [
  {
    value: "*",
    text: "contains",
    useInput: true,
    query: (key, value) => value && query(key, value, (k, v) => ({ [k]: { $regex: `${v}`, $options: "i" } })),
  },
  {
    value: "!*",
    text: "does not contain",
    useInput: true,
    query: (key, value) => value && query(key, value, (k, v) => ({ [k]: { $not: { $regex: `${v}`, $options: "i" } } })),
  },
  {
    value: "=",
    text: "is",
    useInput: true,
    query: (key, value) => value && query(key, value, (k, v) => ({ [k]: v })),
  },
  {
    value: "!=",
    text: "is not",
    useInput: true,
    query: (key, value) => value && query(key, value, (k, v) => ({ [k]: { $ne: v } })),
  },
];

export const defaultCombinators = [
  { value: "AND", text: "AND" },
  { value: "OR", text: "OR" },
];

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export function withUniqueKey(rules) {
  if (!Array.isArray(rules)) return [];
  return rules.map((r) => ({ ...r, key: uuidv4() }));
}
