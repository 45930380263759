import React, { useEffect, useState } from "react";
import api from "../../../services/api";

import SelectUser from "../../../components/selectUser";


const defaultUser = { _id: "60353fd15f2a6b066b1bbb09", name: "maxime" };


export default ({ }) => {
  const [data, setData] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("month");

  useEffect(() => {
    get();
  }, [users]);

  async function get() {
    setData(null);
    const { data } = await api.post(`/activity/search`, { userIds: users.map((e) => e._id) });
    setData(data);
  }


  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col gap-4">
        <div>
          <select value={mode} onChange={(e) => setMode(e.target.value)}>
            <option value="month">Month</option>
            <option value="project">Project</option>
          </select>
          <UserAccordion users={users} setUsers={setUsers} />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4">
        {mode === "month" && <ProfitPerMonth data={data} />}
        {mode === "project" && <ProfitPerProject data={data} />}
      </div>
    </div>
  );
};

const ProfitPerProject = ({ data: d }) => {

  function getData(data) {

    
    const obj = [];
    for (let i = 0; i < (data || []).length; i++) {
      const d = data[i];
      const find = obj.find((e) => e.projectId === d.projectId);
      if (!find) {
        obj.push({ projectId: d.projectId, arr: [d] });
      } else {
        find.arr.push(d);
      }
    }

    for (let i = 0; i < obj.length; i++) {
      const o = obj[i];
      const cost = o.arr.reduce((a, b) => {
        if (b.projectType === "prospection") return a + 0;
        if (b.projectType === "timeoff") return a + 0;
        return a + b.cost
      }, 0);
      const value = o.arr.reduce((a, b) => {
        if (b.projectType === "admin") return a + 0;
        if (b.projectType === "prospection") return a + 0;
        if (b.projectType === "timeoff") return a + 0;
        return a + b.value;
      }, 0);

      const days = ((o.arr.reduce((a, b) => a + b.total, 0)) / 8).toFixed(2);

      console.log(`project: ${o.arr[0].projectName} days ${days} cost ${cost} value ${value} profit ${value - cost}`, o)
      o.cost = cost;
      o.value = value;
      o.days = days;
    }

    obj.sort((a, b) => b.value - a.value);

    return obj;
  }

  if (!d) return <div />

  const obj = getData(d);

  const totalCost = obj.reduce((a, b) => a + b.cost, 0).toFixed(2);
  const totalValue = obj.reduce((a, b) => a + b.value, 0).toFixed(2);
  const totalDays = obj.reduce((a, b) => a + parseFloat(b.days), 0).toFixed(2);

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <table className="min-w-full">
            <thead>
              <tr className="border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Project</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Type</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Days</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Cost</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Value</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Profit</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100 dark:bg-gray-900 dark:divide-gray-800">
              {(obj || []).map((e, i) => {
                const projectName = e.arr[0].projectName;
                const projectType = e.arr[0].projectType;
                const profit = (e.value - e.cost)

                return (
                  <tr key={i}>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{projectName}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{projectType}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.days} days</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.cost}€</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.value}€</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{profit.toFixed(0)}€</div>
                    </td>
                  </tr>
                );
              })}
              <tr key="total">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">Total</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{totalDays} days</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{totalCost}€</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{totalValue}€</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{totalValue}€</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{(totalValue - totalCost).toFixed(2)}€</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ProfitPerMonth = ({ data: d }) => {


  function getData(data) {
    const obj = [];
    for (let i = 0; i < (data || []).length; i++) {
      const d = data[i];
      const find = obj.find((e) => e.date === d.date);
      if (!find) {
        obj.push({ date: d.date, arr: [d] });
      } else {
        find.arr.push(d);
      }
    }

    for (let i = 0; i < obj.length; i++) {
      const o = obj[i];
      const cost = o.arr.reduce((a, b) => {
        if (b.projectType === "timeoff") return a + 0;
        return a + b.cost
      }, 0);
      const value = o.arr.reduce((a, b) => {
        if (b.projectType === "admin") return a + 0;
        if (b.projectType === "prospection") return a + 0;
        if (b.projectType === "timeoff") return a + 0;
        return a + b.value;
      }, 0);

      const days = (o.arr.reduce((a, b) => a + b.total, 0) / 8).toFixed(2);
      o.cost = cost;
      o.value = value;
      o.days = days;
    }

    //sort by date 
    obj.sort((a, b) => new Date(b.date) - new Date(a.date));

    return obj;
  }

  if (!d) return <div />

  const obj = getData(d);

  const totalCost = obj.reduce((a, b) => a + b.cost, 0);
  const totalValue = obj.reduce((a, b) => a + b.value, 0);
  const totalDays = obj.reduce((a, b) => a + parseFloat(b.days), 0).toFixed(2);

  const totalProfit = (totalValue - totalCost);

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <table className="min-w-full">
            <thead>
              <tr className="border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Date</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Days</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Cost</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Value</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Profit</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100 dark:bg-gray-900 dark:divide-gray-800">
              {(obj || []).map((e, i) => {
                const profit = e.value - e.cost;
                return (
                  <tr key={i}>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{new Date(e.date).toString().substring(0, 15)}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.days} days</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.cost.toFixed(0)}€</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.value.toFixed(0)}€</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{profit.toFixed(0)}€</div>
                    </td>
                  </tr>
                );
              })}
              <tr key="total">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">Total</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{totalDays} days</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{totalCost.toFixed(0)}€</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{totalValue.toFixed(0)}€</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900 dark:text-gray-100">{totalProfit.toFixed(0)}€</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const UserAccordion = ({ users, setUsers }) => {
  const [tree, setTree] = useState([]);

  useEffect(() => {
    get()
  }, []);


  async function get() {
    try {
      let arr = [];
      const res = await api.post(`/user/search`, { contracts: ["VIE", "CDD", "CDI", "APPRENTICESHIP", "INTERNSHIP"], status: "active" });
      arr.push(...res.data.users);
      const d = new Date();
      d.setMonth(d.getMonth() - 2);
      const res1 = await api.post(`/user/search`, { contracts: ["FREELANCE"], payment_type: "MONTHLY", status: "active" });
      arr.push(...res1.data.users);
      const res2 = await api.post(`/user/search`, { role: "applicant", statuses: ["HIRE"] });
      arr.push(...res2.data.users);
      arr = arr.map((e) => ({ ...e, children: [] }))
      const items = arr.reduce((acc, e) => {
        acc[e._id] = { ...e, children: [] };
        return acc;
      }, {});
      arr = arr.reduce((acc, e) => {
        if (e.manager_id && items[e.manager_id]) {
          items[e.manager_id].children.push(items[e._id]);
        } else {
          acc.push(items[e._id]);
        }
        return acc;
      }, []);

      setTree(arr)
    } catch (error) {
      console.error(error);
    }
  }

  // add a checkbox 

  const User = ({ data, level }) => {
    const checked = users.find((e) => e._id === data._id);

    return (
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <div style={{ width: level * 20 }}></div>
          <div className="flex items-center gap-2">
            <input type="checkbox" checked={checked} onChange={(e) => {
              if (e.target.checked) {
                setUsers([...users, data]);
              } else {
                setUsers(users.filter((e) => e._id !== data._id));
              }
            }} />
          </div>
          <div>{data.name}</div>
        </div>
        <div className="flex flex-col gap-2">
          {data.children.map((e, i) => (
            <User key={i} data={e} level={level + 1} />
          ))}
        </div>
      </div>
    );
  }


  // accordier and checkbox 
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        {tree.map((e, i) => (
          <User key={i} data={e} level={0} />
        ))}
      </div>
    </div>
  );
}
