import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Info from "./info";
import FineTuning from "./fineTuning";
import Raw from "./raw";
import Feature from "./features";

export default () => {
  const [tab, setTab] = useState("info");
  const [agent, setAgent] = useState();
  const { id } = useParams();

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const { data } = await api.get(`/agent/${id}`);
      setAgent(data);
    } catch (e) {
      console.log("a", e);
    }
  }

  if (!agent) return <Loader />;

  return (
    <div className="p-4">
      <div className="mb-4 overflow-x-scroll">
        <nav className="bg-white rounded-md flex item-center gap-2 w-full border border-neutral-300 overflow-hidden p-1">
          <TabItem tab="info" title="Info" setTab={setTab} active={tab === "info"} />
          <TabItem tab="features" title="Features" setTab={setTab} active={tab === "features"} />
          <TabItem tab="fine-tuning" title="Fine-tuning" setTab={setTab} active={tab === "fine-tuning"} />
          <TabItem tab="raw" title="Raw" setTab={setTab} active={tab === "raw"} />
        </nav>
      </div>
      <div>
        {tab === "info" && <Info agent={agent} setAgent={setAgent} />}
        {tab === "features" && <Feature agent={agent} />}
        {tab === "fine-tuning" && <FineTuning agent={agent} setAgent={setAgent} />}
        {tab === "raw" && <Raw agent={agent} />}
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active }) => (
  <button onClick={() => setTab(tab)} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
