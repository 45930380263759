import React, { useEffect, useState } from "react";

import api from "../../../services/api";
import Loader from "../../../components/loader";
import { ResponsiveSankey } from "@nivo/sankey";

export default ({}) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState();

  useEffect(() => {
    get();
  }, [source]);

  async function get() {
    setLoading(true);

    const dateFrom = new Date('2023-05-31T00:00:00.000Z');
    dateFrom.setDate(dateFrom.getDate() - 1);

    const query2 = {
      type: "invoice",
      sent: "yes",
      source: source,
    };
    const res1 = await api.post(`/invoice/search`, query2);
    const invoices = res1.data;
    console.log("invoices", invoices);

    const query5 = { startDate: dateFrom, per_page: 20000, sub_category: "INVOICE", source };
    const res5 = await api.post(`/bank/search`, query5);
    const incoming = res5.data.banks;

    const query3 = { startDate: dateFrom, per_page: 20000, source };
    const res2 = await api.post(`/bank/search`, query3);
    const banks = res2.data.banks;

    const res3 = await api.post(`/bank/cash`, { source });

      const cashData = res3.data;
    
      const invoicesIn = parseInt(incoming.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));
    
      const VATIn = parseInt(incoming.reduce((acc, f) => acc + (parseInt(f.tax) || 0), 0));
      const invoicesInHT = invoicesIn - VATIn;
    
      const cost = parseInt(banks.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));
    
      const costSalaries = banks
        .filter((f) => f.category === "VARIABLE_COST")
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);
    
      const fixedCosts = banks
        .filter((f) => f.category == "GENERAL_COST")
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);
    
      const VATOut = banks
        .filter((f) => f.sub_category === "VAT_DISBURSEMENT")
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);
    
      const totalOutOperating = costSalaries + fixedCosts + VATOut;
    
      const impots = banks
        .filter((f) => f.sub_category === "CORPORATION_TAX")
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);
    
      const invests = banks
        .filter((f) => f.sub_category === "INVEST")
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);
    
      const transfers = banks
        .filter((f) => f.sub_category === "TRANSFER")
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);


      const positive_transfers = banks
        .filter((f) => f.sub_category === "TRANSFER" && parseInt(f.amount) > 0)
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);

      const negative_transfers = banks
        .filter((f) => f.sub_category === "TRANSFER" && parseInt(f.amount) < 0)
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);

    
      const balance = invoicesIn + totalOutOperating + impots + invests + transfers;
    
      const uncategorisedCosts = cost - costSalaries - VATOut - invests - transfers - fixedCosts - invoicesIn;
    

      const inBank = cashData[cashData.length-1]?.total || 0;
    
      const dividendes = banks
        .filter((f) => f.sub_category === "DIVIDEND")
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);


      const invoicesPaidSum = invoices
        .filter((f) => f.sent === "yes" && f.received === "yes")
        .reduce((acc, f) => acc + (parseInt(f.total) || 0), 0);

    
      const cashOut = invoices
        .filter((f) => f.sent === "yes" && (f.received === "no" || f.received === "yes"))
        .reduce((acc, f) => acc + (parseInt(f.total) || 0), 0);
    
      const virtualMoney = inBank + cashOut;
    
      const obj = {
        invoicesInHT,
        VATIn,
        invoicesIn,
        costSalaries,
        fixedCosts,
        VATOut,
        totalOutOperating,
        impots,
        invests,
        transfers,
        positive_transfers,
        negative_transfers,
        uncategorisedCosts,
        balance,
        inBank,
        invoicesPaidSum,
        cashOut,
        dividendes,
        virtualMoney,
      };
    
      setStats(obj);
      setLoading(false);

  
  }

  if (loading) return <Loader />;

  return (
    <div className="p-4">
      <div className="flex items-center justify-end mb-2">
        <div className="max-w-xs w-full flex items-center space-x-2">
          <label class="whitespace-nowrap text-sm font-medium text-gray-700">Organisation: </label>
          <select class="input" value={source} onChange={(evt) => setSource(evt.target.value)}>
            <option value="">ALL</option>
            <option value="BNP">LE COLLECTIF</option>
            <option value="BUNQ">SELEGO</option>
          </select>
        </div>
      </div>

      <div style={{ height: "500px" }}>
        <CashflowGraph obj={stats} />
      </div>
      <div>
        {/* Available */}
        <div className="flex justify-between items-center">
          <div className="text-lg font-bold">Available</div>
          <div className="text-lg font-bold">{stats.inBank} €</div>
          </div>

      </div>
    </div>
  );
};

const CashflowGraph = ({ obj }) => {
  const transformData = (obj) => {
    const nodes = [
      { id: "Invoices In (HT)" },
      { id: "VAT In" },
      { id: "Operating Costs" },
      { id: "Investments" },
      { id: "Cashout" },

      { id: "Taxes" },
      { id: "Positive Transfers" },
      { id: "Negative Transfers" },
      { id: "Uncategorized Costs" },
      { id: "In Bank" },
      { id: "Virtual Money" },
      { id: "Virtual Money Invoices" },
    ];

    const totalExpenses = Math.abs(obj.totalOutOperating) + Math.abs(obj.invests) + Math.abs(obj.impots) + Math.abs(obj.transfers) + Math.abs(obj.uncategorisedCosts);
    const remainingForBank = Math.abs(obj.invoicesInHT) - totalExpenses; // This is the theoretical bank balance
    
    // You can optionally log the difference for debugging purposes:
    console.log("Calculated remaining for bank:", remainingForBank);
    console.log("Actual inBank value:", obj.inBank);
    
    const rawLinks = [

      { source: "Invoices In (HT)", target: "Virtual Money", value: obj.invoicesInHT },


      { source: "Positive Transfers", target: "Virtual Money", value: Math.abs(obj.positive_transfers) || 0 },    


      { source: "Virtual Money", target: "Operating Costs", value: Math.abs(obj.totalOutOperating) || 0 },
      { source: "Virtual Money", target: "Investments", value: Math.abs(obj.invests) || 0 },
      { source: "Virtual Money", target: "Taxes", value: Math.abs(obj.impots) || 0 },
      { source: "Virtual Money", target: "Negative Transfers", value: Math.abs(obj.negative_transfers) || 0 },
      { source: "Virtual Money", target: "Uncategorized Costs", value: Math.abs(obj.uncategorisedCosts) || 0 },


    ];
    

    const mergedLinks = rawLinks.reduce((acc, currentLink) => {
      const existingLink = acc.find((link) => link.source === currentLink.source && link.target === currentLink.target);

      if (existingLink) {
        existingLink.value += currentLink.value;
      } else {
        acc.push(currentLink);
      }

      return acc;
    }, []);

    return { nodes, links: mergedLinks };
  };

  const transformedData = transformData(obj);

  // filter out 0 or negative
  transformedData.links = transformedData?.links?.filter((e) => e.value > 0);

  console.log("transformedData", transformedData);

  return (
    <ResponsiveSankey
      data={transformedData}
      margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
      align="justify"
      colors={{ scheme: "category10" }}
      nodeOpacity={1}
      nodeHoverOthersOpacity={0.35}
      nodeThickness={18}
      nodeSpacing={24}
      nodeBorderWidth={0}
      nodeBorderColor={{
        from: "color",
        modifiers: [["darker", 0.8]],
      }}
      nodeBorderRadius={3}
      linkOpacity={0.5}
      linkHoverOthersOpacity={0.1}
      linkContract={3}
      enableLinkGradient={true}
      labelPosition="outside"
      labelOrientation="vertical"
      labelPadding={16}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1]],
      }}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          translateX: 130,
          itemWidth: 100,
          itemHeight: 14,
          itemDirection: "right-to-left",
          itemsSpacing: 2,
          itemTextColor: "#999",
          symbolSize: 14,
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

