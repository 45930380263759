import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { IoChatbubblesOutline, IoPeopleOutline, IoPencilOutline } from "react-icons/io5";
import { IoIosLink } from "react-icons/io";

import Loader from "../../../components/loader";
import api from "../../../services/api";
import { classNames, copyToClipboard } from "../../../utils";

import Edit from "./edit";
import Debate from "./debate";

export default function DebateView() {
  const [debate, setDebate] = useState();
  const { id } = useParams();
  const { tab } = useParams();
  const navigate = useNavigate();

  console.log("tab", tab);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!["edit"].includes(tab)) navigate(`/debate/${id}/debate`);
  }, [tab]);

  async function fetchData() {
    const { data, ok } = await api.get(`/debate/${id}`);
    if (!ok) return;
    setDebate(data);
  }

  const user = useSelector((state) => state.Auth.user);

  if (!debate) return <Loader />;

  function render() {
    if (tab === "debate") return <Debate debate={debate} setDebate={setDebate} />;
    if (tab === "edit") return <Edit onSave={setDebate} />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-7 bg-white-500 p-4">
        <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
          <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
            {user.role === "admin" && <TabItem title="Debate" active={tab === "debate"} Icon={IoChatbubblesOutline} onClick={() => navigate("debate")} />}
            {user.role === "admin" && <TabItem title="Edit" active={tab === "edit"} Icon={IoPencilOutline} onClick={() => navigate("edit")} />}
          </nav>
        </div>
        <div className="my-2" />
        <div>{render()}</div>
        <div className="my-2" />
      </div>
      <div className="col-span-12 md:col-span-5 m-2 pr-2">
        <div className="gap-4 px-2 pb-3 shadow-md bg-white rounded-md mb-2">
          <div className="flex flex-col">
            <div className="flex flex-col items-start space-y-3">
              <button
                className="px-2 py-1 hover:bg-gray-100 transition duration-300 cursor-pointer rounded text-xs text-gray-700 flex items-center space-x-1"
                onClick={() => {
                  const v = window.location.href;
                  copyToClipboard(v);
                  toast.success("Link copied to clipboard");
                }}>
                <IoIosLink className="text-primary" />
                Copy link
              </button>
              <img src={debate.image} alt={debate.name} className="mt-4 rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TabItem = ({ title, active, Icon, Badge, onClick }) => (
  <button
    onClick={onClick}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-primary  border-primary" : "",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
