import React, { useEffect } from "react";

const getVisibleHeight = (el) => {
  if (!el) return 0;

  const rect = el.getBoundingClientRect();
  const windowHeight = document.getElementById("wrapper").clientHeight / 2;

  const visibleTop = Math.max(rect.top, 0);
  const visibleBottom = Math.min(rect.bottom, windowHeight);

  if (visibleTop >= visibleBottom) {
    return 0;
  } else {
    return visibleBottom - visibleTop;
  }
};

const Pages = ({ children, onSelect }) => {
  const calculateMostVisiblePage = () => {
    const divs = document.querySelectorAll("#pages .page");
    let maxVisibleHeight = 0;
    let visibleDiv = null;

    divs.forEach((div) => {
      const visibleHeight = getVisibleHeight(div);
      if (visibleHeight > maxVisibleHeight) {
        maxVisibleHeight = visibleHeight;
        visibleDiv = div;
      }
    });

    if (visibleDiv) {
      const id = visibleDiv.getAttribute("id").split("-")[1];
      onSelect(id);
    }
  };

  useEffect(() => {
    const wrapper = document.getElementById("wrapper");

    setTimeout(() => calculateMostVisiblePage(), 1000); // fake wait for renderng of pages

    const handleResize = () => calculateMostVisiblePage();
    const handleScroll = () => calculateMostVisiblePage();

    wrapper.addEventListener("scroll", handleScroll);
    wrapper.addEventListener("resize", handleResize);

    return () => {
      wrapper.removeEventListener("scroll", handleScroll);
      wrapper.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div id="pages">{children}</div>;
};

export default Pages;
