import React, { useEffect, useState, createContext, useRef } from "react";
import toast from "react-hot-toast";
import { io } from "socket.io-client";
import { useNavigate, useLocation } from "react-router-dom";
import { BiMessageAdd } from "react-icons/bi";

import api from "./services/api";
import Modal from "./components/modal";
import { useSelector } from "react-redux";

import { apiURL } from "./config";

export const ChatContext = createContext({});

export default ({ children }) => {
  const [messageCallBacks, setMessageCallBacks] = useState([]);
  const navigate = useNavigate();
  const audioPlayer = useRef(null);
  const [notification, setNotification] = useState(false);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    async function requestNotificationPermission() {
      if (!("Notification" in window)) {
        console.log("Browser does not support desktop notification");
      } else {
        try {
          const permission = await Notification.requestPermission();
          console.log(permission);
        } catch (error) {
          console.error("Notification permission request failed:", error);
        }
      }
    }

    requestNotificationPermission();
  }, []);

  async function playAudio() {
    try {
      await audioPlayer.current.play();
    } catch (err) {
      console.error("Error playing audio:", err);
    }
  }

  useEffect(() => {
    if (!user) return;

    const socket = io(apiURL, {
      transports: ["websocket"],
      query: { user_id: user._id },
    });

    function handleNewMessage(v) {
      if (v.message.user_id === user._id) return; // dont receve my own message
      if (!v.ticket.people.map((e) => e.user_id).includes(user._id)) return; // don't receive message if you are not in the ticket

      for (let i = 0; i < messageCallBacks.length; i++) {
        messageCallBacks[i].cb(v);
      }

      if (v.ticket._id === history.location.pathname.split("/")[2]) return;

      playAudio();

      setNotification(true);
      const notif = new Notification(`New message from ${v.message.user_name}`, { body: "You have a new message" });
      console.log(notif);

      if (!notification) return;

      toast.custom((t) => (
        <div
          onClick={() => navigate(`/message/${v.ticket._id}`)}
          className={`${t.visible ? "animate-enter" : "animate-leave"} max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}>
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <img className="h-10 w-10 rounded-full" src={v.message.user_avatar} alt="" />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-xs font-norma text-gray-500">{v.message.user_name}</p>
                <p className="mt-1 text-sm text-gray-800">{v.message.text}</p>
              </div>
            </div>
          </div>
        </div>
      ));
    }

    function handleError(err) {
      console.log(`connect_error due to ${err.message} for ${apiURL}`);
    }

    socket.on(`new-message`, handleNewMessage);
    socket.on("connect_error", handleError);
    socket.on("connect", () => {
      console.log("connected");
      socket.emit("join", { user_id: user._id });
    });

    return () => {
      socket.off(`new-message`, handleNewMessage); // Fix the event name to match what was registered.
      socket.off(`connect_error`, handleError); // Remove all listeners when the component is unmounted.
    };
  }, [user, messageCallBacks]);

  const onNewMessage = (cb) => {
    const key = getId();
    setMessageCallBacks([...messageCallBacks, { key, cb }]);
    return key;
  };

  const clearNewMessage = (id) => {
    setMessageCallBacks(messageCallBacks.filter((e) => e.key !== id));
  };

  return (
    <ChatContext.Provider value={{ onNewMessage, clearNewMessage, setNotification }}>
      <div className="fixed bottom-4 right-4 z-50">
        <CreateTicket />
      </div>
      {children}
      <audio ref={audioPlayer} src="https://bank.cellar-c2.services.clever-cloud.com/file/project/848d0f021eb72bb01ec595a2e20f18ab/very_short_notif.mp3.mp3" />
    </ChatContext.Provider>
  );
};

function getId() {
  return Math.random().toString(36).substr(2, 9);
}
const CreateTicket = () => {
  const [text, setText] = useState("");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState();

  useEffect(() => setIsOpen(!!query.get("new_message")), [query]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!text) return;
      setLoading(true);
      const { data } = await api.post("/ticket", { type: "ticket", text });
      navigate(`/message/${data._id}`);
      setText("");
      setLoading(false);
      setIsOpen(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      toast.error("Can't create your ticket :( try later");
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    query.delete("new_message");
    navigate({ pathname: location.pathname, search: query.toString() });
  };

  return <div />;

  return (
    <>
      <button
        className="min-w-[48px] w-fit px-4 h-12 flex gap-2 items-center justify-center group bg-primary text-white rounded-full shadow-lg hover:bg-primary-500"
        onClick={() => {
          query.set("new_message", 1);
          navigate({ pathname: location.pathname, search: query.toString() });
        }}>
        <BiMessageAdd />
        <div className="hidden group-hover:block text-xs">Ask me anything</div>
      </button>
      <Modal isOpen={isOpen} className="max-w-xl w-full p-6" onClose={handleClose}>
        <div className="text-2xl mb-8 text-dull-black font-bold">Create a ticket</div>
        <form onSubmit={handleSubmit}>
          <div className="w-full mb-2">
            <div className="text-sm font-medium mb-2">Description</div>
            <textarea className="projectsInput h-[200px]" placeholder="Ask me anything..." value={text} onChange={(e) => setText(e.target.value)} />
          </div>
          <button disabled={loading} type="submit" className="btn btn-primary disabled:opacity-50 disabled:cursor-wait">
            Create
          </button>
        </form>
        <hr className="my-3 mx-10" />
        <div className="w-full grid grid-cols-2 gap-x-4">
          <div className="text-sm text-right text-light-grey">/alice</div>
          <div className="text-sm text-light-grey">Turn on assistant</div>
          <div className="text-sm text-right text-light-grey">/remind</div>
          <div className="text-sm text-light-grey">Add information permanently to assistant context</div>
          <div className="text-sm text-right text-light-grey">/imagine</div>
          <div className="text-sm text-light-grey">Get an image midjourney style</div>
          <div className="text-sm text-right text-light-grey">/gpt</div>
          <div className="text-sm text-light-grey">Use gpt 4</div>
        </div>
      </Modal>
    </>
  );
};
