import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoPaperPlane } from "react-icons/io5";
import { useSelector } from "react-redux";

import MultipleSelectInput from "../../../components/MultipleSelectInput";
import api from "../../../services/api";

export default ({ contact }) => {
  const u = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState({ recipients: contact.email ? contact.email.split(",") : [], subject: "", body: "" });

  async function sendEmail() {
    try {
      // if (!mail.recipients.length) return toast.error("Please add recipients");
      // setLoading(true);
      // await api.post(`/user/send/${user._id}`, mail);
      // await api.post(`/folk/send`, mail);
      // await api.put(`/user/${user._id}`, { applicant_status: "CONTACTED" });
      // setLoading(false);
      // toast.success("Email sent !");
      // setOpen(false);
      // setMail({ recipients: [user.email], subject: "Job offer", body: "" });
      // getusers();
      toast.success("todo");
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error sending email");
    }
  }

  return (
    <div className="flex flex-col gap-2 p-3 pt-5 w-full">
      {/* <div className="">
        <div className="px-1 text-sm text-gray-600 font-medium">Template</div>
        <select disabled={loading} onChange={(e) => loadTemplate(e.target.value)} className="bg-[#FFFFFF] text-sm text-gray-700 py-2 px-2 rounded-sm cursor-pointer shadow-sm">
          <option disabled selected>
            Select a template
          </option>
          {MAIL_TEMPLATES.filter((template) => template.active).map((template) => (
            <option key={template.name} value={template.name} label={template.name}>
              {template.name}
            </option>
          ))}
        </select>
      </div> */}
      <MultipleSelectInput value={mail.recipients} label="To" placeholder="Add recipients..." onChange={(e) => setMail({ ...mail, recipients: e })} />
      <div className={`w-full`}>
        <div className="px-1 text-sm text-gray-600 font-medium">Subject</div>
        <input
          disabled={loading}
          className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
          name="subject"
          value={mail.subject}
          onChange={(e) => setMail({ ...mail, subject: e.target.value })}
        />
      </div>
      <div className="mt-3">
        <div className="px-1 text-sm text-gray-600 font-medium">Message</div>
        <textarea
          disabled={loading}
          className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
          rows="12"
          name="description"
          value={mail.body}
          onChange={(e) => setMail({ ...mail, body: e.target.value })}
        />
      </div>
      <div className="flex gap-1 items-center w-3/4 h-3/4 min-h-max min-w-max">
        <button
          disabled={loading}
          className="ml-2 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-3 text-sm border border-blue-500 hover:border-transparent rounded flex gap-2 items-center disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-wait"
          onClick={() => sendEmail()}>
          <IoPaperPlane />
          Send
        </button>
      </div>
    </div>
  );
};
