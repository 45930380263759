import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { IoIosInformationCircleOutline } from "react-icons/io";

import Comments from "./Comments";
import SelectBudget from "../../../../../../components/SelectBudget";

import MultiSelect from "../../../../../../components/MultiSelect";
import SelectUser2 from "../../../../../../components/SelectUser2";

import Gerard from "./gerard";

import api from "../../../../../../services/api";

export default ({ notion, setNotion }) => {
  const user = useSelector((state) => state.Auth.user);

  return (
    <div className="flex mx-2 gap-2">
      <div className="w-1/6">
        <div className="relative w-full max-w-2xl mx-auto bg-white px-4 py-2 border border-gray-200 rounded-md">
          <div className="text-xs font-semibold mb-1">Category</div>
          <div className="mb-2">
            <SelectCategory
              value={notion.category}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { category: e.target.value });
                setNotion(data);
                toast.success("Category updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1 flex items-center" data-tip data-for={`priority`}>
            Priority
            <a
              href={`https://www.selego.co/ressources/hierarchiser-les-priorites-des-bugs-dans-le-developpement-agile/`}
              target="_blank"
              className="color-indigo-500 ml-1 underline">
              what does that mean?
            </a>
          </div>
          <div className="mb-2">
            <SelectPriority
              value={notion.priority}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { priority: e.target.value });
                setNotion(data);
                toast.success("Priority updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Budget</div>
          <div className="mb-2">
            <SelectBudget
              value={{ name: notion.budget_name, _id: notion.budget_id }}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { budget_name: e.name, budget_id: e._id });
                setNotion(data);
                toast.success("Budget updated!");
              }}
              projectId={notion.project_id}
            />
          </div>

          <div className="text-xs font-semibold mb-1">Tags</div>
          <div className="mb-2">
            <SelectTags
              projectId={notion.project_id}
              value={notion.tags}
              onChange={async (e) => {
                try {
                  await api.put(`/notion/${notion._id}`, { tags: e });
                  toast.success("Tags updated!");
                } catch (e) {
                  console.log(e);
                  toast.error("Something went wrong!");
                }
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Estimated hours</div>
          <div className="mb-2">
            <Hours
              value={notion.estimated_hours}
              onChange={async (estimated_hours) => {
                const estimated_price = ((estimated_hours * 500) / 8).toFixed(2);
                const { data } = await api.put(`/notion/${notion._id}`, { estimated_hours, estimated_price });
                setNotion(data);
                toast.success("Hours updated!");
              }}
            />
          </div>
          <div className="flex gap-x-1 text-xs font-semibold mb-1 items-center" data-tip data-for={`estimated-price`}>
            Estimated price
            <IoIosInformationCircleOutline />
          </div>
          <ReactTooltip id={`estimated-price`}>
            <span>Based on a 500€ daily rate</span>
          </ReactTooltip>
          <div className="mb-2">
            <input
              type="number"
              className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2 disabled:bg-gray-100"
              value={notion.estimated_price}
              disabled
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { estimated_price: e });
                setNotion(data);
                toast.success("Price updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Status</div>
          <div className="mb-2">
            <select
              className="input"
              value={notion.status}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { status: e.target.value });
                setNotion(data);
                toast.success("Status updated!");
              }}>
              <option value="">No status</option>
              <option value="TODO">TODO</option>
              <option value="READY_TO_DEV">READY_TO_DEV</option>
              <option value="IN_PROGRESS">IN_PROGRESS</option>
              <option value="TESTING">TESTING</option>
              <option value="DONE">DONE</option>
              <option value="ARCHIVED">ARCHIVED</option>
            </select>
          </div>

          <div className="text-xs font-semibold mb-1">Assignees</div>
          <SelectUser2
            value={notion.users}
            onChange={async (e) => {
              const { data } = await api.put(`/notion/${notion._id}`, { users: e });
              setNotion(data);
              toast.success("Assignees updated!");
            }}
          />
        </div>
      </div>
      <div className="w-3/6">
        <Comments
          value={notion.comments}
          onAdd={async (e) => {
            console.log("Adding comment", e);
            const comments = [...notion.comments, { text: e, user_name: user.name, user_id: user._id, user_avatar: user.avatar }];
            const { data } = await api.put(`/notion/${notion._id}`, { comments });
            setNotion(data);
            toast.success("Comment added!");
          }}
          onEdit={async (text, index) => {
            console.log("Edit comment", text);
            const comments = [...notion.comments];
            comments[index].text = text;
            const { data } = await api.put(`/notion/${notion._id}`, { comments });
            setNotion(data);
            toast.success("Comment updated!");
          }}
          onDelete={async (id) => {
            const comments = notion.comments.filter((c) => c._id !== id);
            const { data } = await api.put(`/notion/${notion._id}`, { comments });
            setNotion(data);
            toast.success("Comment deleted!");
          }}
        />
        <div className="mb-2">
          <span className="flex items-center text-xs text-gray-600 italic">
            Created by&nbsp;{notion.user_name} on {moment(notion.created_at).format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
      <div className="w-2/6">
        <Gerard notion={notion} setNotion={(e) => setNotion(e)} />
      </div>
    </div>
  );
};

const Hours = ({ value, onChange }) => {
  const [hours, setHours] = useState(0);

  useEffect(() => {
    setHours(value);
  }, [value]);

  return (
    <input
      type="number"
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={hours}
      onChange={(e) => setHours(e.target.value)}
      onBlur={(e) => onChange(hours)}
    />
  );
};

const SelectPriority = ({ value, onChange }) => {
  return (
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={value}
      onChange={onChange}>
      <option value="">No priority</option>
      <option value="P0">P0 (Critical)</option>
      <option value="P1">P1 (Important)</option>
      <option value="P2">P2 (Medium)</option>
      <option value="P3">P3 (Low)</option>
    </select>
  );
};

const SelectCategory = ({ value, onChange }) => {
  return (
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={value}
      onChange={onChange}>
      <option value="">No category</option>
      <option value="BUG">BUG</option>
      <option value="FEATURE">FEATURE</option>
    </select>
  );
};

const SelectTags = ({ value, onChange, projectId }) => {
  const [tags, setTags] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/notion/aggregate", { project_id: projectId, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-col gap-2">
      <MultiSelect
        values={tags.map((tag) => ({ value: tag, label: tag }))}
        id="select-tags"
        options={options}
        onSelectedChange={(e) => {
          setTags(e.map((e) => e.value));
          onChange(e.map((e) => e.value));
        }}
        placeholder="Tags"
        onCreateNew={(e) => {
          setTags([...tags, e.value]);
          get();
        }}
      />

      <div className="flex flex-wrap gap-1">
        {tags.map((tag) => (
          <span
            key={tag}
            className="text-xs w-fit  text-gray-600 bg-gray-100 px-2 py-1 rounded border border-gray-200 hover:bg-gray-200 cursor-pointer"
            onClick={() => {
              const newTags = tags.filter((t) => t !== tag);
              setTags(newTags);
              onChange(newTags);
            }}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};
