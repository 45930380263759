import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../services/api";

const View = ({ data, setData, onDone }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const extractValuesFromRow = (row) => {
    const values = [];
    let value = "";
    let inQuotes = false;

    for (let i = 0; i < row.length; i++) {
      const currentChar = row[i];

      if (currentChar === '"') {
        inQuotes = !inQuotes;
        continue;
      }

      if (currentChar === "," && !inQuotes) {
        values.push(value.trim());
        value = "";
      } else {
        value += currentChar;
      }

      if (i === row.length - 1) {
        values.push(value.trim());
      }
    }

    return values;
  };

  const parseCSV = (str) => {
    const rows = str.trim().split(/\r?\n/);
    const header = extractValuesFromRow(rows[0]);
    const jsonData = [];

    for (let i = 0; i < header.length; i++) {
      header[i] = header[i] || `Column ${i + 1}`;
    }

    for (let i = 1; i < rows.length; i++) {
      const values = extractValuesFromRow(rows[i]);
      const obj = {};
      for (let j = 0; j < values.length; j++) {
        const key = header[j] || `Column ${j + 1}`;
        obj[key] = values[j];
      }
      jsonData.push(obj);
    }

    return jsonData;
  };

  const handleChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = async function (event) {
      setLoading(true);
      const csvData = parseCSV(event.target.result);
      console.log("CSV Data:", csvData);
      await api.post(`/data/${data._id}/upload`, { data: csvData });
      setLoading(false);
      onDone();
      toast.success(`Uploaded ${csvData.length} records`);
    };
    reader.onerror = function (err) {
      console.error("Error reading file:", err);
    };
  };

  return (
    <div className="flex flex-col gap-4 p-6 bg-gray-50 rounded shadow-md max-w-md mx-auto">
      <div className="text-center text-gray-600 font-semibold">Upload your CSV file</div>
      <label className="flex flex-col items-center justify-center px-4 py-4 bg-blue-600 text-white rounded cursor-pointer transition duration-300 ease-in-out transform hover:bg-blue-700 active:scale-95">
        <svg className="w-6 h-6 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
        </svg>
        Select a CSV File
        <input type="file" className="hidden" accept=".csv" onChange={handleChange} />
      </label>
    </div>
  );
};

export default View;
