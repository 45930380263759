import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Modal from "../../../../components/modal";
import api from "../../../../services/api";

export default ({ project }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, [project]);

  const getData = async () => {
    const res = await api.post(`/credit/search`, { projectId: project._id });
    if (!res.ok) return toast.error("Failed to fetch credits");
    setData(res.data);
  };

  const total_amount = data.reduce((acc, credit) => acc + credit.amount, 0);

  return (
    <div>
      <div className="bg-white shadow rounded-lg p-6 mb-5">
        <h3 className="text-md font-semibold text-gray-800 mb-3">Summary</h3>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <p className="text-sm font-medium text-gray-600">Total amount remaining</p>
            <p className="text-xl font-bold text-green-600">{total_amount}€</p>
          </div>
          <div>
            <p className="text-sm font-medium text-gray-600">Active credits</p>
            <p className="text-xl font-bold text-gray-900">{data.length || 0}</p>
          </div>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-md font-semibold text-gray-800">Credits</h3>
          <Create project={project} onDone={getData} />
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="border-b text-left font-medium text-gray-700">
                <th className=" p-2">Name</th>
                <th className="p-2">Amount</th>
                <th className="p-2" />
              </tr>
            </thead>
            <tbody>
              {data.map((credit) => (
                <tr className="text-gray-800">
                  <td className="p-2">{credit.name}</td>
                  <td className="p-2">{credit.amount}</td>
                  <td className="p-2">
                    <Edit credit={credit} onDone={getData} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Create = ({ project, onDone }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ projectId: project._id });

  async function onCreate() {
    if (!values.name) return toast.error("Missing title !");

    const obj = {};

    obj.name = values.name;
    obj.projectId = project._id;
    obj.projectName = project.name;

    const res = await api.post("/credit", values);

    toast.success("Created!");
    onDone();
    setOpen(false);
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Add credit
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="projectsInput block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Description</div>
          <input className="projectsInput block" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
        </div>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Amount</div>
          <input className="projectsInput block" type="number" value={values.amount} onChange={(e) => setValues({ ...values, amount: e.target.value })} />
        </div>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};

const Edit = ({ credit, onDone }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(credit);

  async function onCreate() {
    if (!values.name) return toast.error("Missing name !");
    const res = await api.put(`/credit/${credit._id}`, values);
    toast.success("Created!");
    onDone();
    setOpen(false);
  }

  async function onDelete() {
    const res = await api.remove(`/credit/${credit._id}`);
    toast.success("Deleted!");
    onDone();
    setOpen(false);
  }

  return (
    <div>
      <div className="text-sm underline cursor-pointer" onClick={() => setOpen(true)}>
        Edit
      </div>
      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Name (*)</div>
          <input className="projectsInput block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Amount</div>
          <input className="projectsInput block" type="number" value={values.amount} onChange={(e) => setValues({ ...values, amount : e.target.value })} />
        </div>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Update
        </button>
        <button className="btn btn-secondary mt-4 ml-2" onClick={onDelete}>
          Delete
        </button>
      </Modal>
    </div>
  );
};
