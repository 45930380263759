import React, { useState } from "react";
import { useSelector } from "react-redux";

import { CONTRACTS, DIVISION, LOCATION, ROLES, YEARSSCHOOL, SCHOOL, MANAGEMENT } from "./constants";

import Salary from "./salary";
import Sell from "./sell";
import BenchmarkUser from "./benchmarkuser";

export default () => {

  const { user } = useSelector((state) => state.Auth);

  const [filter, setFilter] = useState({
    contract: "CDI",
    role: "FULLSTACK",
    experience_leStud: 1,
    experience_total: 1,
    division: "LE_COLLECTIF",
    numberDays: 5,
    location: "PARIS",
  });

  return (
    <div className="flex w-full">
      <div className="flex flex-col flex-1 p-4 gap-2">
        <SelectContract filter={filter} setFilter={setFilter} />
        <SelectDivision filter={filter} setFilter={setFilter} />
        <ContractLength filter={filter} setFilter={setFilter} />
        <YearsOfSchools filter={filter} setFilter={setFilter} />
        <NumberOfDayPerWeek filter={filter} setFilter={setFilter} />
        <Localisation filter={filter} setFilter={setFilter} />
        <JobTitle filter={filter} setFilter={setFilter} />
        <School filter={filter} setFilter={setFilter} />
        <Management filter={filter} setFilter={setFilter} />
        <hr className="my-4" />
        <div className="flex justify-between">
          <div className="flex-1">
            <GlobalAdvantage filter={filter} setFilter={setFilter} />
          </div>
          <div className="flex-1 px-4">
            <FrenchContractAdvantage filter={filter} setFilter={setFilter} />
          </div>
          <div className="flex-1">
            <DutchContractAdvantage filter={filter} setFilter={setFilter} />
          </div>
        </div>
        <hr className="my-4" />
        <ExperienceSimulator filter={filter} setFilter={setFilter} />
      </div>

      <div className="flex flex-col flex-1 p-4 gap-2">
      <BenchmarkUser filter ={filter} setFilter={setFilter}/>
        <div className="flex gap-3 items-center">
          <label className="font-bold">Chosen annual brut salary:</label>
          <input
            className=" rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
            name="salaryTest"
            type="number"
            value={filter.brut_salary}
            onChange={(e) => setFilter({ ...filter, brut_salary: e.target.value })}
          />
        </div>
        <Salary filter={filter} setFilter={setFilter} />
        <hr className="my-4" />
        {user.role === "admin" && <Sell filter={filter} setFilter={setFilter}/>}
      </div>
    </div>
  );
};

const YearsOfSchools = ({ filter, setFilter }) => {
  const CHOICES = Object.values(YEARSSCHOOL);

  if (![CONTRACTS.INTERNSHIP, CONTRACTS.APPRENTICESHIP].includes(filter.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Number of years of school</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={filter.yearSchool}
        onChange={(e) => setFilter({ ...filter, yearSchool: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.reverse().map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const DutchContractAdvantage = ({ filter, setFilter }) => {
  if (![DIVISION.SELEGO_NL].includes(filter.division)) return <div />;

  return (
    <div className="w-full">
      <label className="font-bold">Avantages Dutch</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        <div>
          <input type="checkbox" value={filter.dutch_ruling} onChange={(e) => setFilter({ ...filter, dutch_ruling: e.target.checked })} />
          <label className="p-4">30% Ruling</label>
        </div>
      </div>
    </div>
  );
};

const GlobalAdvantage = ({ filter, setFilter }) => {
  if ([CONTRACTS.FREELANCE].includes(filter.contract)) return <div />;

  const elements = [];

  elements.push(
    <div>
      <input type="checkbox" value={filter.computer} onChange={(e) => setFilter({ ...filter, computer: e.target.checked })} />
      <label className="p-4">Computer</label>
    </div>,
  );

  return (
    <div className="w-full">
      <label className="font-bold">Avantages globaux</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        {elements.map((e, i) => {
          return e;
        })}
      </div>
    </div>
  );
};

const FrenchContractAdvantage = ({ filter, setFilter }) => {
  if (![DIVISION.LE_COLLECTIF, DIVISION.SELEGO_FR].includes(filter.division)) return <div />;
  if ([CONTRACTS.VIE, CONTRACTS.FREELANCE].includes(filter.contract)) return <div />;

  const elements = [];

  elements.push(
    <div>
      <input type="checkbox" value={filter.french_ticketresto} onChange={(e) => setFilter({ ...filter, french_ticketresto: e.target.checked })} />
      <label className="p-4">Ticket resto</label>
    </div>,
  );

  if ([CONTRACTS.CDI, CONTRACTS.CDD, CONTRACTS.VIE].includes(filter.contract)) {
    elements.push(
      <div>
        <input type="checkbox" value={filter.french_alan} onChange={(e) => setFilter({ ...filter, french_alan: e.target.checked })} />
        <label className="p-4">Alan</label>
      </div>,
    );
  }

  elements.push(
    <div>
      <input type="checkbox" value={filter.french_navigo} onChange={(e) => setFilter({ ...filter, french_navigo: e.target.checked })} />
      <label className="p-4">Transport</label>
    </div>,
  );

  return (
    <div className="w-full">
      <label className="font-bold">Avantages Francais</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        {elements.map((e, i) => {
          return e;
        })}
      </div>
    </div>
  );
};

const JobTitle = ({ filter, setFilter }) => {
  const CHOICES = Object.values(ROLES);

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Job title</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={filter.role}
        onChange={(e) => setFilter({ ...filter, role: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const Localisation = ({ filter, setFilter }) => {
  const CHOICES = Object.values(LOCATION);

  if (![CONTRACTS.CDD, CONTRACTS.CDI, CONTRACTS.VIE].includes(filter.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Localisation</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={filter.location}
        onChange={(e) => setFilter({ ...filter, location: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const School = ({ filter, setFilter }) => {
  const CHOICES = Object.values(SCHOOL);

  if (![CONTRACTS.CDD, CONTRACTS.CDI].includes(filter.contract)) return <div />;
  if ([ROLES.DEVOPS].includes(filter.role)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">School</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={filter.school}
        onChange={(e) => setFilter({ ...filter, school: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const Management = ({ filter, setFilter }) => {
  const CHOICES = Object.values(MANAGEMENT);

  if (![CONTRACTS.CDD, CONTRACTS.CDI].includes(filter.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Management of a team</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={filter.management}
        onChange={(e) => setFilter({ ...filter, management: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};

const NumberOfDayPerWeek = ({ filter, setFilter }) => {
  if (![CONTRACTS.CDD, CONTRACTS.CDI, CONTRACTS.APPRENTICESHIP].includes(filter.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Number of days per week</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={filter.numberDays}
        onChange={(e) => setFilter({ ...filter, numberDays: e.target.value })}>
        <option key="" value=""></option>
        {[1, 2, 3, 4, 5].reverse().map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
    </div>
  );
};

const SelectContract = ({ filter, setFilter }) => {
  const CHOICES = Object.values(CONTRACTS);
  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Contract</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={filter.contract}
        onChange={(e) => setFilter({ ...filter, contract: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((contract) => (
          <option key={contract} value={contract}>
            {contract}
          </option>
        ))}
      </select>
    </div>
  );
};

const ContractLength = ({ filter, setFilter }) => {
  if (CONTRACTS.CDD !== filter.contract) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Contract length in month</label>
      <input
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        type="number"
        value={filter.contractLength}
        onChange={(e) => setFilter({ ...filter, contractLength: e.target.value })}
      />
    </div>
  );
};

const SelectDivision = ({ filter, setFilter }) => {
  const CHOICES = Object.values(DIVISION);

  if (![CONTRACTS.CDD, CONTRACTS.CDI, CONTRACTS.INTERNSHIP, CONTRACTS.APPRENTICESHIP].includes(filter.contract)) return <div />;

  return (
    <div className="flex flex-row">
      <label className="w-1/3 font-bold">Division</label>
      <select
        className="block w-1/3 mx-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
        value={filter.division}
        onChange={(e) => setFilter({ ...filter, division: e.target.value })}>
        <option key="" value=""></option>
        {CHOICES.map((contract) => (
          <option key={contract} value={contract}>
            {contract}
          </option>
        ))}
      </select>
    </div>
  );
};

const ExperienceSimulator = ({ filter, setFilter }) => {
  if (filter.contract === CONTRACTS.INTERNSHIP || filter.contract === CONTRACTS.APPRENTICESHIP) return <div />;
  // Calculate the total years of experience

  function calculateTotal(obj) {
    return (
      parseFloat(obj.experience_leStud || 0) * 1 +
      parseFloat(obj.experience_notUseful || 0) * 0 +
      parseFloat(obj.experience_inTheFieldButNotUsefull || 0) * 0.25 +
      parseFloat(obj.experience_halfUseful || 0) * 0.5 +
      parseFloat(obj.experience_useful || 0)
    );
  }

  return (
    <div className="w-full">
      <label className="font-bold">Experience</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        <div className="p-4">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  -
                </th>
                <th scope="col" className="px-6 py-3">
                  -
                </th>
                <th scope="col" className="px-6 py-3">
                  Coefficient
                </th>
                <th scope="col" className="px-6 py-3">
                  Years
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="px-6 py-2">At Le Stud</td>
                <td className="px-6 py-2">-</td>
                <td className="px-6 py-2">1</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    name="leStud"
                    value={filter.experience_leStud}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...filter, experience_leStud: val });
                      setFilter({ ...filter, experience_leStud: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-6 py-2">Outside of Le Stud</td>
                <td className="px-6 py-2">Not in the field</td>
                <td className="px-6 py-2">0</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    name="outside"
                    value={filter.experience_notUseful}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...filter, experience_notUseful: val });
                      setFilter({ ...filter, experience_notUseful: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-6 py-2">Outside of Le Stud</td>
                <td className="px-6 py-2">In the field but not useful</td>
                <td className="px-6 py-2">0.25</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    value={filter.experience_inTheFieldButNotUsefull}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...filter, experience_inTheFieldButNotUsefull: val });
                      setFilter({ ...filter, experience_inTheFieldButNotUsefull: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-6 py-2">Outside of Le Stud</td>
                <td className="px-6 py-2">50% useful</td>
                <td className="px-6 py-2">0.5</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    value={filter.experience_halfUseful}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...filter, experience_halfUseful: val });
                      setFilter({ ...filter, experience_halfUseful: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr className="border-b">
                <td className="px-6 py-2">Outside of Le Stud</td>
                <td className="px-6 py-2">Useful</td>
                <td className="px-6 py-2">1</td>
                <td className="px-6 py-2">
                  <input
                    type="number"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    value={filter.experience_useful}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value);
                      const total = calculateTotal({ ...filter, experience_useful: val });
                      setFilter({ ...filter, experience_useful: val, experience_total: total });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-2 font-medium text-gray-900">Total years of experience</td>
                <td className="px-6 py-2"></td>
                <td className="px-6 py-2"></td>
                <td className="px-6 py-2">
                  <input
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
                    type="number"
                    disabled={true}
                    value={filter.experience_total || 0}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
