import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../../services/api";

export default ({ contact, setContact, onClose }) => {
  const [values, setValues] = useState(contact);

  async function deleteData() {
    const { ok } = await api.remove(`/folk/${contact._id}`);
    if (!ok) return toast.error("Error");
    onClose();
    toast.success("Deleted!");
  }

  async function onSubmit(e) {
    try {
      e.preventDefault();
      const { data, ok } = await api.put(`/folk/${contact._id}`, values);
      if (!ok) return toast.error("Error");
      setContact(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  }

  function handleChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <div className="">
      <form onSubmit={onSubmit}>
        <React.Fragment>
          <div className="w-full">
            <div className="flex flex-row">
              <div className="flex flex-col gap-2 w-2/3">
                <div className="p-1 md:flex-auto"></div>
                <div className="p-1 !pt-0 grid grid-cols-1 md:grid-cols-3 gap-3">
                  <UserInput label="Company" name="company" value={values.company} onChange={handleChange} />
                  <UserInput label="First Name" name="first_name" value={values.first_name} onChange={handleChange} />
                  <UserInput label="Last Name" name="last_name" value={values.last_name} onChange={handleChange} />
                  {/* <div /> */}
                  <UserInput label="Email" name="email" value={values.email} onChange={handleChange} />
                  <UserInput label="Linkedin" name="linkedin" value={values.linkedin} onChange={handleChange} />
                  <UserInput label="Website" name="url" value={values.url} onChange={handleChange} />
                  {/* <div /> */}

                  {/* <UserSelect label="Contact Type" name="contactType" value={values.contactType} onChange={handleChange}>
                  <option value="">Select a contact type</option>
                  <option value="CLIENT">Client</option>
                  <option value="PARTNER">Partner</option>
                </UserSelect> */}
                  {/* <UserSelect label="Type" name="type" value={values.type} onChange={handleChange}>
                  <option value="">Select a type</option>
                  <option value="UPSELL">Upselling</option>
                  <option value="CONQUEST">Conquest</option>
                </UserSelect> */}
                  {/* <SelectProject
                  label="Project"
                  name="project_id"
                  value={values.project_name}
                  onChange={(e) => setValues({ ...values, project_id: e._id, project_name: e.name, project_logo: e.logo })}
                /> */}
                </div>
                <div className="p-1 !pt-0 grid grid-cols-1 md:grid-cols-3 gap-3">
                  <UserInput label="Budget" name="budget" value={values.budget} onChange={handleChange} />
                  {/* <UserInput label="Team" name="team" value={values.team} onChange={handleChange} /> */}
                  <UserSelect label="Category" name="category" value={values.category} onChange={handleChange}>
                    <option value="">Select a category</option>
                    <option value="Fintech">Fintech</option>
                    <option value="HRTech">HRTech</option>
                    <option value="EdTech">EdTech</option>
                    <option value="Insurtech">Insurtech</option>
                    <option value="Regtech">Regtech</option>
                    <option value="Legaltech">Legaltech</option>
                    <option value="Healthtech">Healthtech</option>
                    <option value="Proptech">Proptech</option>
                    <option value="Agtech">Agtech</option>
                    <option value="Govtech">Govtech</option>
                    <option value="Retailtech">Retailtech</option>
                    <option value="Traveltech">Traveltech</option>
                    <option value="Martech">Martech</option>
                    <option value="Adtech">Adtech</option>
                    <option value="Cleantech">Cleantech</option>
                    <option value="Biotech">Biotech</option>
                    <option value="Foodtech">Foodtech</option>
                    <option value="Transporttech">Transporttech</option>
                    <option value="Fashiontech">Fashiontech</option>
                    <option value="MediaTech">MediaTech</option>
                  </UserSelect>
                  <UserSelect label="Rank" name="rank" value={values.rank} onChange={handleChange}>
                    <option value="">Select a rank</option>
                    <option value="PROJECT_A">Project A</option>
                    <option value="PROJECT_B">Project B</option>
                    <option value="PROJECT_C">Project C</option>
                  </UserSelect>
                </div>
                <div className="p-3 !pt-0 flex">
                  <button className="bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onSubmit}>
                    Update
                  </button>
                  <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={deleteData}>
                    Delete
                  </button>
                </div>
              </div>
              <div className=" w-1/3">
                <Comments contact={contact} setContact={setContact} />
              </div>
            </div>
          </div>
        </React.Fragment>
      </form>
    </div>
  );
};


const Comments = ({ contact, setContact }) => {
  const [comment, setComment] = useState("");

  const user = useSelector((state) => state.Auth.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return;
    setComment("");
    const comments = contact.comments || [];
    comments.push({ text: comment, user_name: user.name, user_id: user._id, user_avatar: user.avatar });
    const { data } = await api.put(`/folk/${contact._id}`, { comments });
    setContact(data);
    toast.success("Comment added!");
  };

  async function onDelete(id) {
    if (confirm("Are you sure you want to delete this comment?") === false) return;
    const comments = contact.comments.filter((c) => c._id !== id);
    const { data } = await api.put(`/folk/${contact._id}`, { comments });
    setContact(data);
    toast.success("Comment deleted!");
  }

  return (
    <div className=" w-full h-full p-4">
      <div className="w-full h-full flex flex-col ">
        <span className="mb-10 font-bold">Comments</span>
        <div className="flex flex-col justify-end">
          {(contact.comments || []).map((comment, index) => (
            <div className="border-b-2 pb-2 mb-2">
              <div className="flex flex-row">
                <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4" />
                <div key={index} className="flex items-center mb-2">
                  <div>
                    <div className="bg-white  p-2 break-words">{comment.text}</div>
                    <div className="text-xs text-gray-500">
                      {comment.user_name} - {new Date(comment.created_at).toLocaleDateString()}
                    </div>
                    {onDelete && (
                      <div className="text-xs text-gray-500 text-right w-full cursor-pointer hover:text-red-500" onClick={() => onDelete(comment._id)}>
                        DELETE
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="flex items-center">
            <img src={user.avatar} alt="userlogo" className="rounded-full w-9 h-9 mr-2" />
            <textarea
              className="rounded-md border border-gray-100 shadow-sm block w-full"
              placeholder="Add a comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className="flex justify-end mt-2">
            <button className="btn btn-primary text-xs font-medium" onClick={handleSubmit}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserInput = ({ name, value, type = "text", onChange, label, className, disabled }) => (
  <div className={`${className} w-full`}>
    <div className="px-1 text-sm text-gray-600 font-medium">{label}</div>
    <input
      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
    />
  </div>
);

const UserSelect = ({ name, value, onChange, label, children }) => (
  <div className="w-full">
    <div className="px-1 text-sm text-gray-600 font-medium">{label}</div>
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      type="select"
      name={name}
      value={value}
      onChange={onChange}>
      {children}
    </select>
  </div>
);
