import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../components/loader";
import FileInput from "../../../components/FileInput";
import api from "../../../services/api";

const View = ({ data, setData }) => {
  const navigate = useNavigate();

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/data/${data._id}`);
    toast.success("successfully removed!");
    navigate("/data");
  }

  async function onSync() {
    try {
      const { ok, data: d, code } = await api.post(`/data/${data._id}/sync`);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setData(d);
      toast.success("data post sync !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  async function handleSubmit() {
    try {
      const { ok, data: d, code } = await api.put(`/data/${data._id}`, data);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setData(d);
      toast.success("data post updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!data) return <Loader />;

  return (
    <div className="flex flex-col gap-4 p-2 ">
      <div className="p-12 pb-6 bg-white rounded-2xl">
        <div className="grid grid-cols-2 gap-4 p-2">
          <div>
            <div className="">Name</div>
            <input
              type="text"
              name="name"
              id="name"
              className="block rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-1 px-2"
              placeholder="unlimited contract"
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>
          <div>
            <div className="">Index</div>
            <input
              type="text"
              name="index"
              className="block rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-1 px-2"
              placeholder=""
              disabled={true}
              value={data.index}
            />
          </div>
          <div className=" col-span-2">
            <div className="text-[14px] text-[#212325] font-medium">Description</div>
            <textarea
              rows="5"
              className="projectsInput h-20 text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={data.description}
              onChange={(e) => setData({ ...data, description: e.target.value })}
            />
          </div>

          <div>
            <div className="">Logo</div>
            <FileInput folder="data" value={data.logo} onChange={(file) => setData({ ...data, logo })} />
          </div>
        </div>

        <div className="flex mt-2">
          <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={handleSubmit}>
            Update
          </button>
          <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onSync}>
            Sync
          </button>
          <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default View;
