import React, { useState } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import RichTextEditorSun from "../../../../../../components/RichTextEditor";

export default ({ value, onAdd, onEdit, onDelete }) => {
  const [comment, setComment] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  return (
    <div className="flex-grow-0 ">
      <div>
        <div className="flex items-center justify-between flex-col">
          {/* <input type="text" className="w-[85%] projectsInput m-0" placeholder="Add a comment" value={comment} onChange={(e) => setComment(e.target.value)} /> */}
          <RichTextEditorSun
            buttonList={[
              ["font", "fontSize", "formatBlock"],
              ["bold", "underline", "italic"],
              ["align", "list"],
              ["outdent", "indent"],
              ["link", "image"]
            ]}
            values={comment}
            onChange={(e) => setComment(e)}
          />
          {editIndex !== null ? (
            <div className="flex items-center space-x-2">
              <button
                className="btn"
                onClick={() => {
                  setComment("");
                  setEditIndex(null);
                }}>
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  onEdit(comment, editIndex);
                  setComment("");
                  setEditIndex(null);
                }}>
                Update
              </button>
            </div>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => {
                onAdd(comment);
                setComment("");
              }}>
              Add
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-1 mb-2 overflow-y-auto mt-2">
        {(value || [])
          .filter((_, idx) => idx !== editIndex)
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          })
          .map((comment, index) => (
            <div key={index} className="flex items-center bg-white p-2 rounded-md">
              <div>
                <div className="flex items-center gap-2 text-xs font-semibold">
                  <img src={comment.user_avatar} className="rounded-full w-6 h-6 mr-2" />
                  <div>{comment.user_name} </div>
                  <div className="font-normal text-gray-500" data-tip data-for={`comment-${comment._id}`}>
                    {moment(new Date(comment.created_at)).fromNow()}
                  </div>
                  <ReactTooltip id={`comment-${comment._id}`}>
                    <span>{new Date(comment.created_at).toLocaleString()}</span>
                  </ReactTooltip>
                </div>
                <div dangerouslySetInnerHTML={{ __html: comment.text }} />
              </div>
              <div className="flex-grow" />
              <div className="flex space-x-2">
                {onDelete && (
                  <div
                    className="text-sm text-gray-500 ml-4 cursor-pointer underline"
                    onClick={() => {
                      if (!window.confirm("Are you sure?")) return;
                      onDelete(comment._id);
                    }}>
                    delete
                  </div>
                )}
                <div
                  className="text-sm text-gray-500 ml-4 cursor-pointer underline"
                  onClick={() => {
                    setEditIndex(index);
                    setComment(comment.text);
                  }}>
                  edit
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
