import React, { useEffect, useState } from "react";
import api from "../services/api";
import Tooltip from "react-tooltip";

import { getMonths } from "../utils";

const Workers = ({ project, max = 5 }) => {
  const [workers, setWorkers] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const dates = getMonths(3);
    const { data } = await api.get(`/activity?projectId=${project._id}&date=gte:${dates.pop().getTime()}`);
    const users = [];
    for (const activity of data) {
      const f = users.find((u) => u.name === activity.userName);
      if (f) continue;
      users.push({ name: activity.userName, avatar: activity.userAvatar });
    }
    setWorkers([...users]);
  }

  const count = workers.length > max ? workers.length - max : 0;

  return (
    <div className="mt-1">
      <div className="flex -space-x-0.5 flex-wrap">
        {workers
          .filter((e, i) => i < max)
          .map((user) => (
            <>
              <img key={user.name} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" src={user.avatar} alt={user.name} data-tip data-for={`worker-${user.name}`} />
              <Tooltip border borderColor="#e6e6e6" id={`worker-${user.name}`} effect="solid" className="max-w-xs text-sm keepOpacity" backgroundColor="#fff" textColor="#000">
                <span>{user.name}</span>
              </Tooltip>
            </>
          ))}
        {count > 0 && <div className="flex justify-center items-center font-semibold relative z-30 bg-gray-100 w-6 h-6 ring-2 ring-white text-xs rounded-full">{count}&nbsp;+</div>}
      </div>
    </div>
  );
};

export default Workers;
