import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Chart as ChartJS, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import api from "../../services/api";
import {
  LooperGroup,
  Elipse,
  ReportRocket,
  Bg_Shadow,
  Bg_Dots,
  Chart_Elipse,
  Chart__Elipse_Bg,
  Linear,
  CardImage,
  Sentry,
  HistoryBg,
  Rectangle_Fill,
  Rectangle_Empty,
  imageVector,
  Contact_LooperGroup,
  emailIcon,
  contact_bg,
} from "./imageImports";

import KPI from "../../components/kpi";
import Workers from "../../components/workers";
import Github from "../../components/github";
import { formatDate } from "../../utils";

ChartJS.register(...registerables);

export default function Reporting() {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [project, setProject] = useState(null);

  useEffect(() => {
    get(id);
  }, [id]);

  async function get(id) {
    const { data } = await api.get(`/reporting/${id}`);
    setProject(data.project);
    setInvoice(data.invoice);
  }

  if (!invoice) return <div>Loading...</div>;

  return (
    <div className="bg-[#1A1A40] text-[white] min-h-screen w-full bg-cover bg-no-repeat">
      <div className=" pb-[30px]">
        <div className="w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Bg_Dots})` }}>
          <div className="min-h-[500px] w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${LooperGroup})` }}>
            <div className="md:px-[120px]">
              <div className="pt-4" />
              <div className="flex justify-end mt-4">
                <div className="h-[50px] w-[50px] bg-cover bg-no-repeat " style={{ backgroundImage: `url(${Elipse})` }}></div>
              </div>
              <div className="flex justify-between flex-wrap gap-10">
                <div className="flex items-center gap-6 ">
                  <img className="h-[120px] w-[120px]" src={project.logo} />
                  <div className="">
                    <div>
                      <p className="text-[36px] text-[#FFFFFF] font-semibold	">{project.name}</p>
                      <span className="text-[20px] text-[#E5EAEF] font-medium">{formatDate(invoice.date)}</span>
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <div className="absolute w-[320px] h-[300px] bg-cover bg-no-repeat blur" style={{ backgroundImage: `url(${Bg_Shadow})` }}></div>
                  <img className="h-[300px] w-[320px] object-cover" src={ReportRocket} />
                </div>
              </div>
              <div className="flex items-center gap-3">
                <Workers project={project} max={100} />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-60 md:mb-24 md:px-[120px]">
          <WorkedFor data={invoice.itemsInvoice} />
        </div>
        <div className="md:px-[120px]">
          {/* <DogNutChartBar activities={activities} /> */}
          <div className="flex justify-end mt-4">
            <div className="h-[70px] w-[70px] bg-cover bg-no-repeat " style={{ backgroundImage: `url(${Chart__Elipse_Bg})` }}></div>
          </div>
        </div>
        <div className="md:px-[120px]">
          <div className="text-[36px] font-semibold text-[#FFFFFF]">
            <span>KPIs</span>
          </div>
          <KPI project={project} />
        </div>
        <div className="md:px-[120px]">{/* <History project={project} date={invoice.date} /> */}</div>
        <div className="md:px-[120px]">{/* <Team activities={activities} /> */}</div>
        <ContactUs />
      </div>
    </div>
  );
}

// WorkedFor Component
const WorkedFor = ({ data }) => {
  return (
    <div className="mt-5 w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Bg_Dots})` }}>
      <div className="text-[36px] font-semibold text-[#FFFFFF]">
        <span>Who worked and for how much ?</span>
      </div>
      <div className="relative">
        <div className="top-12 ">
          <div className="mt-5">
            <div className="my_bg backdrop-blur-md border !border-[#7A0BC0] rounded-[10px]">
              <table className="w-full table-fixed">
                <thead>
                  <tr className="border-b !border-[#0B88FE] text-[16px] text-[#FFFFFF] font-semibold">
                    <th className="p-3">Member</th>
                    <th className="p-3">Profil</th>
                    <th className="p-3">Time spent</th>
                    <th className="p-3">Price per day</th>
                    <th className="p-3">Price total</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                    .sort((a, b) => b.totalPrice - a.totalPrice)
                    .map((e) => {
                      return (
                        <tr className="border-b !border-[#7A0BC0]" key={e._id}>
                          <td className="p-3">
                            <div className="flex items-center gap-3">
                              {e.logo && <img className="w-[40px] h-[40px] rounded-full border !border-[#0B88FE]" src={e.logo} alt="image.png" />}
                              <span className="text-[16px] text-[#FFFFFF] font-medium">{e.name}</span>
                            </div>
                          </td>
                          <td className="p-3">{e.description}</td>
                          <td className="p-3">{`${e.quantity.toFixed(2)} days`}</td>
                          <td className="p-3">{`${e.price} €`}</td>
                          <td className="p-3">{`${e.totalPrice.toFixed()} €`}</td>
                        </tr>
                      );
                    })}
                  <tr className="text-[16px] text-[#FFFFFF] font-semibold" key="total">
                    <td className="p-3">Total-</td>
                    <td className="p-3">--</td>
                    <td className="p-3">{`${data.reduce((acc, a) => acc + a.quantity, 0).toFixed(2)} days`}</td>
                    <td className="p-3">---</td>
                    <td className="p-3">{`${data.reduce((acc, a) => acc + a.totalPrice, 0).toFixed()} €`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// DogNutChartBar Component
const DogNutChartBar = ({ activities }) => {
  const options = {
    legend: { display: false },
    elements: { arc: { borderWidth: 0 } },
  };

  let total = 0;

  const obj = {};
  for (let i = 0; i < activities.length; i++) {
    const key = activities[i].userJobTitle;
    if (!obj[key]) obj[key] = 0;
    obj[key] += activities[i].value;
    total += activities[i].value;
  }

  const arr = Object.keys(obj).map((e) => ({ key: `${e} ${((obj[e] * 100) / total).toFixed()}%`, value: obj[e] }));

  const data = {
    maintainAspectRatio: false,
    responsive: true,
    labels: arr.map((e) => e.key),
    datasets: [
      {
        data: arr.map((e) => e.value),
        backgroundColor: ["#003CBF", "#07CBFD", "#0560FD", "#003CBF"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="flex flex-wrap relative w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Bg_Dots})` }}>
      <div className="absolute top-0 bottom-10">
        <img className="rotate-12" width={100} height={100} src={Chart_Elipse} />
      </div>
      <div className="relative flex-1 border !border-[#7A0BC0] my_bg backdrop-blur-md rounded-[10px] m-4 p-3">
        <div className="flex justify-center">
          <div className="w-[400px]">
            <Doughnut data={data} options={options} />
          </div>
        </div>
      </div>
      {/* Dev product */}
      <div className=" z-20 flex-1">
        <div className="w-[300px] m-auto">
          <span className="text-[36px] font-semibold text-[#FFFFFF]">
            Dev/Product/
            <br /> Design/Growth?
          </span>
          <span className="text-[#E5EAEF] text-[16px] font-medium">Here is the repartition of the work that has been done this week, between our main specialities. </span>
        </div>
      </div>
    </div>
  );
};

const colors = ["#07CBFD", "#003CBF", "#0560FD", "#212325"];

// Sla-Errors Component
const SlaErrors = () => {
  let projects = [
    { name: "Accounting", title: "Accounting", percent: 40 },
    { name: "Snu", title: "Accounting", percent: 80 },
    { name: "Champergue", title: "Accounting", percent: 50 },
    { name: "Champergue", title: "Accounting", percent: 50 },
  ];

  let error = [
    { name: "Sentry", title: "TypeError", desc: "Description", pro: "ACCOUNTING-8W via web", date: "Mar 23, 2022 at 03:05 pm" },
    { name: "Sentry", title: "TypeError", desc: "Description", pro: "ACCOUNTING-8W via web", date: "Mar 23, 2022 at 03:05 pm" },
    { name: "Sentry", title: "TypeError", desc: "Description", pro: "ACCOUNTING-8W via web", date: "Mar 23, 2022 at 03:05 pm" },
    { name: "Sentry", title: "TypeError", desc: "Description", pro: "ACCOUNTING-8W via web", date: "Mar 23, 2022 at 03:05 pm" },
    { name: "Sentry", title: "TypeError", desc: "Description", pro: "ACCOUNTING-8W via web", date: "Mar 23, 2022 at 03:05 pm" },
    { name: "Sentry", title: "TypeError", desc: "Description", pro: "ACCOUNTING-8W via web", date: "Mar 23, 2022 at 03:05 pm" },
  ];

  return (
    <div className="mt-5 min-h-[700px] w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Linear})` }}>
      <div className="md:px-[120px] pt-20 flex justify-between flex-wrap w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Bg_Dots})` }}>
        <div className="w-full md:w-[40%]">
          <span className="text-[36px] text-[#fff] font-semibold">SLA</span>
          {/* Projects Card */}
          {projects.map((val, id) => {
            return (
              <div key={id} className="mt-4">
                <div className="my_bg backdrop-blur-md border !border-[#7A0BC0] flex flex-wrap items-center justify-between p-3 rounded-[10px]">
                  {/* Circular Progress Bar */}
                  <svg className="relative -rotate-90 w-[80px] h-[80px]" viewBox="0 0 120 120">
                    <circle cx="60" cy="60" r="54" fill="none" stroke="#0560FD" strokeDashoffset={`calc(100 - 0)`} strokeWidth="12" />
                    <circle
                      className="percent fifty"
                      strokeDasharray={100}
                      strokeDashoffset={`calc(100 - ${val.percent})`}
                      cx="60"
                      cy="60"
                      r="54"
                      fill="none"
                      stroke="#F9FBFD"
                      strokeWidth="12"
                      pathLength="100"
                    />
                  </svg>

                  {/* Loading percentage */}
                  <span className="absolute left-0 top-0 w-[110px] h-[110px] flex justify-center items-center ">{`${val.percent}%`}</span>
                  <div>
                    <p className="text-[24px] text-[#FFFFFF] font-semibold">{val.name}</p>
                    <span className="text-[16px] text-[#E5EAEF] font-medium">{val.title}</span>
                  </div>
                  <div>
                    <img width={100} src={CardImage} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* Errors */}
        <div className="w-full md:w-[58%] ">
          <span className="text-[36px] text-[#fff] font-semibold">Errors</span>
          <div className="mt-4 h-[520px] my_bg backdrop-blur-md rounded-[10px] border !border-[#7A0BC0] overflow-auto">
            {/* Card */}
            {error.map((err, id) => {
              return (
                <div key={id} className=" border-b !border-[#7A0BC0] p-3 flex gap-3">
                  {/* Image */}
                  <div className="border-r !border-[#0560FD] p-2">
                    <img width={40} height={40} src={Sentry} />
                  </div>
                  <div>
                    <p className="text-[16px] text-[#FFFFFF] font-semibold">Sentry</p>
                    <p className="text-[14px] text-[#0560FD] font-medium">TypeError</p>
                    <p className="text-[14px] text-[#FFFFFF] font-medium">Description</p>
                    <p className="text-[14px] text-[#E5EAEF] font-medium">
                      ACCOUNTING-8W via web | <span>Mar 23, 2022 at 03:05 pm</span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

// History Component
const History = ({ project, date }) => {
  const [dates, setDates] = useState();

  React.useEffect(() => {
    let from = new Date(date);
    let date_to = new Date(date);
    date_to.setMonth(date_to.getMonth() + 1);
    date_to.setDate(0);
    setDates({ from: from.toISOString(), to: date_to.toISOString() });
  }, [date]);

  if (!dates) return null;

  return (
    <div className="mt-5 w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Bg_Dots})` }}>
      {/* Heading and Bg Image */}
      <div className="flex justify-between">
        <p className="text-[36px] text-[#FFFFFF] font-semibold">Activity</p>
        <div className="h-[100px] w-[100px] bg-cover bg-no-repeat " style={{ backgroundImage: `url(${HistoryBg})` }}></div>
      </div>
      <Github project={project} dates={dates} paginated showUsers={false} />
    </div>
  );
};

// Team Component
const Team = ({ activities }) => {
  return (
    <div className="mt-5 mb-5 w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Bg_Dots})` }}>
      <div className="text-[36px] text-[#fff] font-semibold text-center">Comments from our team</div>
      {/* Team Images */}
      <div className="flex flex-wrap mt-5">
        <div className="w-full md:w-[50%] ">
          <div className="flex gap-10">
            <div className="pt-[120px]">
              <img width={100} height={100} src={Rectangle_Fill} />
              <img width={100} height={100} src={Rectangle_Empty} />
            </div>
            <div>
              <img width={100} height={100} src={Rectangle_Empty} />
              <img width={100} height={100} src={Rectangle_Empty} />
            </div>
            <div className="relative">
              <img width={150} height={150} src={imageVector} />
              <img
                className="absolute top-20 left-20 w-[100px] h-[100px] -rotate-45 border !border-[#0B88FE]"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5Wp2cMHZKaLKAEBMo5QgfeFPuppZPrHnAnfVgLU39ivryChoRKIRXs_C4Ui7Y-7qVFXk&usqp=CAU"
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-[50%] flex">
          <div className="w-[60%]">
            <div className="mt-2">
              {(activities || []).map((activity) => {
                if (activity.comment != null)
                  return (
                    <div className="flex gap-2 mb-8">
                      <img className="w-[40px] h-[40px] rounded-full border !border-[#0B88FE]" src={activity.userAvatar} alt="image.png" />
                      <div>
                        {activity.comment.split("\n").map((line, i) => {
                          return <div key={`${activity.user} ${i}`}>{line}</div>;
                        })}
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
          <div className="w-[40%]">
            <img width={100} height={100} src={Rectangle_Empty} />
            <img width={100} height={100} src={Rectangle_Empty} />
          </div>
        </div>
      </div>
    </div>
  );
};

// Contact Us Component
const ContactUs = () => {
  return (
    <div className="mt-5 min-h-[300px] w-full bg-cover bg-no-repeat" style={{ backgroundImage: `url(${Contact_LooperGroup})` }}>
      <div className="md:px-[120px] mt-5 flex flex-col justify-between min-h-[300px]">
        <div className="flex flex-wrap">
          <div className="w-full md:w-[50%] flex justify-center">
            <div>
              <p className="text-[56px] font-bold text-[#FFFFFF]">Contact us</p>
              <div className="flex ">
                <img width={35} height={30} src={emailIcon} />
                <span className="ml-3 text-[20px] font-medium text-[#E5EAEF]">sebastien@selego.co</span>
              </div>
            </div>
          </div>
          <div className="w-full md:w-[50%]">
            <div className="flex justify-center mt-4">
              <div className="h-[100px] w-[100px] bg-cover bg-no-repeat " style={{ backgroundImage: `url(${contact_bg})` }}></div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div>
            <p className="text-[24px] text-[#E5EAEF] font-medium">Thank you for being with us</p>
            <div className="flex items-center justify-center ">
              <div className="w-[80px] h-1 bg-[#0560FD] rounded-[10px]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
