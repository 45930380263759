import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Modal from "./modal";
import api from "../services/api";

export default ({ onChange }) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [prompt, setPrompt] = useState("");

  async function handleGenerate() {
    try {
      setLoading(true);
      const { ok, data, code } = await api.post(`/gpt`, { userPrompt: prompt });
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      const str = data.replaceAll("\n", "<br/>");
      onChange(str);

      toast.success("Post generated !");
      setLoading(false);
      setModal(false);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  return (
    <div>
      <button className="text-xs py-[4px] px-[4px] rounded-[10px] underline" onClick={() => setModal(true)}>
        Generate GPT4.0
      </button>
      <Modal isOpen={modal} onClose={(e) => setModal(false)} className="max-w-4xl w-full p-4">
        <div className="bg-white rounded-2xl font-bold">Prompt</div>
        <textarea className="border block w-full h-[500px] my-4" placeholder="my super prompt here" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
        <div>
          {loading && <div>loading</div>}
          {!loading && (
            <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={handleGenerate}>
              Generate
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};
