import React, { useContext } from "react";
import App, { AppContext } from "./context";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Helmet from "react-helmet";

import Rating from "../rating";

import api from "../../../services/api";

export default ({}) => {
  const context = useContext(AppContext);

  if (!context.workshop) return <div />;
  if (context.index !== 0) return <div />;

  function renderAction() {
    // if (context.userWorkshop) {
    //   const userWorkshop = context.userWorkshop;
    //   return (
    //     <button
    //       className="btn btn-primary mt-3"
    //       onClick={async () => {
    //         // const index = userWorkshop.step_id === 0 ? 1 : userWorkshop.step_id;
    //         // context.setIndex(index);
    //         context.setIndex(1);
    //       }}>
    //       Continue
    //     </button>
    //   );
    // }
    return (
      <button
        className="btn btn-primary mt-3"
        onClick={async () => {
          toast.success("Workshop started");
          const { data } = await api.post(`/workshop_user/public`, {
            workshop_id: context.workshop._id,
            workshop_name: context.workshop.name,
            workshop_version: context.workshop.version,
          });
          context.setUserWorkshop(data);
          context.setIndex(1);
        }}>
        Start
      </button>
    );
  }

  return (
    <div>
      <Link to="/learn-public" className="flex items-center space-x-2 cursor-pointer mb-6">
        <AiOutlineArrowLeft />
        <span>Library</span>
      </Link>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={context.workshop.name} />
        <meta property="og:description" content={context.workshop.description} />
        <meta property="og:image" content={context.workshop.image} />
        <meta property="og:url" content={`https://accounting.selego.co/learn_public/${context.workshop._id}`} />
      </Helmet>
      <div className="flex md:items-center md:justify-between md:flex-row flex-col-reverse">
        <div>
          <div className="text-base text-gray-400	font-light uppercase">{context.workshop.category}</div>
          <h1 className="text-3xl mb-2 font-bold">{context.workshop.name}</h1>
          <div className="space-x-2">
            {context.workshop.version && <span className="text-sm text-dull-grey my-2">version: {context.workshop.version}</span>}
            {context.workshop.read_time && <span className="text-sm text-dull-grey">({context.workshop.read_time} read)</span>}
          </div>

          <div className="my-3">{context.workshop.description}</div>
          <div className="flex gap-4 text-sm text-gray-700">
            <div>Last update</div>
            <div>{new Date(context.workshop.updated_at).toLocaleDateString()}</div>
          </div>
          <div className="flex items-center space-x-2 text-sm text-light-grey my-2">
            <span>Rating:</span>
            {!!context.workshop.rating_score && (
              <>
                <Rating value={context.workshop.rating_score} /> <strong>{`${context.workshop.rating_score} (${context.workshop.rating_count})`}</strong>
              </>
            )}
          </div>
          {renderAction()}
        </div>
        <div className="pl-2 lg:self-center self-start mb-4">
          <img src={context.workshop.image} className="object-cover rounded-md max-w-xs" />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col gap-4 mt-4 justify-between">
        <Menu />
        <Contributors />
      </div>
    </div>
  );
};

const Menu = () => {
  const context = useContext(AppContext);
  const steps = context.workshop.steps || [];
  return (
    <div>
      <div className="text-xl mb-2 font-bold">Workshop steps</div>
      <div className="	">
        {steps.map((step, index) => (
          <div
            key={index}
            className="cursor-pointer bg-white rounded-lg shadow-sm flex gap-4 p-2 mb-2"
            onClick={() => {
              // if (context.userWorkshop) context.setIndex(index + 1);
            }}>
            {`${index}. ${step.title}`}
          </div>
        ))}
      </div>
    </div>
  );
};

const Contributors = ({}) => {
  const context = useContext(AppContext);
  const contributors = context.workshop.contributors || [];
  if (!contributors.length) return <div />;

  const map = {};
  const uniqueContributors = [];
  contributors.forEach((contributor) => {
    if (!map[contributor.user_id]) {
      map[contributor.user_id] = true;
      uniqueContributors.push(contributor);
    }
  });

  return (
    <div>
      <div className="text-xl my-2 font-bold">Contributors</div>
      <div className="grid grid-cols-2 gap-4">
        {uniqueContributors.map((contributor, index) => {
          return (
            <div key={contributor._id} className="bg-white mb-[10px] rounded-lg shadow-sm flex items-center justify-between gap-4 p-2 flex-wrap">
              <div className="flex gap-2 items-center flex-1">
                <img src={contributor.user_avatar} alt="userlogo" className="rounded-full w-8 h-8" />
                <div>
                  <div className="text-sm">{contributor.user_name}</div>
                  <div className="text-xs text-gray-500">{new Date(contributor.created_at).toLocaleDateString()}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
