import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoPeople, IoInformationCircle, IoCard, IoPencil, IoNewspaper, IoDesktop } from "react-icons/io5";
import { classNames } from "../../../utils";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Info from "./info";
import Raw from "./raw";
import Mail from "./mail";
import Files from "./files";

const TABS = {
  INFO: "Info",
  RAW: "Raw",
  FILES: "Files",
  MAIL: "Mail",
};

const View = () => {
  const [tab, setTab] = useState(TABS.INFO);
  const [mail, setMail] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const { data } = await api.get(`/mail/${id}`);
      setMail(data);
    } catch (e) {
      console.log("a", e);
    }
  }

  const render = () => {
    switch (tab) {
      case TABS.INFO:
        return <Info mail={mail} setMail={setMail} />;
      case TABS.RAW:
        return <Raw mail={mail} setMail={setMail} />;
      case TABS.FILES:
        return <Files mail={mail} setMail={setMail} />;
      case TABS.MAIL:
        return <Mail mail={mail} setMail={setMail} />;
      default:
        return <Info mail={mail} setMail={setInvoice} />;
    }
  };

  if (!mail) return <Loader />;

  return (
    <div>
      <div className="m-2">
        <div className="flex justify-between">
          <div className="font-semibold text-lg text-primary mb-1">{mail.subject}</div>
        </div>
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden">
          <TabItem title={TABS.INFO} tab={TABS.INFO} setTab={setTab} active={tab === TABS.INFO} Icon={IoInformationCircle} />
          <TabItem title={TABS.FILES} tab={TABS.FILES} setTab={setTab} active={tab === TABS.FILES} Icon={IoDesktop} />
          <TabItem title={TABS.MAIL} tab={TABS.MAIL} setTab={setTab} active={tab === TABS.MAIL} Icon={IoDesktop} />
          <TabItem title={TABS.RAW} tab={TABS.RAW} setTab={setTab} active={tab === TABS.RAW} Icon={IoDesktop} />
        </nav>
        <div className="my-2" />
        <div className="w-full">{render()}</div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-primary  border-primary" : "",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);

export default View;
