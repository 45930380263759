import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Info from "./info";
import Raw from "./raw";
import Upload from "./upload";
import Mapping from "./mapping";
import Explore from "./explore";

const TABS = {
  INFO: "Info",
  TABLE: "Table",
  MAPPING: "Mapping",
  UPLOAD: "Upload",
  RAW: "Raw",
};

export default () => {
  const [tab, setTab] = useState(TABS.TABLE);
  const [data, setData] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const { data: d } = await api.get(`/data/${id}`);
      setData(d);
    } catch (e) {
      console.log("a", e);
    }
  }

  if (!data) return <Loader />;

  return (
    <div>
      <div className="m-2">
        <div className="flex justify-between">
          <div>{`${data.name}`}</div>
        </div>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px list-none space-x-2">
            <li
              onClick={(e) => setTab(TABS.INFO)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${tab === TABS.INFO && "border-primary"
                }`}>
              Info
            </li>
            <li
              onClick={(e) => setTab(TABS.UPLOAD)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${tab === TABS.UPLOAD && "border-primary"
                }`}>
              Upload
            </li>
            <li
              onClick={(e) => setTab(TABS.MAPPING)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${tab === TABS.MAPPING && "border-primary"
                }`}>
              Mapping
            </li>
            <li
              onClick={(e) => setTab(TABS.TABLE)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${tab === TABS.RAW && "border-primary"
                }`}>
              Table/Explore
            </li>
            <li
              onClick={(e) => setTab(TABS.RAW)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${tab === TABS.RAW && "border-primary"
                }`}>
              Raw
            </li>
          </ul>
        </div>
        {tab === TABS.INFO && <Info data={data} setData={setData} />}
        {tab === TABS.UPLOAD && <Upload data={data} onDone={() => setTab(TABS.TABLE)} />}
        {tab === TABS.MAPPING && <Mapping data={data} setData={setData} />}
        {tab === TABS.RAW && <Raw data={data} />}
        {tab === TABS.TABLE && <Explore data={data} />}
      </div>
    </div>
  );
};