import React, { useState, useEffect } from "react";

import toast from "react-hot-toast";

import api from "../../services/api";

import { CONTRACTS, DIVISION, LOCATION, ROLES, YEARSSCHOOL, SCHOOL, MANAGEMENT, PRICING, PRICING_START } from "./salary/constants";
import { PERIOD } from "./../../constants";

import { dutchInfo, frenchInfo, spanishInfo } from "./salary/salary";

import { calculateMargin, calculateTjm, toDateInputValue } from "../../utils";

import { HiCurrencyEuro } from "react-icons/hi2";

export default ({ user, setUser }) => {
  const [values, setValues] = useState(user);

  useEffect(() => {
    if (!values.days_worked && values.contract != "FREELANCE") return;
    if (!values.brut_salary && values.contract != "FREELANCE") return;
    
    let total_monthly_cost;
    let tjm_salary;
    let tjm_expense; 

    if (values.payment_type === "DAILY" && values.contract === "FREELANCE") {
      tjm_salary = values.payment_value
      tjm_expense = 0; 
    } else if (values.payment_type === "MONTHLY" && values.contract === "FREELANCE") {
      tjm_salary = (values.payment_value / values.days_worked).toFixed(0);
      tjm_expense = 0; 
      total_monthly_cost = values.payment_value
    } else {
      tjm_salary = getCostSalary(values)
      tjm_expense = getCostExpenses(values)
      total_monthly_cost = getMonthlyTotalCost(values)
    }

    const tjm = parseFloat(values.tjm_salary || 0) + parseFloat(values.tjm_expense || 0);

    if (values.tjm !== tjm || values.tjm_salary !== tjm_salary || values.tjm_expense !== tjm_expense || values.total_monthly_cost !== total_monthly_cost) {
      setValues({ ...values, tjm, tjm_salary, tjm_expense, total_monthly_cost});
    }

  }, [values]);


  const saveChanges = async () => {
    try {
      const { data } = await api.put(`/user/${user._id}`, values);
      setUser(data);
      toast.success("Saved!");
    } catch (e) {
      console.log(e);
    }
  };

  function handleChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <div className="w-full">
      <label className="font-bold">Payment data</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md flex flex-col">
        <div className="mt-2">
            <div className="flex flex-row justify-between py-1">
              <UserSelect label="Contract" name="contract" value={values.contract} onChange={handleChange}>
                <option value=""></option>
                {Object.keys(CONTRACTS).map((e) => {
                  return (
                    <option key={e} value={e}>
                      {e}
                    </option>
                  );
                })}
              </UserSelect>
              <UserSelect label="Division" name="division" value={values.division} onChange={handleChange}>
                <option value=""></option>
                {Object.keys(DIVISION).map((e) => {
                  return (
                    <option key={e} value={e}>
                      {e}
                    </option>
                  );
                })}
              </UserSelect>
              </div>
              <PaymentFreelance values={values} handleChange={handleChange}/>
              <PaymentEmployee values={values} handleChange={handleChange}/>
            <div>
              <button className="btn btn-primary" onClick={saveChanges}>
                Save
              </button>
            </div>
        </div>
        </div>
    </div>
  );
};

const PaymentEmployee = ({ values, handleChange }) => {
  if (values.contract === CONTRACTS.FREELANCE) return <div></div>;

  const margin = calculateMargin(values.tjm, values.tjms);

  return (
    <div>
      <div className="flex flex-row justify-between py-1">
        <UserInput
            label="Annual brut salary"
            name="brut_salary"
            type="number"
            value={values.brut_salary}
            onChange={handleChange}
        />
        <UserInput label="Days worked" name="days_worked" type="number" value={values.days_worked} key="days_worked" onChange={handleChange} />
        {values.contract === CONTRACTS.APPRENTICESHIP && <UserInput label="Annual school cost" name="school_cost" type="number" value={values.school_cost} onChange={handleChange} />}
        <UserInput label="Cost per month (taxes salary + fixed costs)" type="number" name="total_monthly_cost" key="total_monthly_cost" disabled={true} value={values.total_monthly_cost}/>
        <UserInput label="Payment value - net per month" type="number" name="payment_value" key="payment_value" onChange={handleChange} value={values.payment_value}/>
      </div>
      <div className="flex flex-row justify-between py-1">
        <UserInput label="[tjm_salary] Cost per day salary" type="number" name="tjm_salary" key="tjm_salary" disabled={true} value={values.tjm_salary}/>
        <UserInput label="[tjm_expense] Cost per day expense" type="number" name="tjm_expense" key="tjm_expense" disabled={true} value={values.tjm_expense}/>
        <UserInput key="tjm" label="[tjm] Cost per day total (tjm_expense + tjm_salary)" type="number" name="tjm" value={values.tjm} disabled={true}/>
      </div>
      <div className="flex flex-row justify-between py-1">
          <UserInput label="[TJMS] Sell per day" type="number" name="tjms" value={values.tjms} onChange={handleChange} />
          <UserInput
          label={
              <div className="flex items-center gap-1">
              Margin
              <HiCurrencyEuro />
              </div>
          }
          disabled
          value={`${margin}%`}
          className={margin < 15 ? "text-red-600" : "text-green-600"}
          />
      </div>
  </div>
  )
}

const PaymentFreelance = ({ values, handleChange }) => {
  if (values.contract != CONTRACTS.FREELANCE) return <div></div>;

  const margin = calculateMargin(values.tjm, values.tjms)

  return (
    <div>
      <div className="flex flex-row justify-between py-1">
        <UserSelect label="Payment type" name="payment_type" value={values.payment_type} onChange={handleChange}>
            <option value=""></option>
            {Object.keys(PERIOD).map((e) => {
              return (
                <option key={e} value={e}>
                  {e}
                </option>
              );
            })}
          </UserSelect>
        <UserInput label="Payment value (TJM if daily /monthly net amount if monthly)" name="payment_value" value={values.payment_value} onChange={handleChange} />
        {values.payment_type === "MONTHLY" &&  <UserInput label="Cost per month (salary only)" type="number" name="total_monthly_cost" key="total_monthly_cost" disabled={true} value={values.total_monthly_cost}/>}
        <UserInput label="TJM" name="tjm" value={values.tjm} onChange={handleChange} disabled={true}/>
        <UserInput label="TJM expense - 0 for freelancers" name="tjm_expense" value={values.tjm_expense} onChange={handleChange} disabled={true}/>
        <UserInput label="TJM vendu (TJMs)" name="tjms" value={values.tjms} onChange={handleChange} />
      </div>
      <div className="flex flex-row justify-between py-1">
        <UserInput
                label={
                    <div className="flex items-center gap-1">
                    Margin
                    <HiCurrencyEuro />
                    </div>
                }
                disabled
                value={`${margin}%`}
                className={margin < 15 ? "text-red-600" : "text-green-600"}
          />
      </div>
    </div>
  );
}

function getCostSalary(values) {
  let elements_user = []

  if ([DIVISION.LE_COLLECTIF, DIVISION.SELEGO_FR].includes(values.division)) {
    elements_user = frenchInfo(values, values.brut_salary);
  }

  if ([DIVISION.SELEGO_NL].includes(values.division)) {
    elements_user = dutchInfo(values, values.brut_salary);
  }
  if ([DIVISION.SELESPAGNE].includes(values.division)) {
    elements_user = spanishInfo(values, values.brut_salary);
  }

  let numberOfWeeksOff = 5;
  if (values.division == DIVISION.LE_COLLECTIF) numberOfWeeksOff += 5;
  if (values.division == DIVISION.SELEGO_FR) numberOfWeeksOff += 5;

  let totalDaysWorkedPerYear = values.days_worked / 4.4 * (52 - numberOfWeeksOff) - 8;
  let totalSalary = 0;

  for (let i = 0; i < elements_user.length; i++) {
    // Vérifiez si l'objet a un attribut 'key' égal à 'salary'
    if (elements_user[i].key === "salary") {
      totalSalary += elements_user[i].value;
    } else if (elements_user[i].key === "salary-reduction") {
      totalSalary -= elements_user[i].value;
    }
  }
  let totalSalaryPerDay = Math.round(totalSalary / totalDaysWorkedPerYear);

  return totalSalaryPerDay;
  // ici besoin de sommer depuis elements le brut annuel + coût entreprise
  // diviser par le nb de jours travaillés/semaine - 52 semaines - 6 semaines (5 de congés payés + 1 jours fériés)
}

function getCostExpenses(values) {

  let elements_user = []

  if ([DIVISION.LE_COLLECTIF, DIVISION.SELEGO_FR].includes(values.division)) {
    elements_user = frenchInfo(values, values.brut_salary);
  }

  if ([DIVISION.SELEGO_NL].includes(values.division)) {
    elements_user = dutchInfo(values, values.brut_salary);
  }
  if ([DIVISION.SELESPAGNE].includes(values.division)) {
    elements_user = spanishInfo(values, values.brut_salary);
  }

  let numberOfWeeksOff = 5;

  if (values.division == DIVISION.LE_COLLECTIF) numberOfWeeksOff += 5;
  if (values.division == DIVISION.SELEGO_FR) numberOfWeeksOff += 5;

  let totalDaysWorkedPerYear = values.days_worked / 4.4 * (52 - numberOfWeeksOff) - 8;
  let totalExpenses = 0;

  for (let i = 0; i < elements_user.length; i++) {
      // Vérifiez si l'objet a un attribut 'key' égal à 'salary'
      if (elements_user[i].key === "expenses") {
        // Si oui, ajoutez sa valeur à la somme
        totalExpenses += elements_user[i].value;
      }
    }

  let totalExpensesPerDay = Math.round(totalExpenses / totalDaysWorkedPerYear);

  return totalExpensesPerDay;
  // ici besoin de sommer depuis elements retraite + prévoyance + mutuelle + ordi + bureau + CEG
}

function getMonthlyTotalCost(values) {
  let elements_user = []

  if ([DIVISION.LE_COLLECTIF, DIVISION.SELEGO_FR].includes(values.division)) {
    elements_user = frenchInfo(values, values.brut_salary);
  }

  if ([DIVISION.SELEGO_NL].includes(values.division)) {
    elements_user = dutchInfo(values, values.brut_salary);
  }
  if ([DIVISION.SELESPAGNE].includes(values.division)) {
    elements_user = spanishInfo(values, values.brut_salary);
  }
  
  let numberOfWeeksOff = 5;
  if (values.division == DIVISION.LE_COLLECTIF) numberOfWeeksOff += 5;
  if (values.division == DIVISION.SELEGO_FR) numberOfWeeksOff += 5;

  let totalDaysWorkedPerYear = values.days_worked / 4.4 * (52 - numberOfWeeksOff) - 8;

  let totalCost = 0;

  for (let i = 0; i < elements_user.length; i++) {
      // Vérifiez si l'objet a un attribut 'key' égal à 'salary'
      if (elements_user[i].key === "expenses") {
        // Si oui, ajoutez sa valeur à la somme
        totalCost += elements_user[i].value;
      } else if (elements_user[i].key === "salary") {
          totalCost += elements_user[i].value;
      } else if (elements_user[i].key === "salary-reduction") {
          totalCost -= elements_user[i].value;
      }
  }

  let totalCostMonthly = Math.round(totalCost / 12);
  
  return totalCostMonthly;

}

const UserInput = ({ name, value, type = "text", onChange, label, className, disabled }) => (
  <div className={`${className} w-full`}>
    <div className="px-1 text-sm text-gray-600 font-medium">{label}</div>
    <input
      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
    />
  </div>
);

const UserSelect = ({ name, value, onChange, label, children }) => (
  <div className="w-full">
    <div className="px-1 text-sm text-gray-600 font-medium">{label}</div>
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      type="select"
      name={name}
      value={value}
      onChange={onChange}>
      {children}
    </select>
  </div>
);
  