import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import Raw from "../../../components/Raw";

import api from "../../../services/api";

import Stats from "./stats";
import Info from "./info";
import Invite from "./invite";
import Editor from "./editor";
import Applicants from "./applicants";
import Tracking from "./tracking";
import Note from "./notes";
import Question from "./question";

export default () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ok, data } = await api.get(`/job/${id}`);
        if (!ok) throw new Error("Error fetching job");
        setData(data);
      } catch (error) {
        console.error(error);
        toast.error("Error fetching job");
      }
    };
    fetchData();
  }, [id]);

  if (!data) return <Loader />;

  return (
    <div className="p-2">
      <div className="flex justify-between">
        <div className="font-semibold py-2 text-lg">{`${data.title} (#${data.index})`}</div>
      </div>
      <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
        <TabItem key="Stats" href="stats" title="Stats" />
        <TabItem key="Info" href="info" title="Info" />
        <TabItem key="Editor" href="editor" title="Editor" />
        <TabItem key="Applicants" href="" title="Applicants" />
        <TabItem key="Tracking" href="tracking" title="Tracking" />
        <TabItem key="Question" href="question" title="Question" />
        <TabItem key="Note" href="note" title="Note" />
        <TabItem key="Raw" href="raw" title="Raw" />
      </nav>
      <Routes>
        <Route path="/info" element={<Info job={data} setJob={setData} />} />
        <Route path="/stats" element={<Stats job={data} />} />
        <Route path="/invite" element={<Invite job={data} setJob={setData} />} />
        <Route path="/editor" element={<Editor job={data} setJob={setData} />} />
        <Route path="/" element={<Applicants job={data} setJob={setData} />} />
        <Route path="/tracking" element={<Tracking job={data} setJob={setData} />} />
        <Route path="/question" element={<Question job={data} setJob={setData} />} />
        <Route path="/raw" element={<Raw data={data} />} />
        <Route path="/note" element={<Note job={data} setJob={setData} />} />
      </Routes>
    </div>
  );
};

const TabItem = ({ href, title }) => {
  const { id } = useParams();
  const active = href === "" ? window.location.pathname === `/job/${id}` || window.location.pathname === `/job/${id}/` : window.location.pathname === `/job/${id}/${href}`;

  return (
    <Link to={`/job/${id}/${href}`} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
      <div className="flex items-center gap-2 truncate text-xs">{title}</div>
    </Link>
  );
};
