import React from "react";
import MultiSelect from "../../../components/MultiSelect";

export default ({ values, onChange }) => {
  return (
    <MultiSelect
      values={values}
      id="select-location"
      options={[
        { value : "Remote", label: "Remote" },
        { value : "Amsterdam", label: "Amsterdam" },
        { value : "Paris", label: "Paris" },
        { value : "Barcelona", label: "Barcelona" },
        { value : "Bangladesh", label: "Bangladesh" },
        { value : "Dublin", label: "Dublin" },
        { value : "Utrecht", label: "Utrecht" },
        { value : "Rotterdam", label: "Rotterdam" },
      
      ]}
      onSelectedChange={onChange}
      placeholder="Select a location"
    />
  );
};
