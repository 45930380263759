import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../services/api";
import FileInput from "../../../components/FileInput";
import SelectProject from "../../../components/selectProject";

export default ({ tool, setTool }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({ project_id: tool.project_id || "", project_name: tool.project_name || "" });

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/tool/${tool._id}`);
    toast.success("successfully removed!");
    navigate("/tools");
  }

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/tool/${tool._id}`, { ...tool, project_id: values.project_id, project_name: values.project_name });
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setTool(data);
      toast.success("Updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!tool) return <div />;

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-4 p-2">
        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Logo</label>
          <FileInput value={tool.logo} name="logo" folder="/logo" onChange={(e) => setTool({ ...tool, logo: e.target.value[0] })} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Name</label>
          <input type="text" className="input" placeholder="Name" value={tool.name} onChange={(e) => setTool({ ...tool, name: e.target.value })} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Link</label>
          <input type="text" className="input" placeholder="http://" value={tool.link} onChange={(e) => setTool({ ...tool, link: e.target.value })} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Identifier</label>
          <input type="text" className="input" value={tool.identifier} onChange={(e) => setTool({ ...tool, identifier: e.target.value })} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Password</label>
          <input type="text" className="input" value={tool.password} onChange={(e) => setTool({ ...tool, password: e.target.value })} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Project</label>
          <input readOnly type="text" className="input cursor-not-allowed bg-gray-200" value={tool.project_name} />
        </div>
        <div className="w-full space-y-2">
          <label className="font-semibold">Select Project</label>
          <SelectProject value={values.project_id} onChange={(e) => setValues({ ...values, project_name: e.name, project_id: e._id })} />
        </div>
        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Description</label>
          <textarea type="text" className="input" placeholder="Description" value={tool.description} rows={5} onChange={(e) => setTool({ ...tool, description: e.target.value })} />
        </div>
      </div>
      <div className="flex gap-4 justify-end">
        <button className="red-btn" onClick={onDelete}>
          Delete
        </button>
        <button className="blue-btn" onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
};
