import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "../../../components/loader";
import api from "../../../services/api";
import { toast } from "react-hot-toast";

import FileInput from "../../../components/FileInput";

export default function ({ onSave }) {
  const [debate, setDebate] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data } = await api.get(`/debate/${id}`);
    setDebate(data);
  }
  async function submit() {
    const { data } = await api.put(`/debate/${id}`, debate);
    onSave(data);
    toast.success("debate updated successfully!");
  }

  async function onDelete() {
    const { data } = await api.remove(`/debate/${id}`);
    onSave(data);
    toast.success("debate deleted successfully!");
    navigate("/debate");
  }

  const user = useSelector((state) => state.Auth.user);

  if (!debate) return <Loader />;

  return (
    <div className="p-6 w-full rounded-lg shadow-md space-y-6">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <label className="text-[14px] text-[#212325] font-medium mb-1" htmlFor="name">
            Name
          </label>
          <input
            className="w-full px-4 py-2 border  border-gray-300 rounded-md"
            placeholder="Enter debate name"
            id="name"
            value={debate.name}
            onChange={(e) => setDebate({ ...debate, name: e.target.value })}
          />
        </div>

        <div className="col-span-12">
          <label className="text-[14px] text-[#212325] font-medium mb-1" htmlFor="description">
            Description
          </label>
          <textarea
            className="w-full px-4 py-2 border  border-gray-300 rounded-md"
            rows="4"
            placeholder="Enter debate description"
            id="description"
            value={debate.description}
            onChange={(e) => setDebate({ ...debate, description: e.target.value })}
          />
        </div>

        <FileInput value={debate.image} folder="/debate" onChange={(e) => setDebate({ ...debate, image: e.target.value })} />
      </div>

      <div className="flex justify-end">
        <button
          className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          onClick={submit}>
          Save
        </button>
        <button className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
}
