import React, { useEffect, useRef, useState, useContext } from "react";
import { GrFormClose, GrRefresh, GrAttachment } from "react-icons/gr";
import { FaRegPaperPlane } from "react-icons/fa";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import styleMention from "./style-mention";
import { timeSince } from "../../utils";
import api from "../../services/api";
import { ChatContext } from "../../chat";

export default ({ ticket }) => {
  const [text, setText] = useState();
  const [messages, setMessages] = useState([]);
  const chatView = useRef(null);
  const user = useSelector((state) => state.Auth.user);
  const inputRef = useRef(null);

  const chat = useContext(ChatContext);
  const { setNotification } = useContext(ChatContext);

  useEffect(() => {
    setNotification(false);
    return () => {
      setNotification(true);
    };
  }, []);

  useEffect(() => {
    setMessages(ticket.messages || []);

    const id = chat.onNewMessage(async (value) => {
      console.log(`Received 2 at ${new Date()} for ticket ${ticket._id}`, value);

      if (value.ticket._id !== ticket._id) return; // dont receve message from other ticket
      if (value.message.user_id === user._id) return; // dont receve my own message

      console.log("UPDATE MESSAGE");
      setMessages((m) => [...m, value.message]);
      await api.put(`/ticket/read/${ticket._id}`);
    });

    // asyncrone
    api.put(`/ticket/read/${ticket._id}`);

    return () => {
      chat.clearNewMessage(id);
    };
  }, [ticket._id]);

  const onSend = async (tmp = "") => {
    const str = tmp || text;
    if (!str) return;

    const message = { text: str, sent_at: new Date(), user_name: user.name, user_id: user._id, user_avatar: user.avatar };
    setMessages([...messages, message]);
    setText("");

    const { data } = await api.post(`/ticket/message/${ticket._id}`, message);
    if (!data) return toast.error("Something went wrong!");

    // onChange(data);
  };

  useEffect(() => {
    chatView.current.scroll({ top: chatView.current.scrollHeight });
  }, [messages]);

  return (
    <div className="overflow-auto" ref={chatView}>
      <div className="space-y-4 bg-gray-50 p-4">
        {messages.map((m) => {
          const itsMe = user._id === m.user_id;
          return (
            <div key={m.sent_at} className={`flex items-start space-x-3 ${itsMe ? "flex-row-reverse" : "justify-start"}`}>
              <img className={`object-cover h-9 w-9 min-w-[36px] min-h-[36px] rounded-full mt-1 ${itsMe && "ml-3"}`} src={m.user_avatar} />
              <div>
                <div className={`${itsMe ? "bg-primary text-white" : "bg-white text-black"} rounded-lg py-3 px-4 text-sm shadow-sm inline-block`}>
                  <div className="chat-message">
                    <Message message={m} />
                    <Attachments files={m.files} />
                    <Actions actions={m.actions} onClick={(action) => onSend(action)} />
                  </div>
                </div>
                <div className={`text-[10px] text-gray-400 mt-1 ${itsMe && "text-right"}`} title={m.sent_at}>
                  {!itsMe ? `${m.user_name}, ` : ""}
                  {timeSince(m.sent_at)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <form
        className="bg-white border-t border-gray-200 flex items-center mt-auto w-full"
        onSubmit={(e) => {
          e.preventDefault();
          onSend();
        }}>
        <div className="h-8 border-r border-gray-300" />
        <input ref={inputRef} value={text} placeholder="Write your message..." onChange={(e) => setText(e.target.value)} style={styleMention}></input>
        <button type="submit" className="p-4 cursor-pointer">
          <FaRegPaperPlane className="text-2xl" />
        </button>
      </form>
    </div>
  );
};

const Actions = ({ actions, onClick }) => {
  if (!(actions || []).length) return <div />;
  return (
    <>
      <div className="font-bold mb-2 mt-6">Actions:</div>
      <div className="space-y-2">
        {actions.map((a) => {
          return (
            <button className="flex items-center space-x-2 underline" target="_blank" onClick={() => onClick(a.value)}>
              <span>{a.name}</span>
            </button>
          );
        })}
      </div>
    </>
  );
};

const Attachments = ({ files }) => {
  if (!(files || []).length) return <div />;

  function render(file) {
    if (file.url.includes(".png") || file.url.includes(".jpg") || file.url.includes(".jpeg") || file.url.includes(".gif")) {
      return <img src={file.url} className="object-contain w-3/6	" />;
    }
    return (
      <a href={f.url} className="flex items-center space-x-2 underline" target="_blank">
        <GrAttachment className="text-lg" /> <span>{f.name}</span>
      </a>
    );
  }

  return (
    <>
      <div className="font-bold mb-2 mt-6">Attachments:</div>
      <div className="space-y-2">{files.map((f) => render(f))}</div>
    </>
  );
};

const Message = ({ message }) => {
  if (message.html) {
    let html = message.html;
    if (message?.files?.length) html = message.html.replace(/cid:[^"]+/g, message?.files[0]?.url || "");
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
  let text = message.text || "";
  if ((message.tagged || []).length) {
    message.tagged.forEach((tag) => {
      text = text.replace(`@${tag.name}`, `<span className="text-primary underline bg-gray-300"> @${tag.name} </span>`);
    });
  }

  //if urls in the message, display it though a href
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  text = text.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" className="text-primary underline bg-gray-300">${url}</a>`;
  });

  return (text || "").split("\n").map((m) => <div dangerouslySetInnerHTML={{ __html: m }} />);
};
