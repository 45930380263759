import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Loader from "../../components/loader";
import Pagination from "./components/Pagination";

import api from "../../services/api";

import UserCard from "./userCard";

const ApplicantList = () => {
  const [users, setUsers] = useState(null);
  const [total, setTotal] = useState(null);
  const [filters, setFilters] = useState();

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const getusers = async () => {
    try {
      const obj = {};
      if (query.get("search")) obj.search = query.get("search");
      if (query.get("projects")) obj.project_ids = query.get("projects").split(",");
      if (query.get("jobs")) obj.job_ids = query.get("jobs").split(",");
      if (query.get("statuses")) obj.statuses = query.get("statuses").split(",");
      if (query.get("page")) obj.page = parseInt(query.get("page"));
      if (query.get("per_page")) obj.per_page = parseInt(query.get("per_page"));
      obj.sort = "-created_at";
      obj.role = "applicant";
      setFilters(obj);

      const { data } = await api.post(`/user/public/search`, obj);
      setUsers(data.users);
      setTotal(data.total);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getusers();
  }, []);

  if (!users) return <Loader />;

  return (
    <div className="px-4 pt-2 ">
      <div className="flex justify-between flex-wrap">
        <div className="flex flex-col"></div>
        <div className="flex items-center space-x-2">
          <Export filters={filters} />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 py-2">
        {users.map((applicant) => (
          <UserCard applicant={applicant} />
        ))}
      </div>
      <Pagination
        per_page={filters.per_page}
        total={total}
        onNext={() => setFilters((f) => ({ ...f, page: f.page + 1 }))}
        onPrevious={() => setFilters((f) => ({ ...f, page: f.page - 1 }))}
        currentPage={filters.page}
      />
    </div>
  );
};

const Export = ({ filters }) => {
  const onClick = async () => {
    const { data } = await api.post(`/user/public/search`, filters);

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `Index;Name;Job;Date;Resume;Link\n`;

    for (let i = 0; i < data.users.length; i++) {
      const e = data.users[i];
      const str = `${i};${e.name};${e.job_title};${e.created_at?.substring(0, 10)};${e.resume};https://accounting.selego.co/public/applicant/${e._id};\n`;
      csvContent += str;
    }

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <button className="btn btn-primary" onClick={onClick}>
      Export
    </button>
  );
};

export default ApplicantList;
