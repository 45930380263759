import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { HiMagnifyingGlass } from "react-icons/hi2";

import api from "../../services/api";
import Loader from "../../components/loader";
import DebounceInput from "react-debounce-input";

export default () => {
  const [workshops, setWorkshops] = useState([]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({ search: "", category: "", type: "", level: "", axe: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, [filters]);

  async function load() {
    try {
      setLoading(true);
      const { data } = await api.get(`/workshop?search=${filters.search}&category=${filters.category}&type=${filters.type}&level=${filters.level}&axe=${filters.axe}`);
      setLoading(false);
      setWorkshops(data);
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
    }
  }

  return (
    <div className="p-3 mx-auto px-3">
      <div>
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">Workshops</h3>
          <Create />
        </div>
        <div className="mt-2">
          <div className="flex gap-2">
            <div className="relative rounded-md">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <DebounceInput
                debounceTimeout={300}
                name="email"
                id="email"
                className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                placeholder="Search"
                value={filters.search}
                onChange={(e) => {
                  e.persist();
                  setFilters((f) => ({ ...f, search: e.target.value }));
                }}
              />
            </div>
            <select
              className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
              value={filters.category}
              onChange={(e) => {
                e.persist();
                setFilters((f) => ({ ...f, category: e.target.value }));
              }}>
              <option value="" label="All category">
                All category
              </option>
              <option value="challenge">challenge</option>
              <option value="lesson">lesson</option>
              <option value="profil">profil</option>
              <option value="admin">admin</option>
              <option value="draft">draft</option>
              <option value="mortem">mortem</option>
            </select>
            <select
              className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
              value={filters.type}
              onChange={(e) => {
                e.persist();
                setFilters((f) => ({ ...f, type: e.target.value }));
              }}>
              <option value="" label="All type">
                All type
              </option>
              <option value="book">book</option>
              <option value="tutorial">tutorial</option>
              <option value="mooc">mooc</option>
              <option value="other">other</option>
            </select>
            <select
              className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
              value={filters.level}
              onChange={(e) => {
                e.persist();
                setFilters((f) => ({ ...f, level: e.target.value }));
              }}>
              <option value="" label="All level">
                All level
              </option>
              <option value="beginner">beginner</option>
              <option value="intermediate">intermediate</option>
              <option value="advanced">advanced</option>
            </select>
            <select
              className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
              value={filters.axe}
              onChange={(e) => {
                e.persist();
                setFilters((f) => ({ ...f, axe: e.target.value }));
              }}>
              <option value="" label="All axe">
                All axe
              </option>
              <option value="code">code</option>
              <option value="leadership">leadership</option>
              <option value="communication">communication</option>
              <option value="business">business</option>
              <option value="productivity">productivity</option>
              <option value="investment">investment</option>
              <option value="product">product</option>
              <option value="gtm">gtm</option>
              <option value="data">data</option>
            </select>
          </div>
          <div className="my-1 text-xs text-gray-600">{workshops?.length} result(s)</div>
          {workshops === null || loading ? (
            <Loader />
          ) : (
            <div className="relative w-[calc(100vw_-_220px)] overflow-x-auto">
              <div className="bg-white inline-block min-w-full align-middle">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                        Category
                      </th>
                      <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                        Name
                      </th>
                      <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                        Type
                      </th>
                      <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                        Level
                      </th>
                      <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                        Axe
                      </th>
                      <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                        Created by
                      </th>
                      <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                        Created at
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {workshops.map((workshop) => {
                      return (
                        <tr key={workshop._id} className="even:bg-gray-100 cursor-pointer hover:bg-blue-100" onClick={() => navigate(`/learn-editor/${workshop._id}`)}>
                          <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{workshop.category || <Empty />}</td>
                          <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{workshop.name || <Empty />}</td>
                          <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{workshop.type || <Empty />}</td>
                          <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{workshop.level || <Empty />}</td>
                          <td className="whitespace-nowrap py-3 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{workshop.axe || <Empty />}</td>
                          <td className="whitespace-nowrap py-3 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{workshop.user_name || <Empty />}</td>
                          <td className="whitespace-nowrap py-3 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{new Date(workshop.created_at).toLocaleString()}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Empty = () => <span className="px-3 py-1 bg-gray-50 text-gray-400 rounded-lg border-[1px] border-gray-100 text-xs">Empty</span>;

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  async function onSubmit() {
    const { data } = await api.post(`/workshop`, { ...values });
    toast.success("Workshop created successfully");
    setOpen(false);
    navigate(`/learn-editor/${data._id}`);
  }

  return (
    <div style={{ marginBottom: 10 }}>
      <div className="text-right">
        <button className="bg-[#0560FD] text-[#fff] py-[12px] px-[22px] h-[48px]	rounded-[10px] text-[16px] font-medium" onClick={() => setOpen(true)}>
          Create new workshop
        </button>
      </div>
      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div
            className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <React.Fragment>
              <div>
                <div className="flex justify-between flex-wrap">
                  <div className="w-full md:w-[48%] mt-2">
                    <div className="text-[14px] text-[#212325] font-medium">Name</div>
                    <input
                      className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                      name="name"
                      value={values.name}
                      onChange={(e) => setValues({ name: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <br />
              <button className="mt-[1rem]  bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onSubmit}>
                Save
              </button>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </div>
  );
};
