import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

import Table from "../../../../components/Table";
import DebounceInput from "../../../../components/DebounceInput";
import EpicEditModal from "./EpicModal";
import EpicCreationModal from "./EpicCreationModal";
import api from "../../../../services/api";
import Modal from "../../../../components/modal";
import MultiSelect from "../../../../components/MultiSelect";
import { useSelector } from "react-redux";

export default ({ project }) => {
  const [epics, setEpics] = useState([]);
  const [filters, setFilters] = useState({ page: 1, search: "" });
  const [selectedEpics, setSelectedEpics] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [isEpicEditModalOpen, setIsEpicEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    fetchEpics();
  }, [filters]);

  const fetchEpics = async () => {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/epic/search", { project_id: project._id, ...filters });
      if (!ok) return toast.error("Failed to fetch roadmaps");
      // sort by created_at ( date value)
      // data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      // sort on module
      data.sort((a, b) => (a.module || "").localeCompare(b.module || ""));
      setEpics(data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch roadmaps");
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete selected epics?")) return;

      for (let i = 0; i < selectedEpics.length; i++) {
        const { ok } = await api.remove(`/epic/${selectedEpics[i]._id}`);
        if (!ok) {
          toast.error(`Failed to delete epic ${selectedEpics[i]._id}`);
          continue;
        }
      }

      toast.success("Epics deleted successfully");
      fetchEpics();
      setSelectedEpics([]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete epics");
    }
  };

  const totalNow = epics.filter((e) => e.priority === "NOW");
  const totalNext = epics.filter((e) => e.priority === "NEXT");
  const totalLater = epics.filter((e) => e.priority === "LATER");

  function selectEpic(e) {
    setSelectedEpic(e);
    setIsEpicEditModalOpen(true);
  }

  return (
    <div>
      <div className="bg-white p-4 rounded border border-gray-300 mb-4">
        <div className="flex items-center gap-2 mb-2">
          {selectedEpics.length > 0 && <span className="text-gray-500 text-sm">Selected {selectedEpics.length} epics</span>}
          {selectedEpics.length > 0 && (
            <>
              <button onClick={handleMultipleDelete} className="red-btn">
                Delete
              </button>
              <BatchEditAssigned selectedEpics={selectedEpics} onUpdate={fetchEpics} project={project} />
              <ExportButton data={selectedEpics} />
              <QuoteLinking data={selectedEpics} />
            </>
          )}
        </div>
        <div className="flex items-start gap-4">
          <div className="w-full flex justify-between">
            <div className="flex flex-row gap-2">
              <DebounceInput
                debounce={300}
                className="input w-full"
                placeholder="Search by title..."
                value={filters.search}
                onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
              />
              <SelectModule value={filters.module} onChange={(e) => setFilters((f) => ({ ...f, module: e }))} project={project} />
              <MultiSelect
                id="select-resource-profile"
                options={["DEVELOPER", "EXPERIMENTED DEVELOPER", "CTO", "PRODUCT"].map((resourceProfile) => ({ value: resourceProfile, label: resourceProfile }))}
                value={filters.resource_profile?.map((resourceProfile) => ({ value: resourceProfile, label: resourceProfile })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, resource_profile: e.map((e) => e.value) }))}
                placeholder="Resource"
              />
              <MultiSelect
                id="select-priority"
                options={["NOW", "NEXT", "LATER"].map((priority) => ({ value: priority, label: priority }))}
                value={filters.priorities?.map((priority) => ({ value: priority, label: priority })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, priorities: e.map((e) => e.value) }))}
                placeholder="Priority"
              />
              <SelectAssigned value={filters.assigned} onChange={(e) => setFilters((f) => ({ ...f, assigned: e }))} project={project} />
            </div>
            <div>
              <EpicCreationModal
                project={project}
                onCreate={(e) => {
                  fetchEpics();
                  setSelectedEpic(e);
                  setIsEpicEditModalOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isEpicEditModalOpen && (
        <EpicEditModal
          epic={selectedEpic}
          onClose={(e) => {
            if (!selectedEpic) return;
            const index = epics.findIndex((item) => item._id === selectedEpic._id);
            const newEpics = [...epics];
            newEpics[index] = { ...newEpics[index], ...e };
            setEpics(newEpics);
            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
          }}
        />
      )}
      <Table
        total={epics.length}
        header={[
          {
            title: (
              <input
                type="checkbox"
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedEpics(epics);
                  } else {
                    setSelectedEpics([]);
                  }
                }}
              />
            ),
            position: "center",
          },
          { title: "#", key: "index" },
          { title: "Personae", key: "tags" },
          { title: "Module", key: "tags" },
          { title: "Title", key: "title" },
          { title: "Days Required", key: "days_required" },
          { title: "Resource Profile", key: "resource_profile" },
          { title: "Estimation", key: "estimation" },
          { title: "Priority", key: "priority" },
          { title: "Assigned", key: "assigned" },
        ]}
        loading={loading}
        height="h-[32rem]"
        onSort={(e) => {
          toast.success("TODO");
          // if (e === "tags") {
          //   const arr = [...epics].sort((a, b) => a.tags.join("").localeCompare(b.tags.join("")));
          //   setEpics(arr);
          //   toast.success("Sorted by tags");
          // }
        }}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">No results found</div>}>
        <AnimatePresence>
          {epics.map((item, index) => {
            const selected = selectedEpics.some((e) => e._id === item._id);

            let color,
              priorityColor = "bg-white";
            if (item.priority === "NEXT") {
              color = "bg-blue-200";
              priorityColor = "bg-blue-300";
            }
            if (item.priority === "LATER") {
              priorityColor = "bg-gray-300";
              color = "bg-gray-200";
            }

            return (
              <motion.tr
                key={item._id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className={`cursor-pointer hover:brightness-95 ${color}`}>
                <td
                  className="border px-4 py-2 cursor-pointer"
                  onClick={(e) => {
                    const find = selectedEpics.some((e) => e._id === item._id);
                    if (find) {
                      setSelectedEpics(selectedEpics.filter((e) => e._id !== item._id));
                    } else {
                      setSelectedEpics([...selectedEpics, item]);
                    }
                  }}>
                  <input type="checkbox" className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500" checked={selected} onChange={() => {}} />
                </td>
                <td onClick={() => selectEpic(item)} className="border px-4 py-2 max-w-xs truncate text-sm">
                  {item.index}
                </td>
                <td onClick={() => selectEpic(item)} className="border px-4 py-2 max-w-xs truncate text-sm">
                  {item.persona}
                </td>
                <td onClick={() => selectEpic(item)} className="border px-4 py-2 max-w-xs truncate text-sm">
                  {item.module}
                </td>
                <SelectedTd
                  value={item.title}
                  onChange={async (e) => {
                    const { ok, data } = await api.put(`/epic/${item._id}`, { title: e });
                    if (!ok) return toast.error("Failed to update title");
                    setEpics(epics.map((epic) => (epic._id === item._id ? data : epic)));
                    toast.success("Title updated!");
                  }}
                  type="text"
                />
                <SelectedTd
                  value={item.days_required}
                  onChange={async (e) => {
                    const tjm = item.tjm || 500;
                    const estimation = (e * tjm).toFixed(2);
                    const { ok, data } = await api.put(`/epic/${item._id}`, { days_required: e, estimation });
                    if (!ok) return toast.error("Failed to update days_required");
                    setEpics(epics.map((e) => (e._id === item._id ? data : e)));
                    toast.success("days_required updated!");
                  }}
                  type="number"
                />
                <td onClick={(e) => e.preventDefault()} className="border px-4 py-2 max-w-xs truncate">
                  <select
                    value={item.resource_profile}
                    onChange={async (e) => {
                      let tjm = 500;
                      if (e.target.value === "CTO") {
                        tjm = 650;
                      } else if (e.target.value === "EXPERIMENTED DEVELOPER") {
                        tjm = 500;
                      } else if (e.target.value === "DEVELOPER") {
                        tjm = 350;
                      } else if (e.target.value === "PRODUCT") {
                        tjm = 500;
                      }
                      const estimation = (item.days_required * tjm).toFixed(2);
                      const { ok, data } = await api.put(`/epic/${item._id}`, { resource_profile: e.target.value, tjm, estimation });
                      if (!ok) return toast.error("Failed to update resource_profile");
                      setEpics(epics.map((e) => (e._id === item._id ? data : e)));
                      toast.success("resource_profile updated!");
                    }}
                    className={`input w-fit bg-transparent`}>
                    <option value="DEVELOPER">Developer</option>
                    <option value="EXPERIMENTED DEVELOPER">Experimented Developer</option>
                    <option value="CTO">CTO</option>
                    <option value="PRODUCT">Product</option>
                  </select>
                </td>
                <td onClick={() => selectEpic(item)} className="border px-4 py-2 max-w-xs truncate hover:bg-gray-200">
                  {item.estimation ? `${formatNumberThreeDigits(item.estimation)} €` : ""}
                </td>
                <td onClick={(e) => e.preventDefault()} className="border px-4 py-2 max-w-xs truncate">
                  <select
                    value={item.priority}
                    onChange={async (e) => {
                      const { ok, data } = await api.put(`/epic/${item._id}`, { priority: e.target.value });
                      if (!ok) return toast.error("Failed to update priority");
                      setEpics(epics.map((e) => (e._id === item._id ? data : e)));
                      toast.success("Priority updated!");
                    }}
                    className={`input ${priorityColor} mr-5`}>
                    <option value="NOW">NOW</option>
                    <option value="NEXT">NEXT</option>
                    <option value="LATER">LATER</option>
                  </select>
                </td>
                <td onClick={(e) => e.preventDefault()} className="border px-4 py-2 max-w-xs truncate">
                  <DebounceInput
                    type="text"
                    list="assigned-options"
                    debounce={300}
                    value={item.assigned || ""}
                    onChange={async (e) => {
                      const { ok, data } = await api.put(`/epic/${item._id}`, { assigned: e.target.value });
                      if (!ok) return toast.error("Failed to update assigned");
                      setEpics(epics.map((epic) => (epic._id === item._id ? data : epic)));
                      toast.success("Assigned updated!");
                    }}
                    className="input w-fit bg-transparent"
                    placeholder="Assign to..."
                  />
                  <datalist id="assigned-options">
                    {epics
                      .map((e) => e.assigned)
                      .filter((value, index, self) => value && self.indexOf(value) === index)
                      .map((assignedValue) => (
                        <option key={assignedValue} value={assignedValue} />
                      ))}
                  </datalist>
                </td>
              </motion.tr>
            );
          })}
        </AnimatePresence>
        <tr>
          <td className="my-2 bg-gray-100" colSpan="8">
            &nbsp;
          </td>
        </tr>
        <tr className="">
          <td colSpan="3"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalNow.length} epics</td>
          <td className="border px-4 py-2 whitespace-normal text-sm">
            Short-term features that are priority and MUST for an MVP. These are the key features of a product in addition to the basics (admin + deployment + metabase). The
            features that will be developed in the MVP.{" "}
          </td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalNow.reduce((acc, item) => acc + (item.days_required || 0), 0).toFixed(2)}</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{formatNumberThreeDigits(totalNow.reduce((acc, item) => acc + (item.estimation || 0), 0))} €</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">NOW</td>
        </tr>

        <tr className="bg-blue-200">
          <td colSpan="3"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalNext.length} epics</td>
          <td className="border px-4 py-2 whitespace-normal text-sm">The medium-term functionalities which enrich the product, without these the product already works. </td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalNext.reduce((acc, item) => acc + (item.days_required || 0), 0).toFixed(2)}</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{formatNumberThreeDigits(totalNext.reduce((acc, item) => acc + (item.estimation || 0), 0))} €</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">NEXT</td>
        </tr>

        <tr className="bg-gray-200">
          <td colSpan="3"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalLater.length} epics</td>
          <td className="border px-4 py-2 whitespace-normal text-sm">Long-term functionalities are functionalities which are not defined and difficult to estimate.</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalLater.reduce((acc, item) => acc + (item.days_required || 0), 0)}</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{formatNumberThreeDigits(totalLater.reduce((acc, item) => acc + (item.estimation || 0), 0))} €</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">LATER</td>
        </tr>
        <tr className="bg-sky-700 text-white font-bold">
          <td colSpan="3"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{epics.length} epics</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{epics.reduce((acc, item) => acc + (item.days_required || 0), 0).toFixed(2)}</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{formatNumberThreeDigits(epics.reduce((acc, item) => acc + (item.estimation || 0), 0))} €</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">Total</td>
        </tr>
      </Table>
    </div>
  );
};

const SelectedTd = ({ value, onChange, type = "text" }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [originalValue, setOriginalValue] = useState(value);

  return (
    <td
      className={`border px-4 py-2 max-w-xs truncate relative ${isHovered ? "border-2 border-gray-400 rounded-lg" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {isEditing ? (
        <input
          type={type}
          className="w-full p-1 border rounded text-sm"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          autoFocus
          onBlur={() => {
            setIsEditing(false);
            if (inputValue !== originalValue) {
              onChange(inputValue);
              setOriginalValue(inputValue);
            } else {
              setInputValue(originalValue);
            }
          }}
        />
      ) : (
        <>
          <span className="whitespace-normal text-sm">{originalValue}</span>
          {isHovered && (
            <button className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700" onClick={() => setIsEditing(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
              </svg>
            </button>
          )}
        </>
      )}
    </td>
  );
};

const formatNumberThreeDigits = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const ExportButton = ({ data }) => {
  const cols = [
    "module",
    "title",
    "resource_profile",
    "business_objective",
    "charge",
    "business_contribution",
    "urgent",
    "module",
    "points",
    "estimation",
    "days_required",
    "status",
    "priority",
    "comments",
  ];
  const downloadCSV = () => {
    console.log("data", data);
    const csvData = [cols.map((column) => column)];
    data.forEach((item) => {
      const rowData = cols.map((column) => {
        if (column === "comments")
          return `"${item["comments"]
            .map((e) => {
              //remove html
              return e.text.replace(/<[^>]*>?/gm, "");
            })
            .join("\n")
            .replace(/"/g, '""')}"`;
        const value = item[column];
        return `"${value ? String(value).replace(/"/g, '""') : ""}"`;
      });
      csvData.push(rowData);
    });

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "exported_data.csv";
    link.click();
  };

  return (
    <button onClick={downloadCSV} className="gray-btn">
      Export CSV
    </button>
  );
};

const QuoteLinking = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [search, setSearch] = useState("");
  const [quote, setQuote] = useState({});
  const navigate = useNavigate();
  const [startPosition, setStartPosition] = useState("");
  const [quotePages, setQuotePages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.post("/quote/search", { search });
        setQuotes(data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch quotes");
      }
    };
    fetchData();
  }, [search]);

  useEffect(() => {
    const fetchPages = async () => {
      if (!quote._id) return;
      const { data } = await api.post("/quote_page/search", { quote_id: quote._id });
      setQuotePages(data);
    };
    fetchPages();
  }, [quote._id]);

  const handleLink = async () => {
    try {
      // Split data into chunks of 15
      const chunks = [];
      for (let i = 0; i < data.length; i += 15) {
        chunks.push(data.slice(i, i + 15));
      }

      // If startPosition is provided, use it, otherwise get last position
      let lastPosition;
      if (startPosition) {
        lastPosition = parseInt(startPosition) - 1;

        // Shift existing pages to make room
        const pagesToShift = quotePages.filter((p) => p.position >= lastPosition + 1);
        for (const page of pagesToShift.reverse()) {
          const newPosition = page.position + chunks.length;
          await api.put(`/quote_page/${page._id}`, { position: newPosition });
        }
      } else {
        const resLP = await api.post("/quote_page/search", {
          quote_id: quote._id,
          sort: { position: -1 },
          limit: 1,
        });
        if (!resLP.ok) throw resLP;
        lastPosition = resLP.data[0]?.position || 0;
      }

      // Create a page and block for each chunk
      for (const chunk of chunks) {
        lastPosition += 1;
        const page = {
          position: lastPosition,
          format: "A4",
          quote_id: quote._id,
          quote_name: quote.name,
          background_image_url: "https://bank.cellar-c2.services.clever-cloud.com/filebgImage/9146709e9582992ec6bc4910ed545904/background.png",
        };

        const resP = await api.post("/quote_page", page);
        if (!resP.ok) throw resP;

        const block = {
          type: "table",
          quote_id: quote._id,
          quote_name: quote.name,
          quote_page_id: resP.data._id,
          isTemplate: false,
          arr: chunk.map((item) => ({
            description: {
              fr: `<div>
                ${item.module ? `<div class="text-sm text-gray-500">${item.module}</div>` : ""}
                <div class="font-semibold">${item.title}</div>
                ${item.business_objective ? `<div class="text-sm text-gray-600">${item.business_objective}</div>` : ""}
              </div>`,
            },
            price: item.estimation / (item.days_required || 1),
            min_quantity: item.days_required,
            max_quantity: item.days_required,
            min_total: item.estimation,
            max_total: item.estimation,
            tax: 0,
            quantity: item.days_required,
            total: item.estimation,
          })),
        };

        const resB = await api.post("/quote_block", block);
        if (!resB.ok) throw resB;
      }

      toast.success(`Created ${chunks.length} pages with ${data.length} items`);

      if (window.confirm(`Epics linked to quote across ${chunks.length} pages!\n\nDo you want to be redirected to the quote page?\nCancel to stay on this page.`)) {
        navigate(`/quote/${quote._id}`);
      }
      setOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to link quote");
    }
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="gray-btn">
        Link to Quote
      </button>

      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <div className="p-12 space-y-6">
          <h2 className="text-xl font-bold">Link to Quote</h2>

          <div className="space-y-2">
            <h3 className="text-lg font-bold">Search for a quote</h3>
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="input" placeholder="Search..." />
            <div className="overflow-scroll h-[20rem] space-y-1">
              {quotes.map((item, i) => (
                <button key={i} className={`w-full ${item._id === quote._id ? "blue-btn" : "empty-btn"}`} onClick={() => (quote._id === item._id ? setQuote({}) : setQuote(item))}>
                  <div className="flex-1">{item.name}</div>
                  {item._id === quote._id && <IoCheckmarkCircle className="text-green-500" />}
                </button>
              ))}
            </div>
          </div>

          {quote._id && (
            <div className="space-y-2">
              <h3 className="text-lg font-bold">Insert Position</h3>
              <select value={startPosition} onChange={(e) => setStartPosition(e.target.value)} className="input w-full">
                <option value="">Add to end</option>
                {quotePages
                  .sort((a, b) => a.position - b.position)
                  .map((page, index) => (
                    <option key={page._id} value={page.position}>
                      Insert before page {page.position}
                    </option>
                  ))}
              </select>
            </div>
          )}

          <div className="flex justify-end">
            <button onClick={handleLink} className="blue-btn w-48" disabled={!quote._id}>
              Link
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const SelectAssigned = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchAssigned() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "assigned" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    fetchAssigned();
  }, [project._id]);

  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchAssigned();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-assigned"
      options={options}
      value={value ? [{ value, label: value }] : []}
      onSelectedChange={(e) => onChange(e[0]?.value || null)}
      placeholder="Assigned"
    />
  );
};

const SelectModule = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchModules() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "module" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    // Initial fetch when component mounts
    fetchModules();
  }, [project._id]);

  // Refetch when component gains focus if more than 5 seconds have passed
  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchModules();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-module"
      options={options}
      value={value ? [{ value: value, label: value }] : []}
      onSelectedChange={(e) => onChange(e[0]?.value || null)}
      placeholder="Module"
    />
  );
};

const SelectTags = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchTags() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    // Initial fetch when component mounts
    fetchTags();
  }, [project._id]);

  // Refetch when component gains focus if more than 5 seconds have passed
  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchTags();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-status"
      options={options}
      value={value?.map((tag) => ({ value: tag, label: tag }))}
      onSelectedChange={(e) => onChange(e.map((e) => e.value))}
      placeholder="Tags"
    />
  );
};
const BatchEditAssigned = ({ selectedEpics, onUpdate, project }) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  // Fetch existing assigned values
  useEffect(() => {
    async function fetchAssigned() {
      const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "assigned" });
      setOptions(data.filter((e) => e._id !== null).map((e) => e._id));
    }
    fetchAssigned();
  }, [project._id]);

  const handleChange = async (value) => {
    try {
      // Only proceed if the value exists in options
      if (!options.includes(value)) {
        toast.error("Please select an existing assigned value");
        setInputValue("");
        return;
      }

      for (const epic of selectedEpics) {
        const { ok } = await api.put(`/epic/${epic._id}`, { assigned: value });
        if (!ok) {
          toast.error(`Failed to update assigned for epic ${epic._id}`);
          return;
        }
      }
      toast.success("Successfully updated assigned value for selected epics");
      setInputValue("");
      onUpdate();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update epics");
    }
  };

  return (
    <div className="relative">
      <select
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          handleChange(e.target.value);
        }}
        className="input w-32">
        <option value="">Batch assign...</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
