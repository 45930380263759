import React, { useEffect, useState } from "react";
import api from "../../services/api";

import SelectMonth from "../../components/selectMonth";
import { ResponsivePie } from "@nivo/pie";
import { getDaysInMonth, getDaysInMonthSoFar } from "../../utils";
const Availability = ({ user }) => {
  const [date, setDate] = useState(null);

  return (
    <div className="w-screen md:w-full">
      <div className="flex flex-wrap gap-5 p-2 md:!px-8">
        <SelectMonth start={-3} indexDefaultValue={3} value={date} onChange={(e) => setDate(e.target.value)} showArrows />
      </div>
      {date && user && <AvailabilityGraph date={new Date(date)} user={user} />}
    </div>
  );
};

const AvailabilityGraph = ({ user, date }) => {
  const [activities, setActivities] = useState([]);
  const days = getDaysInMonthSoFar(date.getMonth(), date.getFullYear());

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/activity?date=${date.getTime()}&userId=${user._id}`);
      setActivities(data);
    })();
  }, [date, user]);

  const getWorkingDays = () => {
    return days.reduce((acc, a) => {
      const day = a.getDay();
      if (day === 0 || day == 6) return acc;
      return acc + 1;
    }, 0);
  };
  const getPayingProjectsTotal = () =>
    (activities.reduce((acc, a) => acc + (a.projectType !== "prospection" && a.projectType !== "admin" && a.projectType !== "startup-invest" ? a.total : 0), 0) / 8).toFixed(2);

  const nonPayingProjectsTotal = () =>
    (activities.reduce((acc, a) => acc + (a.projectType === "prospection" || a.projectType === "admin" || a.projectType === "startup-invest" ? a.total : 0), 0) / 8).toFixed(2);

  const payingHours = parseFloat(getPayingProjectsTotal());
  const nonPayingHours = parseFloat(nonPayingProjectsTotal());
  const nonOccupiedHours = parseFloat(getWorkingDays()) - (payingHours + nonPayingHours);
  const totalHours = parseFloat(getWorkingDays());

  console.log(payingHours, nonPayingHours, totalHours);

  const data = [
    {
      id: "Paying",
      label: "Paying",
       // Convert to percentage rounded to 2 decimal places
      value: parseFloat(((payingHours / totalHours) * 100).toFixed(2)),
    },
    {
      id: "Non-Paying",
      label: "Non-Paying",
      value: parseFloat(((nonPayingHours / totalHours) * 100).toFixed(2)), // Convert to percentage rounded to 2 decimal places
        },
    {
      id: "Non-Occupied",
      label: "Non-Occupied",
      value:  parseFloat(((nonOccupiedHours / totalHours) * 100).toFixed(2)), // Convert to percentage rounded to 2 decimal places
    },
  ];

  return (
    <>
      <div style={{ height: "600px", width: "600px" }}>
        <ResponsivePie
          data={data}
          key={data.id}
          margin={{ top: 0, right: 80, bottom: 80, left: 200 }}
          innerRadius={0.5}
          width={600}
            height={600}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
        />
      </div>
    </>
  );
};

export default Availability;
