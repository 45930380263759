import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../services/api";
import RichTextEditor from "../../../components/RichTextEditor";

export default ({ agent, setAgent }) => {
  const navigate = useNavigate();

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/agent/${agent._id}`);
    toast.success("successfully removed!");
    navigate("/agents");
  }

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/agent/${agent._id}`, agent);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setAgent(data);
      toast.success("Updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!agent) return <div />;

  return (
    <div className="space-y-8">
      <div className="">
        <div className="w-full mt-2 grid grid-cols-2 gap-4">
          <div>
            <label className="font-semibold">Name</label>
            <input type="text" className="input" value={agent.name} onChange={(e) => setAgent({ ...agent, name: e.target.value })} />
          </div>
          <div>
            <button
              className="blue-btn mt-2"
              onClick={async () => {
                toast.success("Training starting ...");
                await api.put(`/agent/${agent._id}/train`);
                toast.success("Training end!");
              }}>
              Train
            </button>
          </div>
        </div>
        <div className="w-full mt-2">
          <label className="font-semibold">Job title</label>
          <input type="text" className="input" value={agent.job_title} onChange={(e) => setAgent({ ...agent, job_title: e.target.value })} />
        </div>
        <div className="w-full mt-2">
          <label className="font-semibold">Assistant ID</label>
          <input type="text" className="input" value={agent.assistant_id} onChange={(e) => setAgent({ ...agent, assistant_id: e.target.value })} />
        </div>

        <div className="w-full mt-2">
          <label className="font-semibold">Model</label>
          {agent.model && <input type="text" disabled className="input" value={agent.model} />}
          <button
            className="blue-btn mt-2"
            onClick={async () => {
              const { ok, data } = await api.put(`/agent/${agent._id}/model`, { model: "gpt-4o" });
              if (!ok) return toast.error("Failed to update model");
              setAgent(data);
              toast.success("Model updated !");
            }}>
            Put default GPTo model
          </button>
        </div>

        <div className="w-full grid grid-cols-2 gap-4 mt-4">
          <div className="col-span-1">
            <label className="font-semibold">Description</label>
            <RichTextEditor
              buttonList={[
                ["font", "fontSize", "formatBlock"],
                ["bold", "underline", "italic"],
                ["fontColor", "hiliteColor"],
                ["align", "list", "lineHeight"],
                ["outdent", "indent"],
                ["link"],
                ["removeFormat"],
              ]}
              values={agent.description}
              options={{ minHeight: "250px", backGorundColor: "white" }}
              onChange={(e) => setAgent({ ...agent, description: e })}
            />
          </div>
          <div className="col-span-1">
            <label className="font-semibold">Prompt</label>
            <RichTextEditor
              buttonList={[
                ["font", "fontSize", "formatBlock"],
                ["bold", "underline", "italic"],
                ["fontColor", "hiliteColor"],
                ["align", "list", "lineHeight"],
                ["outdent", "indent"],
                ["link"],
                ["removeFormat"],
              ]}
              values={agent.prompt}
              options={{ minHeight: "250px", backGorundColor: "white" }}
              onChange={(e) => setAgent({ ...agent, prompt: e })}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 justify-end">
        <button className="red-btn" onClick={onDelete}>
          Delete
        </button>
        <button className="blue-btn" onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
};
