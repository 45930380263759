import React, { useEffect, useState } from "react";
import api from "../../services/api";
import FolkModal from "../folk/folkModal";

export default ({ contact }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [selectedFolk, setSelectedFolk] = useState();

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    try {
      setLoading(true);
      const res = await api.post("/folk/stats");
      setData(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  }

  if (loading) return <div>Loading...</div>;


  console.log("data", data);
  return (
    <div className="overflow-x-auto">
      <FolkModal onClose={() => setSelectedFolk(null)} contact={selectedFolk} />
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="bg-sky-900" />
            {data[0].months.map((month, index) => {
              const formattedDate = new Date(month.date).toLocaleDateString("fr-FR", { month: "long", year: "numeric" });
              return (
                <th key={index} className="border border-gray-300 py-2 px-4 min-w-[200px] bg-sky-900 text-sm font-normal text-white">
                  {formattedDate}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => {
            return (
              <>
                <tr key="days">
                  <td className="border border-gray-300 py-2 px-4 bg-sky-900 text-white text-sm whitespace-nowrap">{row["user_name"]}</td>
                  {row.months.map((value, monthIndex) => {
                    return <td key={monthIndex} className="border border-gray-300 py-2 px-4 bg-sky-900" />;
                  })}
                </tr>
                <tr key="days">
                  <td className="border border-gray-300 py-2 px-4 text-sm">Days</td>
                  {row.months.map((value, monthIndex) => {
                    return (
                      <td key={monthIndex} className="border border-gray-300 py-2 px-4">
                        {`${value.days} days`}
                      </td>
                    );
                  })}
                </tr>
                <tr key="leads">
                  <td className="border border-gray-300 py-2 px-4 text-sm">{"Leads (contacts above discovery)"}</td>
                  {row.months.map((value, monthIndex) => {
                    return (
                      <td key={monthIndex} className="border border-gray-300">
                        <div className="flex flex-col gap-1 max-h-[400px] overflow-y-scroll">
                          {value.leads.map((e) => {
                            return (<Item folk={e} onClick={() => setSelectedFolk(e)} />);
                          })}
                        </div>
                      </td>
                    );
                  })}
                </tr>
                <tr key="won">
                  <td className="border border-gray-300 py-2 px-4 text-sm">Won leads</td>
                  {row.months.map((value, monthIndex) => {
                    return (
                      <td key={monthIndex} className="border border-gray-300">
                        {value.wons.map((e) => {
                          return (<Item folk={e} onClick={() => setSelectedFolk(e)} />);
                        })}
                      </td>
                    );
                  })}
                </tr>
                <tr key="lost">
                  <td className="border border-gray-300 py-2 px-4 text-sm">Lost leads</td>
                  {row.months.map((value, monthIndex) => {
                    return (
                      <td key={monthIndex} className="border border-gray-300">
                        {value.losts.map((e) => {
                          return (<Item folk={e} onClick={() => setSelectedFolk(e)} />);
                        })}
                      </td>
                    );
                  })}
                </tr>
                <tr key="budget">
                  <td className="border border-gray-300 py-2 px-4 text-sm">CA generated</td>
                  {row.months.map((value, monthIndex) => {
                    const total = value.wons.reduce((acc, e) => acc + e.budget, 0);
                    const totalLeads = value.leads.reduce((acc, e) => (e.budget ? acc + e.budget : acc), 0);
                    // const percentage = ((total / totalLeads) * 100).toFixed(2);
                    return (
                      <td key={monthIndex} className="border border-gray-300">
                        <div className="flex flex-col items-end p-2">
                          <span className="text-sm">Total leads : {totalLeads}€</span>
                          <span className="border border-yellow-200 bg-yellow-50 text-yellow-800 px-2 text-sm font-semibold">{total}€</span>
                          {/* <span className="border border-yellow-200 bg-yellow-50 text-yellow-800 px-2 text-xs font-semibold">{percentage}%</span> */}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};


const Item = ({ folk, onClick }) => {
  const label = folk.company || `${folk.first_name} ${folk.last_name}`;
  const budget = folk.budget;
  const type = folk.type === "UPSELL" ? "U" : "C"

  return (
    <div onClick={onClick} className="w-full flex justify-between items-center gap-2 odd:bg-gray-100 p-2 cursor-pointer">
      <div>
        <span className="text-sm">{label}</span>
        <span className={`ml-2 border px-2 text-xs ${type === "C" ? "border-purple-200 bg-purple-50 text-purple-800" : "border-orange-200 bg-orange-50 text-orange-800"}`}>{type}</span>
      </div>
      <span className="border border-purple-200 bg-purple-50 text-purple-800 px-2 text-xs">{budget ? `${budget}€` : "N/A"}</span>
    </div>
  )
}