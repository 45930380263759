import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import "@nxu/ninja-keys";
import API from "../../services/api";
import { FaSearch } from "react-icons/fa";

export default function CommandPalette() {
  const { user } = useSelector((state) => state.Auth);
  const navigate = useNavigate();
  const ninjaKeys = useRef(null);
  let debounceTimeout = null;

  const menu = [
    { id: "Dashboard", title: "Dashboard", children: ["home", "support", "projects", "perks", "components", "activities", "people"] },
    { id: "home", parent: "Dashboard", title: "Home", handler: () => navigate("/"), roles: ["admin", "user", "applicant", "client"] },
    { id: "support", parent: "Dashboard", title: "Support", handler: () => navigate("/message"), roles: ["admin", "user", "applicant", "client"] },
    { id: "projects", parent: "Dashboard", title: "Projects", handler: () => navigate("/project"), roles: ["admin", "user", "client"] },
    { id: "perks", parent: "Dashboard", title: "Perks", handler: () => navigate("/perks"), roles: ["admin", "user"] },
    { id: "components", parent: "Dashboard", title: "Components Library", handler: () => navigate("/uxui"), roles: ["admin", "user"] },
    { id: "activities", parent: "Dashboard", title: "Activities", handler: () => navigate("/activity"), roles: ["admin", "user"] },
    { id: "people", parent: "Dashboard", title: "People", handler: () => navigate("/people"), roles: ["admin"] },

    { id: "Missions", title: "Missions", children: ["quotations", "folk", "missions", "missions_ao", "dataroom"] },
    { id: "quotations", parent: "Missions", title: "Quotation", handler: () => navigate("/quote"), roles: ["admin"] },
    { id: "folk", parent: "Missions", title: "Folk", handler: () => navigate("/folk"), roles: ["admin"] },
    { id: "missions", parent: "Missions", title: "Missions", handler: () => navigate("/missions"), roles: ["admin", "user"] },
    { id: "missions_ao", parent: "Missions", title: "Missions AO", handler: () => navigate("/missions_ao"), roles: ["admin", "user"] },
    { id: "dataroom", parent: "Missions", title: "Dataroom", handler: () => navigate("/data"), roles: ["admin", "user"] },

    { id: "Recruitment", title: "Recruitment", children: ["job", "referral", "pool", "channels", "applications"] },
    { id: "job", parent: "Recruitment", title: "Job", handler: () => navigate("/job"), roles: ["admin"] },
    { id: "referral", parent: "Recruitment", title: "Referral", handler: () => navigate("/referral"), roles: ["admin", "user"] },
    { id: "pool", parent: "Recruitment", title: "Pool", handler: () => navigate("/pool"), roles: ["admin"] },
    { id: "channels", parent: "Recruitment", title: "Channels", handler: () => navigate("/jobchannel"), roles: ["admin"] },
    { id: "applications", parent: "Recruitment", title: "My applications", handler: () => navigate("/applicant"), roles: ["admin", "user", "applicant"] },

    { id: "Workshops", title: "Workshops", children: ["learn", "skills", "questioning", "editor", "ratings", "meetings"] },
    { id: "learn", parent: "Workshops", title: "Workshops", handler: () => navigate("/learn"), roles: ["admin", "user"] },
    { id: "skills", parent: "Workshops", title: "Skills", handler: () => navigate("/skills"), roles: ["admin", "user"] },
    { id: "questioning", parent: "Workshops", title: "Questioning", handler: () => navigate("/questioning"), roles: ["admin"] },
    { id: "editor", parent: "Workshops", title: "Editor", handler: () => navigate("/learn-editor"), roles: ["admin"] },
    { id: "ratings", parent: "Workshops", title: "Ratings", handler: () => navigate("/learn-rating"), roles: ["admin", "user"] },
    { id: "meetings", parent: "Workshops", title: "Meetings", handler: () => navigate("/meetings"), roles: ["admin", "user"] },

    { id: "Accounting", title: "Accounting", children: ["invoice", "payment", "bank", "salary"] },
    { id: "invoice", parent: "Accounting", title: "Invoice", handler: () => navigate("/invoice"), roles: ["admin"] },
    { id: "payment", parent: "Accounting", title: "Payment", handler: () => navigate("/payment"), roles: ["admin", "user"] },
    { id: "bank", parent: "Accounting", title: "Bank", handler: () => navigate("/bank"), roles: ["admin"] },
    { id: "salary", parent: "Accounting", title: "Salary", handler: () => navigate("/salary"), roles: ["admin"] },
    { id: "mails", parent: "Accounting", title: "Mails", handler: () => navigate("/mail"), roles: ["admin"] },
    { id: "tools", parent: "Accounting", title: "Tools", handler: () => navigate("/tools"), roles: ["admin"] },
    { id: "documents", parent: "Accounting", title: "Documents", handler: () => navigate("/document"), roles: ["admin"] },
    { id: "organisations", parent: "Accounting", title: "Organisations", handler: () => navigate("/organisation"), roles: ["admin"] },
    { id: "organigramme", parent: "Accounting", title: "Organigramme", handler: () => navigate("/organigramme"), roles: ["admin"] },
    { id: "devices", parent: "Accounting", title: "Devices", handler: () => navigate("/device"), roles: ["admin"] },
    { id: "report", parent: "Accounting", title: "Report", handler: () => navigate("/report"), roles: ["admin", "user"] },
    { id: "collection", parent: "Accounting", title: "Collection", handler: () => navigate("/cashout"), roles: ["admin"] },
    { id: "wallet", parent: "Accounting", title: "Wallet", handler: () => navigate("/wallet"), roles: ["admin"] },
    { id: "portfolio", parent: "Accounting", title: "Portfolio", handler: () => navigate("/portfolio"), roles: ["admin"] },
    { id: "find_your_cofounder", parent: "Accounting", title: "Find Your Cofounder", handler: () => navigate("/find_your_cofounder"), roles: ["admin"] },
    { id: "articles", parent: "Accounting", title: "Articles", handler: () => navigate("/articles"), roles: ["admin"] },
    { id: "users", parent: "Accounting", title: "Users", handler: () => navigate("/user"), roles: ["admin"] },
  ]
    .filter((item) => {
      if (item.roles) return item.roles.includes(user.role);
      return true;
    })
    .map((item) => ({ ...item, section: "Menu" }));

  const searchFn = async (event) => {
    const { search } = event.detail;

    if (!search) {
      ninjaKeys.current.data = menu;
      return;
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(async () => {
      try {
        // TODO: handle permissions
        const projectPromise = API.post(`/project/search`, { search });
        const userPromise = API.post(`/user/search`, { search });
        const workshopPromise = API.post(`/workshop/search`, { search });
        const folkPromise = API.post(`/folk/search`, { search });
        // Add more search functions here

        const [projectResponse, userResponse, workshopResponse, folkResponse] = await Promise.all([projectPromise, userPromise, workshopPromise, folkPromise]);

        // TODO: Add icons based on menu
        const items = [
          ...projectResponse.data.projects.map((p) => ({
            id: `${p.name}-project`,
            title: `[Project] ${p.name}`,
            keywords: search,
            handler: () => navigate(`/project/${p._id}`),
            section: "Projects",
          })),
          ...userResponse.data.users.map((u) => ({
            id: `${u.name}-user`,
            title: `[User] ${u.name}`,
            keywords: search,
            handler: () => navigate(`/people?user_modal_id=${u._id}&user_modal_tab=about`),
            section: "People",
          })),
          ...workshopResponse.data.map((w) => ({
            id: `${w.name}-workshop`,
            title: `[Workshop] ${w.name}`,
            keywords: search,
            handler: () => navigate(`/learn/${w._id}`),
            section: "Workshops",
          })),
          ...folkResponse.data.map((f) => ({
            id: `${f.company} - ${f.first_name} ${f.last_name}-folk`,
            title: `[Folk] ${f.company} - ${f.first_name} ${f.last_name} `,
            keywords: search,
            handler: () => navigate(`/folk?folk_modal_tab=info&folk_modal_id=${f._id}`),
            section: "Folk",
          })),
        ];

        ninjaKeys.current.data = [...menu, ...items];
      } catch (e) {
        toast.error("Something went wrong");
        console.error(e);
      }
    }, 150);
  };

  const reset = () => {
    ninjaKeys.current.data = menu;
  };

  useEffect(() => {
    if (ninjaKeys.current) {
      ninjaKeys.current.data = menu;
      ninjaKeys.current.addEventListener("change", searchFn);
      ninjaKeys.current.addEventListener("selected", reset);
    }
    return () => {
      removeEventListener("change", searchFn);
      removeEventListener("selected", reset);
    };
  }, []);

  if (!["user", "admin"].includes(user.role)) return <div />;

  return (
    <>
      <button
        type="button"
        onClick={() => ninjaKeys.current.open()}
        className="focus:outline-none text-xs flex border border-gray-300 hover:border-sky-700 rounded px-1.5 h-[2.35rem] gap-1 items-center md:w-64">
        <FaSearch className="text-gray-500 text-sm mx-1 flex-none" />
        <p className="text-gray-600 hidden sm:block">Quick Access</p>
        <div className="ml-auto gap-0.5 hidden sm:flex">
          <p className="text-gray-600 border-[1px] border-gray-400 px-1.5 py-0.5 rounded">CMD</p>
          <p className="text-gray-600 border-[1px] border-gray-400 px-1.5 py-0.5 rounded">K</p>
        </div>
      </button>
      <ninja-keys ref={ninjaKeys} placeholder="Search for a user, project, workshop or folk item"></ninja-keys>
    </>
  );
}
