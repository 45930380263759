import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SelectMonth from "./../../components/selectMonth";
import SelectUser from "../../components/selectUser";
import SelectContact from "../../components/SelectContact";

import { formatDate, formatNumberWithThousandSeparator } from "../../utils";
import api from "../../services/api";

const ToPayList = () => {
  const [payments, setPayments] = useState([]);
  const [filter, setFilter] = useState({ status: ["PENDING", "TOPAY", "ERROR"] });

  useEffect(() => {
    update(filter);
  }, []);

  const navigate = useNavigate();

  async function update(f) {
    console.log(f);
    const newFilter = { ...f };
    const { data } = await api.post(`/payment/search`, newFilter);
    setFilter(newFilter);
    setPayments(data);
  }

  const arr = [];
  let previousDate = null;
  for (let i = 0; i < payments.length; i++) {
    const payment = payments[i];
    if (previousDate !== payment.date) {
      const date = new Date(payment.date);
      previousDate = payment.date;
      arr.push(<div className="w-full text-[18px] font-semibold py-2 px-3">{formatDate(date)}</div>);
    }
    arr.push(<Item2 key={i} data={payment} />);
  }

  return (
    <div>
      <div className="p-2">
        <div className="flex flex-wrap gap-5">
          <SelectMonth start={0} value={filter.date} onChange={(e) => update({ ...filter, date: e.target.value })} />
          <SelectUser value={filter.user_id} onChange={(e) => update({ ...filter, user_id: e._id })} />
          <SelectContact value={filter.contract} onChange={(contract) => update({ ...filter, contract })} />
          <FilterStatus value="PENDING" filter={filter} setFilter={update} />
          <FilterStatus value="TOPAY" filter={filter} setFilter={update} />
          <FilterStatus value="PAID" filter={filter} setFilter={update} />
          <FilterStatus value="ERROR" filter={filter} setFilter={update} />
          <Create />
        </div>

        <div className="w-[98%] overflow-x-auto bg-[#fff] rounded-[10px] mt-3">
          <table className="w-[100%] min-w-[700px]">
            <thead>
              <tr className="border-b border-[#E5EAEF] text-[14px] text-[#212325] font-semibold	">
                <th className="py-[10px] px-[22px]">User name</th>
                <th>Month</th>
                <th>Contract</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Files</th>
                <th className="text-center">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              <tr className="border-b border-t bg-blue-100">
                <td className="px-2 py-2 text-sm font-medium text-gray-600">Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td className="px-2 py-2 text-sm font-medium text-gray-600">{`${formatNumberWithThousandSeparator(
                  payments.reduce((prev, curr) => prev + (curr?.value || 0), 0).toFixed(2),
                  ",",
                )}€`}</td>
                <td></td>
                <td></td>
              </tr>
              {arr}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  async function onCreate() {
    const res = await api.post("/payment", values);
    navigate(`/payment/${res.data._id}`);
  }

  return (
    <div style={{ marginBottom: 10 }}>
      <div className="text-right">
        <button className="bg-[#0560FD] text-[#fff] py-[12px] px-[22px] h-[48px]	rounded-[10px] text-[16px] font-medium" onClick={() => setOpen(true)}>
          Create an expense
        </button>
      </div>
      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div
            className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <React.Fragment>
              <div>
                <div className="flex flex-wrap justify-between mt-3">
                  <div className="w-full md:w-[48%] mt-2">
                    <div className="text-[14px] text-[#212325] font-medium">Mois (*)</div>
                    <SelectMonth
                      placeholder="Select month"
                      indexDefaultValue={0}
                      value={values.date}
                      onChange={(e) => setValues({ ...values, date: e.target.value })}
                      name="date"
                    />
                  </div>
                </div>
              </div>
              <br />
              <button className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onCreate}>
                Create
              </button>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const Item2 = ({ data }) => {
  async function onClick() {
    window.open(`/payment/${data._id}`, "_blank");
  }

  let color = "bg-[#E5FED6] text-[#7CB255]";
  if (data.status === "TOPAY") color = "bg-[#EF573C] text-[#FFFFFF]";
  if (data.status === "PENDING") color = "bg-[#f228371a] text-[#EF4444]";

  return (
    <tr className="border-b border-t border-[#E5EAEF] text-[14px] text-[#676D7C] cursor-pointer hover:bg-blue-50">
      <td className="py-[12px] px-[22px]" onClick={onClick}>
        {data.user_name}
      </td>
      <td onClick={onClick}>{formatDate(data.date)}</td>
      <td onClick={onClick}>{data.contract}</td>
      <td onClick={onClick}>{data.type}</td>
      <td onClick={onClick}>{`${data.value ? data.value : "0"} €`}</td>
      <td>
        {data.files.map((e, i) => (
          <a key={i} href={e} download target="_blank">
            🧾
          </a>
        ))}
      </td>
      <td className="text-center" onClick={onClick}>
        <span className={`py-[6px] px-[10px] rounded-[4px] ${color}`}>{data.status}</span>
      </td>
    </tr>
  );
};

const FilterStatus = ({ value, filter, setFilter }) => {
  const status = filter.status || [];
  return (
    <div
      className="flex items-center bg-[#FCEBEC] p-2 px-3 text-sm text-[#EF4444] rounded-xl overflow-hidden cursor-pointer"
      onClick={() => {
        console.log(status, value);
        if (!status.includes(value)) {
          setFilter({ ...filter, status: [...status, value] });
        } else {
          setFilter({ ...filter, status: status.filter((e) => e !== value) });
        }
      }}>
      <div className="mr-2">{value}</div>
      <input type="checkbox" checked={status.includes(value)}></input>
      {/* {!filter.status.includes(value) ? `Show ${value}` : `Hide ${value}`} */}
    </div>
  );
};

// const renderContract = (c) => {
//   if (c === "EMPLOYEE_FR")
//     return (
//       <span>
//         Employee <span className="text-[9px]">FR</span> 🇫🇷
//       </span>
//     );
//   if (c === "EMPLOYEE_NL")
//     return (
//       <span>
//         Employee <span className="text-[9px]">NL</span> 🇳🇱
//       </span>
//     );
//   if (c === "FREELANCE") return "Freelance";
//   return c;
// };

export default ToPayList;
