import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import ProgressBar from "../../../../../components/ProgressBar";

export default ({ project, setFilters }) => {
  const [budgets, setBudgets] = useState([]);
  const [selectedBudgets, setSelectedBudgets] = useState([]);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setFilters((f) => ({ ...f, budget_ids: selectedBudgets }));
  }, [selectedBudgets]);

  async function load() {
    const { data } = await api.post("/budget/search", { projectId: project._id, status: "active" });
    const notions = await api.post("/notion/search", { project_id: project._id });

    const noBudgetNotions = notions.data.filter((notion) => !notion.budget_id && notion.estimated_price).filter((notion) => !["DONE", "ARCHIVED"].includes(notion.status));
    const sumNoBudgetNotions = noBudgetNotions.reduce((acc, notion) => acc + parseFloat(notion.estimated_price) || 0, 0);
    const noBudgetBudget = {
      name: `Tickets with No Budget (${noBudgetNotions.length})`,
      amount: sumNoBudgetNotions,
      amountUsed: sumNoBudgetNotions,
    };
    setBudgets((prev) => {
      const newBudgets = [...prev, ...data];
      if (sumNoBudgetNotions) newBudgets.push(noBudgetBudget);
      return newBudgets;
    });
  }

  const isActive = (budgetId) => selectedBudgets.includes(budgetId);
  const Item = ({ item }) => {
    const max = item.amount;
    const total = item.amountUsed;
    const percentage = (total / max) * 100;

    return (
      <div
        className={`border-2 flex ${isActive(item._id) ? " border-blue-500 shadow-md rounded-lg" : "border-white"}`}
        onClick={() => {
          if (isActive(item._id)) setSelectedBudgets(selectedBudgets.filter((e) => e !== item._id));
          else setSelectedBudgets([...selectedBudgets, item._id]);
        }}>
        <div className="cursor-pointer flex justify-between p-2  items-center flex-col rounded-md border border-gray-200 hover:bg-slate-100 ">
          <div className="text-sm font-semibold mb-1">{item.name}</div>
          <ProgressBar percentage={percentage} max={max} value={total} services={0} expenses={0} legendSymmbol={"€"} />
        </div>
      </div>
    );
  };

  if (!budgets) return <div>Loading</div>;

  return (
    <div className="flex flex-row gap-4 mb-2 overflow-auto">
      {budgets.map((e) => {
        return <Item key={e._id} item={e} />;
      })}
    </div>
  );
};
