import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import MultiSelect from "../../../components/MultiSelect";
import Loader from "../../../components/loader";

export default ({ values, onChange }) => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await api.post("/job/search", { status: ["active"] });
      setJobs(
        res.data
          .sort(function (a, b) {
            if (a.title?.toLowerCase() < b.title?.toLowerCase()) return -1;
            if (a.title?.toLowerCase() > b.title?.toLowerCase()) return 1;
            return 0;
          })
          .map((j) => ({ value: j._id, label: `[#${j.index}] ${j.title} (${j.project_name}) ` })),
      );
    })();
  }, []);

  if (!jobs?.length) return <Loader size="small" />;

  return <MultiSelect id="select-jobs" options={jobs} values={values} onSelectedChange={onChange} placeholder="Select a job" />;
};
