import React, { useState, useEffect } from "react";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import api from "../../services/api";
import { setUser } from "../../redux/auth/actions";

export default () => {
  const navigate = useNavigate();
  const [seePassword, setSeePassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });

  const dispatch = useDispatch();

  const [userForm, setUserForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    agree: false,
  });

  const handleSubmit = async (e) => {
    try {
      const res = await api.post(`/user/signup`, { email: userForm.email, password: userForm.password, name: `${userForm.first_name} ${userForm.last_name}` });
      if (res.ok) {
        api.setToken(res.token);
        dispatch(setUser(res.user));
        navigate("/");
      } else if (res.code === "RESSOURCE_ALREADY_EXISTS") {
        setErrors({ ...errors, email: "Cette adresse email est déjà utilisée" });
      } else if (res.code === "PASSWORD_NOT_VALIDATED") {
        setErrors({ ...errors, password: "Le mot de passe doit faire entre 6 et 100 caractères et contenir au moins 1 lettre" });
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div className="w-full min-h-screen px-4 py-8 bg-blue-50">
      <div className="max-w-3xl mx-auto p-8 bg-white rounded-xl shadow-lg">
        <form
          className="flex flex-col space-y-8"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-800">Create Your Account</h1>
            <p className="text-gray-600">Please complete the required information to get started</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="block text-gray-700 font-medium">
                First Name <span className="text-red-500">*</span>
              </label>
              <input
                required
                type="text"
                value={userForm.first_name}
                onChange={(e) => setUserForm({ ...userForm, first_name: e.target.value })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                placeholder="John"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-gray-700 font-medium">
                Last Name <span className="text-red-500">*</span>
              </label>
              <input
                required
                type="text"
                value={userForm.last_name}
                onChange={(e) => setUserForm({ ...userForm, last_name: e.target.value })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                placeholder="Doe"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label className="block text-gray-700 font-medium">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="email"
              value={userForm.email}
              onChange={(e) => {
                setUserForm({ ...userForm, email: e.target.value });
                setErrors({ ...errors, email: "" });
              }}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
              placeholder="example@mail.com"
            />
            {errors.email && <p className="text-sm text-red-600">{errors.email}</p>}
          </div>

          <div className="space-y-2">
            <label className="block text-gray-700 font-medium">
              Password <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <input
                required
                type={seePassword ? "text" : "password"}
                value={userForm.password}
                onChange={(e) => {
                  setUserForm({ ...userForm, password: e.target.value });
                  setErrors({ ...errors, password: "" });
                }}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                placeholder="Enter your password"
              />
              <button type="button" onClick={() => setSeePassword(!seePassword)} className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700">
                {seePassword ? <FaRegEyeSlash size={20} /> : <FaRegEye size={20} />}
              </button>
            </div>
            {errors.password && <p className="text-sm text-red-600">{errors.password}</p>}
          </div>

          <label className="flex items-start gap-3 cursor-pointer group">
            <input
              type="checkbox"
              checked={userForm.agree}
              onChange={(e) => setUserForm({ ...userForm, agree: e.target.checked })}
              className="mt-1 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-gray-700 group-hover:text-gray-900 transition-colors">By checking this box, I agree to the Terms of Service</span>
          </label>

          <div className="flex flex-col space-y-4">
            <button
              type="submit"
              disabled={Object.values(userForm).some((value) => !value) || Object.values(errors).some((error) => error)}
              className={`px-8 py-3 rounded-lg font-medium text-white transition-all
              ${
                Object.values(userForm).some((value) => !value) || Object.values(errors).some((error) => error)
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700 active:bg-blue-800"
              }`}>
              Create Account
            </button>

            <div className="flex items-center justify-center gap-2 text-gray-600">
              <span>Already have an account?</span>
              <Link to="/auth" className="text-blue-600 hover:text-blue-700 font-medium">
                Sign In
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
