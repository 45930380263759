import React from "react";
import toast from "react-hot-toast";
import { Formik } from "formik";
import { MdDeleteForever } from "react-icons/md";

import api from "../../services/api";
import { readFileAsync } from "../../utils";
import LoadingButton from "../../components/loadingButton";

const Files = ({ user, setUser }) => {
  return (
    <Formik
      initialValues={user}
      onSubmit={async (values) => {
        try {
          await api.put(`/user/${user._id}`, values);
          setUser(values);
          toast.success("Updated!");
        } catch (e) {
          console.log(e);
          toast.error("Some Error!");
        }
      }}>
      {({ values, handleChange, handleSubmit, isSubmitting }) => {
        return (
          <div className="p-2">
            <div className="col-span-3">
              <Documents value={values} onChange={handleChange} />
            </div>
            <LoadingButton className="btn btn-primary mt-2" loading={isSubmitting} onClick={handleSubmit}>
              Update
            </LoadingButton>
          </div>
        );
      }}
    </Formik>
  );
};

const Documents = ({ value, onChange }) => {
  const renderFileName = (document) => {
    const file = document.url;
    if (file.name) return file.name;
    if (file.startsWith("https")) return file.split("/").pop();
    return (
      <label
        htmlFor={`file-upload-${document.id}`}
        className="flex pl-[20px] items-center w-[172px] h-[40px] border border-[#ccc] bg-[#F9FBFD] rounded-[10px] mt-[7px] cursor-pointer">
        Upload a file
      </label>
    );
  };

  return (
    <div>
      <div className="bg-white rounded-lg py-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="border-b border-[#E5EAEF] text-[14px] text-[#212325] font-semibold	">
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                label
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                file name
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {(value.documents || []).map((document) => {
              console.log(document);
              return (
                <tr key={document._id} className="hover:bg-blue-50 ">
                  <td className="px-4 py-2 text-sm font-medium text-gray-600">
                    <input
                      className="projectsInput mt-0 text-[14px] font-normal text-[#212325] rounded-[10px] max-w-md"
                      value={document.label}
                      onChange={(e) => {
                        const newarray = [...value.documents];
                        const find = newarray.find((d) => d.id === document.id);
                        find.label = e.target.value;
                        onChange({ target: { value: newarray, name: "documents" } });
                      }}
                    />
                  </td>
                  <td className="px-4 py-2 text-sm font-medium text-gray-600">
                    <a className="flex gap-1 items-center text-sm text-gray-700 hover:underline cursor-pointer" href={document.url} target="_blank" rel="noreferrer">
                      {renderFileName(document)}
                    </a>
                    <input
                      id={`file-upload-${document.id}`}
                      className="mt-2"
                      name="file-upload"
                      type="file"
                      onChange={async (e) => {
                        console.log(`on change`, document.id);
                        const newarray = [...value.documents];
                        const find = newarray.find((d) => d.id === document.id);
                        find.url = e.target.value;

                        const f = e.target.files[0];
                        const rawBody = await readFileAsync(f);
                        find.url = { rawBody, name: f.name };
                        onChange({ target: { value: newarray, name: "documents" } });
                      }}
                    />
                  </td>
                  <td className="px-4 py-2 text-sm font-medium text-gray-600">
                    <div className={`flex justify-center cursor-pointer text-xl hover:text-red-500`}>
                      <MdDeleteForever
                        onClick={() => {
                          const newLinks = value.documents.filter((l) => l.id !== document.id);
                          onChange({ target: { value: newLinks, name: "documents" } });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <button
          className="btn btn-primary mt-5 ml-3"
          onClick={() => {
            onChange({ target: { value: [...value.documents, { label: "", url: "", id: generateSmallUID() }], name: "documents" } });
          }}>
          Add a file
        </button>
      </div>
    </div>
  );
};

const generateSmallUID = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export default Files;
