import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./home";
import Workshop from "./workshop";

export default () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:id" element={<Workshop />} />
    </Routes>
  );
};
