import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/loader";

export default function ({ ticket, onClick }) {
  const navigate = useNavigate();
  const [unread, setUnread] = useState(0);
  const [lastMessage, setLastMessage] = useState("");
  const user = useSelector((state) => state.Auth.user);

  const PriorityTag = () => {
    if (!ticket.priority) return null;
    const colors = {
      LOW: "bg-green-100 text-xs text-green-800",
      MEDIUM: "bg-yellow-100 text-xs text-yellow-800",
      HIGH: "bg-red-100 text-xs text-red-800",
    };
    return <div className={`px-2 py-1 rounded ${colors[ticket.priority]}`}>{ticket.priority}</div>;
  };

  const CustomLabel = () => {
    if (!ticket.label) return null;

    const SUPPORT_LABEL_COLORS = {
      CONTRACT: "bg-indigo-300 text-xs text-indigo-800",
      INVOICE: "bg-teal-300 text-xs text-teal-800",
      PAYMENT: "bg-black text-xs text-white",
      ADMIN: "bg-violet-200 text-xs text-violet-800",
      FINANCE: "bg-pink-200 text-xs text-pink-800",
      RECRUITMENT: "bg-yellow-200 text-xs text-yellow-800",
      TOOLS: "bg-blue-200 text-xs text-blue-800",
    };

    return <div className={`px-2 py-1 rounded ${SUPPORT_LABEL_COLORS[ticket.label]}`}>{ticket.label}</div>;
  };

  useEffect(() => {
    const me = ticket.people.find((e) => e.user_id === user._id);
    const lastMessage = ticket.messages[ticket.messages.length - 1];

    if (lastMessage != null) {
      if (lastMessage.user_id !== user._id) {
        setUnread(me?.unread);
      } else {
        setUnread(0);
      }
      setLastMessage(lastMessage.text);
    } else {
      setLastMessage("");
      setUnread(0);
    }
  }, [ticket]);

  const openInNewTab = () => {
    const url = `/message/${ticket._id}`;
    window.open(url, "_blank");
  };

  if (!user) return <Loader />;

  return (
    <div className="w-[350px]">
      <div className="p-2 m-2 flex flex-col  bg-gray-50 rounded shadow-md cursor-pointer" onClick={openInNewTab}>
        <div className="flex justify-between">
          <div className="flex align-bottom items-center w-full">
            <div className="mr-1 text-xs font-bold">{`#${ticket.index}`}</div>
            {unread && unread > 0 ? (
              <div className="border border-red-500 rounded-full bg-red-500 text-xs text-white w-5 h-5 flex items-center justify-center mr-2">{unread}</div>
            ) : null}
          </div>
          <div className="flex items-center">
            <PriorityTag />
            <CustomLabel />
          </div>
        </div>
        <div className={"text-sm font-bold mr-1 whitespace-nowrap text-ellipsis overflow-hidden w-full"}>{`${ticket.title}`}</div>
        <div className="flex items-center">
          <div className="text-xs text-gray-500  whitespace-nowrap text-ellipsis overflow-hidden w-full">{lastMessage}</div>
        </div>
        <div className="flex items-center justify-between mt-2">
          <div className={"text-xs text-gray-500"}>
            {`${daysAgo(ticket.created_at)} days ago - `}
            {ticket.end_at ? (
              inDays(ticket.end_at) < 0 ? (
                <span className="text-red-500">{`${Math.abs(inDays(ticket.end_at))} days late`}</span>
              ) : inDays(ticket.end_at) < 2 ? (
                <span className="text-orange-500">{`due in ${inDays(ticket.end_at)} days`}</span>
              ) : (
                <span className="text-green-500">{`due in ${inDays(ticket.end_at)} days`}</span>
              )
            ) : null}
          </div>
          <div className="flex items-center">{ticket.leader_id && <img src={ticket.leader_avatar} className="w-6 h-6 rounded-full" alt={ticket.leader_name} />}</div>
        </div>
      </div>
    </div>
  );
}

const daysAgo = (date) => {
  const today = new Date();
  const diff = today - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};

const inDays = (date) => {
  const today = new Date();
  const diff = new Date(date) - today;
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};

function formatNumber(num) {
  return (num || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
