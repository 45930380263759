import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineCheck } from "react-icons/ai";
import DebounceInput from "react-debounce-input";

import Loader from "../../../components/loader";
import LoadingButton from "../../../components/loadingButton";

import api from "../../../services/api";

const TABS = {
  FIND: "Find People",
  INVITED: "Invited people",
};

const View = ({ job, setJob }) => {
  const [tab, setTab] = useState(TABS.FIND);

  return (
    <div className="p-6 bg-white rounded-2xl">
      <ul className="flex flex-wrap list-none space-x-3 mb-4">
        <li
          onClick={(e) => setTab(TABS.FIND)}
          className={`inline-block p-2 px-4 text-sm rounded-lg cursor-pointer ${tab === TABS.FIND ? "bg-primary text-white" : "bg-lightShade-grey"}`}>
          Find People
        </li>
        <li
          onClick={(e) => setTab(TABS.INVITED)}
          className={`inline-block p-2 px-4 text-sm rounded-lg cursor-pointer ${tab === TABS.INVITED ? "bg-primary text-white" : "bg-lightShade-grey"}`}>
          Invited people
        </li>
      </ul>
      {tab === TABS.FIND && (
        <FindPeople
          job={job}
          onChange={(v) => {
            setJob(v);
            setTab(TABS.INVITED);
          }}
        />
      )}
      {tab === TABS.INVITED && <InvitedPeople users={job.people_invited} />}
    </div>
  );
};

const InvitedPeople = ({ users = [] }) => {
  return (
    <div className="bg-lighter-grey rounded-lg py-4 mt-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="border-b border-[#E5EAEF] text-[14px] text-[#212325] font-semibold">
            <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
              User
            </th>
            <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
              Job title
            </th>
            <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
              Skills
            </th>
            <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
              Availability
            </th>
            <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
              Disponibility
            </th>
            <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
              Contract
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {users
            // .sort((a, b) => (a.availability === "available" ? 0 : 1) - (b.availability === "available" ? 0 : 1))
            .map((u) => {
              return (
                <tr className="hover:bg-blue-50 cursor-pointer">
                  <td className="px-4 py-2 text-sm font-medium text-gray-600">
                    <div className="col-span-3 text-red-brand flex items-center">
                      <div className="flex items-center space-x-2">
                        <img src={u.user_avatar} alt="userlogo" className="rounded-full w-7 h-7" />
                        <span>{u.user_name}</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2 text-sm font-medium text-gray-600">{u.job_title}</td>
                  <td className="px-4 py-2 text-sm font-medium text-gray-600">{(u.skills || []).join(", ")}</td>
                  <td className="px-4 py-2 text-sm font-medium text-gray-600 whitespace-nowrap">{u.availability}</td>
                  <td className="px-4 py-2 text-sm font-medium text-gray-600">{u.contract}</td>
                  <td className="px-4 py-2 text-sm font-medium whitespace-nowrap text-gray-600">{u.disponibility}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const FindPeople = ({ job, onChange }) => {
  const [users, setUsers] = useState(null);
  const [selecteds, setSelecteds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({ search: "", per_page: 200 });

  useEffect(() => {
    (async () => {
      const { data, total: t } = await api.post(`/user/search`, filters);
      setUsers(data.users);
    })();
  }, [filters]);

  const onSelecteAll = () => {
    setSelectAll((state) => !state);
    if (!selectAll) {
      const newArr = [...selecteds];
      for (let i = 0; i < users.length; i++) {
        if (!newArr.find((e) => e._id === users[i]._id)) {
          newArr.push(users[i]);
        }
      }
      setSelecteds(newArr);
    } else {
      setSelecteds([]);
    }
  };

  function onSelect(u) {
    if (selectAll) setSelectAll(false);
    let arr = [...selecteds];
    if (arr.find((user) => user._id === u._id)) arr = arr.filter((user) => user._id !== u._id);
    else arr = [...selecteds, u];
    setSelecteds(arr);
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      const { ok, data, code } = await api.post(`/mission/${job._id}/invite_people`, { users: selecteds });
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      toast.success(selecteds.length + " People invited !");
      onChange(data);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
    setLoading(false);
  }

  if (!users) return <Loader />;

  return (
    <>
      <div className="flex items-center justify-between">
        <label className="block relative w-full text-[#A0A6B1]">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" className="p-1">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-5 h-5">
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </span>
          <DebounceInput
            debounceTimeout={300}
            className="search-input pl-10 max-w-md w-full border-gray-300"
            placeholder="Search (name, email, skills, etc...) "
            value={filters.search}
            onChange={(e) => {
              e.persist();
              setFilters((prev) => ({ ...prev, search: e.target.value }));
            }}
          />
        </label>
        <LoadingButton loading={loading} disabled={!selecteds.length} className="btn btn-primary disabled:opacity-60 disabled:cursor-not-allowed" onClick={handleSubmit}>
          Invite
        </LoadingButton>
      </div>
      {!!selecteds.length && <div className="my-2 text-sm">{selecteds.length} people selected</div>}
      <div className="bg-lighter-grey rounded-lg py-4 mt-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="border-b border-[#E5EAEF] text-[14px] text-[#212325] font-semibold">
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                <div className="flex items-center space-x-2">
                  <label
                    className={`cursor-pointer mr-4 w-4 h-4 flex items-center justify-center rounded border border-gray-light ${selectAll && "bg-primary"}`}
                    onClick={onSelecteAll}>
                    <AiOutlineCheck className="text-white text-xs" />
                  </label>
                  <span>User</span>
                </div>
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Job title
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Skills
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Availability
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Disponibility
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Contract
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {users
              .sort((a, b) => (a.availability === "available" ? 0 : 1) - (b.availability === "available" ? 0 : 1))
              .map((u) => {
                const checked = selecteds.some((sl) => sl._id === u._id);
                return (
                  <tr onClick={() => onSelect(u)} className="hover:bg-blue-50 cursor-pointer">
                    <td className="px-4 py-2 text-sm font-medium text-gray-600">
                      <div className="col-span-3 text-red-brand flex items-center">
                        <label className={`cursor-pointer mr-4 min-w-4 w-4 h-4 flex items-center justify-center rounded border border-gray-light ${checked && "bg-primary"}`}>
                          <AiOutlineCheck className="text-white text-xs" />
                        </label>
                        <div className="flex items-center space-x-2">
                          <img src={u.avatar} alt="userlogo" className="rounded-full w-7 h-7" />
                          <span>{u.name}</span>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-sm font-medium text-gray-600">{u.job_title}</td>
                    <td className="px-4 py-2 text-sm font-medium text-gray-600">{(u.skills || []).join(", ")}</td>
                    <td className="px-4 py-2 text-sm font-medium text-gray-600 whitespace-nowrap">{u.availability}</td>
                    <td className="px-4 py-2 text-sm font-medium text-gray-600">{u.contract}</td>
                    <td className="px-4 py-2 text-sm font-medium whitespace-nowrap text-gray-600">{u.disponibility}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default View;
