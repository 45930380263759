import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import api from "../../../services/api";

import Chart from "../Chart";

export default ({}) => {
  const [data, setData] = useState(null);
  const [keys, setKeys] = useState(null);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const obj = {};
    // if (month) obj.month = month;
    // if (group) obj.groupId = group;
    const res = await api.post(`/report/admin`, obj);
    setData(res.data);
    setKeys(res.keys);
  }

  if (!data) return <div>Loading</div>;
  return (
    <div>
      <h1>Admin Costs</h1>
      <p>
        <strong>Time spent</strong> to run the company (recruitment, financial management, ...)
      </p>
      <div className="bg-white mt-4 p-2 rounded">
        <div className="h-[500px] w-1/2 bg-white mt-4 p-2 rounded">
          <Chart data={data} keys={keys} />
        </div>
      </div>
    </div>
  );
};

const ExpensesLeStud = ({ group, month }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    get();
  }, [group, month]);

  if (group) return <div />;

  async function get() {
    const obj = {};
    if (month) obj.month = month;
    const res = await api.post(`/report/expenses`, obj);
    setData(res.data);
  }

  if (!data) return <div>Loading</div>;

  return (
    <div>
      <h1>Expenses of le stud</h1>
      <p>
        <strong>Bank charges</strong> that cannot be passed on to the customer (restaurant, train ticket, ...)
      </p>
      <div className="bg-white mt-4 p-2 rounded">
        <div className="h-[500px] w-1/2 bg-white mt-4 p-2 rounded">
          <Chart
            data={data}
            onClick={(e) => {
              console.log(e);
            }}
          />
        </div>
      </div>
    </div>
  );
};
