import React, { useState } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";

export default ({ value, onAdd, onDelete }) => {
  const [comment, setComment] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    onAdd(comment);
    setComment("");
  };

  return (
    <div className="flex-grow-0 ">
      <div className="flex flex-col gap-y-1 mb-2">
        {(value || []).map((comment, index) => (
          <div key={index} className="flex items-center bg-white p-2 rounded-md">
            <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4" />
            <div>
              <div className="flex items-center gap-2 text-xs font-semibold">
                <div>{comment.user_name} </div>
                <div className="font-normal text-gray-500" data-tip data-for={`comment-${comment._id}`}>
                  {moment(new Date(comment.created_at)).fromNow()}
                </div>
                <ReactTooltip id={`comment-${comment._id}`}>
                  <span>{new Date(comment.created_at).toLocaleString()}</span>
                </ReactTooltip>
              </div>
              <div>{comment.text}</div>
            </div>
            <div className="flex-grow" />
            <div>
              {onDelete && (
                <div
                  className="text-xs text-gray-500 ml-4 cursor-pointer border border-gray-300 rounded-md p-1 hover:bg-gray-100"
                  onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      onDelete(comment._id);
                    }
                  }}>
                  delete
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mt-2 justify-between">
          <input type="text" className="w-[85%] projectsInput m-0" placeholder="Add a comment" value={comment} onChange={(e) => setComment(e.target.value)} />
          <button className="btn btn-primary ">Add</button>
        </div>
      </form>
    </div>
  );
};
