import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-hot-toast";
import { AiFillHeart } from "react-icons/ai";
import { FaComment, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import FileInputStyled from "../../components/FileInput";
import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import SelectProject from "../../components/selectProject";
import SelectUser from "../../components/selectUser";
import api from "../../services/api";
import FeatureInput from "./components/FeatureInput";
import ComponentModal from "./view";

export default () => {
  const [search, setSearch] = useState("");
  return (
    <div className="p-6">
      <h1 className="text-xl font-semibold mb-1">UX/UI Components</h1>
      <p className="text-gray-700 mb-4 text-xs">Share your UX/UI components here so other developers can use them—let's save time and highlight your expertise!</p>
      <DebounceInput
        debounceTimeout={1000}
        className="py-2 w-full max-w-md font-normal text-gray-700 rounded-md bg-white border border-gray-300 pl-4 shadow-sm"
        placeholder="Search UX/UI components..."
        value={search}
        onChange={(e) => {
          e.persist();
          setSearch(e.target.value);
        }}
      />
      <ComponentsSection search={search} />
    </div>
  );
};

const ComponentsSection = ({ search }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ page: 1, per_page: 50, search: "", limit: 50, is_deleted: false });
  const [total, setTotal] = useState(0);
  const [selectedComponent, setSelectedComponent] = useState(null);

  useEffect(() => {
    setFilters((f) => ({ ...f, search, page: 1 }));
  }, [search]);

  const fetchSelected = async () => {
    const query = new URLSearchParams(window.location.search);
    if (!query.get("component_id")) return;
    const { data, ok } = await api.get(`/uxui/${query.get("component_id")}`);
    if (!ok) return toast.error("Failed to fetch data");
    setSelectedComponent(data);
  };

  const get = async () => {
    setLoading(true);
    const { data, ok, total } = await api.post("/uxui/search", { ...filters });
    if (!ok) return toast.error("Failed to fetch data");
    setData(data);
    setTotal(total);
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, [filters]);

  useEffect(() => {
    fetchSelected();
  }, []);

  return (
    <div className="rounded-lg mt-6">
      <Create refetch={get} />
      {selectedComponent && <ComponentModal component={selectedComponent} setComponent={setSelectedComponent} refetch={get} />}
      <ComponentsList loading={loading} data={data} setSelectedComponent={setSelectedComponent} refetch={get} />
      <Pagination
        per_page={filters.per_page}
        total={total}
        onNext={() => setFilters((f) => ({ ...f, page: +f.page + 1 }))}
        onPrevious={() => setFilters((f) => ({ ...f, page: +f.page - 1 }))}
        currentPage={filters.page}
      />
    </div>
  );
};

const ComponentsList = ({ loading, data, setSelectedComponent }) => {
  if (loading) return <Loader size="small" />;
  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
      <div className="p-3 md:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">UI Components Library</h1>
        <p className="mt-2 text-sm text-gray-700">Browse and reuse components across projects</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {data.map((component) => (
          <div
            key={component._id}
            onClick={() => setSelectedComponent(component)}
            className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 cursor-pointer border border-gray-200">
            <div className="relative aspect-video mb-3">
              <img
                src={component.images[0]}
                alt={component.name}
                className="rounded-md object-cover w-full h-full"
                loading="lazy"
                onError={(e) => (e.target.src = "/default-component.png")} 
              />
              <div className="absolute top-2 right-2 flex gap-2 bg-white/90 rounded-full px-3 py-1">
                <span className="flex items-center gap-1 text-sm">
                  <FaEye className="text-gray-600" />
                  {component.view}
                </span>
                <span className="flex items-center gap-1 text-sm">
                  <AiFillHeart className="text-rose-500" />
                  {component.likes.length} 
                </span>
              </div>
            </div>

            <div>
              <h3 className="font-medium text-gray-900 mb-2">
                {component.name}
              </h3>
              
              <div className="flex items-center justify-between">
                <div className="flex -space-x-2">
                  {(component.users || []).slice(0,3).map((user, index) => (
                    <img 
                      key={index}
                      src={user.user_avatar}
                      alt={`Contributor ${index + 1}`}
                      className="w-6 h-6 rounded-full border-2 border-white"
                    />
                  ))}
                  {component.users?.length > 3 && (
                    <div className="w-6 h-6 rounded-full bg-gray-200 border-2 border-white flex items-center justify-center text-xs">
                      +{component.users.length - 3}
                    </div>
                  )}
                </div>

                <div className="flex items-center gap-3 text-sm text-gray-500">
                  <span className="flex items-center gap-1">
                    <FaComment />
                    {component.comments.length}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Create = ({ refetch }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ images: [], features: [""] });
  const navigate = useNavigate();

  async function onCreate() {
    const formatted = { ...values, features: values.features.filter((val) => val) };
    const { data, ok } = await api.post("/uxui/multiple", formatted);
    if (!ok) return toast.error("Error creating example");
    toast.success("Example created");
    setOpen(false);
    refetch();
    navigate(`/uxui/${data._id}`);
  }

  return (
    <>
      <div className="flex justify-end items-center mb-6 -mt-16">
        <button className="btn btn-primary" onClick={() => setOpen(true)}>
          Add an example
        </button>
      </div>
      {open ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 min-h-max bg-[#00000066] flex justify-center items-center p-[1rem] z-50 h-screen" onClick={() => setOpen(false)}>
          <div
            className="w-full max-h-[96vh] overflow-auto md:w-[60%] h-fit bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <CreationForm values={values} setValues={setValues} />
            <React.Fragment>
              <div className="flex justify-end">
                <button className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onCreate}>
                  Create
                </button>
              </div>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </>
  );
};

const CreationForm = ({ values, setValues }) => {
  return (
    <div className="space-y-4">
      <div>
        <h2 className="text-lg font-semibold mb-2">Choose Project</h2>
        <SelectProject
          indexDefaultValue={0}
          value={values.project_name}
          onChange={(e) => {
            setValues({ ...values, project_id: e._id, project_name: e.name, project_logo: e.logo });
          }}
        />
      </div>
      <input
        type="text"
        className="form-input w-full p-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
        placeholder="Component name"
        value={values.name || ""}
        onChange={(e) => setValues({ ...values, name: e.target.value })}
      />

      <h2 className="text-lg font-semibold mb-2">Description</h2>
      <textarea
        className="form-input w-full p-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none !mt-2"
        placeholder="Component description"
        lines={4}
        value={values.description || ""}
        onChange={(e) => setValues({ ...values, description: e.target.value })}
      />

      <h3 className="text-lg font-semibold mb-2">Features and links</h3>
      {values.features.map((feature, index) => (
        <FeatureInput key={index} feature={feature} index={index} values={values} setValues={setValues} />
      ))}

      <div className="my-1">
        <p className="text-lg font-semibold mb-2">Upload images of the components in the project</p>
        <div className="flex gap-1 overflow-x-auto mt-5">
          {values.images.map((image) => (
            <div className="relative max-h-40 flex-shrink-0" key={image}>
              <img src={image} className="h-full max-h-40 object-contain" />
              <div
                onClick={() => setValues((prev) => ({ ...prev, images: prev.images.filter((img) => img !== image) }))}
                className="h-4 w-4 rounded-full border absolute top-0 right-0 bg-gray-200 z-50 flex items-center justify-center hover:cursor-pointer ">
                &times;
              </div>
            </div>
          ))}
        </div>

        <FileInputStyled onChange={(e) => setValues({ ...values, images: [...values.images, e.target.value[0]] })} name="image" folder="/uxui" />
      </div>
      <h2 className="text-xl font-semibold my-2">Have already done it:</h2>
      <SelectUser
        userRoles={["admin", "user", "applicant"]}
        onChange={(user) => {
          if (!user?._id) return;
          const newUsers = values.users || [];
          newUsers.push({
            user_id: user._id,
            user_name: user.name,
            user_avatar: user.avatar,
          });
          setValues({ ...values, users: newUsers });
        }}
      />
      <div className="flex flex-row gap-3 p-2 flex-wrap">
        {(values.users || []).map((user, index) => {
          return (
            <div key={index}>
              <img src={user.user_avatar} alt="User Avatar" className="w-20 h-20 rounded-full object-cover" />
              <p className="text-xs font-semibold">{user.user_name}</p>
              <button
                className="btn btn-error"
                onClick={() => {
                  const newUsers = values.users.filter((u) => u.user_id !== user.user_id);
                  setValues({ ...values, users: newUsers });
                }}>
                Delete
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
