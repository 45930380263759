import React, { useState, useContext } from "react";
import toast from "react-hot-toast";
import ReactTooltip from "react-tooltip";
// import HiThumbUp from "react-icons/hi";

import api from "../../../services/api";
import App, { AppContext } from "./context";

import Avatar from "../../../assets/avatar.jpg";

export default ({}) => {
  const [comment, setComment] = useState("");

  const context = useContext(AppContext);

  const comments = context.workshop.comments || [];

  if (!context.workshop.steps[context.index]) return <div />;

  const step_id = context.workshop.steps[context.index]._id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return;
    setComment("");

    comments.push({ text: comment, user_name: "anonymous", user_id: "anonymous", step_id });
    const { data } = await api.put(`/workshop/public/${context.workshop._id}`, { comments });
    context.setWorkshop(data);
    toast.success("Comment added!");
  };

  return (
    <div className="md:flex hidden flex-col self-start w-full" style={{ maxWidth: "300px" }}>
      <div className="flex flex-col">
        {(comments || [])
          .filter((e) => e.step_id === step_id)
          .map((comment, index) => (
            <div className="border-b-2 pb-2 mb-2">
              <div className="flex flex-row">
                <img src={comment.user_avatar || Avatar} className="rounded-full w-10 h-10 mr-4" />
                <div key={index} className="flex items-center mb-2">
                  <div>
                    <div className="text-sm">{comment.user_name}</div>
                    <div className="text-sm text-gray-500">{new Date(comment.created_at).toLocaleDateString()}</div>
                  </div>
                </div>
              </div>
              <div>{comment.text}</div>
              <div className="flex gap-2 justify-between">
                <div className="flex items-center gap-2">
                  {/* <HiThumbUp
                    className="cursor-pointer"
                    onClick={async () => {
                      // const { data } = await api.post(`/workshop/${context.workshop._id}/like_comment`, { comment_id: comment._id });
                      // context.setWorkshop(data);
                    }}
                  /> */}
                  <div className="text-sm text-gray-500 cursor-pointer underline" data-tip data-for={`like-${comment._id}`}>
                    {comment?.likes?.length || 0}
                  </div>
                  <ReactTooltip id={`like-${comment._id}`}>
                    {(comment.likes || []).map((l) => (
                      <div>{l.user_name}</div>
                    ))}
                  </ReactTooltip>
                </div>
              </div>
            </div>
          ))}
        {/* {comments.length === 0 && (
          <div>
            <div className="text-gray-500 flex">No comments yet</div>
            <div className="">A question, something to add? Be the first to comment</div>
          </div>
        )} */}
      </div>

      <div>
        <div className="flex items-center">
          <img src={Avatar} alt="userlogo" className="rounded-full w-9 h-9 mr-2" />
          <textarea
            className="rounded-[5px]  border-[transparent] shadow-sm block w-full"
            placeholder="Add a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className="flex justify-end mt-2">
          <button className="btn btn-primary text-xs font-medium" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};
