import React, { useEffect, useState } from "react";

export default ({ user }) => {
  console.log(`user.resume`, user.resume);
  return (
    <div>
      <object data={user.resume} type="application/pdf" width="100%" height="900px">
        <p>
          Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a>
        </p>
      </object>
    </div>
  );
};
