import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";

import Loader from "../../components/loader";
import api from "../../services/api";

import CategoryRadar from "./components/CategoryRadar";
import SubCategoryRadar from "./components/SubCategoryRadar";

export default () => {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState({ search: "" });

  useEffect(() => {
    get();
  }, [filter]);

  async function get() {
    try {
      const { data } = await api.post(`/user/search`, { contracts: ["CDD", "CDI", "INTERNSHIP", "FREELANCE", "APPRENTICESHIP"], status: "active", search: filter.search });
      setUsers(data.users);
    } catch (e) {
      console.log("a", e);
    }
  }

  if (!users) return <Loader />;

  return (
    <div className="mx-auto">
      <div className="flex-1 space-y-1 py-2">
        <DebounceInput
          debounce={300}
          className="input"
          placeholder="Search (name, email, skills, etc...)"
          value={filter.search}
          onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
        />
      </div>

      <div className="grid grid-cols-1 gap-4">
        {users.map((user, i) => {
          return <UserCard key={i} user={user} />;
        })}
      </div>
    </div>
  );
};

const UserCard = ({ user }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  return (
    <div className="w-full h-full">
      <div className="flex h-full flex-col rounded-xl bg-white p-5 space-y-4 border border-neutral-300">
        <div
          className="group flex space-x-4 cursor-pointer"
          onClick={() => {
            query.set("user_modal_id", user._id);
            navigate({ search: query.toString() });
          }}>
          <div className="w-12 h-12 rounded-full overflow-hidden shadow">
            <img alt="profile" src={user.avatar} className="w-full h-full object-contain" />
          </div>
          <div className="flex flex-col flex-grow">
            <h3 className="text-base font-semibold group-hover:underline">{user.name}</h3>
            <p className="text-sm font-normal font-neutral-700">{user.job_title}</p>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="h-[200px] w-full">
            <CategoryRadar userId={user._id} />
          </div>
          <div className="w-full">
            <SubCategoryRadar userId={user._id} />
          </div>
        </div>
      </div>
    </div>
  );
};
