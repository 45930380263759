import { useNavigate } from "react-router-dom";
import api from "../../../../services/api";
import toast from "react-hot-toast";

const Avoir = ({ invoice }) => {
  const navigate = useNavigate();

  const handleClick = async () => {
    if (!window.confirm("Are you sure you want to issue a credit note ?")) return;

    try {
      const items = [{ name: "Annulation", description: ``, quantity: 1, price: -invoice.netTotal, totalPrice: -invoice.netTotal }];
      const { ok, data } = await api.post(`/invoice`, {
        project_id: invoice.projectId,
        budgetId: invoice.budgetId,
        itemsInvoice: items,
        sent: "yes",
        sentAt: new Date(),
        received: "yes",
        reference: invoice.index,
        receivedAt: new Date(),
        freezed: "yes",
        date: new Date(),
        name: `Invoice ${invoice.index} - Avoir`,
      });
      if (!ok) throw new Error("Error while creating a credit note");

      navigate(`/invoice/${data._id}`);

      toast.success("Invoice updated");
    } catch (error) {
      console.error(error);
      toast.error("Error while creating a credit note");
    }
  };

  return (
    <button className="blue-btn w-full" onClick={handleClick}>
      Issue a credit note
    </button>
  );
};

export default Avoir;
