import { IoTrash } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import { getBase64file } from "../utils";
import SelectUser from "../../../../components/selectUser";

const Contact = ({ values, editing, onChange, lang }) => {
  if (editing) return <ContactForm values={values} onChange={onChange} lang={lang} />;

  return (
    <div className="grid grid-cols-2 gap-2">
      {(values.users || []).map((user, index) => {
        return <ContactCard key={index} user={user} lang={lang} />;
      })}
    </div>
  );
};

const ContactCard = ({ user, lang }) => {
  const [imageB64Url, setImageB64Url] = useState("");

  useEffect(() => {
    if (user.avatar) {
      getBase64file(user.avatar).then((url) => {
        setImageB64Url(url);
      });
    }
  }, [user.avatar]);

  return (
    <div className="flex items-center gap-5 bg-white rounded font-poppins">
      <div className="overflow-hidden">
        <img className="w-28 h-auto rounded-md" src={imageB64Url} alt="User Avatar" />
      </div>
      <div className="flex flex-col">
        <p className="font-medium">{user?.name}</p>
        <p className="text-sm mb-4">{user?.role[lang]}</p>
        <p className="text-sm">{user?.phone}</p>
        <a className="text-sm font-normal">{user?.email}</a>
        {user.links?.map((link, index) => (
          <a className="text-sm font-normal underline cursor-pointer" key={index} href={link.url} target="_blank" rel="noreferrer">
            {link.title}
          </a>
        ))}
      </div>
    </div>
  );
};

const ContactForm = ({ values, onChange, lang }) => {
  const updateUser = (user) => {
    const updatedUsers = values.users.map((u) => (u.id === user.id ? user : u));
    onChange({ ...values, users: updatedUsers });
  };

  const handleChange = (e) => {
    const field = e.target.name;
    if (field === "role") {
      onChange({ ...values, role: { ...values.role, [lang]: e.target.value } });
    } else onChange({ ...values, [e.target.name]: e.target.value });
  };

  const onDeleteUser = (user) => {
    const updatedUsers = values.users.filter((u) => u.id !== user.id);
    onChange({ ...values, users: updatedUsers });
  };

  return (
    <div className="space-y-4">
      <div className="text-sm font-semibold">Users</div>
      <SelectUser
        userRoles={["admin", "user", "applicant"]}
        onChange={(user) => {
          if (!user?._id) return;
          const newUsers = values.users || [];
          newUsers.push({
            id: user._id,
            name: user.name,
            avatar: user.avatar,
            role: { fr: user.job_title, en: user.job_title },
            email: user.email,
            phone: user.phone,
            links: [
              {
                title: "Calendly",
                url: user.calendly,
              },
            ],
          });
          onChange({ ...values, users: newUsers });
        }}
      />

      <div className="flex flex-col gap-2">
        {(values.users || [])
          .slice()
          .reverse()
          .map((user, index) => {
            return (
              <div key={index}>
                <div className="flex gap-2">
                  <img className="w-40 h-40 rounded" src={user?.avatar} alt="User Avatar" />
                  <div className="w-full grid grid-cols-2 gap-2">
                    <input className="input" placeholder="Name" value={user?.name} onChange={(e) => updateUser({ ...user, name: e.target.value })} />
                    <input
                      className="input"
                      placeholder="Role"
                      value={user?.role[lang]}
                      onChange={(e) => updateUser({ ...user, role: { ...user.role, [lang]: e.target.value } })}
                    />
                    <input className="input" placeholder="Phone" value={user?.phone} onChange={(e) => updateUser({ ...user, phone: e.target.value })} />
                    <input className="input" placeholder="Email" type="email" value={user?.email} onChange={(e) => updateUser({ ...user, email: e.target.value })} />
                    {user.links?.map((link, index) => (
                      <>
                        <input
                          className="input"
                          placeholder="Link"
                          value={link?.url}
                          onChange={(e) => {
                            const updatedLinks = user.links.map((l, i) => (i === index ? { ...l, url: e.target.value } : l));
                            updateUser({ ...user, links: updatedLinks });
                          }}
                        />
                        <input
                          className="input"
                          placeholder="Link Title"
                          value={link?.title}
                          onChange={(e) => {
                            const updatedLinks = user.links.map((l, i) => (i === index ? { ...l, title: e.target.value } : l));
                            updateUser({ ...user, links: updatedLinks });
                          }}
                        />
                      </>
                    ))}
                    <button
                      className="blue-btn"
                      onClick={() =>
                        updateUser({
                          ...user,
                          links: [
                            ...user.links,
                            {
                              title: "",
                              url: "",
                            },
                          ],
                        })
                      }>
                      Ajouter un lien
                    </button>
                  </div>
                  <button className="gray-btn h-5 min-w-5 p-0" onClick={() => onDeleteUser(user)}>
                    <IoTrash />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Contact;
