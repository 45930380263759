import React, { useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { IoOpenOutline, IoCopyOutline } from "react-icons/io5";
import { FiPaperclip } from "react-icons/fi";

import { copyToClipboard } from "../../utils";

export default ({ user }) => {
  return (
    <div>
      <div className="p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="bg-white border border-gray-100 rounded-md">
          <div className="p-2 border-b border-gray-100 text-gray-800 text-sm">Personnal information</div>
          <dl className="md:divide-y md:divide-gray-200">
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Full name</dt>
              <dd className="text-sm text-black col-span-2">{user.name}</dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Email address</dt>
              <dd className="text-sm text-black col-span-2">
                <div className="flex items-center gap-1">
                  {user.email}
                  <div
                    className="hover:bg-gray-100 cursor-pointer rounded-lg p-1"
                    onClick={() => {
                      copyToClipboard(user.email);
                      toast.success("mail copied to clipboard");
                    }}>
                    <IoCopyOutline />
                  </div>
                </div>
              </dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Location</dt>
              <dd className="text-sm text-black col-span-2">{user.location}</dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Links</dt>
              <dd className="text-sm text-black col-span-2">
                {user.github ? (
                  <a href={user.github} target="_blank" rel="noreferrer" className="flex items-center gap-1 hover:underline">
                    <span className="flex-1">{user.github}</span> <IoOpenOutline />
                  </a>
                ) : null}
                {user.linkedin ? (
                  <a href={user.linkedin} target="_blank" rel="noreferrer" className="flex items-center gap-1 hover:underline">
                    <span className="flex-1">{user.linkedin}</span> <IoOpenOutline />
                  </a>
                ) : null}
                {user.other ? (
                  <a href={user.other} target="_blank" rel="noreferrer" className="flex items-center gap-1 hover:underline">
                    <span className="flex-1">{user.other}</span> <IoOpenOutline />
                  </a>
                ) : null}
              </dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Attachments</dt>
              <dd className="text-sm text-black col-span-2">
                {user.resume ? (
                  <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                      <div className="flex w-0 flex-1 items-center">
                        <FiPaperclip className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <span className="ml-2 w-0 flex-1 truncate">Resume</span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a href={user.resume} download target="_blank" rel="noreferrer" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Open
                        </a>
                      </div>
                    </li>
                  </ul>
                ) : null}
              </dd>
            </div>
          </dl>
        </div>
        <div className="bg-white border border-gray-100 rounded-md">
          <div className="p-2 border-b border-gray-100 text-gray-800 text-sm">Offer</div>
          <dl className="md:divide-y md:divide-gray-200">
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Application for</dt>
              <dd className="text-sm text-black col-span-2">
                <a href={`/missions/${user.job_id}`} target="_blank" rel="noreferrer" className="flex items-center gap-1 hover:underline">
                  <span className="flex-1">{user.job_title}</span> <IoOpenOutline />
                </a>
              </dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Contracts</dt>
              <dd className="text-sm text-black col-span-2">
                <div className="flex items-center gap-1 flex-wrap">
                  {(user.contracts || []).map((contract) => (
                    <span className="bg-blue-100 text-blue-800 rounded-sm text-xs px-2">{contract}</span>
                  ))}
                </div>
              </dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Experience</dt>
              <dd className="text-sm text-black col-span-2">{user.experience}</dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Expertise</dt>
              <dd className="text-sm text-black col-span-2">
                <div className="flex items-center gap-1 flex-wrap">
                  {user.jobCategory}
                  {(user.expertise || []).map((_expertise) => (
                    <span className="bg-orange-100 text-orange-800 rounded-sm text-xs px-2">{_expertise}</span>
                  ))}
                </div>
              </dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Skills</dt>
              <dd className="text-sm text-black col-span-2">
                <div className="flex items-center gap-1 flex-wrap">
                  {(user.skills || []).map((skill) => (
                    <span className="bg-orange-100 text-orange-800 rounded-sm text-xs px-2">{skill}</span>
                  ))}
                </div>
              </dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">About</dt>
              <dd className="text-sm text-black col-span-2">{user.bio}</dd>
            </div>
            <div className="grid grid-cols-3 gap-4 p-2">
              <dt className="text-sm font-medium text-gray-500">Motivation</dt>
              <dd className="text-sm text-black col-span-2">{user.job_motivation}</dd>
            </div>
          </dl>
        </div>
      </div>

      {user?.job_application_qna && user?.job_application_qna?.length > 0 && (
        <div className="bg-white border border-gray-100 rounded-md mx-2">
          <div className="p-2 border-b border-gray-100 text-gray-800 text-sm">Additional Questions</div>
          <dl className="md:divide-y md:divide-gray-200">
            {user?.job_application_qna?.map((qna) => (
              <div className="p-2">
                <dt className="text-sm font-medium text-gray-500 mb-1">{qna?.question}</dt>
                <dd className="text-sm text-black col-span-2">{qna?.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      )}

      <div className="py-2 md:grid md:grid-cols-3 md:gap-4 md:py-3 px-2">
        <span className="text-xs text-gray-400" data-tip data-for={`${user._id}applicantCreatedAt`}>
          {user.name} was created {moment(new Date(user.created_at)).fromNow()}
        </span>
      </div>
    </div>
  );
};
