import { useEffect, useState } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";

import Occupation from "./occupation";
import All from "./all";
import User from "./user";
import Report from "./report";
import Budget from "./budget";


export default () => {
  return (
    <div className="p-4 space-y-4">
      <div className="overflow-x-scroll">
        <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
          <TabItem key="user" href="/activity" title="User" />
          <TabItem key="report" href="/activity/report" title="Report" />
          <TabItem key="budget" href="/activity/budget" title="Budget" />
          <TabItem key="all" href="/activity/all" title="All people" />
          <TabItem key="occupation" href="/activity/occupation" title="Occupation" />
        </nav>
      </div>
      <Routes>
        <Route path="/" element={<User />} />
        <Route path="/user" element={<User />} />
        <Route path="/report" element={<Report />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="/all" element={<All />} />
        <Route path="/occupation" element={<Occupation />} />
      </Routes>
    </div>
  );
};

const TabItem = ({ href, title }) => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(location.pathname === href);
  }, [location.pathname]);

  return (
    <Link to={href} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
      <div className="flex items-center gap-2 truncate">{title}</div>
    </Link>
  );
};
