import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../../components/loader";

import SelectMonth from "../../components/selectMonth";
import SelectUser from "../../components/selectUser";
import RichTextEditorSun from "../../components/RichTextEditor";

import api from "../../services/api";

export default () => {
  const u = useSelector((state) => state.Auth.user);

  const [month, setMonth] = useState(null);
  const [user, setUser] = useState(u);
  const [selectedDay, setSelectedDay] = useState(moment().date());
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    load();
  }, [month, user]);

  async function load() {
    setLoading(true);
    if (!month) return;

    const fromDate = moment(month, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
    const toDate = moment(month, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
    const { data } = await api.post("/activity_report/search", { fromDate, toDate, user_id: user._id });
    setReports(data);

    const { data: tickets } = await api.post("/notion/search", { activity_date: selectedDay, user_id: user._id });
    let desc = "";
    for (let i = 0; i < tickets.length; i++) {
      const logs = tickets[i].logs.filter((log) => log.user_id === user._id && new Date(log.created_at).getDate() === selectedDay);
      for (let j = 0; j < logs.length; j++) {
        if (!logs[j].field) {
          desc += `I created ticket ${tickets[i].name} in ${tickets[i].project_name}  at ${new Date(logs[j].created_at).toLocaleTimeString()}<br/><br/>`;
          continue;
        }
        if (logs[j].field === "comments") {
          desc += `I added a comment on the ticket "${tickets[i].name}" in ${tickets[i].project_name} at ${new Date(logs[j].created_at).toLocaleTimeString()}<br/><br/>`;
          continue;
        }
        if (logs[j].field === "status") {
          desc += `I changed the status of the ticket "${tickets[i].name}" in ${tickets[i].project_name} at ${new Date(logs[j].created_at).toLocaleTimeString()} <br/><br/>`;
          continue;
        }
      }
    }

    setDescription(desc);

    setLoading(false);
  }

  if (user === null) return <Loader />;

  // console.log(reports);

  // const today = moment().format("YYYY-MM-DD");
  // const anotherMarkedDay = moment(date).endOf("month").format("YYYY-MM-DD"); // example: mark end of the month
  const selected = reports.find((r) => moment(r.date).date() === selectedDay);

  async function handleSave() {
    try {
      setLoading(true);
      if (selected) {
        const { ok, data } = await api.put(`/activity_report/${selected._id}`, { description });
        if (!ok) return toast.error("Failed to update report");
        const index = reports.findIndex((r) => r._id === selected._id);
        reports[index] = data;
        setReports([...reports]);
        toast.success("Report updated");
        setLoading(false);
        return;
      }

      // take month + date
      const date = moment(month, "YYYY-MM").date(selectedDay).format("YYYY-MM-DD");
      const { ok, data } = await api.post("/activity_report", { description, date, user_id: user._id });
      if (!ok) return toast.error("Failed to create report");
      setReports([...reports, data]);
      toast.success("Report created");
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to create report");
    }
  }

  //   J'ai bosse sur un peu l'aquisition (1h)
  // J'ai prepare la semaine (avec les objectifs PO, PM etc .. )
  // J'ai reflechi strategie , notament avec DIMAS

  const markedDays = reports.map((r) => moment(r.date).format("YYYY-MM-DD"));

  console.log("selected day", selected?.description);
  console.log("marked days", description);

  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden w-screen md:w-full p-2">
      <div className="flex mb-2">
        <SelectUser disabled={u.role !== "admin"} value={user} onChange={setUser} />
        <div className="mr-2" />
        <SelectMonth start={-3} indexDefaultValue={3} value={month} onChange={(e) => setMonth(e.target.value)} showArrows />
      </div>
      <DaySelector
        month={month}
        selectedDay={selectedDay}
        onSelect={(e) => {
          setDescription("");
          setSelectedDay(e);
        }}
        markedDays={markedDays}
      />
      <div className="mb-2" />

      <div className="grid grid-cols-2 gap-2">
        <form
          className="p-4 flex flex-col items-center"
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}>
          <RichTextEditorSun
            buttonList={[
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle", "blockquote"],
              ["bold", "underline"],
              ["align", "list"],
            ]}
            placeholder="Project XPLORE LAAX : 2h of testing the LAAX app - 2h of testing the Admin app"
            values={selected?.description || description}
            options={{ minHeight: "180px" }}
            onChange={(e) => setDescription(e)}
          />
          <div className="flex justify-end gap-2 mt-2">
            <button type="submit" className="blue-btn">
              {selected ? "Update" : "Create"}
            </button>
            {selected && (
              <button
                className="red-btn"
                onClick={async (e) => {
                  if (!confirm("Are you sure you want to delete this report?")) return;
                  e.preventDefault();
                  await api.remove(`/activity_report/${selected._id}`);
                  const index = reports.findIndex((r) => r._id === selected._id);
                  reports.splice(index, 1);
                  setReports([...reports]);
                  toast.success("Report deleted");
                }}>
                Reset
              </button>
            )}
          </div>
        </form>
        <div>
          {loading && <div>loading</div>}
          {!loading && <BlockList report={selected} />}
        </div>
      </div>
    </div>
  );
};

function displayInHourOrMinute(duration) {
  if (duration < 60) return `${duration}m`;
  if (duration % 60 === 0) return `${duration / 60}h`;
  return `${Math.floor(duration / 60)}h ${duration % 60}m`;
}

const BlockList = ({ report }) => {
  if (!report) return null;

  const time = report.blocks.reduce((acc, block) => acc + block.duration, 0);

  return (
    <div className="container">
      {(report.blocks || []).map((block, index) => (
        <div key={index} className="task-card bg-white border-gray-200 border rounded-sm p-3 mb-2">
          <div className="flex items-center mb-1">
            <img src={block.projectLogo} alt="Project Logo" className="w-12 h-12 mr-2 object-fit rounded-md" />
            <div className="flex-grow">
              <h3 className="text-md font-bold">{block.projectName}</h3>
              {/* <p className="text-sm">s{block.projectId}</p> */}
              <p className="text-sm">{block.task}</p>
            </div>
            <span className="text-xs text-gray-600">{displayInHourOrMinute(block.duration)}</span>
          </div>
        </div>
      ))}
      <div className="total-duration bg-gray-100 border-t border-gray-200 mt-2 p-3">
        <strong>Total Duration:</strong> {displayInHourOrMinute(time)}
      </div>
    </div>
  );
};

const DaySelector = ({ month, selectedDay, onSelect, markedDays }) => {
  const days = Array.from({ length: moment(month, "YYYY-MM").daysInMonth() }, (_, i) => {
    const dayDate = moment(month, "YYYY-MM").date(i + 1);
    return {
      number: i + 1,
      letter: dayDate.format("dd").charAt(0),
      date: dayDate.format("DD"),
      isWeekend: ["Sa", "Su"].includes(dayDate.format("dd")),
      isMarked: markedDays.includes(dayDate.format("YYYY-MM-DD")),
    };
  });

  return (
    <div className="flex overflow-auto">
      {days.map((day) => (
        <button
          key={day.number}
          onClick={() => onSelect(day.number)}
          className={`p-2 rounded-md text-sm font-semibold ${selectedDay === day.number ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}  ${
            day.isWeekend ? "bg-red-200" : ""
          } mr-1`}>
          {day.letter} {day.date}
          {day.isMarked && <span className="ml-1 h-2 w-2 bg-red-500 rounded-full inline-block"></span>}
        </button>
      ))}
    </div>
  );
};
