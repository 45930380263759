import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";

import SelectMonth from "../../components/selectMonth";
import SelectContact from "../../components/SelectContact";
import LoadingButton from "../../components/loadingButton";

import api from "../../services/api";

const Activity = () => {
  const [date, setDate] = useState(null);
  const [contract, setContarct] = useState("");
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [activities, setActivities] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!date) return;
    load();
  }, [date, contract]);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const date = params.get("date");
    if (date) setDate(new Date(date));
  }, []);

  async function load() {
    setLoading(true);
    const { data } = await api.get(`/activity?date=${date?.getTime()}&userContract=${contract}`);
    setLoading(false);

    let arr = [];

    for (let i = 0; i < data.length; i++) {
      const found = arr.find((e) => e.userId === data[i].userId);
      if (!found) {
        const obj = { userId: data[i].userId, detail: data[i].detail.map((e) => e.value), userName: data[i].userName, total: data[i].total, userContract: data[i].userContract };
        obj.unavailable = 0;
        obj.holidays = 0;
        obj.rtt = 0;
        obj.arret_maladie = 0;
        if (data[i].projectName === "Unavailable") obj.unavailable = data[i].total || 0;
        if (data[i].projectName === "Leave paid") obj.holidays = data[i].total || 0;
        if (data[i].projectName === "RTT") obj.rtt = data[i].total || 0;
        if (data[i].projectName === "Arret Maladie") obj.arret_maladie = data[i].total || 0;
        arr.push(obj);
      } else {
        if (data[i].projectName === "Unavailable") found.unavailable += data[i].total;
        if (data[i].projectName === "Leave paid") found.holidays += data[i].total;
        if (data[i].projectName === "RTT") found.rtt += data[i].total;
        if (data[i].projectName === "Arret Maladie") found.arret_maladie += data[i].total;
        found.total += data[i].total;
        for (let j = 0; j < data[i].detail.length; j++) {
          found.detail[j] += data[i].detail[j].value;
        }
      }
    }

    arr.sort((a, b) => a.total - b.total);
    setActivities(arr);
  }

  const handleExport = async () => {
    setExporting(true);
    const arr = [];
    for (let activity of activities) {
      const obj = { ...activity };

      const { data: user } = await api.get(`/user/${activity.userId}`);
      obj.bsn = user.bsn;
      obj.salary_comment = user.salary_comment;
      obj.job_title = user.job_title;
      if (user.starting_date) obj.starting_date = new Date(user.starting_date).toISOString().slice(0, 10);

      obj.holidays = activity.holidays / 8;
      obj.unavailable = activity.unavailable / 8;
      obj.rtt = activity.rtt / 8;
      obj.arret_maladie = activity.arret_maladie / 8;
      obj.daysWorked = activity.total / 8;
      if (activity.unavailable || activity.holidays) obj.daysWorked = (activity.total - ((activity?.unavailable || 0) + (activity?.holidays || 0))) / 8;
      if (activity.total) obj.daysWorked = obj.daysWorked.toFixed(1);
      if (activity.unavailable) obj.unavailable = obj.unavailable.toFixed(1);
      if (activity.holidays) {
        obj.holidays = obj.holidays.toFixed(1);
        obj.holiday_details = await getDays(obj, encodeURIComponent("Leave paid"));
      }
      if (activity.rtt) {
        obj.rtt = obj.rtt.toFixed(1);
        obj.rtt_details = await getDays(obj, "RTT");
      }
      if (activity.arret_maladie) {
        obj.arret_maladie = obj.arret_maladie.toFixed(1);
        obj.arret_maladie_details = await getDays(obj, "Arret Maladie");
      }
      arr.push(obj);
    }
    await exportData(arr);
    setExporting(false);
  };

  async function getDays(activity, project) {
    const dateFrom = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
    const dateTo = new Date(dateFrom);
    dateTo.setMonth(dateTo.getMonth() + 1);
    dateTo.setDate(0);

    const { data } = await api.get(`/activity?userId=${activity.userId}&projectName=${project}&dateFrom=${dateFrom.getTime()}&dateTo=${dateTo.getTime()}`);

    let ranges = "";

    for (let i = 0; i < data.length; i++) {
      const slots = [];
      for (let j = 0; j < data[i].detail.length; j++) {
        if (data[i].detail[j].value === 0) continue;
        const slotDate = new Date(data[i].date);
        slotDate.setDate(j + 1);
        if (slotDate < date) continue; // skip past days
        slots.push(slotDate.toISOString().slice(0, 10));
      }
      if (slots.length == 0) continue;

      ranges = slots.sort((a, b) => a - b);
    }

    if (!ranges || !ranges.length) return "";

    return ranges.join(", ");
  }

  const days = getDaysInMonth(date?.getMonth(), date?.getFullYear());
  const getTotal = () => (activities.reduce((acc, a) => acc + a.total, 0) / 8).toFixed(2);

  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden w-screen md:w-full">
      <div className="flex items-center space-x-4 px-8">
        <SelectMonth start={-3} indexDefaultValue={3} value={date} onChange={(e) => setDate(e.target.value)} showArrows />
        <SelectContact value={contract} onChange={(contract) => setContarct(contract)} />
        <LoadingButton loading={exporting} className="btn btn-primary self-start" onClick={handleExport}>
          Export
        </LoadingButton>
      </div>

      <div className="flex flex-wrap py-3 gap-4 text-black">
        <div className="w-screen md:w-full p-2 md:!px-8">
          {loading ? (
            <div>loading...</div>
          ) : (
            <div className="mt-2 rounded-xl bg-[#fff] overflow-auto">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="py-[10px] text-[14px] font-bold text-[#212325] text-left pl-[10px]">Users</th>
                      {days.map((e) => {
                        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                        const _date = new Date(e);
                        const day = _date.getDay();
                        const weekday = days[day];
                        const date = _date.getDate();
                        return (
                          <th
                            className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center ${day == 0 || day == 6 ? "bg-[#FFD5F1]" : "bg-[white]"}`}
                            key={e}
                            day={day}>
                            <div>{weekday}</div>
                            <div>{date}</div>
                          </th>
                        );
                      })}
                      <th className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center bg-[white]`} />
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-t border-b border-r border-[#E5EAEF]">
                      <th className="px-2">
                        <div className="flex justify-end w-full text-[12px] font-bold text-[#212325] italic">
                          <div>{`${getTotal()} days`}</div>
                        </div>
                      </th>
                    </tr>
                    {activities.map((e, i) => {
                      return (
                        <React.Fragment key={e.projectId}>
                          <tr key={`${e.userName}-${i}`} className="border-t border-b border-r border-[#E5EAEF]">
                            <th className="w-[100px] border-t border-b border-r text-[12px] font-bold text-[#212325] text-left">
                              <div className="flex flex-1 items-center justify-between gap-1 px-2">
                                <button
                                  className="flex flex-1 items-center justify-start gap-1 hover:underline"
                                  onClick={() => navigate(`/activity?username=${e.userName}&user_id=${e.userId}&date=${date.toISOString().split("T")[0]}`)}>
                                  <div className="flex-1 text-left">{e.userName}</div>
                                  <HiArrowTopRightOnSquare />
                                </button>
                                <div className="flex flex-col items-end">
                                  <div className="text-xs italic font-normal">{(e.total / 8).toFixed(2)} days</div>
                                </div>
                              </div>
                            </th>
                            {e.detail.map((e, i) => {
                              return <Field key={`day-${i}`} value={e} disabled />;
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Field = ({ value = 0, onChange, invoiced, ...rest }) => {
  let bgColor = invoiced === "yes" ? "bg-[#F0F0F0]" : "bg-[white]";
  let textColor = "text-[#000]";
  if (value >= 7) {
    bgColor = "bg-[#216E39]";
    textColor = "text-[#fff]";
  } else if (value >= 5) {
    bgColor = "bg-[#30A14E]";
  } else if (value >= 3) {
    bgColor = "bg-[#40C463]";
  } else if (value > 0) {
    bgColor = "bg-[#9BE9A8]";
  } else {
    textColor = "text-[#aaa]";
  }

  return (
    <th className={`border border-[#E5EAEF] py-[6px] px-0 ${bgColor} m-0`}>
      <input
        className={`border-none min-w-[30px] w-full text-center ${bgColor} ${textColor} p-0`}
        disabled={invoiced === "yes"}
        value={value}
        min={0}
        {...rest}
        type="number"
        step="0.1"
        onChange={onChange}
        onFocus={(e) => {
          if (Number(e.target.value) === 0) {
            e.target.value = "";
          }
        }}
        onBlur={(e) => {
          if (e.target.value === "") {
            e.target.value = 0;
          }
        }}
      />
    </th>
  );
};

export default Activity;

function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  date.setHours(0, 0, 0, 0);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

async function exportData(entities) {
  if (!entities.length) return;

  const fileName = "users_availablity-" + new Date().toISOString();
  const customColumns = [
    "userName",
    "job_title",
    "daysWorked",
    "holidays",
    "holiday_details",
    "rtt",
    "rtt_details",
    "arret_maladie",
    "arret_maladie_details",
    "unavailable",
    "userContract",
    "bsn",
    "salary_comment",
    "starting_date",
  ];

  const columns = customColumns;

  const csv = [];

  for (let j = 0; j < entities.length; j++) {
    let obj = {};
    for (let i = 0; i < columns.length; i++) {
      let value = entities[j][customColumns[i]];
      if (!value) value = "";
      obj = { ...obj, [customColumns[i]]: value };
    }
    csv.push(obj);
  }
  exportCSVFile(columns, csv, fileName);
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ";";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
}
