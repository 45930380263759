import React, { useEffect, useState } from "react";
import api from "../services/api";

export default ({ value, onChange, className }) => {
  const [channels, setChannels] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await api.post("/jobchannel/search", {
        pageSize: 1000,
      });
      setChannels(res.data);
    })();
  }, []);

  console.log("value", value);

  return (
    <div>
      <select
        className={`${
          className
            ? className
            : "w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm"
        }`}
        value={value?._id || ""}
        onChange={(e) => {
          e.preventDefault();
          const f = channels.find((f) => e.target.value === f._id);
          onChange(f);
        }}>
        <option disabled>Channel</option>
        <option value={""}>All Channel</option>
        {channels
          .sort(function (a, b) {
            if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
            if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
            return 0;
          })
          .map((e) => {
            return (
              <option key={e._id} value={e._id}>
                {`${e.name}`}
              </option>
            );
          })}
      </select>
    </div>
  );
};
