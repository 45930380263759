//I can't use tailwind with react-mentions, so I have to use this style.js file
export default {
  backgroundColor: "white",
  width: "100%",
  height: "auto",
  padding: "1rem",
  border: "none",
  resize: "none",
  display: "block",
  ":focus": { outline: "none" },
  control: {
    minHeight: 60,
  },
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
      "max-height": "100px",
      "overflow-y": "auto",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#E5E7EB",
      },
    },
  },
};
