import React, { useState } from "react";
import toast from "react-hot-toast";
import Comments from "../../components/Comments";

import MultipleSelectInput from "../../components/MultipleSelectInput";
import api from "../../services/api";

import { toDateInputValue } from "../../utils";

const View = ({ value, onChange }) => {
  const [values, setValues] = useState(value);

  async function onSubmit() {
    const { data } = await api.put(`/lead/${value._id}`, values);
    toast.success("Project created successfully");
    onChange();
  }

  async function deleteData() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/lead/${value._id}`);
    toast.success("successfully removed!");
    onChange();
  }

  return (
    <div className="mb-3">
      <div className="absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => onChange(false)}>
        <div
          className="w-full md:w-[80%] h-fit  bg-[white] p-[25px] rounded-md"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <React.Fragment>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div>
                <div className="p-3 md:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">Lead information</h1>
                </div>
                <div className="p-3 !pt-0 grid grid-cols-1 md:grid-cols-2 gap-3">
                  <UserInput label="Name" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
                  <UserInput label="Contact" name="contact" value={values.contact} onChange={(e) => setValues({ ...values, contact: e.target.value })} />
                  <UserInput label="Budget" name="budget" value={values.budget} onChange={(e) => setValues({ ...values, budget: e.target.value })} />
                  <UserInput label="Website" name="website" value={values.website} onChange={(e) => setValues({ ...values, website: e.target.value })} />
                  <div className="w-full">
                    <div className="px-1 text-sm text-gray-600 font-medium">Tags</div>
                    <MultipleSelectInput value={values.tags} onChange={(tags) => setValues({ ...values, tags })} />
                  </div>
                  <UserInput
                    type="date"
                    label="Deadline"
                    name="deadline"
                    value={toDateInputValue(values.deadline)}
                    onChange={(e) => setValues({ ...values, deadline: e.target.value })}
                  />
                  <div className="col-span-1 md:col-span-2">
                    <div className="px-1 text-sm text-gray-600 font-medium">
                      Closing probability - <span className="bg-primary px-2 rounded-md text-white">{values.closing_probability}%</span>
                    </div>
                    <input
                      type="range"
                      className="h-2 w-full cursor-ew-resize appearance-none rounded-full bg-gray-200 disabled:cursor-not-allowed"
                      value={values.closing_probability || 0}
                      onChange={(e) => setValues({ ...values, closing_probability: e.target.value })}
                    />
                  </div>
                  <div className="col-span-1 md:col-span-2">
                    <div className="px-1 text-sm text-gray-600 font-medium">Description</div>
                    <textarea
                      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
                      rows="12"
                      name="description"
                      value={values.description}
                      onChange={(e) => setValues({ ...values, description: e.target.value })}></textarea>
                  </div>
                </div>
              </div>
              <div>
                <div className="p-3 md:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">Comments</h1>
                </div>
                <Comments
                  value={values.comments}
                  onAdd={async (text) => {
                    const { data } = await api.post(`/lead/comment/${values._id}`, { text });
                    setValues(data);
                    toast.success("Comment added!");
                  }}
                />
              </div>
            </div>
            <br />
            <button className="mt-[1rem]  bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onSubmit}>
              Update
            </button>
            <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={deleteData}>
              Delete
            </button>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

const UserInput = ({ name, value, type = "text", onChange, label, className, disabled }) => (
  <div className={`${className} w-full`}>
    <div className="px-1 text-sm text-gray-600 font-medium">{label}</div>
    <input
      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
    />
  </div>
);

export default View;
