const environment = getEnvironment();

let apiURL = "";
let appURL = "";

if (environment === "development") {
  apiURL = "http://localhost:8080";
  appURL = "http://localhost:8082";
}
if (environment === "production") {
  apiURL = "https://api-accounting.selego.co";
  appURL = "https://accounting.selego.co";
}

const SENTRY_DSN = "https://471e8848ed9f4d31adc1091d4b6bcf92@sentry.selego.co/30";

function getEnvironment() {
  if (window.location.href.indexOf("app-staging") !== -1) return "staging";
  if (window.location.href.indexOf("localhost") !== -1 || window.location.href.indexOf("127.0.0.1") !== -1) return "development";
  return "production";
}

export { apiURL, appURL, SENTRY_DSN, environment };
