import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/loader";
import api from "../../../services/api";

import Info from "./info";
import Edit from "./edit";
import Raw from "../../../components/Raw";

const TABS = [
  { title: "Info", tab: "info" },
  { title: "Edit", tab: "edit" },
  { title: "Raw", tab: "raw" },
];

const View = () => {
  const [tab, setTab] = useState("info");
  const [data, setData] = useState();

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get(`/perk/${id}`);
        setData(data);
      } catch (e) {
        console.log("a", e);
      }
    };
    fetchData();
  }, [id]);

  if (!data) return <Loader />;

  return (
    <div className="py-8 px-10">
      <div className="mb-4">
        <TabBar tabs={TABS} setTab={setTab} active={tab} />
      </div>

      <div>
        {
          {
            info: <Info perk={data} />,
            edit: <Edit perk={data} setPerk={setData} />,
            raw: <Raw data={data} />,
          }[tab]
        }
      </div>
    </div>
  );
};

const TabBar = ({ tabs, setTab, active }) => (
  <nav className="bg-white rounded-md flex item-center gap-2 w-full border border-neutral-300 overflow-hidden p-1">
    {tabs.map((tab, i) => (
      <TabItem key={i} tab={tab.tab} title={tab.title} setTab={setTab} active={active === tab.tab} />
    ))}
  </nav>
);

const TabItem = ({ tab, title, setTab, active }) => (
  <button onClick={() => setTab(tab)} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);

export default View;
