import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Loader from "../../components/loader";
import LoadingButton from "../../components/loadingButton";
import Pagination from "../../components/pagination";
import Modal from "../../components/modal";

import QueryBuilder from "./queryBuilder";

import api from "../../services/api";

const MissionsAo = () => {
  const [missions, setMissions] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const [filters, setFilters] = useState({ search: "", query: null, page: 1 });

  useEffect(() => {
    update();
  }, [filters]);

  async function update() {
    setLoading(true);
    const { data, total: t } = await api.post(`/mission_ao/search`, filters);
    setMissions(data);
    setTotal(t);
    setLoading(false);
  }

  return (
    <div className="flex w-full space-x-4">
      {/* <Alerts filters={filters} setFilters={setFilters} alert={alert} setAlert={setAlert} /> */}
      <div className="py-4 mb-4 w-full flex-1">
        <div className="flex items-center justify-between mb-4 space-x-4 w-full">
          <div className="bg-white mb-1 rounded-xl">
            <Search filters={filters} setFilters={(e) => setFilters({ ...filters, ...e })} />
          </div>
          <div className="!ml-auto flex items-center space-x-2">
            {alert && (
              <>
                <button
                  className="btn btn-primary p-2 text-sm"
                  onClick={async () => {
                    await api.put(`/alert/${alert._id}`, { query: filters.query, frequency: alert.frequency });
                    toast.success("Alert updated successfully");
                  }}>
                  {`Update ${alert.name}`}
                </button>
                <button
                  className="btn red-btn p-2 text-sm"
                  onClick={async () => {
                    if (!window.confirm("Are you sure you want to delete this alert ?")) return;
                    await api.remove(`/alert/${alert._id}`);
                    toast.success("Alert deleted successfully");
                    setAlert(null);
                    setFilters({ search: "", query: null, page: 1 });
                  }}>
                  {`Delete ${alert.name}`}
                </button>
              </>
            )}
            <div className="text-sm font-medium text-gray-700">{`${total} results`}</div>
            <ExportMission filters={filters} />
          </div>
        </div>
        <Results loading={loading} missions={missions} total={total} filters={filters} />
        <div className="mb-2 mt-2">
          <Pagination
            per_page={filters.perPage}
            total={total}
            onNext={() => setFilters((f) => ({ ...f, page: +f.page + 1 }))}
            onPrevious={() => setFilters((f) => ({ ...f, page: +f.page - 1 }))}
            currentPage={filters.page}
          />
        </div>
      </div>
    </div>
  );
};

export default MissionsAo;

const Results = ({ loading, missions }) => {
  const navigate = useNavigate();
  if (loading) {
    return (
      <div className="flex justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="space-y-2">
        {missions.map((mission) => {
          return (
            <div
              onClick={() => navigate(`/missions_ao/${mission._id}`)}
              key={mission._id}
              className="text-sm p-2 group border border-gray-200 mb-2 hover:border-gray-300 cursor-pointer bg-white grid grid-cols-12 items-center">
              <div className="col-span-6">
                <div>
                  <h3 className="text-base mb-1">{ mission.name || mission.offer_name }</h3>
                  <div>
                    {(mission.sectors || []).map((sector) => (
                      <span key={sector} className="bg-blue-100 text-blue-600 px-1 mr-2 text-xs">
                        {sector}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-span-2">{/* <div>{mission.origin}</div> */}</div>
              <div className="col-span-2">
                <div>{mission.origin}</div>
              </div>
              <div className="col-span-2  whitespace-nowrap">{new Date(mission.closure_date).toLocaleString()}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const Search = ({ setFilters }) => {
  const [query, setQuery] = useState();

  return (
    <div className="flex-1">
      <div className="pr-2 pl-2 flex items-center space-x-4">
        <QueryBuilder query={query} onQuery={(query) => setQuery(query)} />
        <button type="submit" className="btn btn-primary py-1" onClick={() => setFilters({ query })}>
          Search
        </button>
      </div>
    </div>
  );
};

const Alerts = ({ filters, setFilters, alert, setAlert }) => {
  const [alerts, setAlerts] = useState([]);
  const [alertId, setAlertId] = useState(null);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    setAlertId(queryParameters.get("alertId"));
    run();
  }, [filters]);

  async function run() {
    const { data } = await api.get("/alert");
    setAlerts(data);
  }

  useEffect(() => {
    if (!alerts) return;
    const a = alerts.find((e) => e._id.toString() === alertId);
    if (a) {
      setAlert(a);
      setFilters({ query: a.query.bool, search: "", page: 1 });
    }
  }, [alerts]);

  return (
    <div className="max-w-[160px] w-full border-r border-gray-300 pt-5 px-2">
      <div
        className={`p-1 border-blue-600 cursor-pointer ${!alert?._id && "text-blue-600"}`}
        onClick={() => {
          setFilters({ search: "", query: null, page: 1 });
          setAlert(null);
        }}>
        All
      </div>
      {alerts.map((a) => {
        return (
          <div
            key={a._id}
            className={`p-1 text-base cursor-pointer ${alert?._id === a._id && "text-blue-600 underline"}`}
            onClick={() => {
              setAlert(a);
              setFilters({ query: a.query, search: "", page: 1 });
            }}>
            {a.name}
          </div>
        );
      })}
      <CreateAlert filters={filters} />
    </div>
  );
};

const CreateAlert = ({ filters }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onCreate() {
    if (!values.name) return toast.error("Missing Name !");
    let query = filters.query;
    const { data } = await api.post(`/alert_oa`, { query, name: values.name });
    toast.success("Alert created successfully");
    setOpen(false);
  }
  return (
    <div className="mt-5">
      <button
        className="btn btn-primary text-xs"
        onClick={() => {
          if (!filters.query?.length) return toast.error("Please work with a query");
          setOpen(true);
        }}>
        Create an alert
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name</div>
          <input className="projectsInput block" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};

const ExportMission = ({ filters }) => {
  const [loading, setLoading] = useState(false);

  const onExport = async () => {
    try {
      setLoading(true);
      const obj = { ...filters };
      obj.page = 0;
      obj.perPage = 2000;
      const { data } = await api.post(`/mission_ao/search`, obj);
      exportData(data);

      toast.success("Exported!");
    } catch (error) {
      toast.error("Error exporting mission");
      console.log(error);
    }
    setLoading(false);
  };

  async function exportData(entities) {
    if (!entities.length) return;

    const fileName = "missions-ao -" + new Date().toISOString();
    const customColumns = ["offer_name", "city", "closure_date", "sectors", "reconduction_dates", "url"];

    const columns = customColumns.map((e) => e.title);

    const csv = [];

    for (let j = 0; j < entities.length; j++) {
      let obj = {};
      for (let i = 0; i < columns.length; i++) {
        let value = entities[j][customColumns[i]];
        if (!value) value = "";
        obj = { ...obj, [customColumns[i]]: value };
      }
      csv.push(obj);
    }
    // initiateFileDownload(csv, fileName);
  }

  return (
    <LoadingButton loading={loading} onClick={onExport} className="btn btn-primary">
      Export
    </LoadingButton>
  );
};
