import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

export default ({ group }) => {
  const [data, setData] = useState(null);
  const [sortField, setSortField] = useState("totalRemaining");
  const [sortDirection, setSortDirection] = useState(false); // true for ascending, false for descending

  const sortData = (field) => {
    setSortField(field);
    setSortDirection(!sortDirection);
  };

  const sortedData = data
    ? [...data].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection ? 1 : -1;
        return 0;
      })
    : null;

  useEffect(() => {
    get();
  }, [group]);

  async function get() {
    const obj = {};
    if (group) obj.groupId = group;
    const res = await api.post(`/report/cashout`, obj);
    setData(res.data);
  }

  if (!sortedData) return <div>Loading</div>;

  const total = data.filter((a) => a.total).reduce((acc, a) => a.totalRemaining + acc, 0);

  return (
    <div>
      <h1 className="text-2xl font-bold">Cashout {total.toFixed(2)}€</h1>
      <table className="table-auto w-full mt-4">
        <thead>
          <tr>
            <th className="text-left">
              <button onClick={() => sortData("name")}>Name {sortField === "name" && (sortDirection ? "↑" : "↓")}</button>
            </th>
            <th className="text-left">
              <button onClick={() => sortData("total")}>Total {sortField === "total" && (sortDirection ? "↑" : "↓")}</button>
            </th>
            <th className="text-left">
              <button onClick={() => sortData("totalRemaining")}>Remaining {sortField === "totalRemaining" && (sortDirection ? "↑" : "↓")}</button>
            </th>
            <th className="text-left">
              <button onClick={() => sortData("alreadyPaid")}>Already Paid {sortField === "alreadyPaid" && (sortDirection ? "↑" : "↓")}</button>
            </th>
            <th className="text-left">
              <button onClick={() => sortData("late")}>Days Late {sortField === "late" && (sortDirection ? "↑" : "↓")}</button>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((e) => {
            let alreadyPaid = 0;
            if (e.totalRemaining !== e.total) alreadyPaid = e.total - e.totalRemaining;
            return (
              <tr key={e.name} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="">
                  <Link to={`/invoice/${e._id}`} key={e.name} className="">
                    <div className="text-sm font-semibold text-left">{e.name}</div>
                  </Link>
                </td>
                <td className="text-left">{e.total.toFixed(2)}€</td>
                <td className="text-left">{e.totalRemaining.toFixed(2)}€</td>
                <td className="text-left">{alreadyPaid.toFixed(2)}€</td>
                <td className="text-left">{e.late > 0 && <span className="text-sm text-red-600 ml-2">{e.late} days late</span>}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

// <div className=" bg-white mt-4 p-4 rounded shadow">
// <div className="grid grid-cols-8 gap-2 mt-2">
//   {data.map((e) => {
//     let alreadyPaid = "";
//     if (e.totalRemaining !== e.total) alreadyPaid = e.total - e.totalRemaining;
//     return (
//       <Link to={`/invoice/${e._id}`} key={e.name} className="flex flex-col items-center">
//         <div className="text-md font-semibold">{e.totalRemaining.toFixed(2)}€</div>
//         <div className="text-sm text-gray-600">{e.name}</div>
//         {alreadyPaid && <div className="text-md font-semibold">{alreadyPaid} already paid</div>}
//         {e.late > 0 && <span className="text-sm text-red-600 ml-2">({e.late} days late)</span>}
//       </Link>
//     );
//   })}
// </div>
// </div>
