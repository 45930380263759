import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IoMdDocument } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";

import { copyToClipboard } from "../../../utils";

import SelectMonth from "../../../components/selectMonth";
import api from "../../../services/api";
import LoadingButton from "../../../components/loadingButton";
import { PAYMENTS } from "../../../constants";

const ToPayView = ({ payment, setPayment }) => {
  const [user, setUser] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    update();
  }, [id]);

  async function update() {
    const { data: u } = await api.get(`/user/${payment.user_id}`);
    setUser(u);
  }

  async function deleteData() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/payment/${id}`);
    toast.success("successfully removed!");
    // navigate(`/topay`);
  }

  const str = `${new Date(payment.date).toLocaleString("default", { month: "long" })} ${new Date(payment.date).getFullYear()} ${user?.name}`;

  async function handleSubmit() {
    try {
      const { ok } = await api.put(`/payment/${payment._id}`, payment);
      if (!ok) throw new Error("Some Error!");
      toast.success("Updated!");
    } catch (err) {
      toast.error("Some Error!");
      console.log(err);
    }
  }

  return (
    <div className="">
      <div className=" bg-[#FFFFFF] p-4 rounded-[10px]">
        <span
          className="text-[18px] font-semibold	text-[#212325] cursor-pointer"
          onClick={() => {
            copyToClipboard(str);
            toast.success("Copied to clipboard!");
          }}>
          {str}
        </span>
        <div className="flex justify-between flex-wrap">
          <User user={user} payment={payment} />
          <div>
            <div className="w-full lg:w-[700px] flex flex-wrap gap-4">
              <div className="w-full md:w-[170px]">
                <label className="text-[14px] font-medium text-[#212325] mb-0">Month</label>
                <div className="mt-2">
                  <SelectMonth
                    placeholder="Select month"
                    indexDefaultValue={0}
                    value={payment.date}
                    onChange={(e) => setPayment({ ...payment, date: e.target.value })}
                    name="date"
                  />
                </div>
              </div>
              <div className="w-full md:w-[170px]">
                <label className="text-[14px] font-medium text-[#212325] mb-0">Value</label>
                <input
                  className="projectsInput rounded-[10px] text-[14px] font-medium"
                  name="value"
                  type="number"
                  value={payment.value}
                  onChange={(e) => setPayment({ ...payment, value: e.target.value })}
                />
              </div>
              <div className="w-full md:w-[170px]">
                <label className="text-[14px] font-medium text-[#212325] mb-0">Status</label>
                <select
                  className="projectsInput rounded-[10px] text-[14px] font-medium text-[#212325] cursor-pointer"
                  type="select"
                  name="status"
                  value={payment.status}
                  onChange={(e) => setPayment({ ...payment, status: e.target.value })}>
                  <option value="" disabled label="Status">
                    Status
                  </option>
                  {Object.keys(PAYMENTS).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full md:w-[300px] flex">
                <UploadInvoice name="files" value={payment.files} onChange={(e) => setPayment({ ...payment, files: e.target.value })} />
              </div>
            </div>
            <div className="w-full mt-2">
              <span className="text-[14px] font-medium text-[#212325] ">Comment (visible for the user)</span>
              <textarea
                className="w-full border border-[#ced4da] rounded-[10px] text-[14px] p-2 mt-2 focus:outline-none focus:ring focus:ring-[#80bdff]"
                type="textarea"
                placeholder="Please add a comment on what you in your mind...."
                rows="6"
                name="comment"
                value={payment.comment}
                onChange={(e) => setPayment({ ...payment, comment: e.target.value })}
              />
            </div>
            <div className="flex justify-end mt-2">
              <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={deleteData}>
                Delete
              </button>
              <LoadingButton className="ml-[10px] bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={handleSubmit}>
                Save
              </LoadingButton>
              <Pay user={user} payment={payment} description={str} onPaid={update} />
            </div>
          </div>
        </div>
        <div className="w-full">
          <Previous user={user} />
        </div>
      </div>
    </div>
  );
};

const Pay = ({ user, payment, description, onPaid }) => {
  const [loading, setLoading] = useState(false);

  if (!user) return <div />;
  if (user.payment_method !== "BANKTRANSFER") return <div />;

  const sendPayment = async () => {
    try {
      setLoading(true);
      const obj = { iban: user.iban, name: payment.user_name, amount: payment.value, description: description };
      const { ok, code } = await api.post(`/payment/draft/${payment._id}`, obj);
      if (!ok) return toast.error(`Error: ${code}`);

      await api.put(`/payment/${payment._id}`, { status: "PAID" });
      toast.success("Draft payment sent!");
      onPaid();
      setLoading(false);
    } catch (err) {
      toast.error("Some Error!");
      console.log(err);
    }
  };

  return (
    <LoadingButton loading={loading} className="ml-[10px] bg-[#7CB255] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={sendPayment}>
      Pay
    </LoadingButton>
  );
};

const User = ({ user, payment }) => {
  if (!user) return <div>Loading...</div>;
  return (
    <div className="w-[300px] bg-[#FFFFFF] border border-[#E5EAEF] p-3 mt-2 rounded-[10px]">
      <img className="w-[80px] h-[80px] rounded-[10px]" src={`${user.avatar}`} alt="user" />
      <div className="my-1">
        <Link className="text-[18px] text-[#212325] font-semibold " to={`/people/${user._id}?user_modal_id=${user._id}&user_modal_tab=about`}>
          {user.name}
        </Link>
      </div>
      <span className="text-[14px] text-[#676D7C] font-medium">{`${user.job_title}`}</span>
      <span className="text-[14px] text-[#676D7C] font-medium">{`${payment.created_at.substring(0, 10)}`}</span>
      <div className="mt-2">
        <span className="text-[14px] text-[#212325] font-medium">Month of credit:</span>{" "}
        <span className="text-[14px] text-[#676D7C]">{`${new Date(payment.date).toLocaleString("default", { month: "long" })} ${new Date(payment.date).getFullYear()}`}</span>
      </div>
      <div>
        <span className="text-[14px] text-[#212325] font-medium">Contract:</span> <span className="text-[14px] text-[#676D7C]">{user.contract}</span>
      </div>
      <div>
        <span className="text-[14px] text-[#212325] font-medium">Price:</span>{" "}
        <span className="text-[14px] text-[#676D7C]">{`${user.payment_value} ${user.payment_currency} ${user.payment_type}`}</span>
      </div>
      <div>
        <span className="text-[14px] text-[#212325] font-medium">Payment method:</span> <span className="text-[14px] text-[#676D7C]">{`${user.payment_method}`}</span>
      </div>
      <div>
        <span className="text-[14px] text-[#212325] font-medium">Price cost daily:</span> <span className="text-[14px] text-[#676D7C]">{`${user.tjm}€`}</span>
      </div>
      <div>
        <span className="text-[14px] text-[#212325] font-medium">Price value daily:</span> <span className="text-[14px] text-[#676D7C]">{`${user.tjms}€`}</span>
      </div>
      <div>
        <span
          className="text-[14px] text-[#676D7C] cursor-pointer"
          onClick={() => {
            copyToClipboard(user.iban);
            toast.success("Copied to clipboard!");
          }}>{`${user.iban}`}</span>
      </div>
    </div>
  );
};

const Previous = ({ user }) => {
  const [previous, setPrevious] = useState([]);

  useEffect(() => {
    get();
  }, [user]);

  async function get() {
    if (!user) return;
    const { data } = await api.get(`/payment?user_id=${user._id}`);
    setPrevious(data);
  }

  return (
    <div className="bg-[#FFFFFF] border border-[#E5EAEF] rounded-[10px] my-3 ">
      <div className="p-3 border-b-2 border-[#E5EAEF]">
        <span className="text-[18px] font-semibold text-[#212325]">Previous payments</span>
      </div>
      <table className="w-[100%] min-w-[700px]">
        <thead>
          {previous.map((p, k) => {
            return (
              <tr className="border-b border-[#E5EAEF] text-[#212325]	p-2" key={k}>
                <th className="p-2">{p.date.substring(0, 10)}</th>
                <th>{p.value}</th>
                <th>{p.status}</th>
                <th>
                  <Link className="text-[18px] text-[#212325] font-semibold " to={`/topay/${p._id}`}>
                    view
                  </Link>
                </th>
              </tr>
            );
          })}
        </thead>
      </table>
    </div>
  );
};

const Activities = ({ activities, user }) => {
  if (!user) return <div />;
  const cost = activities.reduce((acc, cur) => acc + cur.cost, 0);
  const hours = activities.reduce((acc, cur) => acc + cur.total, 0);

  if (!activities.length) return <div>No activities</div>;

  return (
    <div>
      <div className="bg-[#FFFFFF] border border-[#E5EAEF] rounded-[10px] my-3">
        <div className="p-3 border-b-2 border-[#E5EAEF]">
          <Link className="text-[18px] font-semibold text-[#212325]" to={`/activity?userId=${user._id}&date=${activities[0].date}`}>
            Activities
          </Link>
        </div>
        <div className="overflow-x-auto">
          <table className="w-[700px] md:w-full table-fixed">
            <thead>
              <tr className="border-b border-[#E5EAEF] text-[14px] font-semibold text-[#212325]">
                <th className="p-3">Projects</th>
                <th>Days</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((e) => {
                return (
                  <tr key={e.project} className="border-b border-[#E5EAEF] text-[14px] text-[#676D7C] font-medium">
                    <td className="p-3">{e.projectName} </td>
                    <td>{(e.total / 8)?.toFixed(2)}</td>
                    <td>{`${e.cost?.toFixed(2)} €`}</td>
                  </tr>
                );
              })}
              <tr key="total" className="text-[14px] font-semibold text-[#212325]">
                <td className="p-3">Total-</td>
                <td>{`${(hours / 8)?.toFixed(2)} days`}</td>
                <td>{`${cost?.toFixed(2)} €`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const UploadInvoice = ({ onChange, name, value }) => {
  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const renderFileName = (file) => {
    if (file.startsWith("https")) return `${file.substring(0, 8)}...${file.substring(file.length - 15)}`;
    return "Cached filed (save to upload)";
  };

  const onDelete = (index) => {
    const newValue = value.filter((_, i) => i !== index);
    onChange({ target: { value: newValue, name } });
  };

  return (
    <div>
      <label className="text-[14px] font-medium text-[#212325] mb-0">Invoice(s)</label>
      {(value || []).map((file, i) => (
        <div key={file} className="flex items-center mt-1">
          <a className="flex gap-1 items-center text-sm text-gray-700 hover:underline cursor-pointer" href={file} target="_blank" rel="noreferrer">
            <IoMdDocument />
            {renderFileName(file)}
          </a>
          {/* TODO */}
          <div className="flex justify-center cursor-pointer text-xl hover:text-red-500 ml-2">
            <MdDeleteForever onClick={() => onDelete(i)} />
          </div>
        </div>
      ))}
      <label htmlFor="file-upload" className="flex pl-[20px] items-center w-[172px] h-[40px] border border-[#ccc] bg-[#F9FBFD] rounded-[10px] mt-[7px] cursor-pointer">
        Upload a file
      </label>
      <input
        id="file-upload"
        className="mt-2"
        name={name}
        type="file"
        onChange={async (e) => {
          const fileBuffer = await readFileAsync(e.target.files[0]);
          const newFiles = [...(value || []), fileBuffer];
          onChange({ target: { value: newFiles, name } });
        }}
      />
    </div>
  );
};

export default ToPayView;
