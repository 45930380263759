import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import SelectUser from "../../../components/selectUser";

import api from "../../../services/api";

const DATES = [];
var date = new Date(Date.UTC(2023, 9, 1, 0, 0, 0));
for (let i = -6; i < 6; i++) {
  DATES.push(new Date(date.setMonth(date.getMonth() + 1)));
}


//https://www.chargebee.com/blog/wp-content/uploads/2022/01/Revenue-Forecast.png


export default ({ }) => {
  const [projects, setProjects] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);


  useEffect(() => {
    get()
  }, [selectedUser]);

  async function get() {

    const query = { status: "active", per_page: 1000 }
    if (selectedUser) query.responsible_id = selectedUser._id
    const a = await api.post(`/project/search`, query);
    // const arr = a.data.projects.filter((e) => e.estimation_monthly_budget > 0);
    let temp = a.data.projects;
    temp = temp.sort((a, b) => (a.estimation_monthly_budget || 0) - (b.estimation_monthly_budget || 0)).reverse()
    setProjects(temp)

    //remove 1 days
    const dateFrom = new Date(DATES[0])
    dateFrom.setDate(dateFrom.getDate() - 1)
  
    const query2 = { dateFrom, type: "invoice" }
    if (selectedUser) query2.projectResponsibleId = selectedUser._id
    const b = await api.post(`/invoice/search`, query2);
    setInvoices(b.data)
  }

  return (
    <div className="p-4">
      <div>
        <SelectUser userName={selectedUser?.name} value={setSelectedUser} onChange={(e) => setSelectedUser(e)} />
      </div>
      <div className="flex justify-between h-12">
        <EstimatedRevenu projects={projects} invoices={invoices} />
      </div>
    </div >
  );
};

const EstimatedRevenu = ({ projects, invoices }) => {
  const [estimatedDetails, setEstimatedDetails] = useState(true);

  const total = projects.reduce((acc, e) => acc + (e.estimation_monthly_budget || 0), 0)

  const elements1 = []
  if (estimatedDetails) {
    for (let i = 0; i < projects.length; i++) {
      const obj = projects[i];
      if (!["startup-project", "startup-invest",].includes(obj.type)) continue

      elements1.push(
        <tr className="cursor-pointer" key={`${obj._id} - ${i} `}>
          <td>{
            <Link className="" to={`/project/${obj._id}`} target="_blank">
              {obj.name}
            </Link>
          }</td>
          <td>-</td>
          {DATES.map((e) => { return < td className="p-2 cursor-pointer" key={`${obj._id} - ${i} - ${e.getTime()}`} > {obj.estimation_monthly_budget || 0}</td> })
          }
        </tr >)

      const invoicesForThisProject = invoices.filter((e) => e.projectId === obj._id);

      elements1.push(
        <tr className="cursor-pointer border-b-[1px]" key={`${obj._id} - ${i} 2`} >
          <td>{<img src={obj.responsible_avatar} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white mr-2" />}</td>
          <td>-</td>
          {DATES.map((e) => {
            const total2 = (invoicesForThisProject.filter((f) => areSameMonth(f.date, e)).reduce((acc, f) => acc + f.total, 0)).toFixed()
            return < td className="p-2 cursor-pointer" key={`${obj._id} - ${i} - ${e.getTime()}`} > {total2}</td>
          })}
        </tr >)
    }
  }

  return (
    <table>
      <thead className="bg-gray-200">
        <tr>
          <th></th>
          <th></th>
          {DATES.map((e) => {
            const options = { month: 'short', year: 'numeric', };
            const str = e.toLocaleDateString('en-US', options) || ""
            return (
              <th key={e} className="w-[100px]">{str}</th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="cursor-pointer" >Project</td>
          <td className="cursor-pointer" >Total Projected</td>
          {
            DATES.map((e, i) => {
              if (invoices.length === 0) return <div />
              const filteredInvoice = (invoices.filter((f) => areSameMonth(f.date, DATES[1])))
              // double in 12 month
              const startValue = parseInt(filteredInvoice.reduce((acc, f) => acc + (parseInt(f.total) || 0), 0))
              const endValue = (startValue * 2) + 1
              const v = parseInt((startValue + (i * (endValue - startValue) / 11))).toFixed()
              return (<td className="p-2 cursor-pointer" key={e.getTime()} >{v}</td>)
            })
          }
        </tr >
        <tr>
          <td className="cursor-pointer" ></td>
          <td className="cursor-pointer" >Total Estimated</td>
          {
            DATES.map((e) => {
              return (<td className="p-2 cursor-pointer" key={e.getTime()} >{total}</td>)
            })
          }
        </tr >
        <tr>
          <td className="cursor-pointer" ></td>
          <td className="cursor-pointer" >Total Done</td>
          {
            DATES.map((e) => {
              const total2 = (invoices.filter((f) => areSameMonth(f.date, e)).reduce((acc, f) => acc + f.total, 0)).toFixed()
              return (<td className="p-2 cursor-pointer" key={e.getTime()} >{total2}</td>)
            })
          }
        </tr >
        {elements1}
      </tbody>
    </table>)
}



function areSameMonth(a, b) {
  let aDate = new Date(a)
  let bDate = new Date(b)
  return aDate.getMonth() === bDate.getMonth() && aDate.getFullYear() === bDate.getFullYear()
}