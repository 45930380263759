import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";

export default function Rule({ fields, operators, combinators, fieldProps, operatorProps, valueProps, ...props }) {
  const [combinator, setCombinator] = useState(props.combinator);
  const [field, setField] = useState(fieldProps);
  const [operator, setOperator] = useState(operatorProps);
  const [value, setValue] = useState(valueProps);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    props.onChange({ field, operator, value, combinator, index: props.index });
  }, [field, operator, value, combinator]);

  useEffect(() => {
    setOperator(operatorProps);
  }, [operatorProps]);

  useEffect(() => {
    setField(fieldProps);
  }, [fieldProps]);

  useEffect(() => {
    setValue(valueProps);
  }, [valueProps]);

  const combinatorElement = props.index ? (
    <select className="react-es-rule-combinator search-input max-w-[90px]" value={combinator} onChange={(e) => setCombinator(e.target.value)}>
      {combinators.map((c) => (
        <option key={c.value} value={c.value}>
          {c.text}
        </option>
      ))}
    </select>
  ) : null;

  const deleteButton = props.index ? (
    <button className="bg-red-600 text-white border-none rounded w-8 py-1" onClick={() => props.onDelete(props.index)}>
      &times;
    </button>
  ) : null;

  let input = null;
  if (operators.find((o) => o.value === operator && o.useInput)) {
    // Autocomplete zone.
    if (!Array.isArray(field)) {
      input = (
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={async ({ value }) => {
            let query = {};
            const suggestionQuery = operators.find((o) => o.value === operator).suggestionQuery;
            if (suggestionQuery) {
              query = suggestionQuery(field, value);
            } else {
              query[field] = value;
              // const terms = { field, include: `.*${value}.*`, order: { _count: "desc" }, size: 10 };
              // query = { query: { match_all: {} }, aggs: { [field]: { terms } }, size: 0 };
            }
            // console.log(query);
            // arr.push({ query: { bool: { must: [{ range: { created_at: { gte: start_at.toISOString() } } }] } }, size: 0 });
            // const suggestions = await api.esQuery([{ index: collection, type: "_doc" }, query]);
            // if (suggestions && suggestions.responses[0].aggregations) setSuggestions(suggestions.responses[0].aggregations[field].buckets.map((e) => e.key));
          }}
          onSuggestionsClearRequested={() => setSuggestions([])}
          getSuggestionValue={(suggestion) => suggestion}
          renderSuggestion={(suggestion) => <div>{suggestion}</div>}
          inputProps={{
            value,
            onChange: (event, { newValue }) => setValue(newValue),
            className: "react-es-rule-value search-input",
            autoComplete: "new-password",
          }}
        />
      );
    } else {
      input = <input className="react-es-rule-value" value={value} autoComplete="new-password" onChange={(e) => setValue(e.target.value)} />;
    }
  }
  return (
    <div className="react-es-rule flex items-center space-x-2 mb-2">
      {combinatorElement}
      <select
        className="react-es-rule-field search-input"
        value={fields.findIndex((e) => String(e.value) === String(field))}
        onChange={(e) => setField(fields[e.target.value].value)}>
        {fields.map((f, k) => {
          return (
            <option key={k} value={k}>
              {f.text}
            </option>
          );
        })}
      </select>
      <select className="react-es-rule-operator search-input max-w-[210px]" value={operator} onChange={(e) => setOperator(e.target.value)}>
        {operators.map((o) => {
          return (
            <option key={o.value} value={o.value}>
              {o.text}
            </option>
          );
        })}
      </select>
      {input}
      <button className="bg-primary text-white border-none rounded w-8 py-1 mr-2" onClick={props.onAdd}>
        +
      </button>
      {deleteButton}
    </div>
  );
}
