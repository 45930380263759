import React from "react";
import toast from "react-hot-toast";

import api from "../../../services/api";

const Info = ({ question, setQuestion, setTab }) => {
  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/question/${question._id}`);
    toast.success("successfully removed!");
    navigate("/questioning");
  }

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/question/${question._id}`, question);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setQuestion(data);
      toast.success("Updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }
  async function handleNextStep() {
    try {
      await handleSubmit();
      setTab("send");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  return (
    <div className="max-w-5xl space-y-4">
      <div className="text-lg font-bold">Question</div>

      <div className="w-full space-y-2">
        <label className="font-semibold">Title</label>
        <input type="text" className="input" placeholder="Title" value={question.title} onChange={(e) => setQuestion({ ...question, title: e.target.value })} />
      </div>
      <div className="col-span-2 w-full space-y-2">
        <label className="font-semibold">Description</label>
        <textarea
          type="text"
          className="input"
          placeholder="Description"
          value={question.description}
          rows={5}
          onChange={(e) => setQuestion({ ...question, description: e.target.value })}
        />
      </div>
      <div className="col-span-2 w-full space-y-2">
        <label className="font-semibold">Options</label>
        <div className="flex flex-col gap-1">
          {question?.options?.map((option, index) => (
            <div key={index} className="flex gap-2 items-center">
              <input
                type="text"
                className="input"
                placeholder="Option"
                value={option}
                onChange={(e) => {
                  const arr = [...question.options];
                  arr[index] = e.target.value;
                  setQuestion({ ...question, options: arr });
                }}
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  const arr = [...question.options];
                  arr.splice(index, 1);
                  setQuestion({ ...question, options: arr });
                }}>
                Remove
              </button>
            </div>
          ))}
        </div>
        <button className="input" onClick={() => setQuestion({ ...question, options: [...question?.options, ""] })}>
          New option
        </button>
      </div>
      <div className="flex gap-4 justify-end">
        {/* <button className="red-btn" onClick={onDelete}>
          Delete
        </button> */}
        <button className="blue-btn" onClick={handleSubmit}>
          Update
        </button>
        <button className="blue-btn" onClick={handleNextStep}>
          Next step
        </button>
      </div>
    </div>
  );
};

export default Info;
