import React, { useState, useEffect } from "react";

import { defaultOperators, defaultCombinators } from "./utils";
import Rule from "./rule";

const fields = [
  { text: "Name", value: "name" },
  { text: "Description", value: "description" },
  { text: "Legal infos", value: "legal_infos" },
  { text: "City", value: "city" },
  { text: "Sectors", value: "sectors" },
  { text: "Description", value: "description" },
  { text: "Origin", value: "origin" },
  { text: "URL", value: "url" },
];

const defaultRule = { field: fields[0].value, operator: defaultOperators[0].value, value: "", combinator: "AND", index: 0 };

function QueryBuilder({ query, onQuery }) {
  const rules = query || [defaultRule];

  return (
    <div className="flex items-center justify-between">
      <div className="react-es-query-builder flex-1">
        {rules.map((rule) => {
          return (
            <Rule
              combinator={rule.combinator}
              fieldProps={rule.field}
              operatorProps={rule.operator}
              valueProps={rule.value}
              fields={fields}
              operators={defaultOperators}
              combinators={defaultCombinators}
              key={rule.index}
              index={rule.index}
              onAdd={() => {
                onQuery([...rules, { ...defaultRule, index: rules.length }]);
              }}
              onDelete={(index) => {
                let arr = rules;
                arr = arr.filter((e) => e.index !== index);
                arr = arr.filter((e) => e);
                arr = arr.map((v, k) => ({ ...v, index: k }));
                onQuery(arr);
              }}
              onChange={(r) => {
                rules[r.index] = { ...r };
                onQuery([...rules]);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default QueryBuilder;
