import { useEffect, useState } from "react";

import { PiKanbanFill } from "react-icons/pi";
import { IoStatsChart } from "react-icons/io5";
import { MdCloudUpload } from "react-icons/md";

import Stats from "./stats";
import Stats2 from "./stats2";
import Pipe from "./pipe";
import Action from "./action";
import Seb from "./seb";
import Partner from "./partner";
import All from "./all";

import { Link, Route, Routes, useLocation } from "react-router-dom";

const TABS = [
  { title: "Pipe", href: "/folk", icon: PiKanbanFill },
  { title: "Partner", href: "/folk/partner", icon: PiKanbanFill },
  { title: "All", href: "/folk/all", icon: MdCloudUpload },
  { title: "Stats", href: "/folk/stats", icon: IoStatsChart },
  { title: "Stats2", href: "/folk/stats2", icon: IoStatsChart },
];

export default () => {
  return (
    <div className="py-4 px-4 space-y-4">
      <TabBar tabs={TABS} />
      <Routes>
        <Route path="/" element={<Pipe />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/all" element={<All />} />
        <Route path="/action" element={<Action />} />
        <Route path="/stats2" element={<Stats2 />} />
      </Routes>
    </div>
  );
};

const TabBar = ({ tabs }) => (
  <div className="overflow-x-scroll">
    <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
      {tabs.map((tab, i) => (
        <TabItem key={i} href={tab.href} title={tab.title} Icon={tab.icon} />
      ))}
    </nav>
  </div>
);

const TabItem = ({ href, title, Icon }) => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(location.pathname === href);
  }, [location.pathname]);

  return (
    <Link to={href} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
      {Icon && <Icon className="text-sm" />}
      <div className="flex items-center gap-2 truncate">{title}</div>
    </Link>
  );
};
