import * as React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import DOMPurify from "dompurify";

const defaultFonts = ["Arial", "Comic Sans MS", "Courier New", "Impact", "Georgia", "Tahoma", "Trebuchet MS", "Verdana"];
export default ({ values, onChange, buttonList, options = {}, placeholder }) => {
  const sortedFontOptions = ["Logical", "Salesforce Sans", "Garamond", "Sans-Serif", "Serif", "Times New Roman", "Helvetica", "Poppins", ...defaultFonts].sort();

  const handleChange = (content) => {
    console.log("handleChange 1")
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    const headers = tempDiv.querySelectorAll("h1, h2, h3, h4, h5, h6");
    headers.forEach((header) => {
      const spans = header.querySelectorAll("span[style*='font-size']");
      spans.forEach((span) => {
        const parent = span.parentNode;
        while (span.firstChild) {
          parent.insertBefore(span.firstChild, span);
        }
        parent.removeChild(span);
      });

      // Find and wrap icons
      const regex =
        /[\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E0}-\u{1F1FF}]/gu;
      header.innerHTML = header.innerHTML.replace(regex, (match) => `<span style="font-weight: normal; display: inline;">${match}</span>`);
    });

    const cleanedContent = DOMPurify.sanitize(tempDiv.innerHTML);
    console.log("handleChange 2")
    onChange(cleanedContent);
  };

  return (
    <div className="cursor-text">
      <SunEditor
        setContents={values}
        placeholder={placeholder}
        onChange={handleChange}
        setOptions={{
          ...options,
          buttonList: buttonList,
          defaultTag: "div",
          height: "auto",
          showPathLabel: false,
          font: sortedFontOptions,
          defaultStyle: "font-family: 'Poppins'; color: #000; font-size: 14px;",
        }}
      />
    </div>
  );
};
