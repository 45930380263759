import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { FaChevronUp, FaChevronDown, FaTrash, FaStar } from "react-icons/fa";

import DebounceInput from "react-debounce-input";

import api from "../../../../services/api";
import Modal from "../../../../components/modal";

import Table from "../../../../components/Table";
import Paginator from "../../../../components/Paginator";



import { classNames } from "../../../../utils";


export default ({ onClick, onClose }) => {
  const [filters, setFilters] = useState({ search: "", category: "", page: 1, pageSize: 10 });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("use-cases");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = {};
        if (filters.category) query.category = filters.category;
        if (filters.project) query.projectId = filters.project._id;

        let category = "";
        if (tab === "propals") category = "PROPAL";
        if (tab === "contracts") category = "CONTRACT";
        if (tab === "use-cases") category = "USE_CASE";
        if (tab === "cv") category = "CV";
        if (tab === "others") category = "OTHER";

        const { ok, data, total } = await api.post("/quote/search", {
          skip: (filters.page - 1) * filters.pageSize,
          limit: filters.pageSize,
          search: filters.search,
          archived: false,
          category,
        });

        if (!ok) return toast.error("Error while fetching quotes");
        setData(data);
        setTotal(total);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching quotes");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters, tab]);

  const categories = {
    PROPAL: <span className="text-yellow-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-yellow-500 bg-yellow-100">Propal</span>,
    QUOTE: <span className="text-green-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-green-500 bg-green-100">Quote</span>,
    CONTRACT: <span className="text-blue-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-blue-500 bg-blue-100">Contract</span>,
    USE_CASE: <span className="text-violet-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-violet-500 bg-violet-100">Use Case</span>,
    CV: <span className="text-red-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-red-500 bg-red-100">CV</span>,
    OTHER: <span className="text-orange-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-orange-500 bg-orange-100">Other</span>,
  };

  const TabItem = ({ title, onClick, active }) => (
    <button
      onClick={onClick}
      className={classNames(
        "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:text-gray-700",
        active ? "text-primary border-b-2 border-primary" : "",
      )}>
      <div className="flex items-center gap-2">{title}</div>
    </button>
  );

  return (
    <Modal isOpen={true} onClose={onClose} className="p-4 max-w-7xl w-full">
      <div className="space-y-3">
        <div className="w-full flex items-center justify-between gap-4">
          <label className="text-lg font-semibold">Templates</label>
          <div className="w-full max-w-sm space-y-2 mr-4">
            <DebounceInput
              debounceTimeout={300}
              name="search"
              id="search"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
              placeholder="Search"
              value={filters.search}
              onChange={(e) => {
                e.persist();
                setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
              }}
            />
          </div>
        </div>
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden my-2">
          <TabItem title="Propales" tab="propals" onClick={() => setTab("propals")} active={tab === "propals"} />
          <TabItem title="Contracts" tab="contracts" onClick={() => setTab("contracts")} active={tab === "contracts"} />
          <TabItem title="Use cases" tab="use-cases" onClick={() => setTab("use-cases")} active={tab === "use-cases"} />
          <TabItem title="CV" tab="cv" onClick={() => setTab("cv")} active={tab === "cv"} />
          <TabItem title="Others" tab="others" onClick={() => setTab("others")} active={tab === "others"} />
        </nav>
        <section className="text-sm">
          <Table
            header={[{ title: "Index" }, { title: "Name" }, { title: "Category" }, { title: "Tags" }, { title: "Score" }]}
            total={total}
            loading={loading}
            height="h-[32rem]"
            sticky={true}
            noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
            {data?.map((item, index) => (
              <tr className={`hover:bg-gray-50 cursor-pointer border-b bg-white`} key={item._id} onClick={() => onClick(item)}>
                <td className="px-4 py-2 max-w-xs truncate">{`#${item.index}`}</td>
                <td className="px-4 py-2 max-w-xs truncate">{item.name}</td>
                <td className="px-4 py-2 max-w-xs truncate">{categories[item.category]}</td>
                <td className="px-4 py-2 max-w-xs truncate">
                  <div className="flex gap-2">
                    {(item.tags || []).map((tag) => {
                      const color = tag === "to review" ? "red" : "gray";
                      return (
                        <span key={tag} className={`text-xs font-semibold text-gray-500 bg-${color}-100 px-2 py-1 rounded-md`}>
                          {tag}
                        </span>
                      );
                    })}
                  </div>
                </td>
                <td className="px-4 py-2 max-w-xs truncate">
                  {item.score && (
                    <div className="flex items-center space-x-1">
                      <span>{item.score}</span>
                      <FaStar size={16} className="text-yellow-500" />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </Table>
          <div className="mt-1">
            <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.pageSize)} />
          </div>
        </section>
      </div>
    </Modal>
  );
};
