import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoPeople, IoInformationCircle, IoCard, IoPencil, IoNewspaper, IoDesktop } from "react-icons/io5";
import { classNames } from "../../../utils";
import { useSelector } from "react-redux";

import Loader from "../../../components/loader";
import { copyToClipboard } from "../../../utils";
import toast from "react-hot-toast";

import api from "../../../services/api";

import Admin from "./admin";
import Logs from "./logs";
import Raw from "./raw";
import InvoiceGenerator from "./invoice_generator";
import Info from "./info";
import Instruction from "./instructions";

const TABS = {
  ADMIN: "Admin",
  LOGS: "Logs",
  RAW: "Raw",
  INVOICE_GENERATOR: "Invoice Generator",
  INFO: "Info",
  INSTRUCTION: "Instructions",
};

export default () => {
  const [tab, setTab] = useState(TABS.INFO);
  const [payment, setPayment] = useState(null);

  const { id } = useParams();

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const { data } = await api.get(`/payment/${id}`);
      setPayment(data);
    } catch (e) {
      console.log("a", e);
    }
  }

  const render = () => {
    switch (tab) {
      case TABS.INFO:
        return <Info payment={payment} setPayment={setPayment} />;
      case TABS.ADMIN:
        return <Admin payment={payment} setPayment={setPayment} />;
      case TABS.LOGS:
        return <Logs payment={payment} setPayment={setPayment} />;
      case TABS.INVOICE_GENERATOR:
        return <InvoiceGenerator payment={payment} setPayment={setPayment} />;
      case TABS.RAW:
        return <Raw payment={payment} setPayment={setPayment} />;
      case TABS.INSTRUCTION:
        return <Instruction payment={payment} setPayment={setPayment} />;
      default:
        return <Admin payment={payment} setPayment={setPayment} />;
    }
  };

  if (!payment) return <Loader />;

  const description = `${payment.type} the ${new Date(payment.date).toLocaleString("default", { month: "long" })} ${new Date(payment.date).getFullYear()} for ${payment.user_name}`;

  return (
    <div>
      <div className="m-2">
        <div className="flex justify-between">
          <div
            className="text-xl font-bold text-gray-700 cursor-pointer"
            onClick={() => {
              copyToClipboard(`https://accounting.selego.co/payment/${payment._id}`);
              toast.success("Copied to clipboard!");
            }}>
            {description}
          </div>
        </div>
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden">
          <TabItem title={TABS.INFO} tab={TABS.INFO} setTab={setTab} active={tab === TABS.INFO} Icon={IoPencil} />

          {/* <TabItem title={TABS.LOGS} tab={TABS.LOGS} setTab={setTab} active={tab === TABS.LOGS} Icon={IoDesktop} /> */}
          <TabItem title={TABS.INVOICE_GENERATOR} tab={TABS.INVOICE_GENERATOR} setTab={setTab} active={tab === TABS.INVOICE_GENERATOR} Icon={IoNewspaper} />
          {user.role === "admin" && <TabItem title={TABS.ADMIN} tab={TABS.ADMIN} setTab={setTab} active={tab === TABS.ADMIN} Icon={IoInformationCircle} />}
          <TabItem title={TABS.INSTRUCTION} tab={TABS.INSTRUCTION} setTab={setTab} active={tab === TABS.INSTRUCTION} Icon={IoDesktop} />
          <TabItem title={TABS.RAW} tab={TABS.RAW} setTab={setTab} active={tab === TABS.RAW} Icon={IoDesktop} />
        </nav>
        <div className="my-2" />
        <div className="w-full">{render()}</div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-primary  border-primary" : "",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
