import React, { useState, useEffect, useRef, Fragment } from "react";

import Loader from "../../components/loader";
import api from "../../services/api";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { HiX } from "react-icons/hi";

export default () => {
  const [skills, setSkills] = useState();
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({});

  useEffect(() => {
    const t = setTimeout(() => get(), 500);
    return () => clearTimeout(t);
  }, [filters]);

  async function get() {
    try {
      setLoading(true);
      const { data } = await api.post(`/skill/search`, filters);
      setSkills(data);
    } catch (e) {
      console.log("a", e);
    } finally {
      setLoading(false);
    }
  }
  async function remove(id) {
    try {
      setLoading(true);
      const { data } = await api.remove(`/skill/${id}`);
      toast.success("Skill removed");
      setSkills(skills.filter((skill) => skill._id !== id));
    } catch (e) {
      console.log("a", e);
    } finally {
      setLoading(false);
    }
  }

  if (!skills) return <Loader />;

  return (
    <div className="mx-auto px-10 py-4 mb-4">
      <input value={filters?.search} onChange={(e) => setFilters({ search: e.target.value })} placeholder="Search" />
      <ModalCreate callback={get} />
      <table className="">
        <thead>
          <tr>
            <th></th>
            <th>Category</th>
            <th>SubCat</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {skills.map((skill, i) => {
            return (
              <tr key={i} className="hover:bg-gray-100">
                <td>
                  <Link to={`/skills/${skill._id}`}>open</Link>
                  <button
                    className="ml-2 bg-red-700 text-white px-2 py-1 rounded-md"
                    onClick={() => {
                      if (!confirm("are you sure?")) return;
                      remove(skill._id);
                    }}>
                    REMOVE
                  </button>
                </td>
                <td>{skill.category}</td>
                <td>{skill.subCategory}</td>
                <td>{skill.description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ModalCreate = ({ callback }) => {
  const cancelButtonRef = useRef();
  const [open, setOpen] = useState(false);

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [description, setDescription] = useState("");

  if (!open)
    return (
      <button className="px-5 py-1 bg-sky-700 text-white" onClick={() => setOpen(true)}>
        Create
      </button>
    );

  async function create() {
    try {
      if (!category || !subCategory) return toast.error("missing fields");
      const { data } = await api.post(`/skill`, { category, subCategory, description });
      console.log(data);
      callback?.();
    } catch (e) {
      console.log("a", e);
    }
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-30 backdrop-blur-sm"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={() => {
          setOpen(false);
        }}>
        <div className="min-h-screen md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className={`bg-white rounded-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-h-screen max-w-xl w-full p-6 overflow-auto`}>
              <div className="absolute top-2 right-2 ">
                <button className="sr-only" ref={cancelButtonRef} />
                <HiX
                  className={`text-xl text-gray-500 transition-colors  hover:text-red-500`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(false);
                  }}
                />
              </div>
              <div className="pt-2">
                <select onChange={(e) => setCategory(e.target.value)} value={category}>
                  <option>--select category--</option>
                  <option>Leadership</option>
                  <option>Communication</option>
                  <option>Code</option>
                  <option>Product</option>
                </select>
                <input placeholder="subCategory" onChange={(e) => setSubCategory(e.target.value)}></input>
                <input placeholder="description" onChange={(e) => setDescription(e.target.value)}></input>
                <button onClick={create}>Create</button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
