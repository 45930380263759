import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/loader";
import api from "../../services/api";
import { Link } from "react-router-dom";
import { IoArrowForwardOutline } from "react-icons/io5";

import { classNames, getDaysInMonth } from "../../utils";

const Activities = () => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.Auth.user);
  const [value, setValue] = useState();
  const [total, setTotal] = useState();

  const d = new Date();
  const date = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1));

  async function get() {
    try {
      setLoading(true);
      const activities = [];

      const previousMonth = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth() - 1, 1));
      const response1 = await api.post(`/activity/search`, { date: previousMonth.getTime(), userId: user._id });
      activities.push(...response1?.data);

      const response2 = await api.post(`/activity/search`, { date: date.getTime(), userId: user._id });
      activities.push(...response2?.data);

      const hoursPerDay = compute(activities);

      for (let i = 0; i < hoursPerDay.length; i++) {
        setValue((p) => p + hoursPerDay[i].declaredHours);
        setTotal((p) => p + 8);
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  const compute = (activities) => {
    const differentsMonhtsInActivities = activities.reduce((acc, a) => {
      if (!acc.includes(a.date)) {
        acc.push(a.date);
      }
      return acc;
    }, []);

    // get all the possible days in the months of the activities
    const days = differentsMonhtsInActivities.reduce((acc, curr) => {
      const _date = new Date(curr);
      const _days = getDaysInMonth(_date.getUTCMonth(), _date.getUTCFullYear());
      return [...acc, ..._days];
    }, []);

    // get all the workings days, we do not care about the weekends
    const workingDays = getWorkingDays(days);

    // remove the days in the future
    const today = new Date();
    const workingDaysInThePast = workingDays.filter((d) => d.getTime() <= today.getTime());

    // get the last 20 days
    const last20Days = workingDaysInThePast.slice(-20);

    // get the declared hours for each day
    const hoursPerDay = [];
    for (let _d of last20Days) {
      const d = new Date(_d);
      const month = d.getMonth();
      const day = d.getDate();
      const year = d.getFullYear();

      // get the activities for the month
      const activitiesForMonth = activities.filter((a) => {
        const date = new Date(a.date);
        return date.getMonth() === month && date.getFullYear() === year;
      });

      // get the declared hours for the day for every activity
      const declaredHours = activitiesForMonth.reduce((acc, a) => {
        const detail = a.detail[day - 1];
        return acc + detail.value;
      }, 0);

      hoursPerDay.push({ date: d, declaredHours });
    }

    return hoursPerDay;
  };

  const getWorkingDays = (days) => {
    return days.reduce((acc, a) => {
      const day = a.getDay();
      if (day === 0 || day == 6) return acc;
      return [...acc, a];
    }, []);
  };

  useEffect(() => {
    setValue(0);
    setTotal(0);
    get();
  }, []);

  if (loading) return <Skeleton />;

  let color = "";
  if (value / total >= 0.8) color = "#34D399";
  if (value / total < 0.8) color = "#FBBF24";
  if (value / total < 0.5) color = "#F87171";

  return (
    <div className={classNames(" border-[1px] border-gray-200 rounded-md overflow-hidden h-full bg-white")}>
      <Link to="/activity" className="group relative flex flex-col items-center h-full">
        <div className="w-full p-3 flex items-center justify-between">
          <div className="w-full flex justify-between items-center">
            <h1 className="flex items-center text-base font-semibold text-gray-900 gap-2">
              <span>My activities</span>
            </h1>
            <span className="text-xs text-gray-500">last 20 days</span>
          </div>
        </div>
        <div to="/activity" className="flex-1 group relative flex flex-col items-center justify-between">
          <CircleScore value={value} total={total} color={color} text={`${Math.round((value / total) * 100)}%`} />
          <div className={`text-sm flex w-full items-center justify-center text-gray-500 mb-3 transition ease-in-out group-hover:translate-y-32`}>
            {value} / {total} hours
          </div>
          <span className={`absolute -bottom-32 flex w-full items-center justify-center text-black font-bold transition ease-in-out group-hover:-translate-y-36`}>
            Fill in <IoArrowForwardOutline />
          </span>
        </div>
      </Link>
    </div>
  );
};

const CircleScore = ({ value, total = 100, text, color }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const percentage = value / total;
  const strokeDashoffset = String(circumference * (1 - percentage));

  return (
    <div className="relative flex items-center justify-center w-28 h-28">
      {percentage < 0.5 ? (
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-20"></span>
      ) : percentage < 0.8 ? (
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
      ) : null}
      <svg className="-rotate-90" width="100%" height="100%" viewBox="0 0 80 80">
        <circle cx="40" cy="40" r={radius} fill="transparent" stroke={"#DFE1E5"} strokeWidth="4" />
        <circle cx="40" cy="40" r={radius} fill="white" stroke={color} strokeWidth="4" strokeDasharray={circumference} strokeDashoffset={strokeDashoffset} strokeLinecap="round" />
      </svg>
      <div className="absolute flex flex-col items-center">
        <div className="text-lg font-semibold">{text}</div>
      </div>
    </div>
  );
};

const Skeleton = () => (
  <div className="bg-white flex flex-col justify-between no-underline	hover:no-underline rounded-md p-3 border border-gray-200 h-full overflow-hidden">
    <div className="bg-gray-200 w-24 h-4 rounded-md mb-4" />
    <div className="flex flex-row items-center justify-center w-full h-full">
      <div className="bg-gray-200 rounded-full w-20 h-20 mr-2" />
    </div>
  </div>
);

export default Activities;
