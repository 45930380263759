import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./home";
import View from "./view";

const Ticketing = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:id" element={<View />} />
    </Routes>
  );
};
export default Ticketing;
