import React, { useEffect, useState } from "react";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { HiOutlineShare } from "react-icons/hi2";
import toast from "react-hot-toast";
import { IoLocationSharp, IoOpenOutline, IoCopyOutline } from "react-icons/io5";
import { IoLogoGithub, IoLogoLinkedin, IoIosLink } from "react-icons/io";
import { copyToClipboard } from "../../utils";
import moment from "moment";

import Loader from "../../components/loader";

import api from "../../services/api";

const View = () => {
  const [data, setData] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    const fetchData = async () => {
      try {
        const { ok, data } = await api.get(`/user/${id}`);
        if (!ok) return toast.error("Error while fetching user");
        setData(data);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching user");
      }
    };
    fetchData();
  }, [id]);

  if (!data) return <Loader />;

  return (
    <div className="">
      <div className="m-2">
        <div className="flex flex-row justify-between p-2">
          <div className="">
            <div className="flex items-center gap-2">
              <img
                src={data.avatar || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
                alt="userlogo"
                className="rounded-full w-7 h-7 md:w-14 md:h-14 mr-2"
              />
              <div>
                <div className="flex items-center gap-1">
                  <div className="text-lg font-bold">{data.name}</div>
                  <button
                    className="hover:bg-gray-100 cursor-pointer rounded-lg p-2 flex items-center gap-1 text-xs text-gray-700"
                    onClick={() => {
                      const v = `${window.location.origin}/public/user/${data._id.toString()}`;
                      copyToClipboard(v);
                      toast.success("Link copy to clipboard");
                    }}>
                    <IoIosLink />
                    Copy link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="bg-white border border-gray-100 rounded-md">
              <div className="p-2 border-b border-gray-100 text-gray-800 text-sm">Personnal information</div>
              <dl className="md:divide-y md:divide-gray-200">
                <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Full name</dt>
                  <dd className="text-sm text-black col-span-2">{data.name}</dd>
                </div>
                {/* <div className="grid grid-cols-2 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Email address</dt>
                  <dd className="text-sm text-black col-span-2">
                    <div className="flex items-center gap-1">
                      {user.email}
                      <div
                        className="hover:bg-gray-100 cursor-pointer rounded-lg p-1"
                        onClick={() => {
                          copyToClipboard(user.email);
                          toast.success("mail copied to clipboard");
                        }}>
                        <IoCopyOutline />
                      </div>
                    </div>
                  </dd>
                </div> */}
                <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Location</dt>
                  <dd className="text-sm text-black col-span-2">{data.location}</dd>
                </div>
                <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Links</dt>
                  <dd className="text-sm text-black col-span-2">
                    {data.github ? (
                      <a href={data.github} target="_blank" rel="noreferrer" className="flex items-center gap-1 hover:underline">
                        <span className="flex-1">{data.github}</span> <IoOpenOutline />
                      </a>
                    ) : null}
                    {data.linkedin ? (
                      <a href={data.linkedin} target="_blank" rel="noreferrer" className="flex items-center gap-1 hover:underline">
                        <span className="flex-1">{data.linkedin}</span> <IoOpenOutline />
                      </a>
                    ) : null}
                    {data.other ? (
                      <a href={data.other} target="_blank" rel="noreferrer" className="flex items-center gap-1 hover:underline">
                        <span className="flex-1">{data.other}</span> <IoOpenOutline />
                      </a>
                    ) : null}
                  </dd>
                </div>
                {/* <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                  <dd className="text-sm text-black col-span-2">
                    {user.resume ? (
                      <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                        <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                          <div className="flex w-0 flex-1 items-center">
                            <FiPaperclip className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <span className="ml-2 w-0 flex-1 truncate">Resume</span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <a href={user.resume} download target="_blank" rel="noreferrer" className="font-medium text-indigo-600 hover:text-indigo-500">
                              Download
                            </a>
                          </div>
                        </li>
                      </ul>
                    ) : null}
                  </dd>
                </div> */}
              </dl>
            </div>
            <div className="bg-white border border-gray-100 rounded-md">
              <div className="p-2 border-b border-gray-100 text-gray-800 text-sm">Offer</div>
              <dl className="md:divide-y md:divide-gray-200">
                <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Application for</dt>
                  <dd className="text-sm text-black col-span-2">
                    <a href={`/missions/${data.job_id}`} target="_blank" rel="noreferrer" className="flex items-center gap-1 hover:underline">
                      <span className="flex-1">{data.job_title}</span> <IoOpenOutline />
                    </a>
                  </dd>
                </div>
                <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Contracts</dt>
                  <dd className="text-sm text-black col-span-2">
                    <div className="flex items-center gap-1 flex-wrap">
                      {(data.contracts || []).map((contract) => (
                        <span className="bg-blue-100 text-blue-800 rounded-sm text-xs px-2">{contract}</span>
                      ))}
                    </div>
                  </dd>
                </div>
                <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Experience</dt>
                  <dd className="text-sm text-black col-span-2">{data.experience}</dd>
                </div>
                <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Expertise</dt>
                  <dd className="text-sm text-black col-span-2">
                    <div className="flex items-center gap-1 flex-wrap">
                      {data.jobCategory}
                      {(data.expertise || []).map((_expertise) => (
                        <span className="bg-orange-100 text-orange-800 rounded-sm text-xs px-2">{_expertise}</span>
                      ))}
                    </div>
                  </dd>
                </div>
                <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">Skills</dt>
                  <dd className="text-sm text-black col-span-2">
                    <div className="flex items-center gap-1 flex-wrap">
                      {(data.skills || []).map((skill) => (
                        <span className="bg-orange-100 text-orange-800 rounded-sm text-xs px-2">{skill}</span>
                      ))}
                    </div>
                  </dd>
                </div>
                <div className="grid grid-cols-3 gap-4 p-2">
                  <dt className="text-sm font-medium text-gray-500">About</dt>
                  <dd className="text-sm text-black col-span-2">{data.description}</dd>
                </div>
              </dl>
            </div>
            <div className="py-2 md:grid md:grid-cols-3 md:gap-4 md:py-3">
              <span className="text-xs text-gray-400" data-tip data-for={`${data._id}applicantCreatedAt`}>
                {data.name} was created {moment(new Date(data.created_at)).fromNow()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
