import React from "react";
import { Link } from "react-router-dom";
import CategoryRadar from "../../scenes/skills/components/CategoryRadar";
import SubCategoryRadar from "../../scenes/skills/components/SubCategoryRadar";
import KeyWords from "../../scenes/skills/components/KeyWords";

export default ({ user }) => {
  if (user?.role === "applicant") {
    return <div className="flex items-center justify-center bg-white gap-4 p-2">No skills available</div>;
  }
  return (
    <div className="grid grid-cols-1 gap-4 p-2">
      <UserCard user={user} />
    </div>
  );
};

const UserCard = ({ user }) => {
  return (
    <div className="w-full h-full">
      <Link className="gray-btn" to={`/skills/answers?userId=${user._id}&userName=${decodeURIComponent(user.name)}`}>
        See detailed answers
      </Link>
      <div className="flex h-full flex-col rounded-xl bg-white p-5 space-y-4 border border-neutral-300 mt-2">
        <div className="flex flex-col justify-between">
          <div className="w-full">
            <KeyWords userId={user._id} />
          </div>
          <div className="">
            <CategoryRadar userId={user._id} />
          </div>
          <div className="w-full">
            <SubCategoryRadar userId={user._id} />
          </div>
        </div>
      </div>
    </div>
  );
};
