import React, { useState, useEffect } from "react";

import Result from "./result";

const CATEGORIES = {
  INVOICE: "INVOICE",
  OFFICE: "OFFICE",
  HIRING: "HIRING",
};

export default () => {
  const [category, setCategory] = useState(CATEGORIES.INVOICE);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.get("category")) setCategory(queryParameters.get("category"));
  }, []);

  return (
    <div>
      <div className="flex mb-2 text-sm text-gray-400 border-b border-lightShade-grey justify-between">
        <div className="flex justify-between w-full">
          <div className="flex ">
            <div className={`p-2 border-blue-600 cursor-pointer ${!category ? "border-b-2 text-blue-600 font-bold" : ""}`} onClick={() => setCategory("")}>
              All
            </div>
            <div
              key={CATEGORIES.INVOICE}
              className={`p-2 border-blue-600 cursor-pointer ${category === CATEGORIES.INVOICE ? "border-b-2 text-blue-600 font-bold" : ""}`}
              onClick={() => setCategory(CATEGORIES.INVOICE)}>
              {CATEGORIES.INVOICE}
            </div>
            <div
              key={CATEGORIES.HIRING}
              className={`p-2 border-blue-600 cursor-pointer ${category === CATEGORIES.HIRING ? "border-b-2 text-blue-600 font-bold" : ""}`}
              onClick={() => setCategory(CATEGORIES.HIRING)}>
              {CATEGORIES.HIRING}
            </div>
            <div
              key={CATEGORIES.OFFICE}
              className={`p-2 border-blue-600 cursor-pointer ${category === CATEGORIES.OFFICE ? "border-b-2 text-blue-600 font-bold" : ""}`}
              onClick={() => setCategory(CATEGORIES.OFFICE)}>
              {CATEGORIES.OFFICE}
            </div>
          </div>
        </div>
      </div>
      <Result category={category} />
    </div>
  );
};
