import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HiMagnifyingGlass } from "react-icons/hi2";
import DebounceInput from "react-debounce-input";
import toast from "react-hot-toast";
import api from "../../services/api";
import SelectProject from "../../components/selectProject";
import Loader from "../../components/loader";
import Pagination from "../../components/Paginator";

const ToolList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tools, setTools] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const [filters, setFilters] = useState({
    page: parseInt(searchParams.get("page")) || 1,
    per_page: parseInt(searchParams.get("per_page")) || 100,
    search: searchParams.get("search") || "",
    project_id: searchParams.get("project_id") || "",
  });

  const fetchProjects = async () => {
    try {
      const { ok, data } = await api.post("/project/search", { status: "active" });
      if (!ok) throw new Error("Error while fetching projects");
      data.projects.sort(function (a, b) {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
        return 0;
      });
      setProjects(data.projects);
    } catch (error) {
      console.error(error);
      toast.error("Error while fetching projects");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const query = {
        search: filters.search,
        project_id: filters?.project_id,
        page: filters.page,
        per_page: filters.per_page,
      };

      const response = await api.post("/tool/search", query);
      if (!response.ok) throw new Error("Error while fetching tools");

      setTools(response.data);
      setTotal(response.total);

      const newSearchParams = new URLSearchParams();
      if (filters.search) newSearchParams.set("search", filters.search);
      if (filters.project_id) newSearchParams.set("project_id", filters.project_id);
      if (filters.page) newSearchParams.set("page", filters.page);
      if (filters.per_page) newSearchParams.set("per_page", filters.per_page);
      setSearchParams(newSearchParams);
    } catch (error) {
      console.error(error);
      toast.error("Error while fetching tools");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filters, setSearchParams]);

  return (
    <div className="bg-white min-h-screen py-6 px-5">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-semibold">Tools</h1>
        <Create />
      </div>
      <div className="mb-4 flex items-center justify-between gap-1">
        <div className="flex gap-2">
          <div className="flex flex-raw gap-2">
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 top-0 flex items-center pl-3">
                <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <DebounceInput
                debounceTimeout={300}
                name="search"
                id="search"
                className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                placeholder="Search"
                value={filters.search || ""}
                onChange={(e) => {
                  setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
                }}
              />
            </div>
            <select
              value={filters.project_id}
              onChange={(e) => setFilters({ ...filters, project_id: e.target.value })}
              className={`select ${filters.project_id ? "border-sky-500" : ""}`}>
              <option value="">Select a project</option>
              {projects.map((item, i) => (
                <option key={i} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="w-full bg-[#fff] rounded-[10px] border">
            <table className="w-full min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Identifier
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Project Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Link
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {tools.map((e, i) => (
                  <tr key={i} className="cursor-pointer hover:bg-gray-50" onClick={() => navigate(`/tools/${e._id}`)}>
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 flex items-center gap-2">
                      {e.logo ? (
                        <img src={e.logo} alt={e.name} className="h-8 w-8 rounded object-contain" />
                      ) : (
                        <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">{e.name[0]}</div>
                      )}
                      {e.name}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">{e.identifier}</td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{e.project_name}</td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{e.link}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {total / filters.per_page > 1 && (
            <div className="flex justify-center mt-5 w-full">
              <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.per_page)} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const Create = () => {
  const [values, setValues] = useState({ name: "" });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const res = await api.post("/tool", values);
      if (!res.ok) throw res;
      toast.success("Created!");
      setOpen(false);
      navigate(`/tools/${res.data._id}`);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  };

  return (
    <>
      <div className="text-right">
        <button className="btn blue-btn" onClick={() => setOpen(true)}>
          Create a new tool
        </button>
      </div>
      {open && (
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-[#00000066] flex justify-center items-center p-[1rem] z-50" onClick={() => setOpen(false)}>
          <div className="w-full md:w-[60%] h-fit bg-[white] p-[25px] rounded-md" onClick={(e) => e.stopPropagation()}>
            <div className="mb-4">
              <label className="block mb-2">Name</label>
              <input type="text" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} className="w-full p-2 border rounded" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Project</label>
              <SelectProject value={values.project_id} onChange={(e) => setValues({ ...values, project_name: e.name, project_id: e._id })} />
            </div>
            <button type="submit" className="btn blue-btn mt-4" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ToolList;
