import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";

export default ({ data, onClick }) => {
  const arr = [];

  for (let i = 0; i < data.length; i++) {
    const keys = Object.keys(data[i]);
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      if (key === "month") continue;
      if (arr.includes(key)) continue;
      arr.push(key);
    }
  }

  arr.sort();

  return (
    <ResponsiveBar
      data={data}
      keys={arr}
      indexBy="month"
      margin={{ top: 10, right: 200, bottom: 50, left: 60 }}
      padding={0.3}
      groupMode="stacked"
      width={window.innerWidth * 0.95}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      onClick={(data) => {
        if (!onClick) return;
        onClick(data);
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: "Money", legendPosition: "middle", legendOffset: -50 }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color" }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      legends={[
        {
          anchor: "right",
          direction: "column",
          translateX: 150,
          itemWidth: 160,
          itemHeight: 20,
          symbolSize: 20,
          symbolShape: "circle",
          textColor: "#999",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};
