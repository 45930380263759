import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Chart from "../Chart";

export default ({}) => {
  const [data, setData] = useState(null);
  // const [selected, setSelected] = useState(null);

  useEffect(() => {
    get();
  }, []);

  // if (group) return <div />;

  async function get() {
    const obj = {};
    const res = await api.post(`/report/prospection`, obj);
    setData(res.data);
  }

  if (!data) return <div>Loading</div>;

  return (
    <div>
      <h1>Prospection Costs</h1>
      <div className="bg-white mt-4 p-2 rounded">
        <div className="h-[500px] w-1/2 bg-white mt-4 p-2 rounded">
          <Chart data={data} onClick={(e) => console.log(e)} />
        </div>
      </div>
    </div>
  );
};
