import React from "react";
import { Routes, Route } from "react-router-dom";

import View from "./view";

const Organigramme = () => {
  return (
    <Routes>
      <Route path="/" element={<View />} />
    </Routes>
  );
};

export default Organigramme;
