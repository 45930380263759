import React from "react";
import MultiSelect from "../../../components/MultiSelect";

export const STATUSES = [
  { value: "DONE", label: "Done" },
  { value: "TODO", label: "Todo" },
];

export default ({ values = [], onChange }) => {
  return <MultiSelect values={values} id="select-statuses" options={STATUSES} onSelectedChange={onChange} placeholder="Select a status" />;
};
