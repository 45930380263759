import React, {useState} from "react";
import {IoDesktop, IoInformationCircle} from "react-icons/io5";
import {TicketInfo} from "./ticket_info";
import {TicketRaw} from "./ticket_raw";
import {classNames} from "../../../../utils";

const TABS = {
	INFO: "Info",
	RAW: "Raw",
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
	<button
		onClick={() => setTab(tab)}
		className={classNames(
			"group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
			active ? "text-primary  border-primary" : "",
		)}>
		{Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
		<div className="flex items-center gap-2">{title}</div>
		{Badge ? Badge : null}
	</button>
);

export const RightBar = ({ ticket, setTicket }) => {
	const [tab, setTab] = useState(TABS.INFO);

	const render = () => {
		switch (tab) {
			case TABS.INFO:
				return <TicketInfo ticket={ticket} setTicket={setTicket} />;
			case TABS.RAW:
				return <TicketRaw ticket={ticket}/>;
			default:
				return <TicketInfo ticket={ticket} />;
		}
	};

	return (
		<div>
			<div className="bg-white rounded-md m-2 border-[1px] border-gray-200">
				<nav className=" flex flex-row w-full border-b-2	border-gray-200 overflow-hidden">
					<TabItem title={TABS.INFO} tab={TABS.INFO} setTab={setTab} active={tab === TABS.INFO} Icon={IoInformationCircle} />
					<TabItem title={TABS.RAW} tab={TABS.RAW} setTab={setTab} active={tab === TABS.RAW} Icon={IoDesktop} />

				</nav>
				<div className="w-[350px]">{render()}</div>
			</div>
		</div>
	);
};
