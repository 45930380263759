import React, { useEffect, useState } from "react";

import api from "../../../services/api";

const DATES = [];
var date = new Date(Date.UTC(2023, 4, 1, 0, 0, 0));
for (let i = -6; i < 6; i++) {
  DATES.push(new Date(date.setMonth(date.getMonth() + 1)));
}


export default ({ }) => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    get()
  }, []);

  async function get() {

    // const query = { status: "active", per_page: 1000 }
    // const a = await api.post(`/project/search`, query);
    // // const arr = a.data.projects.filter((e) => e.estimation_monthly_budget > 0);
    // let temp = a.data.projects;
    // temp = temp.sort((a, b) => (a.estimation_monthly_budget || 0) - (b.estimation_monthly_budget || 0)).reverse()
    // setProjects(temp)

    // //remove 1 days
    const dateFrom = new Date(DATES[0])
    dateFrom.setDate(dateFrom.getDate() - 1)

    const query2 = { type: "invoice", sent: "yes" }
    const res1 = await api.post(`/invoice/search`, query2);
    const invoices = res1.data

    const query3 = { startDate: dateFrom, per_page: 20000 }
    const res2 = await api.post(`/bank/search`, query3);
    const banks = res2.data.banks

    const query6 = { status: { $in: ["TOPAY", "PENDING"] }, type: "activity"  };
    const res6 = await api.post(`/payment/search`, query6);
    const payments = res6.data

    const query5 = { projectType: { $nin: ["timeoff"] } };
    const res5 = await api.post(`/activity/search`, query5);
    const activities = res5.data

    const res7 = await api.post(`/user/search`)
    const users = res7.data.users

    for (let i=0; i < activities.length; i++) {
      const activity = activities[i]
      const user = users?.find(e => e._id === activity.userId)
      activity.tjm_expense = user?.tjm_expense * activity.total / 8
    } 


    const obj = {}
    for (let i = 0; i < DATES.length; i++) {
      const date = DATES[i]

      const filteredCosts = (banks.filter(e => e.category === "GENERAL_COST" && (e.projectName === "selego" || e.projectName === "le collectif" || e.projectName === "")).filter((f) => areSameMonth(f.month, date)))
      const fixedCosts = parseInt(filteredCosts.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0))

      const filteredActivities = (activities.filter(e => e.projectType != "timeoff")).filter(((f) => areSameMonth(f.date, date)))

      const filteredActivitiesFreelance = filteredActivities.filter(((f) => f.userContract === "FREELANCE"))
      const costActivitiesFreelance = -parseInt(filteredActivitiesFreelance.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0))

      const filteredBanksFreelance = (banks.filter(e => e.sub_category === "SALARY_FREELANCE").filter((f) => areSameMonth(f.month, date)))
      const costBanksFreelance = parseInt(filteredBanksFreelance.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0))

      const filteredPaymentsFreelance = (payments.filter(e => e.contract === "FREELANCE").filter((f) => areSameMonth(f.date, date)))
      const paymentsFreelanceComing = -parseInt(filteredPaymentsFreelance.reduce((acc, f) => acc + (parseInt(f.value) || 0), 0))

      const differenceFreelance = Math.round((costActivitiesFreelance - (costBanksFreelance + paymentsFreelanceComing)) / (costActivitiesFreelance) * 100)

      const filteredBanksEmployee = (banks.filter(e => e.sub_category === "SALARY_EMPLOYEE" || e.sub_category === "TAX_SALARY" ).filter((f) => areSameMonth(f.month, date)))
      const costBanksEmployee = parseInt(filteredBanksEmployee.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0))

      const filteredFixedCostsFromActivities = (filteredActivities.filter((f) => areSameMonth(f.date, date)))
      const fixedCostsFromActivities = -parseInt(filteredFixedCostsFromActivities.reduce((acc, f) => acc + (parseInt(f.tjm_expense) || 0), 0))

      const filteredActivitiesEmployee = filteredActivities.filter(((f) => f.userContract != "FREELANCE"))
      const costActivitiesEmployee = -parseInt(filteredActivitiesEmployee.reduce((acc, f) => acc + (parseInt(f.cost) || 0), 0)) - fixedCostsFromActivities

      const filteredPaymentsEmployee = (payments.filter(e => e.contract != "FREELANCE").filter((f) => areSameMonth(f.date, date)))
      const paymentsEmployee = -parseInt(filteredPaymentsEmployee.reduce((acc, f) => acc + (parseInt(f.value) || 0), 0))

      const differenceEmployee = Math.round(( costActivitiesEmployee - (costBanksEmployee + paymentsEmployee)) / (costActivitiesEmployee) * 100)

      const differenceFixedCosts = Math.round((fixedCostsFromActivities - fixedCosts) / fixedCostsFromActivities * 100)

      const totalDifferenceActivitiesEmployeeInPercent = (((costActivitiesEmployee + fixedCostsFromActivities) - (paymentsEmployee + costBanksEmployee + fixedCosts)) / (costActivitiesEmployee + fixedCostsFromActivities)).toFixed(2) * 100
      const totalDifferenceActivitiesEmployee =  (costActivitiesEmployee + fixedCostsFromActivities) - (paymentsEmployee + costBanksEmployee + fixedCosts)

      obj[date] = { costActivitiesFreelance, costBanksFreelance, paymentsFreelanceComing, differenceFreelance,
         costActivitiesEmployee, costBanksEmployee, paymentsEmployee,  differenceEmployee, 
          fixedCosts, fixedCostsFromActivities, differenceFixedCosts, totalDifferenceActivitiesEmployee, totalDifferenceActivitiesEmployeeInPercent }
    }

    setStats(obj)

  }

  return (
    <div className="p-4">
      <div className="flex justify-between h-12">
        <table>
          <thead className="bg-gray-200">
            <tr>
              <th></th>
              {DATES.map((e) => {
                const options = { month: 'short', year: 'numeric', };
                const str = e.toLocaleDateString('en-US', options) || ""
                return (
                  <th key={e} className="w-[100px]">{str}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Activities Freelance - from activities</div>
                <div className="text-xs text-gray-500 w-[200px]">Sum of all activities not timeoff, userContract FREELANCE</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = stats[e]?.costActivitiesFreelance || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Payments Freelance - from banks</div>
                <div className="text-xs text-gray-500 w-[200px]">Sum of all banks SALARY_FREELANCE</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = stats[e]?.costBanksFreelance || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Payments freelance coming - from payments</div>
                <div className="text-xs text-gray-500 w-[200px]">Payments from freelancers to be coming (from payments screen)</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = stats[e]?.paymentsFreelanceComing || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Error freelance</div>
                <div className="text-xs text-gray-500 font-bold">Difference in % between activities FREELANCE VS banks SALARY_FREELANCE + payments to come</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.differenceFreelance || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >

            <tr>
              <td className="border border-gray-200 text-center">
                <div>Activities Employees - from activities</div>
                <div className="text-xs text-gray-500">Sum of all costs linked to activities of employees, except timeoff activities</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.costActivitiesEmployee || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Payments Employees - from banks</div>
                <div className="text-xs text-gray-500 w-[200px]">Sum of all banks SALARY_EMPLOYEE & TAX_SALARY</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = stats[e]?.costBanksEmployee || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Payments employees coming - from payments</div>
                <div className="text-xs text-gray-500 w-[200px]">Payments from employees to be coming</div>
              </td>
              {
                DATES.map((e, i) => {
                  const value = (stats[e]?.revenue || 0) + (stats[e]?.subvention || 0)
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Error employees</div>
                <div className="text-xs text-gray-500">Difference in % between activities EMPLOYEE VS banks SALARY_EMPLOYEE + payments to come</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.differenceEmployee || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Fixed costs - from activities</div>
                <div className="text-xs text-gray-500">From Employees activities - to do more precisely</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.fixedCostsFromActivities || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Fixed costs - from banks</div>
                <div className="text-xs text-gray-500">Sum of all banks with category GENERAL_COST</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.fixedCosts || 0
                  return (<td className="border border-gray-200 text-center" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Error fixed costs</div>
                <div className="text-xs text-gray-500">Difference between fixed costs from activities & banks GENERAL_COST</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.differenceFixedCosts || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Total error employees</div>
                <div className="text-xs text-gray-500">Difference between activities and reality in €</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.totalDifferenceActivitiesEmployee || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}</td>)
                })
              }
            </tr >
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Total error employees (%)</div>
                <div className="text-xs text-gray-500">Difference between activities and reality - in %</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.totalDifferenceActivitiesEmployeeInPercent || 0
                  return (<td className="border border-gray-200 text-center font-bold" key={e.getTime()} >{addSpaceBetweenNumbers(value)}%</td>)
                })
              }
            </tr >
          </tbody>
        </table>
      </div>
    </div >
  );
};

function addSpaceBetweenNumbers(str) {
  return str.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function areSameMonth(a, b) {
  let aDate = new Date(a)
  let bDate = new Date(b)
  return aDate.getMonth() === bDate.getMonth() && aDate.getFullYear() === bDate.getFullYear()
}