import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { MissionIcon } from "../../../assets/Icons";
import { classNames, copyToClipboard } from "../../../utils";

import api from "../../../services/api";

import Raw from "./raw";
import View from "./view";

export default () => {
  const { id } = useParams();
  const [mission, setMission] = useState();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("about");

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/mission/${id}`);
        setLoading(false);
        setMission(data);
      } catch (e) {
        setLoading(false);
        toast.error(e.message);
      }
    })();
  }, [id]);

  if (loading || !mission) return <div>chargement</div>;

  //get domain from url
  const domain = mission.link ? mission.link.split("/")[2] : "";

  return (
    <div>
      <Helmet>
        <title>{mission.title} - Accounting</title>
      </Helmet>
      <div className="w-[90vw] h-[90vh]">
        <div className="flex flex-col w-full h-full bg-white">
          <div className="flex flex-row items-center justify-between p-3 !pr-14">
            <div className="">
              <div className="flex gap-3">
                <div className="h-8 w-8">
                  <MissionIcon />
                </div>
                <div className="flex items-start gap-1">
                  <div>
                    <div className="text-lg font-bold mb-2">{mission.title}</div>
                    <div className="flex items-center space-x-4 ">
                      {mission.company && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Company: </span>
                          <span className="font-medium text-dull-black">{mission.company}</span>
                        </div>
                      )}
                      {(mission.location || mission.country) && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Location: </span>
                          <span className="font-medium text-dull-black">{mission.location || mission.country}</span>
                        </div>
                      )}
                      {mission.origin && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Origin: </span>
                          <span className="font-medium text-dull-black">{mission.origin}</span>
                        </div>
                      )}
                      {mission.link && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Link to job: </span>
                          <a className="font-medium" href={mission.link} target="_blank">
                            View job
                          </a>
                        </div>
                      )}
                      <div className="flex items-center text-sm space-x-1">
                        <span className="text-dull-grey">Share public: </span>
                        <div
                          className="p-2 hover:bg-lightShade-grey cursor-pointer"
                          onClick={() => {
                            copyToClipboard("https://accounting.selego.co/" + "missions/public/" + mission._id);
                            toast.success("URL copied !");
                          }}>
                          copy link
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-1">
              <div className="text-xs text-gray-500">Created</div>
              <div className="text-sm font-normal">{mission.date ? new Date(mission.date).toLocaleString() : <></>}</div>
            </div>
          </div>

          <nav className="flex flex-row w-full bg-gray-50 border border-gray-100">
            <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} />
            {user?.role === "admin" && <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />}
          </nav>
          <div className="p-4">
            {tab === "about" && <View mission={mission} />}
            {tab === "raw" && <Raw mission={mission} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames("px-4 py-2 cursor-pointer text-sm text-gray-600 hover:text-black", active ? "text-black border-b-2 border-blue-400" : "")}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
