import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { IoInformationCircleOutline, IoPaperPlane } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosLink } from "react-icons/io";
import { FaNoteSticky } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import api from "../../../services/api";

import Modal from "../../../components/modal";
import SelectUsers from "../../../components/selectUser";

import Info from "./info";
import Raw from "./raw";
import Action from "./action";
import People from "./people";
import Send from "./send";
import Note from "./note";
import Status from "./status";

import { classNames, copyToClipboard } from "../../../utils";
import Loose from "./loose";

export default ({ onClose, contact }) => {
  const [tab, setTab] = useState("info");

  const [values, setValues] = useState();
  const [lostModalOpen, setLostModalOpen] = useState(false);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();

  const getFolk = async () => {
    try {
      console.log("get folk")
      const folk_modal_id = query.get("folk_modal_id");
      if (!folk_modal_id) return;
      const folk_modal_tab = query.get("folk_modal_tab") || "info";
      setTab(folk_modal_tab);
      const res = await api.get(`/folk/${folk_modal_id}`);
      if (!res.ok) return;
      setValues(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   const folk_modal_id = query.get("folk_modal_id");
  //   if (values?._id !== folk_modal_id) getFolk();
  // }, [query]);

  useEffect(() => {
    if (!tab) return;
    query.set("folk_modal_tab", tab);
    navigate({ pathname: location.pathname, search: query.toString() });
  }, [tab]);

  useEffect(() => {
    setValues(contact);
  }, [contact]);

  async function handleChange(e) {
    const { name, value } = e.target;
    if (name === "status" && value === "LOST") return setLostModalOpen(true);

    try {
      console.log("contact", contact);
      e.preventDefault();
      const { data, ok } = await api.put(`/folk/${values._id}`, { ...values, status: e.target.value });
      if (!ok) return toast.error("Error");
      setValues(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  }

  if (!values) return <div />;

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        onClose();
        setTab("info");
        query.delete("folk_modal_id");
        query.delete("folk_modal_tab");
        navigate({ pathname: location.pathname, search: query.toString() });
        setValues(null);
      }}>
      <div className="w-full h-[90vh] bg-gray-100 p-2 overflow-y-scroll">
        <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
          <div className="flex justify-between items-center gap-2 p-4">
            <img src={"https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"} alt="userlogo" className="rounded-full w-7 h-7 md:w-14 md:h-14 mr-2" />
            <div className="flex justify-between flex-1">
              <div className="flex items-center gap-2">
                <div className="flex flex-col">
                  <div className="text-lg font-bold">{values.company || "Undefined company"}</div>
                  <div className="">{`${values.first_name} ${values.last_name}`}</div>
                  {values.user_name && (
                    <div className="flex gap-x-2">
                      <span className="text-xs text-[#b7b3b4]">lead</span>
                      <span className="text-xs text-[#b7b3b4]">{values.user_name}</span>
                    </div>
                  )}
                  <span className="text-xs flex-1 text-left text-[#b7b3b4]">Last update: {moment(values.last_updated_at).format("DD/MM/YYYY, HH:mm")}</span>
                </div>
                <button
                  className="hover:bg-gray-100 cursor-pointer rounded-lg p-2 flex items-center gap-1 text-xs text-gray-700"
                  onClick={() => {
                    const v = `${window.location.origin}/folk?folk_modal_id=${values._id.toString()}&folk_modal_tab=${tab}`;
                    copyToClipboard(v);
                    toast.success("Link copy to clipboard");
                  }}>
                  <IoIosLink />
                  Copy link
                </button>
              </div>
            </div>
            <div>
              <div className="flex">
                <Users contact={contact} values={values} setValues={setValues} />
                <Deadline contact={contact} values={values} setValues={setValues} />
                <UserSelect label="Status" name="status" value={values.status} onChange={handleChange}>
                  <option value="CREATED">CREATED (0%)</option>
                  <option value="PROSPECTION">PROSPECTION (10%)</option>
                  <option value="QUALIFIED">QUALIFIED (20%)</option>
                  <option value="DISCOVERY">DISCOVERY (50%)</option>
                  <option value="PROPOSAL">PROPOSAL (75%)</option>
                  <option value="NEGOTIATION">NEGOTIATION (80%)</option>
                  <option value="CLOSING">CLOSING (90%)</option>
                  <option value="WON">WON (100%)</option>
                  <option value="LOST">LOST (0%)</option>
                </UserSelect>
                <Loose open={lostModalOpen} setOpen={setLostModalOpen} values={values} setValues={setValues} onClose={() => {}} />
              </div>
            </div>
          </div>

          <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
            <TabItem title="Info" tab="info" setTab={setTab} active={tab === "info"} Icon={IoInformationCircleOutline} />
            <TabItem title="Actions" tab="action" setTab={setTab} active={tab === "action"} Icon={FaNoteSticky} />
            <TabItem title="People" tab="people" setTab={setTab} active={tab === "people"} Icon={FaNoteSticky} />
            <TabItem title="Note" tab="note" setTab={setTab} active={tab === "note"} Icon={FaNoteSticky} />
            <TabItem title="Send Email" tab="send" setTab={setTab} active={tab === "send"} Icon={IoPaperPlane} />
            <TabItem title="Status" tab="status" setTab={setTab} active={tab === "status"} Icon={FaCheckCircle} />
            <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
          </nav>
        </div>
        <div className="my-2" />
        {tab === "info" && <Info contact={values} setContact={setValues} onClose={onClose} />}
        {tab === "action" && <Action contact={values} />}
        {tab === "people" && <People contact={values} />}
        {tab === "send" && <Send contact={values} />}
        {tab === "note" && <Note contact={values} setContact={setValues} />}
        {tab === "status" && <Status contact={values} setContact={setValues} />}
        {tab === "raw" && <Raw contact={values} />}
      </div>
    </Modal>
  );
};

const UserSelect = ({ name, value, onChange, label, children }) => (
  <div className="w-full">
    <div className="px-1 text-sm text-gray-600 font-medium">{label}</div>
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      type="select"
      name={name}
      value={value}
      onChange={onChange}>
      {children}
    </select>
  </div>
);

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-primary  border-primary" : "",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);

const Users = ({ contact, values, setValues }) => {
  return (
    <>
      <div className="">
        <div className="mr-2 flex flex-row ">
          <div className="w-full">
            <div className="px-1 text-sm text-gray-600 font-medium w-16">Lead</div>
            <SelectUsers
              value={{ _id: values.user_id, name: values.user_name, avatar: values.user_avatar }}
              userName={values.user_name}
              onChange={async (u) => {
                if (!u._id) return;
                const { data, ok } = await api.put(`/folk/${contact._id}`, { ...values, user_name: u.name, user_avatar: u.avatar, user_id: u._id });
                if (!ok) return toast.error("Something went wrong!");
                toast.success(`Added ${u.name} as lead`);
                setValues(data);
              }}
            />
          </div>
          <div className="w-full">
            <div className="px-1 text-sm text-gray-600 font-medium w-16">More</div>
            <SelectUsers
              name="people"
              className="projectsInput"
              placeholder="@somebody"
              onChange={async (u) => {
                if (!u._id) return;
                const { data, ok } = await api.post(`/folk/people/${values._id}`, { user_id: u._id });
                if (!ok) return toast.error("Something went wrong!");
                toast.success(`Added ${u.name}`);
                setValues(data);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Deadline = ({ contact, values, setValues }) => {
  async function onEditEndAt(value) {
    try {
      const { data, ok } = await api.put(`/folk/${contact._id}`, { ...values, end_at: value });
      if (!ok) return toast.error("Error");
      toast.success(`Deadline changed`);
      setValues(data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <div className="w-full">
        <label className="px-1 text-sm text-gray-600 font-medium">Deadline</label>
        <input
          type="date"
          label="EndAt"
          name="end_at"
          defaultValue={values.end_at ? new Date(values.end_at).toISOString().split("T")[0] : null}
          onChange={(e) => {
            onEditEndAt(e.target.value);
          }}
          className="mt-1 block w-full py-2 px-3 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
    </>
  );
};
