import React, { useEffect, useState } from "react";
import { FaUser, FaLinkedin, FaGlobe, FaRegBuilding, FaEnvelope } from "react-icons/fa";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ company, setCompany }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <div className="w-full p-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Company company={company} setCompany={setCompany} />
          </div>
          <div>
            <Users company={company} onSelectUser={setSelectedUser} selectedUser={selectedUser} />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Activities company={company} user={selectedUser} />
          </div>
          <div>
            <Message company={company} user={selectedUser} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Company = ({ company, setCompany }) => {
  const user = useSelector((state) => state.Auth.user);
  const [edit, setEdit] = useState(false);
  const [values, setValues] = useState(company);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.put(`/s_company/${company._id}`, values);
      console.log("data", data);
      setCompany(data);
      toast.success("Updated!");
    } catch (error) {
      console.error(error);
      toast.error("Some Error!");
    }
  };

  return (
    <div className="bg-white shadow-md p-2">
      <button
        className={`btn ${edit ? "red-btn" : "blue-btn"}`}
        onClick={() => {
          if (edit) {
            setValues(company); // Reset values when canceling edit
          }
          setEdit(!edit);
        }}>
        {edit ? "Cancel" : "Edit"}
      </button>
      {edit && (
        <form onSubmit={handleSubmit} className="col-span-2">
          <div className="flex items-center">
            <FaUser className="mr-3" />
            <input className="input w-full" type="text" name="name" value={values.name} placeholder="Enter name" onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <div className="flex items-center">
            {!values.linkedin ? (
              <FaLinkedin className="mr-3" />
            ) : (
              <a href={`${values.linkedin}`} target="_blank" className="mr-3">
                <FaLinkedin />
              </a>
            )}
            <input
              className="input w-full"
              type="text"
              name="linkedin"
              value={values.linkedin}
              placeholder="LinkedIn profile URL"
              onChange={(e) => setValues({ ...values, linkedin: e.target.value })}
            />
          </div>
          <div className="flex items-center">
            {!values.website ? (
              <FaGlobe className="mr-3" />
            ) : (
              <a href={`http://${values.website}`} target="_blank" className="mr-3">
                <FaGlobe />
              </a>
            )}
            <input
              className="input w-full"
              type="text"
              name="website"
              value={values.website}
              placeholder="Website URL"
              onChange={(e) => setValues({ ...values, website: e.target.value })}
            />
          </div>
          <div className="flex items-center">
            <FaRegBuilding className="mr-3" />
            <select className="input w-full" value={values.personnae} onChange={(e) => setValues({ ...values, personnae: e.target.value })}>
              <option value="">Select personae</option>
              <option value="BUSINESS_REFERRERS">BUSINESS_REFERRERS</option>
              <option value="INCUBATORS">INCUBATORS</option>
              <option value="STARTUPS_ADVANCED">STARTUPS_ADVANCED</option>
              <option value="STARTUPS_EARLYSTAGE">STARTUPS_EARLYSTAGE</option>
              <option value="CORPORATES">CORPORATES</option>
              <option value="GOVERNMENT">GOVERNMENT</option>
            </select>
          </div>
          <div className="flex items-center">
            <FaRegBuilding className="mr-3" />
            <select className="input w-full" value={values.stage} onChange={(e) => setValues({ ...values, stage: e.target.value })}>
              <option value="">Select stage</option>
              <option value="idea">Idea</option>
              <option value="businessPlan">Business Plan</option>
              <option value="activeUsers">Active Users</option>
            </select>
          </div>
          <div className="flex items-center">
            <FaRegBuilding className="mr-3" />
            <select className="input w-full" value={values.source} onChange={(e) => setValues({ ...values, source: e.target.value })}>
              <option value="">Select source</option>
              <option value="cofounder">Cofounder</option>
              <option value="other">Other</option>
            </select>
          </div>
          {values?.linkedin_raw?.employees && (
            <div className="flex items-center">
              <FaRegBuilding className="mr-3" />
              <div>{values?.linkedin_raw?.employees} employees</div>
            </div>
          )}
          <div className=" col-span-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="description">
              Description
            </label>
            <textarea rows={4} className="input w-full" name="description" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
          </div>
          <button className="btn blue-btn" type="submit">
            Save
          </button>
        </form>
      )}

      {!edit && (
        <div className="space-y-4">
          <div className="flex items-center">
            <FaUser className="mr-3" />
            <span>{values.name}</span>
          </div>
          <div className="flex items-center">
            {values.linkedin ? (
              <a href={values.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                <FaLinkedin className="mr-3" />
              </a>
            ) : (
              <span className="text-gray-500">No LinkedIn profile</span>
            )}
          </div>
          <div className="flex items-center">
            <FaGlobe className="mr-3" />
            {values.website ? (
              <a href={`http://${values.website}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                {values.website}
              </a>
            ) : (
              <span className="text-gray-500">No website</span>
            )}
          </div>
          <div className="flex items-center">
            <FaRegBuilding className="mr-3" />
            <span>{values.personnae || "No personae selected"}</span>
          </div>
          <div className="flex items-center">
            <span>{values.stage || "No stage selected"}</span>
          </div>
          <div className="flex items-center">
            <span>{values.source || "No source selected"}</span>
          </div>
          {values?.linkedin_raw?.employees && (
            <div className="flex items-center">
              <div>{values.linkedin_raw.employees} employees</div>
            </div>
          )}
          <div>
            <p className="text-gray-600">{values.description || "No description"}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const Activities = ({ company, user }) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    fetchActivities();
  }, [user]);

  async function fetchActivities() {
    try {
      setLoading(true);
      const { data } = await api.post(`/s_activity/search`, { contact_id: user._id });
      setActivities(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Error fetching activities");
      setLoading(false);
    }
  }

  if (!user) return null;

  return (
    <div className="bg-white shadow-md p-4">
      <h3 className="font-medium mb-4">Activities</h3>
      {loading ? (
        <div>Loading activities...</div>
      ) : activities.length === 0 ? (
        <div className="text-gray-500">No activities found</div>
      ) : (
        <div className="space-y-4">
          {activities.map((activity) => (
            <div key={activity._id} className="border-b pb-2">
              <div className="font-medium">{activity.type}</div>
              <div className="text-sm text-gray-600">{activity.description}</div>
              <div className="text-xs text-gray-400">{new Date(activity.created_at).toLocaleString()}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

//{{s_lk_message}}

//cam_ZiGnEZjAYqN7CrDzD

// message,
// method: sendMethod,

const Message = ({ company, user }) => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [sendMethod, setSendMethod] = useState("default");
  if (!user) return null;

  async function onGenerateMessage() {
    try {
      setLoading(true);
      const res = await api.post(`/s_contact/generate/${user._id}`);
      if (!res.ok) throw res;
      toast.success("Message generated");
      setValues(res.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!", e.code);
    }
  }

  async function onSendMessage() {
    try {
      toast.success("Todo");
      const res = await api.post(`/s_contact/send`, { message, method: sendMethod, contact_id: user._id });
      if (!res.ok) throw res;
      toast.success("Message sent");
      setValues(res.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!", e.code);
    }
  }

  return (
    <div className="bg-white shadow-md p-4">
      <div className="font-medium mb-2">PERSONANE ??? </div>
      <div className="mb-4">{user?.first_name}</div>
      <button className="btn blue-btn mb-4" onClick={onGenerateMessage}>
        {loading ? "Loading..." : "Generate Message"}
      </button>
      <pre className="mb-4">{JSON.stringify(values, null, 2)}</pre>
      <div className="font-medium mb-2">Send Method</div>
      <select className="input w-full mb-4" value={sendMethod} onChange={(e) => setSendMethod(e.target.value)}>
        <option value="default">Default</option>
        <option value="linkedin">LinkedIn</option>
        <option value="email">Email</option>
      </select>
      <div className="font-medium mb-2">Message</div>
      <div>
        <textarea className="input w-full mb-2" value={message} onChange={(e) => setMessage(e.target.value)} rows={4} />
        <button className="btn blue-btn w-full" onClick={onSendMessage} disabled={loading}>
          {loading ? "Sending..." : "Send"}
        </button>
      </div>
    </div>
  );
};

const Users = ({ company, onSelectUser, selectedUser }) => {
  const [users, setUsers] = useState([]); // Initialize with one empty user

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    try {
      const res = await api.post(`/s_contact/search`, { company_id: company._id, limit: 10 });
      if (!res.ok) throw res;
      setUsers(res.data);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!", e.code);
    }
  }

  console.log("users", users);

  //https://www.linkedin.com/in/jeandemaupeou/

  return (
    <div className="">
      {users.map((user, index) => (
        <User key={user._id} user={user} onSelectUser={onSelectUser} fetchUsers={fetchUsers} selectedUser={selectedUser} />
      ))}
      <div className="user-form bg-white shadow-md mb-2 p-2">
        <button
          className="btn px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600"
          onClick={async () => {
            try {
              const obj = {
                companies: [
                  {
                    company_id: company._id,
                    name: company.name,
                    description: company.description,
                    logo: company.logo,
                    linkedin_id: company.linkedin_id,
                    linkedin_pseudo: company.linkedin_pseudo,
                    linkedin: company.linkedin,
                    website: company.website,
                  },
                ],
              };
              const res = await api.post("/s_contact", obj);
              if (!res.ok) throw res;
              toast.success("User created");
              fetchUsers();
            } catch (e) {
              console.error(e);
              toast.error("Some Error!", e.code);
            }
          }}>
          Create
        </button>
      </div>
    </div>
  );
};

const User = ({ user, onSelectUser, fetchUsers, selectedUser }) => {
  const [values, setValues] = useState(user);
  const [isEditing, setIsEditing] = useState(false);

  async function onUpdateUser() {
    try {
      const res = await api.put(`/s_contact/${values._id}`, values);
      if (!res.ok) throw res;
      toast.success("User updated");
      setIsEditing(false);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!", e.code);
    }
  }

  async function onDeleteUser() {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const res = await api.remove(`/s_contact/${values._id}`);
        if (!res.ok) throw res;
        toast.success("User deleted");
        fetchUsers();
      } catch (e) {
        console.error(e);
        toast.error("Some Error!", e.code);
      }
    }
  }

  //avatar

  if (!isEditing) {
    return (
      <div className={`user-form bg-white shadow-md mb-2 p-4 ${selectedUser?._id === user._id ? "ring-2 ring-blue-500" : ""}`} onClick={() => onSelectUser(user)}>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center mr-4 overflow-hidden">
              {values?.linkedin_raw?.avatar ? (
                <img src={values.linkedin_raw.avatar} alt="avatar" className="w-full h-full object-cover rounded-full" style={{ aspectRatio: "1/1" }} />
              ) : (
                values.first_name?.[0]?.toUpperCase() || "U"
              )}
            </div>
            <div>
              <h3 className="font-medium">{`${values.first_name || ""} ${values.last_name || ""}`}</h3>
            </div>
          </div>
          <div className="flex space-x-2">
            <button
              className="btn px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-600"
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
              }}>
              Edit
            </button>
          </div>
        </div>
        <p className="text-sm text-gray-600 line-clamp-2">{values.description || values?.linkedin_raw?.description || "No description"}</p>

        <div className="flex space-x-3">
          {values.linkedin && (
            <a href={values.linkedin} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-500">
              <FaLinkedin size={20} />
            </a>
          )}
          {values.email && (
            <a href={`mailto:${values.email}`} className="text-gray-600 hover:text-blue-500">
              <FaEnvelope size={20} />
            </a>
          )}
          {values.client_id && (
            <a href={`https://app.lemlist.com/leads/${values.client_id}`} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-500">
              <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Emblem-important.svg/100px-Emblem-important.svg.png" alt="Lemlist" className="w-5 h-5" />
            </a>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`user-form bg-white shadow-md mb-2 p-2 ${selectedUser?._id === user._id ? "ring-2 ring-blue-500" : ""}`}>
      <div className="flex items-center mb-4">
        {values.linkedin ? (
          <a href={values.linkedin} target="_blank" rel="noopener noreferrer" className="mr-3">
            <FaLinkedin />
          </a>
        ) : (
          <FaLinkedin className="mr-3" />
        )}
        <input
          className="input flex-1 p-2 border border-gray-300 rounded"
          type="text"
          name="linkedin"
          placeholder="LinkedIn profile URL"
          value={values.linkedin || ""}
          onChange={(e) => setValues({ ...values, linkedin: e.target.value })}
        />
      </div>
      <div className="flex items-center mb-4">
        <FaUser className="mr-3" />
        <input
          className="input flex-1 p-2 border border-gray-300 rounded"
          type="text"
          name="name"
          placeholder="Enter first name"
          value={values.first_name || ""}
          onChange={(e) => setValues({ ...values, first_name: e.target.value })}
        />
        <input
          className="input flex-1 p-2 border border-gray-300 rounded ml-2"
          type="text"
          name="name"
          placeholder="Enter last name"
          value={values.last_name || ""}
          onChange={(e) => setValues({ ...values, last_name: e.target.value })}
        />
      </div>
      <div className="flex items-center mb-4">
        {values.email ? (
          <a href={`mailto:${values.email}`} target="_blank" rel="noopener noreferrer" className="mr-3">
            <FaEnvelope />
          </a>
        ) : (
          <FaEnvelope className="mr-3" />
        )}
        <input
          className="input flex-1 p-2 border border-gray-300 rounded"
          type="email"
          name="email"
          placeholder="Email"
          value={values.email || ""}
          onChange={(e) => setValues({ ...values, email: e.target.value })}
        />
      </div>

      <div className="mb-4">
        <textarea
          rows={4}
          className="input w-full p-2 border border-gray-300 rounded"
          name="description"
          placeholder="Description"
          value={values.description || values?.linkedin_raw?.description || ""}
          onChange={(e) => setValues({ ...values, description: e.target.value })}
        />
      </div>
      <div className="flex space-x-2">
        <button className="btn px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600" onClick={onUpdateUser}>
          Save
        </button>
        <button
          className="btn px-4 py-2 rounded bg-gray-500 text-white hover:bg-gray-600"
          onClick={() => {
            setValues(user);
            setIsEditing(false);
          }}>
          Cancel
        </button>
        <button className="btn px-4 py-2 rounded bg-red-500 text-white hover:bg-red-600" onClick={onDeleteUser}>
          Delete
        </button>
      </div>
    </div>
  );
};
