import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DebounceInput from "react-debounce-input";
// import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";

import Rating from "./rating";
import Loader from "../../components/loader";

import api from "../../services/api";

export default () => {
  const [workshops, setWorkshops] = useState();
  const [filter, setFilter] = useState({ search: "" });

  useEffect(() => {
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    try {
      const { data: w } = await api.get("/workshop/public" + "?" + new URLSearchParams(filter).toString());
      setWorkshops(w);
    } catch (e) {
      console.log(e);
    }
  };

  if (!workshops) return <Loader />;

  return (
    <div className="flex flex-col">
      <div className="p-3">
        <div className="relative text-[#A0A6B1]">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" className="p-1">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-5 h-5">
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </span>
          <DebounceInput
            debounceTimeout={300}
            className="py-2 w-[364px] text-[14px] font-normal text-[black] rounded-[10px] bg-[#F9FBFD] border border-[#FFFFFF] pl-10"
            placeholder="Search (name, tags, etc...)"
            value={filter.search}
            onChange={(e) => {
              e.persist();
              setFilter((prev) => ({ ...prev, search: e.target.value }));
            }}
          />
        </div>
        <h3 className="text-lg my-4 font-semibold">Tutorials</h3>
        {workshops.filter((e) => e.category === "lesson").length === 0 && <Empty />}
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
          {workshops
            .filter((e) => e.category === "lesson")
            .map((e) => (
              <Card workshop={e} />
            ))}
        </div>
        <h3 className="text-xl text-dull-black my-4 font-semibold">Post mortem</h3>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
          {workshops
            .filter((e) => e.category === "mortem")
            .map((e) => (
              <Card workshop={e} />
            ))}
        </div>
        <h3 className="text-xl text-dull-black my-4 font-semibold">Profiling</h3>
        {workshops.filter((e) => e.category === "profil").length === 0 && <Empty />}
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
          {workshops
            .filter((e) => e.category === "profil")
            .map((e) => (
              <Card workshop={e} />
            ))}
        </div>
        <h3 className="text-xl text-dull-black my-4 font-semibold">Challenges</h3>
        {workshops.filter((e) => e.category === "challenge").length === 0 && <Empty />}
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
          {workshops
            .filter((e) => e.category === "challenge")
            .map((e) => (
              <Card workshop={e} />
            ))}
        </div>

        <h3 className="text-xl text-dull-black my-4 font-semibold">Admin</h3>
        {workshops.filter((e) => e.category === "admin").length === 0 && <Empty />}
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
          {workshops
            .filter((e) => e.category === "admin")
            .map((e) => (
              <Card workshop={e} />
            ))}
        </div>
      </div>
    </div>
  );
};

const Card = ({ workshop }) => {
  function renderStatus() {
    //   if (!userWorkshop) return <div />;
    //   if (userWorkshop.finished_at) return <img src={DoneIcon} />;
    //   return <Progress value={userWorkshop.step_id} total={workshop.steps.length} />;
  }

  return (
    <Link to={`/learn_public/${workshop._id}`} className="flex flex-col no-underline	hover:no-underline bg-white rounded-md shadow-md p-4 cursor-pointer hover:shadow-lg">
      <div className="flex justify-between mb-4">
        <img src={workshop.image} className="object-cover rounded-xl w-28 h-28" />
        <div className="h-8 w-8 shrink-0"> {renderStatus()}</div>
      </div>
      <div className="text-xl font-bold text-dull-black">{workshop.name}</div>
      {workshop.read_time && <div className="text-sm text-dull-grey">({workshop.read_time} read)</div>}
      <div className="flex items-center space-x-2 text-sm text-light-grey my-2">
        <span>Rating:</span>
        {!!workshop.rating_score && (
          <>
            <Rating value={workshop.rating_score} /> <strong>{`${workshop.rating_score} (${workshop.rating_count})`}</strong>
          </>
        )}
      </div>
      <div className="text-sm text-light-grey mt-1 mb-2">{workshop.description}</div>
      <div className="text-xs font-medium text-dull-grey mt-auto text-right">Last updated at: {(workshop.updated_at || "").slice(0, 16).replace("T", " ")}</div>
    </Link>
  );
};

const Empty = () => <span className="px-3 py-1 bg-gray-50 text-gray-400 rounded-lg border-[1px] border-gray-100 text-xs">No elements</span>;

// const Progress = ({ value, total }) => (
//   <CircularProgressbar
//     value={value}
//     maxValue={total}
//     strokeWidth={14}
//     styles={buildStyles({
//       strokeLinecap: "round",
//       pathColor: `#0560FD`,
//       trailColor: "#E5EAEF",
//       backgroundColor: "transparent",
//     })}
//   />
// );
