import React from "react";
import MultiSelect from "../../../../components/MultiSelect";

export default ({ values, onChange }) => {
  return (
    <MultiSelect
      values={values}
      id="select-statuses"
      options={[
        { value: "DONE", label: "Done" },
        { value: "TODO", label: "Todo" },
      ]}
      onSelectedChange={onChange}
      placeholder="Select a status"
    />
  );
};
