import React from "react";
import { IoCodeSlash } from "react-icons/io5";
import { IoArrowForwardOutline, IoSettingsOutline } from "react-icons/io5";
import { RiMegaphoneLine } from "react-icons/ri";
import { FaRegStar } from "react-icons/fa";

import Loader from "../../../components/loader";
import { classNames } from "../../../utils";

const ProgressCard = ({ category, answers, questions, onClick }) => {
  const total = questions.filter((q) => q.skillCategory === category).length;
  const questionsByCategory = questions.filter((q) => q.skillCategory === category);
  const answeredQuestions = answers.filter((a) => questionsByCategory.find((q) => q._id === a.questionId));

  let levelSum = 0;
  const subCategories2 = questionsByCategory.map((q) => q.skillSubCategory);
  const subCategories = [...new Set(subCategories2)];

  const levelsValidatedSubCategory = [];
  for (let s = 0; s < subCategories.length; s++) {
    const questionsBySubCategory = questions.filter((q) => q.skillSubCategory === subCategories[s]);
    const answeredQuestionsSubCategory = answers.filter((a) => questionsBySubCategory.find((q) => q._id === a.questionId));

    let levelValidatedSubCategory = 0;
    for (let i = 1; i <= 5; i++) {
      // if there is no question for this level, we consider the level validated
      const questionsForThisLevel = questionsBySubCategory.filter((question) => question.level === i);
      if (!questionsForThisLevel.length) {
        levelValidatedSubCategory = i;
        levelsValidatedSubCategory.push(i);
        continue;
      }

      // get all answers for this subCategory and this level
      const answersForThisLevel = answeredQuestionsSubCategory.filter((answer) => answer.levelValue === i);
      // if there is at least one falsy answer for this level, we consider the level not validated and we stop the loop
      if (!answersForThisLevel.length || answersForThisLevel.find((answer) => !parseInt(answer.value))) {
        break;
      }
      levelValidatedSubCategory = i;
      levelsValidatedSubCategory.push(i);
    }
    levelSum += levelValidatedSubCategory;
  }
  const averageLevel = (levelSum / subCategories.length).toFixed(1);
  const level = Math.min(...levelsValidatedSubCategory);

  const bgColors = {
    Code: "from-cyan-900 to-cyan-400",
    Communication: "from-emerald-900 to-emerald-400",
    Leadership: "from-fuchsia-900 to-fuchsia-400",
    Product: "from-yellow-900 to-yellow-400",
  };

  const graphColor = {
    Code: "#0891b2",
    Communication: "#059669",
    Leadership: "#c026d3",
    Product: "#EABB17",
  };

  const icon = {
    Code: <IoCodeSlash />,
    Communication: <RiMegaphoneLine />,
    Leadership: <FaRegStar />,
    Product: <IoSettingsOutline />,
  };

  if (!answers) return <Loader />;

  return (
    <button
      className={`group relative flex w-[220px] h-[320px] flex-col justify-start items-center m-1 rounded-lg shadow border border-neutral-200 overflow-hidden`}
      onClick={onClick}>
      <div
        className={classNames(
          `absolute rounded-full -top-[330px] -right-[200px] bottom-0 -left-[200px] w-auto bg-green-300 transition ease-in-out -translate-y-48 group-hover:-translate-y-32 bg-gradient-to-b`,
          bgColors[category],
        )}></div>
      <p className="text-white z-10 mb-4 text-xs uppercase tracking-widest mt-3">{category}</p>
      <p className="text-white z-10 text-3xl">{icon[category]}</p>
      <div className="flex-1" />
      <div className="transition ease-in-out group-hover:-translate-y-10">
        <CircleScore value={answeredQuestions.length} total={total} color={graphColor[category]} level={level} averageLevel={averageLevel} />
      </div>
      <div className={`flex w-full items-center justify-center text-neutral-500 mb-3 transition ease-in-out group-hover:translate-y-32`}>
        {Math.round((answeredQuestions.length / total) * 100)}%
      </div>
      <span className={`absolute -bottom-32 flex w-full items-center justify-center text-black font-bold transition ease-in-out group-hover:-translate-y-36`}>
        Go <IoArrowForwardOutline />
      </span>
    </button>
  );
};

const CircleScore = ({ value, total = 100, level = "-", averageLevel = "-", color }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const percentage = value / total;
  const strokeDashoffset = String(circumference * (1 - percentage));

  return (
    <div className="relative flex items-center justify-center w-36 h-36">
      <svg className="-rotate-90" width="100%" height="100%" viewBox="0 0 80 80">
        <circle cx="40" cy="40" r={radius} fill="transparent" stroke={"#DFE1E5"} strokeWidth="4" />
        <circle cx="40" cy="40" r={radius} fill="white" stroke={color} strokeWidth="4" strokeDasharray={circumference} strokeDashoffset={strokeDashoffset} strokeLinecap="round" />
      </svg>
      <div className="absolute flex flex-col items-center">
        <div className="text-neutral-500 tracking-[.5em] ml-[.5em] text-xs mb-2 uppercase">level</div>
        <div>
          <div className="text-lg font-semibold">
            {level}
            <span className="text-xs font-light text-gray-500">/5</span>
          </div>
          <div className="text-xs font-light text-gray-500">avg {averageLevel}</div>
        </div>
      </div>
    </div>
  );
};

export default ProgressCard;
