import React, { useEffect, useState } from "react";
import { RiCake2Line } from "react-icons/ri";

import DayTile from "../../components/DayTile";
import api from "../../services/api";

const Birthdays = () => {
  const [users, setUsers] = useState();
  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    const { data } = await api.get(`/user/incoming-birthdays`);
    setUsers(data);
  }

  if (!users) return null;
  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md">
      <div className="p-3 md:flex-auto">
        <h1 className="flex items-center gap-1 text-base font-semibold leading-6 text-gray-900">
          Incoming Birthdays <RiCake2Line />
        </h1>
      </div>
      <div className="p-2 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
        {users
          .sort((a, b) => new Date(a.nextBirthdate) - new Date(b.nextBirthdate))
          .map((w) => {
            return (
              <div key={w.name} className="bg-white mb-[10px] rounded-lg shadow-sm flex items-center justify-between gap-4 p-3 flex-wrap border-[1px] border-gray-100">
                <div className="flex gap-2 items-center flex-1">
                  <img src={w.avatar} alt="userlogo" className="rounded-full w-8 h-8" />
                  <h3 className="font-semibold text-sm">{w.name}</h3>
                </div>
                <div>
                  <DayTile date={w.birthdate} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Birthdays;
