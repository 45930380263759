import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import api from "../../../services/api";

export default () => {
  const [channel, setChannel] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/jobchannel/${id}`);
      setChannel(data);
    })();
  }, []);

  const navigate = useNavigate();

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/jobchannel/${id}`);
    toast.success("successfully removed!");
    navigate("/jobchannel");
  }

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/jobchannel/${id}`, channel);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setChannel(data);
      toast.success("Channel updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!channel) return <Loader />;

  console.log("channel", channel);

  return (
    <div className="flex flex-col gap-4 pt-2 ">
      <div className="bg-white rounded-2xl p-2">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-[14px] text-[#212325] font-medium mt-2">Name (*)</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.name}
              onChange={(e) => setChannel({ ...channel, name: e.target.value })}
            />
          </div>
          <div>
            <div className="text-[14px] text-[#212325] font-medium">Url</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.url}
              onChange={(e) => setChannel({ ...channel, url: e.target.value })}
            />
          </div>
          <div>
            <div className="text-[14px] text-[#212325] font-medium">Login</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.login}
              onChange={(e) => setChannel({ ...channel, login: e.target.value })}
            />
          </div>
          <div>
            <div className="text-[14px] text-[#212325] font-medium">Password</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.password}
              onChange={(e) => setChannel({ ...channel, password: e.target.value })}
            />
          </div>
          <div className=" col-span-2">
            <div className="text-[14px] text-[#212325] font-medium">Description</div>
            <textarea
              rows="5"
              className="projectsInput h-20 text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.description}
              onChange={(e) => setChannel({ ...channel, description: e.target.value })}
            />
          </div>
          <div>
            <div className="text-[14px] text-[#212325] font-medium">Category</div>
            <select
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.category}
              onChange={(e) => setChannel({ ...channel, category: e.target.value })}>
              <option value=""></option>
              <option value="SCHOOL">SCHOOL</option>
              <option value="COMMUNITY">COMMUNITY</option>
              <option value="JOBBOARD">JOBBOARD</option>
              <option value="FREELANCE">FREELANCE</option>
            </select>
          </div>

          {channel.category === "SCHOOL" && (
            <div>
              <div className="text-[14px] text-[#212325] font-medium">Rank</div>
              <select
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                value={channel.rank}
                onChange={(e) => setChannel({ ...channel, rank: e.target.value })}>
                <option value=""></option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
              </select>
            </div>
          )}

          {channel.category === "SCHOOL" && (
            <div>
              <div className="text-[14px] text-[#212325] font-medium">Periods</div>
              <PeriodsComponent value={channel.periods} onChange={(periods) => setChannel({ ...channel, periods })} />
            </div>
          )}

          <div>
            <div className="text-[14px] text-[#212325] font-medium">Location</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.location}
              onChange={(e) => setChannel({ ...channel, location: e.target.value })}
            />
          </div>
        </div>
        <div className="flex  mt-2">
          <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={handleSubmit}>
            Update
          </button>
          <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
function PeriodsComponent({ value, onChange }) {
  const [editIndex, setEditIndex] = useState(null); // Tracks which period is being edited

  const handleAddPeriod = () => {
    const newPeriod = {
      name: "",
      experience: "BAC_1",
      from: "JANUARY",
      to: "JUNE",
    };
    onChange([...value, newPeriod]);
    setEditIndex(value.length); // Set new period in edit mode immediately
  };

  const handleDeletePeriod = (index) => {
    if (index === editIndex) {
      setEditIndex(null); // Clear edit mode if deleting currently edited period
    }
    const updatedPeriods = value.filter((_, i) => i !== index);
    onChange(updatedPeriods);
  };

  const handleEditPeriod = (index) => {
    setEditIndex(index); // Set specific period in edit mode
  };

  const handleSavePeriod = (index) => {
    setEditIndex(null); // Exit edit mode
  };

  const handleChange = (index, field, valueChange) => {
    const updatedPeriods = value.map((period, i) => {
      if (i === index) {
        return { ...period, [field]: valueChange };
      }
      return period;
    });
    onChange(updatedPeriods);
  };

  return (
    <div className="container mx-auto p-4">
      {value.map((period, index) => (
        <div key={index} className="mb-4 p-2 border rounded">
          {editIndex === index ? (
            <>
              <input type="text" placeholder="Name" value={period.name} onChange={(e) => handleChange(index, "name", e.target.value)} className="p-2 border rounded w-full" />
              <select value={period.experience} onChange={(e) => handleChange(index, "experience", e.target.value)} className="p-2 border rounded w-full my-2">
                {["BAC_1", "BAC_2", "BAC_3", "BAC_4", "BAC_5"].map((exp) => (
                  <option key={exp} value={exp}>
                    {exp}
                  </option>
                ))}
              </select>
              <select value={period.from} onChange={(e) => handleChange(index, "from", e.target.value)} className="p-2 border rounded w-full mb-2">
                {["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"].map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <select value={period.to} onChange={(e) => handleChange(index, "to", e.target.value)} className="p-2 border rounded w-full">
                {["", "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"].map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <button onClick={() => handleSavePeriod(index)} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Save
              </button>
            </>
          ) : (
            <>
              <div>Name: {period.name}</div>
              <div>Experience: {period.experience}</div>
              <div>From: {period.from}</div>
              <div>To: {period.to}</div>
              <button onClick={() => handleEditPeriod(index)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Edit
              </button>
            </>
          )}
          <button onClick={() => handleDeletePeriod(index)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 ml-2 rounded">
            Delete
          </button>
        </div>
      ))}
      <button onClick={handleAddPeriod} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Add Period
      </button>
    </div>
  );
}
