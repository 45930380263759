import React, { useState, useEffect } from "react";
import moment from "moment";

import api from "../../services/api";
import Loader from "../../components/loader";

const Files = ({ user }) => {
  const [devices, setDevices] = useState();

  const getDevices = async () => {
    try {
      const { data } = await api.get(`/user/${user._id.toString()}/devices`);
      setDevices(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDevices();
  }, []);

  const renderStatus = (device) => {
    if (device.status === "active") {
      return (
        <div className="flex gap-2 items-center justify-center w-full">
          <div className="w-2 h-2 rounded-full bg-green-400" />
          <div className="text-xs tracking-wider text-green-400">{device.status}</div>
        </div>
      );
    } else if (device.status === "inactive") {
      return (
        <div className="flex gap-2 items-center justify-center w-full">
          <div className="w-2 h-2 rounded-full bg-red-400" />
          <div className="text-xs tracking-wider text-red-400">{device.status}</div>
        </div>
      );
    }
  };

  if (!devices) return <Loader />;
  if (!devices.length)
    return (
      <div className="flex flex-col items-center gap-3">
        <div className="text-gray-700 px-3">No device found for this user</div>
        <a href="/device/add" className="px-3 py-1 rounded-md text-white bg-primary">
          Add a device
        </a>
      </div>
    );

  return (
    <div className="grid grid-cols-2 gap-4 p-2">
      {devices.map((device) => (
        <div className="w-full border border-gray-100 shadow-lg rounded-md bg-[#7a7679]">
          <div className="my-10" />
          <div className="flex justify-center">
            <img src={"https://support.apple.com/library/APPLE/APPLECARE_ALLGEOS/SP825/macbookair.png"} alt="mac-logo" className="w-32" />
          </div>
          <div className="my-8" />
          <div>
            <div className="w-full text-lg text-center font-bold text-white">{device.name || device.model}</div>
            <div>{renderStatus(device)}</div>
          </div>
          <div className="my-5" />
          <div className="flex flex-col items-center">
            <div className="flex items-center gap-4 w-full">
              <span className="text-sm flex-1 text-right text-gray-100">Model</span>
              <span className="text-sm flex-1 text-left text-gray-200">{device.model}</span>
            </div>
            <div className="flex items-center gap-4 w-full">
              <span className="text-sm flex-1 text-right text-gray-100">Serial Number</span>
              <span className="text-sm flex-1 text-left text-gray-200">{device.serial_number}</span>
            </div>
            <div className="flex items-center gap-4 w-full">
              <span className="text-sm flex-1 text-right text-gray-100">RAM</span>
              <span className="text-sm flex-1 text-left text-gray-200">{device.ram}</span>
            </div>
            <div className="flex items-center gap-4 w-full">
              <span className="text-sm flex-1 text-right text-gray-100">Storage</span>
              <span className="text-sm flex-1 text-left text-gray-200">{device.storage}</span>
            </div>
            <div className="flex items-center gap-4 w-full">
              <span className="text-sm flex-1 text-right text-gray-100">User</span>
              <span className="text-sm flex-1 text-left text-gray-200">{device.userName}</span>
            </div>
          </div>
          {device.comments ? (
            <>
              <div className="my-5 border-b border-gray-400 w-1/3 mx-auto" />
              <div className="flex items-center gap-4 w-full">
                <span className="text-sm flex-1 text-right text-gray-100">Comments</span>
                <span className="text-sm flex-1 text-left text-gray-200">{device.comments}</span>
              </div>
            </>
          ) : null}
          <div className="my-5" />
          <div className="flex flex-row items-center justify-center gap-2">
            <a href={`/device/${device._id.toString()}/edit`} className="text-xs text-white bg-[#b8b4b6] rounded-md py-1 px-2">
              Edit
            </a>
          </div>
          <div className="my-4" />
          <div className="flex flex-col items-center">
            <div className="flex items-center gap-4 w-full">
              <span className="text-xs flex-1 text-right text-[#b7b3b4]">Updated at</span>
              <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(device.updatedAt).format("DD/MM/YYYY, HH:mm")}</span>
            </div>
            <div className="flex items-center gap-4 w-full">
              <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created at</span>
              <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(device.createdAt).format("DD/MM/YYYY, HH:mm")}</span>
            </div>
          </div>
          <div className="my-10" />
        </div>
      ))}
    </div>
  );
};

export default Files;
