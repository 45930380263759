import React from "react";
import moment from "moment";

import { IoPeople, IoCalendarClear } from "react-icons/io5";
import { RiMoneyEuroCircleFill, RiErrorWarningLine } from "react-icons/ri";

const Card = ({ lead, onClick }) => {
  const renderWarning = () => {
    if (new Date().getTime() - new Date(lead.updated_at).getTime() >= 86400000 * 14)
      return (
        <div className="text-xs font-medium mt-2 flex items-center text-red-500">
          <RiErrorWarningLine className="text-lg mr-1" /> No activitiy since last 2 weeks
        </div>
      );
    if (new Date().getTime() - new Date(lead.updated_at).getTime() >= 86400000 * 7)
      return (
        <div className="text-xs font-medium mt-2 flex items-center text-orange-500">
          <RiErrorWarningLine className="text-lg mr-1" /> No activitiy since last week
        </div>
      );
  };

  return (
    <div className="cursor-pointer w-60 bg-white p-2 rounded-sm shadow-sm mb-1" onClick={onClick}>
      <div className="flex justify-between items-start gap-1">
        <div className="text-sm font-semibold flex-1 min-w-0 whitespace-normal text-ellipsis overflow-hidden">
          {lead.closing_probability >= 0 ? <span className="text-xs mr-1 text-gray-600 font-normal">[{lead.closing_probability}%]</span> : null}
          {lead.name}
        </div>
        <img src={lead.user_avatar} alt="userlogo" className="rounded-full w-5 h-5" />
      </div>
      <div className="flex items-center text-gray-500 gap-1">
        <IoPeople />
        {lead.contact ? (
          <div className="text-sm"> {lead.contact}</div>
        ) : (
          <span className="text-sm px-2 bg-gray-50 text-gray-400 rounded-lg border-[1px] border-gray-100">Empty</span>
        )}
      </div>
      {lead.budget ? (
        <div className="flex items-center text-gray-500 gap-1">
          <RiMoneyEuroCircleFill />
          <div className="text-sm"> {lead.budget.toLocaleString()}</div>
        </div>
      ) : null}
      {lead.deadline ? (
        <div className="flex items-center text-gray-500 gap-1">
          <IoCalendarClear />
          <div className="text-sm">{moment(lead.deadline).format("DD MMMM YYYY")}</div>
        </div>
      ) : null}
      <div className="flex items-end justify-between">
        <div className="flex flex-wrap gap-1">
          {(lead.tags || []).map((tag) => (
            <div key={tag} className="text-sm px-2 rounded-lg bg-yellow-500 text-white">
              {tag}
            </div>
          ))}
        </div>
      </div>
      {renderWarning()}
    </div>
  );
};

export default Card;
