import React, { useEffect, useState } from "react";
import FileInput from "../../../../components/FileInput";
import TextEditor from "../../../../components/TextEditor";
import RichTextEditorSun from "../../../../components/RichTextEditor";
import Prompt from "../../../../components/Prompt";
import { applyFontClassToHtml } from "../utils";

import { getBase64file } from "../utils";

const PortfolioBlock = ({ values, editing, onChange }) => {
  const [imageB64Url, setImageB64Url] = useState("");

  useEffect(() => {
    if (values.image_url) {
      getBase64file(values.image_url).then((url) => {
        setImageB64Url(url);
      });
    }
  });

  if (editing) return <PortfolioForm values={values} onChange={onChange} />;

  return (
    <div className="flex flex-row gap-8 cursor-pointer py-5">
      <div className="md:w-1/2 w-full">
        <p className="font-poppins mb-3 font-semibold text-2xl">{values.title}</p>
        <p className="font-poppins mb-3 font-semibold text-lg">{values.project_name}</p>
        <div className="text-sm font-poppins rich-text" dangerouslySetInnerHTML={{ __html: applyFontClassToHtml(values.description) }}></div>
      </div>
      <div className="md:w-1/2 w-full">{values.image_url && <img src={imageB64Url} alt="image.png" className="w-full max-h-[500px] object-contain" />}</div>
    </div>
  );
};

const PortfolioForm = ({ values, onChange }) => {
  const [refresh, setRefresh] = useState(false);

  function handleChange(e) {
    setRefresh(true);
    onChange({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <div className="flex flex-col gap-2 p-2 bg-white border border-gray-200 rounded-md shadow-md my-6">
      <div className="text-[14px] text-[#212325] font-medium ">Title</div>
      <input className="input" type="text" name="title" value={values.title} onChange={handleChange} />
      <div className="text-[14px] text-[#212325] font-medium ">Project Name</div>
      <input className="input" type="text" name="project_name" value={values.project_name} onChange={handleChange} />
      <div className=" grid grid-cols-4 gap-2">
        <div className="col-span-3">
          <div className="flex flex-row gap-2">
            <div className="text-[14px] text-[#212325] font-medium mb-1">Description</div>
            <Prompt onChange={(e) => handleChange({ target: "description", value: e })} />
          </div>
          <TextEditor reload={refresh} value={values.description} name="description" onChange={handleChange} />
        </div>
        <div>
          <div className="text-[14px] text-[#212325] font-medium mb-1">Image</div>
          <FileInput folder="quotes" value={values.image_url} name="image_url" onChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

export const PortfolioTemplateCard = ({ template, onClick, selectedTemplate }) => {
  return (
    <div
      key={template._id}
      className={`p-4 border overflow-hidden relative ${selectedTemplate?._id === template._id ? "border-blue-500" : "border-gray-300"} rounded-md cursor-pointer`}
      onClick={onClick}>
      <div className={"flex justify-between items-start mb-2"}>
        <div className="text-md font-semibold">{template.title}</div>
      </div>

      <div className=" grid grid-cols-2 gap-1">
        <div className="text-gray-600 text-xs font-poppins" dangerouslySetInnerHTML={{ __html: template.description }}></div>
        <img src={template.image_url || "https://place-hold.it/500x300/AAA"} alt={template.title} className="mt-2 w-full max-h-[200px] object-contain" />
      </div>
    </div>
  );
};

export default PortfolioBlock;
