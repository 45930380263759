import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../services/api";

const Add = () => {
  const [values, setValues] = useState({ model: "" });

  const navigate = useNavigate();

  async function onCreate() {
    const { data } = await api.post("/device", values);
    toast.success("Created!");
    navigate(`/device/${data._id.toString()}/edit`);
  }

  return (
    <div className="max-w-2xl w-full h-auto bg-[white] p-[25px] rounded-md grid grid-cols-1">
      <select
        placeholder="model"
        value={values.model}
        onChange={(e) => {
          e.persist();
          setValues((p) => ({ ...p, model: e.target.value }));
        }}>
        <option label="Choose Model" value="" disabled>
          Choose Model
        </option>
        {[
          "MacBook Air (15-inch, M3, 2024)",
          "MacBook Air (13-inch, M3, 2024)",
          "MacBook Air (15-inch, M2, 2023)",
          "MacBook Pro (16-inch, 2023)",
          "MacBook Pro (14-inch, 2023)",
          "MacBook Air (13-inch, M2, 2022)",
          "MacBook Pro (13-inch, M2, 2022)",
          "MacBook Pro (16-inch, 2021)",
          "MacBook Pro (14-inch, 2021)",
          "MacBook Pro (13-inch, M1, 2020)",
          "MacBook Air (M1, 2020)",
          "MacBook Pro (13-inch, Four Thunderbolt, 2020)",
          "MacBook Pro (13-inch, Two Thunderbolt, 2020)",
          "MacBook Air (Retina, 13-inch, 2020)",
          "MacBook Pro (16-inch, 2019)",
          "MacBook Air (Retina, 13-inch, 2019)",
          "MacBook Pro (13-inch, Two Thunderbolt, 2019)",
          "MacBook Pro (13-inch, Four Thunderbolt, 2019)",
          "MacBook Pro (15-inch, 2019)",
        ].map((option, i) => (
          <option key={`option-type-${i}`} label={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <button className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px] disabled:cursor-not-allowed" onClick={onCreate}>
        Create
      </button>
    </div>
  );
};

export default Add;
