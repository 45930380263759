import React, { useState } from "react";

import { ROLES } from "./constants"


export default () => {

  return <div className="m-2">
    <span className="text-[14px] font-medium text-[#212325] bt-2 mb-3">
        Here is some benchmark based on :
        <br />
        <a href="https://datarecrutement.fr/etude-salaire/">- DataRecrutement</a>
        <br />
        <a href="https://shodo.io/shomulator">- Shodo</a>
        <br />
        <a href="https://www.linkedin.com/posts/pauline-egea-yeita-collectif-produit_chez-yeita-nous-naimons-pas-les-cases-et-activity-7051811173276504064-r_zj">- Yeita</a>
        <br />
        <a href="https://drive.google.com/file/d/1kDFWMl4qNBadIVomMlzS_cAmGYaYhS3C/view?_hsenc=p2ANqtz-8n0MLGbbA_Ssigtj43VpsVE6pkGxqg0LvR_yInyiBgLmJ17Bu8MelcWG_Kchk-6NgdJMSmzyJRoCAbG_Lc_IQB0jbMQA&_hsmi=252992940">- The Product Crew</a> 
        <br />
        <a href="https://marketing-pictures.s3.eu-west-1.amazonaws.com/Salary_Report_2023/talentio_r_FR.pdf">- Talent.io x Figures</a> 
        <br />
        Our grid is based on those numbers.
        <br />
        <br />
    </span>
    <table>
      <thead>
        <tr>
          <th>Job</th>
          <th>Experience</th>
          <th>Shodo</th>
          <th>Bench Le Stud</th>
          <th>Average</th>
        </tr>
      </thead>
      <tbody>
        {PRICING.map((e, i) => {
          return <tr key={i}>
            {e.map((el, j) => {
              return <td key={j}>{el}</td>
            })}
          </tr>
        })}
      </tbody>
    </table>
  </div>
}

const PRICING = []

PRICING.push([ROLES.FULLSTACK, 0, 493, 375, 430])
PRICING.push([ROLES.FULLSTACK, 1, 514, 425, 470])
PRICING.push([ROLES.FULLSTACK, 2, 534, 470, 500])
PRICING.push([ROLES.FULLSTACK, 3, 562,	515, 540])
PRICING.push([ROLES.FULLSTACK, 4, 590,	560, 575])
PRICING.push([ROLES.FULLSTACK, 5, 611, 600, 610])
PRICING.push([ROLES.FULLSTACK, 6, 639,	625, 640])
PRICING.push([ROLES.FULLSTACK, 7, 659,	650, 650])
PRICING.push([ROLES.FULLSTACK, 8, 664,	675, 675])
PRICING.push([ROLES.FULLSTACK, 9, 678,	700, 700])
PRICING.push([ROLES.FULLSTACK, 10, 700, 750, 750])

PRICING.push([ROLES.DEVOPS, 0, 493,	400,	475])
PRICING.push([ROLES.DEVOPS, 1, 514,	450,	500])
PRICING.push([ROLES.DEVOPS, 2, 534,	500,	525])
PRICING.push([ROLES.DEVOPS, 3, 562,	525,	550])
PRICING.push([ROLES.DEVOPS, 4, 590,	575,	575])
PRICING.push([ROLES.DEVOPS, 5, 611,	625,	600])
PRICING.push([ROLES.DEVOPS, 6, 639,	650,	650])
PRICING.push([ROLES.DEVOPS, 7, 659,	700,	675])
PRICING.push([ROLES.DEVOPS, 8, 664,	750,	700])
PRICING.push([ROLES.DEVOPS, 9, 678,	775,	775])
PRICING.push([ROLES.DEVOPS, 10, 700, 800,	800])

PRICING.push([ROLES.DATA, 0 , 493, 400,	450])
PRICING.push([ROLES.DATA, 1, 514,	440, 500])
PRICING.push([ROLES.DATA, 2, 534,	500,	525])
PRICING.push([ROLES.DATA, 3, 562,	540,	550])
PRICING.push([ROLES.DATA, 4, 590,	580, 575])
PRICING.push([ROLES.DATA, 5, 611,	600,	600])
PRICING.push([ROLES.DATA, 6, 639,	700,	650])
PRICING.push([ROLES.DATA, 7, 659,	725,	700])
PRICING.push([ROLES.DATA, 8, 664,	750,	715])
PRICING.push([ROLES.DATA, 9, 678,	800,	750])
PRICING.push([ROLES.DATA, 10, 700,	825,	800])

PRICING.push([ROLES.PRODUCT, 0 , 493, 450,	450])
PRICING.push([ROLES.PRODUCT, 1, 514,	500,	500])
PRICING.push([ROLES.PRODUCT, 2, 534,	525,	550])
PRICING.push([ROLES.PRODUCT, 3, 562,	550,	575])
PRICING.push([ROLES.PRODUCT, 4, 590,	575, 600])
PRICING.push([ROLES.PRODUCT, 5, 611,	600,	615])
PRICING.push([ROLES.PRODUCT, 6, 639,	625,	650])
PRICING.push([ROLES.PRODUCT, 7, 659,	650,	675])
PRICING.push([ROLES.PRODUCT, 8, 664,	700,	700])
PRICING.push([ROLES.PRODUCT, 9, 678,	750,	750])
PRICING.push([ROLES.PRODUCT, 10, 700,	800,	800])

const PRICING_START = []

PRICING_START.push([ROLES.FULLSTACK, 0, 400])
PRICING_START.push([ROLES.DEVOPS, 0, 430])
PRICING_START.push([ROLES.DATA, 0, 425])
PRICING_START.push([ROLES.PRODUCT, 0, 450])
PRICING_START.push([ROLES.SALES, 0, 350])
PRICING_START.push([ROLES.GROWTH, 0, 400])


export { PRICING, PRICING_START } 