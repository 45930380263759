import React from "react";
import moment from "moment";

const TableDaysOff = ({ filter, data, date }) => {
  console.log("✍️  data:", data);
  const month = moment(date).format("M");
  const year = moment(date).format("YYYY");
  const filteredData = data?.filter((item) => {
    const isDisplayed = filter.users.includes(item.userId) || filter.users.length === 0;
    return isDisplayed;
  });
  let index = 0;

  return (
    <div className="bg-[#FFFFFF] border border-[#E5EAEF] rounded-[10px] my-3">
      <div className="overflow-x-auto">
        <table className="w-[700px] md:w-full table-fixed">
          <thead>
            <tr className="border-b border-[#E5EAEF] text-[14px] font-semibold text-[#212325]">
              <th className="p-3">User</th>
              <th>Contract</th>
              <th>Project</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Days</th>
              <th>Hours</th>
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((item) =>
              item.daysOff.length > 0
                ? item.daysOff?.map((_, i) => (
                    <tr key={index++} className="border-b border-[#E5EAEF] text-[14px] text-[#676D7C] font-medium">
                      <td className="pl-3">
                        <div className="flex items-center gap-3">
                          {item.userAvatar && <img className="h-7 rounded-full border border-[#C9C8C9]" src={item.userAvatar} alt="image.png" />}
                          <span className="py-2.5">{item.userName}</span>
                        </div>
                      </td>
                      <td>{item.userContract}</td>
                      <td>{item.projectName}</td>
                      <td>{`${item.daysOff[i][0].day}/${month}/${year}`}</td>
                      <td>{`${item.daysOff[i][item.daysOff[i].length - 1].day}/${month}/${year}`}</td>
                      <td>{item.daysOff[i].length}</td>
                      <td>{item.daysOff[i].reduce((acc, a) => acc + a.hours, 0).toFixed(2)}</td>
                    </tr>
                  ))
                : [],
            )}
            <tr key="total" className="text-[14px] font-semibold text-[#212325]">
              <td className="p-3">Total-</td>
              <td />
              <td />
              <td />
              <td />
              <td>{filteredData?.reduce((acc, a) => acc + a.daysOff.reduce((acc, a) => acc + a.length, 0), 0).toFixed(0)}</td>
              <td>{filteredData?.reduce((acc, a) => acc + a.daysOff.reduce((acc, a) => acc + a.reduce((acc, a) => acc + a.hours, 0), 0), 0).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableDaysOff;
