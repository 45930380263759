import Modal from "../../../../../components/modal";
import { useState } from "react";
import { useEffect } from "react";

import api from "../../../../../services/api";

//modal to give feedback on the AI suggestions
export default function FeedbackModal({ isOpen, onClose, epic, setEpic }) {
  const [feedback, setFeedback] = useState(epic.feedback || "");
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    try {
      setLoading(true);
      const feedBackContent = "INPUT :" + epic.title + "\n" + "OUTPUT :" + JSON.stringify(epic.suggestions) + "\n" + "FEEDBACK :" + feedback;
      if (epic.agent_feature_id) {
        const { data, ok } = await api.put(`/agent_feature/${epic.agent_feature_id}`, {
          description: feedBackContent,
        });
        if (!ok) throw new Error(data.error);
        const { data: newEpic, ok: okEpic } = await api.put(`/epic/${epic._id}`, { feedback });
        if (okEpic) setEpic(newEpic);
      } else {
        const { data, ok } = await api.post(`/agent_feature`, {
          title: "Epic Feedback",
          description: feedBackContent,
          tags: ["feedbacks"],
          agent_id: "670d58ceeddea1e0bc7d200c",
          agent_name: "Gerard",
        });
        if (!ok) throw new Error(data.error);
        const { data: newEpic, ok: okEpic } = await api.put(`/epic/${epic._id}`, { feedback, agent_feature_id: data._id });
        if (okEpic) setEpic(newEpic);
      }
      onClose();
    } catch (e) {
      console.error("Error saving feedback:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-4">
        <h1 className="text-lg font-semibold">Feedback</h1>
        <textarea className="w-full h-32 p-2 border border-gray-200 rounded-md mt-2" value={feedback} onChange={(e) => setFeedback(e.target.value)} />
        <div className="flex justify-center mt-4">
          <button className="blue-btn" onClick={handleSave}>
            {loading ? "Saving..." : "Save feedback"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
