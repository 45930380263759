import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import api from "../../../services/api";

const View = ({ job, setJob }) => {
  const [values, setValues] = useState(job);
  if (!job) return <Loader />;

  async function onUpdate(e) {
    e.preventDefault();
    const { data } = await api.put(`/job/${job._id}`, values);
    setJob(data);
    toast.success("successfully updated!");
  }

  return (
    <div>
      <div className="">
        <div className="text-[14px] text-[#212325] font-medium mb-1">Notes</div>
        <textarea
          rows="20"
          className="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Write your thoughts here..."
          value={values.note}
          onChange={(e) => setValues({ ...values, note: e.target.value })}
        />
      </div>
      <div className="flex items-center my-2">
        <button className="ml-[10px] bg-[#0d6efd] hover:bg-[#0b5ed7] text-[1rem] text-[#fff] py-[0.375rem] px-[0.75rem] rounded-[0.25rem]" onClick={onUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};

export default View;
