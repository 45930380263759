import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Table from "../../../../components/Table";
import EpicEditModal from "./EpicModal";
import EpicCreationModal from "./EpicCreationModal";
import api from "../../../../services/api";
import DebounceInput from "../../../../components/DebounceInput";
import MultiSelect from "../../../../components/MultiSelect";
import { useSelector } from "react-redux";

export default ({ project }) => {
  const [epics, setEpics] = useState([]);
  const [filters, setFilters] = useState({ page: 1, search: "" });
  const [selectedEpics, setSelectedEpics] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [isEpicEditModalOpen, setIsEpicEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    fetchEpics();
  }, [filters]);

  const fetchEpics = async () => {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/epic/search", { project_id: project._id, ...filters });
      if (!ok) return toast.error("Failed to fetch roadmaps");
      setEpics(addPointsAndSort(data));
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch roadmaps");
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete selected epics?")) return;

      for (const epicId of selectedEpics) {
        console.log(epicId);
        const { ok } = await api.remove(`/epic/${epicId}`);
        if (!ok) {
          toast.error(`Failed to delete epic ${epicId}`);
          continue;
        }
      }
      toast.success("Epics deleted successfully");
      fetchEpics();
      setSelectedEpics([]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete epics");
    }
  };

  const handleSave = async (itemId, field, value) => {
    try {
      const { ok, data } = await api.put(`/epic/${itemId}`, { [field]: value });
      if (!ok) return toast.error("Failed to update");

      const index = epics.findIndex((item) => item._id === itemId);
      const newEpics = [...epics];
      newEpics[index] = { ...newEpics[index], [field]: value };
      setEpics(newEpics);
      toast.success(`${field} updated successfully`);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update");
    }
  };

  function addPointsAndSort(arr1) {
    const arr = [...arr1];
    for (let i = 0; i < arr.length; i++) {
      //= (IF(B2="XL",1,0) + IF(B2="L",2,0) + IF(B2="M",3,0) + IF(B2="S",4,0)  + IF(B2="XS",5,0))*(IF(C2="XL",5,0) + IF(C2="L",4,0) + IF(C2="M",3,0) + IF(C2="S",2,0)  + IF(C2="XS",1,0))  + (IF(D2="YES",100,0))

      let chargePoints = 0;
      if (arr[i].charge === "XL") chargePoints += 1;
      if (arr[i].charge === "L") chargePoints += 2;
      if (arr[i].charge === "M") chargePoints += 3;
      if (arr[i].charge === "S") chargePoints += 4;
      if (arr[i].charge === "XS") chargePoints += 5;

      let businessPoints = 0;
      if (arr[i].business_contribution === "XL") businessPoints += 5;
      if (arr[i].business_contribution === "L") businessPoints += 4;
      if (arr[i].business_contribution === "M") businessPoints += 3;
      if (arr[i].business_contribution === "S") businessPoints += 2;
      if (arr[i].business_contribution === "XS") businessPoints += 1;

      let basePoints = chargePoints * businessPoints;
      let urgentPoints = arr[i].urgent ? 100 : 0;
      arr[i].points = basePoints + urgentPoints;

      let days_required = 0;
      if (arr[i].charge === "XL") days_required += 20;
      if (arr[i].charge === "L") days_required += 15;
      if (arr[i].charge === "M") days_required += 10;
      if (arr[i].charge === "S") days_required += 5;
      if (arr[i].charge === "XS") days_required += 1;
      arr[i].days_required = days_required;
    }

    arr.sort((a, b) => b.points - a.points);
    return arr;
  }

  return (
    <div>
      <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
        <div className="flex items-start gap-4">
          <div className="w-full flex justify-between">
            <div className="w-full grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
              <DebounceInput
                debounce={300}
                className="input w-full"
                placeholder="Search by title..."
                value={filters.search}
                onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
              />

              <MultiSelect
                id="select-status"
                options={["TODO", "IN_PROGRESS", "DONE", "NEED_INFO"].map((status) => ({ value: status, label: status }))}
                value={filters.statuses?.map((status) => ({ value: status, label: status })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, statuses: e.map((e) => e.value) }))}
                placeholder="Status"
              />

              <MultiSelect
                id="select-urgent"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                value={filters.urgent ? [{ value: filters.urgent, label: filters.urgent }] : []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, urgent: e.map((e) => e.value) }))}
                placeholder="Urgence"
              />

              <SelectTags value={filters.tags} onChange={(e) => setFilters((f) => ({ ...f, tags: e }))} project={project} />
            </div>
          </div>
          <div>
            <EpicCreationModal
              project={project}
              onCreate={(e) => {
                fetchEpics();
                setSelectedEpic(e);
                setIsEpicEditModalOpen(true);
              }}
            />
          </div>
          {selectedEpics.length > 0 && (
            <button onClick={handleMultipleDelete} className="red-btn">
              Delete Selected Epics
            </button>
          )}
        </div>
      </div>
      {isEpicEditModalOpen && (
        <EpicEditModal
          epic={selectedEpic}
          onClose={(e) => {
            if (!selectedEpic) return;
            const index = epics.findIndex((item) => item._id === selectedEpic._id);
            const newEpics = [...epics];
            newEpics[index] = { ...newEpics[index], ...e };
            setEpics(newEpics);
            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
          }}
        />
      )}
      <Table
        sort={{ points: -1 }}
        total={epics.length}
        onSort={(e) => {
          if (e === "points") {
            setEpics(addPointsAndSort([...epics]));
            toast.success("Sorted by points");
          }
          if (e === "tags") {
            setEpics([...epics].sort((a, b) => a.tags.join("").localeCompare(b.tags.join(""))));
            toast.success("Sorted by tags");
          }
        }}
        header={[
          {
            title: (
              <input
                type="checkbox"
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedEpics(epics.map((epic) => epic._id));
                  } else {
                    setSelectedEpics([]);
                  }
                }}
              />
            ),
            position: "center",
          },
          { title: "#", key: "index" },
          { title: "Personae", key: "tags" },
          { title: "Module", key: "tags" },
          { title: "Title", key: "title" },
          { title: "Business objective", key: "business_objective" },
          { title: "Charge", key: "charge" },
          { title: "Apport Business", key: "business_contribution" },
          { title: "Urgent", key: "urgent" },
          { title: "Points", key: "points" },
          { title: "Status", key: "status" },
        ]}
        loading={loading}
        height="h-[32rem]"
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">No results found</div>}>
        <AnimatePresence>
          {epics.map((item, index) => {
            return (
              <motion.tr
                key={item._id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}>
                <td
                  className="border px-4 py-2 cursor-pointer"
                  onClick={(e) => {
                    if (selectedEpics.includes(item._id)) {
                      setSelectedEpics(selectedEpics.filter((id) => id !== item._id));
                    } else {
                      setSelectedEpics([...selectedEpics, item._id]);
                    }
                  }}>
                  <input
                    type="checkbox"
                    className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={selectedEpics.includes(item._id)}
                    onChange={() => {}}
                  />
                </td>
                <td
                  onClick={() => {
                    setSelectedEpic(item);
                    setIsEpicEditModalOpen(true);
                  }}
                  className="border px-4 py-2 max-w-xs truncate">
                  {item.index}
                </td>
                <td
                  onClick={() => {
                    setSelectedEpic(item);
                    setIsEpicEditModalOpen(true);
                  }}
                  className="border px-4 py-2 max-w-xs truncate">
                  {item.persona}
                </td>
                <td
                  onClick={() => {
                    setSelectedEpic(item);
                    setIsEpicEditModalOpen(true);
                  }}
                  className="border px-4 py-2 max-w-xs truncate">
                  {item.module}
                </td>
                <td
                  onClick={() => {
                    setSelectedEpic(item);
                    setIsEpicEditModalOpen(true);
                  }}
                  className="border px-4 py-2 text-sm hover:bg-gray-200">
                  {item.title}
                </td>
                <td
                  onClick={() => {
                    setSelectedEpic(item);
                    setIsEpicEditModalOpen(true);
                  }}
                  className="border px-4 py-2 max-w-xs hover:bg-gray-200 text-sm">
                  {item.business_objective}
                </td>
                <EditableSelectCell itemId={item._id} field="charge" value={item.charge} options={["XS", "S", "M", "L", "XL"]} onSave={handleSave} />
                <EditableSelectCell itemId={item._id} field="business_contribution" value={item.business_contribution} options={["XS", "S", "M", "L", "XL"]} onSave={handleSave} />
                <EditableSelectCell itemId={item._id} field="urgent" value={item.urgent ? "yes" : "no"} options={["yes", "no"]} onSave={handleSave}>
                  <UrgentTag item={item} />
                </EditableSelectCell>
                <td className="border px-4 py-2 max-w-xs truncate">{item.points}</td>
                <EditableSelectCell itemId={item._id} field="status" value={item.status} options={["TODO", "IN_PROGRESS", "NEED_INFO", "DONE"]} onSave={handleSave}>
                  <StatusTag item={item} />
                </EditableSelectCell>
              </motion.tr>
            );
          })}
        </AnimatePresence>
      </Table>
    </div>
  );
};

const EditableSelectCell = ({ itemId, field, value, options, onSave, children }) => {
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const cellRef = useRef(null);

  const handleSave = async (e) => {
    const newValue = e.target.value;
    setCurrentValue(newValue);
    setEditing(false);
    await onSave(itemId, field, newValue);
  };

  const handleClickOutside = (event) => {
    if (cellRef.current && !cellRef.current.contains(event.target)) {
      setEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <td ref={cellRef} onClick={() => setEditing(true)} onBlur={() => setEditing(false)} className="border px-4 py-2 max-w-xs truncate text-sm hover:bg-gray-200">
      {editing ? (
        <select className="input p-1" value={currentValue} onChange={handleSave}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        children || currentValue
      )}
    </td>
  );
};

const StatusTag = ({ item }) => {
  if (!item?.status) return null;
  const colors = {
    TODO: "bg-blue-600 text-xs text-white border-gray-700",
    IN_PROGRESS: "bg-blue-100 text-xs text-blue-800",
    DONE: "bg-green-100 text-xs text-green-800",
  };
  return <div className={`px-2 py-1 rounded w-fit ${colors[item?.status]}`}>{item?.status}</div>;
};

const UrgentTag = ({ item }) => {
  const colors = {
    true: "bg-red-600 text-xs text-white border border-red-700",
    false: "bg-green-100 text-xs text-green-800 border border-green-200",
  };
  return <div className={`px-2 py-1 rounded w-fit ${colors[item?.urgent]}`}>{item?.urgent ? "Yes" : "No"}</div>;
};

const SelectTags = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchTags() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    // Initial fetch when component mounts
    fetchTags();
  }, [project._id]);

  // Refetch when component gains focus if more than 5 seconds have passed
  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchTags();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-status"
      options={options}
      value={value?.map((tag) => ({ value: tag, label: tag }))}
      onSelectedChange={(e) => onChange(e.map((e) => e.value))}
      placeholder="Tags"
    />
  );
};
