import React from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import User from "./user";
import Flux from "./flux";
import Transaction from "./transaction";
import { classNames } from "../../utils";

import { IoPeople, IoPerson } from "react-icons/io5";

const Index = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!tab) navigate(`/wallet/user`);
  }, [tab]);

  return (
    <div className="p-3 flex flex-col w-full h-full">
      <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden">
        <TabItem title="User" tab="user" onClick={() => navigate(`/wallet/user`)} active={tab === "user"} Icon={IoPerson} />
        <TabItem title="Flux" tab="flux" onClick={() => navigate(`/wallet/flux`)} active={tab === "flux"} Icon={IoPeople} />
        <TabItem title="Transaction" tab="transaction" onClick={() => navigate(`/wallet/transaction`)} active={tab === "transaction"} Icon={IoPeople} />
      </nav>
      <div className="my-2" />
      <Routes>
        <Route path="/user" element={<User />} />
        <Route path="/flux" element={<Flux />} />
        <Route path="/transaction" element={<Transaction />} />
      </Routes>
    </div>
  );
};

const TabItem = ({ title, onClick, active, Icon, Badge }) => (
  <button
    onClick={onClick}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-primary  border-primary" : "",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);

export default Index;
