import React, { useEffect, useState } from "react";
import Send from "./send";
import Sidebar from "./sidebar";
import DebounceInput from "../../../components/DebounceInput";
import Pagination from "../../../components/Paginator";
import API from "../../../services/api";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import Loader from "../../../components/loader";

export default ({ project, setProject }) => {
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [emails, setEmails] = useState([]);
  const [total, setTotal] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({
    search: "",
    project_id: project._id,
    page: 1,
    per_page: 10,
    total,
  });

  async function fetchEmails() {
    try {
      const { ok, data } = await API.post(`/report_email/search`, filters);
      if (!ok) return toast.error("Error fetching report_emails");
      const emailPromises = data.report_emails.map((email) => API.get(`/report_email/${email._id}`).then((response) => response.data));
      const emailsData = await Promise.all(emailPromises);

      //sort
      const sortedEmails = emailsData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        return dateB - dateA;
      });

      setEmails(sortedEmails);
      setSelectedEmail(sortedEmails[0]);
      setTotal(data.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching emails");
    }
  }

  useEffect(() => {
    fetchEmails();
  }, [filters]);

  const countEvents = (email, name) => {
    const nb = email?.events?.filter((event) => event.name === name)?.length;
    const percent = ((nb / email?.emailTo?.length) * 100).toFixed(0);
    return (
      <div className="flex flex-col text-center">
        <p>{nb}</p>
        <p>{percent} %</p>
      </div>
    );
  };

  return (
    <div className="bg-white p-4">
      <Send project={project} refetch={fetchEmails} />

      <div className="flex flex-col space-y-3 p-3">
        <div className="flex flex-row">
          <table className="mt-6 mb-2 w-2/5 table-auto font-marianne">
            <thead>
              <tr className="border-t border-t-slate-100 uppercase">
                <th className="w-2/3 px-2 py-3 text-xs font-normal text-gray-500 ">Subject</th>
                <th className="w-1/6 px-2 py-3 text-xs font-normal text-gray-500 text-center">Opened</th>
                <th className="w-1/6 px-2 py-3 text-xs font-normal text-gray-500 text-center">Clicked</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <Loader />
              ) : (
                emails?.map((email, index) => (
                  <tr
                    key={index}
                    className={`${selectedEmail === email ? "bg-gray-200 rounded-lg" : ""} border-t hover:cursor-pointer hover:bg-gray-100`}
                    onClick={() => setSelectedEmail(email)}>
                    <td className="px-2 py-2">
                      <p className="max-w-2xl truncate text-sm font-semibold">{email?.subject}</p>
                      <p className="max-w-2xl truncate text-xs font-normal text-gray-500">
                        {dayjs(email?.date).format("YYYY-MM-DD")}, {dayjs(email?.date).format("HH:mm")}
                      </p>
                    </td>
                    <td className="truncate px-2 py-2 text-xs">{countEvents(email, "open")}</td>
                    <td className="truncate px-2 py-2 text-xs">{countEvents(email, "click")}</td>
                    <td className="truncate px-2 py-2 text-xs">{email?.email_id?.slice(14, 25) || ""}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {selectedEmail && (
            <div className=" flex-col text-left w-3/5 h-screen mt-6 mb-2 flex items-center  px-4 ">
              <div className=" text-left  w-full px-2 mb-2">
                <span className="font-bold text-sm ">Recipients :</span>{" "}
                {selectedEmail?.emailTo.map((email, i) => {
                  if (i < selectedEmail?.emailTo.length - 1) return <span key={i}>{email}, </span>;
                  else return <span key={i}>{email}</span>;
                })}
              </div>
              <iframe srcDoc={selectedEmail?.body} className="w-full h-full border-t" />
            </div>
          )}
        </div>

        <div className="flex justify-center mx-[25%]">
          <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.per_page)} />
        </div>
      </div>
      {/* {setSelectedEmail && <Sidebar project={project} email={selectedEmail} isOpen={selectedEmail} onClose={() => setSelectedEmail(null)} />} */}
    </div>
  );
};
