import React, { useEffect, useState } from "react";

import api from "../../../services/api";

export default () => {
  return (
    <div className="flex items-center space-x-4">
      <MyContribution />
      <TopContributionALL />
      <TopContribution />
    </div>
  );
};

const TopContributionALL = ({}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data } = await api.post("/workshop_user/top_contributors", { period: "alltime" });
    setData(data);
  }

  return (
    <div className="bg-blue-100 rounded-lg shadow-md p-4">
      <div className="text-xl font-semibold mb-2">Top Contributors all time</div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left text-gray-600">User</th>
            <th className="text-left text-gray-600">Score</th>
            <th className="text-left text-gray-600">Workshops</th>
          </tr>
        </thead>
        <tbody>
          {data.map((e, index) => (
            <tr key={e._id}>
              <td className="text-left">
                <div className="flex items-center">
                  <div className="ml-2">{`${index} - `}</div>
                  <img src={e.user_avatar} className="w-8 h-8 rounded-full" alt={e.user_name} />
                  <div className="ml-2">{e.user_name}</div>
                </div>
              </td>
              <td className="text-left">{e.sumFeedbackRating}</td>
              <td className="text-left">{e.countWorkshops}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TopContribution = ({}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data } = await api.post("/workshop_user/top_contributors", { period: "month" });
    setData(data);
  }

  return (
    <div className="bg-blue-100 rounded-lg shadow-md p-4">
      <div className="text-xl font-semibold mb-2">Top Contributors Monthly </div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left text-gray-600">User</th>
            <th className="text-left text-gray-600">Score</th>
            <th className="text-left text-gray-600">Workshops</th>
          </tr>
        </thead>
        <tbody>
          {data.map((e, index) => (
            <tr key={e._id}>
              <td className="text-left">
                <div className="flex items-center">
                  <div className="ml-2">{`${index} - `}</div>
                  <img src={e.user_avatar} className="w-8 h-8 rounded-full" alt={e.user_name} />
                  <div className="ml-2">{e.user_name}</div>
                </div>
              </td>
              <td className="text-left">{e.sumFeedbackRating}</td>
              <td className="text-left">{e.countWorkshops}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const MyContribution = ({}) => {
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data } = await api.post("/workshop_user/contribution");
    setData(data);
  }

  return (
    <div className="bg-blue-100 rounded-lg shadow-md p-4">
      <div className="text-xl font-semibold mb-2">My Contribution</div>
      <div className="grid grid-cols-2 gap-4">
        <div className="text-gray-600">
          <div className="text-sm">Score</div>
          <div className="text-2xl text-blue-600">{data.score}</div>
        </div>
        <div className="text-gray-600">
          <div className="text-sm">Total Workshops</div>
          <div className="text-2xl text-blue-600">{data.workshops}</div>
        </div>
        <div className="text-gray-600">
          <div className="text-sm">Total User Workshops done</div>
          <div className="text-2xl text-blue-600">{data.user_workshops}</div>
        </div>
      </div>
    </div>
  );
};
