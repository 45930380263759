import React, { useContext } from "react";
import { AppContext } from "./context";
import { FaShare } from "react-icons/fa";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Tooltip from "react-tooltip";

import Rating from "../rating";

import api from "../../../services/api";
import { copyToClipboard } from "../../../utils";

export default ({}) => {
  const context = useContext(AppContext);

  if (!context.workshop) return <div />;
  if (context.index !== 0) return <div />;

  function renderAction() {
    if (context.userWorkshop) {
      const userWorkshop = context.userWorkshop;
      return (
        <button
          className="btn btn-primary mt-3"
          onClick={async () => {
            // const index = userWorkshop.step_id === 0 ? 1 : userWorkshop.step_id;
            // context.setIndex(index);
            context.setIndex(1);
          }}>
          Continue
        </button>
      );
    }
    return (
      <button
        className="btn btn-primary mt-3"
        onClick={async () => {
          toast.success("Workshop started");
          const { data } = await api.post(`/workshop_user`, {
            workshop_id: context.workshop._id,
            workshop_name: context.workshop.name,
            workshop_version: context.workshop.version,
          });
          context.setUserWorkshop(data);
          context.setIndex(1);
        }}>
        Start
      </button>
    );
  }

  return (
    <div>
      <div className="flex md:items-center md:justify-between md:flex-row flex-col-reverse">
        <div>
          <div className="text-base text-gray-400	font-light uppercase">{context.workshop.category}</div>
          <h1 className="text-3xl mb-2 font-bold">{context.workshop.name}</h1>
          <div className="space-x-2 flex items-center">
            {context.workshop.version && <span className="text-sm text-dull-grey my-2">version: {context.workshop.version}</span>}
            {context.workshop.read_time && <span className="text-sm text-dull-grey">({context.workshop.read_time} read)</span>}
            {context.workshop.public && (
              <div
                className="p-2 hover:bg-lightShade-grey cursor-pointer text-dull-grey text-sm flex items-center space-x-1.5"
                onClick={() => {
                  copyToClipboard("https://accounting.selego.co/" + "learn_public/" + context.workshop._id);
                  toast.success("URL copied !");
                }}>
                <FaShare /> <span>Share public</span>
              </div>
            )}
          </div>

          <div className="my-3">{context.workshop.description}</div>
          <div className="flex gap-4 text-sm text-gray-700">
            <div>Last update</div>
            <div>{new Date(context.workshop.updated_at).toLocaleDateString()}</div>
          </div>
          <div className="flex items-center space-x-2 text-sm text-light-grey my-2">
            <span>Rating:</span>
            {!!context.workshop.rating_score && (
              <>
                <Rating value={context.workshop.rating_score} />{" "}
                <Link to={`/learn-rating?workshop_id=${context.workshop._id.toString()}`} className="hover:underline">
                  <strong>{`${context.workshop.rating_score} (${context.workshop.rating_count})`}</strong>
                </Link>
              </>
            )}
          </div>
          {renderAction()}
        </div>
        <div className="pl-2 mb-4">
          <img src={context.workshop.image} className="object-cover rounded-md max-w-xs	" />
        </div>
      </div>
      <div className="flex gap-4 mt-4 justify-between md:flex-row flex-col">
        <div className="flex-1">
          <Menu />
        </div>
        <div className="flex-1">
          <Contributors />
        </div>
      </div>
    </div>
  );
};

const Menu = () => {
  const context = useContext(AppContext);
  const steps = context.workshop.steps || [];
  console.log("[DEV]  context.workshop", context.workshop);
  console.log("[DEV]  steps", steps);
  return (
    <div>
      <div className="text-xl mb-2 font-bold">Workshop steps</div>
      <div className="	">
        {steps.map((step, index) => (
          <div
            key={index}
            className="cursor-pointer bg-white rounded-lg border border-gray-200 hover:border-gray-300 flex gap-4 p-2 mb-2"
            onClick={() => {
              if (context.userWorkshop) context.setIndex(index + 1);
            }}>
            {`${index}. ${step.title}`}
          </div>
        ))}
      </div>
    </div>
  );
};

const Contributors = ({}) => {
  const context = useContext(AppContext);
  const contributors = context.workshop.contributors || [];
  if (!contributors.length) return <div />;

  const map = {};
  const uniqueContributors = [];
  contributors.forEach((contributor) => {
    if (!map[contributor.user_id]) {
      map[contributor.user_id] = true;
      uniqueContributors.push(contributor);
    }
  });

  return (
    <div className="p-2">
      <div className="text-xl my-2 font-bold">Contributors ({uniqueContributors.length})</div>
      <div className="flex -space-x-0.5 flex-wrap">
        {uniqueContributors.map((contributor) => {
          return (
            <>
              <img
                key={contributor._id}
                className="h-10 w-10 rounded-full bg-gray-50 ring-2 ring-white"
                src={contributor.user_avatar}
                alt={contributor.user_name}
                data-tip
                data-for={`contributor-${contributor._id}`}
              />
              <Tooltip
                border
                borderColor="#e6e6e6"
                id={`contributor-${contributor._id}`}
                effect="solid"
                className="max-w-xs text-sm keepOpacity"
                backgroundColor="#fff"
                textColor="#000">
                <div className="text-sm">{contributor.user_name}</div>
                <div className="text-xs text-gray-500">{new Date(contributor.created_at).toLocaleDateString()}</div>{" "}
              </Tooltip>
            </>
          );
        })}
      </div>
    </div>
  );
};
