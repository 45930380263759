import React from "react";
import MultiSelect from "../../../components/MultiSelect";

export const COUNTRIES = [
  { value: "France", label: "France" },
  { value: "Netherland", label: "Netherland" },
  { value: "Other", label: "Other" },
];

export default ({ values = [], onChange }) => {
  return <MultiSelect values={values} id="select-countries" options={COUNTRIES} onSelectedChange={onChange} placeholder="Select a country" />;
};
