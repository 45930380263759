import React, { useEffect, useState } from "react";
import DebounceInput from "react-debounce-input";

import Loader from "../../components/loader";
import api from "../../services/api";
import SelectMonth from "../../components/selectMonth";

const Occupation = () => {
  const [filter, setFilter] = useState({ search: "", per_page: 200, applicants: false, occupation: true });

  return (
    <div className="pt-1 px-2 md:px-8">
      <div className="flex flex-col-reverse md:flex-row justify-between items-center">
        <div className="flex gap-2 flex-wrap flex-col">
          <div className="flex items-center space-x-3">
            <div className="relative text-[#A0A6B1]">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button type="submit" className="p-1">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-5 h-5">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </span>
              <DebounceInput
                debounceTimeout={300}
                className="py-2 w-[364px] text-[14px] font-normal text-[black] rounded-[10px] bg-[#F9FBFD] border border-[#FFFFFF] pl-10"
                placeholder="Search (name, email, skills, etc...)"
                value={filter.search}
                onChange={(e) => {
                  e.persist();
                  setFilter((prev) => ({ ...prev, search: e.target.value }));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Users filter={filter} />
    </div>
  );
};

const Users = ({ filter }) => {
  const [users, setUsers] = useState(null);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (filter.applicants) setUsers(null);
    getUsers();
  }, [filter]);

  console.log(filter);
  async function getUsers() {
    const { data } = await api.post("/user/search", filter);
    setUsers(data.users);
  }

  if (!users) return <Loader />;
  return (
    <>
      <div>
        <span className="text-sm font-normal text-gray-500">
          <>
            <span className="text-base font-medium text-gray-700">{users?.length}</span> of {users?.length}
          </>
        </span>
      </div>
      <div className="overflow-x-auto">
        <SelectMonth start={-3} indexDefaultValue={3} value={date} onChange={(e) => setDate(e.target.value)} showArrows />

        <div className="flex flex-col">
          {users?.map((user) => (
            <UserOccupation user={user} date={new Date(date)} key={user.id} />
          ))}
        </div>
      </div>
    </>
  );
};

const UserOccupation = ({ user, date }) => {
  const [occupation, setOccupation] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/activity/occupation?date=${date.getTime()}&userId=${user._id}`);
      setOccupation(data);
    })();
  }, [date, user]);

  if (!occupation || occupation.length === 0) return <div></div>;
  return (
    <div className="w-full p-2">
      <div className="text-base font-semibold mb-1">{user.name}</div>
      <div className="bg-gray-200 rounded-full h-4">
        <div className="bg-blue-500 rounded-full h-4" style={{ width: `${occupation}%` }}>
          <div className="flex justify-end items-center pr-1 h-full">
            <span className="text-white text-xs font-semibold">{occupation}%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Occupation;
