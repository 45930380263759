import React from "react";
import MultiSelect from "../../../components/MultiSelect";

export const SUBCATEGORIES = [
  { value: "BANK", label: "BANK" },
  { value: "TRAVEL", label: "TRAVEL" },
  { value: "HOSTING", label: "HOSTING" },
  { value: "OFFICE", label: "OFFICE" },
  { value: "ADS", label: "ADS" },
  { value: "ACCOUNTING", label: "ACCOUNTING" },
  { value: "TOOLS", label: "TOOLS" },
  { value: "FOODANDBEVERAGE", label: "FOODANDBEVERAGE" },
  { value: "LEGAL", label: "LEGAL" },
  { value: "OTHER", label: "OTHER" },

  { value: "SALARY_FREELANCE", label: "SALARY_FREELANCE" },
  { value: "SALARY_EMPLOYEE", label: "SALARY_EMPLOYEE" },
  { value: "TAX_SALARY", label: "TAX_SALARY" },
  { value: "BUSINESS_INTRODUCER_COST", label: "BUSINESS_INTRODUCER_COST" },

  { value: "INVOICE", label: "INVOICE" },
  { value: "VAT_INCOME", label: "VAT_INCOME" },
  { value: "BUSINESS_INTRODUCER_INCOME", label: "BUSINESS_INTRODUCER_INCOME" },
  { value: "SUBVENTION", label: "SUBVENTION" },

  { value: "CORPORATION_TAX", label: "CORPORATION_TAX" },
  { value: "VAT_DISBURSEMENT", label: "VAT_DISBURSEMENT" },
  { value: "TRANSFER", label: "TRANSFER" },
  { value: "INVEST", label: "INVEST" },
  { value: "DIVIDEND", label: "DIVIDEND" },
  { value: "IT_EQUIPMENT", label: "IT_EQUIPMENT" },

  { value: "REFUND", label: "REFUND" },
];

export default ({ values = [], onChange }) => {
  return <MultiSelect values={values} id="select-subcategories" options={SUBCATEGORIES} onSelectedChange={onChange} placeholder="Select a subcategory" />;
};
