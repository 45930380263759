import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";

import api from "../../services/api";

import Loader from "../../components/loader";
import Table from "../../components/Table";
import Paginator from "../../components/Paginator";
import SelectUser from "../../components/selectUser";

export default () => {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    sort: "-created_at",
    status: "active",
    contracts: ["CDD", "CDI", "INTERNSHIP", "APPRENTICESHIP"],
    page: 1,
    per_page: 1000,
  });

  useEffect(() => {
    get();
  }, [filters]);

  async function get() {
    try {
      setLoading(true);
      const { data } = await api.post(`/user/search`, filters);
      setUsers(data.users);
      setTotal(data.total);
    } catch (e) {
      console.log("a", e);
    } finally {
      setLoading(false);
    }
  }

  if (!users) return <Loader />;

  const HEADER_COLS = [{ title: "User" }, { title: "Manager" }, { title: "progression" }];

  return (
    <div className="mx-auto">
      <div className="grid grid-cols-2 gap-2 py-2">
        <DebounceInput
          debounce={300}
          className="input"
          placeholder="Search (name, email, skills, etc...)"
          value={filters.search}
          onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
        />
        <SelectUser
          label="user"
          userName={filters.manager_name}
          value={{ user_name: filters.manager_name, user_id: filters.manager_id }}
          onChange={(e) => {
            setFilters({ ...filters, manager_id: e._id, manager_name: e.name });
          }}
          placeholder="All managers"
        />
      </div>

      <Table
        header={HEADER_COLS}
        total={total}
        loading={loading}
        height=""
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">No result</div>}>
        {users?.map((user) => (
          <UserLine user={user} key={user._id} />
        ))}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.per_page)} />
      </div>
    </div>
  );
};

const UserLine = ({ user }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();

  const fetchQuestions = async () => {
    const { data } = await api.post("/skill_question/search");
    setQuestions(data);
  };

  const fetchAnswers = async () => {
    const { data } = await api.post("/skill_answer/search", { userId: user._id });
    const answeredQuestions = data.filter((a) => questions.find((q) => q._id === a.questionId));
    setAnswers(answeredQuestions);
  };

  useEffect(() => {
    fetchQuestions();
  }, [user]);

  useEffect(() => {
    if (!questions.length) return;
    fetchAnswers();
  }, [questions]);

  return (
    <tr
      className={`hover:bg-gray-50 cursor-pointer border-b bg-white`}
      onClick={() => {
        query.set("user_modal_id", user._id);
        query.set("user_modal_tab", "skills");
        navigate({ search: query.toString() });
      }}>
      <td className="px-4 py-2 max-w-xs truncate">{user.name}</td>
      <td className="px-4 py-2 max-w-xs truncate">{user.manager_name}</td>
      <td className="px-4 py-2 max-w-xs truncate">
        <ProgressBar percentage={Math.round((answers.length / questions.length) * 100)} />
      </td>
    </tr>
  );
};

const ProgressBar = ({ percentage }) => {
  if (!percentage < 0) return <div className="w-full bg-gray-200 h-3 rounded-sm overflow-hidden animate-pulse" />;
  return (
    // Progress Bar
    <div className="flex gap-2 items-center">
      <div className={`w-full bg-gray-200 h-3 rounded-sm overflow-hidden`}>
        <div className="bg-white rounded-sm " style={{ width: `${Math.min(100, percentage)}%` }}>
          <div className={`flex justify-center items-center h-3 bg-sky-700 rounded-sm`} />
        </div>
      </div>
      <div className="text-gray-700 text-xs">{percentage}</div>
    </div>
  );
};
