import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import Loader from "../../components/loader";
import api from "../../services/api";

export default () => {
  const { id } = useParams();
  const [device, setDevice] = useState();

  const getDevice = async () => {
    const { data } = await api.get(`/device/${id}`);
    setDevice(data);
  };

  const onDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete this device?")) return;
      await api.remove(`/device/${id}`);
      window.location.href = "/device";
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDevice();
  }, []);

  const renderStatus = () => {
    if (device.status === "active") {
      return (
        <div className="flex gap-2 items-center justify-center w-full">
          <div className="w-2 h-2 rounded-full bg-green-400" />
          <div className="text-xs tracking-wider text-green-400">{device.status}</div>
        </div>
      );
    } else if (device.status === "inactive") {
      return (
        <div className="flex gap-2 items-center justify-center w-full">
          <div className="w-2 h-2 rounded-full bg-red-400" />
          <div className="text-xs tracking-wider text-red-400">{device.status}</div>
        </div>
      );
    }
  };

  if (!device) return <Loader />;

  return (
    <>
      <div className="w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3 mx-auto mt-5 sm:mt-20 border border-gray-100 shadow-lg rounded-md bg-[#7a7679]">
        <div className="my-10" />
        <div className="flex justify-center">
          <img src={"https://support.apple.com/library/APPLE/APPLECARE_ALLGEOS/SP825/macbookair.png"} alt="mac-logo" className="w-32" />
        </div>
        <div className="my-8" />
        <div>
          <div className="w-full text-lg text-center font-bold text-white">{device.name || device.model}</div>
          <div>{renderStatus()}</div>
        </div>
        <div className="my-5" />
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-4 w-full">
            <span className="text-sm flex-1 text-right text-gray-100">Model</span>
            <span className="text-sm flex-1 text-left text-gray-200">{device.model}</span>
          </div>
          <div className="flex items-center gap-4 w-full">
            <span className="text-sm flex-1 text-right text-gray-100">Serial Number</span>
            <span className="text-sm flex-1 text-left text-gray-200">{device.serial_number}</span>
          </div>
          <div className="flex items-center gap-4 w-full">
            <span className="text-sm flex-1 text-right text-gray-100">RAM</span>
            <span className="text-sm flex-1 text-left text-gray-200">{device.ram}</span>
          </div>
          <div className="flex items-center gap-4 w-full">
            <span className="text-sm flex-1 text-right text-gray-100">Storage</span>
            <span className="text-sm flex-1 text-left text-gray-200">{device.storage}</span>
          </div>
          {device.status === "active" ? (
            <div className="flex items-center gap-4 w-full">
              <span className="text-sm flex-1 text-right text-gray-100">User</span>
              <span className="text-sm flex-1 text-left text-gray-200">{device.userName}</span>
            </div>
          ) : (
            <div className="flex items-center gap-4 w-full">
              <span className="text-sm flex-1 text-right text-gray-100">Office</span>
              <span className="text-sm flex-1 text-left text-gray-200">{device.office}</span>
            </div>
          )}
        </div>
        {device.comments ? (
          <>
            <div className="my-5 border-b border-gray-400 w-1/3 mx-auto" />
            <div className="flex items-center gap-4 w-full">
              <span className="text-sm flex-1 text-right text-gray-100">Comments</span>
              <span className="text-sm flex-1 text-left text-gray-200">{device.comments}</span>
            </div>
          </>
        ) : null}
        <div className="my-5" />
        <div className="flex flex-row items-center justify-center gap-2">
          <button onClick={onDelete} className="text-xs text-white bg-[#b8b4b6] rounded-md py-1 px-2">
            Delete
          </button>
          <a href={`/device/${device._id.toString()}/edit`} className="text-xs text-white bg-[#b8b4b6] rounded-md py-1 px-2">
            Edit
          </a>
        </div>
        <div className="my-4" />
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-4 w-full">
            <span className="text-xs flex-1 text-right text-[#b7b3b4]">Updated at</span>
            <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(device.updatedAt).format("DD/MM/YYYY, HH:mm")}</span>
          </div>
          <div className="flex items-center gap-4 w-full">
            <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created at</span>
            <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(device.createdAt).format("DD/MM/YYYY, HH:mm")}</span>
          </div>
        </div>
        <div className="my-10" />
      </div>
      <div className="my-10 mx-4">
        <p className="text-center mb-3">Logs :</p>
        <Logs device={device} />
      </div>
      <div className="my-10 mx-4">
        <p className="text-center mb-3">Raw :</p>
        <pre className="bg-gray-800 text-white p-4 rounded-md">{JSON.stringify(device, null, 2)}</pre>
      </div>
    </>
  );
};

const Logs = ({ device }) => {
  return (
    <div>
      {device.logs
        .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
        .map((log) => {
          return <Card key={log._id} log={log} />;
        })}
    </div>
  );
};

const Card = ({ log }) => {
  return (
    <div className="bg-white shadow overflow-hidden md:rounded-lg mb-2 p-2">
      <span className="font-bold">
        Updated at {new Date(log.created_at).toLocaleString()} by {log.user_name}
      </span>
      <div>{log.text}</div>
    </div>
  );
};
