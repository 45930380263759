import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";

import api from "../../../services/api";
import toast from "react-hot-toast";

const Activities = ({ invoice }) => {
  const [activities, setActivities] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryActivities = { budgetId: invoice.budgetId };
        const resActivities = await api.post("/activity/search", queryActivities);
        if (resActivities.ok) setActivities(resActivities.data.map((e) => ({ ...e, type: "activity" })));

        const queryBanks = { budgetId: invoice.budgetId };
        const resBanks = await api.post("/bank/search", queryBanks);
        if (resBanks.ok) setBanks(resBanks.data.banks.map((e) => ({ ...e, type: "bank" })));
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const handleChangeMany = async () => {
    try {
      const update = { invoiceId: invoice._id, invoiceName: invoice.name };

      const activityIds = selected.filter((a) => a.userId).map((a) => a._id);
      const bankIds = selected.filter((a) => !a.userId).map((a) => a._id);

      if (activityIds.length) {
        const resActivities = await api.put(`/activity/many`, { ids: activityIds, update });
        if (resActivities.ok) setActivities(activities.map((a) => (activityIds.includes(a._id) ? { ...a, ...update } : a)));
      }
      if (bankIds.length) {
        const resBanks = await api.put(`/bank/many`, { ids: bankIds, update });
        if (resBanks.ok) setBanks(banks.map((a) => (bankIds.includes(a._id) ? { ...a, ...update } : a)));
      }

      toast.success("updated");
    } catch (error) {
      console.error(error);
      toast.error("error");
    }
  };

  const handleChange = async (values) => {
    try {
      if (values.type === "bank") {
        const res = await api.put(`/bank/${values._id}`, values);
        if (res.ok) {
          setBanks(banks.map((a) => (a._id === values._id ? { ...a, ...values } : a)));
          toast.success("updated");
        }
      } else {
        const res = await api.put(`/activity/${values._id}`, values);
        if (res.ok) {
          setActivities(activities.map((a) => (a._id === values._id ? { ...a, ...values } : a)));
          toast.success("updated");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("error");
    }
  };

  const activitiesTotalCost = activities.reduce((acc, a) => acc + a.cost, 0);
  const activitiesTotalPrice = activities.reduce((acc, a) => acc + a.value, 0);
  const activitesMargin = (activitiesTotalPrice - activitiesTotalCost) / activitiesTotalCost;

  const data = [...activities, ...banks].filter((e) => e.invoiceId === invoice._id);
  const invoiceTotalCost = activities.filter((e) => e.invoiceId === invoice._id).reduce((acc, a) => acc + a.cost, 0) + banks.filter((e) => e.invoiceId === invoice._id).reduce((acc, a) => acc + Math.abs(a.amount), 0)
  const invoiceTotalPrice = activities.filter((e) => e.invoiceId === invoice._id).reduce((acc, a) => acc + a.value, 0) + banks.filter((e) => e.invoiceId === invoice._id).reduce((acc, a) => acc + Math.abs(a.amount), 0)
  const invoiceMargin = (invoiceTotalPrice - invoiceTotalCost) / invoiceTotalCost;

  return (
    <div className="space-y-8">
      <div className="space-y-2">
        <h2 className="text-gray-800">Associated to this invoice</h2>
        <div className="w-full  p-6 border border-gray-300 rounded">
          <table className="table-fixed">
            <thead className="border-b border-gray-300">
              <tr>
                <th colSpan={3} className="text-left font-semibold text-sm p-2">
                  Name
                </th>
                <th className="text-right font-semibold text-sm px-3">Cost</th>
                <th className="text-right font-semibold text-sm px-3">Price</th>
                <th className="text-right font-semibold text-sm px-3">Quantity</th>
                <th className="text-right font-semibold text-sm px-3">Total Cost</th>
                <th className="text-right font-semibold text-sm px-3 pr-10">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) =>
                  item.type === "bank" ? (
                    <BankRowInvoice key={index} data={item} onRemove={(v) => handleChange({ ...v, invoiceId: null, invoiceName: null })} />
                  ) : (
                    <ActivityRowInvoice key={index} data={item} onRemove={(v) => handleChange({ ...v, invoiceId: null, invoiceName: null })} />
                  ),
                )
              ) : (
                <tr>
                  <td colSpan={7} className="p-2 text-sm text-center text-gray-400">
                    No data linked to the invoice
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot className="border-t border-gray-300">
              <tr>
                <td colSpan={3} className="p-2 text-sm font-semibold">
                  Total
                </td>
                <td className="text-right p-2 text-sm font-semibold">
                  {data.reduce((acc, a) => acc + a.userTjm || 0, 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
                </td>
                <td className="text-right p-2 text-sm font-semibold">
                  {data.reduce((acc, a) => acc + a.userTjms || 0, 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
                </td>
                <td className="text-right p-2 text-sm font-semibold">{data.reduce((acc, a) => acc + a.total / 8, 0).toFixed(2)} days</td>

                <td className="text-right p-2 text-sm font-semibold">{invoiceTotalCost.toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right p-2 text-sm font-semibold">
                  <span className={`p-1 ${invoiceMargin < 0 ? "bg-red-200" : "bg-green-200"} rounded text-[10px] mr-2`}>
                    {invoiceMargin < 0 ? "-" : "+"}
                    {invoiceMargin.toLocaleString("fr", { style: "percent" })}
                  </span>
                  {invoiceTotalPrice.toLocaleString("fr", { style: "currency", currency: "EUR" })}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex justify-between items-center gap-2">
          <h2 className="text-gray-800">Activities of the budget</h2>
          {selected.length > 0 && (
            <div className="flex items-center gap-3">
              <button className="blue-btn" onClick={() => handleChangeMany()}>
                Add to Invoice
              </button>
            </div>
          )}
        </div>
        <div className="w-full  p-6 border border-gray-300 rounded">
          <table className="table-fixed">
            <thead className="border-b border-gray-300">
              <tr>
                <th colSpan={3} className="text-left font-semibold text-sm p-2">
                  Name
                </th>
                <th className="text-right font-semibold text-sm px-3">Cost</th>
                <th className="text-right font-semibold text-sm px-3">Price</th>
                <th className="text-right font-semibold text-sm px-3">Quantity</th>
                <th className="text-right font-semibold text-sm px-3">Total Cost</th>
                <th className="text-right font-semibold text-sm px-3">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {activities.length > 0 ? (
                activities.map((item, index) => (
                  <tr key={index} className={`${item.invoiceId ? (item.invoiceId === invoice._id ? "bg-sky-100 text-sky-600" : "bg-gray-100 text-gray-600") : ""}`}>
                    <td colSpan={3} className="p-2 text-sm">
                      <div className="flex items-center gap-2">
                        {item.invoiceId === invoice._id ? (
                          <input type="checkbox" id={`checkbox-${item._id}`} checked className="rounded mr-2 text-sky-700" disabled />
                        ) : (
                          <input
                            type="checkbox"
                            id={`checkbox-${item._id}`}
                            className="rounded mr-2 text-sky-700"
                            checked={selected.some((s) => s._id === item._id)}
                            onChange={(e) => setSelected(e.target.checked ? [...selected, item] : selected.filter((s) => s._id !== item._id))}
                          />
                        )}
                        <label htmlFor={`checkbox-${item._id}`} className="truncate">
                          <p className="flex items-center">
                            {item.userName}
                            {item.invoiceId ? (
                              <p>
                                - <span className="border border-gray-600 text-gray-600 text-xs px-1 rounded truncate">{item.invoiceName}</span>
                              </p>
                            ) : null}
                          </p>
                          <p className="text-xs text-gray-500">{new Date(item.date).toLocaleDateString("en-US", { year: "numeric", month: "2-digit" })}</p>
                        </label>
                      </div>
                    </td>
                    <td className="text-right p-2 text-sm">{(item.userTjm || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                    <td className="text-right p-2 text-sm">{(item.userTjms || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                    <td className="text-right p-2 text-sm">{(item.total / 8).toFixed(2)} days</td>
                    <td className="text-right p-2 text-sm">{(item.cost || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                    <td className="text-right p-2 text-sm">{(item.value || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} className="p-2 text-sm text-center text-gray-400">
                    No activity found
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot className="border-t border-gray-300">
              <tr>
                <td colSpan={3} className="p-2 text-sm font-semibold">
                  Total
                </td>
                <td className="text-right p-2 text-sm font-semibold">
                  {activities.reduce((acc, a) => acc + a.userTjm, 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
                </td>
                <td className="text-right p-2 text-sm font-semibold">
                  {activities.reduce((acc, a) => acc + a.userTjms, 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
                </td>
                <td className="text-right p-2 text-sm font-semibold">{activities.reduce((acc, a) => acc + a.total / 8, 0).toFixed(2)} days</td>

                <td className="text-right p-2 text-sm font-semibold">{activitiesTotalCost.toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                <td className="text-right p-2 text-sm font-semibold">
                  <span className={`p-1 ${activitesMargin < 0 ? "bg-red-200" : "bg-green-200"} rounded text-[10px] mr-2`}>
                    {activitesMargin < 0 ? "-" : "+"}
                    {activitesMargin.toLocaleString("fr", { style: "percent" })}
                  </span>
                  {activitiesTotalPrice.toLocaleString("fr", { style: "currency", currency: "EUR" })}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex justify-between items-center gap-2">
          <h2 className="text-gray-800">Banks of the budget</h2>
          {selected.length > 0 && (
            <div className="flex items-center gap-3">
              <button className="blue-btn" onClick={() => handleChangeMany()}>
                Add to Invoice
              </button>
            </div>
          )}
        </div>
        <div className="w-full p-6 border border-gray-300 rounded">
          <table className="table-fixed">
            <thead className="border-b border-gray-300">
              <tr>
                <th colSpan={2} className="text-left font-semibold text-sm p-2">
                  Item
                </th>
                <th className="text-left font-semibold text-sm p-2">Date</th>
                <th className="text-right font-semibold text-sm p-2">Amount</th>
                <th className="text-right font-semibold text-sm p-2">Category</th>
              </tr>
            </thead>
            <tbody>
              {banks.length > 0 ? (
                banks.map((item, index) => (
                  <tr key={index} className={`${item.invoiceId ? (item.invoiceId === invoice._id ? "bg-sky-100 text-sky-600" : "bg-gray-100 text-gray-600") : ""}`}>
                    <td colSpan={2} className="text-right p-2 text-sm">
                      <div className="flex items-center gap-2">
                        {item.invoiceId === invoice._id ? (
                          <input type="checkbox" id={`checkbox-${item._id}`} checked className="rounded mr-2 text-sky-700" disabled />
                        ) : (
                          <input
                            type="checkbox"
                            id={`checkbox-${item._id}`}
                            className="rounded mr-2 text-sky-700"
                            checked={selected.some((s) => s._id === item._id)}
                            onChange={(e) => setSelected(e.target.checked ? [...selected, item] : selected.filter((s) => s._id !== item._id))}
                          />
                        )}

                        <Link to={`/bank/${item._id}`}>
                          <label htmlFor={`checkbox-${item._id}`} className="truncate">
                            {item.name} - {item.invoiceId ? <span className="border border-gray-600 text-gray-600 text-xs p-1 rounded truncate">{item.invoiceName}</span> : null}
                          </label>
                        </Link>
                      </div>
                    </td>
                    <td className="p-2 text-sm">{new Date(item.date).toLocaleDateString()}</td>
                    <td className="text-right p-2 text-sm">{(-item.amount).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                    <td className="text-right p-2 text-sm">{item.category}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="p-2 text-sm text-center text-gray-400">
                    No bank found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const BankRowInvoice = ({ data, onRemove }) => (
  <tr>
    <td colSpan={3} className="p-2 text-sm">
      <div className="flex items-center gap-2">
        <span className="border border-sky-700 text-sky-700 text-xs p-1 rounded">Bank</span>
        <p className="font-semibold">{data.name}</p>
      </div>
    </td>
    <td className="text-right p-2 text-sm">{(-data.amount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
    <td className="text-right p-2 text-sm">{(-data.amount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
    <td className="text-right p-2 text-sm">1</td>
    <td className="text-right p-2 text-sm">{(-data.amount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
    <td className="text-right p-2 text-sm">
      <div className="w-full flex items-center">
        <p className="flex-1">{(-data.amount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>

        <button className="text-sm h-10 w-10 flex items-center justify-center text-red-600" onClick={() => onRemove(data)}>
          <MdDeleteOutline />
        </button>
      </div>
    </td>
  </tr>
);

const ActivityRowInvoice = ({ data, onRemove }) => (
  <tr>
    <td colSpan={3} className="p-2 text-sm">
      <div className="flex items-center gap-2">
        <span className="border border-yellow-600 text-yellow-600 text-xs p-1 rounded">Activity</span>

        <p className="font-semibold">{data.userName}</p>
      </div>
    </td>
    <td className="text-right p-2 text-sm">{(data.userTjm || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
    <td className="text-right p-2 text-sm">{(data.userTjms || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
    <td className="text-right p-2 text-sm">{(data.total / 8).toFixed(2)} days</td>
    <td className="text-right p-2 text-sm">{(data.cost || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
    <td className="text-right p-2 text-sm">
      <div className="w-full flex items-center">
        <p className="flex-1">{(data.value || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>

        <button className="text-sm h-10 w-10 flex items-center justify-center text-red-600" onClick={() => onRemove(data)}>
          <MdDeleteOutline />
        </button>
      </div>
    </td>
  </tr>
);

export default Activities;
