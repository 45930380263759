import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsCalendar4Week, BsFileEarmarkCheck } from "react-icons/bs";
import { FaThumbsUp, FaUser, FaRegThumbsUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { AiOutlineClockCircle } from "react-icons/ai";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { PiStarFill, PiStarLight } from "react-icons/pi";
import api from "../../services/api";
import toast from "react-hot-toast";

import { classNames } from "../../utils";
import UserProjects from "../../components/UserProjects";

export default function UserCard({ user, setUser, link }) {
  const navigate = useNavigate();

  const u = useSelector((state) => state.Auth.user);

  const [values, setValues] = useState(user);
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const handleLike = async (keyword) => {
    const newKeyWords = values.key_words.map((kw) => {
      if (kw._id === keyword._id) {
        if (kw.likes.includes(u._id)) {
          const newLikes = kw.likes.filter((like) => like !== u._id);
          return { ...kw, likes: newLikes };
        } else {
          const newLikes = [...kw.likes, u._id];
          return { ...kw, likes: newLikes };
        }
      } else {
        return kw;
      }
    });
    const { data } = await api.put(`/user/${user._id}`, { ...values, key_words: newKeyWords });
    setValues(data);
  };

  return (
    <div className={classNames("relative bg-white rounded-lg border flex", user.status === "active" ? "opacity-100" : "opacity-50")}>
      <div className="w-3/12 p-4">
        <div className="relative h-max shrink-0">
          <img
            src={user.avatar || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
            alt="userlogo"
            className="rounded-full w-7 h-7 md:w-14 md:h-14 object-cover"
          />
          <AvailabilityDot user={user} />
        </div>
        {u.role === "admin" && (
          <div className="py-1">
            <div className="text-[10px] font-semibold">cost</div>
            <div className="text-xs font-normal">{user.tjm}€</div>
          </div>
        )}
        <div className="py-1">
          <div className="text-[10px] font-semibold">tjm</div>
          <div className="text-xs font-normal">{user.tjms}€</div>
        </div>
        <Rating user={user} />
      </div>
      <div className="w-9/12 flex-1 flex flex-col">
        <div className="flex items-center justify-between gap-1 pt-2">
          <h3
            className="font-semibold flex m-0 cursor-pointer"
            onClick={() => {
              query.set("user_modal_id", user._id);
              navigate({ search: query.toString() });
            }}>
            {user.name}
          </h3>
          {user.status === "inactive" ? <span className="bg-red-100 text-red-700 border-red-200 border-[1px] uppercase text-xs px-3 py-1">inactive</span> : null}
        </div>

        <div className="text-xs font-normal">{user.job_title}</div>
        {user.calendly && (
          <a href={user.calendly} onClick={(e) => e.stopPropagation()} target="_blank" className="relative" rel="noreferrer">
            <BsCalendar4Week className="w-5 h-5" />
          </a>
        )}

        <div className="w-full flex gap-1 overflow-x-scroll py-2 pr-2">
          {values?.key_words
            ?.sort((a, b) => b.likes.length - a.likes.length)
            ?.map((keyword) => (
              <div key={keyword.name} className="flex flex-row gap-1 items-center rounded-lg bg-blue-50 text-blue-800 px-2 border-[1px] border-blue-200 text-[10px] text-nowrap">
                {keyword.name}
                <button className="flex flex-row gap-1 text-blue-600 hover:text-blue-800 " onClick={() => handleLike(keyword)}>
                  <FaThumbsUp className={`${keyword.likes.includes(u._id) ? "text-blue-600" : "text-gray-400"}`} />
                  <p>{keyword.likes.length > 0 ? keyword.likes.length : " "}</p>
                </button>
              </div>
            ))}
        </div>

        <Availability user={user} />
        <Description value={user.description} />
        {/* <Projects
          user={user}
          max={7}
          handleCountClick={() => {
            query.set("user_modal_id", user._id);
            query.set("user_modal_tab", "activity");
            navigate({ search: query.toString() });
          }}
          handleClick={(projectId) => navigate(`/project/${projectId}`)}
        /> */}

        <div className="mt-auto p-2 flex justify-end gap-2">
          {u.role === "admin" && (
            <select
              id="availability"
              name="availability"
              onChange={async (e) => {
                e.persist();
                const { ok, data, code } = await api.put(`/user/${user._id}`, { availability: e.target.value });
                if (!ok) return toast.error(`Error: ${code}`);
                setUser(data);
              }}
              value={user.availability}
              className={`text-xs rounded-lg py-1.5 ${user.availability === "available" ? "text-green-500 border-green-500" : "text-gray-500 border-gray-500"}`}>
              <option value="available">Available</option>
              <option value="not available">Unavailable</option>
            </select>
          )}

          <button
            className="text-xs text-blue-500 border-blue-500 border-[1px] rounded-lg py-1.5 px-3 hover:bg-blue-500 hover:text-white"
            onClick={() => {
              query.set("user_modal_id", user._id);
              navigate({ search: query.toString() });
            }}>
            View profile
          </button>
        </div>
      </div>
    </div>
  );
}

const Rating = ({ user }) => {
  const averageRating = user.ratings.length > 0 ? user.ratings.reduce((sum, rating) => sum + rating.score, 0) / user.ratings.length : 0;
  return (
    <div className="flex items-center gap-1 text-xs font-normal">
      <PiStarFill className="text-yellow-400" />
      {averageRating}
    </div>
  );
};
const Projects = (props) => (
  <div className="flex items-center gap-4 text-xs font-normal py-2">
    <span>Projects: </span>
    <UserProjects {...props} />
  </div>
);

const AvailabilityDot = ({ user }) => {
  if (user.availability === "available") return <div className="w-2 h-2 md:w-4 md:h-4 bg-green-500 rounded-full absolute bottom-1 left-7 md:left-11 border-2 border-white" />;
  return <div className="w-2 h-2 md:w-4 md:h-4 bg-red-500 rounded-full absolute bottom-1 left-7 md:left-11 border-2 border-white" />;
};

const Availability = ({ user }) => {
  if (user.availability === "available")
    return (
      <div className="mb-1 flex items-center gap-1 h-max px-2">
        <AiOutlineClockCircle className="w-3.5 h-3.5" />
        <p className="text-sm semibold">{user.disponibility} disponibility</p>
      </div>
    );
  return <div />;
};

const Description = ({ value }) => {
  const LIMIT = 100;
  const [open, setOpen] = useState(false);

  return (
    <div className="flex gap-2 items-start">
      <p className="flex-1 text-sm text-gray-500">
        {open ? (
          value
        ) : (
          <>
            {value?.substring(0, LIMIT)}
            {value?.length > LIMIT && "..."}
          </>
        )}
      </p>
      {value?.length > LIMIT ? (
        <>
          <button onClick={() => setOpen((e) => !e)} className="flex items-center gap-1 text-xs text-gray-600">
            {open ? (
              <div className="flex flex-col items-center">
                <HiChevronUp />
                See less
              </div>
            ) : (
              <div className="flex flex-col items-center">
                See more
                <HiChevronDown />
              </div>
            )}
          </button>
        </>
      ) : null}
    </div>
  );
};
