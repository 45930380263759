import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import Step from "./step";

import api from "../../../../services/api";
import { classNames } from "../../../../utils";

export default ({ workshop, setWorkshop }) => {
  const [selectedStep, setSelectedStep] = useState(workshop.steps[0]);

  const step = selectedStep ? { ...selectedStep, index: workshop.steps.findIndex((s) => s._id === selectedStep?._id) } : null;

  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
      <div className="grid grid-cols-12">
        <div className="col-span-2 border-r border-gray-200">
          <ul className="divide-y divide-gray-100">
            {workshop.steps.map((step, i) => {
              const isActive = step._id === selectedStep?._id;
              return (
                <li
                  className={classNames("flex gap-2 items-center list-none py-1 px-3 cursor-pointer", isActive ? "text-white bg-primary" : "hover:bg-primary-50")}
                  key={step._id}
                  onClick={() => setSelectedStep(step)}>
                  <span className={classNames("text-xs", isActive ? "text-white " : "text-gray-600")}>{i}</span>
                  {step.title}
                </li>
              );
            })}
          </ul>
          <div className="my-1" />
          <div className="">
            <Create workshop={workshop} setWorkshop={setWorkshop} setSelectedStep={setSelectedStep} />
          </div>
        </div>
        <div className="col-span-10 p-2">
          {selectedStep ? (
            <Step
              step={step}
              workshop={workshop}
              onUpdate={async (e) => {
                console.log("e", e);
                const index = workshop.steps.findIndex((s) => s._id === e._id);
                workshop.steps[index] = e;
                console.log("workshop.steps[index]", workshop.steps[index]);

                // change position
                if (e.index !== step.index) {
                  const s = workshop.steps.splice(step.index, 1)[0];
                  workshop.steps.splice(e.index, 0, s);
                }

                const { data } = await api.put(`/workshop/${workshop._id}`, workshop);

                setWorkshop(data);
                setSelectedStep(e);
                toast.success("successfully updated!");
              }}
              onRemove={async () => {
                if (!confirm("Are you sure?")) return;
                const { data } = await api.put(`/workshop/${workshop._id}/delete_step/${step._id}`);
                setWorkshop(data);
                setSelectedStep(workshop.steps[step.index - 1 || 0]);

                toast.success("successfully deleted!");
              }}
            />
          ) : (
            <div className="p-4 italic">Select a step</div>
          )}
        </div>
      </div>
    </div>
  );
};

const Create = ({ workshop, setWorkshop, setSelectedStep }) => {
  const [open, setOpen] = useState(false);
  const [title, setTile] = useState("");
  const ref = useRef(null);

  async function handleSubmit(event) {
    try {
      event.preventDefault();
      const steps = [...(workshop.steps || []), { title }];
      const { data } = await api.put(`/workshop/${workshop._id}`, { steps });
      setWorkshop(data);
      setSelectedStep(data.steps[data.steps.length - 1]);
      setOpen(false);
      toast.success("Step created successfully");
    } catch (err) {
      toast.error("Error creating step");
      console.log(err);
    }
  }

  useEffect(() => {
    if (open) ref.current.focus();
  }, [open]);

  return (
    <div>
      <div className="flex gap-2 items-center list-none py-1 px-3 cursor-pointer hover:bg-primary-50 text-primary" onClick={() => setOpen(true)}>
        <span className="text-x text-primary">+</span>
        New step
      </div>
      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md" onClick={(e) => e.stopPropagation()}>
            <form onSubmit={handleSubmit}>
              <div>
                <div className="flex justify-between flex-wrap">
                  <div className="w-full md:w-[48%] mt-2">
                    <div className="text-[14px] text-[#212325] font-medium	">Title</div>
                    <input ref={ref} className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]" value={title} onChange={(e) => setTile(e.target.value)} />
                  </div>
                </div>
              </div>
              <br />
              <button type="submit" className="bg-primary text-white py-1 px-3 rounded-md text-base hover:bg-primary-500" onClick={handleSubmit}>
                Create
              </button>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};
