import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SelectUser from "../../components/selectUser";

import api from "../../services/api";
import Loader from "../../components/loader";
import { classNames } from "../../utils";
import ProgressCard from "./components/ProgressCard";
import ProgressCardSubCategory from "./components/ProgressCardSubCategory";
import Modal from "../../components/modal";
import { CATEGORIES_KEY_WORDS } from "../../constants";

export default ({ setQuestionModalId }) => {
  const [questions, setQuestions] = useState();
  const [answers, setAnswers] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { search } = useLocation();
  const [isModalKeyWordsOpen, setIsModalKeyWordsOpen] = useState(false);
  const query = new URLSearchParams(search);
  const [keyWords, setKeyWords] = useState();

  const [displayedQuestions, setDisplayedQuestions] = useState([]);

  const user = useSelector((state) => state.Auth.user);
  const [values, setValues] = useState(user);

  const [filters, setFilters] = useState();

  useEffect(() => {
    if (query.get("userId")) {
      setFilters({ ...filters, userId: query.get("userId"), userName: query.get("userName") });
    } else {
      setFilters({ ...filters, userId: user._id, userName: user.name });
    }
  }, [query.get("userId")]);

  useEffect(() => {
    if (!filters) return;
    const fetchData = async () => {
      setLoadingData(true);
      try {
        const resQuestions = await api.post("/skill_question/search", { limit: 1000 });
        setQuestions(resQuestions.data);
        const resAnswers = await api.post("/skill_answer/search", { userId: filters.userId });
        setAnswers(resAnswers.data);

        const obj = { limit: 1000 };
        if (filters.skillCategory) obj.skillCategory = filters.skillCategory;
        if (filters.skillSubCategory) obj.skillSubCategory = filters.skillSubCategory;
        navigate({ search: new URLSearchParams(filters).toString() });

        const { data } = await api.post("/skill_question/search", obj);
        setDisplayedQuestions(data);
      } catch (error) {
        console.error("error", error);
      }
      setLoadingData(false);
    };
    fetchData();
  }, [filters]);

  useEffect(() => {
    if (!selectedCategory) return;
    getSubCategoryOptions();
  }, [selectedCategory]);

  useEffect(() => {
    if (!questions) return;
    setDisplayedQuestions(questions?.filter((q) => q.skillCategory === selectedCategory));
  }, [selectedCategory]);

  const getSubCategoryOptions = async () => {
    try {
      setLoading(true);
      const { data } = await api.post("/skill_question/search", { skillCategory: selectedCategory });
      const subCategories = data.map((question) => question.skillSubCategory);
      setSubCategoriesOptions([...new Set(subCategories)]);
      setLoading(false);
    } catch (error) {
      console.error("error", error);
    }
  };

  async function getKeyWords() {
    setLoading(true);
    const res = await api.post(`/key_word/search`);
    console.log("res", res);
    setKeyWords(res.data);
    setLoading(false);
  }

  useEffect(() => {
    getKeyWords();
  }, []);

  const handleSetKeyWords = (e) => {
    if (values.key_words.some((keyWord) => keyWord.name === e.name)) {
      const newKeyWords = values.key_words.filter((keyWord) => keyWord.name !== e.name);
      setValues({ ...values, key_words: newKeyWords });
    } else {
      const newKeyWords = [...values.key_words, { name: e.name, category: e.category, likes: [] }];
      setValues({ ...values, key_words: newKeyWords });
    }
  };

  const updateUser = async () => {
    try {
      const { data } = await api.put(`/user/${user._id}`, values);
      setIsModalKeyWordsOpen(false);
      toast.success("Key words updated");
    } catch (error) {
      console.error("error", error);
    }
  };

  if (!questions || !answers) return <Loader />;

  return (
    <div className="mx-auto flex flex-col gap-3">
      <div className="flex justify-between my-3">
        <div className="flex gap-3 items-center">
          <SelectUser
            disabled={user.role !== "admin"}
            label="user"
            userName={filters.userName}
            value={{ user_name: filters.userName, user_id: filters.userId }}
            onChange={(e) => {
              setFilters({ ...filters, userId: e._id, userName: e.name });
            }}
          />
          <button
            className="transparent-btn"
            onClick={() => {
              query.set("user_modal_id", filters.userId);
              navigate({ search: query.toString() });
            }}>
            See user
          </button>
        </div>
        <div className="flex flex-row  gap-4">
          <a className="gray-btn" href="https://docs.google.com/spreadsheets/d/1tPrd7IeGoJydYw3Gsp2A5lQ4k_TAwjVMuHtGxusjqEs/edit?gid=493361999#gid=493361999" target="_blank">
            See workshops to improve skills
          </a>
        </div>
      </div>

      {filters.userId ? (
        <>
          {selectedCategory ? (
            <div>
              <button
                className="transparent-btn"
                onClick={() => {
                  setSelectedCategory(null);
                  setFilters({ ...filters, skillSubCategory: null });
                  setSubCategoriesOptions([]);
                }}>
                &lt;&nbsp;Choose another category
              </button>
              <div className="flex flex-raw">
                <div className="">
                  <label className="text-xs text-gray-500 ml-4">Category</label>
                  <ProgressCard
                    category={selectedCategory}
                    answers={answers}
                    questions={questions}
                    onClick={() => {
                      setSelectedCategory(null);
                      setFilters({ ...filters, skillSubCategory: null });
                      setSubCategoriesOptions([]);
                    }}
                  />
                </div>
                <div className="">
                  <label className="text-xs text-gray-500 ml-4">Sub categories</label>
                  <div className="flex justify-center">
                    <div className="grid grid-cols-4 gap-2">
                      {subCategoriesOptions.map((option) => {
                        const isSelected = filters.skillSubCategory === option;
                        return (
                          <ProgressCardSubCategory
                            subCategory={option}
                            category={selectedCategory}
                            answers={answers}
                            questions={questions}
                            isSelected={isSelected}
                            onClick={() => setFilters({ ...filters, skillSubCategory: option })}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <button className="transparent-btn" onClick={() => setSelectedCategory(null)}>
                Choose a category
              </button>
              <div className="w-full flex-row flex gap-x-5">
                <ProgressCard category="Code" answers={answers} questions={questions} onClick={() => setSelectedCategory("Code")} />
                <ProgressCard category="Communication" answers={answers} questions={questions} onClick={() => setSelectedCategory("Communication")} />
                <ProgressCard category="Leadership" answers={answers} questions={questions} onClick={() => setSelectedCategory("Leadership")} />
                <ProgressCard category="Product" answers={answers} questions={questions} onClick={() => setSelectedCategory("Product")} />
              </div>
            </div>
          )}

          {loadingData ? (
            <Loader />
          ) : (
            selectedCategory && (
              <div className="">
                {displayedQuestions
                  .sort((a, b) => a.level - b.level)
                  .map((question, i) => {
                    return (
                      <Card
                        key={i}
                        question={question}
                        answer={answers.find((a) => a.questionId === question._id)}
                        userId={filters.userId}
                        setQuestionModalId={setQuestionModalId}
                      />
                    );
                  })}
              </div>
            )
          )}
        </>
      ) : (
        <div className="h-full w-full text-center italic text-neutral-500">Please select a user</div>
      )}
    </div>
  );
};

const Card = ({ question, answer: defaultAnswer, userId, setQuestionModalId }) => {
  const [answer, setAnswer] = useState(defaultAnswer);

  const onClick = async (optionSelected) => {
    try {
      if (!answer) {
        const { data } = await api.post("/skill_answer", {
          questionId: question._id,
          value: optionSelected.value,
          label: optionSelected.label,
          levelValue: question.level,
          userId,
          skillCategory: question.skillCategory,
          skillSubCategory: question.skillSubCategory,
          completedAt: new Date(),
          status: "completed",
        });
        setAnswer(data);
      } else {
        const { data } = await api.put(`/skill_answer/${answer._id}`, {
          questionId: question._id,
          value: optionSelected.value,
          label: optionSelected.label,
          levelValue: question.level,
          userId,
          skillCategory: question.skillCategory,
          skillSubCategory: question.skillSubCategory,
          completedAt: new Date(),
          status: "completed",
        });
        setAnswer(data);
      }
      toast.success("Answer saved");
    } catch {}
  };

  useEffect(() => {
    setAnswer(defaultAnswer);
  }, [defaultAnswer]);

  return (
    <div className={classNames(`flex flex-col justify-center gap-1 rounded-lg mt-2 border`, !answer ? "border-x-8 border-y-2 border-sky-700" : "border-neutral-200")}>
      <div className="flex justify-between items-center gap-2">
        <div className="px-4 py-3">
          <div className="my-2">
            <button className="text-left" onClick={() => setQuestionModalId(question._id)}>
              {question.title}
            </button>
          </div>
        </div>
        <div className="p-2">
          <div className="text-right">
            <div className="flex items-start justify-around gap-1">
              <div
                className={classNames(
                  "px-5 py-2 flex border border-gray-300 justify-center items-center rounded-lg text-base cursor-pointer hover:bg-red-200",
                  answer?.value === "0" ? "bg-red-600 text-white" : "text-black",
                )}
                onClick={() => onClick({ value: 0, label: "No" })}>
                No
              </div>
              <div
                className={classNames(
                  "px-5 py-2 flex border border-gray-300 justify-center items-center rounded-lg text-base cursor-pointer hover:bg-green-200",
                  answer?.value === "1" ? "bg-green-600 text-white" : "text-black",
                )}
                onClick={() => onClick({ value: 1, label: "Yes" })}>
                Yes
              </div>
            </div>
          </div>
        </div>
      </div>
      {question.details ? (
        <div className="pb-3 px-4">
          <div className="text-sm font-normal text-neutral-500">{question.details}</div>
        </div>
      ) : null}
    </div>
  );
};
