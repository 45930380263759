import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../../services/api";

import Modal from "../../../components/modal";
import Table from "../../../components/Table";
import Paginator from "../../../components/Paginator";

import SelectArchived from "../component/SelectArchived";

import CompanyModal from "./companyModal";

export default () => {
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({ page: 1, archived: false, personnae: "INCUBATORS" });
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetch();
    load();
  }, [filters]);

  async function load() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    if (!id) return;
    const { ok, data } = await api.get(`/s_company/${id}`);
    if (!ok) return toast.error("Error fetching company");
    setSelectedCompany(data);
    window.history.pushState({}, "", "/acquiz/companies");
  }

  async function fetch() {
    setLoading(true);
    const newFilters = {};
    newFilters.skip = (filters.page - 1) * 100;
    newFilters.archived = filters.archived;
    newFilters.personnae = filters.personnae;
    if (filters.search) newFilters.search = filters.search;
    const { data, total, ok } = await api.post("/s_company/search", newFilters);
    console.log(data, total, ok);
    if (!ok) return toast.error("Error fetching companies");
    setCompanies(data);
    setTotal(total);
    setLoading(false);
  }

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between items-center">
        <Search setFilters={setFilters} filters={filters} />
        <SelectArchived value={filters.archived} onChange={(e) => setFilters({ ...filters, archived: e })} />
        <SelectPersonae value={filters.personnae} onChange={(e) => setFilters({ ...filters, personnae: e })} />
        <CreateCompany
          onDone={(e) => {
            fetch();
            setSelectedCompany(e);
          }}
        />
      </div>
      <div>{total} items</div>
      <CompanyModal company={selectedCompany} setCompany={setSelectedCompany} />
      <Table
        total={total}
        header={[
          { title: "Name", key: "name" },
          { title: "Personnae", key: "personnae" },
          { title: "Campaigns", key: "campaigns" },
          { title: "Created At", key: "created_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {companies.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedCompany(item);
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.personnae}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                {item.campaigns.map((e) => {
                  return (
                    <span key={e._id} className="bg-gray-200 text-xs px-2 py-1 rounded-md mr-1">
                      {e.name}
                    </span>
                  );
                })}
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.created_at}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");
  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);
  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};

const CreateCompany = ({ onDone }) => {
  async function onSubmit() {
    try {
      const res = await api.post("/s_company", { name });
      if (!res.ok) throw res;
      toast.success("Client created");
      onDone(res.data);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }
  return (
    <div>
      <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={onSubmit}>
        Create a company
      </button>
    </div>
  );
};

const SelectPersonae = ({ value, onChange }) => {
  return (
    <div>
      <select
        className="w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm"
        value={value || ""}
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value);
        }}>
        <option value="">Select personae</option>
        <option value="BUSINESS_REFERRERS">BUSINESS_REFERRERS</option>
        <option value="INCUBATORS">INCUBATORS</option>
        <option value="STARTUPS_ADVANCED">STARTUPS_ADVANCED</option>
        <option value="STARTUPS_EARLYSTAGE">STARTUPS_EARLYSTAGE</option>
        <option value="CORPORATES">CORPORATES</option>
        <option value="GOVERNMENT">GOVERNMENT</option>
      </select>
    </div>
  );
};
