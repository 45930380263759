import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { IoInformationCircleOutline } from "react-icons/io5";

import Modal from "../../../../components/modal";

import About from "./about";
import Edit from "./edit";
import Raw from "./raw";

import { classNames } from "../../../../utils";

export default ({ company, setCompany }) => {
  const [tab, setTab] = useState("edit");

  if (!company) return <></>;

  return (
    <Modal isOpen={true} onClose={() => setCompany(null)}>
      <div className="w-full h-[90vh] bg-[#FBFBF9] p-2 overflow-y-auto">
        <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
          <div className="flex items-center gap-2 p-4">
            <div className="flex justify-between flex-1">
              <div className="flex items-center gap-2">
                <div>
                  <div className="text-lg font-bold"> {company.name}</div>
                  <div className="text-sm text-gray-500">{company.about}</div>
                  <div className="text-sm text-gray-500">{company.location}</div>
                  <div className="text-sm text-gray-500">{company.employees}</div>
                </div>
                <div className="flex space-x-2 ml-8">
                  {company.linkedin && (
                    <a href={company.linkedin} target="_blank" rel="noopener noreferrer">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn Logo" className="w-6 h-5" />
                    </a>
                  )}
                  {company.email && (
                    <a href={`mailto:${company.email}`}>
                      <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" alt="Email Logo" className="w-6 h-5" />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-x-4">
                <span className="text-xs flex-1 text-right text-[#b7b3b4]">Updated at</span>
                <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(company.updated_at).format("DD/MM/YYYY, HH:mm")}</span>
                <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created at</span>
                <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(company.created_at).format("DD/MM/YYYY, HH:mm")}</span>
              </div>
            </div>
          </div>
          <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
            <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} Icon={IoInformationCircleOutline} />
            <TabItem title="Edit" tab="edit" setTab={setTab} active={tab === "edit"} Icon={IoInformationCircleOutline} />
            <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
          </nav>
        </div>
        <div className="my-2" />
        {tab === "about" ? <About company={company} setCompany={setCompany} /> : null}
        {tab === "edit" ? <Edit company={company} setCompany={setCompany} /> : null}
        {tab === "raw" ? <Raw company={company} setCompany={setCompany} /> : null}
      </div>
    </Modal>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 ",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
