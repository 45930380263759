import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-hot-toast";
import Comments from "../../../components/Comments";
import Edit from "./edit";

export default ({ meeting, setMeeting }) => {
  return (
    <div className="flex w-full">
      <div className="flex-col gap-4 w-2/3">
        <div className="flex justify-center w-full aspect-video">
          <iframe
            title={`Meeting: ${meeting.name}`}
            width="100%"
            height={"100%"}
            className="rounded-lg object-cover"
            src={`https://drive.google.com/file/d/${meeting.drive_id}/preview`}
            frameBorder="0"
            allowFullScreen
          />
        </div>
        <Comments
          value={meeting.comments}
          onAdd={async (text) => {
            const { data } = await api.post(`/meeting/comment/${meeting._id}`, { text });
            setMeeting(data);
            toast.success("Comment added!");
          }}
        />
      </div>
      <div className="w-1/3">
        <Edit meeting={meeting} setMeeting={setMeeting} />
      </div>
    </div>
  );
};
