import React, { useEffect, useState } from "react";
import api from "../../../services/api";

export default ({}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    try {
      setLoading(true);
      const { data } = await api.post("/user/pool-applicant-stats");
      setData(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  }

  if (loading) return <div>Loading...</div>;

  console.log("data", data);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="bg-sky-900" />
            {data &&
              data[0].months
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((month, index) => {
                  const formattedDate = new Date(month.date).toLocaleDateString("fr-FR", { month: "long", year: "numeric" });
                  return (
                    <th key={index} className="border border-gray-300 py-2 px-4 min-w-[200px] bg-sky-900 text-sm font-normal text-white">
                      {formattedDate}
                    </th>
                  );
                })}
          </tr>
        </thead>
        <tbody>
          {data &&
            data
              .filter((row) => row.user_name)
              .map((row) => {
                const sorted = row.months.sort((a, b) => new Date(b.date) - new Date(a.date));

                return (
                  <>
                    <tr key="user">
                      <td className="border border-gray-300 py-2 px-4 bg-sky-900 text-white text-sm whitespace-nowrap">{row["user_name"]}</td>
                      {sorted.map((month, monthIndex) => {
                        return <td key={monthIndex} className="border border-gray-300 py-2 px-4 bg-sky-900" />;
                      })}
                    </tr>

                    <tr key="applicants">
                      <td className="border border-gray-300 py-2 px-4 text-sm">{"Applicants (new entries by category)"}</td>
                      {sorted.map((month, monthIndex) => {
                        return (
                          <td key={monthIndex} className="border border-gray-300">
                            <div className="flex flex-col gap-1 max-h-[400px] overflow-y-scroll">
                              {Object.entries(month.stats)
                                .filter(([key, value]) => value !== 0)
                                .map(([key, value]) => {
                                  return (
                                    <div className="w-full flex justify-between items-center gap-2 odd:bg-gray-100 p-2 cursor-pointer">
                                      <span className="text-sm">{key}</span>
                                      <span className="border border-purple-200 bg-purple-50 text-purple-800 px-2 text-xs">{value}</span>
                                    </div>
                                  );
                                })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};
