import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { HiMagnifyingGlass } from "react-icons/hi2";

import Loader from "../../components/loader";
import Pagination from "../../components/pagination";

import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState();
  const [filter, setFilter] = useState({
    search: "", 
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const handleNextPage = () => setCurrentPage((prev) => prev + 1);
  const handlePreviousPage = () => setCurrentPage((prev) => prev - 1);

  useEffect(() => {
    get();
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    get();
  }, [currentPage]);

  const get = async () => {
    try {
      const { data, ok, total, code } = await api.post("/ai-review/search", {
        search: filter.search,
        offset: (currentPage - 1) * 10,
        sort: { created_at: -1 },
      });
      if (!ok) return toast.error("Error!" || code);
      setReviews(data);
      setTotal(total);
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching reviews!");
    }
  };

  if (!reviews) return <Loader />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-6 space-y-6">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold text-gray-900">AI Code Reviews</h2>
            <div className="relative w-96">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="search"
                value={filter.search}
                onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                className="block w-full rounded-lg border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                placeholder="Search reviews..."
              />
            </div>
          </div>

          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Author</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">AI Review</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Pull Request</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {reviews.map((review, i) => (
                  <tr 
                    key={i} 
                    className="hover:bg-gray-50 transition-colors cursor-pointer"
                    onClick={() => navigate(`/review/${review._id}`)}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                      <div className="font-medium text-gray-900">{review.author}</div>
                      <div className="text-gray-500">{review.repo_name}</div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <div className="max-w-xl">{truncate_text(review.review_content)}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {new Date(review.created_at).toLocaleDateString()} 
                      <div className="text-gray-400 text-xs">
                        {new Date(review.created_at).toLocaleTimeString()}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      <a 
                        href={review.comment_link} 
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 hover:bg-blue-100"
                      >
                        PR #{review.pr_number}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination total={total} currentPage={currentPage} onNext={handleNextPage} onPrevious={handlePreviousPage} />
        </div>
      </div>
    </div>
  );
};

const truncate_text = (text, limit = 250) => (text?.length > limit ? text?.slice(0, limit) + "..." : text);
