import { Routes, Route, NavLink } from "react-router-dom";
import { IoInformationCircle, IoPencil } from "react-icons/io5";

import Simulator from "./simulator";
import Benchmark from "./benchmark";
import Pricing from "./pricing";

const TABS = {
  SIMULATOR: "Simulator",
  BENCH: "Benchmark",
  PRICING: "Pricing",
};

export default () => {
  return (
    <div>
      <div className="m-2">
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden justify-between">
          <div>
            <TabItem title={TABS.SIMULATOR} tab="simulator" Icon={IoInformationCircle} />
            <TabItem title={TABS.BENCH} tab="bench" Icon={IoPencil} />
            <TabItem title={TABS.PRICING} tab="pricing" Icon={IoPencil} />
          </div>
        </nav>
        <div className="my-2" />
        <div className="w-full">
          <Routes>
            <Route path="/" element={<Simulator />} />
            <Route path="/simulator" element={<Simulator />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/bench" element={<Benchmark />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, Icon }) => (
  <NavLink
    to={`/salary/${tab}`}
    activeClassName="text-primary  border-primary"
    className="group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent">
    {Icon ? <Icon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
  </NavLink>
);
