import React, { useEffect, useState } from "react";
import api from "../services/api";

export default ({ value, onChange, className }) => {
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await api.post("/job/search");
      setJobs(res.data);
    })();
  }, []);

  return (
    <div>
      <select
        className={`${className
          ? className
          : className = "w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
          }`}
        value={value?._id || ""}
        onChange={(e) => {
          e.preventDefault();
          const f = jobs.find((f) => e.target.value === f._id);
          onChange(f);
        }}>
        <option disabled>Job</option>
        <option value={""}>All Jobs</option>
        {jobs
          .sort(function (a, b) {
            if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
            if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
            return 0;
          })
          .map((e) => {
            return (
              <option key={e._id} value={e._id}>
                {`${e.title} (#${e.index})`}
              </option>
            );
          })}
      </select>
    </div>
  );
};

//tset
