import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import api from "../../services/api";

export default function () {
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data } = await api.get("/project");
    const arr = data.filter((e) => e.profitSharing.length);
    const all = [];
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].profitSharing.length; j++) {
        all.push({ ...arr[i].profitSharing[j], project_name: arr[i].name, project_id: arr[i]._id });
      }
    }

    setData(all);
  }

  return (
    <div className="bg-white rounded-lg border-gray-300 p-6">
      <div className="text-2xl font-bold">Profit Sharing</div>
      <table className="min-w-full table-auto border-collapse">
        <thead>
          <tr className="border-b border-gray-400">
            <th className="text-sm p-2">Name</th>
            <th className="text-sm p-2">Role</th>
            <th className="text-sm p-2">Percent</th>
            <th className="text-sm p-2">Project Name</th>
            <th className="text-sm p-2">Created At</th>
            <th className="text-sm p-2">GO</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((e) => (
            <tr className="border-b border-gray-400">
              <td className="text-sm p-2">{e.name}</td>
              <td className="text-sm p-2">{e.role}</td>
              <td className="text-sm p-2">{e.percent}%</td>
              <td className="text-sm p-2">{e.project_name}</td>
              <td className="text-xs text-gray-400 p-2">{dayjs(e.createdAt).format("DD/MM/YYYY")}</td>
              <td>
                <Link to={`/project/${e.project_id}`} target="_blank">
                  View Project
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
