import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import api from "./../../services/api";

const Create = () => {
  const user = useSelector((state) => state.Auth.user);
  const navigate = useNavigate();
  const [data, setData] = useState({ iban: "", amount: 0, description: "", name: "" });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { ok, code } = await api.post("/payment/draft/", data);
      if (!ok) {
        throw new Error(code);
      }

      toast.success("Payment sent");
      navigate("/payment");
    } catch (error) {
      console.log(error);
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid gap-8 w-full grid-cols-2">
      <form className="p-8 grid gap-4" onSubmit={handleSubmit}>
        <h1 className="text-2xl font-bold">Create Payment</h1>

        <label className="flex flex-col gap-2">
          Name
          <input type="text" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} placeholder="Name" />
        </label>

        <label className="flex flex-col gap-2">
          Description
          <input type="text" value={data.description} onChange={(e) => setData({ ...data, description: e.target.value })} placeholder="Description" />
        </label>

        <label className="flex flex-col gap-2">
          Amount
          <input type="number" value={data.amount} onChange={(e) => setData({ ...data, amount: e.target.value })} placeholder="Amount" />
        </label>

        <label className="flex flex-col gap-2">
          IBAN
          <input type="text" value={data.iban} onChange={(e) => setData({ ...data, iban: e.target.value })} placeholder="IBAN" />
        </label>

        <div className="my-2">
          <button
            type="submit"
            disabled={loading || !data.iban || !data.amount || !data.description || !data.name}
            className="btn btn-primary disabled:opacity-50 disabled:cursor-wait">
            {loading ? "Loading" : "Send payment request"}
          </button>
        </div>
      </form>
      <div className="flex items-center justify-center">
        <img
          src="https://bank.cellar-c2.services.clever-cloud.com/workshops/63e0130123112e7476a35c5f/sebastienlg_3d_realistic_cartoon_style_of_someone_rich_and_happ_abc3c6aa-e4a3-4951-8f05-2c24da46b500.png.png"
          alt="placeholder"
          className="rounded-lg w-1/2"
        />
      </div>
    </div>
  );
};

export default Create;
