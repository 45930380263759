import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import api from "../../../services/api";

export default ({}) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const d = new Date();
    d.setMonth(d.getMonth() - 2);
    const res = await api.post(`/folk/search`, { contactType: "CLIENT", type: "CONQUEST", status: ["NEGOTIATION", "CLOSING", "PROPOSAL"] });
    setProjects(res.data);
  }

  console.log("Leads", projects);

  //people
  return (
    <div className="p-2">
      <span>Leads in negotiation or closing</span>
      <div>
        {projects.map((e) => {
          return (
            <Link className="border-b-[1px] border-gray-200 p-2 flex justify-between" to={`/folk?folk_modal_tab=info&folk_modal_id=${e._id}`} target="_blank">
              <div className="flex flex-col w-full">
                <div className="flex justify-between ">
                  <div>
                    <div>
                      {e.company || `${e.first_name} ${e.last_name}`}
                      {`(${e.probability}%)`}
                    </div>
                    <img src={e.user_avatar} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white mr-2" />
                    {e.people.map((e) => {
                      return <img src={e.user_avatar} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" />;
                    })}
                  </div>
                  <div>
                    <span className="text-sm font-semibold text-gray-500 bg-gray-100 p-1 rounded-md">{e.potential}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-500 bg-gray-100 p-1 rounded-md">{e.type}</span>
                    <span className="text-sm font-semibold text-red-500 bg-red-100 p-1 rounded-md">{e.rank}</span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

// A => project with high potential in our stack,
// B => project for learning, business potential -> freelance
// C => project to give to freelance
