import React, { useEffect, useState } from "react";

import api from "../../../services/api";
import Table from "../../../components/Table";
import Paginator from "../../../components/Paginator";

import ActivityModal from "./ActivityModal";


export default () => {
  const [filters, setFilters] = useState({ page: 1 });
  const [activities, setActivities] = useState([]);
  const [total, setTotal] = useState(0);
  const [activity, setActivity] = useState(null);
  
  const getActivities = async () => {
    try {
      const { data, total } = await api.post("/s_activity/search", { skip: (filters.page - 1) * 100, limit: 100, ...filters });
      setActivities(data);
      setTotal(total);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getActivities();
  }, [filters]);

  return (
    <div>
      <Table
        total={total}
        header={[
          { title: "Type", key: "type" },
          { title: "Tool", key: "tool" },
          { title: "Message", key: "message" },
          { title: "Contact Name", key: "contact_name" },
          { title: "Created At", key: "created_at" },
        ]}
        loading={false}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {activities.map((item, index) => {
          return (
            <tr 
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`} 
              key={item._id}
              onClick={() => setActivity(item)}
            >
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.type}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.tool}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.message}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.contact_name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{new Date(item.created_at).toLocaleString()}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
      <ActivityModal activity={activity} setActivity={setActivity} />
    </div>
  );
};
