import React, { useState, useEffect } from "react";
import { ORGANISATIONS } from "../../constant";

export const HeaderBlock = ({ values, editing, onChange }) => {
  if (editing) return <HeaderForm values={values} onChange={onChange} />;

  return (
    <header>
      <div className="flex justify-between w-full">
        <div className="w-2/5">
          <div className="flex gap-3 items-center mb-2">
            {values.sender_img && <img className="h-10 rounded-md border border-[#C9C8C9]" src={values.sender_img} />}
            <h1 className="text-lg text-black font-semibold">{values.sender_name}</h1>
          </div>
          <p className="text-sm py-1">{values.sender_address}</p>
          <p className="text-sm py-1">
            <span className="font-semibold">Email: </span> {values.sender_email}
          </p>
          {values.sender_vat && (
            <p className="text-sm py-1">
              <span className="font-semibold">VAT Intracommunity Tax: </span> {values.sender_vat}
            </p>
          )}
          {values.sender_siret && (
            <p className="text-sm py-1">
              <span className="font-semibold">SIRET: </span> {values.sender_siret}
            </p>
          )}
        </div>
        <div className="w-2/5">
          <div className="flex gap-3 items-center mb-2">
            {values.receiver_img && <img className="h-10 rounded-md border border-[#C9C8C9]" src={values.receiver_img} />}
            <h1 className="text-lg  text-black font-semibold">{values.receiver_name}</h1>
          </div>
          <p className="text-sm py-1">{values.receiver_address}</p>
          <p className="text-sm py-1">
            <span className="font-semibold">Email: </span> {values.receiver_email}
          </p>
          {values.receiver_vat && (
            <p className="text-sm py-1">
              <span className="font-semibold">VAT Intracommunity Tax: </span> {values.receiver_vat}
            </p>
          )}
          {values.receiver_siret && (
            <p className="text-sm py-1">
              <span className="font-semibold">SIRET: </span> {values.receiver_siret}
            </p>
          )}
        </div>
      </div>
      <div>
        <p className="text-sm">{values.date}</p>
      </div>
    </header>
  );
};

const HeaderForm = ({ values, onChange }) => {
  useEffect(() => {
    const obj = {};
    if (!values.sender_name) {
      obj.sender_img = ORGANISATIONS[0].img;
      obj.sender_name = ORGANISATIONS[0].name;
      obj.sender_email = ORGANISATIONS[0].email;
      obj.sender_address = ORGANISATIONS[0].address;
      obj.sender_vat = ORGANISATIONS[0].vat;
      obj.sender_kvk = ORGANISATIONS[0].kvk;
    }

    onChange({ ...values, ...obj });
  }, []);

  function handleChange(e) {
    onChange({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <header className="space-y-2">
      <div className="flex justify-between w-full">
        <div className="w-2/5 space-y-2">
          <div className="flex gap-3 items-center">
            <img className="h-9 rounded-md border border-[#C9C8C9]" src={values.sender_img} alt="image.png" />
            <input className="input" type="text" name="sender_name" value={values.sender_name} onChange={handleChange} placeholder="Sender name" />
          </div>
          <textarea rows={2} className="input" type="text" name="sender_address" value={values.sender_address} onChange={handleChange} placeholder="Sender address" />
          <input className="input" type="text" name="sender_email" value={values.sender_email} onChange={handleChange} placeholder="Sender email" />
          <input className="input" type="text" name="sender_vat" value={values.sender_vat} onChange={handleChange} placeholder="Sender VAT" />
          <input className="input" type="text" name="sender_kvk" value={values.sender_kvk} onChange={handleChange} placeholder="Sender KVK" />
        </div>
        <div className="w-2/5 space-y-2">
          <div className="flex gap-3 items-center">
            <img className="h-9 rounded-md border border-[#C9C8C9]" src={values.receiver_img} alt="image.png" />
            <input className="input" type="text" name="receiver_name" value={values.receiver_name} onChange={handleChange} placeholder="Receiver name" />
          </div>
          <textarea rows={2} className="input" type="text" name="receiver_address" value={values.receiver_address} onChange={handleChange} placeholder="Receiver address" />
          <input className="input" type="text" name="receiver_email" value={values.receiver_email} onChange={handleChange} placeholder="Receiver email" />
          <input className="input" type="text" name="receiver_vat" value={values.receiver_vat} onChange={handleChange} placeholder="Receiver VAT" />
          <input className="input" type="text" name="receiver_siret" value={values.receiver_siret} onChange={handleChange} placeholder="Receiver SIRET" />
        </div>
      </div>
      <div>
        <input className="input" type="text" name="date" value={values.date} onChange={handleChange} placeholder="Fait le 18/03/1999 au Mans" />
      </div>
    </header>
  );
};

export { HeaderBlock as Header, HeaderForm };
export default HeaderBlock;
