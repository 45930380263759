import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Formik } from "formik";
import { FiExternalLink } from "react-icons/fi";
import toast from "react-hot-toast";
import { IoMdDocument } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import api from "../../../services/api";
import SelectMonth from "../../../components/selectMonth";
import SelectOrganisation from "../../../components/selectOrganisation";
import Select from "../../../components/Select";

const PaymentView = ({ payment, setPayment }) => {
  const [values, setValues] = useState(payment);
  const TAXES = ["FR 20%", "NL 21%", "N/A (Not applicable)"];
  const TAXES_VALUE = {
    "FR 20%": 0.2,
    "NL 21%": 0.21,
    "N/A (Not applicable)": 0,
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (payment.amount) return;
  }, []);

  async function onSave() {
    try {
      const { ok, data, code } = await api.put(`/payment/${payment._id}`, { ...values });
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      toast.success("Saved !");
      setPayment(data);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  async function onRequest() {
    try {
      if (!values.files || values.files.length === 0) return toast.error("Error!\nPlease upload an invoice", { icon: "❌" });
      const { ok, code } = await api.put(`/payment/${payment._id}`, { ...values, status: "TOPAY" });
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      toast.success("Saved !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/payment/${payment._id}`);
    toast.success("successfully removed!");
    navigate("/payment");
  }

  return (
    <div>
      <div className="p-2">
        <div className="flex gap-4">
          <span className="flex items-center text-[18px] font-semibold text-[#212325]">{`${payment.user_name}`}</span>
        </div>
        <React.Fragment>
          <div className="flex gap-6 mt-3">
            <div>
              <span className="text-[14px] font-medium text-[#212325]">Month</span>
              <div className="mt-2">
                <SelectMonth
                  placeholder="Select month"
                  indexDefaultValue={0}
                  value={values.date}
                  onChange={(e) => setValues({ ...values, date: e.target.value })}
                  name="date"
                />
              </div>
            </div>
            <div>
              <span className="text-[14px] font-medium text-[#212325]">Organisation</span>
              <div className="mt-2">
                <SelectOrganisation
                  value={values.organisation_id}
                  className="projectsInput"
                  onChange={(e) => {
                    console.log(e);
                    setValues({ ...values, organisation_id: e._id, organisation_name: e.name });
                  }}
                />
              </div>
            </div>
            <div>
              <div className="flex gap-1 items-center">
                <span className="text-[14px] font-medium text-[#212325]">Amount in euro (€)</span>
                <a className="flex gap-1 text-[#0560FD] text-xs font-medium ml-2" href="https://www.xe.com/currencyconverter/convert" target="_blank" rel="noreferrer">
                  Currency Converter <FiExternalLink />
                </a>
              </div>
              <input
                required
                className="border border-[#E5EAEF] rounded-[10px] w-full h-[40px] px-2 mt-2 text-[1rem] focus:outline-none focus:ring focus:ring-[#80bdff]"
                name="value"
                placeholder="€"
                type="number"
                value={values.value}
                onChange={(e) => {
                  console.log(e.target.value);
                  setValues({ ...values, value: e.target.value });
                }}
              />
            </div>
            <div>
              <span className="text-[14px] font-medium text-[#212325]">VAT</span>
              <div className="mt-2">
                <div className="w-44">
                  <Select
                    options={TAXES}
                    value={values.taxType}
                    onChange={(e) => setValues({ ...values, taxType: e, taxValue: TAXES_VALUE[e] })}
                    placeholder="VAT"
                    width="w-full"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-[265px]">
              <UploadInvoice name="files" value={values.files} onChange={(e) => setValues({ ...values, files: e.target.value })} />
            </div>
          </div>
          <div className="mt-4">
            <Activities payment={payment} />
          </div>
          <div className="w-full mt-4">
            <span className="text-[14px] font-medium text-[#212325]">Comment</span>
            <textarea
              className="w-full border border-[#E5EAEF] mt-2 rounded-[10px] text-sm pt-2 pl-2  focus:outline-none focus:ring focus:ring-[#80bdff]"
              placeholder="Please add a comment on what you in your mind...."
              rows="5"
              name="comment"
              value={values.comment}
              onChange={(e) => {
                setValues({ ...values, comment: e.target.value });
              }}
            />
          </div>
          <div className="mt-2">
            <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={onSave}>
              Save
            </button>
            <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={onRequest}>
              Request
            </button>
            <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onDelete}>
              Delete
            </button>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

const UploadInvoice = ({ onChange, name, value }) => {
  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const renderFileName = (file) => {
    if (file.startsWith("https")) return `${file.substring(0, 8)}...${file.substring(file.length - 15)}`;
    return "Cached filed (send to upload)";
  };

  const onDelete = (index) => {
    const newValue = value.filter((_, i) => i !== index);
    onChange({ target: { value: newValue, name } });
  };

  return (
    <div>
      <label className="text-[14px] font-medium text-[#212325] mb-0">Invoice(s)</label>
      {(value || []).map((file, i) => (
        <div key={file} className="flex items-center mt-1">
          <a className="flex gap-1 items-center text-sm text-gray-700 hover:underline cursor-pointer" href={file} target="_blank" rel="noreferrer">
            <IoMdDocument />
            {renderFileName(file)}
          </a>
          {/* TODO */}
          <div className="flex justify-center cursor-pointer text-xl hover:text-red-500 ml-2">
            <MdDeleteForever onClick={() => onDelete(i)} />
          </div>
        </div>
      ))}
      <label htmlFor="file-upload" className="flex pl-[20px] items-center w-[172px] h-[40px] border border-[#ccc] bg-[#F9FBFD] rounded-[10px] mt-[7px] cursor-pointer">
        Upload a file
      </label>
      <input
        id="file-upload"
        className="mt-2"
        name={name}
        type="file"
        onChange={async (e) => {
          const fileBuffer = await readFileAsync(e.target.files[0]);
          const newFiles = [...(value || []), fileBuffer];
          onChange({ target: { value: newFiles, name } });
        }}
      />
    </div>
  );
};

const Activities = ({ payment }) => {
  const [activities, setActivities] = useState([]);

  if (payment.type !== "activity") return <div />;

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const { data: a } = await api.get(`/activity?userId=${payment.user_id}&date=${payment.date}&exclude_unavailable_project=true`);
      setActivities(a);
    })();
  }, [id]);

  const cost = activities.reduce((acc, cur) => acc + cur.cost, 0);
  const hours = activities.reduce((acc, cur) => acc + cur.total, 0);

  if (!activities.length) return <div>No activities</div>;

  return (
    <div className="w-2/3">
      <div className="bg-[#FFFFFF] border border-[#E5EAEF] rounded-[10px] my-3">
        <div className="p-3 border-b-2 border-[#E5EAEF]">
          <Link className="text-[18px] font-semibold text-[#212325]" to={`/activity?userId=${payment.user_id}&date=${activities[0].date}`}>
            Activities
          </Link>
        </div>
        <div className="overflow-x-auto">
          <table className="w-[700px] md:w-full table-fixed">
            <thead>
              <tr className="border-b border-[#E5EAEF] text-[14px] font-semibold text-[#212325]">
                <th className="p-3">Projects</th>
                <th>Days</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((e) => {
                return (
                  <tr key={e.project} className="border-b border-[#E5EAEF] text-[14px] text-[#676D7C] font-medium">
                    <td className="p-3">{e.projectName} </td>
                    <td>{(e.total / 8)?.toFixed(2)}</td>
                    <td>{`${e.cost?.toFixed(2)} €`}</td>
                  </tr>
                );
              })}
              <tr key="total" className="text-[14px] font-semibold text-[#212325]">
                <td className="p-3">Total-</td>
                <td>{`${(hours / 8)?.toFixed(2)} days`}</td>
                <td>{`${cost?.toFixed(2)} €`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentView;
