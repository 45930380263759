import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

import api from "../../../services/api";
import Modal from "../../../components/modal";
import Pagination from "../../../components/Paginator";
import Table from "../../../components/Table";

const headers = [
  { title: "Index", key: "index" },
  { title: "Job title", key: "title" },
  { title: "Location", key: "location" },
  { title: "Contract", key: "contract_type" },
  { title: "Views", key: "view" },
  { title: "Applicants", key: "applicant_count_v2.applicant" },
  { title: "Perf", key: "perf" },
  { title: "Account manager", key: "user_name" },
  { title: "Last applicant", key: "last_applicant" },
  { title: "Created at", key: "created_at" },
];

export default () => {
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState({ created_at: -1 });
  const [filter, setFilter] = useState({ search: "", page: 1, pageSize: 30, status: "active" });
  const [jobs, setJobs] = useState();

  useEffect(() => {
    load();
  }, [filter, sort]);

  async function load() {
    try {
      const { ok, data, total } = await api.post("/job/search", { ...filter, sort });
      if (!ok) return console.error("Error fetching jobs");
      setJobs(data);
      setTotal(total);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSort = (key) => {
    if (key === "last_applicant" || key === "view") return toast.error("Cannot sort by this column");
    setSort((prevSort) => {
      const newSort = { [key]: prevSort[key] === 1 ? -1 : 1 };
      return newSort;
    });
  };

  if (!jobs) return <div className="p-3 text-sm">Loading...</div>;

  return (
    <div className="p-3">
      <section>
        <div className="flex flex-1 items-center gap-4 justify-between mb-3 ">
          <input placeholder="Search..." className="flex-1 input" value={filter.search} onChange={(e) => setFilter({ ...filter, search: e.target.value })} />
          <div className="flex gap-2 items-center flex-1">
            <SelectStatus value={filter.status} onChange={(status) => setFilter({ ...filter, status })} />
            <Create />
            <SyncWebflow />
          </div>
        </div>
      </section>

      <Table header={headers} sort={sort} total={total} onSort={handleSort} className="min-w-full divide-y divide-gray-200 bg-white">
        {(jobs || []).map((job) => (
          <Job key={job._id} job={job} />
        ))}
      </Table>

      <div className="flex w-full mt-5">
        <Pagination page={filter.page} setPage={(page) => setFilter({ ...filter, page })} last={Math.ceil(total / filter.pageSize)} />
      </div>
    </div>
  );
};

const SelectStatus = ({ value, onChange }) => {
  return (
    <select className="input" value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="">All</option>
      <option value="active">Active</option>
      <option value="inactive">Archived</option>
    </select>
  );
};

const Job = ({ job }) => {
  const [last_applicant, set_last_applicant] = useState();
  const [view, set_view] = useState();
  const [realUserCount, setRealUserCount] = useState(null); // Add new state

  async function get() {
    const { data } = await api.post("/user/search", { job_ids: [job._id], role: "applicant", sort: { created_at: -1 } });
    const { data: view_data } = await api.post("/jobpost/search", { job_id: job._id });

    setRealUserCount(data?.users?.length || 0);
    set_last_applicant(data?.users[0]?.created_at);
    set_view(view_data.reduce((total, item) => total + item.views, 0));
  }

  useEffect(() => {
    get();
  }, []);

  return (
    <tr key={job?._id} className="hover:bg-gray-100 border-b">
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">#{job?.index}</td>
      <td className="px-3 py-2 text-sm text-gray-500 !w-56 group">
        <Link to={`/job/${job?._id}`} target="_blank" className="flex items-center group-hover:underline">
          {job?.title}
        </Link>
      </td>
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{job?.location}</td>
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{job?.contract_type}</td>
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{view ? view : "loading..."}</td>
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{realUserCount !== null ? realUserCount : "loading..."}</td>
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{view && realUserCount ? `${((realUserCount * 100) / view).toFixed(2)} %` : "-"}</td>
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{job?.user_name}</td>
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{last_applicant ? moment(last_applicant).fromNow() : "loading..."}</td>

      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{job?.created_at.substring(0, 10)}</td>
    </tr>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ project_id: "62fb3ecdf4d045068e31a78d" });
  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.user);

  async function onCreate() {
    if (!values.title) return toast.error("Missing title !");
    const res = await api.post("/job", values);

    let obj = {};
    obj.job_id = res.data._id;
    obj.job_title = res.data.title;

    obj.user_id = user._id;
    obj.user_name = user.name;
    obj.user_avatar = user.avatar;

    obj.channel_id = "650c0ae98e232b2a9879d6c0";
    obj.channel_name = "Accounting";

    const jobPost = await api.post("/jobpost", obj);
    toast.success("Created!");

    navigate(`/job/${res.data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary !text-sm" onClick={() => setOpen(true)}>
        Create a job
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Title (*)</div>
          <input className="projectsInput block" value={values.title} onChange={(e) => setValues({ ...values, title: e.target.value })} />
        </div>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};

const SyncWebflow = () => {
  const [loading, setLoading] = useState(false);

  async function sync() {
    setLoading(true);
    try {
      const { ok, data, code } = await api.post(`/job/sync-webflow`, { all_jobs: true });
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      toast.success("Job post synced !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
    setLoading(false);
  }
  return (
    <button
      disabled={loading}
      className="bg-blue-800 mb-1 disabled:opacity-50 disabled:cursor-not-allowed text-xs whitespace-nowrap font-medium text-white py-3 px-5 rounded-md"
      onClick={sync}>
      Sync webflow
    </button>
  );
};
