import React from "react";

import { CONTRACTS } from "../constants";

export default function ({ value, onChange }) {
  return (
    <select className="w-[180px] bg-white projectsInput text-sm  font-normal py-2 px-[14px] rounded-[10px] cursor-pointer" value={value} onChange={(e) => onChange(e.target.value)}>
      <option disabled>Contract</option>
      <option value={""}>All contracts</option>
      {Object.keys(CONTRACTS).map((e) => {
        return (
          <option key={e} value={e} label={e}>
            {e}
          </option>
        );
      })}
    </select>
  );
}
