import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoArrowForwardOutline } from "react-icons/io5";

import { classNames } from "../../utils";
import api from "../../services/api";

const MySkills = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState();
  const user = useSelector((state) => state.Auth.user);

  const fetchQuestions = async () => {
    const { data } = await api.post("/skill_question/search");
    setQuestions(data);
  };

  const fetchAnswers = async () => {
    const { data } = await api.post("/skill_answer/search", { userId: user._id });
    const answeredQuestions = data.filter((a) => questions.find((q) => q._id === a.questionId));
    setAnswers(answeredQuestions);
  };

  useEffect(() => {
    fetchQuestions();
  }, [user]);

  useEffect(() => {
    if (!questions.length) return;
    fetchAnswers();
  }, [questions]);

  if (!questions.length || !answers) return <Skeleton />;

  let color = "";
  if (answers.length / questions.length >= 0.8) color = "#34D399";
  if (answers.length / questions.length < 0.8) color = "#FBBF24";
  if (answers.length / questions.length < 0.5) color = "#F87171";

  return (
    <div className={classNames(" border-[1px] border-gray-200 rounded-md overflow-hidden h-full bg-white")}>
      <Link to="/skills/answers" className="group relative flex flex-col items-center h-full">
        <div className="w-full p-3 flex items-center justify-between">
          <div className="w-full flex justify-between items-center">
            <h1 className="flex items-center text-base font-semibold text-gray-900 gap-2 mb-1">
              <span>My skills</span>
            </h1>
            <p className="text-xs text-gray-500">Your progress, our strength</p>
          </div>
        </div>
        <div className="flex-1 group relative flex flex-col items-center justify-between">
          <CircleScore value={answers.length} total={questions.length} color={color} text={`${Math.round((answers.length / questions.length) * 100)}%`} />
          <div className={`text-sm flex w-full items-center justify-center text-gray-500 mb-3 transition ease-in-out group-hover:translate-y-32`}>
            {answers.length} / {questions.length} answers
          </div>
          <span className={`absolute -bottom-32 flex w-full items-center justify-center text-black font-bold transition ease-in-out group-hover:-translate-y-36`}>
            Let's go <IoArrowForwardOutline />
          </span>
        </div>
      </Link>
    </div>
  );
};

const CircleScore = ({ value, total = 100, text, color }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const percentage = value / total;
  const strokeDashoffset = String(circumference * (1 - percentage));

  return (
    <div className="relative flex items-center justify-center w-28 h-28">
      {percentage < 0.5 ? (
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-20"></span>
      ) : percentage < 0.8 ? (
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
      ) : null}
      <svg className="-rotate-90" width="100%" height="100%" viewBox="0 0 80 80">
        <circle cx="40" cy="40" r={radius} fill="transparent" stroke={"#DFE1E5"} strokeWidth="4" />
        <circle cx="40" cy="40" r={radius} fill="white" stroke={color} strokeWidth="4" strokeDasharray={circumference} strokeDashoffset={strokeDashoffset} strokeLinecap="round" />
      </svg>
      <div className="absolute flex flex-col items-center">
        <div className="text-lg font-semibold">{text}</div>
      </div>
    </div>
  );
};

const Skeleton = () => (
  <div className="bg-white flex flex-col justify-between no-underline	hover:no-underline rounded-md p-3 border border-gray-200 h-full overflow-hidden">
    <div className="bg-gray-200 w-24 h-4 rounded-md mb-4" />
    <div className="flex flex-row items-center justify-center w-full h-full">
      <div className="bg-gray-200 rounded-full w-20 h-20 mr-2" />
    </div>
  </div>
);

export default MySkills;
