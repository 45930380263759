import { Switch as HLSwitch } from "@headlessui/react";

const Switch = ({ checked, onChange, label }) => {
  return (
    <div className="flex items-center">
      <HLSwitch
        checked={checked}
        onChange={onChange}
        className={`${checked ? "bg-sky-900" : "bg-gray-300"}
      relative inline-flex h-5 w-9 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}>
        <span
          aria-hidden="true"
          className={`${checked ? "translate-x-4" : "translate-x-0"}
        pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </HLSwitch>
      {label && (
        <label className="ml-2 text-sm text-gray-950" onClick={() => onChange(!checked)}>
          {label}
        </label>
      )}
    </div>
  );
};
export default Switch;
