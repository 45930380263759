import React, { useEffect, useState } from "react";

import api from "../services/api";

export default ({
  value,
  onChange,
  className = "w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm",
}) => {
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const res = await api.get("/organisation");
    setOrganisations(res.data);
  }

  return (
    <div>
      <select
        className={className}
        name="organisation"
        value={value || ""}
        onChange={(e) => {
          e.preventDefault();
          const f = organisations.find((f) => e.target.value === f._id);
          onChange(f);
        }}>
        <option disabled selected value="">
          Select organisation
        </option>
        {organisations
          .sort(function (a, b) {
            if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
            if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
            return 0;
          })
          .map((e) => {
            return (
              <option key={e.name} value={e._id}>
                {e.name}
              </option>
            );
          })}
      </select>
    </div>
  );
};
