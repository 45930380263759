import React, { useEffect, useState } from "react";
import api from "../../../services/api";

export default ({ group, month }) => {
  const [data, setData] = useState(null);
  console.log(month);

  useEffect(() => {
    get();
  }, [group, month]);

  async function get() {
    const obj = {};
    if (month) obj.month = month;
    if (group) obj.groupId = group;

    const res = await api.post(`/report/team`, obj);
    setData(res.data);
  }

  if (!data) return <div>Loading</div>;

  // display tile with name and avatar and a progress bar on the projects types
  const Tile = ({ hit }) => {
    // console.log(hit);
    // const total = hit.projects.reduce((acc, a) => a.total + acc, 0);
    return (
      <div className="bg-white rounded shadow p-2 flex flex-col items-center">
        <div className="flex items-center">
          <img src={hit.userAvatar} className="w-8 h-8 rounded-full mr-2" />
          <span className="font-semibold">{`${hit.userName} : ${hit.total.toFixed(0) / 8} days`}</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h1 className="text-2xl font-bold">Team</h1>
      <div className="grid grid-cols-4 gap-4 mt-4">
        {data.map((e) => (
          <Tile key={e.userId} hit={e} />
        ))}
      </div>
    </div>
  );
};
