import React, { useEffect, useState } from "react";

import api from "../../../services/api";

//value should be user ID
export default ({ value, userName, onChange, disabled = false, placeholder = "All users", name = "user", className = "w-[180px]" }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState(userName ?? "");

  useEffect(() => {
    searchUser();
  }, []);

  async function searchUser() {
    const res = await api.post("/user/search", { name: search });
    setUsers(res.data.users);
  }

  return (
    <div>
      {/* <label className="text-[#212325] font-semibold text-[14px] mb-[4px] block">{value.name}</label> */}
      <div>
        <input
          list="user"
          value={search || userName}
          className="min-w-[180px] inline-flex justify-between items-center gap-4 px-2 pr-1 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          placeholder={placeholder}
          onChange={(e) => {
            setSearch(e.target.value);
            if (e.target.value === "all") {
              return onChange();
            }
            const f = users.find((f) => e.target.value === f.name);
            if (f) onChange(f);
            if (!e.target.value) onChange({});
          }}
        />
        <datalist
          id="user"
          name={name}
          disabled={disabled}
          className="w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm"
          value={value && value.name}>
          <option value="" disabled selected>
            Select user
          </option>
          {users
            .sort(function (a, b) {
              if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
              if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
              return 0;
            })
            .map((e) => (
              <option key={e.name} value={e.name} />
            ))}
        </datalist>
      </div>
    </div>
  );
};
