import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../components/loader";
import LoadingButton from "../../components/loadingButton";
import api from "../../services/api";

const ListOrganisation = () => {
  const [organisations, setOrganisations] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data: u } = await api.get("/organisation");
      setOrganisations(u);
    })();
  }, []);

  if (!organisations) return <Loader />;

  return (
    <div>
      {/* Container */}
      <div className="w-screen md:w-full pt-6 px-2 md:px-8">
        <Create />
        <div className="overflow-x-auto bg-[#fff] rounded-[10px]">
          <table className="w-[800px] md:w-full ">
            <thead className="border-b border-[#E5EAEF]">
              <tr className="text-[14px] text-[#212325] font-semibold">
                <th className="py-3 px-3">Organisation name</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              {organisations.map((hit) => {
                return (
                  <tr
                    className="hover:bg-[#f6f6f6] border-b border-[#dee2e6] text-[#676D7C] cursor-pointer text-[14px] font-medium"
                    key={hit._id}
                    onClick={() => navigate(`/organisation/${hit._id}`)}>
                    <td className="py-[10px] px-[15px] min-w-[80px] flex items-center gap-3">
                      <img className="relative z-30 inline object-cover w-[36px] h-[36px] border border-white rounded-full" src={hit.logo}></img>
                      <div>{hit.name}</div>
                    </td>
                    <td className="min-w-[80px]">
                      <LastLogin created_at={hit.created_at} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const LastLogin = ({ created_at }) => {
  const diffInMs = new Date() - new Date(created_at);
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  if (diffInDays < 1) return "Today";
  return `${diffInDays.toFixed(0)} day${diffInDays >= 2 ? "s" : ""} ago`;
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div style={{ marginBottom: 10 }}>
      <div className="text-right">
        <button className="bg-[#0560FD] text-[#fff] py-[10px] px-[22px] inline-block rounded-[10px] text-[16px] font-medium" onClick={() => setOpen(true)}>
          Create new organisation
        </button>
      </div>
      {open ? (
        <div
          className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 "
          onClick={() => {
            setOpen(false);
          }}>
          <div
            className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <Formik
              initialValues={{ name: "", email: "" }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  values.status = "active";
                  const res = await api.post("/organisation", values);
                  if (!res.ok) throw res;
                  toast.success("Created!");
                  navigate(`/organisation/${res.data._id}`);
                  setOpen(false);
                } catch (e) {
                  console.log(e);
                  toast.error("Some Error!", e.code);
                }
                setSubmitting(false);
              }}>
              {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <React.Fragment>
                  <div>
                    {/* first Row */}
                    <div className="flex justify-between flex-wrap">
                      {/* Name */}
                      <div className="w-full md:w-[48%] mt-2">
                        <div className="text-[14px] text-[#212325] font-medium	">Name</div>
                        <input className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]" name="name" value={values.name} onChange={handleChange} />
                      </div>
                      <div className="w-full md:w-[48%] mt-2">
                        <div className="text-[14px] text-[#212325] font-medium	">Email</div>
                        <input className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]" name="email" value={values.email} onChange={handleChange} />
                      </div>
                    </div>
                  </div>

                  <br />
                  <LoadingButton
                    className="mt-[1rem]  bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]"
                    loading={isSubmitting}
                    disabled={!values.name || !values.email}
                    onClick={handleSubmit}>
                    Save
                  </LoadingButton>
                </React.Fragment>
              )}
            </Formik>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ListOrganisation;
