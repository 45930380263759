import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    get();
  }, []);

  const get = async () => {
    try {
      const { data } = await api.get("/data");
      setDatas(data);
    } catch (e) {
      console.log(e);
    }
  };

  if (!datas) return <div>loading</div>;

  return (
    <div className="px-4 pt-2 ">
      <div className="w-full overflow-x-auto bg-[#fff] rounded-[10px] mt-3">
        <Create />
        <table className="w-full min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <tr>
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3">Documents</th>
              <th className="px-6 py-3">Description</th>
              <th className="px-6 py-3">Creator</th>
              <th className="px-6 py-3 whitespace-nowrap">Created At</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 whitespace-nowrap text-sm text-gray-500">
            {datas.map((data) => (
              <tr key={data._id} className="cursor-pointer hover:bg-gray-50" onClick={() => navigate(`/data/view/${data._id}`)}>
                <td className="px-6 py-2">{data.name}</td>
                <td className="px-6 py-2">
                  <span>{spacenumber(data.documents)}</span>
                </td>
                <td className="px-6 py-2">{data.description}</td>
                <td className="px-6 py-2">
                  <div className="flex items-center space-x-2">
                    <span>{data.user_name || "Sebastien Le Goff"}</span>
                  </div>
                </td>
                <td className="px-6 py-2">{new Date(data.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const spacenumber = (number = 0) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  async function onCreate() {
    const res = await api.post("/data", { name: values.name });
    navigate(`/data/view/${res.data._id}`);
  }

  return (
    <>
      <div className="text-right">
        <button className="btn btn-primary" onClick={() => setOpen(true)}>
          Create a data set
        </button>
      </div>
      {open ? (
        <div>
          <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
            <div
              className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <React.Fragment>
                <div>
                  <div className="flex flex-wrap justify-between mt-3">
                    <div className="w-full md:w-[48%] mt-2">
                      <input
                        type="text"
                        className="w-full border border-[#E5E5E5] rounded-[10px] px-[10px] py-[12px] text-[16px] font-medium text-[#000000] placeholder-[#BDBDBD] focus:outline-none"
                        placeholder="Name"
                        value={values.name}
                        onChange={(e) => setValues({ ...values, name: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <button className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onCreate}>
                  Create
                </button>
              </React.Fragment>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
