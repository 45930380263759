import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { MissionIcon } from "../../../assets/Icons";
import { classNames, copyToClipboard } from "../../../utils";
import SelectUser from "../../../components/selectUser";

import api from "../../../services/api";

const MissionCard = () => {
  const { id } = useParams();
  const [mission, setMission] = useState();
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("about");

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/mission_ao/${id}`);
        setMission(data);
      } catch (e) {
        setLoading(false);
        toast.error(e.message);
      }
      setLoading(false);
    })();
  }, [id]);

  if (loading) return <div>chargement</div>;

  //get domain from url
  const domain = mission.link ? mission.link.split("/")[2] : "";

  return (
    <div>
      <div className="w-[90vw] h-[90vh]">
        <div className="flex flex-col w-full h-full bg-white">
          <div className="flex flex-row items-center  p-3 !pr-14">
            <div className="">
              <div className="flex gap-3">
                <div className="h-8 w-8">
                  <MissionIcon />
                </div>
                <div className="flex items-start gap-1">
                  <div>
                    <div className="text-lg font-bold mb-2">{ mission.name || mission.offer_name }</div>
                    <div className="flex items-center space-x-4 ">
                      {/* {mission.buyer && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Buyer: </span>
                          <span className="font-medium text-dull-black">{mission.buyer}</span>
                        </div>
                      )}
                      {mission.city && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">City: </span>
                          <span className="font-medium text-dull-black">{mission.city}</span>
                        </div>
                      )} */}
                      {/* {mission.reference && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Reference: </span>
                          <span className="font-medium text-dull-black">{mission.reference}</span>
                        </div>
                      )}
                      {mission.url && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Link to the offer: </span>
                          <a className="font-medium" href={mission.url} target="_blank">
                            View offer
                          </a>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full ml-5 my-1">
            <div>
              {(mission.sectors || []).map((sector) => (
                <span key={sector} className="bg-blue-100 text-blue-600 px-1 mr-2 text-xs">
                  {sector}
                </span>
              ))}
            </div>
          </div>
          <div className="flex items-center text-sm space-x-1 ml-5 my-1">
            <span className="text-dull-grey">Estimated closing date: </span>
            <span className="font-medium text-dull-black">{mission.closure_date ? new Date(mission.closure_date).toLocaleString() : <></>}</span>
          </div>
          <nav className="flex flex-row w-full bg-gray-50 border border-gray-100">
            <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} />
            {user.role === "admin" && <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />}
          </nav>
          <div className="p-4">
            {tab === "about" && <About mission={mission} />}
            {tab === "raw" && user.role === "admin" && <Raw mission={mission} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const About = ({ mission }) => {
  return (
    <div className="w-3/4">
      <div>
        <div className="mb-10 p-2 leading-7 bg-lighter-grey whitespace-pre-line">{mission.description ? mission.description : "Pas de description pour le moment..."}</div>
      </div>
    </div>
  );
};

const Raw = ({ mission }) => (
  <div>
    <pre className="text-xs bg-gray-800 text-gray-100 rounded p-2">{JSON.stringify(mission, null, 2)}</pre>
  </div>
);

const TabItem = ({ tab, title, setTab, active }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames("px-4 py-2 cursor-pointer text-sm text-gray-600 hover:text-black", active ? "text-black border-b-2 border-blue-400" : "")}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);

export default MissionCard;
