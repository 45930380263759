import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import { setUser } from "../../redux/auth/actions";
import api from "../../services/api";
import Loader from "../../components/loader";

const MagicLink = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!searchParams.has("magic_token")) return;

        //http://localhost:8083/auth/magic-link/%22/project/667d47f7511f380710c1860c%22

        const redirect = searchParams.get("redirect") || "/";
        console.log(`redirect: ${redirect}`)

        const { user, token } = await api.post(`/user/magic-link`, { magic_token: searchParams.get("magic_token") });
        if (token) api.setToken(token);
        if (user) {
          dispatch(setUser(user));
          navigate(redirect);
        } else {
          toast.error("Error while signing in");
          navigate("/auth");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error while signing in");
        navigate("/auth");
      }
    };
    fetchData();
  }, []);

  return <Loader />;
};

export default MagicLink;
