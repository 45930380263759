const Logs = ({ bank }) => {
  if ((bank.logs || []).length == 0)
    return (
      <div>
        <div className="text-sm text-gray-400">No logs registered</div>
      </div>
    );

  return (
    <div className="space-y-2 h-96 overflow-y-scroll">
      <ul className="list-none">
        {(bank.logs || [])
          .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
          .map((e, i) => {
            return (
              <li key={i} className="flex items-center justify-between px-4 py-2 gap-4 w-full">
                <div className="w-1/5 text-sm">
                  <p className="font-semibold">{e.user_name}</p>
                  <p>{new Date(e.created_at).toLocaleString("fr")}</p>
                </div>
                <div className="flex-1 text-sm">{e.text}</div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Logs;
