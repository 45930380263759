import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Loader from "../../../components/loader";

import api from "../../../services/api";
import Pagination from "./Pagination";
import SelectProjects from "./components/SelectProjects";
import SelectStatuses from "./components/SelectStatuses";
import { HiMagnifyingGlass } from "react-icons/hi2";
import DebounceInput from "react-debounce-input";

import BankModal from "../../../components/bankModal";

export default History = ({ invoice, setInvoice }) => {
  const [banks, setBanks] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState();
  const [selectedBank, setSelectedBank] = useState();

  useEffect(() => {
    load();
  }, [filter]);

  React.useEffect(() => {
    (async () => {
      const _filters = { status: "ALL", page: 1, per_page: 25 };
      setFilter(_filters);
    })();
  }, []);

  async function load() {
    try {
      setLoading(true);
      if (!invoice || !filter) return;
      const newFilter = { ...filter };
      console.log("newFilter", newFilter);
      if (newFilter.status === "ALL") delete newFilter.status;
      if (filter.projects) newFilter.project_ids = filter.projects.map((e) => e.value);
      if (filter.statuses) newFilter.statuses = filter.statuses.map((e) => e.value);

      const { data } = await api.post(`/bank/search`, { ...newFilter, sub_category: "INVOICE" });
      setLoading(false);
      setBanks(data.banks);
      setTotal(data.total);
    } catch (e) {
      setLoading(false);
      console.log("a", e);
    }
  }

  if (!invoice || !banks) return <Loader />;

  const tot = (invoice.banks || []).reduce((acc, bank) => acc + bank.amount, 0);

  return (
    <div>
      <BankModal bank={selectedBank} setBank={setSelectedBank} />
      <div className="p-2">
        <div>
          <h1 className="text-[18px] font-semibold text-[#000000] mt-2 mb-1">{`Attached payments / Total to match ${invoice.total}€`}</h1>
          <table className="md:w-full table-auto">
            <thead className="border-t border-b-2	 border-t-[#dee2e6] border-l-0 border-r-0 border-b-[#dee2e6]">
              <tr>
                <th className="">Date</th>
                <th>Name</th>
                <th>Project</th>
                <th>Amount</th>
                <th>Days</th>
                <th>Action</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(invoice.banks || []).map((hit, index) => {
                return (
                  <tr key={hit._id} className="hover:bg-[#dee2e6] border-b border-[#dee2e6]">
                    <td>{hit.date.slice(0, 10)}</td>
                    <td>{hit.name?.substring(0, 20)}</td>
                    <td>{hit.projectName}</td>
                    <td>{hit.amount}</td>
                    <td>{`${daysCount(hit.date, invoice.date)} days`}</td>
                    <td>
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white my-1 px-1"
                        onClick={async () => {
                          const { data } = await api.get(`/bank/${hit.id}`);
                          setSelectedBank(data);
                        }}>
                        View
                      </button>
                    </td>
                    <td>
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white my-1 px-1"
                        onClick={async () => {
                          let banks = invoice.banks || [];
                          banks = banks.filter((f) => f.id !== hit.id);
                          const { data } = await api.put(`/invoice/${invoice._id}`, { banks });

                          const { data: bank } = await api.get(`/bank/${hit.id}`);
                          let invoices = bank.invoices || [];
                          invoices = invoices.filter((f) => f.id !== invoice._id);
                          await api.put(`/bank/${hit.id}`, { invoices });

                          setInvoice(data);
                          toast.success("Updated!");
                        }}>
                        UnAttached
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr key={"key"} className="hover:bg-[#dee2e6] border-b border-[#dee2e6]">
                <td>TOTAL</td>
                <td></td>
                <td></td>
                <td>{tot}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="my-4" />
        <hr />
        <h1 className="text-[18px] font-semibold text-[#000000] mt-7  mb-1">All payments</h1>
        <div className="flex items-center flex-wrap gap-1">
          <div className="flex flex-1 gap-1 items-center">
            <div className="relative rounded-md shadow-sm w-full">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <DebounceInput
                debounceTimeout={300}
                className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                placeholder="Search (name, description)"
                value={filter.search}
                onChange={(e) => {
                  e.persist();
                  setFilter((f) => ({ ...f, search: e.target.value, page: 1 }));
                }}
              />
            </div>
          </div>
          <div className="flex items-center flex-wrap flex-1 gap-1">
            <SelectProjects value={filter.projects} onChange={(e) => setFilter((f) => ({ ...f, projects: e }))} />
            <SelectStatuses values={filter.statuses} onChange={(e) => setFilter((f) => ({ ...f, statuses: e }))} />
          </div>
        </div>
        <div className="overflow-x-auto bg-white mt-2">
          <table className="md:w-full table-auto">
            <thead className="border-t border-b-2	 border-t-[#dee2e6] border-l-0 border-r-0 border-b-[#dee2e6]">
              <tr>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Date
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Name
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Project
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Amount
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Type
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Category
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Project
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Country
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Tax
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Status
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>Loading...</tr>
              ) : (
                (banks || []).map((hit, index) => {
                  return (
                    <tr key={hit._id} className="hover:bg-[#dee2e6] border-b border-[#dee2e6]">
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div>
                          <div>{hit.date.slice(0, 10)}</div>
                          <div className="text-gray-400 text-xs">created at {new Date(hit.created_at).toLocaleString()}</div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div>{hit.name?.substring(0, 20)}</div>
                        <div className="text-gray-400 text-xs">{hit.description?.substring(0, 20)}...</div>
                      </td>
                      <div>{hit.projectName}</div>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div className="flex items-center gap-1">
                          <Amount amount={hit.amount} />
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.type}</td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.category}</td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.projectName}</td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.country}</td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{`${(hit.tax || 0).toFixed()}€`}</td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <Status status={hit.status} />
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <a href={`/bank/${hit._id}`} target="_blank">
                          <button className="bg-blue-500 hover:bg-blue-700 text-white my-1 px-1">open</button>
                        </a>
                      </td>
                      <td>
                        <button
                          className="bg-red-500 hover:bg-red-700 text-white my-1 px-1"
                          onClick={async () => {
                            const banks = invoice.banks || [];
                            banks.push({ id: hit._id, amount: hit.amount, name: hit.name, date: hit.date });
                            const { data } = await api.put(`/invoice/${invoice._id}`, { banks, receivedAt: hit.date });
                            setInvoice(data);

                            const bank = await api.get(`/bank/${hit._id}`);
                            const invoices = bank.data.invoices || [];
                            invoices.push({ id: invoice._id, amount: invoice.total, name: invoice.name, date: invoice.date });
                            await api.put(`/bank/${hit._id}`, { invoices });

                            toast.success("Updated!");
                          }}>
                          Attach
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          per_page={filter.per_page}
          total={total}
          onNext={() => setFilter((f) => ({ ...f, page: +f.page + 1 }))}
          onPrevious={() => setFilter((f) => ({ ...f, page: +f.page - 1 }))}
          currentPage={filter.page}
        />
      </div>
    </div>
  );
};

function daysCount(date1, date2) {
  const diffTime = new Date(date1) - new Date(date2);
  const diffDays = diffTime / (1000 * 60 * 60 * 24);
  return diffDays;
}

const Amount = ({ amount }) => {
  let color = amount > 0 ? "bg-green-50 text-green-700 border-[1px] border-green-100" : "bg-red-50 text-red-700 border-[1px] border-red-100";
  return <div className={`px-2 py-1 rounded-sm flex justify-center items-center text-sm font-bold ${color}`}>{amount}€</div>;
};

const Status = ({ status }) => {
  const selected = status === "DONE";
  let color = selected ? "bg-[#28a745]" : "bg-[#dc3545]";
  return <div className={`w-16 mt-[1px] h-4 flex justify-center items-center text-[#fff] text-[75%] font-bold ${color}`}>{status}</div>;
};
