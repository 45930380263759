import React, { useState } from "react";
import Project from "./project";
import Folk from "./folk";

const TABS = {
  PROJECT: "PROJECT",
  FOLK: "FOLK",
};

export default () => {
  const [tab, setTab] = useState(TABS.PROJECT);

  return (
    <div>
      <div className="m-2">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px list-none space-x-2">
            <li
              onClick={(e) => setTab(TABS.PROJECT)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${
                tab === TABS.PROJECT && "border-primary"
              }`}>
              Project
            </li>
            <li
              onClick={(e) => setTab(TABS.FOLK)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${
                tab === TABS.NEXT && "border-primary"
              }`}>
              Folk
            </li>
          </ul>
        </div>
        {tab === TABS.PROJECT && <Project />}
        {tab === TABS.FOLK && <Folk />}
      </div>
    </div>
  );
};
