import React, { useState } from "react";
import { toast } from "react-hot-toast";

import Modal from "../../components/modal";
import api from "../../services/api";

const TABS = {
  EDIT: "Edit",
  RAW: "Raw",
};

export default ({ user, onClose }) => {
  const [tab, setTab] = useState(TABS.EDIT);

  return (
    <>
      <Modal isOpen={!!user} onClose={onClose} className="flex flex-col w-full md:w-[60%] min-h-full bg-white justify-center">
        <div className="overflow-auto p-[25px]">
          <div className="flex justify-between items-center">
            <div className="text-sm mb-4 font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
              <ul className="flex flex-wrap -mb-px list-none space-x-2">
                <li
                  onClick={(e) => setTab(TABS.EDIT)}
                  className={`inline-block p-2 rounded-t-lg border-b-2 hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${
                    tab === TABS.EDIT ? "border-primary" : "border-transparent"
                  }`}>
                  Edit
                </li>
                <li
                  onClick={(e) => setTab(TABS.RAW)}
                  className={`inline-block p-2 rounded-t-lg border-b-2 hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${
                    tab === TABS.RAW ? "border-primary" : "border-transparent"
                  }`}>
                  Raw
                </li>
              </ul>
            </div>
          </div>
          {tab === TABS.EDIT && <EditForm user={user} />}
          {tab === TABS.RAW && <Raw item={user} />}
        </div>
      </Modal>
    </>
  );
};

const EditForm = ({ user }) => {
  const [values, setValues] = useState(user);

  async function onEdit() {
    const { data, ok } = await api.put(`/findyourcofounder_user/${user._id}`, values);
    if (!ok) {
      console.log(data);
      return toast.error("Error editing user");
    } else {
      toast.success("User updated");
    }
  }

  async function onDelete() {
    if(!confirm("Are you sure?")) return;
    const { ok } = await api.remove(`/findyourcofounder_user/${user._id}`, values);
    if (ok) toast.success("User deleted!");
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block font-medium text-gray-600 mb-1">First name</label>
          <input type="text" className="input" value={values.first_name} onChange={(e) => setValues({ ...values, first_name: e.target.value })} />
        </div>
        <div>
          <label className="block font-medium text-gray-600 mb-1">Last name</label>
          <input type="text" className="input" value={values.last_name} onChange={(e) => setValues({ ...values, last_name: e.target.value })} />
        </div>
        <div>
          <label className="block font-medium text-gray-600 mb-1">App country</label>
          <select className="input" value={values.app_country} onChange={(e) => setValues({ ...values, app_country: e.target.value })}>
            <option value="">Select</option>
            <option value="nl">NL</option>
            <option value="es">ES</option>
          </select>
        </div>
        <div>
          <label className="block font-medium text-gray-600 mb-1">Email</label>
          <input type="text" className="input" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
        </div>
        <div>
          <label className="block font-medium text-gray-600 mb-1">City</label>
          <input type="text" className="input" value={values.city} onChange={(e) => setValues({ ...values, city: e.target.value })} />
        </div>
        <div>
          <label className="block font-medium text-gray-600 mb-1">Invest</label>
          <input type="number" className="input" value={values.invest} onChange={(e) => setValues({ ...values, invest: e.target.value })} />
        </div>
        <div>
          <label className="block font-medium text-gray-600 mb-1">Linkedin</label>
          <input type="text" className="input" value={values.linkedin} onChange={(e) => setValues({ ...values, linkedin: e.target.value })} />
        </div>
        <div className="col-span-2">
          <label className="block font-medium text-gray-600 mb-1">Motivations</label>
          <textarea className="input" value={values.motivations} onChange={(e) => setValues({ ...values, motivations: e.target.value })} />
        </div>
        <div className="col-span-2">
          <label className="block font-medium text-gray-600 mb-1">Business</label>
          <textarea className="input" value={values.business} onChange={(e) => setValues({ ...values, business: e.target.value })} />
        </div>
        <div className="col-span-2">
          <label className="block font-medium text-gray-600 mb-1">Partner</label>
          <textarea className="input" value={values.partner} onChange={(e) => setValues({ ...values, partner: e.target.value })} />
        </div>
        <div className="flex flex-row items-center gap-2 mb-4">
          <input
            id="approved"
            type="checkbox"
            placeholder="Approved?"
            className=""
            checked={values.approved}
            onChange={(e) => setValues({ ...values, approved: e.target.checked })}
          />
          <label htmlFor="approved" className="block font-medium text-gray-600 mb-1">
            Approved
          </label>
        </div>
      </div>
      <div className="space-x-3">
        <button className="btn btn-primary" onClick={onEdit}>
          Update
        </button>
        <button className="btn red-btn" onClick={onDelete}>
          Delete
        </button>
      </div>
    </>
  );
};

const Raw = ({ item }) => {
  return (
    <div className="mt-2">
      <div className="p-2">
        <pre className="text-xs bg-gray-800 text-gray-100 rounded p-2">{JSON.stringify(item, null, 2)}</pre>
      </div>
    </div>
  );
};
