import React from "react";

export default function DayTile({ date, showWeekDay }) {
  const d = new Date(date);
  const monthNum = d.getMonth();
  const dayNum = d.getDate();
  const yearNum = d.getFullYear();
  const day = d.getDay();
  const currentYear = new Date().getFullYear();
  const monthString = ["jan", "feb", "mar", "apr", "may", "june", "july", "aug", "sept", "oct", "nov", "dec"][monthNum];
  const dayString = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][day];

  return (
    <div className="bg-white rounded-lg shadow-sm flex flex-col items-center justify-around w-11 h-11 p-1">
      <div className="text-[10px] text-[#EC6316] font-medium uppercase leading-3">{monthString}</div>
      <div className="text-[19px] text-[#3F444A] font-bold leading-4">{dayNum}</div>
      {showWeekDay ? <div className="text-[9px] text-[#3F444A] font-medium uppercase leading-3">{dayString}</div> : null}
      {currentYear !== yearNum ? <div className="text-[9px] text-[#3F444A] font-medium uppercase leading-3">{yearNum}</div> : null}
    </div>
  );
}
