import React, { useEffect, useRef, useState, Fragment } from "react";

import api from "../../services/api";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { HiX } from "react-icons/hi";
import { DebounceInput } from "react-debounce-input";

export default ({ setQuestionModalId }) => {
  const [questions, setQuestions] = useState(null);
  const [filters, setFilters] = useState({ search: "" });

  useEffect(() => {
    get();
  }, [filters]);

  async function get() {
    const { data } = await api.post(`/skill_question/search`, { skillCategory: filters.skillCategory, search: filters.search });
    setQuestions(data);
  }

  return (
    <div className="mx-auto mb-4">
      <div className="flex gap-2 flex-1 space-y-1 py-2">
        <DebounceInput
          debounce={300}
          className="input flex-1"
          placeholder="Search..."
          value={filters.search}
          onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value }))}
        />
        <select onChange={(e) => setFilters({ skillCategory: e.target.value })}>
          <option value="">-- Category --</option>
          <option value="Code">Code</option>
          <option value="Communication">Communication</option>
          <option value="Leadership">Leadership</option>
          <option value="Product">Product</option>
        </select>
        <ModalCreate callback={get} setQuestionModalId={setQuestionModalId} />
      </div>
      <table>
        <thead>
          <tr>
            {/* <th>id</th> */}
            <th>Category</th>
            <th>Sub cat.</th>
            <th>lvl</th>
            <th>Question</th>
          </tr>
        </thead>
        <tbody>
          {questions?.map((question) => {
            return <Question question={question} setQuestionModalId={setQuestionModalId} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

const Question = ({ question, setQuestionModalId }) => {
  return (
    <tr className="odd:bg-gray-100">
      {/* <td className="py-1 px-1">
        <div className="text-xs">{question.skillId}</div>
      </td> */}
      <td className="py-1 px-1">
        <div>{CATEGORIES[question.skillCategory] || question.skillCategory}</div>
      </td>
      <td className="py-1 px-1">
        <div>{question.skillSubCategory}</div>
      </td>
      <td className="py-1 px-1">
        <div>{question.level}</div>
      </td>
      <td className="py-1 px-1">
        <button onClick={() => setQuestionModalId(question._id)} className="hover:underline">
          <p className="">{question.title}</p>
        </button>
      </td>
    </tr>
  );
};

const CATEGORIES = {
  Code: <span className="text-sm px-2 border border-blue-300 bg-blue-100 text-blue-800 rounded-sm">Code</span>,
  Communication: <span className="text-sm px-2 border border-green-300 bg-green-100 text-green-800 rounded-sm">Communication</span>,
  Leadership: <span className="text-sm px-2 border border-purple-300 bg-purple-100 text-purple-800 rounded-sm">Leadership</span>,
  Product: <span className="text-sm px-2 border border-orange-300 bg-orange-100 text-orange-800 rounded-sm">Product</span>,
};

const ModalCreate = ({ callback, setQuestionModalId }) => {
  const cancelButtonRef = useRef();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [title, setTitle] = useState("");

  if (!open)
    return (
      <button className="px-5 py-1 bg-sky-700 text-white" onClick={() => setOpen(true)}>
        Create
      </button>
    );

  async function create() {
    try {
      if (!title) return toast.error("missing fields");
      const { data } = await api.post(`/skill_question`, {
        title,
        options: [
          { title: "Yes", levelValue: 1 },
          { title: "No", levelValue: 0 },
        ],
      });
      console.log(data);
      callback?.();
      setQuestionModalId(data._id);
    } catch (e) {
      console.log("a", e);
    }
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-30 backdrop-blur-sm"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={() => {
          setOpen(false);
        }}>
        <div className="min-h-screen md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className={`bg-white rounded-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-h-screen max-w-xl w-full p-6 overflow-auto`}>
              <div className="absolute top-2 right-2 ">
                <button className="sr-only" ref={cancelButtonRef} />
                <HiX
                  className={`text-xl text-gray-500 transition-colors  hover:text-red-500`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(false);
                  }}
                />
              </div>
              <div className="pt-2">
                <input className="w-full input" placeholder="title" onChange={(e) => setTitle(e.target.value)}></input>
                <button className="blue-btn" onClick={create}>
                  Create
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
