import React from "react";
import MultiSelect from "../../../components/MultiSelect";

export const CARDS = [
  { value: "1397223", label: "Bunq Account" },
  { value: "3995515", label: "Seb 1" },
  { value: "3995524", label: "Fab" },
  { value: "3995520", label: "Max" },
  { value: "4506172", label: "Théo" },
  { value: "4506175", label: "Hugo L" },
  { value: "4690301", label: "Docloop" },
  { value: "4746846", label: "Fridgee" },
  { value: "4747857", label: "Growth" },
  { value: "4969349", label: "FKM" },
  { value: "4964474", label: "Seb 2" },
  { value: "4992148", label: "Expenses" },
  { value: "", label: "Unknown" },
];

export default ({ values = [], onChange }) => {
  return <MultiSelect values={values} id="select-cards" options={CARDS} onSelectedChange={onChange} placeholder="Select a card" />;
};
