import React, { useEffect, useState } from "react";

export default ({ bank }) => {
  // console.log(`user.resume`, user.resume);

  const arr = [];
  for (let i = 0; i < bank.files.length; i++) {
    const file = bank.files[i];
    if (file.indexOf(".pdf") !== -1) {
      arr.push(
        <object data={file} type="application/pdf" width="100%" height="900px">
          <p>
            Alternative text - include a link <a href={file}>to the PDF!</a>
          </p>
        </object>,
      );
    } else {
      arr.push(<img src={file} width="100%" height="900px" />);
    }
  }

  console.log(`arr`, arr);
  return <div>{arr}</div>;
};
