import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import FileInput from "../../components/FileInput";

import api from "../../services/api";
import { classNames } from "../../utils";

export default ({}) => {
  const [component, setComponent] = useState(null);
  const [tab, setTab] = useState("edit");

  const { id } = useParams();

  useEffect(() => {
    refetch();
  }, []);

  async function refetch() {
    const { data } = await api.get(`/uxui2/${id}`);
    setComponent(data);
  }

  if (!component) return <div />;

  return (
    <div>
      <div className="flex-shrink-0 w-full overflow-x-scroll">
        <TabItem tab="edit" title="Edit" active={tab === "edit"} setTab={setTab} />
        <TabItem tab="raw" title="Raw" active={tab === "raw"} setTab={setTab} />
      </div>
      <div className="flex-1 overflow-y-auto bg-white">
        {tab === "edit" && <Edit component={component} setComponent={setComponent} refetch={refetch} />}
        {tab === "raw" && <Raw component={component} />}
      </div>
    </div>
  );
};

const Raw = ({ component }) => {
  return (
    <div className="p-4">
      <pre className="text-sm text-gray-500">{JSON.stringify(component, null, 2)}</pre>
    </div>
  );
};

const Edit = ({ component, setComponent, refetch }) => {
  const [values, setValues] = useState(component);

  const onSave = async (id) => {
    const { ok, data } = await api.put(`/uxui2/${id}`, { ...values });
    console.log(data);
    if (!ok) return toast.error("Failed to update component!");
    setValues(data);
    setComponent(data);
    refetch();
    toast.success("Component updated!");
  };

  return (
    <div className="space-y-4 p-4 ">
      <div className="flex flex-col gap-4">
        <label className="text-sm text-gray-500">Name</label>
        <input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} className="input" type="text" />
      </div>
      <div className="flex flex-col gap-4">
        <label className="text-sm text-gray-500">Description</label>
        <textarea value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} rows={10} className="input" type="text" />
      </div>
      <div className="flex flex-col gap-4">
        <label className="text-sm text-gray-500">Files</label>
        <FileInput value={values.images} onChange={(e) => setValues({ ...values, images: e.target.value })} folder="uxui2/" />
      </div>

      <div className="flex flex-col gap-4">
        <button
          onClick={async () => {
            const { ok } = await api.put(`/uxui2/${component._id}`, values);
            if (!ok) {
              toast.error("Failed to save");
              return;
            }
            setComponent({ ...component, ...values });
            toast.success("Saved!");
            onSave(component._id);
            setIsOpen(false);
          }}
          className="btn btn-primary w-full p-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-md">
          Save
        </button>

        <button
          className="btn btn-primary mt-4 bg-red-500 hover:bg-red-600"
          onClick={async (e) => {
            e.stopPropagation();
            if (!window.confirm("Are you sure you want to delete this component?")) return;
            const { ok } = await api.remove(`/uxui/${component._id}`);
            if (!ok) return toast.error("Failed to delete component");
            toast.success("Component deletetd!");
            setComponent(null);
            refetch();
          }}>
          Delete
        </button>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 ",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
