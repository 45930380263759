import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

import Modal from "../../components/modal";
import Table from "../../components/Table";
import MultiSelect from "../../components/MultiSelect";
import Pagination from "../../components/Paginator";
import DebounceInput from "../../components/DebounceInput";

import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState();

  const [filter, setFilter] = useState({ page: 1, pageSize: 50, search: "", categories: [] });

  useEffect(() => {
    get();
  }, [filter]);

  async function get() {
    try {
      const { data, total } = await api.post("/jobchannel/search", { ...filter });
      setItems(data);
      setTotal(total);
    } catch (error) {
      toast.error("Failed to fetch channels");
    }
  }

  if (!items) return <div className="p-3 text-sm">Loading...</div>;

  return (
    <div>
      <div className="p-4">
        <div className="rounded-[10px] mt-3">
          <div className="flex gap-4 mb-2">
            <DebounceInput
              debounce={300}
              className="input w-1/3"
              placeholder="Search..."
              value={filter.search}
              onChange={(e) => setFilter({ ...filter, search: e.target.value, page: 1 })}
            />
            <MultiSelect
              id="select-category"
              options={["SCHOOL", "COMMUNITY", "JOBBOARD", "FREELANCE"].map((priority) => ({ value: priority, label: priority }))}
              onSelectedChange={(e) => setFilter((f) => ({ ...f, categories: e.map((e) => e.value) }))}
              placeholder="Category"
            />
            <Create />
          </div>
          <Table
            header={[
              { title: "Name", key: "name" },
              { title: "Category", key: "category" },
              { title: "Score", key: "score" },
            ]}
            total={items.length}>
            {items.map((hit) => (
              <tr
                className="hover:bg-[#f6f6f6] border-b border-[#dee2e6] text-[#676D7C] cursor-pointer text-[14px] font-medium"
                key={hit._id}
                onClick={() => navigate(`/job/channel/${hit._id}`)}>
                <td className="py-3 px-5">{hit.name}</td>
                <td className="py-3 px-5">{hit.category}</td>
                <td className="py-3 px-5">{hit.score}</td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
      <div className="flex w-full my-5 mb-10">
        <Pagination page={filter.page} setPage={(page) => setFilter({ ...filter, page })} last={Math.ceil(total / filter.pageSize)} />
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  const navigate = useNavigate();

  async function onCreate() {
    const res = await api.post("/jobchannel", values);
    toast.success("Created!");
    navigate(`/jobchannel/${res.data._id}`);
  }
  return (
    <div style={{ marginBottom: 10 }}>
      <div className="text-right">
        <button className="btn btn-primary" onClick={() => setOpen(true)}>
          Create a channel
        </button>
      </div>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Channel (*)</div>
          <input className="projectsInput" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>

        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
