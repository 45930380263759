import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Table from "../../../components/Table";
import Paginator from "../../../components/Paginator";
import { useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { toast } from "react-hot-toast";
import SelectUsers from "../../../components/selectUser";
import DebounceInput from "../../../components/DebounceInput";

export default () => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({ page: 1, per_page: 10 });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    try {
      if (loading) return;
      setLoading(true);
      const newFilters = {};
      if (filters.user) newFilters.leader_id = filters?.user?._id;
      if (filters.search) newFilters.search = filters.search;
      if (filters.page) newFilters.page = filters.page;
      if (filters.per_page) newFilters.per_page = filters.per_page;
      if (filters.status) newFilters.status = filters.status;
      if (filters.priority) newFilters.priority = filters.priority;
      if (filters.label) newFilters.label = filters.label;
      newFilters.type = "ticket";

      const res = await api.post("/ticket/search", newFilters);

      setData(res.data);
      setTotal(res.total);
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setLoading(false);
    }
  }

  const daysAgo = (date) => {
    const today = new Date();
    const diff = today - new Date(date);
    return Math.floor(diff / (1000 * 60 * 60 * 24));
  };

  const inDays = (date) => {
    const today = new Date();
    const diff = new Date(date) - today;
    return Math.floor(diff / (1000 * 60 * 60 * 24));
  };

  const PriorityTag = ({ priority }) => {
    if (!priority) return null;
    const colors = {
      LOW: "bg-green-100 text-xs text-green-800",
      MEDIUM: "bg-yellow-100 text-xs text-yellow-800",
      HIGH: "bg-red-100 text-xs text-red-800",
    };
    return <div className={`px-2 py-1 rounded ${colors[priority]}`}>{priority}</div>;
  };

  const CustomLabel = ({ label }) => {
    if (!label) return null;

    const SUPPORT_LABEL_COLORS = {
      CONTRACT: "bg-indigo-300 text-xs text-indigo-800",
      INVOICE: "bg-teal-300 text-xs text-teal-800",
      PAYMENT: "bg-black text-xs text-white",
      ADMIN: "bg-violet-200 text-xs text-violet-800",
      FINANCE: "bg-pink-200 text-xs text-pink-800",
      RECRUITMENT: "bg-yellow-200 text-xs text-yellow-800",
      TOOLS: "bg-blue-200 text-xs text-blue-800",
    };

    return <div className={`px-2 py-1 rounded ${SUPPORT_LABEL_COLORS[label]}`}>{label}</div>;
  };

  const openInNewTab = (id) => {
    const url = `/message/${id}`;
    window.open(url, "_blank");
  };

  if (!user) return <Loader />;

  return (
    <div className="bg-white h-full">
      <Filters setFilters={setFilters} filters={filters} />
      <div>{total} items</div>
      <Table
        total={total}
        header={[
          { title: "Status", key: "status" },
          { title: "Index", key: "index" },
          { title: "Priority/Type", key: "priority" },
          { title: "Title", key: "title" },
          { title: "Last Message" },
          { title: "Time", key: "time" },
        ]}
        loading={loading}
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {data.map((ticket, index) => {
          const me = ticket.people.find((e) => e.user_id === user._id);
          const lastMessage = ticket.messages[ticket.messages.length - 1];
          const unread = lastMessage && lastMessage.user_id !== user._id ? me?.unread : 0;
          const lastMessageText = lastMessage ? lastMessage.text : "";

          return (
            <tr className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer hover:bg-gray-200`} key={ticket._id} onClick={() => openInNewTab(ticket._id)}>
              <td className="border  px-4 py-2 max-w-xs truncate">{ticket.status}</td>
              <td className="border  px-4 py-2 max-w-xs truncate">
                {ticket.index}
                {unread && unread > 0 ? (
                  <div className="border border-red-500 rounded-full bg-red-500 text-xs text-white w-5 h-5 flex items-center justify-center mr-2">{unread}</div>
                ) : null}
              </td>
              <td className="px-4 py-2 max-w-xs truncate flex flex-row gap-2">
                <PriorityTag priority={ticket.priority} />
                <CustomLabel label={ticket.label} />
              </td>
              <td className="border  px-4 py-2 max-w-xs truncate">{ticket.title}</td>
              <td className="border  px-4 py-2 max-w-xs truncate">{lastMessageText}</td>
              <td className="px-4 py-2 max-w-xs truncate flex gap-2">
                {`${daysAgo(ticket.created_at)} days ago`}
                {ticket.end_at && (
                  <span>
                    {inDays(ticket.end_at) < 0 ? (
                      <span className="text-red-500">{`${Math.abs(inDays(ticket.end_at))} days late`}</span>
                    ) : inDays(ticket.end_at) < 2 ? (
                      <span className="text-orange-500">{`due in ${inDays(ticket.end_at)} days`}</span>
                    ) : (
                      <span className="text-green-500">{`due in ${inDays(ticket.end_at)} days`}</span>
                    )}
                  </span>
                )}
              </td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.per_page)} />
      </div>
    </div>
  );
};

const Filters = ({ filters, setFilters }) => {

  console.log("filters", filters);

  return (
    <div>
      <DebounceInput
        debounceTimeout={300}
        name="search"
        id="search"
        className="block w-[300px] rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
        placeholder="Search"
        value={filters.search}
        onChange={(e) => {
          setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
        }}
      />
      <div className="p-2 flex items-start space-x-4">
        <div>
          <div className="px-1 text-sm text-gray-600 font-medium">{"Lead"}</div>
          <SelectUsers
            value={filters.user}
            onChange={(v) => setFilters({ ...filters, user: v })}
          />
        </div>
        <div className="px-1 text-sm text-gray-600 font-medium">
          {"Status"}
          <select
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            name="status"
            value={filters.status || ""}
            className="w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
            <option value="">All</option>
            <option value="TODO">To Do</option>
            <option value="INPROGRESS">In Progress</option>
            <option value="AWAITING_FEEDBACK">Awaiting Feedback</option>
            <option value="CLOSE">Closed</option>
          </select>
        </div>
        <div className="px-1 text-sm text-gray-600 font-medium">
          {"Priority"}
          <select
            name="priority"
            onChange={(e) => setFilters({ ...filters, priority: e.target.value })}
            value={filters.priority || ""}
            className="w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
            <option value="">All Priorities</option>
            <option value="LOW">Low</option>
            <option value="MEDIUM">Medium</option>
            <option value="HIGH">High</option>
          </select>
        </div>
        <div className="px-1 text-sm text-gray-600 font-medium">
          {"Label"}
          <select
            name="label"
            onChange={(e) => setFilters({ ...filters, label: e.target.value })}
            value={filters.label || ""}
            className="w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
            <option value="">All Labels</option>
            <option value="CONTRACT">Contract</option>
            <option value="INVOICE">Invoice</option>
            <option value="PAYMENT">Payment</option>
            <option value="ADMIN">Admin</option>
            <option value="FINANCE">Finance</option>
            <option value="RECRUITMENT">Recruitment</option>
            <option value="TOOLS">Tools</option>
          </select>
        </div>
      </div>
    </div>
  );
};
