import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../../../../components/modal";
import api from "../../../../../services/api";

import RichTextEditorSun from "../../../../../components/RichTextEditor";

const STEPS = {
  FORM: "form",
  EXPLAINATION: "explaination",
  LOADING: "loading",
};

export default function ({ projectId, onDone }) {
  const [step, setStep] = useState(STEPS.FORM);
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState({ description: "", multiTicket: false });

  const { search } = useLocation();

  const navigate = useNavigate();

  const handleCreate = async () => {
    setStep(STEPS.LOADING);
    const nf = { ...value, project_id: projectId };
    const { data } = await api.post("/notion", nf);
    setValue({ description: "", multiTicket: false });
    const query = new URLSearchParams(search);
    query.set("task_modal_id", data[0]._id);
    navigate({ search: query.toString() });
    onDone();
  };

  return (
    <div>
      <button className="blue-btn mr-2" onClick={() => setOpenModal(true)}>
        New ticket
      </button>
      {openModal && (
        <Modal
          isOpen={true}
          onClose={() => {
            setStep(STEPS.FORM);
            setOpenModal(false);
          }}>
          {step === STEPS.FORM && <FormChoice onCreate={handleCreate} value={value} setValue={setValue} />}
          {step === STEPS.LOADING && <Loading />}
        </Modal>
      )}
    </div>
  );
}

const Loading = () => {
  return (
    <div className="flex flex-col gap-2 items-center justify-center max-w-lg mx-auto p-3">
      <div className="mb-2">
        <p>Please wait...</p>
      </div>
    </div>
  );
};


const FormChoice = ({ value, setValue, onCreate }) => {
  return (
    <div className="flex flex-col gap-2 items-center justify-center max-w-lg mx-auto p-3">
      <h3 className="mb-1">Create new task</h3>
      <RichTextEditorSun
        buttonList={[
          ["font", "fontSize", "formatBlock"],
          ["bold", "underline", "italic"],
          ["fontColor", "hiliteColor"],
          ["align", "list", "lineHeight"],
          ["outdent", "indent"],
          ["link", "image", "video"],
          ["removeFormat"],
        ]}
        values={value.description}
        options={{ minHeight: "180px" }}
        onChange={(e) => setValue((prevValue) => ({ ...prevValue, description: e }))}
      />
      <div className="my-2">
        <input type="checkbox" id="myCheckbox" checked={value.multiTicket} onChange={(e) => setValue((prevValue) => ({ ...prevValue, multiTicket: !prevValue.multiTicket }))} />
        <label className="p-4">Generate several tickets</label>
      </div>
      <button disabled={!value.description} onClick={onCreate} className="blue-btn p-6 w-full">
        Create task
      </button>
    </div>
  );
};
