import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import html2pdf from "html2pdf.js";
import api from "../../../services/api";
import SelegoJpeg from "../../../assets/selego.jpeg";

const saveOptions = {
  margin: 0,
  filename: `invoice.pdf`,
  image: { type: "jpeg", quality: 1 },
  html2canvas: { dpi: 300 },
  jsPDF: { unit: "mm", format: "a4" },
};

export default ({ payment }) => {
  // payment;
  //date, values, id

  const [invoice, setInvoice] = useState({});
  const [organisation, setOrganisation] = useState({});

  const user = useSelector((state) => state.Auth.user);

  const getOrganisation = async () => {
    const { data } = await api.get(`/organisation/${payment.organisation_id}`);
    setOrganisation(data);
  };

  useEffect(() => {
    getData();
    getOrganisation();
  }, []);

  const getData = async () => {
    const { data } = await api.get(`/activity?date=${new Date(payment.date).getTime()}&userId=${payment.user_id}&exclude_unavailable_project=true`);
    const { total: t } = await api.post(`/payment/search`, { user_id: payment.user_id, limit: 0 });
    const index = t + 1;

    const itemsInvoice = data.map((item) => {
      return {
        name: item.projectName,
        price: item.userTjm,
        quantity: (item.total / 8).toFixed(2),
        totalPrice: item.cost,
      };
    });

    const subTotal = itemsInvoice.reduce((acc, item) => acc + item.totalPrice, 0);
    const vat = {
      amount: (subTotal * payment.taxValue) / 100,
      rate: payment.taxValue,
    };
    const total = subTotal + vat.amount;

    setInvoice({ itemsInvoice, subTotal, vat, total, index });
  };

  async function print() {
    const mainElemToPrint = document.getElementById("invoice");
    mainElemToPrint.classList.add("printing");
    const pdf = await html2pdf().set(saveOptions).from(mainElemToPrint).outputPdf("datauristring");
    mainElemToPrint.classList.remove("printing");
    return pdf;
  }

  if (!invoice) return <div>Loading</div>;

  return (
    <div className="justify-center grid">
      <div className="flex align-middle items-center col-start-1 col-end-12 p-4 border-b-2 border-[#E5EAEF]">
        <p className="text-[18px] font-semibold text-[#212325]">Your invoice</p>
        <button
          className="absolute right-16 bg-blue-600 hover:bg-blue-500 text-white font-semibold hover:text-white py-3 px-3 text-sm border border-blue-500 hover:border-transparent rounded"
          onClick={async () => {
            const pdf = await print();
            const { ok, code } = await api.put(`/payment/${payment._id}`, { files: [pdf] });
            if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
            toast.success("Invoice sent");
            window.location.reload();
          }}>
          Send
        </button>
      </div>
      <div className="col-start-1 col-end-5">
        <Invoice invoice={invoice} setInvoice={setInvoice} user={user} organisation={organisation} />
      </div>
    </div>
  );
};

const Invoice = ({ invoice, setInvoice, user, organisation }) => {
  return (
    <div className="flex flex-col justify-center gap-1">
      <div className="bg-white px-6 py-10 text-black">
        <div id="invoice" className="px-4 py-4">
          <Header user={user} organisation={organisation} />
          <SubHeader invoice={invoice} />
          <AmountTable invoice={invoice} setInvoice={setInvoice} />
          <div className="flex flex-col mt-16 pl-3">
            <h3 className="font-semibold text-xs">Terms & conditions</h3>
            <p className="text-xs">
              Please pay within 30 days. In the event of late payment, a penalty of 15% interest rate will be applied, to which will be added a lump sum compensation for recovery
              costs of €40.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = ({ user, organisation }) => {
  return (
    <div>
      <header className="flex items-center justify-between">
        <div className="w-2/5">
          <div className="flex gap-3 items-center mb-2">
            <h1 className="text-black font-semibold h-10">{user?.name}</h1>
          </div>
          <p>{user?.address}</p>
          <p className="text-sm py-1">
            <span className="font-semibold mr-2">Email:</span>
            <span className="">{user?.email}</span>
          </p>
          {user?.iban && (
            <p className="text-sm py-1">
              <span className="font-semibold mr-2">IBAN:</span>
              <span className="">{user.iban}</span>
            </p>
          )}
          {user?.siret && (
            <p className="text-sm py-1">
              <span className="font-semibold mr-2">SIRET:</span>
              <span className="">{user.siret}</span>
            </p>
          )}
        </div>
        <div className="w-2/5">
          <div className="flex gap-3 items-center mb-2">
            <img className="h-10 rounded-md border border-[#C9C8C9]" src={SelegoJpeg} alt="image.png" />
            <h1 className="text-black font-semibold">{organisation.name}</h1>
          </div>
          <p className="text-sm py-1">{organisation.address}</p>
          <p className="text-sm py-1">
            <span className="font-semibold mr-2">Email:</span>
            <span className="">{organisation.email}</span>
          </p>
          <p className="text-sm py-1">
            <span className="font-semibold mr-2">VAT Intracommunity Tax:</span>
            <span className="">{organisation.vat}</span>
          </p>
          <p className="text-sm py-1">
            <span className="font-semibold mr-2">IBAN:</span>
            <span className="">{organisation.iban}</span>
          </p>
        </div>
      </header>
      <div className="border-t border-t-gray-300 my-8"></div>
    </div>
  );
};

const SubHeader = ({ invoice }) => {
  return (
    <div className="pl-3 flex justify-between">
      <div className="w-1/3">
        <div className="flex">
          <span className="w-1/2 font-semibold">Invoice:</span>
          <span className="">{invoice.index}</span>
        </div>
        <div className="flex">
          <span className="w-1/2 font-semibold">Invoice date:</span>
          <span>{new Date().toLocaleDateString("fr")}</span>
        </div>
        <div className="flex">
          <span className="w-1/2 font-semibold">Due date:</span>
          <span>{new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()).toLocaleDateString("fr")}</span>
        </div>
      </div>
    </div>
  );
};

const AmountTable = ({ invoice }) => {
  return (
    <div className="flex flex-col mt-5">
      <table className="w-full table-fixed">
        <thead>
          <tr className="bg-[#F5F5F5] border-y border-y-[#C9C8C9] text-sm text-black font-semibold">
            <th className="w-1/4 py-2.5 pl-3">Name</th>
            <th className="w-1/4 py-2 pr-4 text-right">Quantity</th>
            <th className="w-1/4 py-2 pr-4 text-right">Price</th>
            <th className="w-1/4 py-2.5 pr-3 text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          {invoice.itemsInvoice?.map((c, index) => {
            const current = index + 1;
            const firstBreakPoint = invoice.itemsInvoice.length > 11 && invoice.itemsInvoice.length <= 14 ? invoice.itemsInvoice.length : 15;
            let breakPage = current % (22 + firstBreakPoint) === 0;
            const firstPage = current === firstBreakPoint;
            if (firstPage) breakPage = current % firstBreakPoint === 0;
            return (
              <tr className={`text-sm text-black text-left ${breakPage && "html2pdf__page-break"}`} key={index}>
                {" "}
                <td className="pl-3">
                  <div className="flex items-center gap-3">
                    <span className="py-2.5">{c.name}</span>
                  </div>
                </td>
                <td className="text-right pr-4">
                  <span className="py-2.5">{`${c.quantity > 1 ? c.quantity + " days" : c.quantity + " day"}`}</span>
                </td>
                <td className="text-right pr-4">
                  <span className="py-2.5">
                    <span className="py-2.5">{`${c.price} €`}</span>
                  </span>
                </td>
                <td className="text-right pr-3">{`${c.totalPrice.toFixed(2)}€`}</td>
              </tr>
            );
          })}
          <tr>
            <td className="pt-3" />
          </tr>
          {invoice.vat?.rate > 0 && (
            <>
              <tr className="bg-[#F5F5F5] border-y border-y-[#C9C8C9] text-sm text-black font-semibold">
                <td className="py-2 pl-3">Subtotal</td>
                <td className="py-2"></td>
                <td className="py-2"></td>
                <td className="py-2 text-right pr-3">{`${invoice.subTotal?.toFixed(2)}€`}</td>
              </tr>
              <tr className="bg-[#F5F5F5] border-y border-y-[#C9C8C9] text-sm text-black font-semibold">
                <td className="py-2 pl-3">VAT ({invoice.vat?.rate} %)</td>
                <td className="py-2"></td>
                <td className="py-2"></td>
                <td className="py-2 text-right pr-3">{`${invoice.vat?.amount.toFixed(2)}€`}</td>
              </tr>
            </>
          )}
          <tr className="bg-[#F5F5F5] border-y border-y-[#C9C8C9] text-sm text-black font-semibold">
            <td className="py-2 pl-3">Total</td>
            <td className="py-2"></td>
            <td className="py-2"></td>
            <td className="py-2 text-right pr-3">{`${invoice.total?.toFixed(2)}€`}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
