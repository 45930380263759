import React, { useState } from "react";
import toast from "react-hot-toast";

import API from "../../../../../../services/api";

export default ({ notion, setNotion }) => {
  const handleDateChange = async (date) => {
    try {
      const { ok, data } = await API.put(`/notion/${notion._id}`, date);
      if (ok) toast.success("Date updated");
      setNotion(data);
    } catch (e) {
      console.log(e);
      toast.error("Error updating date");
    }
  };
  console.log("LA", notion);

  return (
    <div>
      <div className="p-2">
        <div>
          {notion.status_todo_date && (
            <div className="grid grid-cols-5 items-center space-x-2 space-y-2">
              <span>Status To Do at</span> <DateInput value={notion.status_todo_date} onChange={(date) => handleDateChange({ status_todo_date: date })} />
            </div>
          )}
          {notion.status_ready_to_dev_date && (
            <div className="grid grid-cols-5 items-center space-x-2 space-y-2">
              <span>Status Ready to Dev at</span> <DateInput value={notion.status_ready_to_dev_date} onChange={(date) => handleDateChange({ status_ready_to_dev_date: date })} />
            </div>
          )}
          {notion.status_in_progress_date && (
            <div className="grid grid-cols-5 items-center space-x-2 space-y-2">
              <span>Status In Progress at</span> <DateInput value={notion.status_in_progress_date} onChange={(date) => handleDateChange({ status_in_progress_date: date })} />
            </div>
          )}
          {notion.status_testing_date && (
            <div className="grid grid-cols-5 items-center space-x-2 space-y-2">
              <span>Status Testing at</span> <DateInput value={notion.status_testing_date} onChange={(date) => handleDateChange({ status_testing_date: date })} />
            </div>
          )}
          {notion.status_done_date && (
            <div className="grid grid-cols-5 items-center space-x-2 space-y-2">
              <span>Status Done at</span> <DateInput value={notion.status_done_date} onChange={(date) => handleDateChange({ status_done_date: date })} />
            </div>
          )}
          {notion.status_archived_date && (
            <div className="grid grid-cols-5 items-center space-x-2 space-y-2">
              <span>Status Archived at</span> <DateInput value={notion.status_archived_date} onChange={(date) => handleDateChange({ status_archived_date: date })} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DateInput = ({ value, onChange }) => {
  const [date, setDate] = useState(value);

  return (
    <input
      className="input"
      type="date"
      value={date && new Date(date).toISOString().split("T")[0]}
      onChange={(e) => {
        setDate(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

// status_todo_date: { type: Date },
// status_ready_to_dev_date: { type: Date },
// status_in_progress_date: { type: Date },
// status_testing_date: { type: Date },
// status_done_date: { type: Date },
// status_archived_date: { type: Date },
