import { create } from "zustand";

const useStore = create((set) => ({
  // TODO later
  // user: null,
  // setUser: (user) => set(() => ({ user })),
  sideBarOpen: true,
  setSideBarOpen: (sideBarOpen) => set(() => ({ sideBarOpen })),
}));

export default useStore;
