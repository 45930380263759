import React, { useContext } from "react";

import Step from "./step";
import Header from "./header";
import Comments from "./comments";

import { AppContext } from "./context";

export default ({}) => {
  const context = useContext(AppContext);

  if (!context.workshop) return <div>Loading...</div>;

  if (context.index == 0 || context.index == context.workshop.steps.length + 1) return <div />;

  return (
    <div className="w-screen h-screen fixed inset-0 z-50 bg-white flex flex-col justify-between overflow-y-auto pb-[120px]">
      <div className="w-full h-full">
        <Header />
        <div className="max-w-7xl mx-auto py-3 lg:py-16 px-3" id="content" key={context.index}>
          <div className="flex justify-between">
            <Step />
            <Comments />
          </div>
        </div>
      </div>
    </div>
  );
};
