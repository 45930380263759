import React, { useEffect, useRef, useState, useContext } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../services/api";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

// import { HiThumbUp } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import SyntaxHighlighter from "react-syntax-highlighter";
import { BiTrash } from "react-icons/bi";

export default ({ debate, setDebate }) => {
  const [message, setMessage] = useState("");
  const [showInput, setShowInput] = useState(false);

  const [codeReply, setCodeReply] = useState("");
  const [showCodeInput, setShowCodeInput] = useState(false);

  const user = useSelector((state) => state.Auth.user);

  const sendReply = async () => {
    const { data, ok } = await api.put(`/debate/${debate._id}/addMainThread`, {
      text: message,
      type: "text",
    });
    if (!ok) return toast.error("Error sending reply");
    setMessage("");
    setDebate(data);
    setShowInput(false);
  };

  const toggleReplyInput = () => {
    setShowInput((prevShowInput) => !prevShowInput);
  };

  const toggleCodeInput = () => {
    setShowCodeInput((prevShowCodeInput) => !prevShowCodeInput);
  };

  return (
    <div className="p-4 bg-white rounded shadow mb-4">
      <div className="flex items-center mb-4">
        <img src={debate.user_avatar} alt={`${debate.user_name}'s avatar`} className="w-12 h-12 rounded-full mr-4" />
        <div>
          <div className="text-xl font-bold">{debate.name}</div>
          <div className="text-gray-500 text-sm">{debate.user_name}</div>
        </div>
      </div>
      <div className="text-gray-700">{debate.description}</div>
      <div className="flex flex-wrap mt-2">
        {debate.tags.map((tag) => (
          <span className="mr-2 text-sm text-blue-500">#{tag}</span>
        ))}
      </div>
      <div className="flex items-center gap-2">
        {/* <HiThumbUp
          className="cursor-pointer"
          onClick={async () => {
            console.log("debate", debate);
            const { data, ok } = await api.put(`/debate/${debate._id}/like`);
            if (!ok) return toast.error("Error liking debate");
            setDebate(data);
          }}
        /> */}
        <div className="text-sm text-gray-500 cursor-pointer underline" data-tip data-for={`like-${debate._id}`}>
          {debate?.likes?.length || 0}
        </div>
        <ReactTooltip id={`like-${debate._id}`}>
          {(debate.likes || []).map((l) => (
            <div>{l.user_name}</div>
          ))}
        </ReactTooltip>
      </div>

      <div className="flex items-center gap-2">
        <button onClick={toggleReplyInput} className="px-2 py-1 border border-gray-400 rounded bg-white hover:bg-gray-100">
          {showInput ? "Cancel" : "Reply"}
        </button>
        <button onClick={toggleCodeInput} className="px-2 py-1 border border-gray-400 rounded bg-white hover:bg-gray-100">
          {showCodeInput ? "Cancel" : "Code Reply"}
        </button>
      </div>
      {showInput && (
        <>
          <textarea
            className="w-full mt-4 p-2 border border-gray-300 rounded-md"
            rows="4"
            placeholder="Enter your reply"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <button className="btn btn-primary mt-4" onClick={sendReply}>
            {" "}
            Send{" "}
          </button>
        </>
      )}

      {showCodeInput && (
        <>
          <Code value={codeReply} setValue={setCodeReply} onRemove={toggleCodeInput} />
          <button
            onClick={async () => {
              const { data, ok } = await api.put(`/debate/${debate._id}/addMainThread`, {
                text: codeReply,
                type: "code",
              });
              setDebate(data);
              setShowCodeInput(false);
            }}>
            Send
          </button>
        </>
      )}

      <div className="mt-4">
        {debate.threads.map((thread, index) => (
          <Thread key={index} thread={thread} debate={debate} setDebate={setDebate} />
        ))}
      </div>
    </div>
  );
};

function Thread({ thread, debate, setDebate }) {
  const [threadReply, setThreadReply] = useState("");
  const [codeReply, setCodeReply] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);

  const user = useSelector((state) => state.Auth.user);
  const handleReplyChange = (e) => {
    setThreadReply(e.target.value);
  };

  const toggleReplyInput = () => {
    setShowInput((prevShowInput) => !prevShowInput);
  };

  const toggleCodeInput = () => {
    setShowCodeInput((prevShowCodeInput) => !prevShowCodeInput);
  };

  return (
    <div className="ml-8 mt-4 border-l-2 border-gray-200 pl-4">
      <div className="flex items-center mb-2">
        <img src={thread.user_avatar} alt={`${thread.user_name}'s avatar`} className="w-10 h-10 rounded-full mr-2" />
        <div>
          <div className="text-lg font-bold">{thread.name}</div>
          <div className="text-gray-500 text-sm">{thread.user_name}</div>
        </div>
      </div>
      {thread.type === "text" && <div className="text-gray-700 mb-2">{thread.text}</div>}

      {thread.type === "code" && (
        <div className="mb-5 w-full text-xs rounded-md">
          <SyntaxHighlighter language="javascript" showLineNumbers={true} showInlineLineNumbers={true} style={a11yDark}>
            {thread.text}
          </SyntaxHighlighter>
        </div>
      )}

      {(thread.user_id === user._id || user.role === "admin") && (
        <BiTrash
          className="cursor-pointer"
          onClick={async () => {
            if (!window.confirm("Are you sure you want to delete this thread?")) return;
            const { data, ok } = await api.put(`/debate/${debate._id}/deleteThread`, {
              thread_id: thread._id,
            });
            if (!ok) return toast.error("Error deleting thread");
            setDebate(data);
          }}
        />
      )}

      <div className="flex items-center gap-2">
        <button onClick={toggleReplyInput} className="px-2 py-1 border border-gray-400 rounded bg-white hover:bg-gray-100">
          {showInput ? "Cancel" : "Reply"}
        </button>
        <button onClick={toggleCodeInput} className="px-2 py-1 border border-gray-400 rounded bg-white hover:bg-gray-100">
          {showCodeInput ? "Cancel" : "Code Reply"}
        </button>
      </div>
      {showInput && (
        <div className="reply-input ">
          <input type="text" value={threadReply} onChange={handleReplyChange} placeholder="Type your reply..." />
          <button
            onClick={async () => {
              const { data, ok } = await api.put(`/debate/${debate._id}/addSubThread`, {
                text: threadReply,
                type: "text",
                thread_id: thread._id,
              });
              setDebate(data);
              setShowInput(false);
            }}>
            Send
          </button>
        </div>
      )}

      {showCodeInput && (
        <>
          <Code value={codeReply} setValue={setCodeReply} onRemove={toggleCodeInput} />
          <button
            onClick={async () => {
              const { data, ok } = await api.put(`/debate/${debate._id}/addSubThread`, {
                text: codeReply,
                type: "code",
                thread_id: thread._id,
              });
              setDebate(data);
              setShowCodeInput(false);
            }}>
            Send
          </button>
        </>
      )}

      {thread.threads && thread.threads.map((subThread, index) => <Thread key={index} thread={subThread} debate={debate} setDebate={setDebate} />)}
    </div>
  );
}

const Code = ({ value, setValue, onRemove }) => {
  return (
    <div>
      <div className="px-1 text-sm text-gray-600 font-medium flex justify-between">
        <div>Code</div>
        <button onClick={onRemove}>Remove</button>
      </div>
      <textarea
        placeholder="Code"
        rows="10"
        className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};
