import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader";
import api from "../../services/api";
import Chat from "./chat";

const General = () => {
  const [selected, setSelected] = useState();

  const { id } = useParams();

  useEffect(() => {}, [id]);

  useEffect(() => {
    getChannels();
  }, []);

  useEffect(() => {
    getCurrentTicket();
  }, [id]);

  async function getChannels() {
    const { data: channels } = await api.post(`/ticket/search`, { type: "channel" });
    setSelected(channels[0]);
  }

  async function getCurrentTicket() {
    if (!id) return setSelected(null);
    const { data } = await api.get(`/ticket/${id}`);
    if (data) setSelected(data);
  }

  if (!selected) return <Loader />;
  return (
    <div className="flex w-full items-stretch h-[calc(100vh_-_60px)] font-inter">
      <div className="flex-1 max-h-full bg-white border-l border-lightShade-grey flex flex-col relative">
        <div className="flex-1" />
          <Chat ticket={selected} />
      </div>
    </div>
  );
};



export default General;
