import React from "react";
import { useNavigate } from "react-router-dom";
import { HiCalendar } from "react-icons/hi";
import { RiThumbUpFill } from "react-icons/ri";
import { PiStarFill, PiStarLight } from "react-icons/pi";
import CategoryRadar from "../../scenes/skills/components/CategoryRadar";
import SubCategoryRadar from "../../scenes/skills/components/SubCategoryRadar";
import UserProjects from "../../components/UserProjects";

export default function Overview({ user, setUser }) {
  const navigate = useNavigate();

  const handleProjectClick = (projectId) => {
    navigate(`/project/${projectId}`);
    setUser(null);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-12 gap-4">
        <div className="flex flex-col col-span-4 h-full gap-4">
          <div className="bg-white border border-gray-300 rounded p-3 space-y-2">
            <h3 className="text-sky-800">My infos</h3>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="text-sm font-semibold">Daily Rate</div>
                <div className="text-sm font-light">{user.tjms} €</div>
              </div>
              <div>
                <div className="text-sm font-semibold">Disponibility</div>
                <div className="text-sm font-light text-gray-800">{user.disponibility}</div>
              </div>
              <div>
                <div className="text-sm font-semibold">Office</div>
                <div className="text-sm font-light">{user.office}</div>
              </div>
              <div>
                <div className="text-sm font-semibold">Contract</div>
                <div className="text-sm font-light">{user.contract}</div>
              </div>
            </div>
          </div>
          <div className="bg-white border border-gray-300 rounded p-3 space-y-2">
            <h3 className="text-sky-800">My projects</h3>
            <UserProjects user={user} max={15} handleClick={handleProjectClick} />
          </div>
          <div className="bg-white border border-gray-300 rounded p-3 space-y-2">
            <div className="text-sm font-semibold">Ratings</div>
            <Rating user={user} />
          </div>
          <div className="bg-white border border-gray-300 rounded p-3 space-y-2">
            <div className="text-sm font-semibold">Description</div>
            <div className="text-sm font-light">{user.description}</div>
          </div>
        </div>
        <div className="flex flex-col col-span-8 h-full gap-4">
          <div className="bg-white border border-gray-300 rounded p-3 space-y-2">
            <div className="text-sm font-semibold">My skills</div>

            <div className="flex items-center w-full">
              <div className="h-[200px] w-1/4 border border-gray-200">
                <CategoryRadar userId={user._id} />
              </div>
              <div className="w-3/4">
                <SubCategoryRadar userId={user._id} />
              </div>
            </div>
          </div>
          <div className="bg-white border border-gray-300 rounded p-3 space-y-2">
            <div className="text-sm font-semibold">My keywords</div>
            <Keywords key_words={user.key_words} />
          </div>
          {user.calendly && (
            <a target="_blank" href={user.calendly} rel="noreferrer" className="gray-btn">
              <HiCalendar /> Book a meeting
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

const Empty = () => <span className="px-3 py-1 bg-gray-50 text-gray-400 rounded-lg border border-gray-100 text-xs">Empty</span>;

const Rating = ({ user }) => {
  const averageRating = user.ratings.length > 0 ? user.ratings.reduce((sum, rating) => sum + rating.score, 0) / user.ratings.length : 0;
  return (
    <div className="flex items-center gap-2 text-sm font-normal pt-2">
      <div className="flex">
        {[1, 2, 3, 4, 5].map((score) => (
          <div key={score} className={`px-1 ${score <= averageRating ? "text-yellow-400" : "text-gray-300"}`}>
            {score <= averageRating ? <PiStarFill size={24} /> : <PiStarLight size={24} />}
          </div>
        ))}
      </div>
    </div>
  );
};

const Keywords = ({ key_words }) => {
  if (!key_words || key_words.length === 0) return <Empty />;
  return (
    <div className="w-full flex gap-1 flex-wrap py-1 pr-2">
      {key_words
        ?.sort((a, b) => b.likes.length - a.likes.length)
        ?.map((keyword) => (
          <div key={keyword.name} className="flex flex-row gap-1 items-center rounded-lg bg-blue-50 text-blue-800 px-2 py-1 border-[1px] border-blue-200 text-xs text-nowrap">
            {keyword.name}
            <p className="flex flex-row gap-1 text-blue-600 hover:text-blue-800 ">{keyword.likes.length > 0 ? keyword.likes.length : " "}</p>
          </div>
        ))}
    </div>
  );
};
