import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Menu from "./menu";
import Content from "./content";

import api from "../../../services/api";
import { useNavigate, useParams } from "react-router-dom";

const View = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pages, setPages] = useState([]);
  const [quote, setQuote] = useState();
  const [blocks, setBlocks] = useState([]);
  const [selection, setSelection] = useState({ page: null, block: null });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: q } = await api.get(`/quote/${id}`);
        if (!q) {
          toast.error("Quote not found");
          navigate("/quote");
          return;
        }
        const { data: b } = await api.post(`/quote_block/search`, { quote_id: q._id });
        const { data: p } = await api.post(`/quote_page/search`, { quote_id: q._id });
        if (!p.length) {
          const page = await api.post(`/quote_page`, { quote_id: q._id, quote_name: q.name, position: 0 });
          p.push(page.data);
        }

        p.sort((a, b) => a.position - b.position);
        b.sort((a, b) => {
          if (a.quote_page_id !== b.quote_page_id) {
            const pageA = p.findIndex((page) => page._id === a.quote_page_id);
            const pageB = p.findIndex((page) => page._id === b.quote_page_id);
            return pageA - pageB;
          } else {
            return a.page - b.page;
          }
        });
        setQuote(q);
        setPages(p);
        setBlocks(b);
      } catch (error) {
        console.error(error);
        toast.error("Error fetching quote");
      }
    };
    fetchData();
  }, []);

  if (!quote) return <div>Loading...</div>;

  return (
    <div className="h-full flex bg-gray-800">
      <div className="overflow-scroll h-full flex-1" id="wrapper">
        <Content pages={pages} blocks={blocks} selection={selection} onPagesChange={setPages} quote={quote} onBlocksChange={setBlocks} onSelect={setSelection} />
      </div>
      <Menu
        quote={quote}
        pages={pages}
        blocks={blocks}
        selection={selection}
        onQuoteChange={setQuote}
        onPagesChange={setPages}
        onBlocksChange={setBlocks}
        onSelect={setSelection}
      />
    </div>
  );
};

export default View;
