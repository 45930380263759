import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Jobs from "./job_list";
import Channels from "./channel_list";

import Job from "./job_view";
import Channel from "./channel_view";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();

  const is_active = (path) => location.pathname === path;

  return (
    <div>
      <div className="m-2">
        <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
          <TabItem onClick={() => navigate(`/job`)} active={is_active("/job")} title="Jobs" />
          <TabItem onClick={() => navigate(`/job/channel`)} active={is_active("/job/channel")} title="Channels" />
        </nav>
        <Routes>
          <Route path="/" element={<Jobs />} />
          <Route path="/channel" element={<Channels />} />
          <Route path="/:id/*" element={<Job />} />
          <Route path="/channel/:id" element={<Channel />} />
        </Routes>
      </div>
    </div>
  );
};

const TabItem = ({ onClick, active, title }) => {
  return (
    <div onClick={onClick} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded px-4 flex items-center gap-2 cursor-pointer`}>
      <div className="flex items-center gap-2 truncate text-xs">{title}</div>
    </div>
  );
};
