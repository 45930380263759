import React, { useEffect, useState } from "react";
import api from "../../services/api";
import toast from "react-hot-toast";
import { CATEGORIES_KEY_WORDS } from "../../constants";

export default () => {
  const [keyWords, setKeyWords] = useState();
  const [newKeyWord, setNewKeyWord] = useState({ name: "", category: "" });
  const [isLoading, setIsLoading] = useState(false);

  async function get() {
    setIsLoading(true);
    const res = await api.post(`/key_word/search`);
    setKeyWords(res.data);
    setIsLoading(false);
  }

  async function save() {
    if (!newKeyWord.name || !newKeyWord.category) return;
    const res = await api.post(`/key_word`, newKeyWord);
    if (!res.ok) toast.error("Error");
    get();
    setNewKeyWord({name: "", category: ""});
  }

  async function handleDelete(item) {
    if (!confirm("Are you sure you want to delete this key word?")) return;
    const { ok } = await api.remove(`/key_word/${item._id}`);
    if (!ok) return toast.error("Error");
    toast.success("Key Word Deleted");
    setKeyWords(keyWords.filter((i) => i._id !== item._id));
  }
  useEffect(() => {
    get();
  }, []);

  return (
    <div>
      <h1>Key Words</h1>
      <div className="flex flex-wrap gap-2 mt-4">
        {CATEGORIES_KEY_WORDS.map((category) => (
          <div key={category} className="border rounded-lg  gap-2 p-2 items-center">
            <span className="font-bold">{category}</span>
            {keyWords && keyWords.filter((item) => item.category === category).length > 0 ? (
              keyWords
                .filter((item) => item.category === category)
                .map((item) => (
                  <div key={item._id}>
                    <span>{item.name}</span>
                    <button className="text-xs border rounded-lg p-1 ml-2 border-red-600" onClick={() => handleDelete(item)}>
                      ❌
                    </button>
                  </div>
                ))
            ) : (
              <p>-</p>
            )}
          </div>
        ))}
      </div>
      <span>Add KeyWord</span>
      <div className="flex">
        <input type="text" value={newKeyWord.name} onChange={(e) => setNewKeyWord({ ...newKeyWord, name: e.target.value })} />
        <select value={newKeyWord.category} onChange={(e) => setNewKeyWord({ ...newKeyWord, category: e.target.value })}>
          <option value="">Select Category</option>
          {CATEGORIES_KEY_WORDS.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
        <button className="btn btn-primary " onClick={save}>
          Save
        </button>
      </div>
    </div>
  );
};
