import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../../services/api";
import Modal from "../../../components/modal";
import Table from "../../../components/Table";
import Paginator from "../../../components/Paginator";

import ContactModal from "../component/contactModal";

import SelectCampaign from "../component/SelectCampaign";
import SelectArchived from "../component/SelectArchived";

export default () => {
  const [filters, setFilters] = useState({ page: 1, events: [], archived: false });
  const [loading, setLoading] = useState(false);

  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [total, setTotal] = useState(0);

  //if ?id="66d2e99f5d30c3535ef50562", load contact and setSelectdcontact

  async function loadContact() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    if (!id) return;

    const { ok, data } = await api.get(`/s_contact/${id}`);
    if (!ok) return toast.error("Error fetching contact");
    setSelectedContact(data);
    // change url
    window.history.pushState({}, "", "/acquiz/contacts");
  }

  useEffect(() => {
    fetch();
    loadContact();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = {};
    newFilters.skip = (filters.page - 1) * 100;
    if (filters.campaign) newFilters.campaign_id = filters.campaign._id;
    if (filters.events && filters.events.length) newFilters.events = filters.events;
    if (filters.search) newFilters.search = filters.search;
    newFilters.archived = filters.archived;

    console.log(newFilters);
    // newFilters.sort = "createdAt"
    const { data, total, ok } = await api.post("/s_contact/search", newFilters);
    console.log(data, total, ok);
    if (!ok) return toast.error("Error fetching contacts");
    setContacts(data);
    setTotal(total);
    setLoading(false);
  }

  return (
    <div className="bg-white h-full">
      <div>{total} items</div>
      <ContactModal contact={selectedContact} setContact={setSelectedContact} />
      <Table
        total={total}
        header={[
          { title: "Name", key: "first_name" },
          { title: "Job", key: "job_titles" },
          { title: "Social", key: "social" },
          { title: "Company", key: "company" },
       
          { title: "Events", key: "events" },
          { title: "Created At", key: "created_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {contacts.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedContact(item);
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{`${item.first_name} ${item.last_name}`}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item?.linkedin_raw?.job_title}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex space-x-2">
                  {item.linkedin && (
                    <a href={item.linkedin} target="_blank" rel="noopener noreferrer">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn Logo" className="w-6 h-5" />
                    </a>
                  )}
                  {item.email && (
                    <a href={`mailto:${item.email}`}>
                      <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" alt="Email Logo" className="w-6 h-5" />
                    </a>
                  )}
                  {item.client_id && (
                    <a href={`https://app.lemlist.com/leads/${item.client_id}`} target="_blank" rel="noopener noreferrer">
                      <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Emblem-important.svg/100px-Emblem-important.svg.png" alt="Email Logo" className="w-6 h-5" />
                    </a>
                  )}
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.company_name}</td>
         
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex flex-wrap gap-1">
                  {item.events.map((e) => (
                    <span key={e} className="bg-gray-200 text-xs px-2 py-1 rounded-md">
                      {e}
                    </span>
                  ))}
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.created_at}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};