import React, { useEffect, useState } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { ResponsiveBar } from "@nivo/bar";
import { MdCheck, MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { formatNumberWithSpaces } from "../utils";

import api from "../../../services/api";

ChartJS.register(...registerables);

// const y = new Date().getFullYear();

export default ({ project }) => {
  const [year, setYear] = useState(null);
  const [activities, setActivities] = useState(null);
  const [budgets, setBudgets] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [banks, setBanks] = useState(null);

  useEffect(() => {
    get();
  }, [year]);

  async function get() {
    const beginingOfThisYear = new Date(year, 0, 1);
    const endOfThisYear = new Date(year, 11, 31);

    const fActivities = {};
    fActivities.projectId = project._id;

    if (year) {
      fActivities.dateFrom = beginingOfThisYear;
      fActivities.dateTo = endOfThisYear;
    }
    const { data: activities } = await api.post(`/activity/search`, fActivities);

    const fBank = { project_id: project._id, notCategories: ["INVOICE", "TAX"], per_page: 20000 };
    if (year) {
      fBank.startDate = beginingOfThisYear;
      fBank.endDate = endOfThisYear;
    }

    const { data: b } = await api.post(`/bank/search`, fBank);
    const banks = b.banks;

    const fInvoices = { projectId: project._id, per_page: 20000 };
    if (year) {
      fInvoices.startDate = beginingOfThisYear;
      fInvoices.endDate = endOfThisYear;
    }

    const { data: invoices } = await api.post(`/invoice/search`, fInvoices);

    const { data: budg } = await api.post(`/budget/search`, { projectId: project._id });

    setActivities(activities);
    setBanks(banks);
    setInvoices(invoices);
    setBudgets(budg);
  }

  if (!activities || !banks || !invoices || !budgets) return <div>Loading...</div>;

  return (
    <div className="bg-white space-y-8">
      <div className="flex items-center justify-end mb-2">
        <SelectYear value={year} onChange={(e) => setYear(e)} />
      </div>
      <Chart activities={activities} banks={banks} />
      <BudgetTable activities={activities} banks={banks} invoices={invoices} budgets={budgets} />
    </div>
  );
};

const SelectYear = ({ value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="block w-32 px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary">
    <option value="">All</option>
    <option value="2024">2024</option>
    <option value="2023">2023</option>
    <option value="2022">2022</option>
    <option value="2021">2021</option>
  </select>
);

const Chart = ({ activities, banks }) => {
  const arr = [];
  const beginingOfThisYear = new Date(new Date().getFullYear(), 0, 1);
  const date = new Date(beginingOfThisYear);

  if (!activities || !banks) return <div />;

  let accumulatedCost = 0;

  for (let i = 0; i < 12; i++) {
    const month = date.toLocaleString("default", { month: "long" });

    const findActivities = activities.filter((activity) => {
      const m = new Date(activity.date).toLocaleString("default", { month: "long" });
      return m === month;
    });

    const findBanks = banks.filter((bank) => {
      const m = new Date(bank.date).toLocaleString("default", { month: "long" });
      return m === month;
    });

    const totalBanks = findBanks.reduce((acc, cur) => acc + cur.amount, 0);
    const totalActivities = findActivities.reduce((acc, cur) => acc + cur.value, 0);
    accumulatedCost = -totalBanks + totalActivities;

    arr.push({ month, accumulatedCost: Math.ceil(accumulatedCost) });
    date.setMonth(date.getMonth() + 1);
  }

  return (
    <div className="border border-gray-200 rounded-md p-4 w-[65%]">
      <h1 className="text-sm font-semibold leading-6 text-gray-900 mb-5">Budget consumed last 12 months</h1>
      <div className="h-[300px] w-full">
        <ResponsiveBar
          data={arr}
          keys={["accumulatedCost", "accumulatedBudget"]}
          indexBy="month"
          margin={{ top: 10, right: 0, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          groupMode="grouped"
          defs={[
            { id: "dots", type: "patternDots", background: "inherit", color: "#38bcb2", size: 4, padding: 1, stagger: true },
            { id: "lines", type: "patternLines", background: "inherit", color: "#eed312", rotation: -45, lineWidth: 6, spacing: 10 },
          ]}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: "Money", legendPosition: "middle", legendOffset: -50 }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color" }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </div>
  );
};

const BudgetTable = ({ activities, banks, invoices, budgets }) => {
  const data = [];

  for (let i = 0; i < budgets.length; i++) {
    const budget = budgets[i];
    const findActivities = activities.filter((activity) => activity.budgetId === budget._id);
    const findBanks = banks.filter((bank) => bank.budgetId === budget._id);
    const findInvoices = invoices.filter((invoice) => invoice.budgetId === budget._id);

    data.push({ budget: budget, invoices: findInvoices, banks: findBanks, activities: findActivities });
  }

  return (
    <div className="w-full p-6 border border-gray-300 rounded">
      <table className="table-fixed">
        <thead className="border-b border-gray-300">
          <tr>
            <th colSpan={2} className="text-left font-semibold text-sm px-3">
              Budget
            </th>
            <th className="text-left font-semibold text-sm p-2">Amount</th>
            <th className="text-left font-semibold text-sm px-3">Amount used</th>
            <th colSpan={4} className="text-left font-semibold text-sm px-3">
              Invoices
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <BudgetItem key={index} index={index} item={row} />
          ))}
        </tbody>
        <tfoot className="border-t border-gray-300">
          <tr>
            <td colSpan={2} className="p-2 text-sm font-semibold">
              Total
            </td>
            <td className="text-left p-2 text-sm font-semibold">
              {data.reduce((acc, a) => acc + a.budget.amount, 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
            </td>
            <td className="text-left p-2 text-sm font-semibold">
              {data.reduce((acc, a) => acc + a.budget.amountUsed, 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
            </td>
            <td colSpan={4} className="text-left p-2 text-sm font-semibold">
              {data.reduce((acc, a) => acc + a.invoices.reduce((acc, cur) => acc + cur.total, 0), 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const BudgetItem = ({ item, index }) => {
  const invoiced = item.invoices.reduce((acc, cur) => acc + cur.total, 0);
  return (
    <tr className={index % 2 === 0 ? "bg-gray-50" : ""}>
      <td colSpan={2} className="text-left p-2 text-sm">
        {item.budget.name} {item.budget.status === "inactive" && <span className="border border-gray-500 rounded px-1 text-gray-500">Inactive</span>}{" "}
      </td>

      <td className="text-left p-2 text-sm">{(item.budget.amount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
      <td className="text-left p-2 text-sm">{(item.budget.amountUsed || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
      <td colSpan={4} className="text-left p-2 text-sm">
        <div className="flex-1 flex items-center gap-4">
          <div>{(invoiced || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
          <div className="flex flex-col gap-2">
            {item.invoices.map((e, i) => {
              return (
                <div key={i} className="flex items-center gap-2">
                  <Link to={`/invoice/${e._id}`} target="_blank" className="text-sm underline">
                    {e.name} ({formatNumberWithSpaces(e.total)}€){" "}
                  </Link>
                  {e.sent === "yes" ? (
                    <span className="border border-green-500 rounded text-green-500 px-1 text-xs">Sent</span>
                  ) : (
                    <span className="border border-gray-500 rounded text-gray-500 px-1 text-xs">Not sent</span>
                  )}
                  {e.received === "yes" ? (
                    <span className="border border-green-500 rounded text-green-500 px-1 text-xs">Received</span>
                  ) : e.sent === "yes" ? (
                    <span className="border border-red-500 rounded text-red-500 px-1 text-xs">Not received</span>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </td>
    </tr>
  );
};

const BudgetMonthTable = ({ activities, banks, invoices }) => {
  console.log("activities", activities);
  console.log("banks", banks);

  const data = [];

  let date = new Date();

  let accumulatedCost = 0;

  for (let i = 0; i < 36; i++) {
    const month = date.toLocaleString("default", { month: "long" });

    const findActivities = activities.filter((activity) => isSameMonth(new Date(activity.date), date));
    const findBanks = banks.filter((bank) => isSameMonth(new Date(bank.month), date));
    const findInvoices = invoices.filter((invoice) => isSameMonth(new Date(invoice.date), date));

    const totalBanks = findBanks.reduce((acc, cur) => acc + cur.amount, 0);
    const totalActivities = findActivities.reduce((acc, cur) => acc + cur.value, 0);
    accumulatedCost = -totalBanks + totalActivities;

    data.push({
      date: `${month} ${date.getFullYear()}`,
      actual: Math.ceil(accumulatedCost),
      budgeted: "-",
      invoices: findInvoices,
      banks: findBanks,
      activities: findActivities,
    });

    date.setMonth(date.getMonth() - 1);
  }

  return (
    <div className="container mx-auto py-8">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-300">Date</th>
            <th className="py-2 px-4 border-b border-gray-300">Actual</th>
            {/* <th className="py-2 px-4 border-b border-gray-300">Budgeted</th> */}
            <th className="py-2 px-4 border-b border-gray-300">Invoiced</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            const invoiced = row.invoices.reduce((acc, cur) => acc + cur.total, 0);

            return (
              <tr key={index} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b border-gray-300">{row.date}</td>
                <td className="py-2 px-4 border-b border-gray-300">{row.actual}</td>
                {/* <td className="py-2 px-4 border-b border-gray-300">
                  <div>{row.budgeted}</div>
                </td> */}
                <td className="py-2 px-4 border-b border-gray-300">
                  <div>{`${formatNumberWithSpaces(invoiced)}€`}</div>
                  <div className="flex flex-col gap-1">
                    {row.invoices.map((e) => {
                      return (
                        <Link to={`/invoice/${e._id}`} target="_blank" className="text-blue-500 text-sm underline">
                          {e.name} ({formatNumberWithSpaces(e.total)}€)
                        </Link>
                      );
                    })}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function isSameMonth(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
}
