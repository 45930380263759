import React from "react";

const Info = ({ perk }) => {
  if (!perk) return <div />;

  return (
    <div className="space-y-8 p-4">
      <div className="flex flex-col space-y-4">
        <div className="flex w-full space-x-8">
          <div className="w-1/3 flex justify-center items-center">
            <img src={perk.logo} alt={`${perk.name} Logo`} className="w-auto max-h-48 object-cover rounded-lg" />
          </div>
          <div className="w-2/3 space-y-4">
            <div className="flex items-center gap-2">
              <h1 className="text-3xl font-bold">{perk.name}</h1>
              <div className={`border rounded px-2 py-1 ${perk.status === "ACTIVE" ? "border-green-500 text-green-500" : "border-gray-500 text-gray-500"}`}>
                {perk.status === "ACTIVE" ? "Active" : "Inactive"}
              </div>
            </div>
            <div>
              <h2 className="font-bold text-lg">Description</h2>
              <p className="text-base">{perk.description}</p>
            </div>
            <div>
              <h2 className="font-bold text-lg">Value</h2>
              <p className="text-base">€{perk.value}</p>
            </div>
            <div>
              <h2 className="font-bold text-lg">Link</h2>
              {perk.link ? (
                <a href={perk.link} className="text-blue-500 underline text-base break-words" style={{ wordWrap: "break-word" }} target="_blank" rel="noopener noreferrer">
                  {perk.link.substring(0, 60) + (perk.link.length > 60 ? "..." : "")}
                </a>
              ) : (
                <p className="text-base">No link provided</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
