import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import List from "./list";
import View from "./view";
import Create from "./create";

const authorizedUsers = ["nicolas.bretecher@selego.co", "nour@selego.co"];

const Payment = () => {
  const { user } = useSelector((state) => state.Auth);

  return (
    <Routes>
      <Route path="/" element={<List />} />
      {authorizedUsers.includes(user?.email) && <Route path="/create" element={<Create />} />}
      <Route path="/:id" element={<View />} />
    </Routes>
  );
};

export default Payment;
