import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import api from "../../../services/api";
import DoneIcon from "../../../assets/learn/done.svg";

const Tutorials = () => {
  const [workshops, setWorkshops] = useState([]);
  const [userWorkshops, setUserWorkshops] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const promises = [api.get("/workshop?category=lesson"), api.get("/workshop_user")];
      const [workshops, userWorkshops] = await Promise.all(promises);
      setWorkshops(workshops.data);
      setUserWorkshops(userWorkshops.data);
    } catch (e) {
      console.log(e);
    }
  };

  if (workshops === null || !userWorkshops) return <Skeleton />;

  const sortWorkshops = (a, b) => {
    const userWorkshopA = userWorkshops.find((w) => w.workshop_id === a._id);
    const userWorkshopB = userWorkshops.find((w) => w.workshop_id === b._id);

    const isInProgress = (userWorkshop) => userWorkshop && !userWorkshop.finished_at;
    const isComplete = (userWorkshop) => userWorkshop && userWorkshop.finished_at;

    if (isInProgress(userWorkshopA) && isInProgress(userWorkshopB)) {
      return 0; // Les deux tutoriels sont en progression, pas besoin de les trier
    }

    if (isInProgress(userWorkshopA) && !isInProgress(userWorkshopB)) {
      return -1; // Le tutoriel A est en progression, le tutoriel B n'est pas en progression, donc A doit être avant B
    }

    if (!isInProgress(userWorkshopA) && isInProgress(userWorkshopB)) {
      return 1; // Le tutoriel A n'est pas en progression, le tutoriel B est en progression, donc A doit être après B
    }

    if (isComplete(userWorkshopA) && isComplete(userWorkshopB)) {
      return 0; // Les deux tutoriels sont complets, pas besoin de les trier
    }

    if (isComplete(userWorkshopA) && !isComplete(userWorkshopB)) {
      return 1; // Le tutoriel A est complet, le tutoriel B n'est pas complet, donc A doit être après B
    }

    if (!isComplete(userWorkshopA) && isComplete(userWorkshopB)) {
      return -1; // Le tutoriel A n'est pas complet, le tutoriel B est complet, donc A doit être avant B
    }

    // Les tutoriels sont dans des catégories différentes (en progression, complet, non commencé)
    return 0;
  };

  return (
    <div className="grid grid-cols-1 gap-3 h-full">
      <div className="flex flex-col bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
        <div className="p-3 flex items-center justify-between">
          <div className="md:flex-auto">
            <h1 className="flex items-center text-base font-semibold text-gray-900 gap-2">
              <span>Tutorials</span>
              <Link to="/learn" className="text-xs text-gray-700 hover:underline">
                View all &gt;
              </Link>
            </h1>
          </div>
        </div>
        <div className="flex px-2 overflow-x-auto flex-1">
          {workshops.sort(sortWorkshops).map((e, i) => (
            <Card key={i} workshop={e} userWorkshop={userWorkshops?.find((w) => w.workshop_id === e._id)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tutorials;

const Card = ({ workshop, userWorkshop }) => {
  function renderStatus() {
    if (!userWorkshop) return null;
    if (userWorkshop.finished_at) return <img src={DoneIcon} />;
    return <Progress value={userWorkshop.step_id} total={workshop.steps.length} />;
  }

  return (
    <Link
      to={`/learn/${workshop._id}`}
      className="flex flex-row no-underline	hover:no-underline rounded-md cursor-pointer hover:shadow-md min-w-[300px] p-2 my-2 mr-1 border border-gray-200">
      <div className="flex flex-row w-full">
        <img src={workshop.image} className="object-cover rounded-xl w-10 h-10" />
        <div className="flex flex-col">
          <div className="text-xs font-semibold text-dull-black flex flex-col mx-2">{workshop.name}</div>
          {workshop.read_time && <div className="text-xs text-dull-grey ml-2">({workshop.read_time} read)</div>}
          <div className="flex flex-row items-center ml-2 mt-auto">
            <svg aria-hidden="true" className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <div className="text-xs ml-2">{`${workshop.rating_score} (${workshop.rating_count})`}</div>
          </div>
        </div>
        <div className="h-6 w-6 shrink-0 ml-auto mt-auto">{renderStatus()}</div>
      </div>
    </Link>
  );
};

const Progress = ({ value, total }) => (
  <CircularProgressbar
    value={value}
    maxValue={total}
    strokeWidth={14}
    styles={buildStyles({
      strokeLinecap: "round",
      pathColor: `#0560FD`,
      trailColor: "#E5EAEF",
      backgroundColor: "transparent",
    })}
  />
);

const Skeleton = () => (
  <div className="bg-white flex flex-col justify-between no-underline	hover:no-underline rounded-md min-w-[300px] p-3 border border-gray-200 h-full overflow-hidden">
    <div className="bg-gray-200 w-24 h-4 rounded-md mb-5" />
    <div className="flex flex-row w-full gap-4 h-full">
      <div className="flex flex-row border border-gray-100 p-2 h-full">
        <div className="bg-gray-200 rounded-xl w-12 h-12 mr-2" />
        <div className="flex flex-col">
          <div className="bg-gray-200 w-24 h-4 rounded-md" />
          <div className="bg-gray-200 w-12 h-2 mt-1" />
          <div className="bg-gray-200 w-12 h-2 mt-1" />
        </div>
      </div>
      <div className="flex flex-row border border-gray-100 p-2">
        <div className="bg-gray-200 rounded-xl w-10 h-10 mr-2" />
        <div className="flex flex-col">
          <div className="bg-gray-200 w-24 h-4 rounded-md" />
          <div className="bg-gray-200 w-12 h-2 mt-1" />
          <div className="bg-gray-200 w-12 h-2 mt-1" />
        </div>
      </div>
      <div className="flex flex-row border border-gray-100 p-2">
        <div className="bg-gray-200 rounded-xl w-10 h-10 mr-2" />
        <div className="flex flex-col">
          <div className="bg-gray-200 w-24 h-4 rounded-md" />
          <div className="bg-gray-200 w-12 h-2 mt-1" />
          <div className="bg-gray-200 w-12 h-2 mt-1" />
        </div>
      </div>
    </div>
  </div>
);
