import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PiStarFill, PiStarLight } from "react-icons/pi";

import { AppContext } from "./context";
import api from "../../../services/api";
import { classNames } from "../../../utils";

export default () => {
  const context = useContext(AppContext);
  const userWorkshop = context.userWorkshop;

  const [rating, setRating] = useState(0);
  const [text, setText] = useState("");

  useEffect(() => {
    if (userWorkshop) {
      setRating(userWorkshop.feedback_rating);
      setText(userWorkshop.feedback_text);
    }
  }, [userWorkshop]);

  const navigate = useNavigate();

  const handleContinue = async () => {
    await api.put(`/workshop_user/${userWorkshop._id}`, { feedback_rating: rating, feedback_text: text });
    toast.success("Feedback submitted !");
    navigate("/learn");
  };

  const workshop = context.workshop;

  if (!workshop || context.index !== workshop.steps.length + 1) return <div />;

  return (
    <div className="p-6 max-w-2xl mx-auto text-center">
      <div>
        <img src={workshop.image} className="w-[300px] object-cover rounded-2xl" />
      </div>
      <div className="text-sm mt-6 mb-2 text-light-grey font-semibold">LESSON COMPLETE</div>
      <div className="text-dull-black text-2xl mb-1 font-bold">{workshop.name}</div>
      <div className="text-base text-dull-grey font-normal mb-6">{workshop.description}</div>
      <div className="mb-6 relative">
        <span className="text-dull-grey text-base font-normal bg-lighter-grey px-3 relative z-10">Did you enjoy this lesson?</span>
        <div className="w-full border border-gray-bord absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
      </div>
      <Rating
        value={rating}
        onChange={async (v) => {
          setRating(v);
          await api.put(`/workshop_user/${userWorkshop._id}`, { feedback_rating: v });
        }}
      />
      <div>
        <div className="text-dull-grey text-base font-normal my-8">{workshop.end_question || "Do you want to share a last thought ?"}</div>
        <textarea className="bg-white shadow-md block w-full h-20 border-0 rounded-xl p-3" placeholder="Add your comments" value={text} onChange={(e) => setText(e.target.value)} />
      </div>
      <button className="btn btn-primary mt-10" onClick={handleContinue}>
        Continue learning
      </button>
    </div>
  );
};

const Rating = ({ value, onChange }) => {
  const [highlightedScore, setHighlightedScore] = useState(value);
  return (
    <div className="flex items-center justify-center text-3xl">
      {[1, 2, 3, 4, 5].map((score) => {
        const isShining = score <= highlightedScore;
        return (
          <div
            className={classNames("px-2", isShining ? "text-yellow-400" : "text-gray-300")}
            onMouseLeave={() => setHighlightedScore(value || 0)}
            onMouseOver={() => setHighlightedScore(score)}
            onClick={() => onChange(score)}>
            {isShining ? <PiStarFill /> : <PiStarLight />}
          </div>
        );
      })}
    </div>
  );
};
