import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";

import RichTextEditorSun from "../../../../components/RichTextEditor";
import Modal from "../../../../components/modal";

import api from "../../../../services/api";

//https://share.streak.com/6DdASS6nUILCSbZP77UwZC

export default ({ project, onCreate }) => {
  const [epicModal, setEpicModal] = useState(false);
  const [value, setValue] = useState("");

  const handleCreate = async () => {
    try {
      const title = value.replace(/<[^>]*>?/gm, "");
      const { ok, data } = await api.post("/epic", { project_id: project._id, title });
      if (!ok) return toast.error("Failed to create epic");
      toast.success("Epic created successfully");
      setEpicModal(false);
      onCreate(data);
      setValue("");
    } catch (error) {
      console.error(error);
      toast.error("Failed to create epic");
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center gap-4">
        <button onClick={() => setEpicModal(true)} className="blue-btn">
          New Epic
        </button>
      </div>

      <Modal className="w-500px mx-auto px-6" isOpen={epicModal} onClose={() => setEpicModal(false)}>
        <form
          className="p-4 flex flex-col items-center "
          onSubmit={(e) => {
            e.preventDefault();
            handleCreate();
          }}>
          <div className="my-2">
            <label htmlFor="title" className="block mb-2">
              Description of the epic
            </label>
            <p className="text-sm text-gray-500  mb-4">
              To write a good epic, clearly define a broad objective that adresses a specific user need or business goal. Include a concise summary with measurable outcomes, and
              ensure it aligns with overall project priorities
            </p>
            <RichTextEditorSun
              buttonList={[
                ["font", "fontSize", "formatBlock"],
                ["bold"],
                ["align", "list", "lineHeight"],
                ["outdent", "indent"],
                ["table", "link", "image"],
                ["removeFormat"],
              ]}
              placeholder="Improve the user onboarding process to increase user retention rates and reduce initial churn. The revamp will include updates to the UI/UX, the introduction of educational content, and the implementation of progressive feature introduction to enhance user engagement"
              values={value}
              options={{ minHeight: "180px" }}
              onChange={(e) => setValue(e)}
            />
          </div>
          <div className="flex justify-end gap-2 mt-2">
            <button type="submit" className="blue-btn">
              Create
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
