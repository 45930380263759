import React, { useState } from "react";
import { useSelector } from "react-redux";

import api from "../../services/api";
import toast from "react-hot-toast";

export default () => {
  const user = useSelector((state) => state.Auth.user);

  const [message, setMessage] = useState(user?.referral_message || getDefaultMessage(user));

  return (
    <div className="px-3 py-6">
      <div className="bg-white p-6 border border-gray-200 mb-10">
        <div className="text-3xl font-bold mb-4">Earn money sharing what you love.</div>
        <div className="text-xl font-semibold mb-4">Would you like to earn a nice bonus by sharing a mission opportunity with us? </div>
        <div className="text-lg mb-4">
          You identify a new assignment opportunity via a customer, a friend, a prospect you hear about...
          <strong className="block">Redirect the offer and earn 12.5% of the profits! </strong>
        </div>
        <div className="text-2xl font-bold mb-4">How does it work?</div>
        <div className="text-base mb-4">1. Copy and paste this message, and send it to your contact. (Linkedin, Email, Whatapps etcc..)</div>
        <div className=" my-4">
          <textarea
            className="block w-full text-base p-4 bg-gray-50 border border-gray-200 rounded-lg h-40"
            placeholder="About your project"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="mt-2 flex items-center space-x-4">
            <button
              onClick={async () => {
                await api.put(`/user`, { referral_message: message });
                toast.success("Saved !");
              }}
              disabled={!message}
              className="btn-primary text-sm">
              Save
            </button>
            <div className="text-sm text-gray-700">Your referral link: {getReferralLink(user)}</div>
          </div>
        </div>
        <div className="text-base mb-4">2. Your contact fills in the form.</div>
        <div className="text-base mb-4">3. We contact them to brief the project.</div>
        <div className="text-base mb-4">4. We get back to you as soon as it's signed.</div>
        <div className="text-base mb-4">5. You have access to your fund.</div>
      </div>
    </div>
  );
};

function getDefaultMessage(user) {
  return `Hello,
I'm part of a startup studio that can take care of your project. They specialize in Tech, Product, Growth! They're the best on the market right now. (PS: They even work
for the government).
You can get in touch with them right here: ${getReferralLink(user)}
Keep me posted,`;
}

function getReferralLink(user) {
  let link = "https://accounting.selego.co/contact";
  if (user) link += `?referral_id=${user._id}`;
  return link;
}
