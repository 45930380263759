import React, { useContext, useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";

import App, { AppContext } from "./context";
import toast from "react-hot-toast";

export default ({}) => {
  const context = useContext(AppContext);

  useEffect(() => {
    document.querySelector("body").style.overflow = "hidden";
    return () => (document.querySelector("body").style.overflow = "auto");
  }, []);

  const Item = ({ element }) => {
    if (element.kind == "text") {
      return <div className="rich-text" dangerouslySetInnerHTML={{ __html: element.value }} />;
    } else if (element.kind == "image") {
      return (
        <div className="mb-5 text-center w-full">
          <img src={element.value?.rawBody || element.value} className={`max-w-fit rounded-md w-full mx-auto`} />
        </div>
      );
    } else if (element.kind == "code") {
      return (
        <div className="mb-5 w-full text-xs rounded-md">
          <SyntaxHighlighter language="javascript" showLineNumbers={true} showInlineLineNumbers={true} style={a11yDark}>
            {element.value}
          </SyntaxHighlighter>
        </div>
      );
    } else if (element.kind == "video") {
      return (
        <div className="mb-5 w-full text-xs rounded-md h-[400px] max-w-4xl mx-auto">
          <video
            src={element.value}
            frameborder="0"
            className="h-full w-full rounded-xl aspect-video"
            controls
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></video>
        </div>
      );
    }

    return <div>No component</div>;
  };

  const Footer = ({}) => {
    useEffect(() => {
      function handleSpaceBar(evt) {
        if (document.activeElement.tagName === "TEXTAREA" || document.activeElement.tagName === "INPUT") return;
        if (evt.key == " " || evt.code == "Space" || evt.code == 32) onContinue();
      }
      document.body.addEventListener("keyup", handleSpaceBar);
      return () => document.body.removeEventListener("keyup", handleSpaceBar);
    }, []);

    const onContinue = () => {
      context.next();
    };

    return (
      <div className="space-y-3 w-full p-3 fixed bottom-0 inset-x-0 bg-gray-100 border-t border-gray-200">
        <div className="flex items-center justify-center max-w-7xl mx-auto px-3 space-x-16">
          {context.index >= 2 ? (
            <button
              onClick={() => context.setIndex(context.index - 1)}
              className="group flex gap-2 items-center justify-around bg-primary disabled:pointer-events-none disabled:bg-half-grey disabled:cursor-not-allowed text-xl text-white px-9 py-3 rounded-md border shadow-sm transition-colors">
              <MdArrowBackIosNew className="group-hover:-translate-x-2 transition-transform" />
              Previous
            </button>
          ) : null}
          <button
            className="group flex gap-2 items-center justify-around bg-primary hover:bg-primary-500 text-xl text-white px-9 py-3 rounded-md border shadow-sm transition-colors"
            onClick={onContinue}>
            Continue
            <MdArrowForwardIos className="group-hover:translate-x-2 transition-transform" />
          </button>
        </div>
      </div>
    );
  };

  const arr = [];

  for (let j = 0; j < context.workshop.steps.length; j++) {
    const step = context.workshop.steps[j];
    arr.push(<h1 className="m-2">{step.title}</h1>);
    for (let i = 0; i < step.elements.length; i++) {
      arr.push(<Item element={step.elements[i]} />);
    }
  }

  return (
    <>
      <div className="pr-2 w-full">
        <div className={`items-center gap-4 pr-2`}>{arr}</div>;
      </div>
      <Footer />
    </>
  );
};
