import React, { useState } from "react";

import Expenses from "./expenses";
import Services from "./services";
import ServicesGraph from "./services-graph";
import ExpensesGraph from "./expenses-graph";
import Activities from "./activities";

import SelectMonth from "../../../../components/selectMonth";
import SelectBudget from "../../../../components/SelectBudget";

const Breakdown = ({ project }) => {
  const [filters, setFilters] = useState({
    year: new Date().getFullYear(),
    date: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, "0")}`,
    budget: null,
  });
  return (
    <div className="py-2 space-y-4">
      <div className="flex flex-row items-center space-x-4">
        <div className="w-1/4">
          <SelectBudget
            value={filters.budget}
            onChange={(e) => setFilters({ ...filters, budget: e, date: "", year: "" })}
            projectId={project._id}
            placeholder={filters.date ? "Select a Budget" : ""}
          />
        </div>

        <div className="w-1/4">
          <SelectMonth
            start={-3}
            indexDefaultValue={3}
            value={filters.date ? filters.date : ""}
            onChange={(e) => setFilters({ ...filters, date: e.target.value, budget: "" })}
            placeholder={filters.budget ? "Select a Month" : ""}
          />
        </div>
      </div>
      <div className="flex w-full flex-col">
        <div className="flex flex-row items-center space-x-4 mb-4 w-full">
          <div className="border p-4 rounded-lg mb-auto w-1/2">
            <Services project={project} filters={filters} />
          </div>
          <div className="border p-4 rounded-lg mb-auto w-1/2">
            <Expenses project={project} filters={filters} />
          </div>
        </div>

        <div className="flex-1 space-y-4">
          <div className="border rounded-lg  p-4">
            <Activities project={project} />
          </div>
          <div className="border rounded-lg">
            <ServicesGraph project={project} filters={filters} />
          </div>
          <div className="border p-4 rounded-lg">
            <h2 className="text-sm font-medium">Expenses breakdown (€) (5 last months)</h2>
            <ExpensesGraph project={project} filters={filters} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breakdown;
