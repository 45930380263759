

const CONTRACTS = {
  CDD: "CDD",
  CDI: "CDI",
  VIE: "VIE",
  INTERNSHIP: "INTERNSHIP",
  APPRENTICESHIP: "APPRENTICESHIP",
  FREELANCE: "FREELANCE",
}

const LOCATION = {
  REMOTE: "REMOTE",
  PARIS: "PARIS",
  AMSTERDAM: "AMSTERDAM",
  BARCELONE: "BARCELONE",
}

const DIVISION = {
  LE_COLLECTIF: "LE_COLLECTIF",
  SELEGO_FR: "SELEGO_FR",
  SELEGO_NL: "SELEGO_NL",
  SELESPAGNE: "SELESPAGNE"
}

const YEARSSCHOOL = {
  FIRSTYEAR: "FIRSTYEAR",
  SECONDYEAR: "SECONDYEAR",
  THIRDYEAR: "THIRDYEAR",
  FOURTHYEAR: "FOURTHYEAR",
  FIFTHYEAR: "FIFTHYEAR",
}

const ROLES = {
  FULLSTACK: "FULLSTACK",
  DATA: "DATA",
  DEVOPS: "DEVOPS",
  PRODUCT: "PRODUCT",
  SALES: "SALES",
  GROWTH:"GROWTH"
}

const SCHOOL = {
  ENGINEERING: "ENGINEERING",
  BOOTCAMP: "BOOTCAMP",
  OTHER: "OTHER"
}

const MANAGEMENT = {
  NO: "NO",
  LEAD: "LEAD / MANAGER",
  HEADOF: "HEAD OF / DIRECTOR / CHIEF"
}

export { CONTRACTS, LOCATION, DIVISION, YEARSSCHOOL, ROLES, SCHOOL, MANAGEMENT }