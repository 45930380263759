import React from "react";
import { IoArrowForwardOutline } from "react-icons/io5";

import Loader from "../../../components/loader";
import { classNames } from "../../../utils";

const ProgressCardSubCategory = ({ subCategory, category, answers, questions, isSelected, onClick }) => {
  const total = questions.filter((q) => q.skillSubCategory === subCategory).length;
  const questionsBySubCategory = questions.filter((q) => q.skillSubCategory === subCategory);
  const answeredQuestions = answers.filter((a) => questionsBySubCategory.find((q) => q._id === a.questionId));

  let levelValidated = 0;
  for (let i = 1; i <= 5; i++) {
    // if there is no question for this level, we consider the level validated
    const questionsForThisLevel = questionsBySubCategory.filter((question) => question.level === i);
    if (!questionsForThisLevel.length) {
      levelValidated = i;
      continue;
    }

    // get all answers for this subCategory and this level
    const answersForThisLevel = answeredQuestions.filter((answer) => answer.levelValue === i);
    // if there is at least one falsy answer for this level, we consider the level not validated and we stop the loop
    if (!answersForThisLevel.length || answersForThisLevel.find((answer) => !parseInt(answer.value))) {
      break;
    }
    levelValidated = i;
  }
  const bgColors = {
    Code: "from-cyan-600 to-cyan-400",
    Communication: "from-emerald-600 to-emerald-400",
    Leadership: "from-fuchsia-600 to-fuchsia-400",
    Product: "from-yellow-600 to-yellow-400",
  };

  if (!answers) return <Loader />;

  return (
    <button
      className={`group relative flex w-[200px] h-[80px] flex-col justify-between items-center m-1 rounded-lg shadow  border-neutral-200 overflow-hidden border bg-white`}
      onClick={onClick}>
      <div
        className={classNames(
          `absolute rounded-full -top-[100px] -right-[200px] bottom-0 -left-[200px] w-auto bg-green-300 transition ease-in-out bg-gradient-to-b`,
          bgColors[category],
          isSelected ? "" : "-translate-y-14 group-hover:translate-y-0",
        )}></div>
      <p className="text-white text-left z-10 text-xs uppercase tracking-widest mt-1">{subCategory}</p>
      <div className="flex flex-row ">
        <div className="text-transition ease-in-out ">
          <Score level={levelValidated} />
        </div>
        <div className={`absolute bottom-1 w-full items-center justify-center text-neutral-500  transition ease-in-out group-hover:translate-y-20`}>
          {Math.round((answeredQuestions.length / total) * 100)}%
        </div>
        <span className={`absolute  -bottom-10 flex w-full items-center justify-center text-white font-bold transition ease-in-out group-hover:-translate-y-12`}>
          Go <IoArrowForwardOutline />
        </span>
      </div>
    </button>
  );
};

const Score = ({ level = "x" }) => {
  return (
    <div className="relative flex items-center justify-center w-16 h-16  rounded-full px-3">
      <div className="absolute flex flex-col items-center">
        <div className="text-neutral-700 tracking-[.2em] text-xs">LEVEL</div>
        <div className="text-base font-semibold">{level}</div>
      </div>
    </div>
  );
};

export default ProgressCardSubCategory;
