import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import toast from "react-hot-toast";
import Loader from "../../../components/loader";

import { CATEGORIES_KEY_WORDS } from "../../../constants";

const KeyWords = ({ userId }) => {
  const [keyWords, setKeyWords] = useState();
  const [values, setValues] = useState({ key_words: [] });
  const [loading, setLoading] = useState(false);
  const [isModifying, setIsModifying] = useState(false);
  const [keyWordsDisplayed, setKeyWordsDisplayed] = useState([]);

  async function get() {
    const resKeywords = await api.post(`/key_word/search`);
    setKeyWords(resKeywords.data);
    const resUser = await api.get(`/user/${userId}`);
    setValues(resUser.data);
    let a = [];

    CATEGORIES_KEY_WORDS.map((category) => {
      const keyWords = resUser.data.key_words.filter((item) => item.category === category);
      if (keyWords.length > 0) a.push({ category: category, keyWords: keyWords });
    }),
      setKeyWordsDisplayed(a);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  const handleSetKeyWords = (e) => {
    if (values.key_words.some((keyWord) => keyWord.name === e.name)) {
      const newKeyWords = values.key_words.filter((keyWord) => keyWord.name !== e.name);
      setValues({ ...values, key_words: newKeyWords });
    } else {
      const newKeyWords = [...values.key_words, { name: e.name, category: e.category, likes: [] }];
      setValues({ ...values, key_words: newKeyWords });
    }
  };

  const updateUser = async () => {
    try {
      const { data } = await api.put(`/user/${userId}`, values);
      get();
      setValues(data);
      setIsModifying(false);
      toast.success("Key words updated");
    } catch (error) {
      console.error("error", error);
    }
  };

  if (loading) return <Loader />;
  return (
    <div className="p-2">
      {isModifying ? (
        <div>
          <div className="flex flex-row  items-center gap-1 mb-4  ">
            <span className="font-bold text-lg ">Keywords</span>
            <button className="btn btn-primary  ml-6 " onClick={updateUser}>
              Update
            </button>
            <button className="btn btn-primary bg-gray-200 text-gray-600 " onClick={() => setIsModifying(false)}>
              Cancel
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {CATEGORIES_KEY_WORDS.map((category) => (
              <div key={category} className="mb-4 border border-blue-200 rounded-lg p-2 max-w-52">
                <h2 className="text-base font-bold mb-2">{category}</h2>
                <div className="flex flex-wrap gap-2">
                  {keyWords &&
                    keyWords.filter((item) => item.category === category).length > 0 &&
                    keyWords
                      .filter((item) => item.category === category)
                      .map((item) => (
                        <button
                          key={item.id}
                          className={`inline-flex items-center  rounded-2xl border-2 px-2 py-1 ${
                            values?.key_words.some((keyWord) => keyWord.name === item.name) ? "bg-primary-600 text-white border-primary-600 hover:bg-primary-800" : " hover:bg-gray-200"
                          }`}
                          value={item.name}
                          onClick={() => handleSetKeyWords(item)}>
                          <span className="text-sm">{item.name}</span>
                        </button>
                      ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-row  items-center gap-1 mb-4">
            <span className="font-bold text-lg ">Keywords</span>
            <button className="btn btn-primary ml-6" onClick={() => setIsModifying(true)}>
              Edit
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {keyWordsDisplayed.map((item) => (
              <div key={item.category} className="mb-4 border border-blue-200 rounded-lg p-2 ">
                <h2 className="text-base font-bold mb-2">{item.category}</h2>
                <div className="flex flex-wrap gap-2">
                  {item.keyWords.map((kw) => (
                    <div key={kw.id} className="inline-flex items-center  rounded-2xl border px-2 py-1   border-blue-600 ">
                      <span className="text-sm">{kw.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default KeyWords;
