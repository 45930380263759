import React, { useState } from "react";
import { IoInformationCircle, IoPencil, IoStatsChart } from "react-icons/io5";
import { classNames } from "../../../utils";
import Create from "./Create";
import List from "./list";
import Kanban from "./kanban";
import Stats from "./Stats";
import { useSelector } from "react-redux";

const TABS = {
  LIST: "All",
  STATS: "Stats",
  KANBAN: "Kanban",
};

export default () => {
  const [tab, setTab] = useState(TABS.KANBAN);
  const { user } = useSelector((state) => state.Auth);

  const render = () => {
    if (tab === TABS.LIST) return <List />;
    if (tab === TABS.STATS) return <Stats />;
    if (tab === TABS.KANBAN) return <Kanban />;
    return <div />;
  };

  return (
    <div>
      <div className="m-2">
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden justify-between">
          <div>
            <TabItem title={TABS.KANBAN} tab={TABS.KANBAN} setTab={setTab} active={tab === TABS.KANBAN} Icon={IoInformationCircle} />
            <TabItem title={TABS.LIST} tab={TABS.LIST} setTab={setTab} active={tab === TABS.LIST} Icon={IoPencil} />
            {user.role === "admin" && <TabItem title={TABS.STATS} tab={TABS.STATS} setTab={setTab} active={tab === TABS.STATS} Icon={IoStatsChart} />}
          </div>
          <div>
            <Create />
          </div>
        </nav>
        <div className="my-2" />
        <div className="w-full">{render()}</div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-primary  border-primary" : "",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {/* {Badge ? Badge : null} */}
  </button>
);
