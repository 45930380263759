/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import api from "../../services/api";

export default ({ bank }) => {
  const [banks, setBanks] = useState(null);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const { data } = await api.post(`/bank/search`, { name: bank.name, limit: 10 });
    const newArr = data.banks.filter((e) => e._id !== bank._id);
    setBanks(newArr);
  }

  if (banks === null) return <div>Loading...</div>;
  if (banks.length === 0) return <div>No similar mutations found</div>;

  return (
    <div className="text-sm">
      <div>Similar mutations</div>
      <div className="overflow-x-auto bg-white mt-2">
        <table className="md:w-full table-auto">
          <thead className="border-t border-b-2	 border-t-[#dee2e6] border-l-0 border-r-0 border-b-[#dee2e6]">
            <tr>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Date
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Name
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Amount
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Type
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Category
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Project
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Country
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Tax
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Doc
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Status
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {(banks || []).map((hit, index) => {
              return (
                <tr key={hit._id} className="hover:bg-[#dee2e6] border-b border-[#dee2e6]">
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <div>
                      <div>{hit.date.slice(0, 10)}</div>
                      <div className="text-gray-400 text-xs">created at {new Date(hit.created_at).toLocaleString()}</div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <div>{hit.name?.substring(0, 20)}</div>
                    <div className="text-gray-400 text-xs">{hit.description?.substring(0, 20)}...</div>
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <div className="flex items-center gap-1">{hit.amount}</div>
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    {hit.source} {hit.type}
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.category}</td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.projectName}</td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.country}</td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{`${(hit.tax || 0).toFixed()}€`}</td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <Files files={hit.files} />
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <Status status={hit.status} />
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <a href={`/bank/${hit._id}`} target="_blank">
                      <button className="bg-blue-500 hover:bg-blue-700 text-white my-1 px-1">open</button>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Files = ({ files }) => {
  if (files.length === 0) return <div>❌</div>;
  return files.map((e) => {
    return (
      <a key={e} href={e} target="_blank" className="cursor-pointer">
        🧾
      </a>
    );
  });
};

const Status = ({ status }) => {
  const selected = status === "DONE";
  let color = selected ? "bg-[#28a745]" : "bg-[#dc3545]";
  return <div className={`w-16 mt-[1px] h-4 flex justify-center items-center text-[#fff] text-[75%] font-bold ${color}`}>{status}</div>;
};
