import React from "react";
import moment from "moment";
import { classNames } from "../../utils";

const CalendarDaysOff = ({ filter, days, data }) => {
  return (
    <div className="flex flex-col">
      <div className="my-2" />
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-5 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">
            M<span className="sr-only md:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only md:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only md:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only md:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only md:not-sr-only">ri</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          {/* view full */}
          <div className="hidden w-full lg:grid lg:grid-cols-5 lg:gap-px">
            {days.map((day) => {
              //if it is a week end day, do not display it
              const weekday = moment(day.date).format("dddd");
              if (weekday === "Sunday" || weekday === "Saturday") return null;
              return (
                <div key={day.date} className={classNames(day.isSameMonth ? "bg-[#ffffff]" : "bg-gray-100 text-gray-500", day.isToday ? "bg-blue-50" : "", "relative py-2 px-3")}>
                  <time dateTime={day.date} className={day.isToday ? "flex h-6 w-6 items-center justify-center rounded-full bg-blue-600 font-semibold text-white" : undefined}>
                    {moment(day.date).format("D")}
                  </time>
                  {day.isSameMonth && data?.length > 0 && (
                    <ol className="mt-2">
                      {data
                        ?.filter((item) => {
                          const hasDayOffThisDay = item.daysOff.some((arr) => {
                            return arr.some((e) => moment(day.date).format("D").toString() === (e.day + 1).toString());
                          });
                          const isDisplayed = filter.users.includes(item.userId) || filter.users.length === 0;
                          return hasDayOffThisDay && isDisplayed;
                        })
                        ?.map((item, i) => {
                          const currentStream = item.daysOff.find((arr) => {
                            return arr.some((e) => moment(day.date).format("D").toString() === (e.day + 1).toString());
                          });
                          return (
                            <div key={`${day.date}-${item.userName}-${i}`} className="flex items-center gap-1">
                              <img src={item.userAvatar} alt="userlogo" className="rounded-full w-4 h-4" />
                              {item.userName}{" "}
                              {currentStream.length > 1 ? `(${currentStream.findIndex((e) => e + 1 === +moment(day.date).format("D")) + 1}/${currentStream.length})` : null}
                            </div>
                          );
                        })}
                    </ol>
                  )}
                </div>
              );
            })}
          </div>

          {/* TODO : view condensate */}
          <div className="isolate grid w-full grid-cols-5 gap-px lg:hidden">
            {days.map((day) => {
              const weekday = moment(day.date).format("dddd");
              if (weekday === "Sunday" || weekday === "Saturday") return null;
              return (
                <div key={day.date} className={classNames(day.isSameMonth ? "bg-[#ffffff]" : "bg-gray-100 text-gray-500", day.isToday ? "bg-blue-50" : "", "relative py-2 px-3")}>
                  <time dateTime={day.date} className={day.isToday ? "flex h-6 w-6 items-center justify-center rounded-full bg-blue-600 font-semibold text-white" : undefined}>
                    {moment(day.date).format("D")}
                  </time>

                  {data?.length > 0 && (
                    <ol className="grid grid-cols-2 gap-px mt-1">
                      {data
                        ?.filter((item) => {
                          return item.daysOff.some((arr) => {
                            return arr.some((e) => moment(day.date).format("D").toString() === (e + 1).toString());
                          });
                        })
                        ?.map((item, i) => {
                          return (
                            <div key={`${day.date}-${item.userName}-${i}`} className="flex items-center gap-1">
                              <img src={item.userAvatar} alt="userlogo" className="rounded-full w-4 h-4" />
                            </div>
                          );
                        })}
                    </ol>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarDaysOff;
