import React, { useState } from "react";
import toast from "react-hot-toast";

import API from "../../../services/api";

export default ({ contact, setContact }) => {

  const handleDateChange = async (date) => {
    try {
      const { ok, data } = await API.put(`/folk/${contact._id}`, date);
      if (ok) toast.success("Date updated");
      setContact(data)
    } catch (e) {
      console.log(e);
      toast.error("Error updating date");
    }
  }

  return (
    <div>
      <div className="p-2">
        <div>
          {contact.status_created_date && <div className="grid grid-cols-5 items-center space-x-2 space-y-2"><span>Status created at</span> <DateInput value={contact.status_created_date} onChange={date => handleDateChange({ status_created_date: date })} /></div>}
          {contact.status_prospection_date && <div className="grid grid-cols-5 items-center space-x-2 space-y-2"><span>Status prospection at</span> <DateInput value={contact.status_prospection_date} onChange={date => handleDateChange({ status_prospection_date: date })} /></div>}
          {contact.status_qualified_date && <div className="grid grid-cols-5 items-center space-x-2 space-y-2"><span>Status qualified at</span> <DateInput value={contact.status_qualified_date} onChange={date => handleDateChange({ status_qualified_date: date })} /></div>}
          {contact.status_discovery_date && <div className="grid grid-cols-5 items-center space-x-2 space-y-2"><span>Status discovery at</span> <DateInput value={contact.status_discovery_date} onChange={date => handleDateChange({ status_discovery_date: date })} /></div>}
          {contact.status_proposal_date && <div className="grid grid-cols-5 items-center space-x-2 space-y-2"><span>Status proposal at</span> <DateInput value={contact.status_proposal_date} onChange={date => handleDateChange({ status_proposal_date: date })} /></div>}
          {contact.status_negotiation_date && <div className="grid grid-cols-5 items-center space-x-2 space-y-2"><span>Status negotiation at</span> <DateInput value={contact.status_negotiation_date} onChange={date => handleDateChange({ status_negotiation_date: date })} /></div>}
          {contact.status_closing_date && <div className="grid grid-cols-5 items-center space-x-2 space-y-2"><span>Status closing at</span> <DateInput value={contact.status_closing_date} onChange={date => handleDateChange({ status_closing_date: date })} /></div>}
          {contact.status_won_date && <div className="grid grid-cols-5 items-center space-x-2 space-y-2"><span>Status won at</span> <DateInput value={contact.status_won_date} onChange={date => handleDateChange({ status_won_date: date })} /></div>}
          {contact.status_lost_date && <div className="grid grid-cols-5 items-center space-x-2 space-y-2"><span>Status lost at</span> <DateInput value={contact.status_lost_date} onChange={date => handleDateChange({ status_lost_date: date })} /></div>}
        </div>
      </div>
    </div>
  );
};

const DateInput = ({ value, onChange }) => {
  const [date, setDate] = useState(value);

  return (
    <input
      className="input"
      type="date"
      value={date && new Date(date).toISOString().split("T")[0]}
      onChange={(e) => {
        setDate(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

// status_created_date: { type: Date },
// status_prospection_date: { type: Date },
// status_qualified_date: { type: Date },
// status_discovery_date: { type: Date },
// status_proposal_date: { type: Date },
// status_negotiation_date: { type: Date },
// status_closing_date: { type: Date },
// status_won_date: { type: Date },
// status_lost_date: { type: Date },
