import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../components/loader";
import api from "../../../services/api";

import BankModal from "../../../components/bankModal";

const InvoiceView = ({ mail, setMail }) => {
  // const [project, setProject] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();

  if (!mail) return <Loader />;

  async function deleteData() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/mail/${id}`);
    toast.success("successfully removed!");
    navigate(`/mail`);
  }

  async function runParsio() {
    await api.post(`/mail/parsio/${id}`);
    toast.success("successfully");
  }

  return (
    <div>
      <div className="">
        <div className="flex items-center mt-2 mb-4 space-x-4">
          <div className="">
            <select
              className="max-w-sm projectsInput"
              value={mail.status}
              onChange={async (e) => {
                const { data } = await api.put(`/mail/${mail._id}`, { status: e.target.value });
                setMail(data);
                toast.success("Invoice updated");
              }}>
              <option value="open">Open</option>
              <option value="close">Close</option>
            </select>
          </div>
        </div>
        <Document mail={mail} />
        <div className="flex items-center my-2">
          <button className="ml-[10px] bg-[#dc3545] hover:bg-[#c82333] text-[1rem] text-[#fff] py-[0.375rem] px-[0.75rem] rounded-[0.25rem]" onClick={deleteData}>
            Delete
          </button>
          <button className="ml-[10px] bg-[#dc3545] hover:bg-[#c82333] text-[1rem] text-[#fff] py-[0.375rem] px-[0.75rem] rounded-[0.25rem]" onClick={runParsio}>
            Run parsio
          </button>
        </div>
      </div>
    </div>
  );
};

const Document = ({ mail }) => {
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);

  if (!mail.parsio) return <div>No parsio data</div>;

  const obj = mail.parsio.payload.parsed;

  useEffect(() => {
    getBanks();
  }, []);

  async function getBanks() {
    // get all banks one day before and one day after

    const filter = {};
    filter.per_page = 50;
    const date = new Date(obj.InvoiceDate || obj.created_at);
    filter.startDate = new Date(date.setDate(date.getDate() - 1)).toISOString();
    filter.endDate = new Date(date.setDate(date.getDate() + 2)).toISOString();

    const { data } = await api.post("/bank/search", filter);

    // remove amount if 30% higher or lower
    data.banks = data.banks.filter((bank) => {
      return -bank.amount < obj.InvoiceTotal.amount * 1.3 && -bank.amount > obj.InvoiceTotal.amount * 0.7;
    });
    // sort the closer of the amount
    data.banks.sort((a, b) => {
      const amountA = Math.abs(-a.amount - obj.InvoiceTotal.amount);
      const amountB = Math.abs(-b.amount - obj.InvoiceTotal.amount);
      return amountA - amountB;
    });
    setBanks(data.banks);
  }

  return (
    <div className="p-4 bg-gray-100 rounded-lg shadow">
      <BankModal bank={selectedBank} setBank={() => setSelectedBank(null)} />
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col space-y-2">
          <div>
            <span className="text-sm font-semibold text-gray-700">Vendor Name:</span>
            <span className="text-sm text-gray-600 ml-2">{obj.VendorName}</span>
          </div>
          <div>
            <span className="text-sm font-semibold text-gray-700">Vendor Tax ID:</span>
            <span className="text-sm text-gray-600 ml-2">{obj.VendorTaxId}</span>
          </div>
          <div>
            <span className="text-sm font-semibold text-gray-700">Address:</span>
            <span className="text-sm text-gray-600 ml-2">{obj.VendorAddressRecipient}</span>
          </div>
          <div>
            <span className="text-sm font-semibold text-gray-700">Date:</span>
            <span className="text-sm text-green-600 ml-2">{new Date(obj.InvoiceDate).toLocaleString()} </span>
          </div>

          <div>
            <span className="text-sm font-semibold text-gray-700">Amount:</span>
            <span className="text-sm text-green-600 ml-2">
              {obj.InvoiceTotal?.amount} {obj.InvoiceTotal?.currencyCode}
            </span>
          </div>
          <div>
            <span className="text-sm font-semibold text-gray-700">Taxes:</span>
            <span className="text-sm text-red-600 ml-2">
              {obj.TotalTax?.amount} {obj?.TotalTax?.currencyCode}
            </span>
          </div>
        </div>
        <div>
          <div>Banks</div>
          {banks.map((bank) => {
            return (
              <div key={bank._id} className="p-2 bg-white shadow-md rounded-lg my-2 flex justify-between">
                <div>
                  <div className="flex flex-row cursor-pointer">
                    <div className="mr-2">{bank.name}</div>
                    <div>{bank.amount}€</div>
                    <span className={`${bank.status === "DONE" ? "bg-green-500" : "bg-red-500"} ml-2 text-white text-xs rounded-md p-1`}>{bank.status} </span>
                    <Files files={bank.files} />
                  </div>
                  <div>
                    <div>{new Date(bank.date).toLocaleString()}</div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white my-1 px-1 rounded-sm" onClick={() => setSelectedBank(bank)}>
                    View
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white my-1 px-1 rounded-sm"
                    onClick={async () => {
                      if (!(mail.files || []).length) return toast.error("No files in mail");
                      const files = bank.files || [];
                      files.push(...mail.files);
                      await api.put(`/bank/${bank._id}`, { files });
                      const file = mail.files[0].split("/").pop();
                      toast.success(`File ${file} added`);
                    }}>
                    Add file
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Files = ({ files }) => {
  if (files.length === 0) return <div />;
  return files.map((e) => {
    return (
      <a key={e} href={e} target="_blank" className="cursor-pointer">
        🧾
      </a>
    );
  });
};

//
export default InvoiceView;
