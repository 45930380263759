import { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const MultipleSelectInput = ({ value, label = "", placeholder = "", onChange }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue("");
  }, [value]);

  return (
    <div className="space-y-2">
      <label className="text-sm font-semibold" htmlFor={label.toString()}>
        {label}
      </label>
      <div className="relative mt-2">
        <TagsInput
          className="input"
          inputProps={{ placeholder, className: "border-none p-0 focus:ring-0 focus:outline-none" }}
          renderLayout={(tagComponents, inputComponent) => (
            <div className="flex gap-2 flex-wrap">
              <div className="flex gap-1 items-center flex-wrap">{tagComponents}</div>
              {inputComponent}
            </div>
          )}
          renderTag={({ tag, key }) => (
            <span key={key} className="blue-btn">
              {tag}
              <button onClick={() => onChange(value.filter((e) => e !== tag))} className="bg-transparent hover focus:outline-none ml-2 font-bold ">
                &times;
              </button>
            </span>
          )}
          value={value}
          onChange={onChange}
          inputValue={inputValue}
          onChangeInput={setInputValue}
        />
      </div>
    </div>
  );
};

export default MultipleSelectInput;
