import React, { useEffect, useState } from "react";
import api from "../../services/api";

const EveningTalesSupport = () => {

    // make a static support page with my email and phone number


    return (
        <div className="min-h-screen bg-blue-50 flex flex-col items-center py-10">
        <header className="text-center">
            <h1 className="text-4xl text-blue-900 font-bold mb-4">Support Page</h1>
            <p className="text-blue-700">Having trouble? We're here to help!</p>
        </header>
        <div className="w-full max-w-md p-5 bg-white rounded-lg shadow-md mt-6">
            <h2 className="text-2xl text-blue-900 font-bold mb-3">Frequently Asked Questions</h2>
            <ul className="space-y-2">
                <li className="border-b pb-2">
                    <h3 className="font-semibold text-blue-800">How do I access the books?</h3>
                    <p>The books can be accessed directly through our app on your dashboard.</p>
                </li>
                <li className="border-b pb-2">
                    <h3 className="font-semibold text-blue-800">Are there any subscription fees?</h3>
                    <p>No, all books are free with your registration.</p>
                </li>
                <li>
                    <h3 className="font-semibold text-blue-800">What age group are the books for?</h3>
                    <p>Our books are suitable for children aged 3 to 8 years old.</p>
                </li>
            </ul>
        </div>
        <div className="w-full max-w-md p-5 bg-white rounded-lg shadow-md mt-6 text-center">
                <h2 className="text-2xl text-blue-900 font-bold mb-3">Contact Us Directly</h2>
                <p className="text-blue-800">Feel free to reach out via email:</p>
                <a href="mailto:deajace99@gmail.com" className="text-blue-600 hover:text-blue-800 underline">
                    deborah.jace@selego.co
                </a>
            </div>
    </div>
    );
            

 
};


export default EveningTalesSupport;
