import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import api from "../../../services/api";

import SelectUser from "../../../components/selectUser";

export default ({ workshop, setWorkshop }) => {
  const navigate = useNavigate();

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/workshop/${workshop._id}`);
    toast.success("successfully removed!");
    navigate("/learn-editor");
  }

  async function onSave() {
    const { data } = await api.put(`/workshop/${workshop._id}`, workshop);
    toast.success("successfully updated!");
    setWorkshop(data);
  }

  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden p-3">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="px-1 text-sm text-gray-600 font-medium">Name</div>
          <input
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={workshop.name}
            onChange={(e) => setWorkshop({ ...workshop, name: e.target.value })}
          />
        </div>
        <div>
          <div className="px-1 text-sm text-gray-600 font-medium">Tags</div>
          <input
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={workshop.tags}
            onChange={(e) => setWorkshop({ ...workshop, tags: e.target.value })}
          />
        </div>
        <div className="w-full">
          <div className="px-1 text-sm text-gray-600 font-medium">Category</div>
          <select
            className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            type="select"
            value={workshop.category || ""}
            onChange={(e) => setWorkshop({ ...workshop, category: e.target.value })}>
            <option disabled>Category</option>
            <option value="">No category</option>
            <option value="challenge">challenge</option>
            <option value="lesson">lesson</option>
            <option value="profil">profil</option>
            <option value="admin">admin</option>
            <option value="draft">draft</option>
            <option value="mortem">mortem</option>
          </select>
        </div>

        <div className="w-full">
          <div className="px-1 text-sm text-gray-600 font-medium">Axe</div>
          <select
            placeholder="toto"
            className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            type="select"
            value={workshop.axe || ""}
            onChange={(e) => setWorkshop({ ...workshop, axe: e.target.value })}>
            <option disabled>Axe</option>
            <option value="">No axe</option>
            <option value="code">code</option>
            <option value="leadership">leadership</option>
            <option value="communication">communication</option>
            <option value="business">business</option>
            <option value="productivity">productivity</option>
            <option value="admin">admin</option>
            <option value="investment">investment</option>
            <option value="product">product</option>
            <option value="gtm">gtm</option>
            <option value="data">data</option>
          </select>
        </div>

        <div className="w-full">
          <div className="px-1 text-sm text-gray-600 font-medium">Type</div>
          <select
            className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            type="select"
            value={workshop.type || ""}
            onChange={(e) => setWorkshop({ ...workshop, type: e.target.value })}>
            <option disabled>Type</option>
            <option value="">No type</option>
            <option value="book">book</option>
            <option value="tutorial">tutorial</option>
            <option value="mooc">mooc</option>
            <option value="other">other</option>
          </select>
        </div>

        <div className="w-full">
          <div className="px-1 text-sm text-gray-600 font-medium">Level</div>
          <select
            className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            type="select"
            value={workshop.level || ""}
            onChange={(e) => setWorkshop({ ...workshop, level: e.target.value })}>
            <option disabled>Level</option>
            <option value="">No level</option>
            <option value="beginner">beginner</option>
            <option value="intermediate">intermediate</option>
            <option value="advanced">advanced</option>
          </select>
        </div>
        <div>
          <div className="px-1 text-sm text-gray-600 font-medium">Description</div>
          <textarea
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={workshop.description}
            rows="6"
            onChange={(e) => setWorkshop({ ...workshop, description: e.target.value })}
          />
        </div>
        <div>
          <div className="px-1 text-sm text-gray-600 font-medium">End screen question</div>
          <textarea
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={workshop.end_question}
            rows="6"
            onChange={(e) => setWorkshop({ ...workshop, end_question: e.target.value })}
          />
        </div>
        <div>
          <div className="px-1 text-sm text-gray-600 font-medium">Time to read</div>
          <input
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            value={workshop.read_time}
            placeholder="10 mins"
            onChange={(e) => setWorkshop({ ...workshop, read_time: e.target.value })}
          />
        </div>
        <div>
          <div className="px-1 text-sm text-gray-600 font-medium">User</div>
          <SelectUser value={workshop.user_id} onChange={(user) => setWorkshop({ ...workshop, user_id: user._id, user_name: user.name, user_avatar: user.avatar })} />
        </div>
        <div className="w-full self-center">
          <label className="flex items-center space-x-2 cursor-pointer">
            <input type="checkbox" checked={workshop.public} onChange={(e) => setWorkshop({ ...workshop, public: e.target.checked })} />
            <span className="text-sm font-normal text-gray-500">Is public ?</span>
          </label>
        </div>
        <div className="w-full self-center">
          <label className="flex items-center space-x-2 cursor-pointer">
            <input type="checkbox" checked={workshop.published} onChange={(e) => setWorkshop({ ...workshop, published: e.target.checked })} />
            <span className="text-sm font-normal text-gray-500">Published ?</span>
          </label>
        </div>
      </div>
      <hr />
      <div>
        <div className="flex  mt-2">
          <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={onSave}>
            Update
          </button>
          <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
