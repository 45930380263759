import React from "react";
import { Formik, Field } from "formik";
import queryString from "query-string";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import api from "../../services/api";
import LoadingButton from "../../components/loadingButton";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="authWrapper font-myfont">
      <div className="font-[Helvetica] text-center text-[32px] font-semibold	mb-[15px]">Create new Password</div>
      <Formik
        initialValues={{ password: "", password1: "" }}
        onSubmit={async (values, actions) => {
          try {
            const { token } = queryString.parse(location.search);
            const res = await api.post("/user/forgot_password_reset", { ...values, token });
            if (!res.ok) throw res;
            toast.success("Success!");
            navigate("/");
          } catch (e) {
            toast.error(`Error\n${e && e.code}`);
          }
          actions.setSubmitting(false);
        }}>
        {({ values, errors, isSubmitting, handleChange, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="border-[1px] border-gray-200 bg-gray-50 text-gray-500 p-2 rounded-md italic">Format : minimum 6 characters, at least one letter</div>
              <div className="mb-[25px] mt-4">
                <div className="flex flex-col-reverse">
                  <Field className="peer signInInputs" name="password" type="password" id="password" value={values.password} onChange={handleChange} />
                  <label className="peer-focus:text-[#116eee]" htmlFor="password">
                    New Password
                  </label>
                </div>
              </div>
              <div className="mb-[25px]">
                <div className="flex flex-col-reverse">
                  <Field className="peer signInInputs " name="password1" type="password" id="password1" value={values.password1} onChange={handleChange} />
                  <label className="peer-focus:text-[#116eee]" htmlFor="password1">
                    Retype Password
                  </label>
                </div>
              </div>
              <LoadingButton
                className="font-[Helvetica] w-[220px] bg-[#007bff] hover:bg-[#0069d9] text-[#fff] rounded-[30px] m-auto block text-[16px] p-[8px] min-h-[42px] "
                loading={isSubmitting}
                type="submit"
                disabled={isSubmitting}>
                Create
              </LoadingButton>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
