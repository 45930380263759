import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import api from "../../../services/api";

export default ({ mission }) => {
  const navigate = useNavigate();

  async function createQuote() {
    const r = await api.post("/quote", { description: mission.description });
    toast.success("Quote created");
    navigate(`/quote/${r.data._id}`);
  }

  return (
    <div className="grid grid-cols-2 gap-5">
      <div className="col-span-2 md:col-span-1">
        <div className="mb-10 p-2 leading-7 bg-lighter-grey whitespace-pre-line">{mission.description}</div>
        {/* <Apply mission={mission} /> */}
      </div>
      <div className="">
        <div className="">
          <button className="bg-primary text-white px-5 py-2 rounded-lg" onClick={() => window.open(mission.link, "_blank")}>
            Go on the website
          </button>
          <button className="bg-primary text-white px-5 py-2 rounded-lg" onClick={createQuote}>
            Create Quote
          </button>
        </div>
      </div>
    </div>
  );
};
