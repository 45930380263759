import React, { useState } from "react";
import validator from "validator";
import toast from "react-hot-toast";

import api from "../../services/api";

export default () => {
  const [values, setValues] = useState({ name: "", email: "", message: "" });
  const [done, setDone] = useState(false);

  const handleSubmit = async () => {
    if (!validator.isEmail(values.email)) return toast.error("Invalid email !");
    const params = new URLSearchParams(window.location.search);
    const referral_id = params.get("referral_id");

    const { data } = await api.post("/contact", { ...values, referral_id });

    toast.success("Message sent !");
    setDone(true);
  };

  if (done)
    return (
      <div className="p-4">
        <div className="bg-white p-10 border border-gray-200 text-center">
          <div className="text-5xl font-bold mb-6">Thank you for your interest!</div>
          <div className="text-base mb-10">We will get back to you as soon as possible.</div>
        </div>
      </div>
    );

  return (
    <div className="p-4">
      <div className="bg-white p-10 border border-gray-200">
        <div className="max-w-5xl mx-auto">
          <div className="text-5xl font-bold mb-6 text-center">Submit your project!</div>
          <div className="text-base mb-10">
            Stud is a cutting-edge startup creation studio with offices in Paris and Amsterdam. Comprising a team of seasoned entrepreneurs, we develop and support CEOs in
            building, strategizing and developing technology startups.
          </div>
          <div className="grid grid-cols-2 gap-5 mb-5">
            <input
              className="text-base p-2 px-4 border border-gray-200 rounded-lg"
              placeholder="Your name"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
            <input
              className="text-base p-2 px-4 border border-gray-200 rounded-lg"
              placeholder="Your email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
            <textarea
              className="col-span-2 text-base p-2 px-4 border border-gray-200 rounded-lg h-60"
              placeholder="Tell us about your project"
              value={values.message}
              onChange={(e) => setValues({ ...values, message: e.target.value })}
            />
          </div>
          <button onClick={handleSubmit} disabled={!values.email || !values.name} className="btn-primary px-6 py-3 rounded-lg text-base">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
