import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoCheckmark } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";
import _ from "lodash";

import api from "../../../services/api";
import Modal from "../../../components/modal";
import SendEmail from "./send";
import Switch from "../../../components/Switch";
import Avoir from "./components/Avoir";

const Editor = ({ invoice, setInvoice, project, setProject }) => {
  const [organisation, setOrganisation] = useState(null);

  useEffect(() => {
    if (!project) return;
    const fetchData = async () => {
      try {
        const res = await api.get(`/organisation/${project.organisation_id}`);
        if (res.ok) setOrganisation(res.data);
      } catch (e) {
        console.log("a", e);
      }
    };
    fetchData();
  }, [project]);

  if (!project || !organisation) return <div>Loading</div>;

  return (
    <div className="h-full flex">
      <div className="overflow-scroll h-full flex-1">
        <Content invoice={invoice} setInvoice={setInvoice} project={project} setProject={setProject} organisation={organisation} setOrganisation={setOrganisation} />
      </div>
      <div className="w-[25%] h-full">
        <Menu invoice={invoice} setInvoice={setInvoice} project={project} />
      </div>
    </div>
  );
};

const Menu = ({ invoice, setInvoice, project }) => {
  const [downloading, setDownloading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setDiscount(invoice.discount);
    setTotal(invoice.subTotal - (invoice.subTotal - (invoice.subTotal * (100 - invoice.discount)) / 100));
  }, [invoice]);

  const handleInvoiceChange = async (values) => {
    try {
      const res = await api.put(`/invoice/${invoice._id}`, values);
      if (!res.ok) throw new Error("Error while updating invoice");
      setInvoice(res.data);
      toast.success("Invoice updated");
    } catch (error) {
      console.error(error);
      toast.error("Error while updating invoice");
    }
  };

  const handlePrint = async () => {
    setDownloading(true);
    try {
      const res = await api.post(`/invoice/${invoice._id}/pdf`);
      if (res.ok) {
        setInvoice(res.data);
        toast.success("PDF generated");
        // open file url in new tab
        window.open(res.data.file, "_blank");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF");
    }
    setDownloading(false);
  };

  const handleLoad = async () => {
    if (invoice.sent === "yes") {
      toast.error("Invoice already sent");
      return;
    }
    try {
      const { data: dataInvoice, error } = await api.get(`/invoice/load_editor/${invoice._id}`);
      if (error) {
        toast.error(error);
        return;
      }

      setInvoice(dataInvoice);
      toast.success("Invoice loaded");
    } catch (e) {
      console.log("Error in handleLoad:", e);
      toast.error("An error occurred while loading the invoice");
    }
  };

  return (
    <div className="space-y-4 pl-8 bg-white">
      <div className="flex-1 space-y-2">
        <CheckInconsistency invoice={invoice} project={project} />
        <button className="blue-btn w-full" onClick={handleLoad}>
          Load the invoice
        </button>
        <button className="blue-btn w-full" onClick={() => handleInvoiceChange(invoice)}>
          Save
        </button>
        <button
          className="empty-btn w-full"
          onClick={() => {
            let sentAt;
            if (!invoice.sentAt || invoice.sent !== "yes") sentAt = new Date();
            else {
              sentAt = invoice.sentAt;
              toast.error("Invoice already sent");
            }
            const dueAt = new Date().setDate(sentAt.getDate() + 30);
            handleInvoiceChange({ sentAt, dueAt });
          }}>
          Set expected receive date
        </button>

        <div className="w-full flex items-center relative group gap-2">
          <button onClick={handlePrint} className="blue-btn w-full" disabled={downloading}>
            {downloading ? "Generating..." : "Generate PDF"}
          </button>

          {invoice.file && (
            <a href={invoice.file} target="_blank" rel="noreferrer" className="blue-btn w-10 text-center">
              <MdOutlineRemoveRedEye className="text-lg font-bold" />
            </a>
          )}
        </div>
        <SendEmail
          invoice={invoice}
          project={project}
          onPrint={() => null}
          onDone={() => {
            setInvoice({ ...invoice, sent: "yes", freezed: "yes" });
          }}
        />
        <Avoir invoice={invoice} />
      </div>

      <div className="grid grid-cols-2 gap-y-4 gap-x-2 items-center">
        <label className="text-sm font-semibold">Freezed</label>

        <Switch checked={invoice.freezed === "yes"} onChange={(e) => handleInvoiceChange({ ...invoice, freezed: e ? "yes" : "no" })} />

        <label className="text-sm font-semibold">Sent</label>

        <Switch
          checked={invoice.sent === "yes"}
          onChange={(e) => handleInvoiceChange({ ...invoice, sent: e ? "yes" : "no", sentAt: e ? new Date() : null, freezed: e ? "yes" : invoice.freezed })}
        />

        <label className="text-sm font-semibold">Sent at</label>
        <input
          disabled={invoice.sent === "yes"}
          className="input p-1 flex-1"
          type="date"
          value={invoice.sentAt ? new Date(invoice.sentAt).toISOString().slice(0, 10) : ""}
          onChange={(e) => handleInvoiceChange({ ...invoice, sent: "yes", sentAt: e.target.value })}
        />

        <label className="text-sm font-semibold">Received</label>

        <Switch checked={invoice.received === "yes"} onChange={(e) => handleInvoiceChange({ ...invoice, received: e ? "yes" : "no" })} />

        <label className="text-sm font-semibold">Received at</label>
        <input
          disabled={invoice.received === "yes"}
          className="input p-1 flex-1"
          type="date"
          value={invoice.receivedAt ? new Date(invoice.receivedAt).toISOString().slice(0, 10) : ""}
          onChange={(e) => handleInvoiceChange({ ...invoice, received: "yes", receivedAt: e.target.value })}
        />

        <label className="text-sm font-semibold">Discount %</label>

        <div className="flex gap-2 items-center">
          {discount !== invoice.discount ? (
            <button onClick={() => handleInvoiceChange({ discount })} className="blue-btn p-1 h-6">
              <IoCheckmark />
            </button>
          ) : null}
          <input type="number" className="input p-1 text-right" value={discount} onChange={(e) => setDiscount(e.target.value)} />
        </div>

        <label className="text-sm font-semibold">Calculate discount</label>
        <div className="flex gap-2 items-center">
          {total !== invoice.subTotal - (invoice.subTotal - (invoice.subTotal * (100 - invoice.discount)) / 100) && total > 0 ? (
            <button onClick={() => handleInvoiceChange({ discount: 100 - (total * 100) / invoice.subTotal })} className="blue-btn p-1 h-6">
              <IoCheckmark />
            </button>
          ) : null}
          <input type="number" className="input p-1 text-right" value={total} onChange={(e) => setTotal(e.target.value)} />
        </div>

        <label className="text-sm font-semibold">Cost</label>
        <p className="text-sm font-bold text-right">{invoice.cost.toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
        <label className="text-sm font-semibold">Margin</label>
        <p className="text-sm font-bold text-right">{(invoice.margin / 100).toLocaleString("fr", { style: "percent" })}</p>

        <Penalties invoice={invoice} setInvoice={setInvoice} />
      </div>
    </div>
  );
};
const Content = ({ invoice, setInvoice, project, setProject, organisation, setOrganisation }) => (
  <div className="flex justify-center py-4 bg-gray-800">
    <div className="col-span-3 grid grid-cols-2 gap-4 p-4 bg-white rounded border w-[794px]">
      <div>
        <Seller values={organisation} onChange={setOrganisation} />
      </div>
      <div>
        <Customer
          values={{ project, invoice }}
          onChange={(v) => {
            setProject(v.project);
            setInvoice(v.invoice);
          }}
        />
      </div>

      <div className="col-span-2">
        <InvoiceDetails values={invoice} onChange={setInvoice} />
      </div>
      <div className="col-span-2">
        <Table values={invoice} onChange={setInvoice} />
      </div>
      <div className="col-span-2">
        <TermsAndConditions values={invoice} onChange={setInvoice} />
      </div>
    </div>
  </div>
);

const CheckInconsistency = ({ invoice, project }) => {
  const render = ({ warningMessages }) => (
    <div className="border-l-4 border-red-400 bg-red-50 p-4 mb-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <RiErrorWarningLine className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          {warningMessages.map((text) => (
            <p className="text-sm text-red-700">{text}</p>
          ))}
        </div>
      </div>
    </div>
  );

  const arr = [];

  // if due date is before the invoire date
  const invoiceDate = invoice.sentAt ? new Date(invoice.sentAt) : new Date();
  if (invoice.dueAt && invoiceDate && new Date(invoice.dueAt) < new Date(invoiceDate)) arr.push("Due date is before the invoice date");

  // if there is a french TVA on a selego invoice
  if (invoice.taxType === "FR 20%" && invoice.organisation_id === "62961be126ff569dbb11d3e6") arr.push("French TVA on a Selego invoice");
  // if there is a dutch TVA on a collectif invoice
  if (invoice.taxType === "NL 21%" && invoice.organisation_id === "645131208989391af9bc9e33") arr.push("Dutch TVA on a Le Collectif invoice");
  if (!invoice.taxType) arr.push("TVA details missing");

  // if there is no client infos
  if (!project.name) arr.push("Client name missing");
  if (!project.address) arr.push("Client's address missing");
  if (!project.clientsMails || !project.clientsMails.length) arr.push("Client's mail missing");
  if (!project.tax) arr.push("Client's VAT missing");
  if (!project.siren) arr.push("Client's SIREN missing");

  if (arr?.length) {
    return render({ warningMessages: arr });
  } else {
    return null;
  }
};

const InvoiceDetails = ({ values, onChange }) => {
  const handleGenerateInvoice = async () => {
    try {
      const res = await api.post(`/invoice/generate/${values._id}`);
      if (!res.ok) throw new Error("Failed to generate invoice");
      onChange(res.data);
      toast.success("Onvoice updated");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update invoice");
    }
  };

  return (
    <div className="flex-1 grid grid-cols-4 items-center gap-2">
      <label className="text-sm font-semibold text-right">Name</label>
      <input className="input p-2" value={values.name} onChange={(v) => onChange({ ...values, name: v.target.value })} />

      <label className="text-sm font-semibold text-right">Sent date</label>

      <input
        disabled={values.sent === "yes"}
        type="date"
        className="input"
        value={values.sentAt ? new Date(values.sentAt).toISOString().slice(0, 10) : ""}
        onChange={(e) => onChange({ ...values, sentAt: e.target.value })}
      />

      <label className="text-sm font-semibold text-right">Reference</label>

      {values.index ? (
        <p className="font-semibold p-2">{values.index}</p>
      ) : (
        <button className="blue-btn" onClick={handleGenerateInvoice}>
          Generate a version
        </button>
      )}

      <label className="text-sm font-semibold text-right">Due date</label>
      <input
        type="date"
        className="input"
        value={values.date ? new Date(values.dueAt).toISOString().slice(0, 10) : ""}
        onChange={(e) => onChange({ ...values, dueAt: e.target.value })}
      />
    </div>
  );
};

const Seller = ({ values }) => {
  return (
    <div className="w-full flex flex-col">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">{values.name}</h2>
        <p>{values.address}</p>
        <p>
          <span className="font-semibold mr-2">Email:</span> {values.email}
          <br />
          <span className="font-semibold mr-2">VAT:</span> {values.vat} <br />
          <span className="font-semibold mr-2">IBAN:</span> {values.iban} <br />
        </p>
      </div>
    </div>
  );
};

const Customer = ({ values, onChange }) => {
  return (
    <div className="flex-1 grid grid-cols-4 items-center gap-2">
      <label className="text-sm text-gray-950 font-semibold text-right">Name</label>
      <div className="col-span-3">
        <input className="input p-2" value={values.project.clientName} onChange={(e) => onChange({ ...values, project: { ...values.project, clientName: e.target.value } })} />
      </div>
      <label className="text-sm text-gray-950 font-semibold text-right">Address</label>
      <div className="col-span-3">
        <textarea
          rows={2}
          className="input p-2"
          value={values.project.address}
          onChange={(e) => onChange({ ...values, project: { ...values.project, address: e.target.value } })}
        />
      </div>
      <label className="text-sm text-gray-950 font-semibold text-right">Email</label>
      <div className="col-span-3">
        <p className="font-semibold p-2">{(values.project.clientsMails || []).join(", ")}</p>
      </div>
      <label className="text-sm text-gray-950 font-semibold text-right">VAT</label>
      <div className="col-span-3">
        <input className="input p-2" value={values.project.tax} onChange={(e) => onChange({ ...values, project: { ...values.project, tax: e.target.value } })} />
      </div>
      <label className="text-sm text-gray-950 font-semibold text-right">SIREN</label>
      <div className="col-span-3">
        <input className="input p-2" value={values.project.siren} onChange={(e) => onChange({ ...values, project: { ...values.project, siren: e.target.value } })} />
      </div>
    </div>
  );
};

const Table = ({ values, onChange }) => {
  const handleItemChange = async (item, index) => {
    const newValues = { ...values };
    newValues.itemsInvoice[index] = item;
    try {
      console.log("changing table newValues", newValues);
      const res = await api.put(`/invoice/${newValues._id}`, newValues);
      console.log("changed table values");
      if (res.ok) {
        onChange(res.data);
        toast.success("Invoice updated");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update invoice");
    }
  };
  const handleAddItem = async () => {
    const newValues = { ...values };
    newValues.itemsInvoice.push({ name: "", description: "", price: 0, quantity: 1, unit: "unit", totalPrice: 0 });
    try {
      const res = await api.put(`/invoice/${newValues._id}`, newValues);
      if (res.ok) {
        onChange(res.data);
        toast.success("Invoice updated");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update invoice");
    }
  };

  const handleItemDelete = async (index) => {
    const newValues = { ...values };
    newValues.itemsInvoice.splice(index, 1);
    try {
      const res = await api.put(`/invoice/${newValues._id}`, newValues);
      if (res.ok) {
        onChange(res.data);
        toast.success("Invoice updated");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update invoice");
    }
  };

  const discountAmount = values.subTotal - (values.subTotal * (100 - values.discount)) / 100;
  const unTaxedAmount = values.subTotal - (values.subTotal - (values.subTotal * (100 - values.discount)) / 100);

  return (
    <table className="table-fixed text-sm">
      <thead className="border-b border-gray-300">
        <tr>
          <th colSpan={2} className="text-left p-2 pl-10">
            Name
          </th>
          <th colSpan={2} className="text-left p-2">
            Description
          </th>
          <th className="text-right p-2">Price</th>
          <th className="text-right p-2">Quantity</th>
          <th className="text-right p-2">Total</th>
        </tr>
      </thead>
      <tbody>
        {values.itemsInvoice.length > 0 ? (
          values.itemsInvoice.map((item, i) => <Item key={i} item={item} onChange={(item) => handleItemChange(item, i)} onDelete={() => handleItemDelete(i)} />)
        ) : (
          <tr>
            <td colSpan={7} className="p-2 text-sm text-center text-gray-400">
              No data linked to the invoice
            </td>
          </tr>
        )}
        <tr>
          <td colSpan={7} className="p-2 text-sm text-center text-gray-400">
            <div className="flex justify-end">
              <button className="gray-btn" onClick={handleAddItem}>
                +
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot className="font-semibold">
        <tr>
          <td colSpan={6} className="p-2 text-right">
            Sub total
          </td>
          <td className="text-right text-sm">{(values.subTotal || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
        </tr>
        <tr>
          <td colSpan={6} className="p-2 text-right">
            Discount €
          </td>
          <td className="text-right">{discountAmount > 0 ? `-${discountAmount.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}` : "0 €"}</td>
        </tr>
        <tr>
          <td colSpan={6} className="p-2 text-right">
            Untaxed total
          </td>
          <td className="text-right text-sm">{unTaxedAmount.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
        </tr>
        <tr>
          <td colSpan={6} className="p-2 text-right">
            Tax {values.taxType || "(not applied)"}
          </td>
          <td className="text-right text-sm">{(values.taxValue || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
        </tr>
        <tr>
          <td colSpan={6} className="p-2 text-right">
            Total due
          </td>
          <td className="text-right text-sm">{(values.total || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
        </tr>
      </tfoot>
    </table>
  );
};

const Item = ({ item, onChange, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <EditItemModal item={item} onChange={onChange} onDelete={onDelete} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <tr className="cursor-pointer hover:bg-gray-100 rounded-lg" onClick={() => setIsOpen(true)}>
        <td colSpan={2} className="p-2 text-sm">
          <p>{item.name}</p>
        </td>
        <td colSpan={2} className="p-2 text-sm">
          {item.description?.split("\n").map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </td>
        <td className="p-2 text-right">{(item.price || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
        <td className="p-2 text-right">
          {item.quantity} {item.unit}
        </td>
        <td className="p-2 text-right">{(item.totalPrice || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
      </tr>
    </>
  );
};

const EditItemModal = ({ item, onChange, onDelete, isOpen, onClose }) => {
  const [values, setValues] = useState({ ...item });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({ ...item });
    console.log("item", item);
  }, [item]);

  const handleSave = async () => {
    setLoading(true);
    values.totalPrice = values.price * values.quantity;
    await onChange(values);
    onClose();
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    await onDelete();
    onClose();
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-1/2">
      <div className="p-6">
        <h2 className="text-2xl">Edit item</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="text-sm text-gray-950 font-semibold">Name</label>
            <input type="text" className="input" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <div>
            <label className="text-sm text-gray-950 font-semibold">Description</label>
            <textarea className="input" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
          </div>
          <div>
            <label className="text-sm text-gray-950 font-semibold">Price</label>
            <input type="number" className="input" value={values.price} onChange={(e) => setValues({ ...values, price: parseFloat(e.target.value) })} />
          </div>
          <div>
            <label className="text-sm text-gray-950 font-semibold">Quantity</label>
            <input type="number" className="input" value={values.quantity} onChange={(e) => setValues({ ...values, quantity: parseFloat(e.target.value) })} />
          </div>
          <div>
            <label className="text-sm text-gray-950 font-semibold">Unit</label>
            <select className="input" value={values.unit} onChange={(e) => setValues({ ...values, unit: e.target.value })}>
              <option value=""></option>
              <option value="unit">Unit</option>
              <option value="days">Days</option>
              <option value="month">Month</option>
            </select>
          </div>
        </div>

        <div className="flex justify-between mt-4">
          <div>
            <button onClick={handleDelete} className="red-btn" disabled={loading}>
              {loading ? "Loading... " : "Delete"}
            </button>
          </div>

          <div className="flex justify-end mt-4">
            <button onClick={onClose} className="empty-btn">
              {loading ? "Loading... " : "Cancel"}
            </button>
            <button onClick={handleSave} className="blue-btn ml-2" disabled={loading}>
              {loading ? "Loading... " : "Save"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Penalties = ({ invoice, setInvoice }) => {
  if (invoice.dueAt && new Date(invoice.dueAt) < new Date()) {
    const dayslate = Math.floor((new Date() - new Date(invoice.dueAt)) / (1000 * 60 * 60 * 24));
    const cal = Math.ceil(invoice.total * 0.15 * (dayslate / 365) + 40);
    return (
      <div className="flex items-center mt-2">
        <div className="text-gray-800 font-medium">{`Penalties (${dayslate} days)`}</div>
        <div className="ml-2 text-gray-600">{`${cal.toFixed(2)}€`}</div>
        {/* <button
          className="ml-3 ms-auto items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={async () => {
            let items = invoice.itemsInvoice;
            items = items.filter((item) => item.name !== "Penalties");
            items = [...items, { name: "Penalties", description: `${dayslate} days late`, quantity: 1, price: cal, totalPrice: cal }];

            console.log("items", items);
            const { data } = await api.put(`/invoice/${invoice._id}`, { itemsInvoice: items });
            setInvoice(data);

            toast.success("Invoice updated");
          }}>
          Apply
        </button> */}
      </div>
    );
  }
  return <div />;
};

const TermsAndConditions = ({ values, onChange }) => {
  useEffect(() => {
    if (!values.termsAndConditions)
      onChange({
        ...values,
        termsAndConditions:
          "Please pay within 30 days. In the event of late payment, a penalty of 15% interest rate will be applied, to which will be added a lump sum compensation for recovery costs of €40.",
      });
  }, []);

  return (
    <div className="space-y-2">
      <h2 className="text-lg">Terms & conditions</h2>
      <textarea rows={4} className="input p-2" value={values.termsAndConditions} onChange={(v) => onChange({ ...values, termsAndConditions: v.target.value })} />
    </div>
  );
};

export default Editor;
