import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import Modal from "../modal";
import api from "../../services/api";
import { PiStarFill, PiStarLight } from "react-icons/pi";

export default ({ user, setUser }) => {
  async function onDelete(ratingId) {
    if (!window.confirm("Are you sure?")) return;
    const { data } = await api.post(`/user/delete-rating/${user._id}`, { ratingId: ratingId });
    setUser(data);
    toast.success("Deleted!");
  }

  const averageRating = user.ratings.length > 0
    ? user.ratings.reduce((sum, rating) => sum + rating.score, 0) / user.ratings.length
    : 0;


  return (
    <div className="h-full">
      <div className="w-full">
        <Create user={user} setUser={setUser} />
      </div>

      <div className="bg-white border rounded-lg border-gray-100 p-4 flex flex-col justify-between w-full gap-2 mt-4">
        <div>
          <div>Average: </div>
          <div className="mt-2 text-3xl font-semibold leading-6 text-gray-900 ">
            <Rating value={averageRating} />
          </div>
        </div>

        <div className="flex overflow-x-auto gap-2">
          {user.ratings.map((rating, index) => (
            <div key={index} className="p-4 bg-white shadow-sm  border rounded-lg border-gray-100 my-2">
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <Rating value={rating.score} />
                  <span>
                    ({rating.score}/5)
                  </span>
                </div>

                <button
                  onClick={() => onDelete(rating._id)}
                  className="ml-2 text-red-500 hover:text-red-700 transition duration-150 ease-in-out px-3 py-1 rounded border border-red-500 hover:bg-red-500 hover:text-white">
                  Delete
                </button>
              </div>
              <p className="text-gray-700 mb-2">{rating.text}</p>
              <p className="ml-auto text-base text-gray-600 mb-1 text-xs">By {rating.user_name}</p>
              <p className="ml-auto text-gray-500 text-xs">Created at: {new Date(rating.created_at).toLocaleDateString()}</p>
            </div>
          ))}
        </div>




      </div >

    </div >
  );
};

const Create = ({ user, setUser }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function handleSubmit(e) {
    e.preventDefault();
    try {

      console.log(values)
      const { data, ok } = await api.post(`/user/rating/${user._id}`, values);
      if (!ok) return toast.error("Some Error!");
      setUser(data);
      toast.success("Created!");
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="bg-white border rounded-lg border-gray-100 p-4">
      <form onSubmit={handleSubmit}>
        <h4 className="text-md text-bold mb-2">Rate your experience working with {user.name}: </h4>
        <RatingForm
          value={values.score}
          onChange={(v) => {
            setValues({ ...values, score: v });
          }}
        />
        <div className="flex items-center mt-2">
          <div className="w-full">
            <textarea
              className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
              placeholder={`Describe your experience wotking with ${user.name}`}
              value={values.text}
              onChange={(e) => {
                setValues({ ...values, text: e.target.value });
              }} />
          </div>
          <button type="submit" className="btn btn-primary ml-4" onClick={handleSubmit}>
            Create
          </button>
        </div>
      </form>
    </div >
  );
};

const Rating = ({ value }) => {
  return <div className="flex ">
    {
      [1, 2, 3, 4, 5].map((score) => {
        const isShining = score <= value;
        return (
          <div
            className={`px-1 ${isShining ? "text-yellow-400" : "text-gray-300"}`}>
            {isShining ? <PiStarFill /> : <PiStarLight />}
          </div>
        );
      })
    }
  </div>
}

const RatingForm = ({ value, onChange }) => {
  const [highlightedScore, setHighlightedScore] = useState(value);
  return (
    <div className="flex items-center justify-start text-3xl">
      {[1, 2, 3, 4, 5].map((score) => {
        const isShining = score <= highlightedScore;
        return (
          <div
            className={`px-1 ${isShining ? "text-yellow-400" : "text-gray-300"}`}
            onMouseLeave={() => setHighlightedScore(value || 0)}
            onMouseOver={() => setHighlightedScore(score)}
            onClick={() => onChange(score)}>
            {isShining ? <PiStarFill /> : <PiStarLight />}
          </div>
        );
      })}
    </div>
  );
};