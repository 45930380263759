import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MdDeleteForever, MdUpload } from "react-icons/md";

import api from "../../../services/api";
import { readFileAsync } from "../../../utils";

function documents({ documents, onChange }) {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.Auth);

  return (
    <div className="w-1/2">
      <div className="bg-white rounded-lg py-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="border-b border-[#E5EAEF] text-[14px] text-xs font-bold text-left text-gray-500 uppercase">
              <th className="px-4 py-3">name</th>
              <th className="px-4 py-3">file name</th>
              <th className="px-4 py-3">Uploaded by</th>
              <th className="px-4 py-3">Created at</th>
              <th className="px-4 py-3">action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {(documents || []).map((document) => (
              <Row
                key={document._id}
                document={document}
                onChange={(name) => {
                  const newarray = [...documents];
                  const find = newarray.find((d) => d._id === document._id);
                  find.name = name;
                  onChange(newarray);
                }}
                onDelete={() => {
                  const newLinks = documents.filter((l) => l._id !== document._id);
                  onChange(newLinks);
                }}
              />
            ))}
          </tbody>
        </table>

        <div className="flex justify-center">
          <label className="btn btn-primary mt-5 ml-3 cursor-pointer flex items-center" style={{ opacity: loading ? 0.5 : 1 }}>
            <input
              className="mt-2"
              name="file-upload"
              type="file"
              disabled={loading}
              onChange={async (e) => {
                setLoading(true);
                const file = e.target.files[0];
                const rawBody = await readFileAsync(file);
                const { data } = await api.post(`/file`, { file: { rawBody, name: file.name }, folder: "documents" });

                let newarray = [...documents];
                newarray.push({ url: data, name: "", userId: user._id, userName: user.name, userAvatar: user.avatar });
                find.url = data;
                await onChange(newarray);
                setLoading(false);
              }}
            />
            <MdUpload className="mr-2 text-lg" />
            Upload new document
            {loading && <div className="ml-2 spinner-border animate-spin inline-block w-4 h-4 border-[0.1em] rounded-full" role="status" />}
          </label>
        </div>
      </div>
    </div>
  );
}

export default documents;

const Row = ({ document, onChange, onDelete }) => {
  const [text, setText] = useState(document.name || "");

  return (
    <tr className="hover:bg-blue-50 ">
      <td className="px-4 py-2 text-sm font-medium text-gray-600">
        <input
          className="projectsInput mt-0 text-[14px] font-normal text-[#212325] rounded-[10px] max-w-md"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onBlur={() => onChange(text)}
        />
      </td>
      <td className="px-4 py-2 text-sm font-medium text-gray-600">
        <a className="flex gap-1 items-center text-sm text-gray-700 hover:underline cursor-pointer" href={document.url} target="_blank" rel="noreferrer">
          {document.url.split("/").pop()}
        </a>
      </td>
      <td className="px-4 py-2 text-sm font-medium text-gray-600">
        {document.userAvatar && (
          <>
            <img src={document.userAvatar} className="rounded-lg w-7 h-7 mr-2" />
            {document.userName}
          </>
        )}
      </td>
      <td className="px-4 py-2 text-sm font-medium text-gray-600">{document?.created_at && document?.created_at.slice(0, 10)}</td>
      <td className="px-4 py-2 text-sm font-medium text-gray-600">
        <div className={`cursor-pointer text-xl hover:text-red-500`}>
          <MdDeleteForever
            onClick={() => {
              if (!confirm("Are you sure ?")) return;
              onDelete();
            }}
          />
        </div>
      </td>
    </tr>
  );
};
