import React from "react";
import moment from "moment";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export default ({ notion }) => {
  return (
    <div className="w-full max-w-2xl mx-auto bg-white">
      {(notion.logs || [])
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .map((log) => {
          return (
            <div className="w-full grid grid-cols-4">
              <div className="relative pr-3 border-r-[2px] border-sky-600">
                <div className="absolute h-2 w-2 bg-sky-600 rounded-full -right-[5px] top-1 ring-2 ring-white" />
                <p className="text-xs font-semibold text-right">{moment(log.created_at).format("DD MMM")}</p>
                <p className="text-[9px] text-gray-600 text-right">{moment(log.created_at).fromNow()}</p>
              </div>
              <div className="col-span-3 pl-3 pb-8">
                <p className="text-xs text-gray-700">{log.field}</p>
                <p className="text-sm flex items-center gap-x-2 flex-wrap">
                  {log.old_value}
                  <MdOutlineKeyboardArrowRight />
                  {log.new_value}
                </p>
                {Boolean(log.text) && <p className="text-xs text-gray-600">{log.text}</p>}
                <p className="text-xs flex items-center gap-2 flex-wrap mt-1">
                  <img src={log.user_avatar} className="w-4 h-4 rounded-full" /> {log.user_name}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};
