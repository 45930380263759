import React, { useEffect, useState } from "react";
import api from "../services/api";
import Tooltip from "react-tooltip";

export default function UserProjects({ user, max = 10, handleCountClick = null, handleClick = null, grid = false }) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    const { data } = await api.get(`/activity?userId=${user._id}`);
    const uniqueProjects = data
      .map((e) => ({ name: e.projectName, avatar: e.projectLogo, id: e.projectId }))
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    setProjects(uniqueProjects);
  };

  const count = projects.length > max ? projects.length - max : 0;

  const ProjectItem = ({ project }) => (
    <div className="cursor-pointer" onClick={() => handleClick(project.id)} key={project.name}>
      <img 
        className="w-6 h-6 rounded-full bg-gray-50 ring-2 ring-white" 
        src={project.avatar} alt={project.name} 
        data-tip data-for={`worker-${project.name}`} 
      />
      <Tooltip border borderColor="#e6e6e6" id={`worker-${project.name}`} effect="solid" className="max-w-xs text-sm keepOpacity" backgroundColor="#fff" textColor="#000">
        <span>{project.name}</span>
      </Tooltip>
    </div>
  );

  const ProjectGridItem = ({ project }) => (
    <div className="cursor-pointer flex items-center justify-center" onClick={() => handleClick(project.id)} key={project.name}>
      <img 
        className="w-12 h-12 rounded-xl bg-gray-50 ring-1 ring-gray-300" 
        src={project.avatar} alt={project.name} 
        data-tip data-for={`worker-${project.name}`} 
      />
      <Tooltip border borderColor="#e6e6e6" id={`worker-${project.name}`} effect="solid" className="max-w-xs text-sm keepOpacity" backgroundColor="#fff" textColor="#000">
        <span>{project.name}</span>
      </Tooltip>
    </div>
  );

  return (
    <div className="mt-1">
      <div className={`${grid ? "grid grid-cols-5 gap-y-2" : "flex flex-wrap -space-x-0.5"}`}>
        {projects.length ? projects.slice(0, max).map((project) => (
          grid ? <ProjectGridItem project={project} key={project.id} /> : <ProjectItem project={project} key={project.id} />
        )) : <p className="text-gray-400">No projects</p>}
        {count > 0 && !grid && (
          <button 
            onClick={handleCountClick}
            className="flex justify-center items-center font-semibold relative z-30 bg-gray-100 w-6 h-6 ring-2 ring-white text-xs rounded-full"
          >
            {count}+
          </button>
        )}
      </div>
    </div>
  );
}
