import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import api from "../../services/api";
import toast from "react-hot-toast";

export default ({ bank, setBank }) => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState(null);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    get();
  }, [filters]);

  async function get() {
    setLoading(true);
    const query = {};
    // calculate the end of previous month
    const datelt = new Date();
    // datelt.setDate(1);
    // datelt.setHours(-1);
    // datelt.setMinutes(-1);
    // datelt.setSeconds(-1);
    // datelt.setMilliseconds(-1);
    query.datelt = datelt;
    if (filters.search) {
      query.search = filters.search;
    }
    const { data } = await api.post(`/payment/search`, query);
    setPayments(data);
    setLoading(false);
  }

  if (payments === null) return <div>Loading...</div>;
  if (payments.length === 0) return <div>No payments found</div>;
  if (loading) return <div>Loading...</div>;

  return (
    <div className="text-sm">
      <div>Salaries</div>
      <Searchbar value={filters.search} onChange={(search) => setFilters({ ...filters, search })} />
      <div className="overflow-x-auto bg-white mt-2">
        <table className="md:w-full table-auto">
          <thead className="border-t border-b-2	 border-t-[#dee2e6] border-l-0 border-r-0 border-b-[#dee2e6]">
            <tr>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Date
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Name
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Payment method
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Type
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Amount
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Doc
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                See
              </th>
              <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                Match
              </th>
            </tr>
          </thead>
          <tbody>
            {(payments || []).map((hit, index) => {
              console.log(hit);
              return (
                <tr key={hit._id} className="hover:bg-[#dee2e6] border-b border-[#dee2e6]">
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <div>
                      <div>{hit.date.slice(0, 10)}</div>
                      <div className="text-gray-400 text-xs">created at {new Date(hit.created_at).toLocaleString()}</div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <div>{hit.description}</div>
                    <div className="text-gray-400 text-xs">{hit.user_name}</div>
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <div>{hit.user_payment_method}</div>
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <div>{hit.type}</div>
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <div>{hit.value}</div>
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <Files files={hit.files} />
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <a href={`/payment/${hit._id}`} target="_blank">
                      <button className="bg-blue-500 hover:bg-blue-700 text-white my-1 px-1">open</button>
                    </a>
                  </td>
                  <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white my-1 px-1"
                      onClick={async () => {
                        const files = [...(hit.files || []), ...(bank.files || [])];
                        const { data } = await api.put(`/bank/${bank._id}`, { files, status: "DONE" });
                        setBank(data);
                        toast.success("Files added");
                      }}>
                      Add files to bank
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Searchbar = ({ value, onChange }) => {
  const [search, setSearch] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    onChange(search);
  }

  return (
    <form className="flex items-center" onSubmit={onSubmit} type="submit">
      <div className="flex items-center">
        <div className="flex items-center border border-[#ccc] rounded-[10px] w-[300px] h-[40px]">
          <input
            key="mykyyy"
            className="w-full h-full pl-[20px] bg-[#F9FBFD] rounded-[10px] focus:outline-none"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#F9FBFD] rounded-[10px]">
            <button className="flex items-center justify-center w-[40px] h-[40px] bg-[#F9FBFD] rounded-[10px]" onClick={onSubmit}>
              <IoSearch />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const Files = ({ files }) => {
  if (files.length === 0) return <div>❌</div>;
  return files.map((e) => {
    return (
      <a key={e} href={e} target="_blank" className="cursor-pointer">
        🧾
      </a>
    );
  });
};
