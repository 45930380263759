import { apiURL } from "../../../config";

export function getBase64file(url) {
  return new Promise((resolve, reject) => {
    let imagePath = "";

    url = url.toString();

    if (url.startsWith("https://bank.cellar-c2.services.clever-cloud.com")) {
      imagePath = url.replace("https://bank.cellar-c2.services.clever-cloud.com", "");
      url = apiURL + "/proxy" + imagePath;
    }

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Network response error ${res.status}`);
        }
        return res.blob();
      })
      .then((image) => {
        var reader = new FileReader();
        reader.onload = function () {
          var dataURL = this.result;
          resolve(dataURL);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsDataURL(image);
      })
      .catch((error) => {
        console.error("Error getting logo", error);
        reject(error);
      });
  });
}

export const applyFontClassToHtml = (htmlString) => {
  const tagRegex = /<[^>]*>/g;
  const modifiedHtml = htmlString.replace(tagRegex, (match) => {
    return match.replace(">", ' className="font-poppins">');
  });

  return modifiedHtml;
};
