import React, { useContext, useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";

import { AppContext } from "./context";
import toast from "react-hot-toast";

export default ({}) => {
  const context = useContext(AppContext);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    document.querySelector("body").style.overflow = "hidden";
    return () => (document.querySelector("body").style.overflow = "auto");
  }, []);

  function validate() {
    const needValidation = step.choices.some((e) => e.isCorrect);
    if (!needValidation) return true;
    if (needValidation && !selected) {
      toast.error("Please select an option");
      return false;
    }

    if (!selected.isCorrect) {
      toast.error(selected.explanation || "You are incorrect");
      return false;
    }
    return true;
  }

  const step = context.workshop.steps[context.index - 1];

  const Choices = () => {
    if (!step.choices) return <div />;
    const userWorkshop = context.userWorkshop;
    return (
      <div className="grid grid-cols-2 gap-4 p-2 mt-4">
        {step.choices.map((e) => {
          let isSelected = selected && selected.key == e.key;
          if (!selected) isSelected = userWorkshop.choices.some((c) => c.index == context.index && c.key == e.key);
          return (
            <label
              className={`rounded shadow-sm cursor-pointer p-2 border-gray-400 mb-1 transition-all flex items-center space-x-2 ${
                isSelected ? "bg-green-500 text-white shadow" : "bg-white"
              }`}
              onClick={() => {
                setSelected(e);
                // setFeedback(null);
              }}>
              <input type="radio" name={"choice"} defaultChecked={isSelected} />
              <span>{e.name}</span>
            </label>
          );
        })}
      </div>
    );
  };

  const Content = () => {
    const elements = [];
    const full = step.elements.length === 1;
    for (let i = 0; i < step.elements.length; i++) {
      const element = step.elements[i];
      if (element.kind == "text") {
        elements.push(<div className="rich-text" dangerouslySetInnerHTML={{ __html: element.value }} />);
      } else if (element.kind == "image") {
        elements.push(
          <div className="mb-5 text-center w-full">
            <img src={element.value} className="object-cover max-w-3xl rounded-md w-full mx-auto" />
          </div>,
        );
      } else if (element.kind == "code") {
        elements.push(
          <div className="mb-5 w-full text-xs rounded-md">
            <SyntaxHighlighter language="javascript" showLineNumbers={true} showInlineLineNumbers={true} style={a11yDark}>
              {element.value}
            </SyntaxHighlighter>
          </div>,
        );
      } else if (element.kind == "video") {
        elements.push(
          <div className="mb-5 w-full text-xs rounded-md">
            <video
              src={`https://${element.value}`}
              frameborder="0"
              className="h-full w-full"
              controls
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></video>
          </div>,
        );
      }
    }

    return <div className={`grid ${full ? "grid-cols-1" : "grid-cols-2"} items-center gap-4 pr-2`}>{elements}</div>;
  };

  const Footer = ({}) => {
    useEffect(() => {
      function handleSpaceBar(evt) {
        if (document.activeElement.tagName === "TEXTAREA" || document.activeElement.tagName === "INPUT") return;
        if (evt.key == " " || evt.code == "Space" || evt.code == 32) onContinue();
      }
      document.body.addEventListener("keyup", handleSpaceBar);
      return () => document.body.removeEventListener("keyup", handleSpaceBar);
    }, []);

    const onContinue = () => {
      if (validate()) context.next(selected);
    };

    return (
      <div className="space-y-3 w-full p-3 fixed bottom-0 inset-x-0 bg-gray-100 border-t border-gray-200">
        <div className="flex items-center justify-center max-w-7xl mx-auto px-3 space-x-16">
          <button
            disabled={context.index < 2}
            onClick={() => context.setIndex(context.index - 1)}
            className="group flex gap-2 items-center justify-around bg-primary disabled:pointer-events-none disabled:bg-half-grey disabled:cursor-not-allowed text-xl text-white px-9 py-3 rounded-md border shadow-sm transition-colors">
            <MdArrowBackIosNew className="group-hover:-translate-x-2 transition-transform" />
            Previous
          </button>
          <button
            className="group flex gap-2 items-center justify-around bg-primary hover:bg-primary-500 text-xl text-white px-9 py-3 rounded-md border shadow-sm transition-colors"
            onClick={onContinue}>
            Continue
            <MdArrowForwardIos className="group-hover:translate-x-2 transition-transform" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="pr-2 w-full">
        <Content />
        <Choices />
      </div>
      <Footer />
    </>
  );
};
