import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import api from "../../../services/api";

export default ({ job }) => {
  const [jobPosts, setJobPosts] = useState([]);
  const [totalViews, setTotalViews] = useState(0);
  const [applicantCount, setApplicantCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [statusDistribution, setStatusDistribution] = useState({});

  useEffect(() => {
    if (job) fetchStats();
  }, [job]);

  async function fetchStats() {
    try {
      const { data: jobposts_data } = await api.post("/jobpost/search", { job_id: job._id });
      setJobPosts(jobposts_data);
      setTotalViews(jobposts_data.reduce((sum, post) => sum + post.views, 0));

      const { data: user_data } = await api.post("/user/search", {
        role: "applicant",
        job_ids: [job._id],
        // applicant_status: ["NEW", "SHORTLIST", "CONTACTED", "FINAL_LIST", "INTERVIEW", "HIRE", "TECHNICAL_TEST"],
      });

      const distribution = user_data.users.reduce((acc, user) => {
        const status = user.applicant_status || "NEW";
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, {});

      setStatusDistribution(distribution);
      setApplicantCount(user_data.total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  }

  const applicantPercentage = totalViews ? ((applicantCount / totalViews) * 100).toFixed(2) : 0;

  const chartData = {
    labels: ["Views", "Applicants"],
    datasets: [
      {
        data: [totalViews, applicantCount],
        backgroundColor: ["#36A2EB", "#FF6384"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  const jobPostViews = jobPosts
    .filter((post) => post.views > 0)
    .map((post) => ({
      title: post.channel_name,
      views: post.views,
    }));

  const jobPostChartData = {
    labels: jobPostViews.map((post) => post.title),
    datasets: [
      {
        data: jobPostViews.map((post) => post.views),
        backgroundColor: ["#FF9F40", "#FF6384", "#36A2EB", "#4BC0C0", "#9966FF", "#FFCD56"],
        hoverBackgroundColor: ["#FF9F40", "#FF6384", "#36A2EB", "#4BC0C0", "#9966FF", "#FFCD56"],
      },
    ],
  };

  const statusChartData = {
    labels: Object.keys(statusDistribution),
    datasets: [
      {
        data: Object.values(statusDistribution),
        backgroundColor: [
          "#FF6384", // NEW
          "#36A2EB", // SHORTLIST
          "#FFCD56", // CONTACTED
          "#4BC0C0", // FINAL_LIST
          "#9966FF", // INTERVIEW
          "#FF9F40", // HIRE
          "#C9CBCF", // TECHNICAL_TEST
        ],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCD56", "#4BC0C0", "#9966FF", "#FF9F40", "#C9CBCF"],
      },
    ],
  };

  if (loading)
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );

  return (
    <div className="p-4 flex flex-col gap-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow">
          <h3 className="text-gray-500 text-sm mb-1">Total Views</h3>
          <p className="text-2xl font-bold text-blue-600">{totalViews}</p>
        </div>
        <div className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow">
          <h3 className="text-gray-500 text-sm mb-1">Applicants</h3>
          <p className="text-2xl font-bold text-pink-600">{applicantCount}</p>
        </div>
        <div className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow">
          <h3 className="text-gray-500 text-sm mb-1">Conversion Rate</h3>
          <p className="text-2xl font-bold text-green-600">{applicantPercentage}%</p>
        </div>
      </div>

      {/* Charts Row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Views vs Applicants Chart */}
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4">Views vs Applicants</h2>
          <div className="w-full h-[200px] flex items-center justify-center">
            <div className="w-[180px]">
              <Pie
                data={chartData}
                options={{
                  plugins: {
                    legend: {
                      position: "bottom",
                      labels: {
                        padding: 8,
                        font: { size: 11 },
                      },
                    },
                  },
                  maintainAspectRatio: true,
                  responsive: true,
                }}
              />
            </div>
          </div>
        </div>

        {/* Job Posts Distribution */}
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4">Job Posts Distribution</h2>
          <div className="w-full h-[200px] flex items-center justify-center">
            <div className="w-[180px]">
              <Pie
                data={jobPostChartData}
                options={{
                  plugins: {
                    legend: {
                      position: "bottom",
                      labels: {
                        padding: 8,
                        font: { size: 11 },
                      },
                    },
                  },
                  maintainAspectRatio: true,
                  responsive: true,
                }}
              />
            </div>
          </div>
        </div>

        {/* Applicant Status Distribution */}
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4">Applicant Status Distribution</h2>
          <div className="w-full h-[200px] flex items-center justify-center">
            <div className="w-[180px]">
              <Pie
                data={{
                  ...statusChartData,
                  labels: statusChartData.labels.map((label) => label.replace("_", " ").toLowerCase()),
                }}
                options={{
                  plugins: {
                    legend: {
                      position: "bottom",
                      labels: {
                        padding: 8,
                        font: { size: 11 },
                      },
                    },
                  },
                  maintainAspectRatio: true,
                  responsive: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Applicant Status Table */}
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <h2 className="text-lg font-semibold mb-4">Applicant Status Breakdown</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Count</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Percentage</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Object.entries(statusDistribution).map(([status, count]) => {
                const percentage = ((count / applicantCount) * 100).toFixed(1);
                return (
                  <tr key={status} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{status}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{count}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{percentage}%</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
