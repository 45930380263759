import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import queryString from "query-string";

import api from "../../../services/api";

export const AppContext = React.createContext({});

export default ({ children, workshopId }) => {
  const [workshop, setWorkshop] = useState(null);
  const [userWorkshop, setUserWorkshop] = useState(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!workshop) return;
    window.history.replaceState(null, "New Page Title", `/learn_public/${workshop._id}?index=${index}`);
  }, [index]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/workshop/${workshopId}`);
      setWorkshop(data);

      // my code is horrible. Its complexe for nothing. and wont work for the end or home. Its bad. Sorry . seb
      const { index } = queryString.parse(location.search);
      if (index) setIndex(parseInt(index));
    })();
  }, [workshopId]);

  async function next(answer) {
    const obj = {};
    const totalSteps = workshop.steps.length + 1;

    const newIndex = index + 1;

    // if (answer && workshop.category === "profil") {
    //   answer.index = index;
    //   console.log("ANSWER", answer);
    //   // console.log(userWorkshop.choices, index, answer.index);
    //   const choices = (userWorkshop.choices || []).filter((e) => e.index !== index);
    //   obj.choices = [...choices, answer];
    //   console.log(obj.choices);
    // }

    // // setAnswers(newAnswers);

    // // const obj = { answers: newAnswers, index: newIndex };

    const finished = newIndex >= totalSteps;
    // if (!userWorkshop?.finished_at && finished) {
    //   obj.finished_at = Date.now();
    // }

    // if (!userWorkshop?.step_id || userWorkshop.step_id <= newIndex) {
    //   obj.step_id = newIndex;
    //   if (index === totalSteps) obj.step_id = totalSteps;
    // }

    // const { data: uw } = await api.put(`/workshop_user/public/${userWorkshop._id}`, obj);
    // setUserWorkshop(uw);

    if (finished) {
      toast.success("Workshop finished!");
    }
    setIndex(newIndex);
  }

  const setValues = (v) => {
    // const newAnswers = v ? { ...answers, ...v } : { ...answers };
    // setAnswers(newAnswers);
    // setUnsaved(true);
  };

  return <AppContext.Provider value={{ index, setIndex, workshop, setWorkshop, next, setValues, userWorkshop, setUserWorkshop }}>{children}</AppContext.Provider>;
};
