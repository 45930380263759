import React, { useEffect, useState } from "react";
import { Chart as ChartJS, registerables } from "chart.js";

import { formatNumberWithSpaces } from "../../project/utils";
import SelectUser from "../../../components/selectUser";

import api from "../../../services/api";

ChartJS.register(...registerables);

export default ({}) => {
  const [year, setYear] = useState("");
  const [lead, setLead] = useState("");
  const [status, setStatus] = useState("active");
  const [budgets, setBudgets] = useState(null);

  useEffect(() => {
    get();
  }, [lead, status, year]);

  async function get() {
    let query = { per_page: 2000, type: "startup-project" }
    if (year!== "") query = {...query, year: year}
    if (status!== "") query = {...query, status: status}
    const { data: p } = await api.post(`/budget/search`, query);
    const budgets = p;

    setBudgets(budgets)
  }

  if (!budgets) return <div>Loading...</div>;

  return (
    <div className="bg-white">
      <div className="grid grid-cols-4 gap-4 my-2">
        <SelectYear value={year} onChange={(e) => setYear(e)} />
        <SelectStatus value={status} onChange={(e) => setStatus(e)} />
        <SelectUser value={lead} onChange={(user) => setLead(user._id)} />
      </div>
      <BudgetTable budgets={budgets} status={status} year={year}/>
    </div>
  );
};

const SelectYear = ({ value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary">
    <option value="">All</option>
    <option value="2024">2024</option>
    <option value="2023">2023</option>
    <option value="2022">2022</option>
    <option value="2021">2021</option>
  </select>
);

const SelectStatus = ({ value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary">
    <option value="">All</option>
    <option value="active">Active</option>
    <option value="inactive">Inactive</option>
  </select>
);

const BudgetTable = ({ budgets, year, status }) => {
  const [totals, setTotals] = useState({ totalBudget: 0, totalBanks: 0, totalActivities: 0, totalInvoices: 0 });

  useEffect(() => {
    setTotals({ totalBudget: 0, totalBanks: 0, totalActivities: 0, totalInvoices: 0 });
  }, [budgets, year, status]);

  const handleTotals = (obj) => {
    setTotals((state) => ({
      totalBudget: state.totalBudget + obj.totalBudget,
      totalBanks: state.totalBanks + obj.totalBanks,
      totalActivities: state.totalActivities + obj.totalActivities,
      totalInvoices: state.totalInvoices + obj.totalInvoices,
    }));
  };

  return (
    <div className="w-full p-6 border border-gray-300 rounded">
      <table className="table-fixed">
        <thead className="border-b border-gray-300 text-left font-semibold text-sm">
          <tr>
            <th className="p-2">Project Name</th>
            <th className="p-2">Budget Name</th>
            <th className="p-2">Total Budget</th>
            <th className="px-3">Total factures</th>
            <th className="px-3">Total Consommé (banks + activités)</th>
            <th className="px-3">Diff Consommation et factures</th>
            <th className="px-3">Diff Budget et consommation</th>
            <th className="px-3">Lead</th>
          </tr>
        </thead>
        <tbody className="border-t border-gray-300 text-left p-2 text-sm font-semibold">
          {budgets.map((budget) => (
            <TableItem key={budget._id} budget={budget} status={status} year={year} handleTotals={handleTotals} />
          ))}
          <TableTotalItem totals={totals} />
        </tbody>
      </table>
    </div>
  );
};

const TableItem = ({ budget, year, status, handleTotals }) => {
  const [totalBudget, setTotalBudget] = useState(0);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [totalBanks, setTotalBanks] = useState(0);
  const [totalActivities, setTotalActivities] = useState(0);

  useEffect(() => {
    (async () => {
      const { data } = await api.post(`/report/budgets`, { budgetId: budget._id, year, status });

      setTotalBudget(Math.round(budget.amount));
      setTotalBanks(Math.round(data.totalBanks));
      setTotalActivities(Math.round(data.totalActivities));
      setTotalInvoices(Math.round(data.totalInvoices));

      handleTotals({ totalBudget: budget.amount, totalBanks: data.totalBanks, totalActivities: data.totalActivities, totalInvoices: data.totalInvoices });
    })();
  }, [year, status, budget]);

  let diffActivitiesInvoices = 0;
  let diffBudgetActivities = 0;

  diffActivitiesInvoices = totalActivities >= 0 ? Math.round(Math.round(totalActivities + totalBanks) - totalInvoices) : 0;
  diffBudgetActivities = totalActivities >= 0 ? Math.round(totalBudget - Math.round(totalActivities + totalBanks)) : 0;

  return (
    <tr>
      <td className="py-1">{budget.projectName}</td>
      <td className="py-1">{budget.name}</td>
      <td className="py-1">{budget.amount}€</td>
      <td className="py-1">{totalInvoices >= 0 ? `${formatNumberWithSpaces(totalInvoices)}€` : "..."}</td>
      <td className="py-1">{totalActivities >= 0 ? `${formatNumberWithSpaces(totalActivities + totalBanks)}€` : "..."}</td>
      <td className={`py-1 ${diffActivitiesInvoices > 5000 ? " text-red-600" : " text-green-600"}`}>
        {diffActivitiesInvoices ? `${formatNumberWithSpaces(diffActivitiesInvoices)}€` : "..."}
      </td>

      <td className={`py-1 ${diffBudgetActivities < 0 ? " text-red-600" : ""}`}>{diffBudgetActivities ? `${formatNumberWithSpaces(diffBudgetActivities)}€` : "..."}</td>
{/*       <td className="py-1">
        <div className="flex items-center gap-x-2 mr-2">
          {project.lead_avatar && <img src={project.lead_avatar} alt="lead avatar" className="h-6 w-6 rounded-full" />}
          <p className="text-sm font-semibold text-gray-700">{project.lead_name || "none"}</p>
        </div>
      </td> */}
    </tr>
  );
};

const TableTotalItem = ({ totals }) => {
  let diffActivitiesInvoices = 0;
  let diffBudgetActivities = 0;

  diffActivitiesInvoices = totals.totalActivities >= 0 ? Math.round(Math.round(totals.totalActivities + totals.totalBanks) - totals.totalInvoices) : 0;
  diffBudgetActivities = totals.totalActivities >= 0 ? Math.round(totals.totalBudget - Math.round(totals.totalActivities + totals.totalBanks)) : 0;

  return (
    <tr>
      <td className="py-1">Total</td>
      <td className="py-1"></td>
      <td className="py-1">{totals.totalBudget > 0 ? `${formatNumberWithSpaces(totals.totalBudget)}€` : "..."}</td>
      <td className="py-1">{totals.totalInvoices > 0 ? `${formatNumberWithSpaces(totals.totalInvoices)}€` : "..."}</td>
      <td className="py-1">{totals.totalActivities + totals.totalBanks > 0 ? `${formatNumberWithSpaces(totals.totalActivities + totals.totalBanks)}€` : "..."}</td>
      <td className={`py-1`}>{diffActivitiesInvoices ? `${formatNumberWithSpaces(diffActivitiesInvoices)}€` : "..."}</td>
      <td className={`py-1`}>{diffBudgetActivities ? `${formatNumberWithSpaces(diffBudgetActivities)}€` : "..."}</td>
    </tr>
  );
};
