import React from "react";
import MultiSelect from "../../../components/MultiSelect";

export const BANKS = [
  { value: "BUNQ", label: "Bunq" },
  { value: "BNP", label: "Bnp" },
  { value: "BUNQ_LESTUD", label: "Le stud" },
];

export default ({ values = [], onChange }) => {
  return <MultiSelect values={values} id="select-banks" options={BANKS} onSelectedChange={onChange} placeholder="Select a bank" />;
};
