import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../components/loader";

import api from "../../services/api";

export default () => {
  const [upworks, setUpworks] = useState();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    (async () => {
      const { data } = await api.post("/upwork/search", {});
      setUpworks(data);
    })();
  }, []);

  if (!upworks) return <Loader size="small" />;

  return (
    <div className="p-3">
      <div className="bg-white rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 table-fixed">
          <thead>
            <tr className="border-b border-gray-300">
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Name
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {upworks.map((d) => (
              <tr className="hover:bg-blue-50">
                <td className="px-4 py-2 text-sm font-medium text-gray-600 ">
                  <a className="hover:underline cursor-pointer" href={`/upwork/${d._id.toString()}`} target="_blank">
                    {d.name}
                  </a>
                </td>
                <td className="px-4 py-2 text-sm font-medium text-gray-600">{d}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
