import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";
import SelectUser from "../../../components/selectUser";
import Modal from "../../../components/modal";
import { useNavigate, useLocation } from "react-router-dom";

export default ({ contact, setContact }) => {
  const [values, setValues] = useState(contact);

  const navigate = useNavigate();
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  async function handleDelete(id) {
    if (!confirm("Are you sure you want to delete this person ?")) return;
    const newPeople = values.people.filter((p) => p.user_id !== id);
    const { ok, data } = await api.put(`/folk/${values._id}`, { ...values, people: newPeople });
    if (!ok) return toast.error("Error");
    setValues(data);
    toast.success("People deleted");
  }

  return (
    <div className="w-full p-4">
      <div>
        <span className=" font-bold mb-2">People</span>
        <CreateContact folk={values} setFolk={setValues} />
      </div>

      <div className="flex flex-row w-full  justify-between">
        <div className="w-1/2">
          <SelectUser
            className="w-3/4"
            placeholder="Add an existing user"
            userRoles={["admin", "user", "client"]}
            onChange={async (e) => {
              if (!e._id) return;
              const people = values.people || [];
              people.push({ user_id: e._id, user_name: e.name, user_avatar: e.avatar, user_email: e.email });
              const { data } = await api.put(`/folk/${values._id}`, { people });
              toast.success("User assigned");
              setFolk(data);
            }}
          />
        </div>
      </div>
      {(values?.people || []).map((person) => (
        <div className="flex items-center justify-between gap-2 border rounded-md  mt-2 hover:bg-gray-200 w-full p-2" key={person.user_id}>
          <div className="flex gap-2">
            <img src={person?.user_avatar ? person.user_avatar : person.user_avatar} alt="avatar" className="w-8 h-8 rounded-full" />
            <div>
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  query.set("user_modal_id", person.user_id);
                  query.set("user_modal_tab", "about");
                  navigate({ search: query.toString() });
                }}
                className="text-sm font-medium hover:underline">
                {person?.user_name}
              </button>
              <div className="text-xs text-gray-500">{person?.user_email}</div>
            </div>
          </div>
          <button type="button" className="text-xs border rounded-lg p-1 ml-2 hover:border-red-600" onClick={() => handleDelete(person.user_id)}>
            ❌
          </button>
        </div>
      ))}
    </div>
  );
};

const CreateContact = ({ folk, setFolk }) => {
  const [open, setOpen] = useState(false);
  const [newContact, setNewContact] = useState({});

  async function onSubmit() {
    try {
      const res = await api.post("/user", { ...newContact, newsletter: true });
      if (!res.ok) throw res;

      const people = folk.people || [];
      people.push({
        user_id: res.data._id,
        user_name: res.data.name,
        user_email: res.data.email,
        user_avatar: res.data.avatar,
      });
      const resFolk = await api.put(`/folk/${folk._id}`, { people });
      setFolk(resFolk.data);
      toast.success("Client created");
      setNewContact({ name: "", email: "", role: "lead" });

      setOpen(false);
    } catch (e) {
      toast.error("Some Error!", e.code);
    }
  }
  return (
    <div>
      <button type="button" className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={() => setOpen(true)}>
        Add new
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="min-w-96 p-6">
        <div>
          <div className="flex flex-col justify-between flex-wrap">
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium	">Name</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="name"
                value={newContact.name}
                onChange={(e) => setNewContact({ ...newContact, name: e.target.value })}
              />
            </div>
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium	">Email</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="email"
                value={newContact.email}
                onChange={(e) => setNewContact({ ...newContact, email: e.target.value })}
              />
            </div>
            <select
              className="projectsInput text-[14px] font-normal  bg-white text-[#212325] rounded-[10px]"
              type="select"
              name="role"
              value={newContact.role}
              onChange={(e) => setNewContact({ ...newContact, role: e.target.value })}>
              <option value="user">User</option>
              <option value="admin">Admin</option>
              <option value="applicant">Applicant</option>
              <option value="client">Client</option>
              <option value="lead">Lead</option>
            </select>
          </div>
        </div>
        <br />
        <div className="w-full flex justify-end">
          <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={onSubmit}>
            Create
          </button>
        </div>
      </Modal>
    </div>
  );
};
