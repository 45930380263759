import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import api from "../../../services/api";

import Table from "../../../components/Table";
import Paginator from "../../../components/Paginator";
import ActionModal from "../../../components/ActionModal";
import SelectS_Contact from "../../../components/selectS_Contact";

export default () => {
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [filters, setFilters] = useState({ page: 1, status: ["TODO", "PENDING"] });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = { ...filters };
    const { data, total } = await api.post("/s_action/search", newFilters);
    setActions(data);
    setTotal(total);
    setLoading(false);
  }

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between items-center">
        <Search setFilters={setFilters} filters={filters} />
        <SelectStatus
          value={filters.status}
          onChange={(e) => {
            if (e?.length && e?.[0] === "ALL") {
              return setFilters((prev) => ({ ...prev, status: null }));
            }
            setFilters((prev) => ({ ...prev, status: e }));
          }}
        />
        <div className="mx-auto">
          <SelectS_Contact
            placeholder="Contact Name"
            value={filters.contact_id}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, contact_id: e?._id }));
            }}
          />
        </div>
        <ActionModal action={selectedAction} setAction={setSelectedAction} refetch={fetch} />
        <button
          className="bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]"
          onClick={async () => {
            const { data } = await api.post("/s_action", { name: "Mon action" });
            toast.success("Action created!");
            await fetch();
            setSelectedAction(data);
          }}>
          Add
        </button>
      </div>

      <div>{total} items</div>
      <Table
        total={total}
        header={[
          { title: "Name", key: "name" },

          { title: "Contact Name", key: "contact_name" },
          { title: "Status", key: "status" },
          { title: "Deadline", key: "deadline" },
          { title: "User", key: "userName" },
          { title: "Category", key: "category" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {actions.map((item, index) => {
          return (
            <tr className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`} key={item._id} onClick={(e) => setSelectedAction(item)}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.contact_name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.status}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{displayDeadline(item.deadline)}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <img src={item.userAvatar} className="w-6 h-6 rounded-full" alt="avatar" />
                <span className="ml-2">{item.userName}</span>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.category}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const displayDeadline = (date) => {
  if (!date) return "";

  const now = new Date();
  const diff = now - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (days > 0) return `${(date || "").substring(0, 10)}, ${days} days ago`;
  if (days === 0) return "Today";
  if (days === 1) return "Tomorrow";
  return (date || "").substring(0, 10);
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");

  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);

  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};

const SelectStatus = ({ value, onChange }) => {
  const STATUS = ["TODO", "PENDING", "DONE", "CANCELED"];

  const handleSelect = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    onChange(selectedValues);
  };

  return (
    <div className="mx-auto">
      <select
        value={value}
        onChange={handleSelect}
        className="block mx-auto p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        <option key={null} value={null}>
          ALL
        </option>
        {STATUS.map((status) => (
          <option key={status} value={status}>
            {status.replace("_", " ")}
          </option>
        ))}
      </select>
    </div>
  );
};
