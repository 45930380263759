import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ company, setCompany }) => {
  const user = useSelector((state) => state.Auth.user);
  const [values, setValues] = useState(company);

  const handleDelete = async () => {
    if (!window.confirm("Are you sure?")) return;
    try {
      await api.remove(`/s_company/${company._id}`);
      toast.success("Successfully removed!");
      setCompany(null);
    } catch (error) {
      toast.error("An error occurred!");
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.put(`/s_company/${company._id}`, values);
      console.log("data", data);
      setCompany(data);
      toast.success("Updated!");
    } catch (error) {
      console.error(error);
      toast.error("Some Error!");
    }
  };

  return (
    <div className="grid grid-cols-3 gap-4 p-6">
      <form onSubmit={handleSubmit} className="col-span-2 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input className="input w-full" type="text" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Linkedin</label>
            <input className="input w-full" type="text" name="linkedin" value={values.linkedin} onChange={(e) => setValues({ ...values, linkedin: e.target.value })} />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Website</label>
            <input className="input w-full" type="text" name="website" value={values.website} onChange={(e) => setValues({ ...values, website: e.target.value })} />
          </div>
          <div className="space-y-2 col-span-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="description">
              Description
            </label>
            <textarea rows={4} className="input w-full" name="description" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
          </div>
        </div>
        <div className="flex justify-between">
          <button className="btn blue-btn" type="submit">
            Save
          </button>
          <button className="btn red-btn " onClick={handleDelete}>
            Delete
          </button>
        </div>
      </form>
    </div>
  );
};