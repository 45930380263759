export function calculateMargin(cost, revenue) {
  if (!cost || !revenue) return 0;
  let c = parseFloat(cost);
  let r = parseFloat(revenue);
  if (!c || !r) return 0;
  if (r < c) return 0;
  return (((r - cost) / r) * 100).toFixed(2);
}
export function isLink(str) {
  const pattern = /^https?:\/\/[\da-z.-]+\.[a-z.]{2,6}(\/[^\s]*)?$/i;
  return pattern.test(str);
}

export function getMonths(count = 20) {
  const arr = [];
  var d = new Date();
  for (let i = 0; i < count; i++) {
    arr.push(new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth() - i, 1)));
  }
  return arr;
}

export function formatDate(date) {
  const d = new Date(date);
  return d.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
  });
}

export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function calculateTjm(payment_value, payment_type, payment_currency, days_worked) {
  if (payment_type === "DAILY" && payment_currency == "DOLLAR") return payment_value * Math.round(tjm * 0.9);
  if (payment_type === "DAILY") return payment_value;
  const tjm = payment_value / days_worked;
  if (payment_currency == "DOLLAR") return Math.round(tjm * 0.9);
  return Math.round(tjm);
}

export const copyToClipboard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(text);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "absolute";
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
};

export const toDateInputValue = (date) => {
  if (!date) return "";
  var local = new Date(date);
  return local.toJSON().slice(0, 10);
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const formatNumberWithThousandSeparator = (x, separator = " ") => {
  if (!x) return x;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export function timeSince(date) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago ";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago ";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago ";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago ";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago ";
  }
  return Math.floor(seconds) + " seconds ago ";
}

export function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  date.setHours(0, 0, 0, 0);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function getDaysInMonthSoFar(month, year) {
  var today = new Date();
  var date = new Date(year, month, 1);
  date.setHours(0, 0, 0, 0);
  var days = [];
  while (date.getMonth() === month && date.getDate() <= today.getDate()) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export const exportCSV = async (name, data) => {
  if (!data.length) return;
  const date = new Date();
  const filename = `${name} - ${date.toLocaleDateString("fr")}.csv`;
  const header = Object.keys(data[0]);
  const csv = [header.join(";"), ...data.map((row) => header.map((field) => `"${row[field]}"`).join(";"))].join("\n");

  const blob = new Blob([csv]);
  if (window.navigator.msSaveOrOpenBlob)
    // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    window.navigator.msSaveBlob(blob, filename);
  else {
    const encodedUrl = window.URL.createObjectURL(blob, {
      type: "data:text/csv;charset=utf-8",
    });
    const link = document.createElement("a");
    link.setAttribute("href", encodedUrl);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const slugify = (str) => {
  if (!str) return "";
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();
  str = str.replace(/www\./g, "");

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc______";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-"); // collapse whitespace and replace by -

  return str;
};
