import React, { useEffect, useState } from "react";
import { copyToClipboard } from "../../utils";
import toast from "react-hot-toast";

import Modal from "../modal";

import Info from "./info";
import Logs from "./logs";
import Similar from "./similar";
import Files from "./files";
import Salary from "./salary";
import Invoice from "./invoice";
import { useSearchParams } from "react-router-dom";
import api from "../../services/api";
import Raw from "../Raw";
import NewInfo from "./new-info";

const TABS = [
  { title: "Info", href: "info" },
  { title: "Similar", href: "similar" },
  { title: "Salary", href: "salary" },
  { title: "Invoice", href: "invoice" },
  { title: "Logs", href: "logs" },
  { title: "Files", href: "files" },
  { title: "Raw", href: "raw" },
];

const BankModal = ({ bank, setBank }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(bank);
  const [tab, setTab] = useState("info");

  useEffect(() => {
    setData(bank);
  }, [bank]);

  useEffect(() => {
    const id = searchParams.get("bank_id");
    if (id) {
      api.get(`/bank/${id}`).then((res) => {
        setData(res.data);
      });
    }
  }, [searchParams.get("bank_id")]);

  const handleClose = () => {
    setData(null);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("bank_id");
    setSearchParams(newSearchParams);
  };

  if (!data) return <div />;

  return (
    <Modal isOpen={true} onClose={handleClose} className="w-2/3">
      <div className="p-8 space-y-4">
        <div className="flex justify-between">
          <h2 className="font-semibold text-lg">{`${data.name} ${data.amount}€  #${data.index}`}</h2>
        </div>

        <TabBar tabs={TABS} selected={tab} onClick={(t) => setTab(t)} />

        {
          {
            info: <NewInfo bank={data} setBank={setData} />,
            similar: <Similar bank={data} setBank={setData} />,
            logs: <Logs bank={data} setBank={setData} />,
            files: <Files bank={data} setBank={setData} />,
            raw: <Raw data={data} />,
            salary: <Salary bank={data} setBank={setData} />,
            invoice: <Invoice bank={data} setBank={setData} />,
          }[tab]
        }
      </div>
    </Modal>
  );
};

const TabBar = ({ selected, tabs, onClick }) => (
  <div className="overflow-x-scroll">
    <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
      {tabs.map((tab, i) => (
        <TabItem key={i} title={tab.title} active={selected === tab.href} onClick={() => onClick(tab.href)} />
      ))}
    </nav>
  </div>
);

const TabItem = ({ active, title, onClick }) => {
  return (
    <button onClick={onClick} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
      <div className="flex items-center gap-2 truncate">{title}</div>
    </button>
  );
};

export default BankModal;
