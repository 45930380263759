import React, { useEffect, useState } from "react";

import List from "./list";
import Stats from "./stats";
import Kanban from "./kanban";
import { FaUsers } from "react-icons/fa";
import { PiKanbanFill } from "react-icons/pi";
import { IoStatsChart } from "react-icons/io5";
import { classNames } from "../../utils";
import FunnelPipeline from "./funnel_pipeline";

const TABS = [
  {
    title: "List",
    tab: "list",
    Icon: FaUsers,
  },
  {
    title: "Kanban",
    tab: "kanban",
    Icon: PiKanbanFill,
  },
  {
    title: "Stats",
    tab: "stats",
    Icon: IoStatsChart,
  },
  {
    title: "Pipeline",
    tab: "pipeline",
    Icon: IoStatsChart,
  },
];

export default () => {
  const [tab, setTab] = useState("list");

  return (
    <div>
      <div className="m-2">
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden">
          {TABS.map((t) => (
            <TabItem key={t.tab} title={t.title} tab={t.tab} onClick={() => setTab(t.tab)} active={tab === t.tab} Icon={t.Icon} />
          ))}
        </nav>
        <div className="h-[calc(100%_-_100px)] overflow-auto">
          {tab === "kanban" && <Kanban />}
          {tab === "list" && <List />}
          {tab === "stats" && <Stats />}
          {tab === "pipeline" && <FunnelPipeline />}
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ title, onClick, active, Icon, Badge }) => (
  <button
    onClick={onClick}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm  hover:border-gray-300 hover:text-gray-700 border-b-2",
      active ? "text-primary  border-primary" : "border-transparent text-gray-500",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
