import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Loader from "../../../components/loader";
import api from "../../../services/api";
import Info from "./info";
import Raw from "./raw";

const TABS = [
  { title: "Info", tab: "info" },
  { title: "Raw", tab: "raw" },
];

export default () => {
  const [tab, setTab] = useState("info");
  const [tool, setTool] = useState();
  const { id } = useParams();

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const res = await api.get(`/tool/${id}`);
      setTool(res.data);
    } catch (e) {
      console.log("a", e);
    }
  }

  if (!tool) return <Loader />;

  return (
    <div className="py-8 px-10">
      <div className="flex justify-end">
        <Link to="/tools" className="btn blue-btn">
          Retour à la liste
        </Link>
      </div>
      <div className="flex items-center gap-4 p-4 mb-4">
        {tool.logo ? (
          <img src={tool.logo} alt={tool.name} className="h-12 rounded object-contain" />
        ) : (
          <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center">{tool.name[0]}</div>
        )}
        <h1 className="text-lg font-bold">{tool.name}</h1>
      </div>
      <div className="mb-4 flex justify-between items-center">
        <TabBar tabs={TABS} setTab={setTab} active={tab} />
      </div>
      <div>
        {tab === "info" && <Info tool={tool} setTool={setTool} />}
        {tab === "raw" && <Raw tool={tool} />}
      </div>
    </div>
  );
};

const TabBar = ({ tabs, setTab, active }) => (
  <nav className="bg-white rounded-md flex item-center gap-2 w-full border border-neutral-300 overflow-hidden p-1">
    {tabs.map((tab, i) => (
      <TabItem key={i} tab={tab.tab} title={tab.title} setTab={setTab} active={active === tab.tab} />
    ))}
  </nav>
);

const TabItem = ({ tab, title, setTab, active }) => (
  <button onClick={() => setTab(tab)} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
