import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { IoInformationCircleOutline, IoListOutline } from "react-icons/io5";
import toast from "react-hot-toast";

import api from "../../../services/api";
import { classNames } from "../../../utils";

import Info from "./info";
import Raw from "./raw";
import Notes from "./notes";
import Steps from "./steps";
import FileInput from "../../../components/FileInput";

const TABS = {
  INFO: "Info",
  STEPS: "Steps",
  RAW: "Raw",
  NOTES: "Notes",
};

const View = () => {
  const { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const step = params.get("step");
  const [tab, setTab] = useState(step ? TABS.STEPS : TABS.INFO);
  const [workshop, setWorkshop] = useState(null);

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const { data } = await api.get(`/workshop/${id}`);
      setWorkshop(data);
    } catch (e) {
      console.log("a", e);
    }
  }

  if (!workshop) return <div>Loading</div>;

  return (
    <div className="m-2">
      <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
        <div className="flex items-center gap-2 p-4">
          <FileInput
            value={workshop.image}
            onChange={async (e) => {
              let image = e.target.value[0];
              const { data, ok } = await api.put(`/workshop/${workshop._id}`, { image: image });
              if (ok) {
                setWorkshop(data);
                toast.success("Image updated!");
              } else {
                toast.error("Failed to update image");
              }
            }}
            folder={`/workshop`}
          />
          <div className="flex justify-between flex-1">
            <div className="flex items-center gap-2 flex-wrap">
              <div>
                <div className="text-lg font-bold">{workshop.name}</div>
              </div>
              {workshop.category ? (
                <p className="text-blue-700 bg-blue-50 ring-blue-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                  {workshop.category}
                </p>
              ) : null}
              {workshop.axe ? (
                <p className="text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                  {workshop.axe}
                </p>
              ) : null}
              {workshop.type ? (
                <p className="text-teal-800 bg-teal-50 ring-teal-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">{workshop.type}</p>
              ) : null}
              {workshop.level ? (
                <p className="text-fuchsia-800 bg-fuchsia-50 ring-fuchsia-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                  {workshop.level}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-x-4">
              <span className="text-xs flex-1 text-right text-neutral-400">Updated at</span>
              <span className="text-xs flex-1 text-left text-neutral-400">{moment(workshop.updated_at).format("DD/MM/YYYY, HH:mm")}</span>
              <span className="text-xs flex-1 text-right text-neutral-400">Created at</span>
              <span className="text-xs flex-1 text-left text-neutral-400">{moment(workshop.created_at).format("DD/MM/YYYY, HH:mm")}</span>
            </div>
          </div>
        </div>
        <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
          <TabItem title={TABS.INFO} tab={TABS.INFO} setTab={setTab} active={tab === TABS.INFO} Icon={IoInformationCircleOutline} />
          <TabItem title={TABS.STEPS} tab={TABS.STEPS} setTab={setTab} active={tab === TABS.STEPS} Icon={IoListOutline} />
          <TabItem title={TABS.RAW} tab={TABS.RAW} setTab={setTab} active={tab === TABS.RAW} />
          <TabItem title={TABS.NOTES} tab={TABS.NOTES} setTab={setTab} active={tab === TABS.NOTES} />
        </nav>
      </div>
      <div className="my-2" />
      {tab === TABS.INFO && <Info workshop={workshop} setWorkshop={setWorkshop} />}
      {tab === TABS.STEPS && <Steps workshop={workshop} setWorkshop={setWorkshop} />}
      {tab === TABS.RAW && <Raw workshop={workshop} setWorkshop={setWorkshop} />}
      {tab === TABS.NOTES && <Notes workshop={workshop} setWorkshop={setWorkshop} />}
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-primary  border-primary" : "",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);

export default View;
