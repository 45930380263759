import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import toast from "react-hot-toast";

import Loader from "../../components/loader";
import LoadingButton from "../../components/loadingButton";
import FileInput from "../../components/FileInput";

import api from "../../services/api";

export default () => {
  const [organisation, setOrganisation] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/organisation/${id}`);
      setOrganisation(data);
    })();
  }, []);

  if (!organisation) return <Loader />;

  return (
    <div>
      {/* Container */}
      <div className="appContainer pt-24">
        <Formik
          initialValues={organisation}
          onSubmit={async (values) => {
            try {
              const { data } = await api.put(`/organisation/${organisation._id}`, values);
              toast.success("Updated!");
            } catch (e) {
              console.log(e);
              toast.error("Some Error!");
            }
          }}>
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <React.Fragment>
                <FileInput value={values.logo} name="logo" folder="/organisation" onChange={handleChange} />
                <div className="w-full md:w-64 mt-[10px] mb-2">
                  <div className="text-[14px] text-[#212325] font-medium">Name</div>
                  <input className="projectsInput text-[14px] font-normal text-[#212325] bg-[#F9FBFD] rounded-[10px]" name="name" value={values.name} onChange={handleChange} />
                </div>
                <div className="flex items-center mt-4 space-x-4">
                  <div className="w-full md:w-64 mt-[10px] mb-2">
                    <div className="text-[14px] text-[#212325] font-medium">Email</div>
                    <input className="projectsInput text-[14px] font-normal text-[#212325] bg-[#F9FBFD] rounded-[10px]" name="email" value={values.email} onChange={handleChange} />
                  </div>
                  <div className="w-full md:w-64 mt-[10px] mb-2">
                    <div className="text-[14px] text-[#212325] font-medium">Iban</div>
                    <input className="projectsInput text-[14px] font-normal text-[#212325] bg-[#F9FBFD] rounded-[10px]" name="iban" value={values.iban} onChange={handleChange} />
                  </div>
                  <div className="w-full md:w-64 mt-[10px] mb-2">
                    <div className="text-[14px] text-[#212325] font-medium">Vat</div>
                    <input className="projectsInput text-[14px] font-normal text-[#212325] bg-[#F9FBFD] rounded-[10px]" name="vat" value={values.vat} onChange={handleChange} />
                  </div>
                </div>
                <div className="w-full mt-[10px] mb-2">
                  <div className="text-[14px] text-[#212325] font-medium">Address</div>
                  <textarea
                    className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
                    rows="12"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                  />
                </div>

                {/* Update and Delete Buttons */}
                <div className="flex  mt-2">
                  <LoadingButton className="bg-[#0560FD] text-[14px] font-medium text-[#FFFFFF] py-[8px] px-[22px] rounded-[10px]" loading={isSubmitting} onClick={handleSubmit}>
                    Update
                  </LoadingButton>
                  <button className="ml-[10px] bg-[#F43F5E] text-[14px] font-medium text-[#FFFFFF] py-[8px] px-[22px] rounded-[10px]">Delete</button>
                </div>
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
