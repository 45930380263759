import React from "react";
import { Routes, Route } from "react-router-dom";

import List from "./list";
import View from "./view";
import Explore from "./view/explore";

const Data = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/view/:id" element={<View />} />
    </Routes>
  );
};

export default Data;
