import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import api from "../../../services/api";

import Loader from "../../../components/loader";
import SelectUser from "../../../components/selectUser";

export default () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    sort: "-created_at",
    status: "active",
    contracts: ["CDD", "CDI", "INTERNSHIP", "FREELANCE", "APPRENTICESHIP"],
    per_page: 1000,
  });
  const { id } = useParams();

  const [skills, setSkills] = useState([]);

  async function get() {
    const { data: project } = await api.get(`/project/${id}`);
    const ids = project.team.map((member) => member.userId);
    const { data } = await api.post(`/user/search`, { ids });
    setUsers(data.users);
  }

  async function getSkills() {
    try {
      setLoading(true);
      const { data } = await api.post(`/skill/search`);
      console.log("✌️  data", data);
      setSkills(data);
    } catch (e) {
      console.log("a", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    get();
  }, [filters]);
  useEffect(() => {
    getSkills();
  }, []);

  if (!users) return <Loader />;

  const calculateAverageLevelByCategory = (users, category) => {
    let totalLevel = 0;
    let count = 0;

    users.forEach((user) => {
      user.stats_skillLevels?.forEach((skill) => {
        if (skill.category === category) {
          totalLevel += skill.level;
          count++;
        }
      });
    });

    return count > 0 ? totalLevel / count : 0;
  };

  const calculateAverageLevelBySubCategory = (users, subCategory) => {
    let totalLevel = 0;
    let count = 0;

    users.forEach((user) => {
      user.stats_skillLevels?.forEach((skill) => {
        if (skill.subCategory === subCategory) {
          totalLevel += skill.level;
          count++;
        }
      });
    });

    return count > 0 ? totalLevel / count : 0;
  };

  const categories = skills.reduce((acc, skill) => {
    if (!acc.includes(skill.category)) acc.push(skill.category);
    return acc;
  }, []);

  return (
    <div className="mx-auto">
      <div className="grid grid-cols-2 gap-2 py-2">
        <DebounceInput
          debounce={300}
          className="input"
          placeholder="Search (name, email, skills, etc...)"
          value={filters.search}
          onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
        />
        <SelectUser
          label="user"
          userName={filters.manager_name}
          value={{ user_name: filters.manager_name, user_id: filters.manager_id }}
          onChange={(e) => {
            setFilters({ ...filters, manager_id: e._id, manager_name: e.name });
          }}
          placeholder="All managers"
        />
      </div>
      {users?.length} users
      <div className="flex flex-col gap-3">
        {categories.map((category) => {
          return (
            <div className="flex w-full rounded-sm bg-white border border-gray-200" key={category}>
              <div className="flex-1 p-3">
                {category} :
                <ProgressBar percentage={(calculateAverageLevelByCategory(users, category) / 5) * 100} />
                <span className="text-sky-700 text-xs italic">{calculateAverageLevelByCategory(users, category)}</span>
              </div>
              <div className="flex-1 p-3">
                {skills
                  .reduce((acc, skill) => {
                    if (skill.category === category) {
                      acc.push(skill.subCategory);
                    }
                    return acc;
                  }, [])
                  .map((subCategory) => (
                    <div key={subCategory} className="flex w-full">
                      <div className="flex-1">
                        {subCategory} :
                        <ProgressBar percentage={(calculateAverageLevelBySubCategory(users, subCategory) / 5) * 100} />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ProgressBar = ({ percentage }) => {
  // if (!percentage >= 0) return <div className="w-full bg-gray-200 h-3 rounded-sm overflow-hidden animate-pulse" />;
  return (
    <div className={`w-full bg-gray-200 h-3 rounded-sm overflow-hidden`}>
      <div className="bg-white rounded-sm " style={{ width: `${Math.min(100, percentage)}%` }}>
        <div className={`flex justify-center items-center h-3 bg-sky-700 rounded-sm`} />
      </div>
    </div>
  );
};
