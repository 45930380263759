import React, { useState } from "react";
import Stats from "./stats";
import Home from "./home";

const TABS = {
  HOME: "HOME",
  STATS: "STATS",
};

export default () => {
  const [tab, setTab] = useState(TABS.HOME);

  return (
    <div>
      <div className="m-2">
        <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
          <TabItem onClick={() => setTab(TABS.HOME)} active={tab === TABS.HOME} title="Home" />
          <TabItem onClick={() => setTab(TABS.STATS)} active={tab === TABS.STATS} title="Stats" />
        </nav>
        {tab === TABS.HOME && <Home />}
        {tab === TABS.STATS && <Stats />}
      </div>
    </div>
  );
};

const TabItem = ({ onClick, active, title }) => {
  return (
    <button onClick={onClick} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
      <div className="flex items-center gap-2 truncate">{title}</div>
    </button>
  );
};
