import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import SelectMonth from "./selectMonth";
import SelectProject from "./selectProject";
import Modal from "./modal";

import api from "../services/api";

export default ({ invoiceId, invoiceName = "", onChange, projectId, disabled = false }) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(invoiceName);
  const [create, setCreate] = useState(false);

  useEffect(() => {
    get();
  }, [search]);

  async function get() {
    const query = { search, sent: "no", limit: 100 }
    if (projectId) query.project_id = projectId;
    const res = await api.post("/invoice/search", query);
    setData(res.data.sort((a, b) => (a.name || "").localeCompare(b.name)));
  }

  const getName = (f) => `${f.name}`;

  return (
    <div>
      <label className="text-[14px] text-[#212325] font-medium mb-1">
        {invoiceId ? (
          <div>
            <a href={`/invoice/${invoiceId}`} target="_blank" className="underline">
              Invoice {invoiceName}
            </a>
            <div
              className="inline-block ml-[10px] cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onChange(null);
              }}>
              X
            </div>
          </div>
        ) : (
          "Invoice"
        )}
      </label>
      <div>
        <input
          list="invoice"
          value={search}
          className={`w-[180px] bg-[#FFFFFF]  border-gray-300 text-[14px] text-[#212325] font-normal py-[9px] px-[14px] rounded-[10px] shadow-sm"`}
          placeholder={"All"}
          onChange={async (e) => {
            const str = e.target.value;
            if (str === "Create new") return setCreate(true);

            setSearch(str);
            const f = data.find((f) => {
              return str == getName(f);
            });

            if (!f) return;

            const res = await onChange(f);
            if (!res) setSearch("");
          }}
        />
        <datalist
          id="invoice"
          name={"selectinvoice"}
          disabled={disabled}
          className="w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm"
          value={invoiceName}
          defaultValue="">
          <option value="" disabled>
            Select invoice
          </option>
          {data.map((e) => (
            <option key={e._id} value={getName(e)} />
          ))}
          {!data.length && <option key={"new"} value="Create new">{`${search} - ${new Date().toLocaleDateString([], { year: "numeric", month: "long" })}`}</option>}
        </datalist>
      </div>
      {create && <CreateInvoice name={search} onChange={onChange} onClose={() => setCreate(false)} />}
    </div>
  );
};

const CreateInvoice = ({ name, onChange, onClose }) => {
  const [values, setValues] = useState({ name });

  async function onCreate() {
    if (!values.project) return toast.error("Missing Project !");
    const { data } = await api.post("/invoice", { project_id: values.project._id, date: values.date, name: values.name });
    await onChange(data);
    window.open(`/invoice/${data._id}`, "_blank");
    onClose();
  }

  return (
    <div>
      <Modal isOpen={true} className="max-w-xl w-full p-6" onClose={onClose}>
        <h4 className="mb-4 font-bold">Create new Invoice</h4>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Name </div>
          <input className="form-input block w-full" placeholder="Name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Projects(*)</div>
          <SelectProject indexDefaultValue={0} value={values?.project?.name} onChange={(e) => setValues({ ...values, project: e })} />
        </div>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Mois(*)</div>
          <SelectMonth
            placeholder="Select month"
            indexDefaultValue={0}
            // start={-3}
            value={values.date}
            onChange={(e) => setValues({ ...values, date: e.target.value })}
            name="date"
          />
        </div>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
