import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Board from "react-trello";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Card from "./Card";
import View from "./view";
import DebounceInput from "react-debounce-input";

import { RiMoneyEuroCircleFill } from "react-icons/ri";

import api from "../../services/api";

import SelectUser from "../../components/selectUser";

const categories = ["QUALIFIED", "CONTACT MADE", "DEMO SCHEDULE", "PROPOSAL MADE", "NEGOCIATION STARTED", "WON", "REJECT"];

const Leads = () => {
  const user = useSelector((state) => state.Auth.user);
  const [leads, setLeads] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [filter, setFilter] = useState({ search: "" });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let pathname = window.location.search;
      const a = new URLSearchParams(pathname);
      const params = Object.fromEntries(a);
      if (params.selectedLeadId) {
        const lead = await api.get(`/lead/${params.selectedLeadId}`);
        setSelectedLead(lead.data);
      }
    })();
  }, []);

  useEffect(() => {
    load();
  }, [filter]);

  async function load() {
    const { data } = await api.get(`/lead` + "?" + new URLSearchParams(filter).toString());
    setLeads(data.leads);
    setTags(data.tags);
  }

  return (
    <div className="flex flex-col">
      <div className="p-2 flex items-center gap-2">
        <div className="relative text-[#A0A6B1]">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" className="p-1">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-5 h-5">
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </span>
          <DebounceInput
            debounceTimeout={300}
            className="py-2 w-[364px] text-[14px] font-normal text-[black] rounded-[10px] bg-[#F9FBFD] border border-[#FFFFFF] pl-10"
            placeholder="Search"
            value={filter.search}
            onChange={(e) => {
              e.persist();
              setFilter((prev) => ({ ...prev, search: e.target.value }));
            }}
          />
        </div>
        <SelectUser value={filter.user_id} onChange={(u) => setFilter((prev) => ({ ...prev, user_id: u?._id || "" }))} />
        <SelectStatus data={tags} value={filter.tag} onChange={(tag) => setFilter((prev) => ({ ...prev, tag }))} />
        <Create onChange={(e) => setSelectedLead(e)} />
        {selectedLead && (
          <View
            value={selectedLead}
            onChange={(e) => {
              setSelectedLead(null);
              load();
            }}
          />
        )}
      </div>
      <div className="relative p-10">
        <div className="absolute inset-0 h-full w-full">
          <Board
            components={{ Card }}
            style={{ backgroundColor: "#F5F6FC" }}
            laneStyle={{ backgroundColor: "#e5e6eC" }}
            hideCardDeleteIcon
            data={{
              lanes: categories.map((category) => ({
                id: category,
                title: (
                  <div>
                    <h2 className="text-base">{category}</h2>
                    <div className="text-gray-600 text-sm font-normal flex gap-1 items-center">
                      <RiMoneyEuroCircleFill />
                      {leads
                        .filter((lead) => lead.status === category)
                        .reduce((prev, curr) => prev + (curr.budget || 0), 0)
                        .toLocaleString()}
                      &nbsp;-&nbsp;
                      {leads.filter((lead) => lead.status === category).length}&nbsp;leads
                    </div>
                  </div>
                ),
                cards: leads.filter((lead) => lead.status === category).map((lead) => ({ lead, id: lead._id })),
              })),
            }}
            onCardClick={(cardId) => {
              setSelectedLead(leads.find((f) => f._id === cardId));
              navigate({ pathname: "/salespipeline", search: "?" + new URLSearchParams({ selectedLeadId: cardId }).toString() });
            }}
            onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId) => {
              const lead = leads.find((f) => f._id === cardId);
              lead.status = toLaneId;
              api.put(`/lead/${cardId}`, lead);
              toast.success("Lead moved successfully");
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Create = ({ onChange }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onSubmit() {
    const { data } = await api.post(`/lead`, { ...values, status: categories[0] });
    toast.success("Lead created successfully");
    setOpen(false);
    onChange(data);
  }
  return (
    <>
      <button className="bg-primary text-white py-2 px-4 rounded-lg text-base font-medium" onClick={() => setOpen(true)}>
        Add a lead
      </button>

      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div
            className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <React.Fragment>
              <div>
                <div className="flex justify-between flex-wrap">
                  <div className="w-full md:w-[48%] mt-2">
                    <div className="text-[14px] text-[#212325] font-medium	">Name</div>
                    <input
                      className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                      name="name"
                      value={values.name}
                      onChange={(e) => setValues({ ...values, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <br />
              <button className="mt-[1rem]  bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onSubmit}>
                Create
              </button>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </>
  );
};

const SelectStatus = ({ data = [], value, onChange }) => {
  return (
    <select className="rounded-lg border text-sm border-lighter-grey" value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="">Select tag</option>
      {data.map((item) => (
        <option value={item}>{item}</option>
      ))}
    </select>
  );
};

export default Leads;
